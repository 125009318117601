import { getRandomInt } from '../../../services/getRandom'
import {
  AGENT_HOUSE_ADD_GROUP_FAIL,
  AGENT_HOUSE_ADD_GROUP_REQUEST,
  AGENT_HOUSE_ADD_GROUP_RESET,
  AGENT_HOUSE_ADD_GROUP_SUCCESS,
  AGENT_HOUSE_AGENCIES_FAIL,
  AGENT_HOUSE_AGENCIES_REQUEST,
  AGENT_HOUSE_AGENCIES_RESET,
  AGENT_HOUSE_AGENCIES_SUCCESS,
  AGENT_HOUSE_APPLY_PAYMENT_FILTER,
  AGENT_HOUSE_APPLY_STATUS_FILTER,
  AGENT_HOUSE_DELETE_GROUP_FAIL,
  AGENT_HOUSE_DELETE_GROUP_REQUEST,
  AGENT_HOUSE_DELETE_GROUP_SUCCESS,
  AGENT_HOUSE_GROUPS_FAIL,
  AGENT_HOUSE_GROUPS_REQUEST,
  AGENT_HOUSE_GROUPS_SELECT,
  AGENT_HOUSE_GROUPS_SUCCESS,
  AGENT_HOUSE_RESET,
  AGENT_HOUSE_UPDATE_FAVORITE_GROUP_SUCCESS,
  AGENT_HOUSE_UPDATE_GROUP_FAIL,
  AGENT_HOUSE_UPDATE_GROUP_REQUEST,
  AGENT_HOUSE_UPDATE_GROUP_RESET,
  AGENT_HOUSE_UPDATE_GROUP_SUCCESS,
} from '../../../types/actionTypes'
import { agencyApprovalStatus, agencyPayment } from '../../../types/agencyTypes'

const initialState = {
  agencyPaymentFilter: Object.values(agencyPayment).map(p => ({ ...p, checked: false })),
  agencyRequestStatusFilter: Object.values(agencyApprovalStatus).map(st => ({ ...st, checked: false })),
  allAgencies: [],
  allAgenciesLoading: [],
  favoriteGroups: [],
  allGroups: [],
  selectedGroups: [],
  groupsLoading: true,
  addGroupLoading: false,
  addGroupSuccess: false,
  updateGroupSuccess: false,
}

export const agentHouseReducer = (state = initialState, action) => {
  const { payload, type } = action
  switch (type) {
    case AGENT_HOUSE_APPLY_PAYMENT_FILTER: {
      return {
        ...state,
        agencyPaymentFilter: state.agencyPaymentFilter.map(f => ({
          ...f,
          checked: payload === f.id ? !f.checked : false,
        })),
      }
    }
    case AGENT_HOUSE_APPLY_STATUS_FILTER: {
      return {
        ...state,
        agencyRequestStatusFilter: state.agencyRequestStatusFilter.map(f => ({
          ...f,
          checked: payload === f.id ? !f.checked : false,
        })),
      }
    }
    case AGENT_HOUSE_GROUPS_REQUEST: {
      return {
        ...state,
        groupsLoading: true,
        groupsError: null,
      }
    }
    case AGENT_HOUSE_GROUPS_SUCCESS: {
      return {
        ...state,
        ...payload,
        groupsLoading: false,
        groupsError: null,
      }
    }
    case AGENT_HOUSE_GROUPS_FAIL: {
      return {
        ...state,
        groupsLoading: false,
        groupsError: payload,
      }
    }
    case AGENT_HOUSE_GROUPS_SELECT: {
      return {
        ...state,
        selectedGroups: [...payload],
      }
    }
    case AGENT_HOUSE_AGENCIES_RESET:
    case AGENT_HOUSE_AGENCIES_REQUEST: {
      return {
        ...state,
        allAgenciesLoading: true,
        allAgencies: [],
        allAgenciesError: null,
      }
    }
    case AGENT_HOUSE_AGENCIES_SUCCESS: {
      return {
        ...state,
        allAgencies: payload,
        allAgenciesLoading: false,
      }
    }
    case AGENT_HOUSE_AGENCIES_FAIL: {
      return {
        ...state,
        allAgenciesLoading: false,
        allAgenciesError: payload,
      }
    }
    case AGENT_HOUSE_ADD_GROUP_REQUEST:
      return {
        ...state,
        addGroupLoading: true,
        addGroupSuccess: false,
        addGroupError: null,
      }
    case AGENT_HOUSE_ADD_GROUP_SUCCESS: {
      const { success, group } = payload
      return {
        ...state,
        addGroupLoading: false,
        addGroupSuccess: success,
        addGroupError: null,
        ...(success && { allGroups: [...state.allGroups, group] }),
      }
    }
    case AGENT_HOUSE_ADD_GROUP_FAIL:
      return {
        ...state,
        addGroupLoading: false,
        addGroupSuccess: false,
        addGroupError: action.payload,
      }
    case AGENT_HOUSE_ADD_GROUP_RESET:
      return {
        ...state,
        addGroupLoading: false,
        addGroupSuccess: false,
        addGroupError: null,
      }
    case AGENT_HOUSE_UPDATE_GROUP_REQUEST: {
      const id = payload
      return {
        ...state,
        allGroups: state.allGroups.map(g => ({
          ...g,
          loading: id === g.id,
        })),
        favoriteGroups: state.favoriteGroups.map(g => ({
          ...g,
          loading: id === g.id,
        })),
        updateGroupSuccess: false,
        updateGroupError: null,
      }
    }
    case AGENT_HOUSE_UPDATE_GROUP_SUCCESS: {
      const { success, group } = payload
      return {
        ...state,
        updateGroupSuccess: success,
        updateGroupError: null,
        allGroups: success
          ? state.allGroups.map(g => (g.id === group.id ? group : g))
          : state.allGroups.map(g => ({ ...g, loading: g.id === group.id ? false : g?.loading })),
        favoriteGroups: success
          ? state.favoriteGroups.map(g => (g.id === group.id ? group : g))
          : state.favoriteGroups.map(g => ({ ...g, loading: g.id === group.id ? false : g?.loading })),
      }
    }
    case AGENT_HOUSE_UPDATE_FAVORITE_GROUP_SUCCESS: {
      const { success, group } = payload
      const moveToFavourite = !group.isFavourite
      return {
        ...state,
        updateGroupError: null,
        allGroups: success
          ? [
              ...state.allGroups.filter(g => (moveToFavourite ? g.id !== group.id : true)),
              ...(!moveToFavourite ? [{ ...group, isFavourite: false }] : []),
            ]
          : state.allGroups.map(g => ({ ...g, loading: g.id === group.id ? false : g?.loading })),
        favoriteGroups: success
          ? [
              ...state.favoriteGroups.filter(g => (!moveToFavourite ? g.id !== group.id : true)),
              ...(moveToFavourite ? [{ ...group, isFavourite: true }] : []),
            ]
          : state.favoriteGroups.map(g => ({ ...g, loading: g.id === group.id ? false : g?.loading })),
      }
    }
    case AGENT_HOUSE_UPDATE_GROUP_FAIL: {
      const { error, id } = payload
      return {
        ...state,
        updateGroupSuccess: false,
        updateGroupError: error,
        allGroups: state.allGroups.map(g => ({ ...g, loading: g.id === id ? false : g?.loading })),
        favoriteGroups: state.favoriteGroups.map(g => ({ ...g, loading: g.id === id ? false : g?.loading })),
      }
    }
    case AGENT_HOUSE_UPDATE_GROUP_RESET: {
      return {
        ...state,
        updateGroupSuccess: false,
        updateGroupError: null,
        allGroups: state.allGroups.map(g => ({ ...g, loading: false })),
        favoriteGroups: state.favoriteGroups.map(g => ({ ...g, loading: false })),
      }
    }
    case AGENT_HOUSE_DELETE_GROUP_REQUEST: {
      return {
        ...state,
        allGroups: state.allGroups.map(g => ({
          ...g,
          loading: payload === g.id,
        })),
        favoriteGroups: state.favoriteGroups.map(g => ({
          ...g,
          loading: payload === g.id,
        })),
      }
    }
    case AGENT_HOUSE_DELETE_GROUP_SUCCESS: {
      const { id, success } = payload
      const deleteFromAll = success && state.allGroups.map(gr => gr.id).includes(id)
      const deleteFromSelected = success && state.selectedGroups.includes(id)
      return {
        ...state,
        allGroups: state.allGroups
          .filter(g => (deleteFromAll ? g.id !== id : true))
          .map(g => ({
            ...g,
            loading: false,
          })),
        favoriteGroups: state.favoriteGroups
          .filter(g => (!deleteFromAll ? g.id !== id : true))
          .map(g => ({
            ...g,
            loading: false,
          })),
        ...(deleteFromSelected && { selectedGroups: state.selectedGroups.filter(g => g.id !== id) }),
      }
    }
    case AGENT_HOUSE_DELETE_GROUP_FAIL: {
      return {
        ...state,
        allGroups: state.allGroups.map(g => ({
          ...g,
          loading: false,
        })),
        favoriteGroups: state.favoriteGroups.map(g => ({
          ...g,
          loading: false,
        })),
      }
    }
    case AGENT_HOUSE_RESET: {
      return initialState
    }
    default:
      return state
  }
}
