import { useTranslation } from 'react-i18next'
import ExportButton from '../../../../components/Common/ExportButton/ExportButton'
import { useDispatch, useSelector } from 'react-redux'
import { disableSubagencyMode } from '../../../login/actions/loginActions'
import { useHistory } from 'react-router-dom'
import { agencyHouseRoutes } from '../../../../environment/urls'
import './BackToBigAgency.scss'

const BackToBigAgency = () => {
  const { t } = useTranslation('agencyHouseLobby')
  const { subagencyMode } = useSelector(({ login }) => login)
  const dispatch = useDispatch()
  const history = useHistory()

  if (!subagencyMode) return null

  const backToBigAgency = () => {
    dispatch(disableSubagencyMode())
    history.push(`${agencyHouseRoutes.agencies}/subagencies`)
  }

  return (
    <div className='back-big-agency-container'>
      <button onClick={backToBigAgency}>
        <ExportButton />
        <span>{t('goBackToBigAgency')}</span>
      </button>
    </div>
  )
}

export default BackToBigAgency
