import $api, { getCancelToken } from '..'
import { urlPolicyMapping } from '../../environment/urls'
import { delay } from '../../services/delay'

export const getPolicyTypesRequest = async ({
  reportName,
  policyTypes,
  companies,
  categories,
  averageCommissionRateRange,
  expectedCommissionRateRange,
}) =>
  await $api.post(
    `${urlPolicyMapping()}get-agency-policy-types`,
    { reportName, policyTypes, companies, categories, averageCommissionRateRange, expectedCommissionRateRange },
    getCancelToken('getPolicyTypesRequest')
  )

export const getPolicyTypeRequest = async policyTypeId =>
  await $api.get(`${urlPolicyMapping()}get-policy-type/${policyTypeId}`)

export const updateAgencyPolicyTypeRequest = async ({ id, categoryId, validationCommissionRate }) =>
  await $api.post(`${urlPolicyMapping()}update-agency-policy-type`, {
    id,
    categoryId,
    validationCommissionRate,
  })

export const getPolicyTypesChangesRequest = async () => await $api.get(`${urlPolicyMapping()}get-policy-types-changes`)

export const confirmPolicyTypesChangesRequest = async ({ confirmedPolicyTypes, declinedPolicyTypes }) =>
  await $api.post(`${urlPolicyMapping()}confirm-policies-types-changes`, { confirmedPolicyTypes, declinedPolicyTypes })
