import { phoneCodes } from '../../../environment/phoneCodes'
import {
  LOGIN_SUCCESS,
  LOGIN_RESET,
  LOGIN_GET_AGENCIES_REQUEST,
  LOGIN_GET_AGENCIES_SUCCESS,
  LOGIN_GET_AGENCIES_EMPTY_SUCCESS,
  LOGIN_GET_AGENCIES_FAILED,
  LOGIN_GET_CODE_RESET,
  LOGIN_SET_SUBSCRIPTION,
  LOGIN_SET_INFO,
  LOGIN_CLOSE_UI_TRANSITION_WARN,
  LOGIN_ENABLE_SUBAGENCYMODE,
  LOGIN_DISABLE_SUBAGENCYMODE,
  LOGIN_AGREE_PRIVACY_TERMS,
} from '../../../types/actionTypes'
import { agencyType } from '../../../types/agencyTypes'
import { paymentPlanType } from '../../../types/paymentPlanTypes'
const {
  il: { areaCodes, countryCode },
} = phoneCodes

const initialState = {
  countryPhoneCode: countryCode,
  areaPhoneCodes: areaCodes,
  areaPhoneCode: '',
  phoneNumber: '',
  agencies: [],
  currentAgency: '',
  loading: false,
  success: false,
  totalSuccess: false,
  serverError: {},
  subscription: paymentPlanType.trial,
  showUiTransitionWarn: false,
}

export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_GET_AGENCIES_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        serverError: {},
      }
    case LOGIN_GET_CODE_RESET:
      return {
        ...state,
        success: false,
      }
    case LOGIN_GET_AGENCIES_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        serverError: {},
        isAuthed: true,
        agencyId: action.payload.agencyId,
        agencyName: action.payload.agencyName,
        agencyDnsName: action.payload.agencyDnsName,
        currentAgency: action.payload.agencyName,
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        UserId: action.payload.UserId,
        AgencyType: action.payload.AgencyType,
        UserName: action.payload.UserName,
        role: action.payload.UserRole,
        preferences: action.payload.preferences,
        isAuthed: true,
      }
    case LOGIN_SET_SUBSCRIPTION:
      return {
        ...state,
        subscription: action.payload,
      }
    case LOGIN_GET_AGENCIES_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        serverError: action.payload,
      }
    case LOGIN_SET_INFO:
      return {
        ...state,
        ...action.payload,
      }
    case LOGIN_RESET:
      return initialState
    case LOGIN_CLOSE_UI_TRANSITION_WARN:
      return {
        ...state,
        showUiTransitionWarn: false,
      }
    case LOGIN_AGREE_PRIVACY_TERMS:
      return {
        ...state,
        agreementConfirmed: true,
      }
    case LOGIN_ENABLE_SUBAGENCYMODE: {
      return {
        ...state,
        AgencyType: agencyType.regular,
        subagencyMode: true,
      }
    }
    case LOGIN_DISABLE_SUBAGENCYMODE: {
      return {
        ...state,
        subagencyMode: false,
        AgencyType: action.payload,
      }
    }
    case LOGIN_GET_AGENCIES_EMPTY_SUCCESS:
    default:
      return state
  }
}
