import {
  CUSTOMER_INFO_REQUEST,
  CUSTOMER_INFO_SUCCESS,
  CUSTOMER_INFO_FAIL,
  CUSTOMER_UPDATE_INFO_REQUEST,
  CUSTOMER_UPDATE_INFO_SUCCESS,
  CUSTOMER_UPDATE_INFO_FAIL,
  CUSTOMER_GET_GROUP_REQUEST,
  CUSTOMER_GET_GROUP_SUCCESS,
  CUSTOMER_GET_GROUP_FAIL,
  CUSTOMER_SEARCH_REQUEST,
  CUSTOMER_SEARCH_SUCCESS,
  CUSTOMER_SEARCH_FAIL,
  CUSTOMER_ADD_MEMBER_REQUEST,
  CUSTOMER_ADD_MEMBER_SUCCESS,
  CUSTOMER_ADD_MEMBER_FAIL,
  CUSTOMER_REMOVE_MEMBER_REQUEST,
  CUSTOMER_REMOVE_MEMBER_SUCCESS,
  CUSTOMER_REMOVE_MEMBER_FAIL,
  CUSTOMER_GET_GROUP_TYPES_REQUEST,
  CUSTOMER_GET_GROUP_TYPES_SUCCESS,
  CUSTOMER_GET_GROUP_TYPES_FAIL,
  CUSTOMER_CATEGORIES_REQUEST,
  CUSTOMER_CATEGORIES_SUCCESS,
  CUSTOMER_CATEGORIES_FAIL,
  CUSTOMER_AGENTS_REQUEST,
  CUSTOMER_AGENTS_SUCCESS,
  CUSTOMER_AGENTS_FAIL,
  CUSTOMER_TREAT_REQUEST,
  CUSTOMER_TREAT_SUCCESS,
  CUSTOMER_TREAT_FAIL,
  CUSTOMER_GROUPS_REQUEST,
  CUSTOMER_GROUPS_SUCCESS,
  CUSTOMER_GROUPS_FAIL,
} from '../../../types/actionTypes'
import { urlCustomers, urlCustomer, urlTreatment, urlAgency } from '../../../environment/urls'
import { infoDetailsType } from '../../../types/infoDetailsTypes'
import $api from '../../../http'
import axios from 'axios'
import { getCustomerGroupsRequest } from '../../../http/requests'

const customersUrl = urlCustomers()
const customerUrl = urlCustomer()
const treatUrl = urlTreatment()
const agencyUrl = urlAgency()

let cancelSearchMembersRequest

export const getCustomerCategories = customerId => async (dispatch, getState) => {
  dispatch({ type: CUSTOMER_CATEGORIES_REQUEST })
  try {
    if (!customerId) customerId = getState().customer.customerInfo.id
    const {
      data: {
        data: {
          result: { clientCategories: usedCategories, otherCategories: unusedCategories },
        },
      },
    } = await $api.get(`${customerUrl}get-client-categories/${customerId}`)
    dispatch({
      type: CUSTOMER_CATEGORIES_SUCCESS,
      payload: { usedCategories, unusedCategories },
    })
  } catch (error) {
    dispatch({
      type: CUSTOMER_CATEGORIES_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const getCustomerInfo = customerId => async dispatch => {
  dispatch({ type: CUSTOMER_INFO_REQUEST })
  try {
    const {
      data: { data: customer },
    } = await $api.get(`${customerUrl}get-client/${customerId}`)
    dispatch({
      type: CUSTOMER_INFO_SUCCESS,
      payload: customer,
    })
  } catch (error) {
    dispatch({
      type: CUSTOMER_INFO_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const getAgents = () => async dispatch => {
  dispatch({ type: CUSTOMER_AGENTS_REQUEST })
  try {
    const {
      data: {
        data: { agents },
      },
    } = await $api.get(`${agencyUrl}agents`)
    dispatch({
      type: CUSTOMER_AGENTS_SUCCESS,
      payload: agents,
    })
  } catch (error) {
    dispatch({
      type: CUSTOMER_AGENTS_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const sendForTreat =
  ({ agentId, policyId, irregularityId, note, customerId = null, type }) =>
  async (dispatch, getState) => {
    dispatch({ type: CUSTOMER_TREAT_REQUEST })
    try {
      if (!customerId) {
        customerId = getState().customer.customerInfo.id
      }
      const treatDto = {
        agentId: agentId || null,
        policyId: policyId || null,
        irregularityId: irregularityId || null,
        customerId: customerId || null,
        note: note?.toString() || null,
        type,
      }
      const {
        data: {
          data: { success },
        },
      } = await $api.put(`${treatUrl}add`, treatDto)
      dispatch({
        type: CUSTOMER_TREAT_SUCCESS,
        payload: { success, irregularityId, agentId },
      })
    } catch (error) {
      dispatch({
        type: CUSTOMER_TREAT_FAIL,
        payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      })
    }
  }

export const updateCustomerInfo = (id, infoType, value) => async dispatch => {
  try {
    dispatch({ type: CUSTOMER_UPDATE_INFO_REQUEST })
    let data
    switch (infoType) {
      case infoDetailsType.customerName:
        data = await $api.post(`${customerUrl}update-client-name`, {
          CustomerId: id,
          name: value,
        })
        break
      case infoDetailsType.address:
        data = await $api.post(`${customerUrl}update-client-address`, {
          CustomerId: id,
          Address: value,
        })
        break
      case infoDetailsType.phone:
        data = await $api.post(`${customerUrl}update-client-phone`, {
          CustomerId: id,
          Phone: value,
        })
        break
      case infoDetailsType.group:
        break
      default:
        break
    }

    const response = data.data.data

    dispatch({
      type: CUSTOMER_UPDATE_INFO_SUCCESS,
      payload: response,
    })
    if (response.success) {
      dispatch(getCustomerInfo(id))
    }
  } catch (error) {
    dispatch({
      type: CUSTOMER_UPDATE_INFO_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const getCustomerGroupTypes = () => async dispatch => {
  dispatch({ type: CUSTOMER_GET_GROUP_TYPES_REQUEST })
  try {
    const {
      data: { data },
    } = await $api.get(`${customerUrl}group-types`)
    dispatch({
      type: CUSTOMER_GET_GROUP_TYPES_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CUSTOMER_GET_GROUP_TYPES_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

const getCustomerGroupMembers = groupId => async (dispatch, getState) => {
  let { clientGroupId: groupId, id } = getState().customer.customerInfo
  try {
    dispatch({ type: CUSTOMER_GET_GROUP_REQUEST })

    const {
      data: { data },
    } = await $api.post(`${customerUrl}client-group/members`, { Id: groupId })

    dispatch({
      type: CUSTOMER_GET_GROUP_SUCCESS,
      payload: data,
    })

    if (data.length === 0) {
      dispatch(getCustomerInfo(id))
    }
  } catch (error) {
    dispatch({
      type: CUSTOMER_GET_GROUP_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const customerSearchMembers =
  (searchText = '', currentPage = 0, pageSize = 25) =>
  async (dispatch, getState) => {
    const { id: customerId } = getState().customer.customerInfo
    try {
      //Check if there are any previous pending requests
      if (typeof cancelSearchMembersRequest != typeof undefined) {
        cancelSearchMembersRequest.cancel('Operation canceled due to new request.')
      }

      //Save the cancel token for the current request
      cancelSearchMembersRequest = axios.CancelToken.source()
      dispatch({ type: CUSTOMER_SEARCH_REQUEST })
      const {
        data: { data },
      } = await $api.post(
        `${customersUrl}filter-clients`,
        {
          SearchText: searchText,
          CurrentPage: currentPage,
          PageSize: pageSize,
        },
        { cancelToken: cancelSearchMembersRequest.token }
      )

      dispatch({
        type: CUSTOMER_SEARCH_SUCCESS,
        payload: data,
      })
      if (data.success) {
        dispatch(getCustomerInfo(customerId))
      }
    } catch (error) {
      dispatch({
        type: CUSTOMER_SEARCH_FAIL,
        payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      })
    }
  }

export const customerAddMembers = (fromCustomerId, groupTypeId, customersIds) => async (dispatch, getState) => {
  dispatch({ type: CUSTOMER_ADD_MEMBER_REQUEST })
  try {
    const {
      data: { data },
    } = await $api.post(`${customerUrl}join-clients-into-group`, {
      FromCustomerId: fromCustomerId,
      groupType: Number(groupTypeId),
      customersIds: customersIds,
      IgnoreConflict: true,
    })
    dispatch({
      type: CUSTOMER_ADD_MEMBER_SUCCESS,
      payload: data.success,
    })
    if (data.success) {
      dispatch(getCustomerInfo(fromCustomerId))
    }
  } catch (error) {
    dispatch({
      type: CUSTOMER_ADD_MEMBER_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const customerRemoveMember = customerId => async (dispatch, getState) => {
  const { clientGroupId: groupId } = getState().customer.customerInfo
  try {
    dispatch({ type: CUSTOMER_REMOVE_MEMBER_REQUEST })
    const {
      data: { data },
    } = await $api.post(`${customerUrl}remove-client-from-group/${groupId}/${customerId}`)

    dispatch({
      type: CUSTOMER_REMOVE_MEMBER_SUCCESS,
      payload: data.success,
    })
    if (data.success) {
      dispatch(getCustomerGroupMembers(groupId))
    }
  } catch (error) {
    dispatch({
      type: CUSTOMER_REMOVE_MEMBER_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const getCustomerGroups = id => async (dispatch, getState) => {
  const customerId = id ?? getState().customer.customerInfo.id
  dispatch({ type: CUSTOMER_GROUPS_REQUEST })
  try {
    const groups = await getCustomerGroupsRequest(customerId)
    dispatch({
      type: CUSTOMER_GROUPS_SUCCESS,
      payload: groups,
    })
  } catch (error) {
    dispatch({
      type: CUSTOMER_GROUPS_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}
