import classNames from 'classnames'
import { useState } from 'react'
import ButtonCheckbox from '../../../../components/Common/ButtonCheckbox/ButtonCheckbox'
import './InputSelectAssociateCompanies.scss'
import { useTranslation } from 'react-i18next'

const InputSelectAssociateCompanies = ({
  label = 'Options',
  items,
  selectItem,
  selectAllItems,
  allSelected,
  tabIndex = 0,
  required = false,
  noItemsPlaceholder = 'Nothing Found',
  defaultItem,
  disabled = false,
}) => {
  const { t } = useTranslation('common')
  const [showOptions, setShowOtions] = useState(false)

  return (
    <div className='input-select-associate-container'>
      <div
        className={classNames('input-select-associate-value-container', {
          'has-values': items.some(itm => itm.selected),
        })}
        tabIndex={tabIndex}
        onBlur={e => {
          if (!e.currentTarget.contains(e.relatedTarget)) {
            setShowOtions(false)
          }
        }}
        title={items
          .filter(itm => itm.selected)
          .map(itm => itm.value)
          .join(', ')}
      >
        <div
          className={classNames('input-select-associate-value')}
          onClick={() => !disabled && setShowOtions(!showOptions)}
        >
          <div className='input-select-associate-value-arrow-wrapper'>
            <div className={classNames('input-select-associate-value-arrow', { flipped: showOptions })}></div>
          </div>
          {label && (
            <div className='input-select-associate-value-label'>
              {label}
              {required && '*'}
            </div>
          )}
          <div className='line'></div>
        </div>
        {showOptions && items.length === 0 && (
          <div className='input-select-associate-options-wrapper' style={{ height: 'unset' }}>
            {noItemsPlaceholder}
            {defaultItem}
          </div>
        )}
        {showOptions && items.length > 0 && (
          <div className='input-select-associate-options-wrapper'>
            <ul className='input-select-associate-options-container'>
              <li className={classNames('input-select-associate-option')}>
                <ButtonCheckbox checked={allSelected} onClick={selectAllItems} />
                <div onClick={selectAllItems}>{t('allCompanies')}</div>
              </li>
              {items.map(itm => (
                <li key={itm.id} className={classNames('input-select-associate-option')}>
                  <ButtonCheckbox checked={itm.selected} onClick={() => selectItem(itm.id)} />
                  <div onClick={() => selectItem(itm.id)}>{itm.value}</div>
                </li>
              ))}
            </ul>
            {defaultItem}
          </div>
        )}
      </div>
    </div>
  )
}

export default InputSelectAssociateCompanies
