export const categoryType = {}

export const categoriesObjects = {
  1: { markerColor: '#D35400' },
  2: { markerColor: '#F39C12' },
  3: { markerColor: '#F1C40F' },
  4: { markerColor: '#2ECC71' },
  5: { markerColor: '#27AE60' },
  6: { markerColor: '#16A085' },
  7: { markerColor: '#1ABC9C' },
  8: { markerColor: '#3498DB' },
  9: { markerColor: '#2980B9' },
  10: { markerColor: '#8E44AD' },
  11: { markerColor: '#9B59B6' },
  12: { markerColor: '#E74C3C' },
  13: { markerColor: '#C0392B' },
  14: { markerColor: '#FF5733' },
  15: { markerColor: '#612AA2' },
  16: { markerColor: '#D35400' },
  17: { markerColor: '#F39C12' },
  18: { markerColor: '#F1C40F' },
  19: { markerColor: '#2ECC71' },
  20: { markerColor: '#27AE60' },
  21: { markerColor: '#16A085' },
  22: { markerColor: '#1ABC9C' },
  23: { markerColor: '#3498DB' },
  24: { markerColor: '#2980B9' },
  25: { markerColor: '#8E44AD' },
  default: { markerColor: '#02B64A' },
}
