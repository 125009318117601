import './LogoLightSvg.scss'

const LogoLightSvg = () => (
  <svg width='40px' height='24px' viewBox='0 0 40 24' version='1.1' xmlns='http://www.w3.org/2000/svg'>
    <title>Icon@3x</title>
    <defs>
      <filter x='-84.0%' y='-48.0%' width='268.0%' height='196.0%' filterUnits='objectBoundingBox' id='filter-1'>
        <feGaussianBlur stdDeviation='1.12' in='SourceGraphic'></feGaussianBlur>
      </filter>
      <path
        d='M16.0682183,8.782 L20.1320736,20 L14.9685341,20 L11.1932183,8.782 L16.0682183,8.782 Z M12.8890896,0 L15.2182183,6.435 L10.4032183,6.435 L8.23721826,0 L12.8890896,0 Z'
        id='path-2'
      ></path>
    </defs>
    <g id='V.3' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='Ai-gent-logo-Copy' transform='translate(-1252.000000, -582.000000)' fillRule='nonzero'>
        <g id='Mockup' transform='translate(21.000000, 516.000000)'>
          <g id='Side-Menu' transform='translate(1216.000000, 38.000000)'>
            <g id='Icon' transform='translate(15.000000, 32.000000)'>
              <path
                d='M25.3567298,8.78183001 C25.2955071,9.17040515 25.2648957,9.58112382 25.2648957,10.013986 C25.2648957,11.8228438 25.7588887,13.2354312 26.7468746,14.2517483 C27.7348605,15.2680653 29.0945173,15.7762238 30.825845,15.7762238 C32.0114281,15.7762238 33.0135281,15.4778555 33.832145,14.8811189 C34.6507619,14.2843823 35.2482582,13.4265734 35.6246338,12.3076923 L35.6246338,12.3076923 L29.4991211,12.3076923 L29.4991211,8.78321678 L40,8.78321678 L40,13.2307692 C39.6424432,14.4242424 39.0355376,15.5337995 38.1792831,16.5594406 C37.3230286,17.5850816 36.2362441,18.4149184 34.9189296,19.048951 C33.601615,19.6829837 32.1149314,20 30.4588788,20 C28.5017258,20 26.756284,19.5757576 25.2225535,18.7272727 C23.6888229,17.8787879 22.4938304,16.6993007 21.637576,15.1888112 C20.7813215,13.6783217 20.3531943,11.95338 20.3531943,10.013986 C20.3531943,9.59343265 20.3733261,9.18274426 20.4135897,8.78192082 Z M30.4306507,0 C32.8018169,0 34.8013122,0.568764569 36.4291366,1.70629371 C38.056961,2.84382284 39.1343361,4.41958042 39.661262,6.43356643 L39.661262,6.43356643 L34.326138,6.43356643 C33.9685812,5.78088578 33.4557695,5.28205128 32.7877028,4.93706294 C32.1196361,4.59207459 31.3339521,4.41958042 30.4306507,4.41958042 C28.868692,4.41958042 27.6172431,4.92773893 26.6763042,5.94405594 C26.5323629,6.09952825 26.3994314,6.26296477 26.2775098,6.43436552 L20.9168963,6.43573726 C21.1083529,5.87498493 21.3485794,5.33813079 21.637576,4.82517483 C22.4938304,3.30536131 23.6841182,2.12121212 25.2084394,1.27272727 C26.7327605,0.424242424 28.4734976,0 30.4306507,0 Z'
                id='G-Copy'
                fill='#FFFFFF'
              ></path>
              <path
                d='M8.23721826,0 L10.058,5.411 L6.304,16.467 L6.30196639,16.4672365 L5.10430762,20 L0,20 L7.24298399,0 L8.23721826,0 Z'
                id='A-Copy'
                fill='#FFFFFF'
              ></path>
              <path
                d='M10.6704446,0 L12,7 L9.23488404,7 L8,0 L10.6704446,0 Z'
                id='Shadow-Copy'
                fillOpacity='0.38'
                fill='#000000'
                filter='url(#filter-1)'
              ></path>
              <g id='I-Copy'>
                <use fill='#FFFFFF' href='#path-2'></use>
                <use fill='#02B64A' href='#path-2'></use>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default LogoLightSvg
