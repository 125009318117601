import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import StepPhone from '../StepPhone/StepPhone'
import { requestRegisterSms } from '../../../../http/requests'
import { logout } from '../../../login/actions/loginActions'
import ModalAgreement from '../../../../components/ModalAgreement/ModalAgreement'
import { buildFullPhoneNumber } from '../../../../utils/buildFullPhoneNumber'
import TabsNavbar from '../../../../components/TabsNavbar/TabsNavbar'
import { twoFactorOption, twoFactorOptions } from '../../../../types/twoFactorOptionTypes'
import { hebrew } from '../../../../i18n'
import { getRegisterCodeRequest } from '../../../../http/requests/agencyLoginRequest'

const StepPhoneNumber = ({ nextStep, data, setData }) => {
  const { t } = useTranslation('registration')
  const { t: tCommon } = useTranslation('common')

  const { phoneNumber, option, agentEmail } = data
  const [loading, setLoading] = useState(false)
  const [serverError, setServerError] = useState(null)
  const [agreedPrivacy, setAgreedPrivacy] = useState(false)
  const [showAgreement, setShowAgreement] = useState(false)
  const selectedOptionPhone = option === twoFactorOption.byPhone
  const selectedOptionKey = selectedOptionPhone ? 'Phone' : 'Email'
  const inputValue = selectedOptionPhone ? phoneNumber : agentEmail ?? ''

  const agreePrivacyPolicyHandler = () => {
    setAgreedPrivacy(true)
    setShowAgreement(false)
  }

  const dispatch = useDispatch()

  const submitDisabled =
    !agreedPrivacy ||
    (selectedOptionPhone
      ? phoneNumber.length < 9
      : !agentEmail.match(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )) ||
    loading

  const submitHandler = async e => {
    e.preventDefault()
    const fullPhoneNumber = buildFullPhoneNumber(phoneNumber)
    try {
      setLoading(true)
      dispatch(logout())
      const { data } = await getRegisterCodeRequest({
        isEmail: !selectedOptionPhone,
        value: selectedOptionPhone ? fullPhoneNumber : agentEmail,
      })
      if (data.HasError) {
        setServerError(data.ErrorMessage)
      } else if (!data.Data.HasError) {
        setData(prev => ({ ...prev, ...(selectedOptionPhone ? { phoneNumber: fullPhoneNumber } : { agentEmail }) }))
        nextStep()
      }
    } catch (error) {
      setServerError('Server Error')
    }
    setLoading(false)
  }

  const onChangeHandler = e => {
    let { value } = e.target
    setData(prev => ({ ...prev, ...(selectedOptionPhone ? { phoneNumber: value } : { agentEmail: value }) }))
  }

  return (
    <>
      <StepPhone serverError={serverError} setServerError={setServerError}>
        <form className='register-step-phone-form' onSubmit={submitHandler}>
          <TabsNavbar
            items={Object.values(twoFactorOptions).map(o => ({ ...o, text: hebrew() ? o.name : o.nameEn }))}
            currentItem={option}
            setCurrentItem={option => setData(prev => ({ ...prev, option }))}
          />
          <div className='register-step-phone-form-header'>
            <h5 className='register-step-phone-form-heading'>{t(`step2factor.form${selectedOptionKey}Heading`)}</h5>
            <p>{t('step2factor.formSubheading')}</p>
          </div>
          <div className='register-step-phone-form-inputs-container'>
            <div className='register-step-phone-form-inputs-group'>
              <input
                type={selectedOptionPhone ? 'tel' : 'email'}
                placeholder={tCommon(`login.input${selectedOptionKey}Placeholder`)}
                value={inputValue}
                onChange={onChangeHandler}
                name='phoneNumber'
                id='phoneNumber'
                size={17}
                autoFocus
              />
            </div>
            <button onClick={submitHandler} disabled={submitDisabled}>
              {t('step2factor.formBtnRequest')}
            </button>
          </div>
          <div className='register-step-phone-form-footer'>
            <div className='register-step-phone-form-terms' onClick={() => setShowAgreement(true)}>
              <div className='policy-terms-icon'>
                <img
                  src={`./assets/${
                    agreedPrivacy ? 'checkbox-sm-checked/Square' : 'check-box-blank/Check Box Blank'
                  }.png`}
                  alt='agree'
                  onClick={() => {
                    setShowAgreement(true)
                  }}
                />
              </div>
              <h4>
                <Trans
                  i18nKey='policyTerms.text' // the key in your JSON file
                  ns='registration'
                  values={{
                    // The variables in your i18n entry from the JSON file
                    policy: t('policyTerms.policy'),
                    terms: t('policyTerms.terms'),
                  }}
                  components={[<span>link1</span>, <span>link2</span>]} // The component at index 0 (<strong />) will be parent of <0> ({{- key}}), and so on...
                />
              </h4>
            </div>
          </div>
        </form>
      </StepPhone>
      {showAgreement && (
        <ModalAgreement
          closeModal={() => setShowAgreement(false)}
          confirmAgreement={agreePrivacyPolicyHandler}
          agreementConfirmed={agreedPrivacy}
        />
      )}
    </>
  )
}

export default StepPhoneNumber
