import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ArrowRightIconSvg from '../../../../components/Common/SvgIcons/ArrowRightIconSvg/ArrowRightIconSvg'
import RegisterTermsFooter from '../RegisterTermsFooter/RegisterTermsFooter'
import { getAgencies, registerBigAgency } from '../../../../http/requests'
import { saveLoginInfo } from '../../../../services/saveLoginInfo'
import { useCrossDomainCookies } from '../../../../environment/urls'
import { LOGIN_SET_INFO } from '../../../../types/actionTypes'
import { buildUserPreferences } from '../../../../types/agencyUserTypes'
import { COOKIES_MAX_AGE } from '../../../../constants/cookies'
import { setItemToLocalStorage } from '../../../../services/localStorageService'
import AgencyHouseDetailsForm from '../../../agentHouse/components/AgencyHouseDetailsForm/AgencyHouseDetailsForm'
import ButtonRounded from '../../../../components/Common/ButtonRounded/ButtonRounded'
import './StepAgentHouseDetails.scss'
import { twoFactorOption } from '../../../../types/twoFactorOptionTypes'
import { buildFullPhoneNumber } from '../../../../utils/buildFullPhoneNumber'
import ModalPaymentAgreement from '../../../../components/ModalPaymentAgreement/ModalPaymentAgreement'
import PaymentFrameModal from '../../../../components/PaymentFrameModal/PaymentFrameModal'

const StepAgentHouseDetails = ({ prevStep, data, setData }) => {
  const history = useHistory()
  const { t } = useTranslation('registration')
  const [submitLoading, setSubmitLoading] = useState(false)
  const [serverError, setServerError] = useState(null)
  const [submitSuccess, setSubmitSuccess] = useState(false)

  const [agreedTerms, setAgreedTerms] = useState(false)
  const [showAgreement, setShowAgreement] = useState(false)
  const [showPaymentFrame, setShowPaymentFrame] = useState(false)

  const agreeTermsHandler = () => {
    setAgreedTerms(true)
    setShowAgreement(false)
    setShowPaymentFrame(true)
  }
  const afterSuccessHandler = () => {
    !agreedTerms ? setShowAgreement(true) : setShowPaymentFrame(true)
  }

  const onPaymentSuccess = () => history.push('/')

  const dispatch = useDispatch()
  const prevSelectedOptionPhone = data.option === twoFactorOption.byPhone

  const onChangeHandler = dataType => value => {
    clearServerError()
    setData(prev => ({
      ...prev,
      [dataType]: value,
    }))
  }

  const setAgencyTypeHandler = ({ target: { value } }) => {
    setData(prev => ({
      ...prev,
      type: +value,
    }))
  }

  const formIsValid = () =>
    data.agencyId?.length > 0 &&
    data.agencyName?.length > 0 &&
    data.agentEmail?.length > 0 &&
    data.phoneNumber?.length > 0

  // const isValidEmail = data.agentEmail.match('[a-z0-9]+@[a-z]+.[a-z]{2,3}')
  const clearServerError = () => {
    if (serverError) setServerError(null)
  }
  const submitBtnDisabled = () => submitLoading || !formIsValid() || submitSuccess

  const submitHandler = async e => {
    e.preventDefault()
    try {
      setServerError(null)
      setSubmitLoading(true)
      const { data: response } = await registerBigAgency({
        ...data,
        agencyIdNumber: data.agencyId,
        ...(!prevSelectedOptionPhone && { phoneNumber: buildFullPhoneNumber(data.phoneNumber) }),
        value: prevSelectedOptionPhone ? buildFullPhoneNumber(data.phoneNumber) : data.agentEmail,
        isEmail: !prevSelectedOptionPhone,
      })
      if (response.HasError) {
        const err = response.ErrorMessage.toLowerCase().includes('email')
          ? t('stepAgentDetails.emailAlreadyTaken')
          : response.ErrorMessage
        setServerError(err)
        setSubmitLoading(false)
      } else {
        setSubmitSuccess(true)
        saveLoginInfo({ ...response.Data, AgreementConfirmed: true })
        const {
          data: { Data: agenciesData },
        } = await getAgencies(response.Data.Id)
        const login = {
          AgencyType: response.Data.AgencyType,
          Id: response.Data.Id,
          UserName: response.Data.FullName,
          AppToken: response.Data.AppToken,
          AccessToken: response.Data.AppToken.AccessToken,
          RefreshToken: response.Data.AppToken.RefreshToken,
          RefreshTokenExpireAt: response.Data.AppToken.RefreshTokenExpireAt,
          UserId: response.Data.AppToken.UserId,
          id: response.Data.AppToken.id,
          role: response.Data.Role,
          preferences: buildUserPreferences(response.Data.Preferences),
          agencyId: agenciesData.Id,
          agencyName: agenciesData.Name,
          agencyDnsName: agenciesData.dnsName,
          currentAgency: agenciesData.Name,
          agreementConfirmed: response.Data.AgreementConfirmed,
        }
        document.cookie = `agencyId=${agenciesData.Id}; Max-Age=${COOKIES_MAX_AGE}${useCrossDomainCookies}`
        setItemToLocalStorage('agencyName', agenciesData.Name)
        document.cookie = `agencyDnsName=${agenciesData.dnsName}; Max-Age=${COOKIES_MAX_AGE}${useCrossDomainCookies}`
        setSubmitLoading(false)
        dispatch({ type: LOGIN_SET_INFO, payload: login })
        afterSuccessHandler()
      }
    } catch (error) {
      setSubmitLoading(false)
      setServerError('Server Error')
    }
  }

  return (
    <>
      <section className='step-agent-house-details'>
        <div className='step-agent-house-details-warn'>
          <p>{t('stepAgentHouseDetails.warn')}</p>
        </div>
        <form className='agent-house-form-container' onSubmit={submitHandler}>
          <AgencyHouseDetailsForm
            agencyId={data.agencyId}
            setAgencyId={onChangeHandler('agencyId')}
            agencyName={data.agencyName}
            setAgencyName={onChangeHandler('agencyName')}
            agentEmail={data.agentEmail}
            setAgentEmail={onChangeHandler('agentEmail')}
            phoneNumber={data.phoneNumber}
            setPhoneNumber={onChangeHandler('phoneNumber')}
            enableEditPhoneNumber={!prevSelectedOptionPhone}
            type={data.type}
            setAgencyType={setAgencyTypeHandler}
            inputsDisabled={submitLoading}
            serverError={serverError}
          />
          <div className='agent-house-form-footer'>
            <button
              className='agent-house-details-back-btn'
              onClick={e => {
                e.preventDefault()
                prevStep()
              }}
              disabled={submitLoading || submitSuccess}
            >
              <div>
                <ArrowRightIconSvg />
              </div>
              <div>{t('stepAgentHouseDetails.backBtn')}</div>
            </button>
            <ButtonRounded typeSubmit disabled={submitBtnDisabled()}>
              {t('stepAgentHouseDetails.finishBtn')}
            </ButtonRounded>
          </div>
        </form>
        <RegisterTermsFooter />
      </section>
      {showAgreement && (
        <ModalPaymentAgreement
          closeModal={() => setShowAgreement(false)}
          confirmAgreement={agreeTermsHandler}
          agreementConfirmed={agreedTerms}
        />
      )}
      {showPaymentFrame && (
        <PaymentFrameModal closeModal={() => setShowPaymentFrame(false)} onPaymentSuccess={onPaymentSuccess} />
      )}
    </>
  )
}
export default StepAgentHouseDetails
