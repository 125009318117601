import ReactApexChart from 'react-apexcharts'

const TrendChart = ({ trend, data }) => {
  const chartPrimaryColor = trend ? '#02b64a' : '#fb4659'
  const data1 = []
  const data2 = []
  const chartDevider = Math.round((data.length / 100) * 25)

  // in order for the chart to be displayed in two colors, according to the design
  // if data array consists of one item, fill in resulting arrays with tha same data just for nice displayng
  if (data.length > 2) {
    data.forEach((item, i) => {
      if (i < chartDevider) data1.push([i, item])
      else if (i === chartDevider) {
        data1.push([i, item])
        data2.push([i, item])
      } else data2.push([i, item])
    })
  } else if (data.length === 2) {
    data1.push([0, data[0]])
    data2.push([0, data[0]])
    data1.push([1, data[1]])
    data2.push([1, data[1]])
  } else if (data.length === 1) {
    for (let i = 0; i < 10; i++) {
      if (i < 3) data1.push([i, data[0]])
      else if (i === 3) {
        data1.push([i, data[0]])
        data2.push([i, data[0]])
      } else data2.push([i, data[0]])
    }
  }

  const state = {
    options: {
      chart: {
        //type: 'line',
        toolbar: {
          show: false,
        },
        offsetX: 0,
        offsetY: 0,
        sparkline: {
          enabled: true,
        },
      },
      stroke: {
        colors: ['#D3D4D9', chartPrimaryColor],
        curve: ['smooth', 'smooth'],
        width: [1, 3],
      },
      fill: {
        colors: ['transparent', chartPrimaryColor],
        type: 'gradient',
        gradient: {
          type: 'vertical',
          shadeIntensity: 1,
          gradientToColors: undefined,
          inverseColors: false,
          opacityFrom: 0.7,
          opacityTo: 0,
          stops: [0, 99, 100],
          colorStops: [],
        },
      },
      xaxis: {
        type: 'numeric',
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
          offsetY: 9,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        type: 'numeric',
        labels: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      legend: { show: false },
      grid: {
        show: false,
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 2,
        },
      },
    },
    series: [
      {
        name: 'Series 1',
        data: data1,
      },
      {
        name: 'Series 2',
        data: data2,
      },
    ],
  }

  return <ReactApexChart options={state.options} series={state.series} type='area' height='100%' />
}

export default TrendChart
