import { THEME_TOGGLE } from "../../../types/actionTypes";

export const themeToggle = () => (dispatch, getState) => {
  let { theme } = getState();
  let newTheme = theme.currentTheme === "light" ? "dark" : "light";
  document.cookie = `theme=${newTheme.toString()}`;
  dispatch({
    type: THEME_TOGGLE,
    payload: newTheme,
  });
};
