import {
  POLICY_SELECT,
  POLICY_CHECK,
  POLICY_FIX_REQUEST,
  POLICY_FIX_SUCCESS,
  POLICY_FIX_FAIL,
  POLICY_IGNORE_REQUEST,
  POLICY_IGNORE_SUCCESS,
  POLICY_IGNORE_FAIL,
  POLICY_IRREG_REQUEST,
  POLICY_IRREG_SUCCESS,
  POLICY_IRREG_FAIL,
  POLICY_IRREG_RESET,
  POLICY_SET_CATEGORY_REQUEST,
  POLICY_SET_CATEGORY_SUCCESS,
  POLICY_SET_CATEGORY_FAIL,
  POLICY_SET_CATEGORY_RESET,
  POLICY_TREAT_REQUEST,
  POLICY_TREAT_SUCCESS,
  POLICY_TREAT_FAIL,
  POLICY_TREAT_RESET,
  POLICY_DEACTIVATE_REQUEST,
  POLICY_DEACTIVATE_SUCCESS,
  POLICY_DEACTIVATE_FAIL,
  POLICY_UNCHECK_ALL,
  POLICY_RESET,
  POLICY_AGG_REQUEST,
  POLICY_AGG_SUCCESS,
  POLICY_AGG_FAIL,
  POLICY_AGG_RESET,
  CUSTOMER_CHECK,
  CUSTOMER_UNCHECK_ALL,
  POLICY_HISTORY_REQUEST,
  POLICY_HISTORY_SUCCESS,
  POLICY_HISTORY_FAIL,
  POLICY_HISTORY_RESET,
  POLICY_CANCEL_REQUEST,
  POLICY_CANCEL_SUCCESS,
  POLICY_CANCEL_FAIL,
  POLICY_DETAILS_REQUEST,
  POLICY_DETAILS_SUCCESS,
  POLICY_DETAILS_FAIL,
  POLICY_DETAILS_RESET,
  POLICY_CHECK_GROUP,
} from '../../../types/actionTypes'

const initialState = {
  selectedPolicy: null,
  checkedPolicies: [],
  checkedCustomers: [],
  details: {},
  detailsLoading: true,
  setCategoryLoading: false,
  setCategorySuccess: false,
  paymentsAgg: [],
  paymentsAggLoading: true,
  history: [],
  historyLoading: true,
  irregularities: [],
  irregularitiesLoading: true,
  treatSuccess: false,
  treatLoading: false,
  fixSuccess: false,
  fixLoading: false,
  ignoreSuccess: false,
  ignoreLoading: false,
  deactivateSuccess: false,
  deactivateLoading: false,
  cancelLoading: false,
  cancelId: null,
  cancelError: null,
  updateRequest: false,
  policyDetails: null,
  policyDetailsLoading: true,
}

export const policyReducer = (state = initialState, action) => {
  switch (action.type) {
    case POLICY_SELECT:
      return {
        ...state,
        selectedPolicy: action.payload,
        ...(action.payload && { policyDetails: null }),
      }
    case POLICY_CHECK:
      return {
        ...state,
        checkedPolicies: state.checkedPolicies.includes(action.payload)
          ? state.checkedPolicies.filter(p => p !== action.payload)
          : [...state.checkedPolicies, action.payload],
      }
    case POLICY_CHECK_GROUP:
      const policesToCheck = action.payload
      const prevCheckedPolicies = state.checkedPolicies
      // let checkedPolicies = []
      // // all has been already checked, so uncheck them
      // if (policesToCheck.every(p => prevCheckedPolicies.includes(p))) {
      //   checkedPolicies = prevCheckedPolicies.filter(p => !policesToCheck.includes(p))
      // }
      // // check all, that are not checked yet
      // else {
      //   checkedPolicies = [...prevCheckedPolicies, ...policesToCheck.filter(p => !prevCheckedPolicies.includes(p))]
      // }
      return {
        ...state,
        checkedPolicies: policesToCheck.every(p => prevCheckedPolicies.includes(p)) ? [] : policesToCheck,
      }
    case POLICY_UNCHECK_ALL:
      return {
        ...state,
        checkedPolicies: [],
      }
    case CUSTOMER_CHECK:
      return {
        ...state,
        checkedCustomers: state.checkedCustomers.find(c => c.id === action.payload.id)
          ? state.checkedCustomers.filter(c => c.id !== action.payload.id)
          : [...state.checkedCustomers, action.payload],
      }
    case CUSTOMER_UNCHECK_ALL:
      return {
        ...state,
        checkedCustomers: [],
      }
    case POLICY_AGG_REQUEST:
      return {
        ...state,
        paymentsAggLoading: true,
        paymentsAgg: [],
        updateRequest: false,
      }
    case POLICY_AGG_SUCCESS:
      return {
        ...state,
        paymentsAggLoading: false,
        paymentsAgg: action.payload,
      }
    case POLICY_AGG_FAIL:
    case POLICY_AGG_RESET:
      return {
        ...state,
        paymentsAggLoading: true,
        paymentsAgg: [],
      }
    case POLICY_SET_CATEGORY_REQUEST:
      return {
        ...state,
        setCategoryLoading: true,
        setCategorySuccess: false,
      }
    case POLICY_SET_CATEGORY_SUCCESS:
      return {
        ...state,
        setCategoryLoading: false,
        setCategorySuccess: action.payload.success,
      }
    case POLICY_SET_CATEGORY_FAIL:
    case POLICY_SET_CATEGORY_RESET:
      return {
        ...state,
        setCategoryLoading: false,
        setCategorySuccess: false,
      }
    case POLICY_IRREG_REQUEST:
      return {
        ...state,
        irregularitiesLoading: true,
        irregularities: [],
        updateRequest: false,
      }
    case POLICY_IRREG_SUCCESS:
      return {
        ...state,
        irregularitiesLoading: false,
        irregularities: action.payload,
      }
    case POLICY_IRREG_FAIL:
    case POLICY_IRREG_RESET:
      return {
        ...state,
        irregularitiesLoading: true,
        irregularities: [],
      }
    case POLICY_HISTORY_REQUEST:
      return {
        ...state,
        historyLoading: action.payload,
        updateRequest: false,
      }
    case POLICY_HISTORY_SUCCESS:
      return {
        ...state,
        historyLoading: false,
        history: action.payload,
      }
    case POLICY_HISTORY_FAIL:
    case POLICY_HISTORY_RESET:
      return {
        ...state,
        historyLoading: true,
        history: [],
      }
    case POLICY_TREAT_REQUEST:
      return {
        ...state,
        treatLoading: true,
        treatSuccess: false,
      }
    case POLICY_TREAT_SUCCESS:
      return {
        ...state,
        treatLoading: false,
        treatSuccess: action.payload.success,
        irregularities: state.irregularities.map(irr =>
          irr.id === action.payload.irregularityId && action.payload.success
            ? {
                ...irr,
                treatmentInfo: {
                  agentId: action.payload.agentId,
                  agentName: action.payload.agentName,
                  date: new Date(Date.now()),
                },
              }
            : irr
        ),
        checkedPolicies: action.payload.success ? [] : state.checkedPolicies,
        updateRequest: true,
      }
    case POLICY_TREAT_FAIL:
      return {
        ...state,
        treatLoading: false,
        treatSuccess: false,
      }
    case POLICY_TREAT_RESET:
      return {
        ...state,
        treatSuccess: false,
        treatLoading: false,
      }
    case POLICY_FIX_REQUEST:
      return {
        ...state,
        fixLoading: true,
        fixSuccess: false,
      }
    case POLICY_FIX_SUCCESS:
      return {
        ...state,
        fixLoading: false,
        fixSuccess: action.payload.success,
        irregularities: action.payload.success
          ? state.irregularities.filter(irr => irr.id !== action.payload.irregularityId)
          : state.irregularities,
        updateRequest: true,
      }
    case POLICY_FIX_FAIL:
      return {
        ...state,
        fixLoading: false,
        fixSuccess: false,
      }
    case POLICY_IGNORE_REQUEST:
      return {
        ...state,
        ignoreLoading: true,
        ignoreSuccess: false,
      }
    case POLICY_IGNORE_SUCCESS:
      return {
        ...state,
        ignoreLoading: false,
        ignoreSuccess: action.payload.success,
        irregularities: action.payload.success
          ? state.irregularities.filter(irr => irr.id !== action.payload.irregularityId)
          : state.irregularities,
        updateRequest: true,
      }
    case POLICY_IGNORE_FAIL:
      return {
        ...state,
        ignoreLoading: false,
        ignoreSuccess: false,
      }
    case POLICY_DEACTIVATE_REQUEST:
      return {
        ...state,
        deactivateLoading: true,
        deactivateSuccess: false,
      }
    case POLICY_DEACTIVATE_SUCCESS:
      return {
        ...state,
        deactivateLoading: false,
        deactivateSuccess: action.payload.success,
      }
    case POLICY_DEACTIVATE_FAIL:
      return {
        ...state,
        deactivateLoading: false,
        deactivateSuccess: false,
      }
    case POLICY_CANCEL_REQUEST:
      return {
        ...state,
        cancelLoading: true,
        cancelId: action.payload,
      }
    case POLICY_CANCEL_SUCCESS:
      return {
        ...state,
        cancelLoading: false,
        cancelId: null,
      }
    case POLICY_CANCEL_FAIL:
      return {
        ...state,
        cancelLoading: false,
        cancelId: null,
        cancelError: action.payload,
      }
    case POLICY_DETAILS_REQUEST:
      return {
        ...state,
        policyDetailsLoading: true,
        policyDetails: null,
      }
    case POLICY_DETAILS_SUCCESS:
      return {
        ...state,
        policyDetailsLoading: false,
        policyDetails: action.payload,
      }
    case POLICY_DETAILS_FAIL:
      return {
        ...state,
        policyDetailsLoading: false,
        policyDetails: null,
        policyDetailsError: action.payload,
        selectedPolicy: null,
      }
    case POLICY_DETAILS_RESET:
      return {
        ...state,
        policyDetailsLoading: false,
        policyDetails: null,
        policyDetailsError: null,
        selectedPolicy: null,
      }
    case POLICY_RESET:
      return initialState
    default:
      return state
  }
}
