import { useTranslation } from 'react-i18next'
import MeatBallsMenu from '../../../../components/Common/MeatBallsMenu/MeatBallsMenu'
import './PolicyListItemMenu.scss'
import { useState } from 'react'
import PoliciesAddCustomerContainer from '../PoliciesAddCustomerContainer/PoliciesAddCustomerContainer'

const PolicyListItemMenu = ({ policy, onPolicyAddCustomer }) => {
  const { t } = useTranslation('policies')
  const [showAddPoliciesCustomerContainer, setShowPoliciesAddCustomerContainer] = useState(false)
  let menu = []
  !policy.clientIdNumber &&
    menu.push({
      id: 1,
      text: t('list.menu.belongsCustomer'),
      clickHandler: () => setShowPoliciesAddCustomerContainer(true),
    })

  return (
    <>
      <div className='policy-list-item-menu-wrapper'>
        <MeatBallsMenu positionFixed menuItems={menu} />
      </div>
      {showAddPoliciesCustomerContainer && (
        <PoliciesAddCustomerContainer
          closeContainer={() => setShowPoliciesAddCustomerContainer(false)}
          policies={[policy]}
          onAddCustomers={onPolicyAddCustomer}
        />
      )}
    </>
  )
}

export default PolicyListItemMenu
