import { useTranslation } from 'react-i18next'
import { CloseRounded } from '@mui/icons-material'
import Loader from '../../../../components/Common/Loader/Loader'
import NoData from '../../../../components/Common/NoData/NoData'
import { useEffect, useState } from 'react'
import { getScanHistoryRequest } from '../../../../http/requests/scanRequests'
import ScanHistorySidebarItem from './ScanHistorySidebarItem'
import './ScanHistorySidebar.scss'

const ScanHistorySidebar = ({ closeSidebar, agency }) => {
  const { t } = useTranslation('scan')
  const [dates, setDates] = useState([])
  const [datesLoading, setDatesLoading] = useState(true)

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        setDatesLoading(true)
        const {
          data: { data },
        } = await getScanHistoryRequest({ agency })
        setDates(data)
        setDatesLoading(false)
      } catch (error) {
        setDatesLoading(false)
      }
    }
    fetchHistory()
  }, [])

  return (
    <div className='scan-history-sidebar-overlay'>
      <div className='scan-history-sidebar'>
        <header>
          <p>{t('history.title')}</p>
          <button onClick={closeSidebar}>
            <CloseRounded />
          </button>
        </header>
        <main>
          {datesLoading ? (
            <Loader />
          ) : dates.length === 0 ? (
            <NoData text='No Data' />
          ) : (
            dates.map(d => <ScanHistorySidebarItem key={d.month} item={d} />)
          )}
        </main>
      </div>
    </div>
  )
}

export default ScanHistorySidebar
