import { createPortal } from 'react-dom'
import MobileHeaderBase from '../../../../components/Common/MobileHeaderBase/MobileHeaderBase'
import './MobileModalBaseScreen.scss'

const MobileModalBaseScreen = ({ open, closeModal = () => {}, modalTitle, goBack, children }) => {
  return createPortal(
    <div className='mobile-modal-base-screen-container'>
      <MobileHeaderBase text={modalTitle} goBack={goBack} />
      {children}
    </div>,
    document.body
  )
}

export default MobileModalBaseScreen
