import { useState, useRef } from 'react'
import classNames from 'classnames'
import { rtlDir } from '../../../../i18n'
import './AgencyHouseAnalyticsHierarchyPicker.scss'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'

const AgencyHouseAnalyticsHierarchyPicker = ({ hierarchies, setHierarchy }) => {
  const [showPicker, setShowPicker] = useState(false)
  const btnRef = useRef()
  const overlayRef = useRef()

  const getButtonPostition = () => {
    const { bottom, left, right } = btnRef?.current?.getBoundingClientRect()
    return { left, right, y: bottom }
  }

  return (
    <div className='agency-house-analytics-hierarchy-picker-container'>
      <div className='agency-house-analytics-hierarchy-picker-btn-container' ref={btnRef}>
        <div
          className={classNames('agency-house-analytics-hierarchy-picker-btn')}
          onClick={() => setShowPicker(!showPicker)}
        >
          <span>{hierarchies.find(h => h.selected)?.text}</span>
          {showPicker ? <ArrowDropUp /> : <ArrowDropDown />}
        </div>
      </div>
      {showPicker && (
        <div
          className='agency-house-analytics-hierarchy-picker-overlay'
          ref={overlayRef}
          onClick={e => {
            overlayRef.current === e.target && setShowPicker(false)
          }}
        >
          <div
            className='agency-house-analytics-hierarchy-picker-main-container'
            style={{
              top: getButtonPostition().y + 14 + 'px',
              ...(rtlDir()
                ? { left: getButtonPostition().right + 'px', transform: 'translateX(-100%)' }
                : { left: getButtonPostition().left + 'px' }),
            }}
          >
            <div className='agency-house-analytics-hierarchy-picker-agents-container'>
              {hierarchies.map(({ id, text, selected, value }) => (
                <div
                  key={id}
                  className={classNames('agency-house-analytics-hierarchy-picker-agent-container', {
                    selected,
                  })}
                  onClick={() => {
                    setHierarchy(value)
                    setShowPicker(false)
                  }}
                >
                  {text}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default AgencyHouseAnalyticsHierarchyPicker
