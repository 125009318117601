import ScanCompanyCredentialsForm from '../ScanCompanyCredentialsForm/ScanCompanyCredentialsForm'
import './ScanCompanyUpdateCredentials.scss'

const ScanCompanyUpdateCredentials = ({ authFields, submit, setAuthFields, warn, error, submitDisabled }) => {
  return (
    <div className='scan-company-updated-creds'>
      <ScanCompanyCredentialsForm
        authFields={authFields}
        submit={submit}
        setAuthFields={setAuthFields}
        warn={warn}
        error={error}
        submitDisabled={submitDisabled}
      />
    </div>
  )
}

export default ScanCompanyUpdateCredentials
