import { useState, useEffect, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { AGENT_HOUSE_ANALYTICS_DATES_FILTER_APPLY } from '../../../../types/actionTypes'
import { hebrew, rtlDir } from '../../../../i18n'
import { getMonthName, getMonthNameByNumber } from '../../../../services/getMonthName'
import './AgencyHouseAnalyticsDatePicker.scss'
import { analyticsPeriodView, analyticsPeriodViewType } from '../../../../types/analyticsTypes'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import CancelFilledIconSvg from '../../../../components/Common/SvgIcons/CancelFilledIconSvg/CancelFilledIconSvg'
import NoData from '../../../../components/Common/NoData/NoData'

const AgencyHouseAnalyticsDatePicker = () => {
  const {
    selectedDates: initDates,
    periodViewType,
    filtersLoading,
  } = useSelector(({ agencyHouseAnalytics }) => agencyHouseAnalytics)
  const [showPicker, setShowPicker] = useState(false)
  const [filtersDirty, setFiltersDirty] = useState(false)
  const [period, setPeriod] = useState(periodViewType)
  const [dates, setDates] = useState([])
  const [years, setYears] = useState([])
  const [yearIndex, setYearIndex] = useState(0)
  const [yearFromIndex, setYearFromIndex] = useState(0)
  const [selectedDates, setSelectedDates] = useState([])
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation('agencyHouseAnalytics')
  const btnRef = useRef()
  const overlayRef = useRef()

  const hasFiltersApplied = initDates.some(d => d.selected)

  const getButtonText = () => {
    let text
    if (!hasFiltersApplied || filtersLoading) {
      return t('datesPicker.btnTitle')
    }
    if (initDates.length === 0) {
      return t('datesPicker.btnTitleNoDates')
    }
    text = hebrew() ? analyticsPeriodView[periodViewType].name : analyticsPeriodView[periodViewType].nameEn
    let dateText
    switch (periodViewType) {
      case analyticsPeriodViewType.monthly:
        {
          const { date } = initDates.find(d => d.selected)
          const [year, month] = date.toString().split('-')
          dateText = `${getMonthNameByNumber(month, i18n.language, 'short')} ${year}`
        }
        break
      case analyticsPeriodViewType.quarterly:
      case analyticsPeriodViewType.semiAnnual:
      case analyticsPeriodViewType.other:
        {
          const selectedDates = initDates.filter(d => d.selected)
          const [yearFrom, monthFrom] = selectedDates.at(0)?.date.toString().split('-')
          const [yearTo, monthTo] = selectedDates.at(-1)?.date.toString().split('-')
          if (yearFrom === yearTo) {
            dateText = `${getMonthNameByNumber(monthFrom, i18n.language, 'short')} - ${getMonthNameByNumber(
              monthTo,
              i18n.language,
              'short'
            )} ${yearFrom}`
          } else {
            dateText = `${getMonthNameByNumber(monthFrom, i18n.language, 'short')} ${yearFrom} - ${getMonthNameByNumber(
              monthTo,
              i18n.language,
              'short'
            )} ${yearTo}`
          }
        }
        break
      case analyticsPeriodViewType.annual:
        {
          const { date } = initDates.find(d => d.selected)
          const [year] = date.toString().split('-')
          dateText = `${year}`
        }
        break
      default:
        break
    }
    return text + ', ' + dateText
  }

  const setYearsIndexHandler = (index, yearIndexSetter) => {
    index > -1 && index < years.length && yearIndexSetter(index)
  }

  const selectDate = date => {
    setFiltersDirty(true)
    setSelectedDates([date.toString()])
  }

  const selectFromToDate = ({ date, from = true }) => {
    setFiltersDirty(true)
    let fromDateIndex = dates.findIndex(d => selectedDates.at(0) === d.date.toString())
    let toDateIndex = dates.findLastIndex(d => selectedDates.at(-1) === d.date.toString())
    let dateToSetIndex = dates.findIndex(d => d.date.toString() === date.toString())
    if (
      (fromDateIndex === -1 && toDateIndex === -1) ||
      (fromDateIndex === toDateIndex && dateToSetIndex < fromDateIndex && dateToSetIndex > toDateIndex) ||
      (from && dateToSetIndex > toDateIndex) ||
      (!from && dateToSetIndex < fromDateIndex)
    ) {
      setSelectedDates([dates[dateToSetIndex].date.toString()])
      return
    }
    if (fromDateIndex === -1) {
      if (toDateIndex < dateToSetIndex) {
        ;[toDateIndex, dateToSetIndex] = [dateToSetIndex, toDateIndex]
      }
      setSelectedDates(dates.map(d => d.date.toString()).filter((_, i) => i >= dateToSetIndex && i <= toDateIndex))
      return
    }
    if (toDateIndex === -1) {
      if (fromDateIndex > dateToSetIndex) {
        ;[fromDateIndex, dateToSetIndex] = [dateToSetIndex, fromDateIndex]
      }
      setSelectedDates(dates.map(d => d.date.toString()).filter((_, i) => i >= fromDateIndex && i <= dateToSetIndex))
      return
    }
    setSelectedDates(
      dates
        .map(d => d.date.toString())
        .filter((_, i) => i >= (from ? dateToSetIndex : fromDateIndex) && i <= (from ? toDateIndex : dateToSetIndex))
    )
  }

  const selectFromDate = date => selectFromToDate({ date, from: true })
  const selectToDate = date => selectFromToDate({ date, from: false })

  const selectDates = dates => {
    setFiltersDirty(true)
    setSelectedDates(dates.map(d => d.toString()))
  }

  const getButtonPostition = () => {
    const { bottom, left, right } = btnRef?.current?.getBoundingClientRect()
    return { left, right, y: bottom }
  }

  const applyFilters = () => {
    filtersDirty &&
      dispatch({
        type: AGENT_HOUSE_ANALYTICS_DATES_FILTER_APPLY,
        payload: { selectedDates, period },
      })
    setFiltersDirty(false)
    setShowPicker(false)
  }

  const initialize = useCallback(() => {
    setDates(initDates)
    const initYears = []
    initDates.forEach(({ date }) => {
      const year = +date.slice(0, 4)
      !initYears.includes(year) && initYears.push(year)
    })
    setYears(initYears)
    setYearIndex(initYears.length - 1)
    const fromDate = initDates.find(d => d.selected)
    if (period === analyticsPeriodViewType.other && fromDate) {
      setYearFromIndex(initYears.findIndex(y => y === +fromDate.date.slice(0, 4)))
    } else {
      setYearFromIndex(0)
    }
    setSelectedDates(period === periodViewType ? initDates.filter(d => d.selected).map(({ date }) => date) : [])
  }, [initDates, period, periodViewType])

  useEffect(() => {
    if (showPicker) {
      setPeriod(periodViewType)
    }
  }, [showPicker, periodViewType])

  useEffect(() => {
    if (showPicker && initDates.length > 0) {
      initialize()
    }
  }, [initDates, showPicker, period, initialize])

  return (
    <div className='agency-house-analytics-datepicker-container'>
      <div className='agency-house-analytics-datepicker-btn-container' ref={btnRef}>
        <div
          className={classNames('agency-house-analytics-datepicker-btn', { 'has-applied': hasFiltersApplied })}
          onClick={() => setShowPicker(!showPicker)}
        >
          <span>{getButtonText()}</span>
          {hasFiltersApplied ? (
            <button
              onClick={e => {
                e.stopPropagation()
                dispatch({
                  type: AGENT_HOUSE_ANALYTICS_DATES_FILTER_APPLY,
                  payload: { selectedDates: [], period: analyticsPeriodViewType.other },
                })
              }}
            >
              <CancelFilledIconSvg />
            </button>
          ) : showPicker ? (
            <ArrowDropUp />
          ) : (
            <ArrowDropDown />
          )}
        </div>
      </div>
      {showPicker && (
        <div
          className='agency-house-analytics-datepicker-overlay'
          ref={overlayRef}
          onClick={e => {
            if (overlayRef.current === e.target) {
              setShowPicker(false)
              setFiltersDirty(false)
            }
          }}
        >
          <div
            className='agency-house-analytics-datepicker-main-container'
            style={{
              top: getButtonPostition().y + 14 + 'px',
              ...(rtlDir()
                ? { left: getButtonPostition().left + 'px' }
                : { left: getButtonPostition().right + 'px', transform: 'translateX(-100%)' }),
            }}
          >
            <PeriodPicker selectedPeriod={period} setPeriod={setPeriod} />
            {initDates.length === 0 ? (
              <div className='agency-house-analytics-datepicker-nodata'>
                <NoData text={t('datesPicker.noDates')} />
              </div>
            ) : (
              <div className='agency-house-analytics-datepicker-calendars-container'>
                <main>
                  <button onClick={() => setShowPicker(false)}>
                    <img src='./assets/close-icon-square/close-square.png' alt='cls' />
                  </button>
                  {period === analyticsPeriodViewType.other && (
                    <div className='agency-house-analytics-datepicker-calendar-container'>
                      <YearsPicker
                        period={period}
                        yearIndex={yearFromIndex}
                        years={years}
                        setYearsIndex={index => setYearsIndexHandler(index, setYearFromIndex)}
                      />
                      <DatesPicker
                        yearIndex={yearFromIndex}
                        years={years}
                        period={period}
                        dates={dates}
                        selectedDates={selectedDates}
                        selectDate={selectDate}
                        selectDates={selectDates}
                        lng={i18n.language}
                        selectFromToDate={selectFromDate}
                      />
                    </div>
                  )}
                  <div className='agency-house-analytics-datepicker-calendar-container'>
                    <YearsPicker
                      period={period}
                      yearIndex={yearIndex}
                      years={years}
                      setYearsIndex={index => setYearsIndexHandler(index, setYearIndex)}
                    />
                    <DatesPicker
                      yearIndex={yearIndex}
                      years={years}
                      period={period}
                      dates={dates}
                      selectedDates={selectedDates}
                      selectDates={selectDates}
                      selectDate={selectDate}
                      lng={i18n.language}
                      selectFromToDate={selectToDate}
                    />
                  </div>
                </main>
                <footer>
                  <div>
                    <button onClick={applyFilters}>{t('datesPicker.applyFilterBtn')}</button>
                  </div>
                </footer>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default AgencyHouseAnalyticsDatePicker

const PeriodPicker = ({ selectedPeriod, setPeriod }) => (
  <div className='agency-house-analytics-datepicker-period-container'>
    {Object.values(analyticsPeriodView).map(p => (
      <button
        key={p.type}
        className={classNames({ selected: selectedPeriod === p.type })}
        onClick={() => setPeriod(p.type)}
      >
        {hebrew() ? p.name : p.nameEn}
      </button>
    ))}
  </div>
)

const YearsPicker = ({ years, yearIndex, setYearsIndex, period }) => {
  if (period === analyticsPeriodViewType.annual) {
    return null
  }
  return (
    <section className='agency-house-analytics-datepicker-controls'>
      <div>
        <button
          style={{
            visibility: yearIndex > 0 ? 'visible' : 'hidden',
          }}
          onClick={() => setYearsIndex(yearIndex - 1)}
        >
          <img src='./assets/chevron-right/chevron-right.png' alt='prev' />
        </button>
      </div>
      <h3>{years[yearIndex]}</h3>
      <div>
        <button
          style={{
            visibility: yearIndex < years.length - 1 ? 'visible' : 'hidden',
          }}
          onClick={() => setYearsIndex(yearIndex + 1)}
        >
          <img src='./assets/chevron-left/chevron-left.png' alt='next' />
        </button>
      </div>
    </section>
  )
}

const DatesPicker = ({
  period,
  dates,
  years,
  yearIndex,
  selectedDates,
  selectDate,
  selectFromToDate,
  selectDates,
  lng,
}) => {
  let calendarItems = dates.filter(d => d.date.includes(years[yearIndex].toString()))
  if (period === analyticsPeriodViewType.quarterly || period === analyticsPeriodViewType.semiAnnual) {
    const parts = {}
    calendarItems.forEach(d => {
      const part = Math.floor((d.date.split('-')[1] - 1) / (period === analyticsPeriodViewType.quarterly ? 3 : 6)) + 1
      !parts[part] ? (parts[part] = [d]) : parts[part].push(d)
    })
    calendarItems = Object.entries(parts).map(([k, q]) => ({
      id: k,
      selected: q.map(q => q.date.toString()).every(qd => selectedDates.includes(qd)),
      nodata: q.every(q => !q.hasValue),
      clickHandler: () => selectDates(q.map(q => q.date)),
      dates: q.map(q => q.date),
      text: `${getMonthName(q[0].date, lng, 'short')} - ${getMonthName(q[q.length - 1].date, lng, 'short')}`,
    }))
  } else if (period === analyticsPeriodViewType.annual) {
    const years = {}
    dates.forEach(d => {
      const year = d.date.split('-')[0]
      !years[year] ? (years[year] = [d]) : years[year].push(d)
    })
    calendarItems = Object.entries(years).map(([k, y]) => ({
      id: k,
      selected: y.map(y => y.date.toString()).every(yd => selectedDates.includes(yd)),
      dates: y.map(y => y.date),
      nodata: y.every(y => !y.hasValue),
      clickHandler: () => selectDates(y.map(y => y.date)),
      text: k,
    }))
  } else {
    calendarItems = calendarItems.map(d => ({
      id: d.date,
      selected: selectedDates.includes(d.date.toString()),
      nodata: !d.hasValue,
      clickHandler: () => (period === analyticsPeriodViewType.monthly ? selectDate(d.date) : selectFromToDate(d.date)),
      text: getMonthName(d.date, lng),
    }))
  }
  return (
    <section className='agency-house-analytics-datepicker'>
      {calendarItems.map(({ id, selected, nodata, text, clickHandler }) => (
        <div
          key={id}
          className={classNames('agency-house-analytics-datepicker-month', {
            selected,
            nodata,
          })}
          onClick={clickHandler}
        >
          {text}
        </div>
      ))}
    </section>
  )
}
