import ButtonText from '../../../../components/Common/ButtonText/ButtonText'
import ButtonRounded from '../../../../components/Common/ButtonRounded/ButtonRounded'
import { useTranslation } from 'react-i18next'
import './AddAgencySummaryContainer.scss'

const AddAgencySummaryContainer = ({ addOneMore, finish, children }) => {
  const { t } = useTranslation('agencyHouse')
  return (
    <div className='add-agency-summary-container'>
      <header>
        <h6>{t('addAgencyStep.addAgencySummary.title')}</h6>
        <h5>{t('addAgencyStep.addAgencySummary.subtitle')}</h5>
      </header>
      <main className='add-agency-summary-content'>{children}</main>
      <footer>
        <ButtonText onClick={finish}>{t('addAgencyStep.addAgencySummary.finishBtn')}</ButtonText>
        <ButtonRounded onClick={addOneMore}>{t('addAgencyStep.addAgencySummary.addMoreBtn')}</ButtonRounded>
      </footer>
    </div>
  )
}

export default AddAgencySummaryContainer
