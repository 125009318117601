import { ArrowDownwardRounded, ArrowUpwardRounded } from '@mui/icons-material'
import { customerDataType, customerDataTypeCard } from '../../../../types/customerDataTypes'
import { useTranslation } from 'react-i18next'
import Loader from '../../../../components/Common/Loader/Loader'
import { hebrew } from '../../../../i18n'
import { getMonthName, isNotaDate } from '../../../../services/getMonthName'
import NoData from '../../../../components/Common/NoData/NoData'
import { useState } from 'react'
import CustomerTotalsDetails from './CustomerTotalsDetails'
import { isDeltaAgency } from '../../../login/reducers/loginSelectors'
import { commissionKeySuffix, deltaCommissionSplitView } from '../../../shared/reducers/sharedSelectors'
import { useSelector } from 'react-redux'
import { deltaCommissionViewType } from '../../../../types/deltaCommissionViewTypes'
import './CustomerTotalsCard.scss'

const CustomerTotalsCard = ({ totals = null, dataType, loading, id }) => {
  const [selectedDataType, setSelectedDataType] = useState(null)
  const {
    t,
    i18n: { language: lng },
  } = useTranslation('customer')
  const { commissionView } = useSelector(({ shared }) => shared)

  const { icon, typeKey } = customerDataTypeCard[dataType]
  const commissionDataType = dataType === customerDataType.commission
  const getCommissionKeySuffix = commissionDataType ? commissionKeySuffix() : ''

  if (loading) {
    return (
      <div className='customer-totals-card'>
        <div className='customer-totals-card-content-wrapper'>
          <Loader />
        </div>
      </div>
    )
  }

  if (!totals[typeKey] && !totals[`${typeKey}Payments`]) {
    return (
      <div className='customer-totals-card'>
        <div className='customer-totals-card-content-wrapper'>
          {' '}
          <section>
            <div>
              <div>{icon}</div>
              <p>{t(`totalsCard.${typeKey}Title`)}</p>
            </div>
            <div style={{ marginTop: '60px' }}>
              <NoData text={t('totalsCard.noData')} />
            </div>
          </section>
        </div>
      </div>
    )
  }

  const month = new Date(totals?.month?.split('T')[0])
  const currentYear = month.getFullYear() === new Date().getFullYear()

  return (
    <>
      <div className='customer-totals-card'>
        <div className='customer-totals-card-content-wrapper'>
          <section>
            <div>
              <div className='totals-card-icon'>{icon}</div>
              <div>
                <p>{t(`totalsCard.${typeKey}Title`)}</p>
                {commissionDataType && isDeltaAgency() && (
                  <p>{t(`commView${deltaCommissionSplitView() ? deltaCommissionViewType.agency : commissionView}`)}</p>
                )}
              </div>
            </div>
            <div className='customer-totals-card-value-container'>
              <div>
                <p>{t(`totalsCard.valueTitle`)}</p>
                <FormattedValue
                  value={
                    totals[
                      `${typeKey}${
                        commissionDataType ? (deltaCommissionSplitView() ? 'Agency' : getCommissionKeySuffix) : ''
                      }`
                    ]?.currentValue
                  }
                />
                <FormattedDiffValue
                  value={
                    totals[
                      `${typeKey}${
                        commissionDataType ? (deltaCommissionSplitView() ? 'Agency' : getCommissionKeySuffix) : ''
                      }`
                    ]?.diff
                  }
                />
              </div>
              <OpenDetailsButton
                selectDataType={() =>
                  setSelectedDataType(
                    customerDataType[
                      `${typeKey}${
                        commissionDataType ? (deltaCommissionSplitView() ? 'Agency' : getCommissionKeySuffix) : ''
                      }`
                    ]
                  )
                }
              />
            </div>
            {dataType !== customerDataType.accumulation && (
              <p className='customer-totals-card-value-remark'>{t(`totalsCard.${typeKey}Remark`)}</p>
            )}
          </section>
          {dataType !== customerDataType.accumulation && (
            <section>
              <div className='customer-totals-card-value-container'>
                <div>
                  <p>
                    {t(`totalsCard.incomeInMonth`, {
                      monthName: `${getMonthName(month, lng)}${
                        !currentYear && !isNotaDate(month) ? ' ' + month.getFullYear() : ''
                      }`,
                    })}
                  </p>
                  <FormattedValue
                    value={
                      totals[
                        `${typeKey}Payments${
                          commissionDataType ? (deltaCommissionSplitView() ? 'Agency' : getCommissionKeySuffix) : ''
                        }`
                      ]?.currentValue
                    }
                  />
                  <FormattedDiffValue
                    value={
                      totals[
                        `${typeKey}Payments${
                          commissionDataType ? (deltaCommissionSplitView() ? 'Agency' : getCommissionKeySuffix) : ''
                        }`
                      ]?.diff
                    }
                  />
                </div>
                <OpenDetailsButton
                  selectDataType={() =>
                    setSelectedDataType(
                      customerDataType[
                        `${typeKey}Payments${
                          commissionDataType ? (deltaCommissionSplitView() ? 'Agency' : getCommissionKeySuffix) : ''
                        }`
                      ]
                    )
                  }
                />
              </div>
            </section>
          )}
        </div>
        {commissionDataType && deltaCommissionSplitView() && (
          <div className='customer-totals-card-content-wrapper'>
            <section>
              <div>
                <div className='totals-card-icon'>{icon}</div>
                <div>
                  <p>{t(`totalsCard.${typeKey}Title`)}</p>
                  <p>{t(`commView${deltaCommissionViewType.agent}`)}</p>
                </div>
              </div>
              <div className='customer-totals-card-value-container'>
                <div>
                  <p>{t(`totalsCard.valueTitle`)}</p>
                  <FormattedValue value={totals[`${typeKey}`]?.currentValue} />
                  <FormattedDiffValue value={totals[`${typeKey}`]?.diff} />
                </div>
                <OpenDetailsButton selectDataType={() => setSelectedDataType(dataType)} />
              </div>
              {dataType !== customerDataType.accumulation && (
                <p className='customer-totals-card-value-remark'>{t(`totalsCard.${typeKey}Remark`)}</p>
              )}
            </section>
            {dataType !== customerDataType.accumulation && (
              <section>
                <div className='customer-totals-card-value-container'>
                  <div>
                    <p>
                      {t(`totalsCard.incomeInMonth`, {
                        monthName: `${getMonthName(month, lng)}${
                          !currentYear && !isNotaDate(month) ? ' ' + month.getFullYear() : ''
                        }`,
                      })}
                    </p>
                    <FormattedValue value={totals[`${typeKey}Payments`]?.currentValue} />
                    <FormattedDiffValue value={totals[`${typeKey}Payments`]?.diff} />
                  </div>
                  <OpenDetailsButton
                    selectDataType={() => setSelectedDataType(customerDataType[`${typeKey}Payments`])}
                  />
                </div>
              </section>
            )}
          </div>
        )}
      </div>
      {selectedDataType && (
        <CustomerTotalsDetails closeDetails={() => setSelectedDataType(null)} totalsType={selectedDataType} id={id} />
      )}
    </>
  )
}

export default CustomerTotalsCard

const FormattedValue = ({ value = null }) => (
  <p
    className=''
    style={{
      direction: 'ltr',
      display: 'flex',
      alignItems: 'baseline',
      ...(hebrew() && { justifyContent: 'end' }),
    }}
  >
    {value === null ? (
      '--'
    ) : (
      <>
        {value < 0 && <span>-</span>}
        <span>{Intl.NumberFormat('en-US').format(Math.abs(+value).toFixed(2))}</span>
        <span className='currency'>₪</span>
      </>
    )}
  </p>
)

const FormattedDiffValue = ({ value = null }) => {
  const trend =
    value === 0 || !value ? null : value > 0 ? (
      <ArrowUpwardRounded style={{ color: 'var(--trend-up-color)', fontSize: '32px' }} />
    ) : (
      <ArrowDownwardRounded style={{ color: 'var(--trend-down-color)', fontSize: '32px' }} />
    )

  return !value ? (
    <div className='customer-totals-card-diff'>--</div>
  ) : (
    <div
      dir='ltr'
      className='customer-totals-card-diff'
      style={{
        ...(value !== 0 && { color: `var(--trend-${value > 0 ? 'up' : 'down'}-color)` }),
        ...(hebrew() && { justifyContent: 'end' }),
      }}
    >
      {<span>{value > 0 ? ' + ' : value < 0 ? ' - ' : ' '}</span>}
      <span>
        {new Intl.NumberFormat('en-US', {
          style: 'decimal',
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(Math.abs(value))}
      </span>
      <span className='customer-totals-card-diff-currency'>₪</span>
      {trend}
    </div>
  )
}

const OpenDetailsButton = ({ selectDataType }) => {
  return (
    <button onClick={selectDataType}>
      <img src='./assets/export/Export.png' alt='export' />
    </button>
  )
}
