export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_RESET = 'LOGIN_RESET'
export const LOGOUT = 'LOGOUT'

export const LOGIN_SET_INFO = 'LOGIN_SET_INFO'
export const LOGIN_ENABLE_SUBAGENCYMODE = 'LOGIN_ENABLE_SUBAGENCYMODE'
export const LOGIN_DISABLE_SUBAGENCYMODE = 'LOGIN_DISABLE_SUBAGENCYMODE'

export const LOGIN_GET_CODE_RESET = 'LOGIN_GET_CODE_RESET'

export const LOGIN_GET_AGENCIES_REQUEST = 'LOGIN_GET_AGENCIES_REQUEST'
export const LOGIN_GET_AGENCIES_SUCCESS = 'LOGIN_GET_AGENCIES_SUCCESS'
export const LOGIN_GET_AGENCIES_EMPTY_SUCCESS = 'LOGIN_GET_AGENCIES_EMPTY_SUCCESS'
export const LOGIN_GET_AGENCIES_FAILED = 'LOGIN_GET_AGENCIES_FAILED'

export const LOGIN_SET_SUBSCRIPTION = 'LOGIN_SET_SUBSCRIPTION'

export const LOGIN_CLOSE_UI_TRANSITION_WARN = 'LOGIN_CLOSE_UI_TRANSITION_WARN'
export const LOGIN_AGREE_PRIVACY_TERMS = 'LOGIN_AGREE_PRIVACY_TERMS'

// ----------------------------------------------------------

export const THEME_TOGGLE = 'THEME_TOGGLE'

// --------------------------------------------------------------------------

export const CUSTOMERS_GET_REQUEST = 'CUSTOMERS_GET_REQUEST'
export const CUSTOMERS_GET_SUCCESS = 'CUSTOMERS_GET_SUCCESS'
export const CUSTOMERS_GET_FAIL = 'CUSTOMERS_GET_FAIL'
export const CUSTOMERS_GROUPS_REQUEST = 'CUSTOMERS_GROUPS_REQUEST'
export const CUSTOMERS_GROUPS_SUCCESS = 'CUSTOMERS_GROUPS_SUCCESS'
export const CUSTOMERS_GROUPS_FAIL = 'CUSTOMERS_GROUPS_FAIL'
export const CUSTOMERS_GROUP_EDIT_REQUEST = 'CUSTOMERS_GROUP_EDIT_REQUEST'
export const CUSTOMERS_GROUP_EDIT_SUCCESS = 'CUSTOMERS_GROUP_EDIT_SUCCESS'
export const CUSTOMERS_GROUP_EDIT_FAIL = 'CUSTOMERS_GROUP_EDIT_FAIL'
export const CUSTOMERS_GROUP_EDIT_RESET = 'CUSTOMERS_GROUP_EDIT_RESET'
export const CUSTOMERS_GROUP_DELETE_REQUEST = 'CUSTOMERS_GROUP_DELETE_REQUEST'
export const CUSTOMERS_GROUP_DELETE_SUCCESS = 'CUSTOMERS_GROUP_DELETE_SUCCESS'
export const CUSTOMERS_GROUP_DELETE_FAIL = 'CUSTOMERS_GROUP_DELETE_FAIL'
export const CUSTOMERS_CLEAR_LIST = 'CUSTOMERS_CLEAR_LIST'

export const CUSTOMERS_GET_FILTERS_REQUEST = 'CUSTOMERS_GET_FILTERS_REQUEST'
export const CUSTOMERS_GET_FILTERS_SUCCESS = 'CUSTOMERS_GET_FILTERS_SUCCESS'
export const CUSTOMERS_GET_FILTERS_FAIL = 'CUSTOMERS_GET_FILTERS_FAIL'

export const CUSTOMERS_GET_DASHBOARD_REQUEST = 'CUSTOMERS_GET_DASHBOARD_REQUEST'
export const CUSTOMERS_GET_DASHBOARD_SUCCESS = 'CUSTOMERS_GET_DASHBOARD_SUCCESS'
export const CUSTOMERS_GET_DASHBOARD_FAIL = 'CUSTOMERS_GET_DASHBOARD_FAIL'

export const CUSTOMERS_FILTER_BY_COMPANIES = 'CUSTOMERS_FILTER_BY_COMPANIES'
export const CUSTOMERS_FILTER_BY_CATEGORIES = 'CUSTOMERS_FILTER_BY_CATEGORIES'
export const CUSTOMERS_FILTER_BY_CATEGORY = 'CUSTOMERS_FILTER_BY_CATEGORY'
export const CUSTOMERS_FILTER_BY_GROUP_TYPE = 'CUSTOMERS_FILTER_BY_GROUP_TYPE'
export const CUSTOMERS_FILTER_BY_GROUP = 'CUSTOMERS_FILTER_BY_GROUP'
export const CUSTOMERS_FILTER_SET_ADVANCED = 'CUSTOMERS_FILTER_SET_ADVANCED'
export const CUSTOMERS_FILTER_BY_ADVANCED = 'CUSTOMERS_FILTER_BY_ADVANCED'
export const CUSTOMERS_FILTERS_CLEAR = 'CUSTOMERS_FILTERS_CLEAR'
export const CUSTOMERS_SET_PAGE_SIZE = 'CUSTOMERS_SET_PAGE_SIZE'
export const CUSTOMERS_SET_PAGE = 'CUSTOMERS_SET_PAGE'
export const CUSTOMERS_SET_SEARCH = 'CUSTOMERS_SET_SEARCH'
export const CUSTOMERS_SET_SORT_BY = 'CUSTOMERS_SET_SORT_BY'
export const CUSTOMERS_SET_GROUPS_SORT_BY = 'CUSTOMERS_SET_GROUPS_SORT_BY'
export const CUSTOMERS_COLLAPSE_GROUP = 'CUSTOMERS_COLLAPSE_GROUP'
export const CUSTOMERS_COLLAPSE_CUSTOMERS_GROUP = 'CUSTOMERS_COLLAPSE_CUSTOMERS_GROUP'
export const CUSTOMERS_RESET = 'CUSTOMERS_RESET'

// ------------------------------------------------

export const CUSTOMER_INFO_REQUEST = 'CUSTOMER_INFO_REQUEST'
export const CUSTOMER_INFO_SUCCESS = 'CUSTOMER_INFO_SUCCESS'
export const CUSTOMER_INFO_FAIL = 'CUSTOMER_INFO_FAIL'
export const CUSTOMER_INFO_CLEAR = 'CUSTOMER_INFO_CLEAR'

export const CUSTOMER_CATEGORIES_REQUEST = 'CUSTOMER_CATEGORIES_REQUEST'
export const CUSTOMER_CATEGORIES_SUCCESS = 'CUSTOMER_CATEGORIES_SUCCESS'
export const CUSTOMER_CATEGORIES_FAIL = 'CUSTOMER_CATEGORIES_FAIL'
export const CUSTOMER_ALL_CATEGORIES_RESET = 'CUSTOMER_ALL_CATEGORIES_RESET'

export const CUSTOMER_UPDATE_INFO_REQUEST = 'CUSTOMER_UPDATE_INFO_REQUEST'
export const CUSTOMER_UPDATE_INFO_SUCCESS = 'CUSTOMER_UPDATE_INFO_SUCCESS'
export const CUSTOMER_UPDATE_INFO_FAIL = 'CUSTOMER_UPDATE_INFO_FAIL'

export const CUSTOMER_GET_GROUP_TYPES_REQUEST = 'CUSTOMER_GET_GROUP_TYPES_REQUEST'
export const CUSTOMER_GET_GROUP_TYPES_SUCCESS = 'CUSTOMER_GET_GROUP_TYPES_SUCCESS'
export const CUSTOMER_GET_GROUP_TYPES_FAIL = 'CUSTOMER_GET_GROUP_TYPES_FAIL'

export const CUSTOMER_GET_GROUP_REQUEST = 'CUSTOMER_GET_GROUP_REQUEST'
export const CUSTOMER_GET_GROUP_SUCCESS = 'CUSTOMER_GET_GROUP_SUCCESS'
export const CUSTOMER_GET_GROUP_FAIL = 'CUSTOMER_GET_GROUP_FAIL'

export const CUSTOMER_GROUPS_REQUEST = 'CUSTOMER_GROUPS_REQUEST'
export const CUSTOMER_GROUPS_SUCCESS = 'CUSTOMER_GROUPS_SUCCESS'
export const CUSTOMER_GROUPS_FAIL = 'CUSTOMER_GROUPS_FAIL'

export const CUSTOMER_ADD_MEMBER_REQUEST = 'CUSTOMER_ADD_MEMBER_REQUEST'
export const CUSTOMER_ADD_MEMBER_SUCCESS = 'CUSTOMER_ADD_MEMBER_SUCCESS'
export const CUSTOMER_ADD_MEMBER_FAIL = 'CUSTOMER_ADD_MEMBER_FAIL'
export const CUSTOMER_ADD_MEMBER_RESET = 'CUSTOMER_ADD_MEMBER_RESET'

export const CUSTOMER_REMOVE_MEMBER_REQUEST = 'CUSTOMER_REMOVE_MEMBER_REQUEST'
export const CUSTOMER_REMOVE_MEMBER_SUCCESS = 'CUSTOMER_REMOVE_MEMBER_SUCCESS'
export const CUSTOMER_REMOVE_MEMBER_FAIL = 'CUSTOMER_REMOVE_MEMBER_FAIL'

export const CUSTOMER_SEARCH_REQUEST = 'CUSTOMER_SEARCH_REQUEST'
export const CUSTOMER_SEARCH_SUCCESS = 'CUSTOMER_SEARCH_SUCCESS'
export const CUSTOMER_SEARCH_FAIL = 'CUSTOMER_SEARCH_FAIL'
export const CUSTOMER_SEARCH_RESET = 'CUSTOMER_SEARCH_RESET'

export const CUSTOMER_AGENTS_REQUEST = 'CUSTOMER_AGENTS_REQUEST'
export const CUSTOMER_AGENTS_SUCCESS = 'CUSTOMER_AGENTS_SUCCESS'
export const CUSTOMER_AGENTS_FAIL = 'CUSTOMER_AGENTS_FAIL'

export const CUSTOMER_TREAT_REQUEST = 'CUSTOMER_TREAT_REQUEST'
export const CUSTOMER_TREAT_SUCCESS = 'CUSTOMER_TREAT_SUCCESS'
export const CUSTOMER_TREAT_FAIL = 'CUSTOMER_TREAT_FAIL'
export const CUSTOMER_TREAT_RESET = 'CUSTOMER_TREAT_RESET'

export const CUSTOMER_IGNORE_REQUEST = 'CUSTOMER_IGNORE_REQUEST'
export const CUSTOMER_IGNORE_SUCCESS = 'CUSTOMER_IGNORE_SUCCESS'
export const CUSTOMER_IGNORE_FAIL = 'CUSTOMER_IGNORE_FAIL'

// ------------------------------------------------

export const DOWNLOAD_COMPANIES_REQUEST = 'DOWNLOAD_COMPANIES_REQUEST'
export const DOWNLOAD_COMPANIES_SUCCESS = 'DOWNLOAD_COMPANIES_SUCCESS'
export const DOWNLOAD_COMPANIES_FAIL = 'DOWNLOAD_COMPANIES_FAIL'

export const DOWNLOAD_COMPANY_REQUEST = 'DOWNLOAD_COMPANY_REQUEST'
export const DOWNLOAD_COMPANY_SUCCESS = 'DOWNLOAD_COMPANY_SUCCESS'
export const DOWNLOAD_COMPANY_FAIL = 'DOWNLOAD_COMPANY_FAIL'

export const DOWNLOAD_UPDATE_COMPANY_REQUEST = 'DOWNLOAD_UPDATE_COMPANY_REQUEST'
export const DOWNLOAD_UPDATE_COMPANY_SUCCESS = 'DOWNLOAD_UPDATE_COMPANY_SUCCESS'
export const DOWNLOAD_UPDATE_COMPANY_FAIL = 'DOWNLOAD_UPDATE_COMPANY_FAIL'

export const DOWNLOAD_SCAN_REQUEST = 'DOWNLOAD_SCAN_REQUEST'
export const DOWNLOAD_SCAN_SUCCESS = 'DOWNLOAD_SCAN_SUCCESS'
export const DOWNLOAD_SCAN_FAIL = 'DOWNLOAD_SCAN_FAIL'
export const DOWNLOAD_SCAN_STOP = 'DOWNLOAD_SCAN_STOP'
export const DOWNLOAD_SCAN_PAUSE = 'DOWNLOAD_SCAN_PAUSE'

export const DOWNLOAD_SCAN_SINGLE = 'DOWNLOAD_SCAN_SINGLE'
export const DOWNLOAD_SCAN_ELEMENTARY = 'DOWNLOAD_SCAN_ELEMENTARY'

export const DOWNLOAD_SET_AUTH = 'DOWNLOAD_SET_AUTH'
export const DOWNLOAD_SET_AGENCY_DBNAME = 'DOWNLOAD_SET_AGENCY_DBNAME'

export const DOWNLOAD_SET_SCAN_STATUS = 'DOWNLOAD_SET_SCAN_STATUS'
export const DOWNLOAD_SET_SCAN_PROGRESS = 'DOWNLOAD_SET_SCAN_PROGRESS'
export const DOWNLOAD_SET_SCAN_RECORDS = 'DOWNLOAD_SET_SCAN_RECORDS'

export const DOWNLOAD_HISTORY_REQUEST = 'DOWNLOAD_HISTORY_REQUEST'
export const DOWNLOAD_HISTORY_SUCCESS = 'DOWNLOAD_HISTORY_SUCCESS'
export const DOWNLOAD_HISTORY_FAIL = 'DOWNLOAD_HISTORY_FAIL'

export const DOWNLOAD_REMOVE_COMPANY_REQUEST = 'DOWNLOAD_REMOVE_COMPANY_REQUEST'
export const DOWNLOAD_REMOVE_COMPANY_SUCCESS = 'DOWNLOAD_REMOVE_COMPANY_SUCCESS'
export const DOWNLOAD_REMOVE_COMPANY_FAIL = 'DOWNLOAD_REMOVE_COMPANY_FAIL'

export const DOWNLOAD_EDIT_COMPANY_CREDS = 'DOWNLOAD_EDIT_COMPANY_CREDS'
export const DOWNLOAD_REMOVE_SCANNED = 'DOWNLOAD_REMOVE_SCANNED'
export const DOWNLOAD_DESELECT_COMPANY = 'DOWNLOAD_DESELECT_COMPANY'

export const DOWNLOAD_RESET = 'DOWNLOAD_RESET'

// --------------------------------------

export const PROFILE_FIELDS_REQUEST = 'PROFILE_FIELDS_REQUEST'
export const PROFILE_FIELDS_SUCCESS = 'PROFILE_FIELDS_SUCCESS'
export const PROFILE_FIELDS_FAIL = 'PROFILE_FIELDS_FAIL'

export const PROFILE_SET_FIELDS_REQUEST = 'PROFILE_SET_FIELDS_REQUEST'
export const PROFILE_SET_FIELDS_SUCCESS = 'PROFILE_SET_FIELDS_SUCCESS'
export const PROFILE_SET_FIELDS_FAIL = 'PROFILE_SET_FIELDS_FAIL'

export const PROFILE_COMPANIES_REQUEST = 'PROFILE_COMPANIES_REQUEST'
export const PROFILE_COMPANIES_SUCCESS = 'PROFILE_COMPANIES_SUCCESS'
export const PROFILE_COMPANIES_FAIL = 'PROFILE_COMPANIES_FAIL'

export const PROFILE_GET_COMPANIES_REQUEST = 'PROFILE_GET_COMPANIES_REQUEST'
export const PROFILE_GET_COMPANIES_SUCCESS = 'PROFILE_GET_COMPANIES_SUCCESS'
export const PROFILE_GET_COMPANIES_FAIL = 'PROFILE_GET_COMPANIES_FAIL'
export const PROFILE_GET_COMPANIES_CLEAR = 'PROFILE_GET_COMPANIES_CLEAR'

export const PROFILE_ADD_COMPANIES_REQUEST = 'PROFILE_ADD_COMPANIES_REQUEST'
export const PROFILE_ADD_COMPANIES_SUCCESS = 'PROFILE_ADD_COMPANIES_SUCCESS'
export const PROFILE_ADD_COMPANIES_FAIL = 'PROFILE_ADD_COMPANIES_FAIL'

export const PROFILE_EDIT_COMPANY_REQUEST = 'PROFILE_EDIT_COMPANY_REQUEST'
export const PROFILE_EDIT_COMPANY_SUCCESS = 'PROFILE_EDIT_COMPANY_SUCCESS'
export const PROFILE_EDIT_COMPANY_FAIL = 'PROFILE_EDIT_COMPANY_FAIL'
export const PROFILE_EDIT_COMPANY_RESET = 'PROFILE_EDIT_COMPANY_RESET'

export const PROFILE_DELETE_COMPANY_REQUEST = 'PROFILE_DELETE_COMPANY_REQUEST'
export const PROFILE_DELETE_COMPANY_SUCCESS = 'PROFILE_DELETE_COMPANY_SUCCESS'
export const PROFILE_DELETE_COMPANY_FAIL = 'PROFILE_DELETE_COMPANY_FAIL'

export const PROFILE_DETAILS_REQUEST = 'PROFILE_DETAILS_REQUEST'
export const PROFILE_DETAILS_SUCCESS = 'PROFILE_DETAILS_SUCCESS'
export const PROFILE_DETAILS_FAIL = 'PROFILE_DETAILS_FAIL'

export const PROFILE_EMPLOYEES_REQUEST = 'PROFILE_EMPLOYEES_REQUEST'
export const PROFILE_EMPLOYEES_SUCCESS = 'PROFILE_EMPLOYEES_SUCCESS'
export const PROFILE_EMPLOYEES_FAIL = 'PROFILE_EMPLOYEES_FAIL'

export const PROFILE_EMPLOYEES_ADD_REQUEST = 'PROFILE_EMPLOYEES_ADD_REQUEST'
export const PROFILE_EMPLOYEES_ADD_SUCCESS = 'PROFILE_EMPLOYEES_ADD_SUCCESS'
export const PROFILE_EMPLOYEES_ADD_FAIL = 'PROFILE_EMPLOYEES_ADD_FAIL'
export const PROFILE_EMPLOYEES_ADD_RESET = 'PROFILE_EMPLOYEES_ADD_RESET'

export const PROFILE_EMPLOYEES_EDIT_REQUEST = 'PROFILE_EMPLOYEES_EDIT_REQUEST'
export const PROFILE_EMPLOYEES_EDIT_SUCCESS = 'PROFILE_EMPLOYEES_EDIT_SUCCESS'
export const PROFILE_EMPLOYEES_EDIT_FAIL = 'PROFILE_EMPLOYEES_EDIT_FAIL'
export const PROFILE_EMPLOYEES_EDIT_RESET = 'PROFILE_EMPLOYEES_EDIT_RESET'

export const PROFILE_EMPLOYEES_DELETE_REQUEST = 'PROFILE_EMPLOYEES_DELETE_REQUEST'
export const PROFILE_EMPLOYEES_DELETE_SUCCESS = 'PROFILE_EMPLOYEES_DELETE_SUCCESS'
export const PROFILE_EMPLOYEES_DELETE_FAIL = 'PROFILE_EMPLOYEES_DELETE_FAIL'

export const PROFILE_EMPLOYEES_SEND_REQUEST = 'PROFILE_EMPLOYEES_SEND_REQUEST'
export const PROFILE_EMPLOYEES_SEND_SUCCESS = 'PROFILE_EMPLOYEES_SEND_SUCCESS'
export const PROFILE_EMPLOYEES_SEND_FAIL = 'PROFILE_EMPLOYEES_SEND_FAIL'

// --------------------------------------

export const POLICIES_FILTERS_REQUEST = 'POLICIES_FILTERS_REQUEST'
export const POLICIES_FILTERS_SUCCESS = 'POLICIES_FILTERS_SUCCESS'
export const POLICIES_FILTERS_FAIL = 'POLICIES_FILTERS_FAIL'

export const POLICIES_SIGNALS_FILTER_APPLY = 'POLICIES_SIGNALS_FILTER_APPLY'

// --------------------------------------

export const POLICY_SELECT = 'POLICY_SELECT'
export const POLICY_CHECK = 'POLICY_CHECK'
export const POLICY_CHECK_GROUP = 'POLICY_CHECK_GROUP'
export const POLICY_UNCHECK_ALL = 'POLICY_UNCHECK_ALL'

export const CUSTOMER_CHECK = 'CUSTOMER_CHECK'
export const CUSTOMER_UNCHECK_ALL = 'CUSTOMER_UNCHECK_ALL'

export const POLICY_AGG_REQUEST = 'POLICY_AGG_REQUEST'
export const POLICY_AGG_SUCCESS = 'POLICY_AGG_SUCCESS'
export const POLICY_AGG_FAIL = 'POLICY_AGG_FAIL'
export const POLICY_AGG_RESET = 'POLICY_AGG_RESET'

export const POLICY_IRREG_REQUEST = 'POLICY_IRREG_REQUEST'
export const POLICY_IRREG_SUCCESS = 'POLICY_IRREG_SUCCESS'
export const POLICY_IRREG_FAIL = 'POLICY_IRREG_FAIL'
export const POLICY_IRREG_RESET = 'POLICY_IRREG_RESET'

export const POLICY_HISTORY_REQUEST = 'POLICY_HISTORY_REQUEST'
export const POLICY_HISTORY_SUCCESS = 'POLICY_HISTORY_SUCCESS'
export const POLICY_HISTORY_FAIL = 'POLICY_HISTORY_FAIL'
export const POLICY_HISTORY_RESET = 'POLICY_HISTORY_RESET'

export const POLICY_CANCEL_REQUEST = 'POLICY_CANCEL_REQUEST'
export const POLICY_CANCEL_SUCCESS = 'POLICY_CANCEL_SUCCESS'
export const POLICY_CANCEL_FAIL = 'POLICY_CANCEL_FAIL'

export const POLICY_SET_CATEGORY_REQUEST = 'POLICY_SET_CATEGORY_REQUEST'
export const POLICY_SET_CATEGORY_SUCCESS = 'POLICY_SET_CATEGORY_SUCCESS'
export const POLICY_SET_CATEGORY_FAIL = 'POLICY_SET_CATEGORY_FAIL'
export const POLICY_SET_CATEGORY_RESET = 'POLICY_SET_CATEGORY_RESET'

export const POLICY_TREAT_REQUEST = 'POLICY_TREAT_REQUEST'
export const POLICY_TREAT_SUCCESS = 'POLICY_TREAT_SUCCESS'
export const POLICY_TREAT_FAIL = 'POLICY_TREAT_FAIL'
export const POLICY_TREAT_RESET = 'POLICY_TREAT_RESET'

export const POLICY_FIX_REQUEST = 'POLICY_FIX_REQUEST'
export const POLICY_FIX_SUCCESS = 'POLICY_FIX_SUCCESS'
export const POLICY_FIX_FAIL = 'POLICY_FIX_FAIL'

export const POLICY_IGNORE_REQUEST = 'POLICY_IGNORE_REQUEST'
export const POLICY_IGNORE_SUCCESS = 'POLICY_IGNORE_SUCCESS'
export const POLICY_IGNORE_FAIL = 'POLICY_IGNORE_FAIL'

export const POLICY_DEACTIVATE_REQUEST = 'POLICY_DEACTIVATE_REQUEST'
export const POLICY_DEACTIVATE_SUCCESS = 'POLICY_DEACTIVATE_SUCCESS'
export const POLICY_DEACTIVATE_FAIL = 'POLICY_DEACTIVATE_FAIL'

export const POLICY_DETAILS_REQUEST = 'POLICY_DETAILS_REQUEST'
export const POLICY_DETAILS_SUCCESS = 'POLICY_DETAILS_SUCCESS'
export const POLICY_DETAILS_FAIL = 'POLICY_DETAILS_FAIL'
export const POLICY_DETAILS_RESET = 'POLICY_DETAILS_RESET'

export const POLICY_RESET = 'POLICY_RESET'

// --------------------------------------

export const WORKSTATION_TASKS_REQUEST = 'WORKSTATION_TASKS_REQUEST'
export const WORKSTATION_TASKS_SUCCESS = 'WORKSTATION_TASKS_SUCCESS'
export const WORKSTATION_TASKS_FAIL = 'WORKSTATION_TASKS_FAIL'

export const WORKSTATION_TASKS_SEARCH = 'WORKSTATION_TASKS_SEARCH'
export const WORKSTATION_SET_SORT = 'WORKSTATION_SET_SORT'

export const WORKSTATION_FILTERS_REQUEST = 'WORKSTATION_FILTERS_REQUEST'
export const WORKSTATION_FILTERS_SUCCESS = 'WORKSTATION_FILTERS_SUCCESS'
export const WORKSTATION_FILTERS_FAIL = 'WORKSTATION_FILTERS_FAIL'
export const WORKSTATION_FILTER_APPLY = 'WORKSTATION_FILTER_APPLY'
export const WORKSTATION_FILTERS_CLEAR = 'WORKSTATION_FILTERS_CLEAR'
export const WORKSTATION_SET_FROM = 'WORKSTATION_SET_FROM'
export const WORKSTATION_SET_TO = 'WORKSTATION_SET_TO'
export const WORKSTATION_RESET_FROMTO = 'WORKSTATION_RESET_FROMTO'

export const WORKSTATION_SET_TASK_STATUS_REQUEST = 'WORKSTATION_SET_TASK_STATUS_REQUEST'
export const WORKSTATION_SET_TASK_STATUS_SUCCESS = 'WORKSTATION_SET_TASK_STATUS_SUCCESS'
export const WORKSTATION_SET_TASK_STATUS_FAIL = 'WORKSTATION_SET_TASK_STATUS_FAIL'

export const WORKSTATION_TASK_AGENT_REQUEST = 'WORKSTATION_TASK_AGENT_REQUEST'
export const WORKSTATION_TASK_AGENT_SUCCESS = 'WORKSTATION_TASK_AGENT_SUCCESS'
export const WORKSTATION_TASK_AGENT_FAIL = 'WORKSTATION_TASK_AGENT_FAIL'
export const WORKSTATION_TASK_AGENT_RESET = 'WORKSTATION_TASK_AGENT_RESET'

export const WORKSTATION_RESET = 'WORKSTATION_RESET'

// --------------------------------------

export const LANDING_EMAIL_REQUEST = 'LANDING_EMAIL_REQUEST'
export const LANDING_EMAIL_SUCCESS = 'LANDING_EMAIL_SUCCESS'
export const LANDING_EMAIL_FAIL = 'LANDING_EMAIL_FAIL'
export const LANDING_INFO_SET = 'LANDING_INFO_SET'

// --------------------------------------

export const AGENT_HOUSE_APPLY_PAYMENT_FILTER = 'AGENT_HOUSE_APPLY_PAYMENT_FILTER'
export const AGENT_HOUSE_APPLY_STATUS_FILTER = 'AGENT_HOUSE_APPLY_STATUS_FILTER'
export const AGENT_HOUSE_GROUPS_REQUEST = 'AGENT_HOUSE_GROUPS_REQUEST'
export const AGENT_HOUSE_GROUPS_SUCCESS = 'AGENT_HOUSE_GROUPS_SUCCESS'
export const AGENT_HOUSE_GROUPS_FAIL = 'AGENT_HOUSE_GROUPS_FAIL'
export const AGENT_HOUSE_AGENCIES_REQUEST = 'AGENT_HOUSE_AGENCIES_REQUEST'
export const AGENT_HOUSE_AGENCIES_SUCCESS = 'AGENT_HOUSE_AGENCIES_SUCCESS'
export const AGENT_HOUSE_AGENCIES_FAIL = 'AGENT_HOUSE_AGENCIES_FAIL'
export const AGENT_HOUSE_AGENCIES_RESET = 'AGENT_HOUSE_AGENCIES_RESET'
export const AGENT_HOUSE_GROUPS_SELECT = 'AGENT_HOUSE_GROUPS_SELECT'

export const AGENT_HOUSE_ADD_GROUP_REQUEST = 'AGENT_HOUSE_ADD_GROUP_REQUEST'
export const AGENT_HOUSE_ADD_GROUP_SUCCESS = 'AGENT_HOUSE_ADD_GROUP_SUCCESS'
export const AGENT_HOUSE_ADD_GROUP_FAIL = 'AGENT_HOUSE_ADD_GROUP_FAIL'
export const AGENT_HOUSE_ADD_GROUP_RESET = 'AGENT_HOUSE_ADD_GROUP_RESET'

export const AGENT_HOUSE_UPDATE_GROUP_REQUEST = 'AGENT_HOUSE_UPDATE_GROUP_REQUEST'
export const AGENT_HOUSE_UPDATE_GROUP_SUCCESS = 'AGENT_HOUSE_UPDATE_GROUP_SUCCESS'
export const AGENT_HOUSE_UPDATE_FAVORITE_GROUP_SUCCESS = 'AGENT_HOUSE_UPDATE_FAVORITE_GROUP_SUCCESS'
export const AGENT_HOUSE_UPDATE_GROUP_FAIL = 'AGENT_HOUSE_UPDATE_GROUP_FAIL'
export const AGENT_HOUSE_UPDATE_GROUP_RESET = 'AGENT_HOUSE_UPDATE_GROUP_RESET'

export const AGENT_HOUSE_DELETE_GROUP_REQUEST = 'AGENT_HOUSE_DELETE_GROUP_REQUEST'
export const AGENT_HOUSE_DELETE_GROUP_SUCCESS = 'AGENT_HOUSE_DELETE_GROUP_SUCCESS'
export const AGENT_HOUSE_DELETE_GROUP_FAIL = 'AGENT_HOUSE_DELETE_GROUP_FAIL'

export const AGENT_HOUSE_RESET = 'AGENT_HOUSE_RESET'

// --------------------------------------

export const AGENT_HOUSE_DOWNLOAD_AGENCIES_REQUEST = 'AGENT_HOUSE_DOWNLOAD_AGENCIES_REQUEST'
export const AGENT_HOUSE_DOWNLOAD_AGENCIES_SUCCESS = 'AGENT_HOUSE_DOWNLOAD_AGENCIES_SUCCESS'
export const AGENT_HOUSE_DOWNLOAD_AGENCIES_FAIL = 'AGENT_HOUSE_DOWNLOAD_AGENCIES_FAIL'
export const AGENT_HOUSE_DOWNLOAD_AGENCIES_SHOW_COMPANIES = 'AGENT_HOUSE_DOWNLOAD_AGENCIES_SHOW_COMPANIES'
export const AGENT_HOUSE_DOWNLOAD_AGENCIES_SHOW_GROUPS = 'AGENT_HOUSE_DOWNLOAD_AGENCIES_SHOW_GROUPS'
export const AGENT_HOUSE_DOWNLOAD_HISTORY_REQUEST = 'AGENT_HOUSE_DOWNLOAD_HISTORY_REQUEST'
export const AGENT_HOUSE_DOWNLOAD_HISTORY_SUCCESS = 'AGENT_HOUSE_DOWNLOAD_HISTORY_SUCCESS'
export const AGENT_HOUSE_DOWNLOAD_HISTORY_FAIL = 'AGENT_HOUSE_DOWNLOAD_HISTORY_FAIL'
export const AGENT_HOUSE_DOWNLOAD_HISTORY_SHOW_AGENCIES = 'AGENT_HOUSE_DOWNLOAD_HISTORY_SHOW_AGENCIES'
export const AGENT_HOUSE_DOWNLOAD_HISTORY_SHOW_COMPANIES = 'AGENT_HOUSE_DOWNLOAD_HISTORY_SHOW_COMPANIES'

export const AGENT_HOUSE_DOWNLOAD_CHECK_RESULTS = 'AGENT_HOUSE_DOWNLOAD_CHECK_RESULTS'

export const AGENT_HOUSE_DOWNLOAD_RESET = 'AGENT_HOUSE_DOWNLOAD_RESET'

// --------------------------------------

export const AGENT_HOUSE_ANALYTICS_SET_DATATYPE = 'AGENT_HOUSE_ANALYTICS_SET_DATATYPE'
export const AGENT_HOUSE_ANALYTICS_SET_LISTTYPE = 'AGENT_HOUSE_ANALYTICS_SET_LISTTYPE'
export const AGENT_HOUSE_ANALYTICS_SET_LISTVIEWTYPE = 'AGENT_HOUSE_ANALYTICS_SET_LISTVIEWTYPE'
export const AGENT_HOUSE_ANALYTICS_FILTERS_REQUEST = 'AGENT_HOUSE_ANALYTICS_FILTERS_REQUEST'
export const AGENT_HOUSE_ANALYTICS_FILTERS_SUCCESS = 'AGENT_HOUSE_ANALYTICS_FILTERS_SUCCESS'
export const AGENT_HOUSE_ANALYTICS_FILTERS_FAIL = 'AGENT_HOUSE_ANALYTICS_FILTERS_FAIL'
export const AGENT_HOUSE_ANALYTICS_DATES_FILTER_APPLY = 'AGENT_HOUSE_ANALYTICS_DATES_FILTER_APPLY'
export const AGENT_HOUSE_ANALYTICS_AGENCIES_FILTER_APPLY = 'AGENT_HOUSE_ANALYTICS_AGENCIES_FILTER_APPLY'
export const AGENT_HOUSE_ANALYTICS_CATEGORIES_FILTER_APPLY = 'AGENT_HOUSE_ANALYTICS_CATEGORIES_FILTER_APPLY'
export const AGENT_HOUSE_ANALYTICS_COMPANIES_FILTER_APPLY = 'AGENT_HOUSE_ANALYTICS_COMPANIES_FILTER_APPLY'
export const AGENT_HOUSE_ANALYTICS_FILTERS_CLEAR = 'AGENT_HOUSE_ANALYTICS_FILTERS_CLEAR'
