import { useState } from 'react'
import { formatAsCurrency, formatAsPercent, formatAsShortDate } from '../../../../utils/formatAs'
import { cn } from '../../../../utils/stylesUtils'
import PolicyCard from '../PolicyCard/PolicyCard'
import PolicyListItemCard from '../PolicyListItemCard/PolicyListItemCard'
import PolicyStatusChip from '../../../../components/Common/PolicyStatusChip/PolicyStatusChip'
import { entityStatusType } from '../../../../types/entityStatusType'
import { hebrew } from '../../../../i18n'
import EntityStatusChip from '../../../../components/Common/EntityStatusChip/EntityStatusChip'
import './PoliciesLostListItem.scss'
import { useTranslation } from 'react-i18next'
import PoliciesSyncIcon from '../PoliciesSyncIcon/PoliciesSyncIcon'
import { commissionKeySuffix, deltaCommissionSplitView } from '../../../shared/reducers/sharedSelectors'
import PolicyListItemMenu from '../PolicyListItemMenu/PolicyListItemMenu'

const PoliciesLostListItem = ({ policy, classes, selectPolicy, selected, onPolicyAddCustomer }) => {
  const [showPolicyCard, setShowPolicyCard] = useState(false)
  const { t } = useTranslation('policy')
  const keySuffix = commissionKeySuffix()

  return (
    <>
      <PolicyListItemCard
        classes={cn(classes, 'policies-lost-list-item', {
          outdated: policy.isOutdated,
        })}
        onClick={() => setShowPolicyCard(true)}
        selectable
        onSelect={() => selectPolicy(policy.id)}
        selected={selected}
      >
        <div>{policy.policyNumber ?? '--'}</div>
        <div>{policy.clientName ?? '--'}</div>
        <div>{policy[`companyName${hebrew() ? '' : 'En'}`] ?? '--'}</div>
        <div>{policy[`categoryName${hebrew() ? '' : 'En'}`] ?? '--'}</div>
        <div>{formatAsShortDate(policy.startDate) ?? '--'}</div>
        {deltaCommissionSplitView() && (
          <div className='financial-value-cell'>
            {formatAsCurrency(policy['commissionAgency'], { dashesForZero: false })}
          </div>
        )}
        <div className='financial-value-cell'>
          {formatAsCurrency(policy['commission' + commissionKeySuffix()], { dashesForZero: false })}
        </div>
        <div className='financial-value-cell'>{formatAsCurrency(policy.premium, { dashesForZero: false })}</div>
        <div className='financial-value-cell'>{formatAsCurrency(policy.accumulation, { dashesForZero: false })}</div>
        {deltaCommissionSplitView() && (
          <div className='commission-rate-cells'>
            <div className='financial-value-cell'>
              {formatAsPercent(policy['commissionRatePremiumAgency'], { dashesForZero: false })}
            </div>
            <div className='financial-value-cell'>
              {formatAsPercent(policy['commissionRateAccumulationAgency'], {
                maximumFractionDigits: 4,
                dashesForZero: false,
              })}
            </div>
          </div>
        )}
        <div className='commission-rate-cells'>
          <div className='financial-value-cell'>
            {formatAsPercent(policy['commissionRatePremium' + keySuffix], { dashesForZero: false })}
          </div>
          <div className='financial-value-cell'>
            {formatAsPercent(policy['commissionRateAccumulation' + keySuffix], {
              maximumFractionDigits: 4,
              dashesForZero: false,
            })}
          </div>
        </div>
        <div className='policy-badges'>
          <PolicyStatusChip status={policy.status} />
          {!policy.customerIdNumber && <EntityStatusChip status={entityStatusType.lostData} />}
        </div>
        <div className='policies-lost-list-item-icons'>
          <PoliciesSyncIcon tooltip={t('list.tooltips.outdatedPolicy')} enabled={policy.isOutdated} />
        </div>
        <PolicyListItemMenu policy={policy} onPolicyAddCustomer={onPolicyAddCustomer} />
      </PolicyListItemCard>
      {showPolicyCard && <PolicyCard closeDetails={() => setShowPolicyCard(false)} id={policy.id} />}
    </>
  )
}

export default PoliciesLostListItem
