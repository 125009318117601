import { useTranslation } from 'react-i18next'
import Loader from '../../../../components/Common/Loader/Loader'
import NoData from '../../../../components/Common/NoData/NoData'
import { hebrew } from '../../../../i18n'
import { insuranceCategory } from '../../../../types/insuranceCategory'
import { formatAsCurrency } from '../../../../utils/formatAs'
import { cn } from '../../../../utils/stylesUtils'
import './CustomersFieldsPolicies.scss'

const CustomersFieldsPolicies = ({ fields, loading, showList, potentialFields = false }) => {
  const { t } = useTranslation('customer')
  if (loading) {
    return (
      <div className='customers-fields-policies'>
        <Loader />
      </div>
    )
  }
  if (fields.length === 0) {
    return (
      <div className='customers-fields-policies'>
        <NoData text='No Data' />
      </div>
    )
  }
  return (
    <div className='customers-fields-policies'>
      {fields.map(f => (
        <section key={f.fieldId}>
          <p>
            {hebrew() ? f.name : f.nameEn}
            <span> ({f.policiesCount})</span>
          </p>
          <div className='customers-fields-policies-categories'>
            {f.categoryInfos.map(c => (
              <div
                key={f.fieldId + ' ' + c.categoryId}
                className={cn('customers-fields-policies-category', { potential: potentialFields })}
                {...(showList && { onClick: () => showList(c.categoryId) })}
              >
                <div>
                  <p>{hebrew() ? c.name : c.nameEn}</p>
                  {insuranceCategory[c.categoryId].cardIcon}
                </div>
                <div>
                  {c.policiesCount !== 0 && (
                    <>
                      <p>{t('categoryCard.policiesAmount')}</p>
                      <p>{c.policiesCount ?? '--'}</p>
                    </>
                  )}
                </div>
                <div>
                  <div>
                    <p>{formatAsCurrency(c.commission)}</p>
                    <p>{t('categoryCard.totalCommission')}</p>
                  </div>
                  <div>
                    <p>{formatAsCurrency(c.premium)}</p>
                    <p>{t('categoryCard.totalPremium')}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      ))}
    </div>
  )
}

export default CustomersFieldsPolicies
