import './WorkstationBanner.scss'

const WorkstationBanner = ({ children, iconPath = './assets/star-icon/star.png' }) => {
  return (
    <div className='workstation-banner'>
      <div className='workstation-banner-icon'>
        <img src={iconPath} alt='ico' />
      </div>
      <div>{children}</div>
    </div>
  )
}

export default WorkstationBanner
