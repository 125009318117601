import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { hebrew } from '../../../../i18n'
import { CloudUploadOutlined, PersonAddAlt1 } from '@mui/icons-material'
import ScanDatepicker from '../ScanDatepicker/ScanDatepicker'
import './ScanCompanyHeader.scss'

const ScanCompanyHeader = ({ openUploadModal, uploadEnabled = false, showAddUser }) => {
  const { t } = useTranslation('scan')
  const { id } = useParams()
  const {
    monthStatus: { companies },
  } = useSelector(({ scan }) => scan)
  const company = companies.find(c => c.id === +id)

  return (
    <header className='scan-company-header'>
      <div className='scan-company-header-content'>
        {company && (
          <>
            <div className='scan-company-header-logo'>
              <img src={`./assets/companies-logos-light/${company.id}.png`} alt={company?.name} />
            </div>
            <div className='scan-company-header-summary'>
              <h5>{hebrew() ? company?.name : company?.nameEn}</h5>
              {company.availableReportsCount > 0 && (
                <p>{t('monthReports', { count: company.availableReportsCount })}</p>
              )}
            </div>
          </>
        )}
      </div>
      <div className='scan-company-header-controls'>
        {uploadEnabled && company?.availableReportsCount > 0 && (
          <button className='scan-company-header-button' onClick={() => openUploadModal()}>
            <CloudUploadOutlined />
          </button>
        )}
        {showAddUser ? (
          <button className='scan-company-header-button-add-user' onClick={showAddUser}>
            <PersonAddAlt1 />
            <span>{t('companyUsers.addUserBtn')}</span>
          </button>
        ) : (
          <ScanDatepicker />
        )}
      </div>
    </header>
  )
}

export default ScanCompanyHeader
