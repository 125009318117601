import './AppBar.scss'

const AppBar = ({ closeBar = () => {}, children }) => {
  return (
    <div className='appbar-container'>
      <div onClick={closeBar} className='appbar-close-icon'>
        <img src='./assets/close-icon-sm/Close.png' alt='cls' className='img-icon' />
      </div>
      {children}
    </div>
  )
}

export default AppBar
