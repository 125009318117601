import {
  LOGIN_SUCCESS,
  LOGIN_RESET,
  LOGOUT,
  LOGIN_GET_AGENCIES_REQUEST,
  LOGIN_GET_AGENCIES_SUCCESS,
  LOGIN_GET_AGENCIES_EMPTY_SUCCESS,
  LOGIN_GET_AGENCIES_FAILED,
  AGENT_HOUSE_RESET,
  DOWNLOAD_RESET,
  LOGIN_ENABLE_SUBAGENCYMODE,
  LOGIN_DISABLE_SUBAGENCYMODE,
  LOGIN_AGREE_PRIVACY_TERMS,
} from '../../../types/actionTypes'
import { urlRegistration, useCrossDomainCookies } from '../../../environment/urls'
import {
  getItemFromLocalStorage,
  getPreferencesFromLocalStorage,
  removeItemFromLocalStorage,
  setItemToLocalStorage,
} from '../../../services/localStorageService'
import $api from '../../../http'
import { COOKIES_MAX_AGE } from '../../../constants/cookies'
import { cookieGetItemValue } from '../../../services/cookieStorgeService'
import { SHARED_SET_NO_SUBSCRIPTION } from '../../../types/actionTypesShared'

const url = urlRegistration()

const getAgencies = userId => async (dispatch, getState) => {
  if (!userId) {
    userId = getState().login.UserId
  }
  try {
    dispatch({
      type: LOGIN_GET_AGENCIES_REQUEST,
    })
    const response = await $api.get(url + `agency/agencies/${userId}`)

    if (response.data.HasError === true) {
      dispatch({
        type: LOGIN_GET_AGENCIES_FAILED,
        payload: response.ErrorMessage,
      })
    } else if (response.data.Data) {
      const agencyId = response.data.Data.Id
      const agencyName = response.data.Data.Name
      const agencyDnsName = response.data.Data.dnsName
      document.cookie = `agencyId=${agencyId}; Max-Age=${COOKIES_MAX_AGE}${useCrossDomainCookies}`
      setItemToLocalStorage('agencyName', agencyName)
      document.cookie = `agencyDnsName=${agencyDnsName}; Max-Age=${COOKIES_MAX_AGE}${useCrossDomainCookies}`

      dispatch({
        type: LOGIN_GET_AGENCIES_SUCCESS,
        payload: { agencyId, agencyName, agencyDnsName },
      })
    } else {
      dispatch({
        type: LOGIN_GET_AGENCIES_EMPTY_SUCCESS,
      })
    }
  } catch (error) {
    dispatch({
      type: LOGIN_GET_AGENCIES_FAILED,
      payload: error.ErrorMessage,
    })
  }
}

export const login = () => dispatch => {
  const UserId = cookieGetItemValue('UserId')
  const UserName = getItemFromLocalStorage('UserName')
  const AgencyType = +getItemFromLocalStorage('AgencyType')
  const UserRole = cookieGetItemValue('role')
  const preferences = getPreferencesFromLocalStorage()

  dispatch({
    type: LOGIN_SUCCESS,
    payload: { UserId, UserName, UserRole, preferences, AgencyType },
  })
  dispatch(getAgencies())
}

export const logout = () => dispatch => {
  document.cookie = 'UserId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
  document.cookie = 'agencyId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
  document.cookie = 'agencyDnsName=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
  document.cookie = 'role=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
  document.cookie = 'applicationId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
  document.cookie = 'bigAgencyDnsName=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
  document.cookie = 'bigAgencyType=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
  document.cookie = 'subagencyMode=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'

  removeItemFromLocalStorage('AccessToken')
  removeItemFromLocalStorage('RefreshToken')
  removeItemFromLocalStorage('RefreshTokenExpireAt')
  removeItemFromLocalStorage('UserId')
  removeItemFromLocalStorage('UserName')
  removeItemFromLocalStorage('agencyName')
  removeItemFromLocalStorage('id')
  removeItemFromLocalStorage('role')
  removeItemFromLocalStorage('preferences')
  removeItemFromLocalStorage('AgencyType')
  removeItemFromLocalStorage('AgreementConfirmed')

  dispatch({ type: AGENT_HOUSE_RESET })
  dispatch({ type: DOWNLOAD_RESET })
  dispatch({ type: LOGOUT })
  dispatch(loginReset())
}

const loginReset = () => dispatch => {
  dispatch({ type: SHARED_SET_NO_SUBSCRIPTION, payload: false })
  dispatch({ type: LOGIN_RESET })
}

export const enableSubagencyMode = subgencyDnsName => (dispatch, getState) => {
  const { AgencyType: bigAgencyType, agencyDnsName: bigAgencyDnsName } = getState().login
  document.cookie = `agencyDnsName=${subgencyDnsName}; Max-Age=${COOKIES_MAX_AGE}${useCrossDomainCookies}`
  document.cookie = `bigAgencyDnsName=${bigAgencyDnsName}; Max-Age=${COOKIES_MAX_AGE}${useCrossDomainCookies}`
  document.cookie = `bigAgencyType=${bigAgencyType}; Max-Age=${COOKIES_MAX_AGE}${useCrossDomainCookies}`
  document.cookie = `subagencyMode=true; Max-Age=${COOKIES_MAX_AGE}${useCrossDomainCookies}`
  dispatch({
    type: LOGIN_ENABLE_SUBAGENCYMODE,
    payload: { bigAgencyDnsName, subgencyDnsName },
  })
}

export const disableSubagencyMode = () => {
  const bigAgencyType = +cookieGetItemValue('bigAgencyType')
  document.cookie = `agencyDnsName=${cookieGetItemValue(
    'bigAgencyDnsName'
  )}; Max-Age=${COOKIES_MAX_AGE}${useCrossDomainCookies}`
  document.cookie = 'bigAgencyDnsName=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
  document.cookie = 'bigAgencyType=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
  document.cookie = 'subagencyMode=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
  return {
    type: LOGIN_DISABLE_SUBAGENCYMODE,
    payload: bigAgencyType,
  }
}

export const agreePrivacyTerms = () => {
  setItemToLocalStorage('AgreementConfirmed', true)
  return {
    type: LOGIN_AGREE_PRIVACY_TERMS,
  }
}
