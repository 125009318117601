export const companyAuthType = {
  all: 0,
  main: 1,
  elementary: 2,
}

export const companyAuth = {
  [companyAuthType.all]: {
    id: companyAuthType.all,
    name: 'All',
    nameEn: 'All',
  },
  [companyAuthType.main]: {
    id: companyAuthType.main,
    name: 'חיים + פיננסי',
    nameEn: 'Life + Financial',
  },
  [companyAuthType.elementary]: {
    id: companyAuthType.elementary,
    name: 'אלמנטרי',
    nameEn: 'Elementary',
  },
}

export const getAuthType = authTypeName => companyAuthType[authTypeName.toLowerCase()]
