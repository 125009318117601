import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ModalWindow from '../../../../components/ModalWindow/ModalWindow'
import './ModalAgencyInvite.scss'
import ButtonText from '../../../../components/Common/ButtonText/ButtonText'
import { Close } from '@mui/icons-material'
import ButtonRounded from '../../../../components/Common/ButtonRounded/ButtonRounded'
import ButtonCheckbox from '../../../../components/Common/ButtonCheckbox/ButtonCheckbox'
import { hebrew } from '../../../../i18n'
import classNames from 'classnames'
import LoadingOverlay from '../../../../components/Common/LoadingOverlay/LoadingOverlay'
import { getUserPreferencesList } from '../../../../types/agencyUserTypes'
import { processAgencyInvitesRequest } from '../../../../http/requests/agencyHouseRequests'

const InviteAgencyItem = ({ children, checked, selectItem }) => (
  <div className={classNames('modal-agency-invite-item', { checked })}>
    {children}
    <ButtonCheckbox checked={checked} onClick={selectItem} />
  </div>
)

const InviteAgencyItems = ({ allItems, selectedItems, selectItem, selectAllItems, itemsName }) => {
  const { t } = useTranslation('common')
  const tModal = key => t(`modalAgencyInvite.${key}`)
  const allItemsSelected = allItems.every(c => selectedItems.includes(c.id))

  return (
    <div className='modal-agency-invite-items'>
      <div className='modal-agency-invite-item invite-item-heading'>
        <p>{itemsName}</p>
        <div>
          <p>{tModal('selectAllBtn')}</p>
          <ButtonCheckbox checked={allItemsSelected} onClick={() => selectAllItems(allItems)} />
        </div>
      </div>
      {allItems.map(({ id, iconPath, name, nameEn }) => (
        <InviteAgencyItem key={id} checked={selectedItems.includes(id)} selectItem={() => selectItem(id)}>
          <div className='company-info'>
            {iconPath && <img src={iconPath} alt={id} />}
            <p>{hebrew() ? name : nameEn}</p>
          </div>
        </InviteAgencyItem>
      ))}
    </div>
  )
}

const ModalAgencyInvite = ({ invite, closeModal }) => {
  const [companies, setCompanies] = useState(invite.companies.map(({ id }) => id))
  const [preferences, setPreferences] = useState(invite.preferences.map(({ id }) => id))
  const [approveLoading, setApproveLoading] = useState(false)

  const { t } = useTranslation('common')
  const tModal = (key, params) => t(`modalAgencyInvite.${key}`, { ...params })

  const selectItemHandler = setItems => id => {
    setItems(prev => (prev.includes(id) ? prev.filter(itm => itm !== id) : [...prev, id]))
  }

  const selectAllItemsHandler = setItems => allItems => {
    setItems(prev => (prev.length === allItems.length ? [] : allItems.map(({ id }) => id)))
  }

  const processInvite = async accepted => {
    try {
      setApproveLoading(true)
      const processRequestDto = {
        inviteId: invite.id,
        accepted,
        ...(accepted && {
          companyIds: companies,
          preferences: Object.fromEntries(
            getUserPreferencesList()
              .filter(pr => invite.preferences.map(pref => pref.id).includes(pr.id))
              .map(pr => [pr.id, preferences.includes(pr.id)])
          ),
        }),
      }
      const {
        data: { Data, HasError },
      } = await processAgencyInvitesRequest(processRequestDto)
      if (Data && !HasError) {
        closeModal()
      }
    } catch (error) {
      setApproveLoading(false)
      console.log(error)
    }
  }

  const approveBtnDisabled = companies.length === 0 || preferences.length === 0 || approveLoading

  return (
    <ModalWindow renderPortal={false} closeOnOutsideClick={false} showCloseIcon={false}>
      <div className='modal-agency-invite'>
        {approveLoading && <LoadingOverlay />}
        <aside>
          <img src='./assets/_agencies/invite-request/modal-illustration.png' alt='desc' />
          <div className='modal-agency-invite-side-content'>
            <h5>{tModal('requestForMirroringHeading')}</h5>
            <p>{tModal('requestForMirroringDesc', { agencyHouse: invite.agencyHouseName })}</p>
            <p>{tModal('requestForMirroringRemark')}</p>
            <div className='modal-agency-invite-payment-desc'>
              <img src='./assets/_agencies/invite-request/coin.png' alt='coin' />
              <div>
                <p>{tModal('requestPaymentHeading')}</p>
                <p>{tModal('requestPaymentDesc')}</p>
                <p>{tModal('requestPaymentRemark')}</p>
              </div>
              <img src='./assets/_agencies/invite-request/coins.png' alt='coins' />
            </div>
          </div>
        </aside>
        <main>
          <header>
            <h5>{tModal('title')}</h5>
            <ButtonText onClick={closeModal}>
              <Close />
            </ButtonText>
          </header>
          <div className='modal-agency-invite-main-content'>
            <InviteAgencyItems
              allItems={invite.companies}
              selectedItems={companies}
              itemsName={tModal('companies')}
              selectItem={selectItemHandler(setCompanies)}
              selectAllItems={selectAllItemsHandler(setCompanies)}
            />
            <InviteAgencyItems
              allItems={invite.preferences}
              selectedItems={preferences}
              itemsName={tModal('preferences')}
              selectItem={selectItemHandler(setPreferences)}
              selectAllItems={selectAllItemsHandler(setPreferences)}
            />
          </div>
          <footer>
            <ButtonRounded disabled={approveLoading} variant='outlined' onClick={() => processInvite(false)}>
              {tModal('declineBtn')}
            </ButtonRounded>
            <ButtonRounded disabled={approveBtnDisabled} onClick={() => processInvite(true)}>
              {tModal('approveBtn')}
            </ButtonRounded>
          </footer>
        </main>
      </div>
    </ModalWindow>
  )
}

export default ModalAgencyInvite
