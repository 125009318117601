import ReactApexChart from 'react-apexcharts'
import { useRef, useEffect, useState } from 'react'
import AgencyHouseAnalyticsIncomesNoData from '../AgencyHouseAnalyticsIncomesNoData/AgencyHouseAnalyticsIncomesNoData'
import classNames from 'classnames'
import './AgencyHouseAnalyticsPoliciesChart.scss'

const AgencyHouseAnalyticsPoliciesChart = ({
  totalsValue,
  totalsName,
  series = [],
  categories = [],
  selectItem,
  chartId,
  colors,
}) => {
  const [chartWidth, setChartWidth] = useState('100%')
  const yAxisWidth = '45px'
  const chartContainerRef = useRef()
  const options = {
    fill: {
      colors,
    },
    states: {
      normal: {
        filter: {
          type: 'darken',
          value: 0.35,
        },
      },
      hover: {
        filter: {
          type: 'lighten',
          value: 0.25,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'lighten',
          value: 0.15,
        },
      },
    },
    chart: {
      id: chartId,
      type: 'bar',
      toolbar: {
        show: false,
      },
      events: {
        xAxisLabelClick: function (event, chartContext, config) {
          chartContext.toggleDataPointSelection(0, config.labelIndex)
          //selectItem(config.labelIndex)
        },
        dataPointSelection: function (event, chartContext, config) {
          selectItem(config.selectedDataPoints[0].length > 0 ? config.dataPointIndex : null)
        },
      },
    },
    grid: {
      show: true,
      showAlways: true,
      strokeDashArray: 1,
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 2,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: '40',
        borderRadius: 8,
        barHeight: '70%',
        borderRadiusApplication: 'end',
        distributed: true,
        colors: {
          backgroundBarColors: ['var(--text-primary-color'],
          backgroundBarOpacity: 0.12,
          backgroundBarRadius: 0,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    series: [
      {
        name: 'Series 1',
        data: series,
      },
    ],
    xaxis: {
      categories,
      labels: {
        style: { cssClass: 'agency-house-analytics-policies-chart-x-labels' },
        rotate: 0,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: true,
      showAlways: true,
      decimalsInFloat: 0,
      floating: true,
      labels: {
        show: true,
        align: 'right',
        minWidth: Number(yAxisWidth.replace('px', '')),
        style: {
          colors: ['red', 'green'],
          fontSize: '12px',
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 400,
          cssClass: 'agency-house-analytics-policies-chart-y-labels',
        },
        offsetX: -13,
        offsetY: 0,
        rotate: 0,
        formatter: value => {
          return Intl.NumberFormat('en', { notation: 'compact' }).format(value)
        },
      },
      axisBorder: {
        show: true,
        color: 'var(--text-primary-color)',
        offsetX: 0,
        offsetY: 0,
      },
    },
    tooltip: {
      enabled: false,
    },
  }

  useEffect(() => {
    if (chartContainerRef.current) {
      const barContainerWidth = 115 // px
      const chartEvaluatedWidth = series.length * barContainerWidth
      if (chartEvaluatedWidth > chartContainerRef.current.clientWidth) setChartWidth(chartEvaluatedWidth)
    }
  }, [series])

  return (
    <div className='agency-house-analytics-policies-chart-container'>
      <header>
        <p>{totalsName}</p>
        <p>{totalsValue}</p>
      </header>
      <div className={classNames({ 'no-chart-data': series.length === 0 })}>
        {series.length === 0 ? (
          <AgencyHouseAnalyticsIncomesNoData />
        ) : (
          <>
            <div className='agency-house-analytics-policies-chart-yaxis-wrapper'>
              <ReactApexChart
                options={{
                  ...options,
                  chart: {
                    id: null,
                    toolbar: {
                      show: false,
                    },
                  },
                }}
                series={[{ data: series, name: 'Series 1' }]}
                type='bar'
                height={'250px'}
                width={yAxisWidth}
              />
            </div>
            <div className='agency-house-analytics-policies-chart-wrapper' ref={chartContainerRef}>
              <div className='agency-house-analytics-policies-chart'>
                <ReactApexChart
                  options={{ ...options, yaxis: { show: false } }}
                  series={[{ data: series, name: 'Series 1' }]}
                  type='bar'
                  height={'250px'}
                  width={chartWidth}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default AgencyHouseAnalyticsPoliciesChart
