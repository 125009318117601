import {
  CUSTOMERS_GET_FILTERS_SUCCESS,
  CUSTOMERS_GET_FILTERS_FAIL,
  CUSTOMERS_GET_DASHBOARD_REQUEST,
  CUSTOMERS_GET_DASHBOARD_SUCCESS,
  CUSTOMERS_GET_DASHBOARD_FAIL,
  CUSTOMERS_FILTER_BY_COMPANIES,
  CUSTOMERS_FILTER_BY_CATEGORIES,
  CUSTOMERS_SET_PAGE_SIZE,
  CUSTOMERS_FILTER_BY_GROUP,
  CUSTOMERS_GET_REQUEST,
  CUSTOMERS_GET_SUCCESS,
  CUSTOMERS_GET_FAIL,
  CUSTOMERS_SET_PAGE,
  CUSTOMERS_SET_SEARCH,
  CUSTOMERS_SET_SORT_BY,
  CUSTOMERS_COLLAPSE_GROUP,
  CUSTOMERS_RESET,
  CUSTOMERS_CLEAR_LIST,
  CUSTOMERS_FILTERS_CLEAR,
  CUSTOMERS_FILTER_BY_GROUP_TYPE,
  CUSTOMERS_GROUPS_REQUEST,
  CUSTOMERS_GROUPS_SUCCESS,
  CUSTOMERS_GROUP_EDIT_REQUEST,
  CUSTOMERS_GROUP_EDIT_SUCCESS,
  CUSTOMERS_GROUP_EDIT_RESET,
  CUSTOMERS_GROUP_DELETE_REQUEST,
  CUSTOMERS_GROUP_DELETE_SUCCESS,
  CUSTOMERS_GROUP_DELETE_FAIL,
  CUSTOMERS_SET_GROUPS_SORT_BY,
  CUSTOMERS_COLLAPSE_CUSTOMERS_GROUP,
  CUSTOMERS_FILTER_SET_ADVANCED,
  CUSTOMERS_FILTER_BY_ADVANCED,
  CUSTOMERS_FILTER_BY_CATEGORY,
} from '../../../types/actionTypes'
import { policyRatingType } from '../../../types/policyRatingTypes'
import { sortBy, sortOrder } from '../../../types/sortByTypes'

const allCustomersInitialState = {
  groups: [
    {
      groupItems: [],
      groupName: '',
      itemsInGroup: 0,
    },
  ],
  showGroups: true,
  totalPages: 0,
}

const initialState = {
  allCustomers: allCustomersInitialState,
  customersLoading: true,
  page: 1,
  pageSize: 50,
  searchTerm: '',
  sort: { by: sortBy.commission, order: sortOrder.desc },
  groupsSort: { by: sortBy.name, order: sortOrder.asc },
  collapsedGroups: {},
  customersGroups: [],
  customersGroupsLoading: true,
  groupsTotalPages: 1,
  customersCollapsedGroups: [],
  editCustomersGroupSuccess: false,
  addCustomersGroupSuccess: false,
  customersTotalCount: 0,
  customersGreenCount: 0,
  customersOrangeCount: 0,
  customersRedCount: 0,
  customersPercentageSplit: [],
  customersInactiveCount: 0,
  totalGroups: 0,
  dashboardLoading: false,
  dashboardError: null,
  companiesFilter: [],
  categoriesFilter: [],
  advancedFiltersApplied: false,
  companiesAndFilter: [],
  categoriesAndFilter: [],
  companiesNoFilter: [],
  categoriesNoFilter: [],
  groupTypesFilter: [],
  groupFilter: [
    {
      id: 0,
      name: 'מדרג 1',
      nameEn: 'group 1',
      checked: false,
    },
    {
      id: 1,
      nameEn: 'group 2',
      name: 'מדרג 2',
      checked: false,
    },
    {
      id: 2,
      nameEn: 'group 3',
      name: 'מדרג 3',
      checked: false,
    },
    {
      id: 3,
      nameEn: 'group 4',
      name: 'מדרג 4',
      checked: false,
    },
    {
      id: 4,
      nameEn: 'group 5',
      name: 'מדרג 5',
      checked: false,
    },
  ],

  filtersError: null,
}

export const customersReducer = (state = initialState, action) => {
  switch (action.type) {
    case CUSTOMERS_GET_REQUEST:
      return { ...state, customersLoading: true }
    case CUSTOMERS_GET_SUCCESS:
      return {
        ...state,
        customersLoading: false,
        allCustomers: action.payload,
        page:
          action.payload.totalPages < state.page
            ? action.payload.totalPages === 0
              ? 1
              : action.payload.totalPages
            : state.page,
      }
    case CUSTOMERS_GET_FAIL:
      return {
        ...state,
        customersLoading: false,
        customersError: action.payload,
      }
    case CUSTOMERS_CLEAR_LIST:
      return {
        ...state,
        customersLoading: true,
        allCustomers: allCustomersInitialState,
        customersError: null,
      }
    case CUSTOMERS_GET_FILTERS_SUCCESS:
      const companiesFilter = action.payload.companiesFilter.map(itm => {
        return { ...itm, checked: !!state.companiesFilter.find(cf => cf.id === itm.id)?.checked }
      })
      const categoriesFilter = action.payload.categoriesFilter.map(itm => {
        return { ...itm, checked: !!state.categoriesFilter.find(cf => cf.id === itm.id)?.checked }
      })
      const companiesAndFilter = action.payload.companiesFilter.map(itm => {
        const filter = state.companiesAndFilter.find(cf => cf.id === itm.id)
        return { ...itm, ...filter }
      })
      const categoriesAndFilter = action.payload.categoriesFilter.map(itm => {
        const filter = state.categoriesAndFilter.find(cf => cf.id === itm.id)
        return { ...itm, ...filter }
      })
      const companiesNoFilter = action.payload.companiesFilter.map(itm => {
        const filter = state.companiesNoFilter.find(cf => cf.id === itm.id)
        return { ...itm, ...filter }
      })
      const categoriesNoFilter = action.payload.categoriesFilter.map(itm => {
        const filter = state.categoriesNoFilter.find(cf => cf.id === itm.id)
        return { ...itm, ...filter }
      })
      const groupTypesFilter = action.payload.groupTypesFilter
      const singleGroupType = groupTypesFilter.filter(gt => gt.hasData).length === 1
      return {
        ...state,
        companiesFilter,
        categoriesFilter,
        companiesAndFilter,
        categoriesAndFilter,
        companiesNoFilter,
        categoriesNoFilter,
        groupFilter: action.payload.groupFilter,
        groupTypesFilter: action.payload.groupTypesFilter,
        customersCollapsedGroups: singleGroupType ? [] : groupTypesFilter.map(gf => gf.id.toString()),
      }
    case CUSTOMERS_GET_FILTERS_FAIL:
      return { ...state, filtersError: action.payload }
    case CUSTOMERS_GET_DASHBOARD_REQUEST:
      return { ...state, dashboardLoading: !action.payload }
    case CUSTOMERS_GET_DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboardLoading: false,
        customersTotalCount: action.payload.activeCustomersCount,
        customersGreenCount:
          action.payload.ratingSummary.find(r => r.policyRating === policyRatingType.green)?.users ?? 0,
        customersOrangeCount:
          action.payload.ratingSummary.find(r => r.policyRating === policyRatingType.orange)?.users ?? 0,
        customersRedCount: action.payload.ratingSummary.find(r => r.policyRating === policyRatingType.red)?.users ?? 0,
        customersPercentageSplit: action.payload.splitUpByCustomers,
        customersInactiveCount: action.payload.inactiveCustomersCount,
        totalGroups: action.payload.totalGroups,
      }
    case CUSTOMERS_GET_DASHBOARD_FAIL:
      return { ...state, dashboardLoading: false, dashboardError: action.payload }
    case CUSTOMERS_FILTER_BY_COMPANIES:
      return {
        ...state,
        page: 1,
        companiesFilter: state.companiesFilter.map(company =>
          company.id === action.payload.id ? { ...company, checked: action.payload.checked } : company
        ),
      }
    case CUSTOMERS_FILTER_BY_CATEGORIES:
      return {
        ...state,
        page: 1,
        categoriesFilter: state.categoriesFilter.map(category =>
          category.id === action.payload.id ? { ...category, checked: action.payload.checked } : category
        ),
      }
    case CUSTOMERS_FILTER_BY_CATEGORY: {
      const id = action.payload
      const existingCategory = state.categoriesFilter.find(c => c.id === id)
      return {
        ...state,
        page: 1,
        categoriesFilter: existingCategory
          ? state.categoriesFilter.map(category => ({ ...category, checked: category.id === id }))
          : [{ id, checked: true }],
        advancedFiltersApplied: false,
      }
    }
    case CUSTOMERS_FILTER_BY_GROUP_TYPE:
      return {
        ...state,
        page: 1,
        groupTypesFilter: state.groupTypesFilter.map(grt =>
          grt.id === action.payload.id ? { ...grt, checked: action.payload.checked } : grt
        ),
      }
    case CUSTOMERS_FILTER_BY_GROUP:
      return {
        ...state,
        page: 1,
        groupFilter: state.groupFilter.map(group =>
          group.id === action.payload.groupId
            ? { ...group, checked: action.payload.checked }
            : { ...group, checked: false }
        ),
      }
    case CUSTOMERS_FILTER_BY_ADVANCED:
      return { ...state, ...action.payload, page: 1 }
    case CUSTOMERS_SET_PAGE_SIZE:
      return {
        ...state,
        page: 1,
        pageSize: action.payload,
      }
    case CUSTOMERS_SET_PAGE:
      return {
        ...state,
        page: action.payload,
      }
    case CUSTOMERS_SET_SEARCH:
      return {
        ...state,
        page: 1,
        searchTerm: action.payload,
      }
    case CUSTOMERS_SET_SORT_BY:
      const sort = action.payload
      let updatedSort
      if (sort === state.sort.by) {
        updatedSort = {
          ...state.sort,
          order: state.sort.order !== sortOrder.asc ? sortOrder.asc : sortOrder.desc,
        }
      } else {
        updatedSort = {
          by: sort,
          order: sort === sortBy.customerName ? sortOrder.asc : sortOrder.desc,
        }
      }
      return {
        ...state,
        page: 1,
        sort: updatedSort,
      }
    case CUSTOMERS_SET_GROUPS_SORT_BY: {
      const sort = action.payload
      let updatedSort
      if (sort === state.groupsSort.by) {
        updatedSort = {
          ...state.groupsSort,
          order: state.groupsSort.order !== sortOrder.asc ? sortOrder.asc : sortOrder.desc,
        }
      } else {
        updatedSort = {
          by: sort,
          order: sort === sortBy.name ? sortOrder.asc : sortOrder.desc,
        }
      }
      return {
        ...state,
        page: 1,
        groupsSort: updatedSort,
      }
    }
    case CUSTOMERS_COLLAPSE_GROUP:
      return {
        ...state,
        collapsedGroups: {
          ...state.collapsedGroups,
          [action.payload]: {
            ...state.collapsedGroups[action.payload],
            show: state.collapsedGroups[action.payload] ? !state.collapsedGroups[action.payload].show : false,
          },
        },
      }
    case CUSTOMERS_COLLAPSE_CUSTOMERS_GROUP:
      return {
        ...state,
        customersCollapsedGroups:
          state.customersCollapsedGroups.indexOf(action.payload) > -1
            ? state.customersCollapsedGroups.filter(gr => gr !== action.payload)
            : [...state.customersCollapsedGroups, action.payload],
      }
    case CUSTOMERS_FILTERS_CLEAR:
      return {
        ...state,
        companiesFilter: state.companiesFilter.map(c => ({ ...c, checked: false })),
        categoriesFilter: state.categoriesFilter.map(c => ({ ...c, checked: false })),
        groupFilter: state.groupFilter.map(g => ({ ...g, checked: false })),
        groupTypesFilter: state.groupTypesFilter.map(gt => ({ ...gt, checked: false })),
        searchTerm: '',
        advancedFiltersApplied: false,
        companiesAndFilter: state.companiesFilter.map(c => ({ ...c, checked: false })),
        categoriesAndFilter: state.categoriesFilter.map(c => ({ ...c, checked: false })),
        companiesNoFilter: state.companiesFilter.map(c => ({ ...c, checked: false })),
        categoriesNoFilter: state.categoriesFilter.map(c => ({ ...c, checked: false })),
      }
    case CUSTOMERS_GROUPS_REQUEST:
      const loadingId = action.payload
      return {
        ...state,
        customersGroupsLoading: !loadingId && true,
        customersGroups: state.customersGroups.map(cg => ({
          ...cg,
          groupItems: cg.groupItems.map(gr => (gr.id === loadingId ? { ...gr, loading: true } : gr)),
        })),
      }
    case CUSTOMERS_GROUPS_SUCCESS:
      const { groupsTotalPages } = action.payload
      const page = groupsTotalPages < state.page ? (groupsTotalPages === 0 ? 1 : groupsTotalPages) : state.page
      return {
        ...state,
        customersGroupsLoading: false,
        customersGroups: action.payload.groups,
        groupsTotalPages,
        page,
      }
    case CUSTOMERS_GROUP_EDIT_REQUEST: {
      const { id } = action.payload
      return {
        ...state,
        customersGroups: state.customersGroups.map(cg => ({
          ...cg,
          groupItems: cg.groupItems.map(gr => (gr.id === id ? { ...gr, loading: true } : gr)),
        })),
      }
    }
    case CUSTOMERS_GROUP_EDIT_SUCCESS: {
      const { success, group } = action.payload
      return {
        ...state,
        customersGroups: state.customersGroups.map(cg => ({
          ...cg,
          groupItems: cg.groupItems.map(gr =>
            gr.id === group.id ? { ...(success ? group : gr), loading: false } : gr
          ),
        })),
        editCustomersGroupSuccess: success,
      }
    }
    case CUSTOMERS_GROUP_EDIT_RESET: {
      return {
        ...state,
        customersGroups: state.customersGroups.map(cg => ({
          ...cg,
          groupItems: cg.groupItems.map(gr => ({ ...gr, loading: false })),
        })),
        editCustomersGroupSuccess: false,
      }
    }
    case CUSTOMERS_GROUP_DELETE_REQUEST: {
      const idToDelete = action.payload
      return {
        ...state,
        customersGroups: state.customersGroups.map(cg => ({
          ...cg,
          groupItems: cg.groupItems.map(gr => ({ ...gr, loading: gr.id === idToDelete ? true : gr.loading })),
        })),
      }
    }
    case CUSTOMERS_GROUP_DELETE_SUCCESS: {
      const { id: idToDelete } = action.payload
      const updatedGroups = state.customersGroups.map(cg => ({
        ...cg,
        groupItems: cg.groupItems.map(g => g.id).includes(idToDelete)
          ? cg.groupItems.filter(g => g.id !== idToDelete)
          : cg.groupItems,
        itemsInGroup: cg.groupItems.map(g => g.id).includes(idToDelete) ? cg.itemsInGroup - 1 : cg.itemsInGroup,
      }))
      return {
        ...state,
        customersGroups: updatedGroups.filter(cg => cg.itemsInGroup !== 0),
        totalGroups: state.totalGroups - 1,
      }
    }
    case CUSTOMERS_GROUP_DELETE_FAIL: {
      const { id: idToDelete } = action.payload
      return {
        ...state,
        customersGroups: state.customersGroups.map(cg => ({
          ...cg,
          groupItems: cg.groupItems.map(gr => ({ ...gr, loading: gr.id === idToDelete ? false : gr.loading })),
        })),
      }
    }
    case CUSTOMERS_FILTER_SET_ADVANCED:
      return {
        ...state,
        ...action.payload,
      }
    case CUSTOMERS_RESET:
      return initialState
    default:
      return state
  }
}
