import { CompareRounded } from '@mui/icons-material'
import { cn } from '../../../../utils/stylesUtils'
import './AnalyticsChangesSwitch.scss'

const AnalyticsChangesSwitch = ({ showChanges, setShowChanges }) => {
  return (
    <div className={cn('analytics-changes-switch', { 'switch-enabled': showChanges })}>
      <button onClick={setShowChanges}>
        <CompareRounded />
      </button>
    </div>
  )
}

export default AnalyticsChangesSwitch
