import DatePicker from 'react-date-picker'
import './PoliciesDatePicker.scss'
import {
  CalendarMonthOutlined,
  Close,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from '@mui/icons-material'
import { cn } from '../../../../utils/stylesUtils'
import { hebrew } from '../../../../i18n'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'

const getShortDayName = (locale, date) =>
  new Date(date).toLocaleDateString(locale, { weekday: 'long' }).slice(locale === 'en' ? 0 : 4, locale === 'en' ? 2 : 6)

const PoliciesDatePicker = ({ initDate, date, setDate, view = 'month', placeholder }) => {
  const [activeStartDate, setActiveStartDate] = useState(date ?? new Date(initDate))
  const {
    i18n: { language },
  } = useTranslation('common')

  return (
    <DatePicker
      onChange={val => {
        setDate(val)
        !val && setActiveStartDate(new Date(initDate))
      }}
      value={date}
      calendarIcon={<CalendarMonthOutlined />}
      clearIcon={date ? <Close /> : null}
      yearPlaceholder={placeholder}
      className={cn({ 'no-value': !date })}
      locale={language}
      showLeadingZeros
      shouldOpenCalendar={() => {
        setActiveStartDate(date ?? new Date(initDate))
        return true
      }}
      shouldCloseCalendar={({ reason }) => {
        reason === 'outsideAction' && setActiveStartDate(date ?? new Date(initDate))
        return reason === 'outsideAction' || reason === 'select'
      }}
      maxDetail={view}
      calendarProps={{
        next2Label: view === 'month' ? hebrew() ? <KeyboardDoubleArrowLeft /> : <KeyboardDoubleArrowRight /> : null,
        prev2Label: view === 'month' ? hebrew() ? <KeyboardDoubleArrowRight /> : <KeyboardDoubleArrowLeft /> : null,
        nextLabel: hebrew() ? <KeyboardArrowLeft /> : <KeyboardArrowRight />,
        prevLabel: hebrew() ? <KeyboardArrowRight /> : <KeyboardArrowLeft />,
        formatShortWeekday: (locale, date) => getShortDayName(locale, date),
        activeStartDate: activeStartDate,
        onActiveStartDateChange: ({ activeStartDate }) => setActiveStartDate(activeStartDate),
        goToRangeStartOnSelect: true,
      }}
    />
  )
}

export default PoliciesDatePicker
