import classNames from 'classnames'
import { useState, useEffect, useRef, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import ButtonBack from '../../../../components/ButtonBack/ButtonBack'
import Loader from '../../../../components/Common/Loader/Loader'
import { getInsuranceFieldsCompanies } from '../../../../http/requests'
import './StepCompanies.scss'

const StepCompanies = ({ nextStep, prevStep, data, setData }) => {
  const { selectedFields, selectedCompanies } = data
  const [fieldsCompanies, setFieldsCompanies] = useState([])
  const [serverError, setServerError] = useState(null)
  const [loading, setLoading] = useState(true)
  const refs = useRef([])
  const {
    t,
    i18n: { language: lang },
  } = useTranslation('registration')
  const hebrew = lang === 'he'

  const submitHandler = async e => {
    nextStep()
  }

  const selectCompanyHandler = (fieldId, companyId) => e => {
    setData(prev => {
      const { selectedCompanies } = prev
      return {
        ...prev,
        selectedCompanies: selectedCompanies.map(c => {
          return {
            ...c,
            insuranceFields: c.insuranceFields.map(f => {
              return {
                ...f,
                selected: c.companyId === companyId && f.id === fieldId ? !f.selected : f.selected,
              }
            }),
          }
        }),
      }
    })
  }

  const selectAllComapanies = (fieldId, companies) => {
    const allFieldCompaniesSelected = selectedCompanies
      .filter(c => companies.includes(c.companyId))
      .flatMap(fc => fc.insuranceFields)
      .filter(f => f.id === fieldId)
      .map(f => f.selected)
      .every(f => f)

    setData(prev => {
      const { selectedCompanies } = prev
      return {
        ...prev,
        selectedCompanies: selectedCompanies.map(c => {
          if (!companies.includes(c.companyId)) {
            return c
          }
          return {
            ...c,
            insuranceFields: allFieldCompaniesSelected
              ? c.insuranceFields.map(f => (f.id === fieldId ? { ...f, selected: false } : f))
              : c.insuranceFields.map(f => (f.id === fieldId ? { ...f, selected: true } : f)),
          }
        }),
      }
    })
  }

  const selectedCompanyHasField = (companyId, fieldId) => {
    const company = selectedCompanies.map(c => c.companyId).includes(companyId)
    if (company) {
      const field = selectedCompanies
        .find(c => c.companyId === companyId)
        .insuranceFields.find(f => f.id === fieldId && f.selected)
      if (field) {
        return true
      }
    }
    return false
  }

  const hasSelectedCompanies = () => selectedCompanies.filter(c => c.insuranceFields.some(f => f.selected)).length > 0
  const scrollHandler = i => {
    refs.current[i]?.scrollIntoView({ block: 'start' })
  }

  useEffect(() => {
    const getFieldsCompanies = async () => {
      try {
        const {
          data: { Data: data, HasError: hasError },
        } = await getInsuranceFieldsCompanies(selectedFields)
        if (hasError) {
          setServerError('Error')
        } else {
          setFieldsCompanies(data)
          setData(prev => ({ ...prev, fieldsCompanies: data }))
          setServerError(null)
        }
      } catch (err) {
        setServerError(err.message)
      } finally {
        setLoading(false)
      }
    }
    getFieldsCompanies()
  }, [setData, selectedFields])

  useEffect(() => {
    let companies = []
    fieldsCompanies.forEach(field => {
      let companiesIds = companies.map(c => c.companyId)
      field.Value.forEach(fc => {
        if (companiesIds.includes(fc.Id)) {
          companies.find(c => c.companyId === fc.Id).insuranceFields.push({ id: field.Key.Id, selected: true })
        } else {
          companies.push({
            companyId: fc.Id,
            insuranceFields: [{ id: field.Key.Id, selected: true }],
          })
        }
      })
    })
    setData(prev => ({ ...prev, selectedCompanies: companies }))
  }, [fieldsCompanies, setData])

  return (
    <div className='register-step step-companies'>
      <div className='header'>
        <h2>{t('stepCompanies.heading')}</h2>
        <h3>{t('stepCompanies.subHeading')}</h3>
      </div>

      <div className={classNames('insurance-fields-container', { 'list-loading': loading })}>
        {loading && <Loader />}
        {!loading &&
          fieldsCompanies.map((field, i) => (
            <Fragment key={field.Key.Id}>
              <div className='insurance-field-header'>
                <div className='insurance-field-header-title'>
                  <span className='insurance-field-header-title-text' onClick={() => scrollHandler(i)}>
                    {hebrew ? field.Key.Name : field.Key.EngilshName}
                  </span>
                  <span> </span>
                  <span className='insurance-field-header-title-count'>
                    ({selectedCompanies.filter(c => selectedCompanyHasField(c.companyId, field.Key.Id)).length})
                  </span>
                </div>
                <button
                  className='select-all-companies'
                  onClick={() =>
                    selectAllComapanies(
                      field.Key.Id,
                      field.Value.map(c => c.Id)
                    )
                  }
                >
                  {t('btnSelectAll')}
                  <img src='./assets/checkbox-lg-disabled/Square.png' alt='' />
                </button>
              </div>
              <div className='insurance-field' key={field.Key.Id} ref={e => (refs.current[i] = e)}>
                <div className='list'>
                  {field.Value.map(company => (
                    <div className='list-item-container' key={company.Id + field.Key.Id}>
                      <input
                        type='checkbox'
                        id={company.Name + field.Key.Id}
                        name='companyName'
                        checked={selectedCompanyHasField(company.Id, field.Key.Id)}
                        onChange={selectCompanyHandler(field.Key.Id, company.Id)}
                      />
                      <label htmlFor={company.Name + field.Key.Id}>
                        <div className='company-info'>
                          <div className='company-avatar'>
                            <img src={`./assets/companies-logos-light/${company.Id}.png`} alt='' />
                          </div>
                          <div className='company-name'>
                            <div>{hebrew ? company.Name : company.EnglishName}</div>
                          </div>
                        </div>
                        <img
                          src={`./assets/checkbox-lg-${
                            selectedCompanyHasField(company.Id, field.Key.Id) ? 'checked' : 'disabled'
                          }/Square.png`}
                          alt=''
                        />
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </Fragment>
          ))}
      </div>

      <div className='footer'>
        <div className='form-buttons-container'>
          <ButtonBack onClick={prevStep} mobileStyle={true} />
          <button className='form-button' onClick={submitHandler} disabled={!hasSelectedCompanies() || loading}>
            {t('btnNext')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default StepCompanies
