import classNames from 'classnames'
import Loader from '../Loader/Loader'
import './Button.css'

const Button = ({
  caption = 'Button',
  onClick,
  disabled = false,
  typeSubmit = false,
  styles = '',
  showLoader,
}) => {
  return (
    <div>
      <button
        className={classNames('button', styles.split(' '), {
          'show-loader': showLoader,
        })}
        disabled={disabled}
        onClick={onClick}
        {...(typeSubmit && { type: 'submit' })}
      >
        {showLoader ? (
          <div>
            <Loader />
            <div>{caption}</div>
          </div>
        ) : (
          caption
        )}
      </button>
    </div>
  )
}

export default Button
