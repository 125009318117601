import classNames from 'classnames'
import './Loader.scss'

const Loader = ({ xs = false, small = false, color }) => (
  <div className='loader-container'>
    <div
      className={classNames('loader', { 'extra-small': xs, small: small })}
      style={{ ...(color && { borderTopColor: color }) }}
    ></div>
  </div>
)

export default Loader
