import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from '@mui/icons-material'
import { hebrew } from '../../../i18n'
import './CalendarBase.scss'
import Calendar from 'react-calendar'
import { useTranslation } from 'react-i18next'

const getShortDayName = (locale, date) =>
  new Date(date).toLocaleDateString(locale, { weekday: 'long' }).slice(locale === 'en' ? 0 : 4, locale === 'en' ? 2 : 6)

const CalendarBase = ({ view = 'month', date, setDate }) => {
  const {
    i18n: { language },
  } = useTranslation()
  return (
    <div className='calendar-base-wrapper'>
      <Calendar
        next2Label={view === 'month' ? hebrew() ? <KeyboardDoubleArrowLeft /> : <KeyboardDoubleArrowRight /> : null}
        prev2Label={view === 'month' ? hebrew() ? <KeyboardDoubleArrowRight /> : <KeyboardDoubleArrowLeft /> : null}
        nextLabel={hebrew() ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        prevLabel={hebrew() ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        formatShortWeekday={(locale, date) => getShortDayName(locale, date)}
        view={view}
        locale={language}
        maxDetail={view}
        value={date}
        onChange={setDate}
      />
    </div>
  )
}

export default CalendarBase
