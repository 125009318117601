import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ButtonRounded from '../../../../components/Common/ButtonRounded/ButtonRounded'
import ButtonText from '../../../../components/Common/ButtonText/ButtonText'
import ArrowRightIconSvg from '../../../../components/Common/SvgIcons/ArrowRightIconSvg/ArrowRightIconSvg'
import InputText from '../../../../components/Common/InputText/InputText'
import { getInsuranceFields, getInsuranceFieldsCompanies } from '../../../../http/requests'
import Loader from '../../../../components/Common/Loader/Loader'
import { hebrew } from '../../../../i18n'
import ButtonCheckbox from '../../../../components/Common/ButtonCheckbox/ButtonCheckbox'
import classNames from 'classnames'
import { insuranceField } from '../../../../types/insuranceField'
import { addSubagencyRequest, editSubagencyRequest } from '../../../../http/requests/agencyHouseRequests'
import ConfirmDialog from '../../../../components/ConfirmDialog/ConfirmDialog'
import AgencyFieldChip from '../AgencyFieldChip/AgencyFieldChip'
import './AddNewAgencyForm.scss'

const AddNewAgencyForm = ({ stepBack, nextStep, setNewAgency, setAddSuccess, agencyToEdit }) => {
  const { t } = useTranslation('agencyHouse')
  const [fields, setFields] = useState([])
  const [fieldsLoading, setFieldsLoading] = useState(true)
  const [selectedFields, setSelectedFields] = useState([])
  const [companies, setCompanies] = useState([])
  const [companiesLoading, setCompaniesLoading] = useState(true)
  const [selectedCompanies, setSelectedCompanies] = useState([])
  const [submitLoading, setSubmitLoading] = useState(false)
  const [agency, setAgency] = useState({
    agencyName: agencyToEdit?.AgencyName ?? '',
    agencyNumber: agencyToEdit?.AgencyLicenseNumber ?? '',
  })
  const [showCancelDialog, setShowCancelDialog] = useState(false)
  const [formIsDirty, setFormIsDirty] = useState(false)

  const submitDisabled =
    !formIsDirty ||
    agency.agencyNumber.length === 0 ||
    agency.agencyNumber.length === 0 ||
    selectedCompanies.flatMap(c => c.insuranceFields).filter(f => selectedFields.includes(f.id) && f.selected)
      .length === 0 ||
    submitLoading

  const onChangeHandler = dataType => value => {
    setFormIsDirty(true)
    setAgency(prev => ({
      ...prev,
      [dataType]: value,
    }))
  }

  const selectField = id => {
    setFormIsDirty(true)
    setSelectedFields(prev => (prev.includes(id) ? prev.filter(pId => pId !== id) : [...prev, id]))
  }

  const selectCompanyField = (companyId, fieldId) => {
    setFormIsDirty(true)
    setSelectedCompanies(prev =>
      prev.map(c => ({
        ...c,
        insuranceFields:
          c.companyId === companyId
            ? c.insuranceFields.map(f => (f.id === fieldId ? { ...f, selected: !f.selected } : f))
            : c.insuranceFields,
      }))
    )
  }

  const submitHandler = async () => {
    const dto = {
      ...(agencyToEdit && { id: agencyToEdit.AgencyId }),
      ...agency,
      companies: selectedCompanies
        .map(c => ({
          companyId: c.companyId,
          insuranceFields: c.insuranceFields.filter(f => selectedFields.includes(f.id) && f.selected).map(f => f.id),
        }))
        .filter(c => c.insuranceFields.length > 0),
    }
    try {
      setNewAgency(null)
      setSubmitLoading(true)
      setAddSuccess(false)
      const request = agencyToEdit ? editSubagencyRequest : addSubagencyRequest
      const {
        data: { HasError },
      } = await request(dto)
      if (!HasError) {
        setAddSuccess(true)
        setNewAgency({
          ...agencyToEdit,
          ...agency,
          fields: fields.filter(f => selectedFields.includes(f.Id)),
          companies: selectedCompanies
            .map(c => ({
              ...c,
              insuranceFields: c.insuranceFields
                .filter(f => selectedFields.includes(f.id) && f.selected)
                .map(f => f.id),
            }))
            .filter(c => c.insuranceFields.length > 0),
        })
        nextStep()
      }
      setSubmitLoading(false)
    } catch (error) {
      console.log(error)
      setSubmitLoading(false)
    }
  }

  const fetchData = async (setLoading, setData, request, requestParams) => {
    try {
      setLoading(true)
      const {
        data: { Data: data },
      } = await request(requestParams)
      setData(data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData(setFieldsLoading, setFields, getInsuranceFields)
  }, [])

  useEffect(() => {
    if (fields.length > 0) {
      const fieldsIds = fields.map(({ Id }) => Id)
      setSelectedFields(agencyToEdit ? agencyToEdit.Fields.map(f => f.Id) : fieldsIds)
      fetchData(setCompaniesLoading, setCompanies, getInsuranceFieldsCompanies, fieldsIds)
    }
  }, [fields, agencyToEdit])

  useEffect(() => {
    const isFieldSelected = companyId =>
      agencyToEdit ? agencyToEdit.Companies.map(c => c.Id).includes(companyId) : true
    let selectedCompanies = []
    companies.forEach(field => {
      let companiesIds = selectedCompanies.map(c => c.companyId)
      field.Value.forEach(fc => {
        if (companiesIds.includes(fc.Id)) {
          selectedCompanies
            .find(c => c.companyId === fc.Id)
            .insuranceFields.push({ id: field.Key.Id, selected: isFieldSelected(fc.Id) })
        } else {
          selectedCompanies.push({
            ...fc,
            companyId: fc.Id,
            insuranceFields: [{ id: field.Key.Id, selected: isFieldSelected(fc.Id) }],
          })
        }
      })
    })
    setSelectedCompanies(selectedCompanies.sort((a, b) => a.companyId - b.companyId))
  }, [companies, agencyToEdit])

  return (
    <>
      <div className='add-new-agency-form-container'>
        <div className='add-new-agency-form-body'>
          <div className='add-new-agency-form-grid-row'>
            <div className='add-new-agency-form-grid-row-desc'>
              <h4>{t(`addAgencyStep.addNewAgencyForm.title${agencyToEdit ? 'Edit' : ''}`)}</h4>
              <p>{t('addAgencyStep.addNewAgencyForm.subtitle')}</p>
            </div>
          </div>
          <div className='add-new-agency-form-grid-row'>
            <div className='add-new-agency-form-grid-row-desc'>
              <h5>{t('addAgencyStep.addNewAgencyForm.details')}</h5>
            </div>
            <div className='add-new-agency-form-grid-row-content details-inputs'>
              <InputText
                autoFocus={true}
                disabled={submitLoading}
                inputName='agencyName'
                id='agencyName'
                label={t('addAgencyStep.addNewAgencyForm.agencyName')}
                value={agency.agencyName}
                onChange={onChangeHandler('agencyName')}
              />
              <InputText
                disabled={submitLoading}
                inputName='agencyNumber'
                id='agencyNumber'
                label={t('addAgencyStep.addNewAgencyForm.license')}
                value={agency.agencyNumber}
                onChange={onChangeHandler('agencyNumber')}
                inputType='number'
              />
            </div>
          </div>
          <div className='add-new-agency-form-grid-row'>
            <div className='add-new-agency-form-grid-row-desc'>
              <h5>{t('addAgencyStep.addNewAgencyForm.fields')}</h5>
              <p>{t('addAgencyStep.addNewAgencyForm.fieldsRemark')}</p>
            </div>
            <div className='add-new-agency-form-grid-row-content fields-inputs'>
              {fieldsLoading ? (
                <Loader />
              ) : (
                fields.map(f => (
                  <div
                    key={f.Id}
                    className={classNames('add-new-agency-form-grid-row-item', {
                      selected: selectedFields.includes(f.Id),
                    })}
                  >
                    <div>
                      {insuranceField[f.Id].svgIcon}
                      <p>{hebrew() ? f.Name : f.EngilshName}</p>
                    </div>
                    <ButtonCheckbox
                      disabled={submitLoading}
                      checked={selectedFields.includes(f.Id)}
                      onClick={() => selectField(f.Id)}
                    />
                  </div>
                ))
              )}
            </div>
          </div>
          <div className='add-new-agency-form-grid-row'>
            <div className='add-new-agency-form-grid-row-desc'>
              <h5>{t('addAgencyStep.addNewAgencyForm.companies')}</h5>
              <p>{t('addAgencyStep.addNewAgencyForm.companiesRemark')}</p>
            </div>
            <div className='add-new-agency-form-grid-row-content companies-inputs'>
              {companiesLoading ? (
                <Loader />
              ) : (
                selectedCompanies.map(c => (
                  <div key={c.companyId} className={classNames('add-new-agency-form-grid-row-item', {})}>
                    <div className='company-info'>
                      <div>
                        <img src={`./assets/companies-logos-light/${c.companyId}.png`} alt={c.name} />
                      </div>
                      <p>{hebrew() ? c.Name : c.EnglishName}</p>
                    </div>
                    <div className='company-fields'>
                      {c.insuranceFields.map(f => (
                        <AgencyFieldChip
                          key={f.id}
                          onClick={() => selectCompanyField(c.companyId, f.id)}
                          checked={f.selected && selectedFields.includes(f.id)}
                          disabled={!selectedFields.includes(f.id) || submitLoading}
                        >
                          {hebrew() ? insuranceField[f.id].name : insuranceField[f.id].nameEn}
                        </AgencyFieldChip>
                      ))}
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
        <footer className='add-new-agency-form-footer'>
          {stepBack && (
            <ButtonText onClick={() => (formIsDirty ? setShowCancelDialog(true) : stepBack())} disabled={submitLoading}>
              <ArrowRightIconSvg />
              <div>{t('addAgencyStep.addNewAgencyForm.addNewAgencyBackBtn')}</div>
            </ButtonText>
          )}
          <ButtonRounded disabled={submitDisabled} onClick={submitHandler}>
            {t(`addAgencyStep.addNewAgencyForm.${agencyToEdit ? 'edit' : 'addNew'}AgencyBtn`)}
          </ButtonRounded>
        </footer>
      </div>
      {showCancelDialog && (
        <ConfirmDialog
          confirm={stepBack}
          decline={() => {
            setShowCancelDialog(false)
          }}
          title={t('addAgencyStep.cancelDialogTitle')}
          text={t('addAgencyStep.cancelDialogText')}
          confirmText={t('addAgencyStep.cancelDialogOkBtn')}
          declineText={t('addAgencyStep.cancelDialogCancelBtn')}
        />
      )}
    </>
  )
}

export default AddNewAgencyForm
