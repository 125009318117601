import { CloseOutlined } from '@mui/icons-material'
import ModalWindow from '../../../../components/ModalWindow/ModalWindow'
import { useTranslation } from 'react-i18next'
import { scanStatusType } from '../../../../types/scanStatus'
import ButtonRounded from '../../../../components/Common/ButtonRounded/ButtonRounded'
import { useEffect, useRef, useState } from 'react'
import { companyAuthType } from '../../../../types/companyAuthType'
import { cn } from '../../../../utils/stylesUtils'
import './ScanCompanyPopupSms.scss'

const ScanCompanyPopupSms = ({
  sendSmsHandler,
  scanStatus,
  company,
  closePopup,
  authType = companyAuthType.all,
  requestError,
  sendError,
  requestLoadingStatus,
  sendLoadingStatus,
  user,
}) => {
  const { t, i18n } = useTranslation('scan')
  const inputsLength = company.smsLettersCount
  const [sms, setSms] = useState([...Array(inputsLength)])

  const incorrectCode = scanStatus === scanStatusType.IncorrectSMSCode
  const unexpectedPage = scanStatus === scanStatusType.UnexpectedPage
  const waitingTimeout = scanStatus === scanStatusType.WaitingSmsTimeOut

  const refs = useRef([])

  const submitHandler = e => {
    e.preventDefault()
    sendSmsHandler(user.id, sms.join(''))
  }

  const numbersOnlyEventHandler = index => e => {
    e.target.value = e.target.value.replace(/\D/g, '')
    if (e.target.value.length !== 0 && refs.current[index + 1] !== undefined) {
      refs.current[index + 1].focus()
    }
  }

  const keyDownHandler = index => e => {
    const { key } = e
    if (key === 'Backspace') {
      if (e.target.value.length === 0 && refs.current[index - 1] !== undefined) {
        refs.current[index - 1].focus()
      }
      return
    }
    if (key === 'ArrowLeft') {
      if (refs.current[index - 1] !== undefined) {
        refs.current[index - 1].focus()
      }
      return
    }
    if (key === 'ArrowRight') {
      if (refs.current[index + 1] !== undefined) {
        refs.current[index + 1].focus()
      }
      return
    }
    if (key === 'Delete') {
      if (e.target.value.length === 0 && refs.current[index + 1] !== undefined) {
        refs.current[index + 1].focus()
      }
      return
    }
    if (e.target.value.length > 0) {
      if (refs.current[index + 1] !== undefined) {
        refs.current[index + 1].focus()
      }
      return
    }
  }

  const hasEmptyFields = () => sms.some(ch => ch === undefined || ch === '')

  useEffect(() => {
    refs.current[0].focus()
  }, [])

  return (
    <ModalWindow renderPortal showCloseIcon={false} loadingOverlay={sendLoadingStatus}>
      <div className='scan-company-popup-sms-error'>
        <header>
          <div>
            <p>{user?.name}</p>
            <p>{t('credentials.waitingSmsPopupHedaing')}</p>
          </div>
          <button onClick={closePopup}>
            <CloseOutlined />
          </button>
        </header>
        <p>{t('credentials.waitingSmsInputsHedaing')}</p>
        <div className='input-sms-form-container'>
          <form action='' autoComplete='off' onSubmit={submitHandler}>
            <div
              className={cn('input-sms-inputs-container', {
                'error-code': incorrectCode,
              })}
              dir='ltr'
            >
              {[...Array(inputsLength)].map((_, i) => (
                <input
                  ref={e => (refs.current[i] = e)}
                  disabled={incorrectCode}
                  key={`digit${i + 1}`}
                  type='text'
                  name={`digit${i + 1}`}
                  id={`digit${i + 1}`}
                  placeholder={sms.length > 0 && sms[i] ? sms[i] : '*'}
                  maxLength='1'
                  onInput={numbersOnlyEventHandler(i)}
                  onKeyDown={keyDownHandler(i)}
                  value={sms[i] ?? ''}
                  onChange={e => setSms(prev => prev.map((ch, idx) => (idx === i ? e.target.value : ch)))}
                />
              ))}
              {(incorrectCode && (
                <div className='input-sms-form-error'>{t('mainContainer.smsStage.incorrectCode')}</div>
              )) ||
                (unexpectedPage && (
                  <div className='input-sms-form-error'>
                    <span>{t('mainContainer.smsStage.unexpectedPage')}</span>
                    <span>
                      {t('mainContainer.credentials.proceedToCompany', {
                        companyName: i18n.language === 'he' ? company.name : company.nameEn,
                      })}
                      <a href={company.website} target='_blank' rel='noopener noreferrer'>
                        {t('mainContainer.credentials.clickHere')}
                      </a>
                    </span>
                  </div>
                )) ||
                (waitingTimeout && (
                  <div className='input-sms-form-error'>{t('mainContainer.smsStage.waitingTimeout')}</div>
                )) ||
                (requestError && <div className='input-sms-form-error'>Server error</div>) ||
                (sendError && (
                  <div className='input-sms-form-error'>
                    {Object.keys(sendError).length !== 0 ? sendError : 'server error'}
                  </div>
                ))}
            </div>
            <div className='input-sms-button-container'>
              <ButtonRounded
                disabled={
                  (hasEmptyFields() && !incorrectCode && !waitingTimeout) || requestLoadingStatus || sendLoadingStatus
                }
                typeSubmit={true}
              >
                {t('credentials.sendSmsBtn')}
              </ButtonRounded>
            </div>
          </form>
        </div>
      </div>
    </ModalWindow>
  )
}

export default ScanCompanyPopupSms
