import classNames from 'classnames'
import { useRef, useState, useLayoutEffect, useEffect } from 'react'
import ContextMenu from '../ContextMenu/ContextMenu'
import MeatBalls from '../MeatBalls/MeatBalls'
import './MeatBallsMenu.scss'

const MeatBallsMenu = ({ menuItems = [], menuSize = 'md', children, positionFixed = false }) => {
  const menuRef = useRef()
  const meatBallsRef = useRef()
  const [showMenu, setShowMenu] = useState(false)
  const [menuX, setMenuX] = useState(null)
  const [menuY, setMenuY] = useState(null)
  let positionTop = false
  const menuClickHanlder = () => setShowMenu(!showMenu)

  if (showMenu) {
    const windowHeight = window.innerHeight
    const widndowBottomPadding = 50
    const menuBottom = menuRef?.current?.getBoundingClientRect().bottom
    const paddingTop = 10
    const paddingBottom = 10
    const menuItemHeight = 48
    const menuHeight = paddingTop + menuItemHeight * menuItems.length + paddingBottom
    positionTop = windowHeight - widndowBottomPadding - menuBottom < menuHeight
  }

  const setPosition = () => {
    if (meatBallsRef.current) {
      const { x, y, height, width } = meatBallsRef.current.getBoundingClientRect()
      setMenuX(x + width / 2)
      setMenuY(y + height / 2)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', setPosition, true)
    return () => {
      window.removeEventListener('scroll', setPosition, true)
    }
  }, [])

  useLayoutEffect(() => {
    showMenu && setPosition()
  }, [showMenu])

  return (
    <div className='meatballs-menu-container' ref={menuRef}>
      <MeatBalls
        showBackground={false}
        onClick={menuItems.length > 0 ? menuClickHanlder : () => {}}
        opened={showMenu}
        size={menuSize}
        meatBallsRef={meatBallsRef}
      />
      {showMenu && (
        <div
          className={classNames('meatballs-menu', { 'position-top': positionTop })}
          style={{
            ...(positionFixed && {
              position: menuX && menuY ? 'fixed' : 'absolute',
              insetInlineEnd: menuX ?? '50%',
              insetInlineStart: menuX ?? '50%',
              insetBlockStart: menuY ?? '50%',
            }),
          }}
        >
          <ContextMenu closeMenu={() => setShowMenu(false)} menuItems={menuItems} />
          {children}
        </div>
      )}
    </div>
  )
}

export default MeatBallsMenu
