export const twoFactorOption = {
  byPhone: 1,
  byEmail: 2,
}

export const twoFactorOptions = {
  [twoFactorOption.byPhone]: {
    id: twoFactorOption.byPhone,
    name: 'דרך טלפון',
    nameEn: 'by phone',
    inputType: 'tel',
  },
  [twoFactorOption.byEmail]: {
    id: twoFactorOption.byEmail,
    name: 'דרך אי־מייל',
    nameEn: 'by email',
    inputType: 'email',
  },
}
