export const REPORTS_DATES_REQUEST = 'REPORTS_DATES_REQUEST'
export const REPORTS_DATES_SUCCESS = 'REPORTS_DATES_SUCCESS'
export const REPORTS_DATES_FAIL = 'REPORTS_DATES_FAIL'
export const REPORTS_DATE_SELECT = 'REPORTS_DATE_SELECT'
export const REPORTS_DATE_SELECT_LATEST = 'REPORTS_DATE_SELECT_LATEST'

export const REPORTS_MONTH_STATUS_REQUEST = 'REPORTS_MONTH_STATUS_REQUEST'
export const REPORTS_MONTH_STATUS_SUCCESS = 'REPORTS_MONTH_STATUS_SUCCESS'
export const REPORTS_MONTH_STATUS_FAIL = 'REPORTS_MONTH_STATUS_FAIL'
export const COMPANY_MONTH_REPORTS_COUNT_UPDATE = 'COMPANY_MONTH_REPORTS_COUNT_UPDATE'

export const SCAN_RESET_ALL = 'SCAN_RESET_ALL'
