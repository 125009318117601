import { useSelector } from 'react-redux'
import './AgenciesGroupsBanner.scss'
import { getUserNameInitials } from '../../../../services/getUserNameInitials'

const AgenciesGroupsBanner = () => {
  const { favoriteGroups, allGroups, selectedGroups } = useSelector(({ agentHouse }) => agentHouse)

  if (selectedGroups.length === 0) {
    return null
  }

  return (
    <div className='agencies-groups-banner'>
      {[...favoriteGroups, ...allGroups]
        .filter(gr => selectedGroups.includes(gr.id))
        .map(gr => (
          <AgenciesGroupsBannerItem key={gr.id} item={gr} />
        ))}
    </div>
  )
}

export default AgenciesGroupsBanner

const AgenciesGroupsBannerItem = ({ item }) => (
  <div className='agencies-groups-banner-item'>
    <AgenciesGroupsBannerItemAvatar names={item.agencies} />
    <p title={item.agencies.map(n => n.name).join(',')}>
      {item.agencies
        .slice(0, 2)
        .map(n => n.name)
        .join(',')}
      {item.agencies.length > 2 && `, +${item.agencies.length - 2}`}
    </p>
  </div>
)

const AgenciesGroupsBannerItemAvatar = ({ names }) => (
  <div className='agencies-groups-banner-avatar-container'>
    {names.length > 1 ? (
      <>
        <div
          className='agencies-groups-banner-avatar-sm'
          style={{
            backgroundColor: names[0].backgroundColor,
          }}
        >
          {getUserNameInitials(names[0]?.name)}
        </div>
        <div className='agencies-groups-banner-avatar-sm' style={{ backgroundColor: names[1]?.backgroundColor }}>
          <span style={{ direction: 'ltr' }}>
            {names.length > 2 ? `+${names.length - 2}` : getUserNameInitials(names[1]?.name)}
          </span>
        </div>
      </>
    ) : (
      <div className='agencies-groups-banner-avatar' style={{ backgroundColor: names[0].backgroundColor }}>
        {getUserNameInitials(names[0].name)}
      </div>
    )}
  </div>
)
