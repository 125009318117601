import './ButtonBack.scss'
import {isMobile} from "react-device-detect";

const ButtonBack = ({ onClick = () => {}, mobileStyle = false }) => {
  const clickHandler = e => {
    e.preventDefault()
    onClick()
  }
  return (
    <button className='button-back' onClick={clickHandler}>
      { mobileStyle && isMobile ?
          (<img src='./assets/_early/back-arrow.svg' alt='back' />)
          :
          (<img src='./assets/arrow-square-right/Right Square.png' alt='back' />)
      }

    </button>
  )
}

export default ButtonBack
