const ArrowRightIconSvg = ({ fill = 'var(--text-primary-color)' }) => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M9.99998 3.3335L8.82498 4.5085L13.475 9.16683H3.33331V10.8335H13.475L8.82498 15.4918L9.99998 16.6668L16.6666 10.0002L9.99998 3.3335Z'
      fill={fill}
    />
  </svg>
)

export default ArrowRightIconSvg
