import { useState } from 'react'
import { allPoliciesListType } from '../../../../types/tablesTypes'
import { formatAsCurrency, formatAsPercent, formatAsShortDate } from '../../../../utils/formatAs'
import { cn } from '../../../../utils/stylesUtils'
import PolicyCard from '../PolicyCard/PolicyCard'
import PolicyListItemCard from '../PolicyListItemCard/PolicyListItemCard'
import PolicyStatusChip from '../../../../components/Common/PolicyStatusChip/PolicyStatusChip'
import { policyStatus } from '../../../../types/policyStatusType'
import EntityStatusChip from '../../../../components/Common/EntityStatusChip/EntityStatusChip'
import { entityStatusType } from '../../../../types/entityStatusType'
import './PoliciesAllListItem.scss'
import { hebrew } from '../../../../i18n'
import { useTranslation } from 'react-i18next'
import PoliciesSyncIcon from '../PoliciesSyncIcon/PoliciesSyncIcon'
import { commissionKeySuffix, deltaCommissionSplitView } from '../../../shared/reducers/sharedSelectors'

const PoliciesAllListItem = ({ policy, listType, classes, selectPolicy, selected }) => {
  const { t } = useTranslation('policies')
  const [showPolicyCard, setShowPolicyCard] = useState(false)
  const elementaryListType = listType === allPoliciesListType.elementary
  const keySuffix = commissionKeySuffix()
  let cells
  switch (listType) {
    case allPoliciesListType.life:
    case allPoliciesListType.elementary:
    default:
      cells = (
        <>
          <div>{policy.policyNumber ?? '--'}</div>
          <div>{policy.customerName ?? '--'}</div>
          <div>{policy.customerIdNumber ?? '--'}</div>
          <div>{policy.company ?? '--'}</div>
          <div>{policy.category[`name${hebrew() ? '' : 'En'}`] ?? '--'}</div>
          <div>{formatAsShortDate(policy.startDate) ?? '--'}</div>
          {elementaryListType && <div>{formatAsShortDate(policy.endDate) ?? '--'}</div>}
          <div>{policy.policyAge ?? '--'}</div>
          {deltaCommissionSplitView() && (
            <div className='financial-value-cell'>
              {formatAsCurrency(policy['commissionAgency'], { dashesForZero: false })}
            </div>
          )}
          <div className='financial-value-cell'>
            {formatAsCurrency(policy['commission' + keySuffix], { dashesForZero: false })}
          </div>
          <div className='financial-value-cell'>{formatAsCurrency(policy.premium, { dashesForZero: false })}</div>
          {!elementaryListType && (
            <>
              <div className='financial-value-cell'>
                {formatAsCurrency(policy.accumulation, { dashesForZero: false })}
              </div>
              {deltaCommissionSplitView() && (
                <div className='commission-rate-cells'>
                  <div className='financial-value-cell'>
                    {formatAsPercent(policy['commissionRatePremiumAgency'], { dashesForZero: false })}
                  </div>
                  <div className='financial-value-cell'>
                    {formatAsPercent(policy['commissionRateAccumulationAgency'], {
                      maximumFractionDigits: 4,
                      dashesForZero: false,
                    })}
                  </div>
                </div>
              )}
              <div className='commission-rate-cells'>
                <div className='financial-value-cell'>
                  {formatAsPercent(policy['commissionRatePremium' + keySuffix], { dashesForZero: false })}
                </div>
                <div className='financial-value-cell'>
                  {formatAsPercent(policy['commissionRateAccumulation' + keySuffix], {
                    maximumFractionDigits: 4,
                    dashesForZero: false,
                  })}
                </div>
              </div>
            </>
          )}
          {elementaryListType && (
            <>
              {deltaCommissionSplitView() && (
                <div className='financial-value-cell'>
                  {formatAsCurrency(policy['commissionMonthlyAgency'], { dashesForZero: false })}
                </div>
              )}
              <div className='financial-value-cell'>
                {formatAsCurrency(policy['commissionMonthly' + keySuffix], { dashesForZero: false })}
              </div>
              <div className='financial-value-cell'>
                {formatAsCurrency(policy.premiumMonthly, { dashesForZero: false })}
              </div>
              {deltaCommissionSplitView() && (
                <div className='financial-value-cell'>
                  {formatAsPercent(policy['commissionRateAgency'], { dashesForZero: false })}
                </div>
              )}
              <div className='financial-value-cell'>
                {formatAsPercent(policy['commissionRate' + keySuffix], { dashesForZero: false })}
              </div>
            </>
          )}
        </>
      )
      break

    case allPoliciesListType.specialPayments:
      cells = (
        <>
          <div>{policy.paymentIdentifierNumber ?? '--'}</div>
          <div>{policy.paymentName ?? '--'}</div>
          <div>{policy.customerIdNumber ?? '--'}</div>
          <div>{policy.company ?? '--'}</div>
          <div>{policy.category ?? '--'}</div>
          <div>{formatAsShortDate(policy.monthOfPayment) ?? '--'}</div>
          {deltaCommissionSplitView() && (
            <div className='financial-value-cell'>
              {formatAsCurrency(policy['commissionAgency'], { dashesForZero: false })}
            </div>
          )}
          <div className='financial-value-cell'>
            {formatAsCurrency(policy['commission' + keySuffix], { dashesForZero: false })}
          </div>
        </>
      )
  }
  return (
    <>
      <PolicyListItemCard
        classes={cn(classes, 'policies-all-list-item', policyStatus[policy.status]?.type, {
          outdated: policy.isOutdated,
        })}
        selectable={listType !== allPoliciesListType.specialPayments}
        onSelect={() => selectPolicy(policy.id)}
        selected={selected}
        {...(listType !== allPoliciesListType.specialPayments && { onClick: () => setShowPolicyCard(true) })}
      >
        {cells}
        {listType !== allPoliciesListType.specialPayments && (
          <>
            <div className='policy-badges'>
              <PolicyStatusChip status={policy.status} />
              {!policy.customerIdNumber && <EntityStatusChip status={entityStatusType.lostData} />}
            </div>
            <div className='policies-all-list-item-icons'>
              {<PoliciesSyncIcon tooltip={t('list.tooltips.outdatedPolicy')} enabled={policy.isOutdated} />}
            </div>
          </>
        )}
      </PolicyListItemCard>
      {showPolicyCard && <PolicyCard closeDetails={() => setShowPolicyCard(false)} id={policy.id} />}
    </>
  )
}

export default PoliciesAllListItem
