import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ButtonRounded from '../../../../components/Common/ButtonRounded/ButtonRounded'
import ButtonText from '../../../../components/Common/ButtonText/ButtonText'
import ArrowRightIconSvg from '../../../../components/Common/SvgIcons/ArrowRightIconSvg/ArrowRightIconSvg'
import InputText from '../../../../components/Common/InputText/InputText'
import { getProfileInvitationCompanies } from '../../../../http/requests'
import Loader from '../../../../components/Common/Loader/Loader'
import { hebrew } from '../../../../i18n'
import ButtonCheckbox from '../../../../components/Common/ButtonCheckbox/ButtonCheckbox'
import classNames from 'classnames'
import InputRadio from '../../../../components/Common/InputRadio/InputRadio'
import { agencyPaymentType } from '../../../../types/agencyTypes'
import { userPreference, userPreferenceAccessType, userPreferenceType } from '../../../../types/agencyUserTypes'
import InputSelectAssociateCompanies from '../InputSelectAssociateCompanies/InputSelectAssociateCompanies'
import AgencyCompanyChip from '../AgencyCompanyChip/AgencyCompanyChip'
import CancelFilledIconSvg from '../../../../components/Common/SvgIcons/CancelFilledIconSvg/CancelFilledIconSvg'
import {
  addAssociatedAgencyRequest,
  addNewUserInviteRequest,
  checkExistingAgentRequest,
  checkExistingEmailRequest,
} from '../../../../http/requests/agencyHouseRequests'
import ConfirmDialog from '../../../../components/ConfirmDialog/ConfirmDialog'
import { buildFullPhoneNumber } from '../../../../utils/buildFullPhoneNumber'
import './AssociateAgencyForm.scss'

const buildPreferences = preferenceType =>
  Object.values(userPreference).filter(
    pr => pr.id !== userPreferenceType.all && pr.accessTypes.includes(preferenceType)
  )

const AssociateAgencyForm = ({ closeStepper, stepBack, setAssociatedAgency, setAssociateSuccess, nextStep }) => {
  const { t } = useTranslation('agencyHouse')
  const tAssociateAgencyForm = key => `${t('addAgencyStep.associateAgencyForm.' + key)}`
  const [companiesLoading, setCompaniesLoading] = useState(true)
  const [selectedCompanies, setSelectedCompanies] = useState([])
  const [submitLoading, setSubmitLoading] = useState(false)
  const [agent, setAgent] = useState({ email: '', phone: '' })
  const [paymentType, setPaymentType] = useState(null)
  const [dataPrefs, setDataPrefs] = useState(buildPreferences(userPreferenceAccessType.data))
  const [pagePrefs, setPagePrefs] = useState(
    buildPreferences(userPreferenceAccessType.page).filter(pr => pr.id !== userPreferenceType.download)
  )
  const [showCancelDialog, setShowCancelDialog] = useState(false)
  const [formIsDirty, setFormIsDirty] = useState(false)
  const [checkAgentLoading, setCheckAgentLoading] = useState(false)
  const [existingAgent, setExistingAgent] = useState(null)
  const [checkEmailLoading, setCheckEmailLoading] = useState(false)
  const [existingEmail, setExistingEmail] = useState(false)
  const [emailError, setEmailError] = useState(null)
  const [existingAgencyDbName, setExistingAgencyDbName] = useState(null)
  const [serverError, setServerError] = useState(null)

  const allCompaniesSelected = () => selectedCompanies.length === selectedCompanies.filter(c => c.selected).length

  const selectCompany = id => {
    setSelectedCompanies(prev => prev.map(c => ({ ...c, selected: c.id === id ? !c.selected : c.selected })))
  }

  const selectAllCompanies = () => {
    setSelectedCompanies(prev => prev.map(c => ({ ...c, selected: !allCompaniesSelected() })))
  }

  const selectPrefrence = (preferenceType, id) => {
    const setPrefs = preferenceType === userPreferenceAccessType.data ? setDataPrefs : setPagePrefs
    setPrefs(prev => prev.map(pr => ({ ...pr, selected: pr.id === id ? !pr.selected : pr.selected })))
  }

  const selectAllPrefrences = preferenceType => {
    const setPrefs = preferenceType === userPreferenceAccessType.data ? setDataPrefs : setPagePrefs
    setPrefs(prev => prev.map(pr => ({ ...pr, selected: !prev.every(p => p.selected) })))
  }

  const setPaymentTypeHandler = ({ target: { value } }) => {
    setPaymentType(+value)
  }

  const existingAgentWithValidEmail = Boolean(existingAgent && existingEmail && !checkAgentLoading)

  const submitDisabled =
    agent.email.length === 0 ||
    agent.phone.length === 0 ||
    !paymentType ||
    (existingAgent &&
      ([...dataPrefs, ...pagePrefs].filter(pr => pr.selected).length === 0 ||
        selectedCompanies.filter(c => c.selected).length === 0))

  const onChangeHandler = dataType => value => {
    setFormIsDirty(true)
    setServerError(null)
    setEmailError(null)
    if (dataType === 'phone') {
      const phoneNumber = buildFullPhoneNumber(value)
      if (phoneNumber.length < 13) {
        setCheckAgentLoading(false)
        setExistingAgent(null)
        setCheckEmailLoading(false)
        setExistingEmail(false)
        setExistingAgencyDbName(null)
      } else {
        checkAgentHandler(phoneNumber)
      }
    }
    if (dataType === 'email') {
      setCheckEmailLoading(false)
      setExistingEmail(false)
    }
    setAgent(prev => ({
      ...prev,
      [dataType]: value,
    }))
  }

  const submitHandler = async () => {
    const emailRegex = new RegExp(/^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/, 'gm')
    if (!emailRegex.test(agent.email)) {
      setServerError('Invalid email format')
      return
    }
    const dto = {
      userPhone: buildFullPhoneNumber(agent.phone),
      userEmail: agent.email,
      isPaidByAssociation: paymentType === agencyPaymentType.byAgency,
      preferences: Object.fromEntries([...dataPrefs, ...pagePrefs].map(pr => [pr.id, !!pr.selected])),
      companyIds: selectedCompanies.filter(c => c.selected).map(c => c.id),
    }
    try {
      setServerError(null)
      setAssociatedAgency(null)
      setSubmitLoading(true)
      setAssociateSuccess(false)
      const apiRequest = existingAgent ? addAssociatedAgencyRequest : addNewUserInviteRequest
      const {
        data: { HasError, Data },
      } = await apiRequest(dto)
      if (HasError) {
        throw new Error('Server Error')
      }
      if (Data) {
        setAssociateSuccess(true)
        setAssociatedAgency({
          ...agent,
          paymentType,
          prefs: [...dataPrefs, ...pagePrefs].filter(pr => pr.selected),
          companies: selectedCompanies.filter(c => c.selected),
        })
        nextStep()
      }

      setSubmitLoading(false)
    } catch (error) {
      console.log('Server Error')
      console.log(error)
      setServerError('Server Error')
      setSubmitLoading(false)
    }
  }

  const checkAgentHandler = async phone => {
    try {
      setCheckAgentLoading(true)
      setExistingAgent(null)
      const {
        data: { Data = null },
      } = await checkExistingAgentRequest(buildFullPhoneNumber(phone))
      setExistingAgent(Boolean(Data))
      if (Data) {
        setExistingAgencyDbName(Data.Agency)
      }
      setCheckAgentLoading(false)
    } catch (error) {
      console.log(error)
      setCheckAgentLoading(false)
    }
  }

  const checkEmailHandler = async ({ email, phone }) => {
    try {
      setCheckEmailLoading(true)
      setExistingEmail(false)
      const {
        data: { Data = null },
      } = await checkExistingEmailRequest({ email, phone })
      setExistingEmail(Data.Result)
      setEmailError(Data.Message)
      setCheckEmailLoading(false)
    } catch (error) {
      console.log(error)
      setCheckEmailLoading(false)
    }
  }

  const fetchExistingAgencyCompanies = async agencyDbName => {
    setCompaniesLoading(true)
    try {
      const {
        data: { data },
      } = await getProfileInvitationCompanies(agencyDbName)
      setSelectedCompanies(data)
      setCompaniesLoading(false)
    } catch (error) {
      console.log('Error fetching companies', error)
      setCompaniesLoading(false)
    }
  }

  useEffect(() => {
    if (existingAgent) {
      const email = agent.email
      const phone = buildFullPhoneNumber(agent.phone)
      checkEmailHandler({ email, phone })
    }
  }, [existingAgent, agent])

  useEffect(() => {
    if (existingAgentWithValidEmail) {
      fetchExistingAgencyCompanies(existingAgencyDbName)
    }
  }, [existingAgentWithValidEmail, existingAgencyDbName])

  return (
    <>
      <div className='associate-agency-form-container'>
        <div className='associate-agency-form-body'>
          <div className='associate-agency-form-grid-row'>
            <div className='associate-agency-form-grid-row-desc'>
              <h4>{t('addAgencyStep.associateAgencyForm.title')}</h4>
              <p>{t('addAgencyStep.associateAgencyForm.subtitle')}</p>
            </div>
          </div>
          <div className='associate-agency-form-grid-row'>
            <div className='associate-agency-form-grid-row-desc'>
              <h5>{t('addAgencyStep.associateAgencyForm.personalInfo')}</h5>
              <p>{t('addAgencyStep.associateAgencyForm.personalInfoRemark')}</p>
            </div>
            <div className='associate-agency-form-grid-row-content details-inputs'>
              <div className='associate-agency-details-inputs-container'>
                <InputText
                  autoFocus={true}
                  disabled={submitLoading}
                  inputName='email'
                  id='email'
                  label={t('addAgencyStep.associateAgencyForm.email')}
                  value={agent.email}
                  onChange={onChangeHandler('email')}
                  inputType='email'
                  loading={checkEmailLoading}
                  {...(existingAgent &&
                    !existingEmail &&
                    !checkEmailLoading &&
                    agent.email.length > 0 && { error: emailError })}
                />
                <InputText
                  disabled={submitLoading}
                  inputName='phone'
                  id='phone'
                  label={t('addAgencyStep.associateAgencyForm.phone')}
                  value={agent.phone}
                  onChange={onChangeHandler('phone')}
                  inputType='number'
                  loading={checkAgentLoading}
                />
              </div>
              {existingAgent === false && (
                <div className='associate-agency-form-alert'>
                  <h6>{tAssociateAgencyForm('noExistAlertTitle')}</h6>
                  <p>{tAssociateAgencyForm('noExistAlertText')}</p>
                </div>
              )}
              {serverError && <span className='server-error'>{serverError}</span>}
            </div>
          </div>
          <div className='associate-agency-form-grid-row'>
            <div className='associate-agency-form-grid-row-desc'>
              <h5>{t('addAgencyStep.associateAgencyForm.payment')}</h5>
              <p>{t('addAgencyStep.associateAgencyForm.paymentRemark')}</p>
              <p>{t('addAgencyStep.associateAgencyForm.paymentSubremark')}</p>
            </div>
            <div className='associate-agency-form-grid-row-content payments-inputs'>
              <InputRadio
                disabled={submitLoading}
                values={[
                  {
                    value: agencyPaymentType.byAgency,
                    text: t('addAgencyStep.associateAgencyForm.paymentByAgency'),
                    disabled: submitLoading,
                  },
                  {
                    value: agencyPaymentType.byAgent,
                    text: t('addAgencyStep.associateAgencyForm.paymentByAgent'),
                    disabled: submitLoading,
                  },
                ]}
                row={false}
                handleChange={setPaymentTypeHandler}
                selectedValue={+paymentType}
              />
            </div>
          </div>
          {existingAgentWithValidEmail && (
            <>
              <div className='associate-agency-form-grid-row'>
                <div className='associate-agency-form-grid-row-desc'>
                  <h5>{t('addAgencyStep.associateAgencyForm.userPreferences')}</h5>
                  <p>{t('addAgencyStep.associateAgencyForm.userPreferencesRemark')}</p>
                </div>
                <div className='associate-agency-form-grid-row-content companies-inputs'>
                  {companiesLoading ? (
                    <Loader />
                  ) : (
                    <>
                      <InputSelectAssociateCompanies
                        items={selectedCompanies.map(c => ({ ...c, value: hebrew() ? c.name : c.nameEn }))}
                        label={t('addAgencyStep.associateAgencyForm.companies')}
                        selectItem={selectCompany}
                        disabled={submitLoading}
                        allSelected={allCompaniesSelected()}
                        selectAllItems={selectAllCompanies}
                      />
                      <div className='selected-companies-chips'>
                        {selectedCompanies
                          .filter(c => c.selected)
                          .map(c => (
                            <AgencyCompanyChip key={c.id}>
                              <img src={`./assets/companies-logos-light/${c.id}.png`} alt={c.name} />
                              <p>{hebrew() ? c.name : c.nameEn}</p>
                              <button onClick={() => selectCompany(c.id)} disabled={submitLoading}>
                                <CancelFilledIconSvg />
                              </button>
                            </AgencyCompanyChip>
                          ))}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className='associate-agency-form-grid-row'>
                <div className='associate-agency-form-grid-row-desc'>
                  <h5>{t('addAgencyStep.associateAgencyForm.accessRequest')}</h5>
                  <p>{t('addAgencyStep.associateAgencyForm.accessRequestRemark')}</p>
                </div>
                <div className='associate-agency-form-grid-row-content access-inputs'>
                  <div className='access-inputs-group-container'>
                    <div className='access-inputs-group-heading'>
                      <p>{t('addAgencyStep.associateAgencyForm.accessData')}</p>
                      <div>
                        <p>{t('addAgencyStep.associateAgencyForm.selectAll')}</p>
                        <ButtonCheckbox
                          onClick={() => selectAllPrefrences(userPreferenceAccessType.data)}
                          checked={dataPrefs.every(pr => pr.selected)}
                          disabled={submitLoading}
                        />
                      </div>
                    </div>
                    <div className='access-inputs-group-items'>
                      {dataPrefs.map(pr => (
                        <div
                          className={classNames('associate-agency-form-grid-row-item', { selected: pr.selected })}
                          key={pr.id}
                        >
                          <p>{hebrew() ? pr.name : pr.nameEn}</p>
                          <ButtonCheckbox
                            checked={pr.selected}
                            onClick={() => selectPrefrence(userPreferenceAccessType.data, pr.id)}
                            disabled={submitLoading}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className='access-inputs-group-container'>
                    <div className='access-inputs-group-heading'>
                      <p>{t('addAgencyStep.associateAgencyForm.accessAdditionalActions')}</p>
                      <div>
                        <p>{t('addAgencyStep.associateAgencyForm.selectAll')}</p>
                        <ButtonCheckbox
                          onClick={() => selectAllPrefrences(userPreferenceAccessType.page)}
                          disabled={submitLoading}
                          checked={pagePrefs.every(pr => pr.selected)}
                        />
                      </div>
                    </div>
                    <div className='access-inputs-group-items'>
                      {pagePrefs.map(pr => (
                        <div
                          className={classNames('associate-agency-form-grid-row-item', { selected: pr.selected })}
                          key={pr.id}
                        >
                          <p>{hebrew() ? pr.name : pr.nameEn}</p>
                          <ButtonCheckbox
                            disabled={submitLoading}
                            checked={pr.selected}
                            onClick={() => selectPrefrence(userPreferenceAccessType.page, pr.id)}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>{' '}
            </>
          )}
        </div>
        <footer className='associate-agency-form-footer'>
          {stepBack && (
            <ButtonText onClick={() => (formIsDirty ? setShowCancelDialog(true) : stepBack())} disabled={submitLoading}>
              <ArrowRightIconSvg />
              <div>{t('addAgencyStep.associateAgencyForm.associateAgencyBackBtn')}</div>
            </ButtonText>
          )}
          <ButtonRounded disabled={submitDisabled || submitLoading} onClick={submitHandler}>
            {t('addAgencyStep.associateAgencyForm.associateAgencyBtn')}
          </ButtonRounded>
        </footer>
      </div>
      {showCancelDialog && (
        <ConfirmDialog
          confirm={stepBack}
          decline={() => {
            setShowCancelDialog(false)
          }}
          title={t('addAgencyStep.cancelDialogTitle')}
          text={t('addAgencyStep.cancelDialogText')}
          confirmText={t('addAgencyStep.cancelDialogOkBtn')}
          declineText={t('addAgencyStep.cancelDialogCancelBtn')}
        />
      )}
    </>
  )
}

export default AssociateAgencyForm
