import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { CUSTOMER_TREAT_RESET } from '../../../../types/actionTypes'
import { getAgents, getCustomerGroups, sendForTreat } from '../../actions/customerActions'
import AddUserModal from '../AddUserModal/AddUserModal'
import CustomerActionModal from '../CustomerActionModal/CustomerActionModal'
import CustomerAllCategoriesModal from '../CustomerAllCategoriesModal/CustomerAllCategoriesModal'
import AddCustomerGroupModal from '../../../../components/AddCustomerGroupModal/AddCustomerGroupModal'
import MeatBallsMenu from '../../../../components/Common/MeatBallsMenu/MeatBallsMenu'

const CustomerActions = () => {
  const { t } = useTranslation('customer')
  const dispatch = useDispatch()
  const [showAddTreatUserModal, setShowAddTreatUserModal] = useState(false)
  const [showTreatActionModal, setShowTreatActionModal] = useState(false)
  const [showAddCustomerGroupModal, setShowAddCustomerGroupModal] = useState(false)
  const [showCategoriesModal, setShowCategoriesModal] = useState(false)
  const [user, setUser] = useState({ currAgent: 2 })

  const agents = useSelector(state => state.customer).agents
  const agentsLoading = useSelector(state => state.customer).agentsLoading
  const treatSuccess = useSelector(state => state.customer).treatSuccess
  const treatLoading = useSelector(state => state.customer).treatLoading
  const customer = useSelector(state => state.customer).customerInfo

  const menuItemsStyles = { display: 'flex', alignItems: 'center', gap: '8px' }

  const actions = [
    // {
    //   text: (
    //     <div style={menuItemsStyles}>
    //       <img src='./assets/customer-actions/add-to-cart/Add  to cart.png' alt='' />
    //       <p>{t('customerActions.products')}</p>
    //     </div>
    //   ),
    //   icon: './assets/customer-actions/add-to-cart/Add  to cart.png',
    //   clickHandler: () => setShowCategoriesModal(true),
    // },
    {
      text: (
        <div style={menuItemsStyles}>
          <img src='./assets/customer-actions/group-members/3 User.png' alt='' />
          <p>{t('customerActions.groupMembers')}</p>
        </div>
      ),
      icon: './assets/customer-actions/group-members/3 User.png',
      clickHandler: () => setShowAddCustomerGroupModal(true),
    },
    // {
    //   text: t('customerActions.externalCommission'),
    //   icon: './assets/customer-actions/external-commission/price-tag.png',
    //   clickHandler: () => console.log('externalCommission'),
    //   disabled: true,
    //   blured: true,
    // },
    {
      text: (
        <div style={menuItemsStyles}>
          <img src='./assets/customer-actions/send-treat/send.png' alt='' />
          <p>{t('customerActions.treatTransfer')}</p>
        </div>
      ),
      icon: './assets/customer-actions/send-treat/send.png',
      clickHandler: () => {
        setUser({})
        setShowAddTreatUserModal(true)
      },
    },
  ]

  const addUser = newUser => {
    setUser({ ...user, ...newUser })
    setShowAddTreatUserModal(false)
    setShowTreatActionModal(true)
  }

  const selectAction = actionId => {
    setUser({ ...user, actionId })
  }

  const sendForTreatHandler = () => {
    const agentId = user.id
    const type = user.actionId
    dispatch(sendForTreat({ agentId, type }))
  }

  useEffect(() => {
    if (showAddTreatUserModal) {
      dispatch(getAgents())
    }
  }, [showAddTreatUserModal, dispatch])

  return (
    <div>
      <MeatBallsMenu menuItems={actions} />

      {/* <div className='customer-actions-title'>{t('customerActions.title')}</div>
      <div className='customer-actions-subtitle'>{t('customerActions.subtitle')}</div>
      <div className='customer-actions'>
        {actions.map(act => (
          <div
            className={classNames('customer-action', { disabled: act.disabled, blured: act.blured })}
            key={act.icon}
            {...(!act.disabled && { onClick: () => act.action() })}
          >
            <div className='customer-action-icon'>
              <img src={act.icon} alt='' />
            </div>
            <div className='customer-action-title'>{act.title}</div>
          </div>
        ))}
      </div> */}
      {showAddCustomerGroupModal && (
        <AddCustomerGroupModal
          onAddSuccess={() => dispatch(getCustomerGroups())}
          closeModal={() => setShowAddCustomerGroupModal(false)}
          groupCustomers={[customer]}
        />
      )}
      {showAddTreatUserModal && (
        <AddUserModal
          users={agents}
          loading={agentsLoading}
          closeModal={() => setShowAddTreatUserModal(false)}
          closeOnOutsideClick={false}
          onAddUser={addUser}
          currentUser={user?.currAgent}
          askConfirm={user?.currAgent}
        />
      )}
      {showTreatActionModal && (
        <CustomerActionModal
          closeModal={() => setShowTreatActionModal(false)}
          closeOnOutsideClick={false}
          onSelectAction={selectAction}
          onSubmit={sendForTreatHandler}
          submitSuccess={treatSuccess}
          resetSuccess={() => dispatch({ type: CUSTOMER_TREAT_RESET })}
          loading={treatLoading}
          currentAction={user?.actionId}
          back={() => setShowAddTreatUserModal(true)}
          agentName={user?.name}
        />
      )}
      {showCategoriesModal && <CustomerAllCategoriesModal closeModal={() => setShowCategoriesModal(false)} />}
    </div>
  )
}

export default CustomerActions
