import { useState, useEffect, useRef } from 'react'
import { getCustomerTotalsRequest } from '../../../../http/requests/customerRequests'
import { customerDataType } from '../../../../types/customerDataTypes'
import CustomerTotalsCard from './CustomerTotalsCard'
import NoData from '../../../../components/Common/NoData/NoData'
import { familyGroupMode } from '../../../shared/reducers/sharedSelectors'
import './CustomerTotals.scss'
import { useSelector } from 'react-redux'

const CustomerTotals = ({ id }) => {
  const { customerGroups } = useSelector(({ customer }) => customer)
  const { selectedGroupsIds } = useSelector(({ shared }) => shared)

  const [totals, setTotals] = useState({})
  const [totalsLoading, setTotalsLoading] = useState(true)
  const [totalsError, setTotalsError] = useState(null)

  const firstRender = useRef(true)

  const calculateByGroup = familyGroupMode()

  useEffect(() => {
    const fetchTotals = async () => {
      try {
        setTotalsLoading(true)
        setTotalsError(null)
        const {
          data: { data },
        } = await getCustomerTotalsRequest({ id, calculateByGroup })
        setTotals(data)
        setTotalsLoading(false)
      } catch (error) {
        setTotalsError('Server Error')
        setTotalsLoading(false)
      }
    }

    if (!firstRender.current) {
      fetchTotals()
      return
    }
    firstRender.current = false
  }, [id, calculateByGroup, customerGroups, selectedGroupsIds])

  return (
    <div className='customer-totals'>
      {totalsError && <NoData text={totalsError} />}
      {!totalsError && (
        <>
          <CustomerTotalsCard dataType={customerDataType.premium} totals={totals} loading={totalsLoading} id={id} />
          <CustomerTotalsCard dataType={customerDataType.commission} totals={totals} loading={totalsLoading} id={id} />
          <CustomerTotalsCard
            dataType={customerDataType.accumulation}
            totals={totals}
            loading={totalsLoading}
            id={id}
          />
        </>
      )}
    </div>
  )
}

export default CustomerTotals
