import ProfileNextPayment from '../ProfileNextPayment/ProfileNextPayment'
import ProfileNextPaymentDetails from '../ProfileNextPaymentDetails/ProfileNextPaymentDetails'
import ProfilePaymentsHistory from '../ProfilePaymentsHistory/ProfilePaymentsHistory'
import './ProfilePayments.scss'

const ProfilePayments = () => {
  return (
    <div className='profile-payments-container'>
      <ProfileNextPayment />
      <ProfileNextPaymentDetails />
      <ProfilePaymentsHistory />
    </div>
  )
}

export default ProfilePayments
