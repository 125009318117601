import './MobileHeaderBase.scss'

const MobileHeaderBase = ({ text, children, goBack, styles }) => {
  const goBackHandler = e => {
    e.stopPropagation()
    goBack()
  }
  return (
    <header className='mobile-header-wrapper' style={{ ...styles }}>
      <div className='mobile-header' onClick={e => e.stopPropagation()}>
        <div className='mobile-header-extras'>{children}</div>
        <div className='mobile-header-text'>{text}</div>
        {goBack && (
          <div className='mobile-header-back' onClick={goBackHandler}>
            <img src='./assets/arrow-left-common/Left.png' alt='back' />
          </div>
        )}
      </div>
    </header>
  )
}

export default MobileHeaderBase
