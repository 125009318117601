import $api from '..'
import { urlIrregularities } from '../../environment/urls'

export const ignoreIrregularityRequest = async irregularitiesIds =>
  await $api.post(`${urlIrregularities()}ignore-irregularity`, irregularitiesIds)

export const fixIrregularitiesRequest = async ({ irregularitiesIds, comment }) =>
  await $api.post(`${urlIrregularities()}fix-irregularities`, { irregularitiesIds, comment })

export const deleteIrregularitiesRequest = async irregularities =>
  await $api.post(`${urlIrregularities()}delete-irregularities-batch`, irregularities)

export const deleteAllIrregularitiesRequest = async () => await $api.get(`${urlIrregularities()}deactivate-all`)
