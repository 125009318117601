import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { insuranceField } from '../../../../types/insuranceField'
import { getInsuranceFields } from '../../../../http/requests'
import Loader from '../../../../components/Common/Loader/Loader'
import './StepFields.scss'
import { isMobile } from 'react-device-detect'

const StepFields = ({ nextStep, data, setData }) => {
  const { selectedFields } = data
  const {
    t,
    i18n: { language: lang },
  } = useTranslation('registration')
  const [fields, setFields] = useState([])
  const [serverError, setServerError] = useState(null)
  const [loading, setLoading] = useState(true)

  const submitHandler = () => {
    nextStep()
  }

  const changeHandler = fieldId => e => {
    const {
      target: { checked },
    } = e
    setData(prev => {
      const { selectedFields } = prev
      if (checked) {
        return {
          ...prev,
          selectedFields: [...selectedFields, fieldId],
        }
      } else {
        return {
          ...prev,
          selectedFields: [...selectedFields.filter(id => id !== fieldId)],
        }
      }
    })
  }

  const submitBtnDisabled = () => loading || selectedFields.length === 0

  const clearServerError = () => {
    if (serverError) setServerError(null)
  }

  useEffect(() => {
    const getFields = async () => {
      try {
        const {
          data: { Data: data, HasError: hasError },
        } = await getInsuranceFields()
        if (hasError) {
          setServerError('Error')
        } else {
          setFields(data)
          setServerError(null)
        }
      } catch (err) {
        setServerError(err.message)
      } finally {
        setLoading(false)
      }
    }
    getFields()
  }, [setData])

  return (
    <div className='register-step step-fields'>
      <div className='header'>
        <h2>{t('stepFields.heading')}</h2>
        <h3>{t('stepFields.subHeading')}</h3>
        {serverError && <h3>{serverError}</h3>}
      </div>

      <div className='form'>
        <form action='' id='fields-form' className='form-container' onSubmit={submitHandler}>
          <div className='input-checkboxes-container'>
            {loading && <Loader />}
            {!loading &&
              fields.map(field => (
                <div className='checkbox-container' key={field.Id}>
                  <input
                    type='checkbox'
                    id={field.Id}
                    name={field.Id}
                    checked={selectedFields.includes(field.Id)}
                    onChange={changeHandler(field.Id)}
                  />
                  <label htmlFor={field.Id}>
                    <div className='checkbox-icon'>
                      {selectedFields.includes(field.Id) && isMobile ? (
                        <img src={insuranceField[field.Id]?.activeIconPath} alt={field.Id} />
                      ) : (
                        <img src={insuranceField[field.Id]?.iconPath} alt={field.Id} />
                      )}
                    </div>
                    <div>{lang === 'he' ? field.Name : field.EngilshName}</div>
                  </label>
                </div>
              ))}
          </div>
        </form>
        <div className='form-buttons-container'>
          <button form='fields-form' className='form-button' disabled={submitBtnDisabled()} type='submit'>
            {t('btnNext')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default StepFields
