import { useTranslation } from 'react-i18next'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ModalWindow from '../ModalWindow/ModalWindow'
import './AddAgentGroupModal.scss'
import SearchAgentsInput from './SearchAgentsInput/SearchAgentsInput'
import Button from '../Common/Button/Button'
import {
  addAgentGroup,
  getAgentNumbers,
  removeAgentNumber,
  restoreAgentNumber,
  updateAgentGroup,
} from '../../features/shared/actions/sharedActions'
import Loader from '../Common/Loader/Loader'
import { hebrew } from '../../i18n'
import { getUserNameInitials } from '../../services/getUserNameInitials'
import { getRandomInt } from '../../services/getRandom'
import CrossMark from '../Common/Marks/CrossMark/CrossMark'
import InputText from '../Common/InputText/InputText'
import {
  SHARED_ADD_GROUP_RESET,
  SHARED_AGENT_NUMBERS_RESET,
  SHARED_UPDATE_GROUP_RESET,
} from '../../types/actionTypesShared'
import NoData from '../Common/NoData/NoData'
import { avatarStyles } from '../../types/colorsTypes'
import LinedTitle from '../Common/LinedTitle/LinedTitle'
import LoadingOverlay from '../Common/LoadingOverlay/LoadingOverlay'
import ConfirmWarnDialog from '../ConfirmWarnDialog/ConfirmWarnDialog'
import { actionWithAgentType } from '../../types/actionWithAgentTypes'
import { ArrowDropDown, ArrowDropUp, Cancel, DeleteOutline, RestoreFromTrashOutlined } from '@mui/icons-material'
import { cn } from '../../utils/stylesUtils'
import { isDeltaAgency } from '../../features/shared/reducers/sharedSelectors'
import { formatAsShortDate } from '../../utils/formatAs'
import { insuranceField, insuranceFieldType } from '../../types/insuranceField'
import AgencyHouseAnalyticsFiltersPicker from '../../features/agencyHouseAnalytics/components/AgencyHouseAnalyticsFiltersPicker/AgencyHouseAnalyticsFiltersPicker'
import CalendarBase from '../Common/CalendarBase/CalendarBase'
import RelativeFixedPosition from '../Common/RelativeFixedPosition/RelativeFixedPosition'

const agentNumberType = {
  agent: 1,
  agency: 2,
}

const insuranceFieldsFilterInit = [
  insuranceField[insuranceFieldType.lifeFinance],
  insuranceField[insuranceFieldType.elementary],
  insuranceField[insuranceFieldType.abroad],
]

const agentTypesFilterInit = [
  {
    id: agentNumberType.agency,
    name: 'סוכנות',
    nameEn: 'agency',
  },
  {
    id: agentNumberType.agent,
    name: 'סוכן',
    nameEn: 'agent',
  },
]

const hasAppliedfilters = filters => filters.some(f => f.selected)
const getAppliedFilters = filters => filters.filter(f => f.selected).map(f => f.id)

const AddAgentGroupModal = ({ closeModal, group = null }) => {
  const { t } = useTranslation('profile')
  const [searchAgent, setSearchAgent] = useState('')
  const [groupName, setGroupName] = useState(group ? group.name : '')
  const [selectedAgents, setSelectedAgents] = useState(group ? group.agents : [])

  const [activeCompaniesFilter, setActiveCompaniesFilter] = useState([])
  const [activeFieldsFilter, setActiveFieldsFilter] = useState(insuranceFieldsFilterInit)
  const [activeAgentTypesFilter, setActiveAgentTypesFilter] = useState(agentTypesFilterInit)
  const [activeDatesFilter, setActiveDatesFilter] = useState()
  const [deletedCompaniesFilter, setDeletedCompaniesFilter] = useState([])
  const [deletedFieldsFilter, setDeletedFieldsFilter] = useState(insuranceFieldsFilterInit)
  const [deletedAgentTypesFilter, setDeletedAgentTypesFilter] = useState(agentTypesFilterInit)
  const [deletedDatesFilter, setDeletedDatesFilter] = useState()

  const {
    activeAgents,
    deletedAgents,
    agentNumbersLoading,
    addGroupLoading,
    addGroupSuccess,
    updateGroupSuccess,
    agentGroups,
  } = useSelector(({ shared }) => shared)
  const groupToUpdate = agentGroups.find(gr => gr.id === group?.id)
  const updateGroupLoading = groupToUpdate && groupToUpdate.loading
  const filterAgents = (agents = []) =>
    agents.filter(
      an =>
        an.agentNumber.toLowerCase().includes(searchAgent.toLowerCase()) ||
        (an.name && an.name.toLowerCase().includes(searchAgent.toLowerCase()))
    )

  const applyExtraFilters =
    activeFilters =>
    ({ companyId, type, insuranceFieldId, added }) => {
      const companiesFilter = getAppliedFilters(activeFilters ? activeCompaniesFilter : deletedCompaniesFilter)
      const fieldsFilter = getAppliedFilters(activeFilters ? activeFieldsFilter : deletedFieldsFilter)
      const agenTypesFilter = getAppliedFilters(activeFilters ? activeAgentTypesFilter : deletedAgentTypesFilter)
      const datesFilter = activeFilters ? activeDatesFilter : deletedDatesFilter
      if (
        (companiesFilter.length > 0 && !companiesFilter.includes(companyId)) ||
        (fieldsFilter.length > 0 && !fieldsFilter.includes(insuranceFieldId)) ||
        (agenTypesFilter.length > 0 && !agenTypesFilter.includes(type))
      ) {
        return false
      }
      if (datesFilter) {
        const itemDate = new Date(added)
        const filterDate = new Date(datesFilter)
        return itemDate.getFullYear() === filterDate.getFullYear() && itemDate.getMonth() === filterDate.getMonth()
      }
      return true
    }

  const filteredActiveAgents = filterAgents(activeAgents).filter(applyExtraFilters(true))
  const filteredDeletedAgents = filterAgents(deletedAgents).filter(applyExtraFilters(false))

  const dispatch = useDispatch()

  const submitBtnDisabled =
    updateGroupLoading ||
    agentNumbersLoading ||
    addGroupLoading ||
    activeAgents.length === 0 ||
    selectedAgents.length === 0 ||
    groupName.length === 0

  const selectAgentHandler = agent => {
    setSelectedAgents(prev =>
      prev.map(a => a.innerId).includes(agent.innerId)
        ? prev.filter(an => an.innerId !== agent.innerId)
        : [...prev, agent]
    )
  }

  const selectAllAgentsHandler = agents => {
    // deselect if all are selected
    if (agents.every(({ innerId }) => selectedAgents.map(fa => fa.innerId).includes(innerId))) {
      setSelectedAgents(prev => prev.filter(an => !agents.map(fa => fa.innerId).includes(an.innerId)))
    } else {
      //select all if some are not
      const notIncluded = agents.filter(({ innerId }) => !selectedAgents.map(fa => fa.innerId).includes(innerId))
      setSelectedAgents(prev => [...prev, ...notIncluded])
    }
  }

  const addGroupHandler = () => {
    dispatch(
      group
        ? updateAgentGroup({
            id: group.id,
            name: groupName,
            agents: selectedAgents.map(({ id, type, insuranceFieldId }) => ({ id, type, insuranceFieldId })),
          })
        : addAgentGroup({
            name: groupName,
            agents: selectedAgents.map(({ id, type, insuranceFieldId }) => ({ id, type, insuranceFieldId })),
          })
    )
  }

  useEffect(() => {
    dispatch(getAgentNumbers())
    return () => dispatch({ type: SHARED_AGENT_NUMBERS_RESET })
  }, [dispatch])

  useEffect(() => {
    if ((addGroupSuccess && !addGroupLoading) || (group && updateGroupSuccess && !updateGroupLoading)) closeModal()
    return () => {
      addGroupSuccess && dispatch({ type: SHARED_ADD_GROUP_RESET })
      group && updateGroupSuccess && dispatch({ type: SHARED_UPDATE_GROUP_RESET })
    }
  }, [addGroupSuccess, addGroupLoading, updateGroupSuccess, group, updateGroupLoading, closeModal, dispatch])

  useEffect(() => {
    let companies = []
    ;[...activeAgents, ...deletedAgents].forEach(an => {
      !companies.map(c => c.id).includes(an.companyId) &&
        companies.push({ id: an.companyId, name: an.companyName, nameEn: an.companyNameEn })
    })
    setActiveCompaniesFilter(companies)
    setDeletedCompaniesFilter(companies)
  }, [activeAgents, deletedAgents])

  return (
    <ModalWindow
      closeOnOutsideClick={false}
      closeModal={closeModal}
      renderPortal
      showCloseIcon={false}
      loadingOverlay={addGroupLoading || updateGroupLoading}
    >
      <div className='add-agent-group-modal'>
        <header className='add-agent-group-modal-header'>
          <div>{t('addGroupModal.title')}</div>
          <button onClick={closeModal}>
            <img src='./assets/close-icon-square/close-square.png' alt='cls' />
          </button>
        </header>
        <div className='add-agent-group-modal-content'>
          <div className='add-agent-group-modal-sidebar'>
            <InputText
              value={groupName}
              onChange={setGroupName}
              required={true}
              label={t('addGroupModal.namePlaceholder')}
            />
            <div className='add-agent-group-modal-selected-heading'>{t('addGroupModal.agentNumbers')}</div>
            <div className='add-agent-group-modal-selected-items'>
              {selectedAgents.map(an => (
                <div className='add-agent-group-modal-selected-item-wrapper' key={an.innerId}>
                  <div className='add-agent-group-modal-selected-item'>
                    <div className='add-agent-group-modal-selected-item-main'>
                      <div className='add-agent-group-modal-company-logo'>
                        <img src={`./assets/companies-logos-light/${an.companyId}.png`} alt={an.companyNameEn} />
                      </div>
                      <div>{an.agentNumber}</div>
                    </div>
                    {isDeltaAgency() && an.type && <p>{t(`agentGroupListHeading.agentType${an.type}`)}</p>}
                    {an.insuranceFieldId && <p>{an[`fieldName${hebrew() ? '' : 'En'}`]}</p>}
                  </div>
                  <button onClick={() => selectAgentHandler(an)}>
                    <CrossMark />
                  </button>
                </div>
              ))}
            </div>
          </div>
          <div className='add-agent-group-modal-main'>
            <div className='add-agent-group-modal-main-content'>
              {agentNumbersLoading ? (
                <Loader />
              ) : activeAgents.length === 0 && deletedAgents.length === 0 ? (
                <NoData text={t('addGroupModal.noAgents')} />
              ) : (
                <>
                  <div className='add-agent-group-search-container'>
                    <SearchAgentsInput
                      searchValue={searchAgent}
                      setSearchValue={setSearchAgent}
                      placeholder={t('addGroupModal.searchPlaceholder')}
                    />
                  </div>
                  <div className='add-agent-group-modal-lists-container'>
                    <AgentsList
                      list={filteredActiveAgents}
                      listTitle={t('agentGroupListHeading.active')}
                      selectItem={selectAgentHandler}
                      selectedItems={selectedAgents}
                      selectAllItmes={() => selectAllAgentsHandler(filteredActiveAgents)}
                      companiesFilter={activeCompaniesFilter}
                      setCompaniesFilter={setActiveCompaniesFilter}
                      fieldsFilter={activeFieldsFilter}
                      setFieldsFilter={setActiveFieldsFilter}
                      agentTypesFilter={activeAgentTypesFilter}
                      setAgentTypesFilter={setActiveAgentTypesFilter}
                      filtersApplied={
                        hasAppliedfilters([
                          ...activeCompaniesFilter,
                          ...activeFieldsFilter,
                          ...activeAgentTypesFilter,
                        ]) ||
                        searchAgent.length > 0 ||
                        activeDatesFilter
                      }
                      datesFilter={activeDatesFilter}
                      setDatesFilter={setActiveDatesFilter}
                    />
                    <AgentsList
                      list={filteredDeletedAgents}
                      listTitle={t('agentGroupListHeading.deleted')}
                      selectItem={selectAgentHandler}
                      selectedItems={selectedAgents}
                      selectAllItmes={() => selectAllAgentsHandler(filteredDeletedAgents)}
                      companiesFilter={deletedCompaniesFilter}
                      setCompaniesFilter={setDeletedCompaniesFilter}
                      fieldsFilter={deletedFieldsFilter}
                      setFieldsFilter={setDeletedFieldsFilter}
                      agentTypesFilter={deletedAgentTypesFilter}
                      setAgentTypesFilter={setDeletedAgentTypesFilter}
                      filtersApplied={
                        hasAppliedfilters([
                          ...deletedCompaniesFilter,
                          ...deletedFieldsFilter,
                          ...deletedAgentTypesFilter,
                        ]) ||
                        searchAgent.length > 0 ||
                        deletedDatesFilter
                      }
                      datesFilter={deletedDatesFilter}
                      setDatesFilter={setDeletedDatesFilter}
                    />
                  </div>
                </>
              )}
            </div>
            <footer className='add-agent-group-modal-footer'>
              <Button caption={t('addGroupModal.saveBtn')} disabled={submitBtnDisabled} onClick={addGroupHandler} />
            </footer>
          </div>
        </div>
      </div>
    </ModalWindow>
  )
}

export default AddAgentGroupModal

const AgentsList = ({
  list,
  listTitle,
  selectedItems,
  selectItem,
  selectAllItmes,
  datesFilter,
  setDatesFilter,
  companiesFilter,
  setCompaniesFilter,
  fieldsFilter,
  setFieldsFilter,
  agentTypesFilter,
  setAgentTypesFilter,
  filtersApplied,
}) => {
  const { t } = useTranslation('profile')
  const [showList, setShowList] = useState(true)
  const [agentToRemove, setAgentToRemove] = useState(null)
  const [agentToRestore, setAgentToRestore] = useState(null)
  const [showCalendar, setShowCalendar] = useState(false)
  const dateBtnRef = useRef()

  const dispatch = useDispatch()

  const removeAgent = () => {
    dispatch(removeAgentNumber(agentToRemove))
    setAgentToRemove(null)
  }
  const restoreAgent = () => {
    dispatch(restoreAgentNumber(agentToRestore))
    setAgentToRestore(null)
  }

  const applyFilters = setter => ids => setter(prev => prev.map(c => ({ ...c, selected: ids.includes(c.id) })))

  const setDatesFilterHandler = date => {
    setDatesFilter(date)
    setShowCalendar(false)
  }

  const actionButton = agent => {
    const { isActive, nextPaymentAction } = agent

    const agentIsActive =
      (!isActive && nextPaymentAction === actionWithAgentType.activate) ||
      (isActive && nextPaymentAction !== actionWithAgentType.deactivate)

    const clickHanlder = agentIsActive ? setAgentToRemove : setAgentToRestore
    return (
      <button onClick={() => clickHanlder(agent)}>
        {agentIsActive ? <DeleteOutline /> : <RestoreFromTrashOutlined style={{ color: 'var(--trend-up-color)' }} />}
      </button>
    )
  }

  return (
    <div className='add-agent-group-modal-list-container'>
      <LinedTitle
        title={listTitle}
        count={list.length}
        collapsed={!showList}
        onCollapse={() => setShowList(!showList)}
        collapsible
        classes='add-agent-group-modal-list-title'
      />
      {showList && (
        <>
          {((filtersApplied && list.length === 0) || list.length > 0) && (
            <div
              className={cn('add-agent-group-modal-list-heading add-agent-group-modal-list-grid', {
                extended: isDeltaAgency(),
              })}
            >
              <div>
                {list.length > 0 && (
                  <img
                    src={`./assets/${
                      list.every(({ innerId }) => selectedItems.map(fa => fa.innerId).includes(innerId))
                        ? 'checkbox-sm-checked/Square.png'
                        : 'check-box-blank/Check Box Blank.png'
                    }`}
                    alt='slct'
                    onClick={selectAllItmes}
                  />
                )}
              </div>
              <div>{t('agentGroupListHeading.agentNumber')}</div>
              <div>{t('agentGroupListHeading.agentName')}</div>
              {isDeltaAgency() && (
                <AgencyHouseAnalyticsFiltersPicker
                  applyFilters={applyFilters(setAgentTypesFilter)}
                  filters={agentTypesFilter}
                  filtersLoading={false}
                  filtersTitle={t(`agentGroupListHeading.agentType`)}
                  noFiltersText={'No Types'}
                  noFoundText={'Nothing Found'}
                  searchPlaceholder={t(`addGroupModal.defaultSearch`)}
                  showSelectedValues={false}
                  enableClear
                />
              )}
              <AgencyHouseAnalyticsFiltersPicker
                applyFilters={applyFilters(setFieldsFilter)}
                filters={fieldsFilter}
                filtersLoading={false}
                filtersTitle={t(`agentGroupListHeading.agentField`)}
                noFiltersText={'No Fields'}
                noFoundText={'Nothing Found'}
                searchPlaceholder={t(`addGroupModal.defaultSearch`)}
                showSelectedValues={false}
                enableClear
              />
              <AgencyHouseAnalyticsFiltersPicker
                applyFilters={applyFilters(setCompaniesFilter)}
                filters={companiesFilter}
                filtersLoading={false}
                filtersTitle={t(`agentGroupListHeading.company`)}
                noFiltersText={'No Companies'}
                noFoundText={'Nothing Found'}
                searchPlaceholder={t(`addGroupModal.defaultSearch`)}
                showSelectedValues={false}
                enableClear
              />
              <div
                onClick={() => setShowCalendar(true)}
                ref={dateBtnRef}
                className={cn('add-agent-group-modal-list-heading-btn-container', { applied: datesFilter })}
              >
                <span>{t('agentGroupListHeading.agentAdded')}</span>
                {datesFilter ? (
                  <button
                    onClick={e => {
                      e.stopPropagation()
                      setDatesFilter()
                    }}
                  >
                    <Cancel />
                  </button>
                ) : showCalendar ? (
                  <ArrowDropUp />
                ) : (
                  <ArrowDropDown />
                )}
              </div>
              {showCalendar && (
                <RelativeFixedPosition anchorRef={dateBtnRef} onOverlayClick={() => setShowCalendar(false)}>
                  <CalendarBase view='year' date={datesFilter} setDate={setDatesFilterHandler} />
                </RelativeFixedPosition>
              )}
              <div></div>
            </div>
          )}
          {list.length > 0 && (
            <div className='add-agent-group-modal-list'>
              {list.map(an => (
                <div
                  key={an.innerId}
                  className={cn('add-agent-group-modal-list-item add-agent-group-modal-list-grid', {
                    extended: isDeltaAgency(),
                  })}
                >
                  {an.loading && <LoadingOverlay />}
                  <div>
                    <img
                      src={`./assets/${
                        selectedItems.map(a => a.innerId).includes(an.innerId)
                          ? 'checkbox-sm-checked/Square.png'
                          : 'check-box-blank/Check Box Blank.png'
                      }`}
                      alt='slct'
                      onClick={() => selectItem(an)}
                    />
                  </div>
                  <div>{an.agentNumber}</div>
                  <div className='add-agent-group-modal-list-item-name'>
                    <div
                      className='add-agent-group-modal-list-item-avatar'
                      style={avatarStyles[an?.colorIndex ?? getRandomInt(1, 3) - 1]}
                    >
                      {getUserNameInitials(an.name)}
                    </div>
                    <div>{an.name}</div>
                  </div>
                  {isDeltaAgency() && <div>{an.type ? t(`agentGroupListHeading.agentType${an.type}`) : '--'}</div>}
                  <div>{an?.[`fieldName${hebrew() ? '' : 'En'}`] ?? '--'}</div>
                  <div className='add-agent-group-modal-list-item-company'>
                    <div className='add-agent-group-modal-company-logo'>
                      <img src={`./assets/companies-logos-light/${an.companyId}.png`} alt={an.companyNameEn} />
                    </div>
                    <div>{hebrew() ? an.companyName : an.companyNameEn}</div>
                  </div>
                  <div>{formatAsShortDate(an.added, '.', { noValue: '--' })}</div>
                  {actionButton(an)}
                </div>
              ))}
            </div>
          )}
          {list.length === 0 && (
            <div className='add-agent-group-modal-no-data-container'>
              <NoData text='Nothing Found' />
            </div>
          )}
        </>
      )}
      {agentToRemove && (
        <ConfirmWarnDialog
          title={t(`deleteAgentWarnTitle`)}
          text={t(`delete${agentToRemove.deletedNextMonth ? 'Existing' : 'New'}AgentWarnText`)}
          cancelText={t('cancelAgentBtn')}
          confirmText={t('deleteAgentBtn')}
          cancelHandler={() => setAgentToRemove(null)}
          confirmHandler={removeAgent}
          dangerWarn
        />
      )}
      {agentToRestore && (
        <ConfirmWarnDialog
          title={t(`restoreAgentWarnTitle`)}
          text={t(`restoreAgentWarnText`)}
          cancelText={t('cancelAgentBtn')}
          confirmText={t('restoreAgentBtn')}
          cancelHandler={() => setAgentToRestore(null)}
          confirmHandler={restoreAgent}
        />
      )}
    </div>
  )
}
