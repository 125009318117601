import AddNewAgencyForm from '../AddNewAgencyForm/AddNewAgencyForm'
import './EditSubagencyForm.scss'

const EditSubagencyForm = ({ agency, closeForm, setUpdateSuccess }) => {
  return (
    <div className='edit-subagency-form'>
      <AddNewAgencyForm
        agencyToEdit={agency}
        nextStep={closeForm}
        stepBack={closeForm}
        setNewAgency={() => {}}
        setAddSuccess={setUpdateSuccess}
      />
    </div>
  )
}

export default EditSubagencyForm
