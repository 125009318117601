import { useDispatch } from 'react-redux'
import CustomerDetailsGeneral from './CustomerDetailsGeneral'
import CustomerDetailsList from './CustomerDetailsList'
import Loader from '../../../../components/Common/Loader/Loader'
import { updateCustomerInfo } from '../../actions/customerActions'
import { infoDetailsType } from '../../../../types/infoDetailsTypes'
import './CustomerDetails.scss'
import NoData from '../../../../components/Common/NoData/NoData'

const CustomerDetails = ({ customerInfo, customerInfoLoading = false, customerGroupMembers }) => {
  const dispatch = useDispatch()
  if (!customerInfo && !customerInfoLoading) {
    return (
      <div className='customer-details-container-wrapper'>
        <div className='customer-details-container'>
          <NoData text='No Customer Found' />
        </div>
      </div>
    )
  }
  const customerDetails = {
    address: customerInfo.clientAddress,
    phone: customerInfo.phone,
    groupId: customerInfo.clientGroupId,
    customerGroupMembers,
    gender: customerInfo.gender,
    birthDate: customerInfo.birthDate,
  }

  const setPersonHanlder = (infoType, value) => {
    dispatch(updateCustomerInfo(customerInfo.id, infoType, value))
  }

  return (
    <div className='customer-details-container-wrapper'>
      <div className='customer-details-container'>
        {customerInfoLoading ? (
          <Loader />
        ) : (
          <>
            <div className='customer-details-header'>
              <CustomerDetailsGeneral
                idNumber={customerInfo.idNumber}
                name={customerInfo.name}
                rating={customerInfo.policyRating}
                active={customerInfo.active}
                detailsType={infoDetailsType.customerName}
                updateName={setPersonHanlder}
              />
            </div>
            <CustomerDetailsList person={customerDetails} changePerson={setPersonHanlder} />
          </>
        )}
      </div>
    </div>
  )
}

export default CustomerDetails
