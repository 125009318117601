import './CloseButton.css'

const CloseButton = ({ onCloseClick, small = false }) => {
  const clickHandler = e => {
    e.stopPropagation()
    onCloseClick()
  }

  return (
    <div className='close-btn' onClick={clickHandler}>
      {small ? (
        <img src='./assets/close-icon-sm/Close.png' alt='close' className='img-icon' />
      ) : (
        <img src='./assets/close-icon/Close.png' alt='close' className='img-icon' />
      )}
    </div>
  )
}

export default CloseButton
