import ModalWindow from '../ModalWindow/ModalWindow'
import { Close } from '@mui/icons-material'
import BigAgencyPaymentFrame from '../BigAgencyPaymentFrame/BigAgencyPaymentFrame'
import './BigAgencyPaymentFrameModal.scss'

const BigAgencyPaymentFrameModal = ({ closeModal, onPaymentSuccess, frameHeader }) => {
  return (
    <ModalWindow renderPortal showCloseIcon={false}>
      <header>
        <p>{frameHeader}</p>
        <button onClick={closeModal}>
          <Close />
        </button>
      </header>
      <BigAgencyPaymentFrame onPaymentSuccess={onPaymentSuccess} />
    </ModalWindow>
  )
}

export default BigAgencyPaymentFrameModal
