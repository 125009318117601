import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import PlusSignIconSvg from '../../../../components/Common/SvgIcons/PlusSignIconSvg/PlusSignIconSvg'
import AddGroupIconSvg from '../../../../components/Common/SvgIcons/AddGroupIconSvg/AddGroupIconSvg'
import AddAgencyStepper from '../AddAgencyStepper/AddAgencyStepper'
import Loader from '../../../../components/Common/Loader/Loader'
import classNames from 'classnames'
import { agencyExistType } from '../../../../types/agencyTypes'
import AgenciesListFilterDropdown from '../AgenciesListFilter/AgenciesListFilterDropdown'
import { useDispatch, useSelector } from 'react-redux'
import './AgenciesListContainer.scss'
import { AGENT_HOUSE_APPLY_PAYMENT_FILTER, AGENT_HOUSE_APPLY_STATUS_FILTER } from '../../../../types/actionTypes'

const AddAgencyButton = ({ onClick, caption }) => {
  return (
    <button onClick={onClick} className='add-agency-btn'>
      <PlusSignIconSvg />
      <div>{caption}</div>
    </button>
  )
}
const AgenciesListContainer = ({
  addAgencyType,
  listLoading = false,
  noItems = false,
  nothingFound = false,
  onAddSuccess,
  onAssociateSuccess,
  children,
}) => {
  const { t } = useTranslation('agencyHouse')
  const [showAddAgencyStepper, setShowAddAgencyStepper] = useState(false)
  const { agencyPaymentFilter, agencyRequestStatusFilter } = useSelector(({ agentHouse }) => agentHouse)
  const dispatch = useDispatch()

  const showAddAgencyStepperHandler = () => setShowAddAgencyStepper(!showAddAgencyStepper)
  const addExistingAgency = addAgencyType === agencyExistType.existing

  const addAgencyBtn = (
    <AddAgencyButton
      onClick={showAddAgencyStepperHandler}
      caption={t(`add${addExistingAgency ? 'Associated' : 'Sub'}AgencyBtn`)}
    />
  )

  const noItemsPlaceholder = noItems ? (
    <>
      <AddGroupIconSvg />
      <p>{t('noAssociatedAgencies')}</p>
      {addAgencyBtn}
    </>
  ) : null

  return (
    <>
      <div className={classNames('agencies-list-container', { 'no-agencies': noItems })}>
        {!noItems && (
          <div className='agencies-list-header'>
            <div className='agencies-list-header-filters'>
              {addExistingAgency && (
                <>
                  <AgenciesListFilterDropdown
                    title={t('filters.payment')}
                    multiSelect={false}
                    filterItems={agencyPaymentFilter}
                    setChecked={id => dispatch({ type: AGENT_HOUSE_APPLY_PAYMENT_FILTER, payload: id })}
                  />
                  <AgenciesListFilterDropdown
                    title={t('filters.status')}
                    multiSelect={false}
                    filterItems={agencyRequestStatusFilter}
                    setChecked={id => dispatch({ type: AGENT_HOUSE_APPLY_STATUS_FILTER, payload: id })}
                  />
                </>
              )}
            </div>
            {addAgencyBtn}
          </div>
        )}
        {listLoading ? <Loader /> : noItemsPlaceholder ?? <div className='agencies-list-content'>{children}</div>}
      </div>
      {showAddAgencyStepper && (
        <AddAgencyStepper
          addAgencyType={addAgencyType}
          closeStepper={() => setShowAddAgencyStepper(false)}
          onAddSuccess={onAddSuccess}
          onAssociateSuccess={onAssociateSuccess}
        />
      )}
    </>
  )
}

export default AgenciesListContainer
