import { useTranslation } from 'react-i18next'
import './CustomersPoliciesTotals.scss'
import Loader from '../../../../components/Common/Loader/Loader'
import { customerPolicyList, customerPolicyListType } from '../../../../types/customerPolicyListTypes'

const policyTotal = [customerPolicyListType.all, customerPolicyListType.notActive, customerPolicyListType.irregular]

const CustomersPoliciesTotals = ({ totals, loading, showList }) => {
  const { t } = useTranslation('customer')

  if (loading) {
    return (
      <div className='customers-policies-totals loading'>
        <Loader />
      </div>
    )
  }

  return (
    <div className='customers-policies-totals'>
      {policyTotal.map(pt => (
        <section key={customerPolicyList[pt].key} onClick={() => showList(pt)}>
          <div>
            <img
              src={`./assets/policies-totals-icons/${customerPolicyList[pt].key}.png`}
              alt={customerPolicyList[pt].key}
            />
          </div>
          <p>{t(`policiesTotals.${customerPolicyList[pt].key}`)}</p>
          <p>{totals[customerPolicyList[pt].key] ?? '--'}</p>
        </section>
      ))}
    </div>
  )
}

export default CustomersPoliciesTotals
