import {useTranslation} from 'react-i18next'
import {NavLink} from 'react-router-dom'
import './BackButton.scss'

const BackButton = ({link = '/'}) => {
    const {i18n} = useTranslation()

    const switchLang = () => {
        if (i18n.language === 'he') {
            i18n.changeLanguage('en')
        } else {
            i18n.changeLanguage('he')
        }
    }

    return (
        <div className='back-button-container'>
            <NavLink to={`${link}`}>
                <img src={`./assets/_early/back-arrow.svg`} alt='arrow'
                     onDoubleClick={switchLang}/>
            </NavLink>
        </div>
    )
}

export default BackButton
