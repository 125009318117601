import React from 'react'
import { useTranslation } from 'react-i18next'
import './LandingPortfolio.scss'
import { hebrew } from '../../../../i18n'

const LandingPortfolio = () => {
  const { t } = useTranslation('landing')
  return (
    <section
      className='landing-portfolio'
      id='portfolio'
      style={{
        backgroundImage: `url('assets/_landing/_portfolio/bg${hebrew() ? '' : '_en'}.png')`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundColor: 'rgba(49, 57, 79, 0.38)',
      }}
    >
      <div
        className='landing-section-container'
        style={{
          backgroundImage: `url('assets/_landing/_portfolio/bg-mobile.png')`,
          backgroundRepeat: 'no-repeat',
        }}
      >
        <p className='landing-portfolio-tagline'>{t('portfolio.tagline')}</p>
        <h5 className='landing-portfolio-title'>{t('portfolio.title')}</h5>
        <p className='landing-portfolio-subtitle'>{t('portfolio.subTitle')}</p>
      </div>
    </section>
  )
}

export default LandingPortfolio
