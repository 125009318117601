import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { CloseRounded, ExpandLessTwoTone, ExpandMoreTwoTone, ViewListRounded } from '@mui/icons-material'
import {
  getAgencyHouseAnalyticsCustomersDetailsRequest,
  getAgencyHouseAnalyticsCustomersPeriodDetailsRequest,
} from '../../../../http/requests/agencyHouseRequests'
import { useSelector } from 'react-redux'
import { getCheckedIds, lowercaseObjectKeys } from '../../../../utils/objectUtils'
import {
  analyticsIncomesExtraListType,
  analyticsIncomesList,
  analyticsIncomesListType,
  analyticsIncomesListViewType,
  customersTotalsObject,
  customersTotalsType,
  getCustomersTotalsTypes,
} from '../../../../types/analyticsIncomesTypes'
import { hebrew } from '../../../../i18n'
import { getMonthNameByNumber } from '../../../../services/getMonthName'
import Loader from '../../../../components/Common/Loader/Loader'
import classNames from 'classnames'
import { getRandomHexColor } from '../../../../services/getRandom'
import { getUserNameInitials } from '../../../../services/getUserNameInitials'
import { categoriesObjects } from '../../../../types/categoriesTypes'
import AgencyHouseAnalyticsHierarchyPicker from '../AgencyHouseAnalyticsHierarchyPicker/AgencyHouseAnalyticsHierarchyPicker'
import AnnualDistributionChart from '../../../../components/Charts/AnnualDistributionChart'
import { Fragment } from 'react-is'
import { isRegularOrDeltaAgency } from '../../../login/reducers/loginSelectors'
import {
  getAgencyAnalyticsCustomersDetailsRequest,
  getAgencyAnalyticsCustomersPeriodDetailsRequest,
} from '../../../../http/requests/agencyAnalyticsRequests'
import './AgencyHouseAnalyticsCustomersDetailsCard.scss'
import { analyticsPeriodViewType } from '../../../../types/analyticsTypes'
import { requestDataTypes } from '../../../../types/requestDataTypes'
import { familyGroupMode } from '../../../shared/reducers/sharedSelectors'

const initalBigAgencyPeriodHierarchy = [
  [analyticsIncomesListType.agents, analyticsIncomesListType.companies, analyticsIncomesListType.categories],
  [analyticsIncomesListType.agents, analyticsIncomesListType.categories, analyticsIncomesListType.companies],
  [analyticsIncomesListType.companies, analyticsIncomesListType.agents, analyticsIncomesListType.categories],
  [analyticsIncomesListType.companies, analyticsIncomesListType.categories, analyticsIncomesListType.agents],
  [analyticsIncomesListType.categories, analyticsIncomesListType.agents, analyticsIncomesListType.companies],
  [analyticsIncomesListType.categories, analyticsIncomesListType.companies, analyticsIncomesListType.agents],
]

const initalRegularAgencyPeriodHierarchy = [
  [analyticsIncomesListType.companies, analyticsIncomesListType.categories],
  [analyticsIncomesListType.categories, analyticsIncomesListType.companies],
]

const AgencyHouseAnalyticsCustomersDetailsCard = ({ item, closeCard, listViewType }) => {
  const { selectedDates, selectedAgencies, categories, companies, periodViewType } = useSelector(
    ({ agencyHouseAnalytics }) => agencyHouseAnalytics
  )
  const calculateByFamilyGroups = familyGroupMode()

  const [details, setDetails] = useState(null)
  const [detailsLoading, setDetailsLoading] = useState(true)
  const [hierarchy, setHierarchy] = useState([])
  const periodHierarchy = isRegularOrDeltaAgency() ? initalRegularAgencyPeriodHierarchy : initalBigAgencyPeriodHierarchy
  const [selectedPeriodHierarchyId, setSelectedPeriodHierarchy] = useState(null)
  const [topLevelEntityType, setTopLevelEntityType] = useState(null)
  const [openItems, setOpenItems] = useState(null)
  const [openSubItems, setOpenSubItems] = useState(null)
  const notMonthlyChartView =
    +listViewType === analyticsIncomesListViewType.chart && periodViewType !== analyticsPeriodViewType.monthly

  const totalsTypes = getCustomersTotalsTypes()
  let mainKey
  let mainKeyValue
  let keys = []
  let allKeys = []
  Object.entries(customersTotalsType)
    .filter(([k, v]) => totalsTypes.includes(v))
    .forEach(([k, v]) => {
      if (customersTotalsObject[v].detailsMainValue) {
        mainKey = k
        mainKeyValue = v
      } else {
        keys.push(k)
      }
      allKeys.push({ key: k, keyValue: v })
    })
  const { t, i18n } = useTranslation('agencyHouseAnalytics')

  const getHierarchies = () => {
    return hierarchy.length > 0
      ? [
          {
            id: 'top',
            text:
              t('orderBy') +
              (hebrew()
                ? analyticsIncomesList[hierarchy[0]].name + ' -> ' + analyticsIncomesList[hierarchy[1]].name
                : analyticsIncomesList[hierarchy[0]].nameEn + ' -> ' + analyticsIncomesList[hierarchy[1]].nameEn),
            value: hierarchy[0],
            selected: hierarchy[0] === topLevelEntityType,
          },
          {
            id: 'inner',
            text:
              t('orderBy') +
              (hebrew()
                ? analyticsIncomesList[hierarchy[1]].name + ' -> ' + analyticsIncomesList[hierarchy[0]].name
                : analyticsIncomesList[hierarchy[1]].nameEn + '-> ' + analyticsIncomesList[hierarchy[0]].nameEn),
            value: hierarchy[1],
            selected: hierarchy[1] === topLevelEntityType,
          },
        ]
      : []
  }
  const getPeriodHierarchies = () =>
    periodHierarchy.map((hSet, hSetIdx) => {
      const id = hSetIdx + 1
      return {
        id,
        text:
          t('orderBy') +
          hSet.map(h => (hebrew() ? analyticsIncomesList[h].name : analyticsIncomesList[h].nameEn)).join(' -> '),
        value: id,
        selected: id === selectedPeriodHierarchyId,
      }
    })

  const dateText = () => {
    const lang = i18n.language
    const dates = selectedDates.filter(d => d.selected)
    if (dates.length === 0) {
      return 'For All Time'
    }
    const [yearFrom, monthFrom] = dates.at(0)?.date.toString().split('-')
    const [yearTo, monthTo] = dates.at(-1)?.date.toString().split('-')
    if (yearFrom === yearTo) {
      return `${getMonthNameByNumber(monthFrom, lang, 'short')}${
        monthFrom !== monthTo ? ' - ' + getMonthNameByNumber(monthTo, lang, 'short') : ''
      } ${yearFrom}`
    } else {
      return `${getMonthNameByNumber(monthFrom, lang, 'short')} ${yearFrom} - ${getMonthNameByNumber(
        monthTo,
        lang,
        'short'
      )} ${yearTo}`
    }
  }

  const getlogo = item => {
    switch (item.entityType) {
      case analyticsIncomesListType.agents:
        return (
          <div
            className='agency-house-analytics-customers-details-item-logo'
            style={{ background: getRandomHexColor() }}
          >
            {getUserNameInitials(item.name)}
          </div>
        )
      case analyticsIncomesListType.companies:
        return (
          <div className='agency-house-analytics-customers-details-item-logo'>
            <img src={`./assets/companies-logos-light/${item.key}.png`} alt={item.name} />
          </div>
        )
      case analyticsIncomesListType.categories:
        return (
          <div
            className='agency-house-analytics-customers-details-item-logo small'
            style={{ background: categoriesObjects[item.key]?.markerColor ?? categoriesObjects['default'].markerColor }}
          ></div>
        )
      default:
        break
    }
  }

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setDetails(null)
        setDetailsLoading(true)
        const getDetailsRequest = isRegularOrDeltaAgency()
          ? getAgencyAnalyticsCustomersDetailsRequest
          : getAgencyHouseAnalyticsCustomersDetailsRequest
        let resp
        if (!notMonthlyChartView) {
          const { data } = await getDetailsRequest({
            categories: getCheckedIds(categories, 'selected'),
            companies: getCheckedIds(companies, 'selected'),
            agenciesIds: selectedAgencies.filter(a => a.selected).map(a => a.key),
            timePeriods: selectedDates.filter(a => a.selected).map(a => a.date),
            mainEntityType: item.type,
            mainEntityId: +item.key,
            topLevelEntityType,
            ...(isRegularOrDeltaAgency() && { calculateByFamilyGroups }),
          })
          resp = data
        } else {
          const getPeriodDetailsRequest = isRegularOrDeltaAgency()
            ? getAgencyAnalyticsCustomersPeriodDetailsRequest
            : getAgencyHouseAnalyticsCustomersPeriodDetailsRequest
          const { data } = await getPeriodDetailsRequest({
            categories: getCheckedIds(categories, 'selected'),
            companies: getCheckedIds(companies, 'selected'),
            agenciesIds: selectedAgencies.filter(a => a.selected).map(a => a.key),
            period: item.key,
            dataType: requestDataTypes.customers,
            entityTypeOrder: [
              analyticsIncomesExtraListType.timePeriods,
              ...periodHierarchy[selectedPeriodHierarchyId - 1],
            ],
            fromNewPolicies: false,
            ...(isRegularOrDeltaAgency() && { calculateByFamilyGroups }),
          })
          resp = data
        }
        const response = lowercaseObjectKeys(resp.Data ?? resp.data)
        setDetails(response)
        const openItems = {}
        const openSubItems = {}
        response.subItems.forEach(si => {
          openItems[si.key] = false
          si.subItems.forEach(ssi => (openSubItems[ssi.key + '' + ssi.entityType + '' + ssi.key] = false))
        })
        setOpenItems(openItems)
        setOpenSubItems(openSubItems)
        setDetailsLoading(false)
      } catch (error) {
        setDetailsLoading(false)
      }
    }
    item &&
      ((topLevelEntityType && !notMonthlyChartView) || (selectedPeriodHierarchyId && notMonthlyChartView)) &&
      fetchDetails()
    return () => {
      setDetails(null)
      setDetailsLoading(true)
    }
  }, [calculateByFamilyGroups, selectedDates, selectedAgencies, item, topLevelEntityType, selectedPeriodHierarchyId])

  useEffect(() => {
    const parentEntityType = item?.type
    let hierarchy = []
    if (isRegularOrDeltaAgency()) {
      switch (parentEntityType) {
        case analyticsIncomesListType.companies:
          hierarchy = [analyticsIncomesListType.categories]
          break
        case analyticsIncomesListType.categories:
          hierarchy = [analyticsIncomesListType.companies]
          break
        default:
          break
      }
    } else {
      switch (parentEntityType) {
        case analyticsIncomesListType.agents:
          hierarchy = [analyticsIncomesListType.companies, analyticsIncomesListType.categories]
          break
        case analyticsIncomesListType.companies:
          hierarchy = [analyticsIncomesListType.agents, analyticsIncomesListType.categories]
          break
        case analyticsIncomesListType.categories:
          hierarchy = [analyticsIncomesListType.agents, analyticsIncomesListType.companies]
          break
        default:
          break
      }
    }
    setHierarchy(hierarchy)
    setTopLevelEntityType(hierarchy[0])
    setSelectedPeriodHierarchy(1)
  }, [item])

  if (!item || (!details && !detailsLoading)) {
    return (
      <div className='agency-house-analytics-customers-details-card'>
        <div className='agency-house-analytics-customers-details-card-empty'>
          <ViewListRounded />
          <p>{t('deatilsCard.emptyPlaceholder')}</p>
        </div>
      </div>
    )
  }

  if (detailsLoading) {
    return (
      <div className='agency-house-analytics-customers-details-card'>
        <div className='agency-house-analytics-customers-details-card-empty'>
          <Loader />
        </div>
      </div>
    )
  }

  return (
    <div className='agency-house-analytics-customers-details-card'>
      <header className='agency-house-analytics-customers-details-card-header'>
        <div>
          <p>
            {hebrew()
              ? customersTotalsObject[mainKeyValue].detailsHeaderName
              : customersTotalsObject[mainKeyValue].detailsHeaderNameEn}
            {details[mainKey]}
          </p>
          <button onClick={closeCard}>
            <CloseRounded />
          </button>
        </div>
        <span>{dateText()}</span>
      </header>
      {notMonthlyChartView ? (
        <div className='agency-house-analytics-customers-details-card-hierarchy'>
          <AgencyHouseAnalyticsHierarchyPicker
            hierarchies={getPeriodHierarchies()}
            setHierarchy={setSelectedPeriodHierarchy}
          />
        </div>
      ) : (
        !isRegularOrDeltaAgency() && (
          <div className='agency-house-analytics-customers-details-card-hierarchy'>
            <AgencyHouseAnalyticsHierarchyPicker hierarchies={getHierarchies()} setHierarchy={setTopLevelEntityType} />
          </div>
        )
      )}
      <div className='agency-house-analytics-customers-details-card-chart'>
        <AnnualDistributionChart
          series={details.subItems.filter(si => si[mainKey]).map(si => si[mainKey])}
          labels={details.subItems.filter(si => si[mainKey]).map(si => (hebrew() ? si.name : si.nameEn))}
          colors={details.subItems
            .filter(si => si[mainKey])
            .map(({ key }) => categoriesObjects[key]?.markerColor ?? categoriesObjects.default.markerColor)}
          valueFormatter={val => val}
        />
      </div>
      <div className='agency-house-analytics-customers-details-card-list-container'>
        <h5>
          {hebrew()
            ? analyticsIncomesList[topLevelEntityType]?.detailsName
            : analyticsIncomesList[topLevelEntityType]?.detailsNameEn}
        </h5>
        <div className='agency-house-analytics-customers-details-card-list'>
          {details.subItems.map(itm => (
            <Fragment key={itm.key}>
              <div
                className={classNames('agency-house-analytics-customers-details-card-list-item', {
                  open: openItems[itm.key],
                })}
                key={itm.key}
              >
                <div>
                  {getlogo(itm)}
                  <p>{hebrew() ? itm.name : itm.nameEn}</p>
                </div>
                <div>
                  <div className='details-card-grid'>
                    {allKeys.map(({ key: k, keyValue: v }) => (
                      <div key={k}>
                        <div className='details-card-grid-header'>
                          {hebrew() ? customersTotalsObject[v].shortName : customersTotalsObject[v].shortNameEn}
                        </div>
                        <div>{itm[k] ?? '--'}</div>
                      </div>
                    ))}
                  </div>
                  <button
                    style={{ visibility: itm.subItems.length > 0 ? 'visible' : 'hidden' }}
                    onClick={() => {
                      setOpenItems(prev => ({ ...prev, [itm.key]: !prev[itm?.key] }))
                    }}
                  >
                    {openItems[itm.key] ? <ExpandLessTwoTone /> : <ExpandMoreTwoTone />}
                  </button>
                </div>
              </div>
              {openItems[itm.key] && (
                <div className='agency-house-analytics-customers-details-card-sublist'>
                  {itm.subItems.map(si => (
                    <Fragment key={si.key}>
                      <div className='agency-house-analytics-customers-details-card-sublist-item' key={si.key}>
                        <div>
                          {getlogo(si)}
                          <p>{hebrew() ? si.name : si.nameEn}</p>
                        </div>
                        <div>
                          <div className='details-card-grid'>
                            {allKeys.map(({ key: k }) => (
                              <div key={k}>
                                <div>{si[k] ?? '--'}</div>
                              </div>
                            ))}
                          </div>
                          <button
                            style={{ visibility: si.subItems.length > 0 ? 'visible' : 'hidden' }}
                            onClick={() => {
                              setOpenSubItems(prev => ({
                                ...prev,
                                [itm?.key + '' + itm?.entityType + '' + si?.key]:
                                  !prev[itm?.key + '' + itm?.entityType + si?.key],
                              }))
                            }}
                          >
                            {openSubItems[itm?.key + '' + itm?.entityType + '' + si?.key] ? (
                              <ExpandLessTwoTone />
                            ) : (
                              <ExpandMoreTwoTone />
                            )}
                          </button>
                        </div>
                      </div>
                      {openSubItems[itm?.key + '' + itm?.entityType + '' + si?.key] && (
                        <div className='agency-house-analytics-customers-details-card-sublist'>
                          {si.subItems.map(ssi => (
                            <div
                              className='agency-house-analytics-customers-details-card-sublist-item inner'
                              key={si.key}
                            >
                              <div>
                                {getlogo(ssi)}
                                <p>{hebrew() ? ssi.name : ssi.nameEn}</p>
                              </div>
                              <div>
                                <div className='details-card-grid'>
                                  {allKeys.map(({ key: k }) => (
                                    <div key={k}>
                                      <div>{ssi[k] ?? '--'}</div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </Fragment>
                  ))}
                </div>
              )}
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  )
}

export default AgencyHouseAnalyticsCustomersDetailsCard
