import { useEffect, useState } from 'react'
import AddUserModal from '../../../customer/components/AddUserModal/AddUserModal'
import { getAgents, getCategories } from '../../../shared/actions/sharedActions'
import { useDispatch, useSelector } from 'react-redux'
import ModalWithNote from '../../../../components/Common/ModalWithNote/ModalWithNote'
import { useTranslation } from 'react-i18next'
import { addTreatmentBatchRequest } from '../../../../http/requests/treatmentRequests'
import { customerTaskType } from '../../../../types/customerTaskTypes'
import CategoriesSelector from '../../../shared/components/CategoriesSelector/CategoriesSelector'
import useFixedPositionRef from '../../../../hooks/useFixedPositionRef'
import { setPoliciesCategoryRequest } from '../../../../http/requests/policyRequests'
import ItemsAppBar from '../../../../components/Common/ItemsAppBar/ItemsAppBar'
import PoliciesAddCustomerContainer from '../PoliciesAddCustomerContainer/PoliciesAddCustomerContainer'

const PoliciesAppBar = ({
  closeBar,
  policies,
  onAddTreat = () => {},
  onUpdateCategory = () => {},
  enableConnectLostPolicies,
  onPoliciesAddCustomer,
}) => {
  const { agents, agentsLoading, categories } = useSelector(({ shared }) => shared)
  const dispatch = useDispatch()
  const { t: tCustomer } = useTranslation('customer')
  const { t } = useTranslation('policies')
  const policiesCategories = [...new Set(policies.map(p => p.categoryId ?? p.category.id))]
  const policiesFields = [...new Set(categories.filter(c => policiesCategories.includes(c.id)).map(c => c.fieldId))]

  const [showAddPoliciesCustomerContainer, setShowPoliciesAddCustomerContainer] = useState(false)
  const [showCategoriesSelector, setShowCategoriesSelector] = useState(false)
  const [updateCategoryLoading, setUpdateCategoryLoading] = useState(false)
  const [categoriesBtnRef, categoriesListRef] = useFixedPositionRef({ offsetTop: 20 })

  const addCustomerHandler = () => {
    onPoliciesAddCustomer()
    closeBar()
  }

  const updateCategoryHandler = async categoryId => {
    try {
      setUpdateCategoryLoading(true)
      const {
        data: {
          data: { updatedPolicies },
        },
      } = await setPoliciesCategoryRequest({
        policiesIds: policies.map(p => p.id),
        categoryId,
        updateAllRelatedPolicies: false,
      })
      setUpdateCategoryLoading(false)
      setShowCategoriesSelector(false)
      if (updatedPolicies.length > 0) {
        onUpdateCategory()
        closeBar()
      }
    } catch (error) {
      console.log('updateCategory Error', error.message)
      setShowCategoriesSelector(false)
      setUpdateCategoryLoading(false)
    }
  }

  const [showAddTreatUserModal, setShowAddTreatUserModal] = useState(false)
  const [treatUser, setTreatUser] = useState(null)
  const [showTreatNoteModal, setShowTreatNoteModal] = useState(false)

  const addUser = newUser => {
    setTreatUser(newUser)
    setShowAddTreatUserModal(false)
    setShowTreatNoteModal(true)
  }

  const addTreatAgentNote = async note => {
    await addTreatmentBatchRequest(
      policies.map(p => ({ policyId: p.id, agentId: treatUser.id, note, type: customerTaskType.policy }))
    )
    onAddTreat()
    closeBar()
  }

  useEffect(() => {
    if (showAddTreatUserModal) {
      dispatch(getAgents())
    }
  }, [showAddTreatUserModal, dispatch])

  useEffect(() => {
    dispatch(getCategories())
  }, [dispatch])

  return (
    <>
      <ItemsAppBar closeBar={closeBar} itemsCount={policies.length}>
        <div className='app-bar-buttons-container'>
          <div className='app-bar-button-container'>
            <button onClick={() => setShowAddTreatUserModal(true)}>
              <div>
                <img src='./assets/app-bar-btn-icons/send-treat.png' alt='send-treat' />
              </div>
              <div>{t('policiesAppBar.sendForTreat')}</div>
            </button>
          </div>
          <div className='app-bar-button-container' ref={categoriesBtnRef}>
            <button disabled={policiesFields.length > 1} onClick={() => setShowCategoriesSelector(true)}>
              <div>
                <img src='./assets/app-bar-btn-icons/categories.png' alt='categories' />
              </div>
              <div>{t('policiesAppBar.categories')}</div>
            </button>
          </div>
          {enableConnectLostPolicies && (
            <div className='app-bar-button-container'>
              <button onClick={() => setShowPoliciesAddCustomerContainer(true)}>
                <div>
                  <img src='./assets/app-bar-btn-icons/share.png' alt='connect' />
                </div>
                <div>{t('policiesAppBar.connectPolicies')}</div>
              </button>
            </div>
          )}
        </div>
      </ItemsAppBar>
      {showAddTreatUserModal && (
        <AddUserModal
          users={agents}
          loading={agentsLoading}
          closeModal={() => setShowAddTreatUserModal(false)}
          closeOnOutsideClick={false}
          onAddUser={addUser}
        />
      )}
      {showTreatNoteModal && (
        <ModalWithNote
          closeModal={() => setShowTreatNoteModal(false)}
          closeOnOutsideClick={false}
          title={tCustomer('treatNoteModal.modalTitle')}
          subtitle={tCustomer('treatNoteModal.modalSubtitle')}
          label={tCustomer('treatNoteModal.inputLabel')}
          btnCaption={tCustomer('treatNoteModal.btnTitle')}
          back={() => setShowAddTreatUserModal(true)}
          onAddNote={addTreatAgentNote}
        />
      )}
      {showCategoriesSelector && (
        <div className='categories-wrapper' ref={categoriesListRef}>
          <CategoriesSelector
            showSelector={showCategoriesSelector}
            closeSelector={() => setShowCategoriesSelector(false)}
            loading={updateCategoryLoading}
            categories={categories.filter(c => policiesFields.includes(c.fieldId))}
            updatePolicyCategory={updateCategoryHandler}
            {...(policiesCategories.length === 1 && { categoryId: policiesCategories[0] })}
          />
        </div>
      )}
      {showAddPoliciesCustomerContainer && (
        <PoliciesAddCustomerContainer
          closeContainer={() => setShowPoliciesAddCustomerContainer(false)}
          policies={policies}
          onAddCustomers={addCustomerHandler}
        />
      )}
    </>
  )
}

export default PoliciesAppBar
