import LandingHeader from '../../features/landing/components/LandingHeader/LandingHeader'
import LandingFooter from '../../features/landing/components/LandingFooter/LandingFooter'
import './MarketingPage.scss'
import LandingContacts from '../../features/landing/components/LandingContacts/LandingContacts'
import MarketingHero from '../../features/marketing/components/MarketingHero/MarketingHero'
import MarketingCompanies from '../../features/marketing/components/MarketingCompanies/MarketingCompanies'
import MarketingConnectionBanner from '../../features/marketing/components/MarketingConnectionBanner/MarketingConnectionBanner'

const MarketingPage = () => {
  return (
    <div className='marketing-page-container' id='marketing'>
      <LandingHeader />
      <MarketingHero />
      <MarketingConnectionBanner />
      <MarketingCompanies />
      <LandingContacts />
      <LandingFooter />
    </div>
  )
}

export default MarketingPage
