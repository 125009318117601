import classNames from 'classnames'
import { createRef, useEffect, useState } from 'react'
import terms from '../../resources/terms'
import ModalWindow from '../ModalWindow/ModalWindow'
import './ModalPaymentAgreement.scss'

const ModalPaymentAgreement = ({ closeModal, confirmAgreement = null, agreementConfirmed = false }) => {
  const [bottom, setBottom] = useState(agreementConfirmed)

  const handleScroll = e => {
    if (!bottom) {
      const scrolledBottom = e.target.scrollHeight - e.target.scrollTop - 10 <= e.target.clientHeight
      setBottom(scrolledBottom)
    }
  }
  return (
    <ModalWindow closeModal={closeModal} showCloseIcon={false} closeOnOutsideClick={false}>
      <div className='modal-payment-agreement'>
        <div className='modal-payment-agreement-header'>
          <h4>תנאי תשלום</h4>
          <button onClick={closeModal}>
            <img src='./assets/cross-icon/cross.png' alt='x' />
          </button>
        </div>
        <div className='modal-payment-agreement-content'>
          <pre className='modal-payment-agreement-text' onScroll={handleScroll}>
            {terms}
          </pre>
        </div>
        {confirmAgreement && (
          <div className='modal-payment-agreement-footer'>
            <button onClick={confirmAgreement} disabled={!bottom}>
              אני מאשר
            </button>
          </div>
        )}
      </div>
    </ModalWindow>
  )
}

export default ModalPaymentAgreement
