import classNames from 'classnames'
import './ButtonCheckbox.scss'
import CheckboxOutlineBlankSvg from '../SvgIcons/CheckboxOutlineBlankSvg/CheckboxOutlineBlankSvg'
import CheckboxCheckedSvg from '../SvgIcons/CheckboxCheckedSvg/CheckboxCheckedSvg'

const ButtonCheckbox = ({ onClick, disabled = false, checked, styles = '', children }) => {
  return (
    <button className={classNames('button-checkbox', styles.split(' '))} disabled={disabled} onClick={onClick}>
      {checked ? <CheckboxCheckedSvg /> : <CheckboxOutlineBlankSvg />}
      {children && <div>{children}</div>}
    </button>
  )
}

export default ButtonCheckbox
