import {
  CUSTOMERS_GET_FILTERS_REQUEST,
  CUSTOMERS_GET_FILTERS_SUCCESS,
  CUSTOMERS_GET_FILTERS_FAIL,
  CUSTOMERS_GET_DASHBOARD_REQUEST,
  CUSTOMERS_GET_DASHBOARD_SUCCESS,
  CUSTOMERS_GET_DASHBOARD_FAIL,
  CUSTOMERS_FILTER_BY_COMPANIES,
  CUSTOMERS_FILTER_BY_CATEGORIES,
  CUSTOMERS_FILTER_BY_GROUP,
  CUSTOMERS_SET_PAGE_SIZE,
  CUSTOMERS_GET_REQUEST,
  CUSTOMERS_GET_SUCCESS,
  CUSTOMERS_GET_FAIL,
  CUSTOMERS_SET_PAGE,
  CUSTOMERS_SET_SEARCH,
  CUSTOMERS_SET_SORT_BY,
  CUSTOMERS_COLLAPSE_GROUP,
  CUSTOMERS_CLEAR_LIST,
  CUSTOMERS_FILTER_BY_GROUP_TYPE,
  CUSTOMERS_GROUPS_REQUEST,
  CUSTOMERS_GROUPS_SUCCESS,
  CUSTOMERS_GROUPS_FAIL,
  CUSTOMERS_GROUP_EDIT_REQUEST,
  CUSTOMERS_GROUP_EDIT_SUCCESS,
  CUSTOMERS_GROUP_EDIT_FAIL,
  CUSTOMERS_GROUP_DELETE_REQUEST,
  CUSTOMERS_GROUP_DELETE_SUCCESS,
  CUSTOMERS_GROUP_DELETE_FAIL,
} from '../../../types/actionTypes'
import { urlCustomer, urlCustomers } from '../../../environment/urls'
import { sortOrder } from '../../../types/sortByTypes'
import { policyRatingType } from '../../../types/policyRatingTypes'
import { customersListType } from '../../../types/tablesTypes'
import $api from '../../../http'
import axios from 'axios'
import {
  deleteCustomersGroupRequest,
  editCustomersGroupRequest,
  getCustomersGroupsRequest,
} from '../../../http/requests'
import { insuranceCategory } from '../../../types/insuranceCategory'
import { getCheckedIds } from '../../../utils/objectUtils'
import { getCustomersFiltersRequest } from '../../../http/requests/customersRequests'
import { familyGroupMode } from '../../shared/reducers/sharedSelectors'
let requestCancel

const customersUrl = urlCustomers()
const customerUrl = urlCustomer()

export const getCustomers =
  ({ listType, totalElements }) =>
  async (dispatch, getState) => {
    if (listType === customersListType.groups) return
    dispatch({ type: CUSTOMERS_GET_REQUEST })
    try {
      let request
      let currentListType = 0
      let rating = null
      switch (listType) {
        case customersListType.rating:
          currentListType = 1
          break
        case customersListType.ratingGreen:
          rating = policyRatingType.green
          currentListType = 1
          break
        case customersListType.ratingOrange:
          rating = policyRatingType.orange
          currentListType = 1
          break
        case customersListType.ratingRed:
          rating = policyRatingType.red
          currentListType = 1
          break
        case customersListType.distribution:
          currentListType = 2
          break
        case customersListType.inactive:
          currentListType = 3
          break
        default:
          break
      }
      const {
        advancedFiltersApplied,
        sort,
        categoriesFilter,
        companiesFilter,
        categoriesAndFilter,
        companiesAndFilter,
        categoriesNoFilter,
        companiesNoFilter,
        groupFilter,
        searchTerm: searchText,
        page,
        pageSize,
        collapsedGroups,
      } = getState().customers
      const calculateByGroup = familyGroupMode()

      request = {
        categories: getCheckedIds(advancedFiltersApplied ? categoriesAndFilter : categoriesFilter),
        companies: getCheckedIds(advancedFiltersApplied ? companiesAndFilter : companiesFilter),
        excludeCategories: getCheckedIds(advancedFiltersApplied ? categoriesNoFilter : []),
        excludeCompanies: getCheckedIds(advancedFiltersApplied ? companiesNoFilter : []),
        shouldHaveBoth: advancedFiltersApplied,
        percentageSplitGroups: groupFilter.filter(c => c.checked).map(c => c.id + 1),
        searchText,
        customersListType: currentListType,
        page,
        pageSize,
        sortBy: sort.by,
        descending: sort.order === sortOrder.desc,
        collapsedGroups: Object.entries(collapsedGroups)
          .filter(([key, v]) => !v.show)
          .map(([key]) => key),
        rating,
        totalElements,
        calculateByGroup,
      }
      //Check if there are any previous pending requests
      if (typeof requestCancel != typeof undefined) {
        requestCancel.cancel('Operation canceled due to new request.')
      }

      //Save the cancel token for the current request
      requestCancel = axios.CancelToken.source()

      const {
        data: { data },
      } = await $api.post(
        `${customersUrl}${
          // list Type 2 - customers distribution
          request.customersListType === 2 ? 'get-percentage-customers-list' : 'customers-list'
        }`,
        request,
        { cancelToken: requestCancel.token }
      )
      dispatch({
        type: CUSTOMERS_GET_SUCCESS,
        payload: { ...data, groups: data.groups.filter(d => d !== null) },
      })
    } catch (error) {
      dispatch({
        type: CUSTOMERS_GET_FAIL,
        payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      })
    }
  }

export const getCustomersFilters =
  (selectedCompanies = [], selectedCategories = []) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: CUSTOMERS_GET_FILTERS_REQUEST })
      const {
        data: { data },
      } = await getCustomersFiltersRequest()
      const {
        data: { data: groupTypesFilter },
      } = await $api.get(`${customerUrl}group-types`)
      const companiesFilter = data

      const categoriesFilter = data
        .map(company => company.categories)
        .flat()
        .filter(item => item)
        .filter(
          (item, index, self) => index === self.findIndex(categ => categ.id === item.id && categ.name === item.name)
        )
        .sort((a, b) => insuranceCategory[a.id].sortOrder - insuranceCategory[b.id].sortOrder)

      const groupFilter = getState().customers.groupFilter

      dispatch({
        type: CUSTOMERS_GET_FILTERS_SUCCESS,
        payload: { companiesFilter, categoriesFilter, groupFilter, groupTypesFilter },
      })
    } catch (error) {
      dispatch({
        type: CUSTOMERS_GET_FILTERS_FAIL,
        payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      })
    }
  }

export const getCustomersGroups = loadingId => async (dispatch, getState) => {
  const {
    companiesFilter,
    categoriesFilter,
    customersGroups,
    groupTypesFilter,
    groupsSort,
    searchTerm,
    customersCollapsedGroups,
    page,
    pageSize,
  } = getState().customers
  const calculateByGroup = familyGroupMode()
  const sortBy = groupsSort.by
  const descending = groupsSort.order === sortOrder.desc
  const groupTypes = getCheckedIds(groupTypesFilter)
  const companies = getCheckedIds(companiesFilter)
  const categories = getCheckedIds(categoriesFilter)

  const totalElements = customersGroups
    .filter(gr => !(customersCollapsedGroups.indexOf(gr.groupName) > -1))
    .map(gr => gr.itemsInGroup)
    .reduce((previousValue, currentValue) => previousValue + currentValue, 0)
  dispatch({ type: CUSTOMERS_GROUPS_REQUEST, payload: loadingId })
  try {
    const { groups, totalPages: groupsTotalPages } = await getCustomersGroupsRequest({
      companies,
      categories,
      groupTypes,
      sortBy,
      descending,
      name: searchTerm,
      collapsedGroups: customersCollapsedGroups,
      page,
      pageSize,
      totalElements,
      calculateByGroup,
    })
    dispatch({
      type: CUSTOMERS_GROUPS_SUCCESS,
      payload: { groups, groupsTotalPages },
    })
  } catch (error) {
    dispatch({
      type: CUSTOMERS_GROUPS_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const editCustomersGroup = group => async dispatch => {
  dispatch({ type: CUSTOMERS_GROUP_EDIT_REQUEST, payload: group })
  try {
    const { loading, ...groupDto } = group
    const { data: success } = await editCustomersGroupRequest(groupDto)
    dispatch({
      type: CUSTOMERS_GROUP_EDIT_SUCCESS,
      payload: { success, group },
    })
  } catch (error) {
    dispatch({
      type: CUSTOMERS_GROUP_EDIT_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const deleteCustomersGroup = id => async dispatch => {
  dispatch({ type: CUSTOMERS_GROUP_DELETE_REQUEST, payload: id })
  try {
    const success = await deleteCustomersGroupRequest(id)
    dispatch({
      type: CUSTOMERS_GROUP_DELETE_SUCCESS,
      payload: { success, id },
    })
  } catch (error) {
    dispatch({
      type: CUSTOMERS_GROUP_DELETE_FAIL,
      payload: {
        error: error.response && error.response.data.message ? error.response.data.message : error.message,
        id,
      },
    })
  }
}

export const getCustomersTotalDashboard =
  ({ silentLoading = false, calculateByGroup = false } = {}) =>
  async (dispatch, getState) => {
    dispatch({ type: CUSTOMERS_GET_DASHBOARD_REQUEST, payload: silentLoading })
    try {
      const {
        data: { data: dashboardTotal },
      } = await $api.get(`${customersUrl}dashboard-info/${calculateByGroup}`)
      dispatch({
        type: CUSTOMERS_GET_DASHBOARD_SUCCESS,
        payload: dashboardTotal,
      })
    } catch (error) {
      dispatch({
        type: CUSTOMERS_GET_DASHBOARD_FAIL,
        payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      })
    }
  }

export const customersFilterByCompanies = (id, checked) => {
  return {
    type: CUSTOMERS_FILTER_BY_COMPANIES,
    payload: { id, checked },
  }
}

export const customersFilterByCategories = (id, checked) => {
  return {
    type: CUSTOMERS_FILTER_BY_CATEGORIES,
    payload: { id, checked },
  }
}

export const customersFilterByGroupType = (id, checked) => {
  return {
    type: CUSTOMERS_FILTER_BY_GROUP_TYPE,
    payload: { id, checked },
  }
}

export const customersFilterByGroup = (groupId, checked) => {
  return {
    type: CUSTOMERS_FILTER_BY_GROUP,
    payload: { groupId, checked },
  }
}

export const customersSetSearchTerm = searchTerm => {
  return {
    type: CUSTOMERS_SET_SEARCH,
    payload: searchTerm,
  }
}

export const customersCollapseGroup = groupName => {
  return {
    type: CUSTOMERS_COLLAPSE_GROUP,
    payload: groupName,
  }
}

export const customersSetSort = sort => {
  return {
    type: CUSTOMERS_SET_SORT_BY,
    payload: sort,
  }
}

export const customersSetPageSize = pageSize => {
  return {
    type: CUSTOMERS_SET_PAGE_SIZE,
    payload: pageSize,
  }
}

export const customersSetPage = page => {
  return {
    type: CUSTOMERS_SET_PAGE,
    payload: page,
  }
}

export const customersClearList = () => ({ type: CUSTOMERS_CLEAR_LIST })
