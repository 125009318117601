import { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import AddTaskModal from '../AddTaskModal/AddTaskModal'
import './AddTask.scss'

const AddTask = () => {
  const { t } = useTranslation('workstation')
  const [showAddModal, setShowAddModal] = useState(false)

  return (
    <>
      <button className='add-task-btn' onClick={() => setShowAddModal(true)}>
        <img src={`./assets/icon-add-white/Add${isMobile ? '-mobile' : ''}.png`} className='img-icon' alt='add-task' />
        {!isMobile && <span>{t('newTask')}</span>}
      </button>
      {showAddModal && <AddTaskModal closeModal={() => setShowAddModal(false)} />}
    </>
  )
}

export default AddTask
