import { useTranslation } from 'react-i18next'
import { companies } from '../../../../types/companies'
import ReactPlayer from 'react-player/youtube'
import './ProfileEditEmailCompanyInstructions.scss'
import { useSelector } from 'react-redux'

const ProfileEditEmailCompanyInstructions = ({ company }) => {
  const { t } = useTranslation('profile')
  const {
    details: { reportEmail },
  } = useSelector(({ profile }) => profile)
  const baseCompany = companies.find(c => c.id === company.id)

  return (
    <div className='profile-company-instructions-container'>
      <p className='profile-company-instructions-title'>
        {baseCompany.emailInstructionsTitle ? baseCompany.emailInstructionsTitle : t('defaultEmailInstructions.title')}
      </p>

      <ul className='profile-company-instructions'>
        {baseCompany.emailInstructions ? (
          baseCompany.emailInstructions.map(i => <li key={i}>{i}</li>)
        ) : (
          <li>{t('defaultEmailInstructions.insturctions1')}</li>
        )}
      </ul>
      <div className='profile-company-instructions-email-container'>
        <p>{reportEmail}</p>
        <div>
          {reportEmail ? (
            <button
              onClick={e => {
                e.stopPropagation()
                navigator.clipboard.writeText(reportEmail)
              }}
            >
              <div>
                <img src='./assets/copy-icon/copy-icon.png' alt='copy' />
              </div>
              <div>{t('copyEmail')}</div>
            </button>
          ) : (
            <p>No email found</p>
          )}
        </div>
      </div>
      {
        <ul className='profile-company-instructions'>
          {baseCompany.emailInstructionsAdditional ? (
            baseCompany.emailInstructionsAdditional.map(i => <li key={i}>{i}</li>)
          ) : (
            <>
              <li>{t('defaultEmailInstructions.instructionsAdditional1')}</li>
              <li>{t('defaultEmailInstructions.instructionsAdditional2')}</li>
            </>
          )}
        </ul>
      }
      {baseCompany.youtubeInstructions && (
        <div className='profile-company-instructions-video'>
          <ReactPlayer
            url='https://www.youtube.com/watch?v=ysz5S6PUM-U'
            width={'unset'}
            height={'unset'}
            muted={true}
            controls={true}
          />
        </div>
      )}
    </div>
  )
}

export default ProfileEditEmailCompanyInstructions
