import { InfoTwoTone } from '@mui/icons-material'
import './AgencyHouseLobbyTotalsCard.scss'
import Loader from '../../../../components/Common/Loader/Loader'
import classNames from 'classnames'

const AgencyHouseLobbyTotalsCard = ({ loading, icon, name, children, onCardClick }) => {
  return (
    <div
      className={classNames('agency-house-lobby-totals-card', { clickable: onCardClick })}
      {...(onCardClick && { onClick: onCardClick })}
    >
      <header className='agency-house-lobby-totals-card-header'>
        <div className='agency-house-lobby-totals-card-icon'>{icon}</div>
        <div className='agency-house-lobby-totals-card-heading'>
          <p>{name}</p>
          <InfoTwoTone />
        </div>
      </header>
      <main className='agency-house-lobby-totals-card-content'>{loading ? <Loader /> : children}</main>
    </div>
  )
}

export default AgencyHouseLobbyTotalsCard
