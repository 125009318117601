import { useState, useRef } from 'react'
import classNames from 'classnames'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import { hebrew, rtlDir } from '../../i18n'
import { useTranslation } from 'react-i18next'
import './HierarchyPicker.scss'

const HierarchyPicker = ({ hierarchies = [], setHierarchy, entities = [] }) => {
  const { t } = useTranslation('common')
  const [showPicker, setShowPicker] = useState(false)
  const btnRef = useRef()
  const overlayRef = useRef()

  const getButtonPostition = () => {
    const { bottom, left, right } = btnRef?.current?.getBoundingClientRect()
    return { left, right, y: bottom }
  }

  const selectedText = hebrew() ? hierarchies.find(h => h.selected)?.text : hierarchies.find(h => h.selected)?.textEn

  return (
    <div className='hierarchy-picker-container'>
      <div className='hierarchy-picker-btn-container' ref={btnRef}>
        <div className={classNames('hierarchy-picker-btn')} onClick={() => setShowPicker(!showPicker)}>
          <span>{t('orderBy') + ': ' + selectedText}</span>
          {showPicker ? <ArrowDropUp /> : <ArrowDropDown />}
        </div>
      </div>
      {showPicker && (
        <div
          className='hierarchy-picker-overlay'
          ref={overlayRef}
          onClick={e => {
            overlayRef.current === e.target && setShowPicker(false)
          }}
        >
          <div
            className='hierarchy-picker-main-container'
            style={{
              top: getButtonPostition().y + 14 + 'px',
              ...(rtlDir()
                ? { left: getButtonPostition().right + 'px', transform: 'translateX(-100%)' }
                : { left: getButtonPostition().left + 'px' }),
              // minWidth: getButtonPostition().right - getButtonPostition().left + 'px',
            }}
          >
            <div className='hierarchy-picker-agents-container'>
              {hierarchies.map(({ id, text, textEn, selected, value }) => (
                <div
                  key={id}
                  className={classNames('hierarchy-picker-agent-container', {
                    selected,
                  })}
                  onClick={() => {
                    setHierarchy(id)
                    setShowPicker(false)
                  }}
                >
                  {hebrew() ? text : textEn}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default HierarchyPicker
