import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import CustomerInfoContainer from '../../features/customer/components/CustomerContainers/CustomerInfoContainer'
import PolicyDetails from '../../features/policy/components/PolicyDetails/PolicyDetails'
import NotifyDialog from '../../components/NotifyDialog/NotifyDialog'
import { getCustomerInfo } from '../../features/customer/actions/customerActions'
import { getCategories } from '../../features/shared/actions/sharedActions'
import { CUSTOMER_INFO_CLEAR, POLICY_RESET } from '../../types/actionTypes'
import { selectPolicy } from '../../features/policy/actions/policyActions'
import { isMobile } from 'react-device-detect'
import NoMobileLayout from '../../components/NoMobileLayout/NoMobileLayout'
import AgentGroupsBanner from '../../components/AgentGroupsBanner/AgentGroupsBanner'
import CustomerTotals from '../../features/customer/components/CustomerTotals/CustomerTotals'
import CustomerMainContainer from '../../features/customer/components/CustomerContainers/CustomerMainContainer'
import { getPoliciesFilters } from '../../features/policies/actions/policiesActions'
import BackToBigAgency from '../../features/shared/components/BackToBigAgency/BackToBigAgency'
import { familyGroupMode } from '../../features/shared/reducers/sharedSelectors'
import { SHARED_SET_FAMILY_GROUPS_TYPE } from '../../types/actionTypesShared'
import { familyGroupType } from '../../types/familyGroupTypes'
import './CustomerScreen.scss'

const CustomerScreen = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const { i18n } = useTranslation('customer')
  const [notifications, setNotifications] = useState([])

  const customerInfo = useSelector(state => state.customer).customerInfo
  const customerInfoLoading = useSelector(state => state.customer).customerInfoLoading
  const customerGroupMembers = useSelector(state => state.customer).customerGroupMembers
  const selectedPolicy = useSelector(state => state.policy).selectedPolicy
  const policy = useSelector(state => state.customer).policies.find(policy => policy.id === selectedPolicy)
  const { selectedGroupsIds } = useSelector(({ shared }) => shared)

  const addNotification = notification => {
    setNotifications(prev => {
      let hasNotification = prev.map(n => n.id).includes(notification.id)
      if (hasNotification) {
        return prev.map(n => (n.id === notification.id ? notification : n))
      } else prev.push(notification)
      return [...prev]
    })
  }
  const removeNotification = id => {
    setNotifications(notifications.filter(n => n.id !== id))
  }

  const closeSidebar = () => {
    dispatch(selectPolicy())
  }

  useEffect(() => {
    if (id) {
      dispatch(getCustomerInfo(id))
    }
  }, [dispatch, id, selectedGroupsIds])

  useEffect(() => {
    const { id = null, familyGroupId } = customerInfo ?? {}
    if (id && !familyGroupId && familyGroupMode()) {
      dispatch({ type: SHARED_SET_FAMILY_GROUPS_TYPE, payload: familyGroupType.standalone })
    }
  }, [customerInfo, dispatch])

  useEffect(() => {
    dispatch(getCategories())
    dispatch(getPoliciesFilters())
    return () => {
      dispatch({ type: CUSTOMER_INFO_CLEAR })
      dispatch({ type: POLICY_RESET })
    }
  }, [dispatch])

  if (isMobile) {
    return <NoMobileLayout pageName={customerInfo.name} />
  }

  return (
    <div className='customer-screen'>
      <div className='customer-screen-banners'>
        <BackToBigAgency />
        <AgentGroupsBanner />
      </div>
      <CustomerInfoContainer
        customerInfo={customerInfo}
        customerInfoLoading={customerInfoLoading}
        customerGroupMembers={customerGroupMembers}
      />
      <div>
        <CustomerTotals id={id} />
        <CustomerMainContainer />
      </div>
      {policy && (
        <PolicyDetails
          closeDetails={closeSidebar}
          notify={addNotification}
          policy={{ ...policy, clientId: customerInfo.id, clientName: customerInfo.name }}
        />
      )}
      {notifications.length > 0 && (
        <div className='notify-modals-wrapper'>
          <div className='notify-modals-container'>
            {notifications.map(notify => (
              <NotifyDialog
                text={i18n.language === 'he' ? notify.text : notify.textEn}
                closeNotify={() => removeNotification(notify.id)}
                closeNotifyTimout={3500}
                timeoutStep={500}
                key={notify.id + notify.text}
                confirm={() => notify.cancelAction && notify.cancelAction()}
                onCloseAction={() => notify.action()}
                confirmText={i18n.language === 'he' ? notify.actionText : notify.actionTextEn}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default CustomerScreen
