import {
  AGENT_HOUSE_DOWNLOAD_AGENCIES_FAIL,
  AGENT_HOUSE_DOWNLOAD_AGENCIES_REQUEST,
  AGENT_HOUSE_DOWNLOAD_AGENCIES_SHOW_COMPANIES,
  AGENT_HOUSE_DOWNLOAD_AGENCIES_SHOW_GROUPS,
  AGENT_HOUSE_DOWNLOAD_AGENCIES_SUCCESS,
  AGENT_HOUSE_DOWNLOAD_CHECK_RESULTS,
  AGENT_HOUSE_DOWNLOAD_HISTORY_FAIL,
  AGENT_HOUSE_DOWNLOAD_HISTORY_REQUEST,
  AGENT_HOUSE_DOWNLOAD_HISTORY_SHOW_AGENCIES,
  AGENT_HOUSE_DOWNLOAD_HISTORY_SHOW_COMPANIES,
  AGENT_HOUSE_DOWNLOAD_HISTORY_SUCCESS,
  AGENT_HOUSE_DOWNLOAD_RESET,
} from '../../../types/actionTypes'

const initialState = {
  Agencies: [],
  AvailableReports: 0,
  FailedReports: 0,
  agenciesLoading: true,
  history: [],
  historyLoading: true,
  checkDownloadResults: false,
}

export const agencyHouseDownloadReducer = (state = initialState, action) => {
  const { payload, type } = action
  switch (type) {
    case AGENT_HOUSE_DOWNLOAD_AGENCIES_REQUEST: {
      return {
        ...state,
        // Agencies: [],
        // AvailableReports: 0,
        // FailedReports: 0,
        ...(payload && { Agencies: [], AvailableReports: 0, FailedReports: 0 }),
        agenciesLoading: payload,
        agenciesError: null,
      }
    }
    case AGENT_HOUSE_DOWNLOAD_AGENCIES_SUCCESS: {
      return {
        ...state,
        ...payload,
        agenciesLoading: false,
        agenciesError: null,
        ...(state.checkDownloadResults && { checkDownloadResults: false }),
      }
    }
    case AGENT_HOUSE_DOWNLOAD_AGENCIES_FAIL: {
      return {
        ...state,
        agenciesLoading: false,
        agenciesError: payload,
      }
    }
    case AGENT_HOUSE_DOWNLOAD_AGENCIES_SHOW_COMPANIES: {
      return {
        ...state,
        Agencies: state.Agencies.map(a => ({
          ...a,
          showCompanies: a.Id === payload ? !a.showCompanies : a.showCompanies,
        })),
      }
    }
    case AGENT_HOUSE_DOWNLOAD_AGENCIES_SHOW_GROUPS: {
      const { agencyId, showGroupType } = payload
      return {
        ...state,
        Agencies: state.Agencies.map(a => ({
          ...a,
          [showGroupType]: a.Id === agencyId ? !a[showGroupType] : a[showGroupType],
        })),
      }
    }
    case AGENT_HOUSE_DOWNLOAD_HISTORY_REQUEST: {
      return {
        ...state,
        ...(payload && { history: [] }),
        historyLoading: payload,
        historyError: null,
      }
    }
    case AGENT_HOUSE_DOWNLOAD_HISTORY_SUCCESS: {
      return {
        ...state,
        history: payload,
        historyLoading: false,
        historyError: null,
        ...(state.checkDownloadResults && { checkDownloadResults: false }),
      }
    }
    case AGENT_HOUSE_DOWNLOAD_HISTORY_FAIL: {
      return {
        ...state,
        historyLoading: false,
        historyError: payload,
      }
    }
    case AGENT_HOUSE_DOWNLOAD_HISTORY_SHOW_AGENCIES: {
      const { month, year } = payload
      return {
        ...state,
        history: state.history.map(h => ({
          ...h,
          showAgencies: h.Year === year && h.Month === month ? !h.showAgencies : h.showAgencies,
        })),
      }
    }
    case AGENT_HOUSE_DOWNLOAD_HISTORY_SHOW_COMPANIES: {
      const { month, year, id } = payload
      return {
        ...state,
        history: state.history.map(h => ({
          ...h,
          Agencies:
            h.Month === month && h.Year === year
              ? h.Agencies.map(a => ({ ...a, showCompanies: a.Id === id ? !a.showCompanies : a.showCompanies }))
              : h.Agencies,
        })),
      }
    }
    case AGENT_HOUSE_DOWNLOAD_CHECK_RESULTS: {
      return { ...state, checkDownloadResults: true }
    }
    // case DOWNLOAD_REMOVE_COMPANY_SUCCESS:{
    //   const {companyId, agency}
    //   return { ...state, checkDownloadResults: true }
    // }
    case AGENT_HOUSE_DOWNLOAD_RESET: {
      return initialState
    }
    default:
      return state
  }
}
