import { useMemo, useState } from 'react'
import debounce from 'debounce'
import { hebrew } from '../../../../i18n'
import { useTranslation } from 'react-i18next'
import './PoliciesListPageSize.scss'

const PoliciesListPageSize = ({ pageSize, setPageSize, itemsCount, page, setPage }) => {
  const { t } = useTranslation('common')
  const [inputValue, setInputValue] = useState(pageSize ?? 1)
  const debounceSetValue = useMemo(() => debounce(value => setPageSize(value), 300), [setPageSize])
  const onInputHandler = e => {
    e.target.value = e.target.value.replace(/\D/g, '')
  }

  const onChangeHandler = e => {
    if (e.target.value >= 1 && e.target.value <= 100) {
      setInputValue(+e.target.value)
      debounceSetValue(+e.target.value)
    } else if (e.target.value < 1) {
      setInputValue(1)
      debounceSetValue(1)
    } else if (e.target.value > 100) {
      setInputValue(100)
      debounceSetValue(100)
    } else if (e.target.value === '') {
      return
    }
  }

  const pagesCount = Math.ceil(itemsCount / pageSize)

  const goForward = () => {
    page < pagesCount && setPage(prev => prev + 1)
  }
  const goBack = () => {
    page > 1 && setPage(prev => prev - 1)
  }

  return (
    <div className='policies-pagination'>
      <div className='paginate-size-selector'>
        <p>{t('paginate.itemsPerPage')}</p>
        <input type='text' value={inputValue} onChange={onChangeHandler} onInput={onInputHandler} />
      </div>
      {/* <div>
        <p>items count: {itemsCount}</p>
        <p>pages count: {pagesCount}</p>
        <p>page: {page}</p>
      </div> */}
      <div className='paginate-content' dir='ltr'>
        {pageSize * page !== itemsCount ? pageSize * page - pageSize + 1 + '-' : ''}
        {pageSize * page > itemsCount ? itemsCount : pageSize * page} of {itemsCount}
      </div>
      <div className='paginate-btns-container'>
        <button onClick={goBack} disabled={page <= 1}>
          <i className={`fas fa-chevron-${hebrew() ? 'right' : 'left'}`} />
        </button>
        <button onClick={goForward} disabled={page >= pagesCount}>
          <i className={`fas fa-chevron-${hebrew() ? 'left' : 'right'}`} />
        </button>
      </div>
    </div>
  )
}

export default PoliciesListPageSize
