import { useState } from 'react'
import SignoutButton from '../../../../components/Common/SignoutButton/SignoutButton'
import LogoLightSvg from '../../../../components/LogoLightSvg/LogoLightSvg'
import LogoutConfirm from '../../../login/components/LogoutConfirm/LogoutConfirm'
import './SidebarAgentHouse.scss'
import { useDispatch } from 'react-redux'
import { logout } from '../../../login/actions/loginActions'
import { NavLink, useHistory } from 'react-router-dom'
import { agencyHouseRoutes } from '../../../../environment/urls'
import { bigAgencyPrivateRoutes } from '../../../../routes/routes'
import { hebrew } from '../../../../i18n'
import AgencyHouseAnalyticsNavbar from '../../../agencyHouseAnalytics/components/AgencyHouseAnalyticsNavbar/AgencyHouseAnalyticsNavbar'

const SidebarAgentHouse = () => {
  const dispatch = useDispatch()
  let history = useHistory()
  const downloadRoute = bigAgencyPrivateRoutes.find(({ path }) => path === agencyHouseRoutes.download)

  const [showLogoutModal, setShowLogoutModal] = useState(false)
  const signOutClickHandler = () => {
    setShowLogoutModal(true)
  }
  const closeModal = () => {
    setShowLogoutModal(false)
  }

  const singOut = () => {
    dispatch(logout())
    history.push('/')
  }
  return (
    <div className='sidebar-agent-house'>
      <LogoLightSvg />
      <nav>
        <ul>
          {bigAgencyPrivateRoutes
            .filter(r => r.path !== agencyHouseRoutes.download && !r.path.includes(agencyHouseRoutes.subagency))
            .map(r => {
              return (
                <li key={r.path}>
                  <NavLink
                    to={r.path}
                    activeClassName='current-link'
                    isActive={(match, location) => {
                      if (!match) {
                        return false
                      }
                      return true
                    }}
                  >
                    {r.navIcon}
                    <span>{hebrew() ? r.name : r.nameEn}</span>
                  </NavLink>
                  {r.path.includes(agencyHouseRoutes.analytics) && (
                    <div className='navbar-hidden-subitems'>
                      <AgencyHouseAnalyticsNavbar />
                    </div>
                  )}
                </li>
              )
            })}
        </ul>
      </nav>

      <div className='bottom-links'>
        <NavLink
          className='download-link'
          to={downloadRoute.path}
          activeClassName='current-link'
          isActive={(match, location) => {
            if (!match) {
              return false
            }
            return true
          }}
        >
          {downloadRoute.navIcon}
        </NavLink>
        <div className='icon-signout'>
          <SignoutButton onClickHandler={signOutClickHandler} />
        </div>
      </div>
      {showLogoutModal && <LogoutConfirm closeModal={closeModal} signoutHandler={singOut} />}
    </div>
  )
}

export default SidebarAgentHouse
