import ReactApexChart from 'react-apexcharts'
import './RadialChartScanProgress.css'

const RadialChartScanProgress = ({ progress, height = '170px', width = '170px' }) => {
  const options = {
    chart: {
      //height: '160',
      parentHeightOffset: 0,
      type: 'radialBar',
      selection: {
        enabled: false,
      },
    },
    fill: {
      colors: ['var(--trend-up-color)'],
    },
    grid: {
      padding: {
        top: -20,
        right: -20,
        bottom: -20,
        left: -20,
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
        },
      },
      hover: {
        filter: {
          type: 'none',
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
        },
      },
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: '50%',
        },
        track: {
          background: 'var(--trend-up-color)',
          opacity: 0.17,
        },
        dataLabels: {
          name: {
            show: false,
            offsetY: 0,
          },
          value: {
            show: true,
            fontSize: '30',
            fontFamily: 'Montserrat',
            fontWeight: 600,
            color: 'var(--text-primary-color)',
            offsetY: 8,
            formatter: val => val.toFixed() + '%',
          },
        },
      },
    },
  }

  const series = [(Math.round(progress) / 100) * 100]
  return (
    <div className='radial-chart-scan-progress-container'>
      <ReactApexChart options={options} series={series} type='radialBar' height={height} width={width} />
    </div>
  )
}

export default RadialChartScanProgress
