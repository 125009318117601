import { EmojiPeopleRounded } from '@mui/icons-material'
import './FamilyGroupsToggle.scss'
import { FamilyRestroomRounded } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { SHARED_SET_FAMILY_GROUPS_TYPE } from '../../types/actionTypesShared'
import { familyGroupType } from '../../types/familyGroupTypes'
import { cn } from '../../utils/stylesUtils'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog'

const FamilyGroupsToggle = () => {
  const { familyGroupsType } = useSelector(({ shared }) => shared)
  const [familyTypeToSwitch, setFamilyTypeToSwitch] = useState(null)
  const dispatch = useDispatch()
  const { t } = useTranslation('common')

  const setFamilyGroupsType = type => e => {
    e.stopPropagation()
    familyGroupsType !== type && setFamilyTypeToSwitch(type)
  }

  const updateFamilyMode = () => {
    dispatch({ type: SHARED_SET_FAMILY_GROUPS_TYPE, payload: familyTypeToSwitch })
    setFamilyTypeToSwitch(null)
  }

  return (
    <div className='family-groups-toggle' onClick={e => e.stopPropagation()}>
      <button
        title={t('familyGroupsToggle.standalone')}
        onClick={setFamilyGroupsType(familyGroupType.standalone)}
        className={cn({ selected: familyGroupsType === familyGroupType.standalone })}
      >
        <EmojiPeopleRounded />
      </button>
      <button
        title={t('familyGroupsToggle.group')}
        onClick={setFamilyGroupsType(familyGroupType.group)}
        className={cn({ selected: familyGroupsType === familyGroupType.group })}
      >
        <FamilyRestroomRounded />
      </button>
      {familyTypeToSwitch && (
        <ConfirmDialog
          confirm={updateFamilyMode}
          decline={() => setFamilyTypeToSwitch(null)}
          title={t(`familyGroupsToggle.switchMode`)}
          text={t(
            `familyGroupsToggle.switchTo${familyTypeToSwitch === familyGroupType.group ? 'Family' : 'Single'}Mode`
          )}
          confirmText={t('familyGroupsToggle.yesBtn')}
          declineText={t('familyGroupsToggle.cancelBtn')}
        />
      )}
    </div>
  )
}

export default FamilyGroupsToggle
