export const getUserNameInitials = (userName, charsCount = 2) => {
  if (!userName || userName.length === 0) return 'X'
  return userName
    .split(' ')
    .filter(x => x !== '')
    .map((n, i) => {
      return n[0].toUpperCase()
    })
    .slice(0, charsCount)
    .join('')
}
