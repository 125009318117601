import { cn } from '../../../utils/stylesUtils'
import './ButtonTab.scss'

const ButtonTab = ({ onClick, tabName, badge, selected }) => {
  return (
    <button className={cn('button-tab', { selected })} {...(!selected && { onClick: onClick })}>
      <span>{tabName}</span>
      <span className='button-tab-badge'>{badge}</span>
    </button>
  )
}

export default ButtonTab
