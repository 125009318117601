import { useState } from 'react'
import { customerTableType } from '../../../../types/tablesTypes'
import CustomerTablesNavbar from '../CustomerTablesNavbar/CustomerTablesNavbar'
import { useTranslation } from 'react-i18next'
import CustomerExistingPoliciesContainer from './CustomerExistingPoliciesContainer'
import CustomerSalesPotentialContainer from './CustomerSalesPotentialContainer'
import './CustomerMainContainer.scss'

const CustomerMainContainer = () => {
  const { t } = useTranslation('customer')
  const [currentTableType, setCurrentTableType] = useState(customerTableType.policies)
  const navbarItems = [
    {
      type: customerTableType.policies,
      text: t(`navbar.${customerTableType.policies}`),
    },
    {
      type: customerTableType.salesPotential,
      text: t(`navbar.${customerTableType.salesPotential}`),
    },
  ]

  const table = {
    [customerTableType.policies]: <CustomerExistingPoliciesContainer />,
    [customerTableType.salesPotential]: <CustomerSalesPotentialContainer />,
  }
  return (
    <div className='customer-main-container'>
      <CustomerTablesNavbar
        currentTableType={currentTableType}
        setCurrentTable={({ type }) => setCurrentTableType(type)}
        navbarItems={navbarItems}
      />
      {table[currentTableType]}
    </div>
  )
}

export default CustomerMainContainer
