import { analyticsIncomesListViewType } from '../../../../types/analyticsIncomesTypes'
import { InsertChart, TableChart } from '@mui/icons-material'
import classNames from 'classnames'
import AgencyHouseAnalyticsCustomersTable from '../AgencyHouseAnalyticsCustomersTable/AgencyHouseAnalyticsCustomersTable'
import AgencyHouseAnalyticsCustomersChartsContainer from '../AgencyHouseAnalyticsCustomersChartsContainer/AgencyHouseAnalyticsCustomersChartsContainer'
import './AgencyHouseAnalyticsCustomersListContainer.scss'

const AgencyHouseAnalyticsCustomersListContainer = ({
  list,
  listLoading,
  listType,
  listViewType,
  setListViewType,
  periodViewType,
  dataType,
  totals,
  selectItem,
  selectedItem,
}) => {
  const ListComponent =
    listViewType === analyticsIncomesListViewType.table
      ? AgencyHouseAnalyticsCustomersTable
      : AgencyHouseAnalyticsCustomersChartsContainer

  return (
    <div className='agency-house-analytics-customers-list-container'>
      <div className='agency-house-analytics-customers-list-view-selector'>
        <button
          onClick={() => setListViewType(analyticsIncomesListViewType.chart)}
          className={classNames({ selected: listViewType === analyticsIncomesListViewType.chart })}
        >
          <InsertChart />
        </button>
        <button
          onClick={() => setListViewType(analyticsIncomesListViewType.table)}
          className={classNames({ selected: listViewType === analyticsIncomesListViewType.table })}
        >
          <TableChart />
        </button>
      </div>
      <ListComponent
        listType={listType}
        periodViewType={periodViewType}
        list={list}
        listLoading={listLoading}
        dataType={dataType}
        totals={totals}
        selectItem={selectItem}
        selectedItem={selectedItem}
      />
    </div>
  )
}

export default AgencyHouseAnalyticsCustomersListContainer
