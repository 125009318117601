import './ClearButton.css'

const ClearButton = ({ onCloseClick, small = false }) => {
  const clickHandler = e => {
    e.stopPropagation()
    onCloseClick()
  }

  return (
    <div className='clear-btn' onClick={clickHandler}>
      <img src='./assets/clear-icon-sm/Square.png' alt='close' className='img-icon' />
    </div>
  )
}

export default ClearButton
