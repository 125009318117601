const AirportIconSvg = ({ fill = 'var(--text-primary-color)' }) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' width='24' height='24'>
    <g id='local-airport'>
      <path
        id='Vector'
        fill={fill}
        d='M 22 16 V 14 L 13.5 9 V 3.5 C 13.5 2.67 12.83 2 12 2 C 11.17 2 10.5 2.67 10.5 3.5 V 9 L 2 14 V 16 L 10.5 13.5 V 19 L 8 20.5 V 22 L 12 21 L 16 22 V 20.5 L 13.5 19 V 13.5 L 22 16 Z'
      />
    </g>
  </svg>
)

export default AirportIconSvg
