import React from 'react'

const MicrosoftExcelIconSvg = ({ fill = 'var(--text-primary-color)' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'>
      <path
        d='M15.8775 2.4375C16.0425 2.4375 16.19 2.5 16.32 2.625C16.44 2.745 16.5 2.89 16.5 3.06V14.94C16.5 15.11 16.44 15.255 16.32 15.375C16.19 15.5 16.0425 15.5625 15.8775 15.5625H5.8725C5.7075 15.5625 5.56 15.5 5.43 15.375C5.31 15.255 5.25 15.11 5.25 14.94V12.75H2.1225C1.9575 12.75 1.81 12.69 1.68 12.57C1.56 12.44 1.5 12.2925 1.5 12.1275V5.8725C1.5 5.7075 1.56 5.56 1.68 5.43C1.81 5.31 1.9575 5.25 2.1225 5.25H5.25V3.06C5.25 2.89 5.31 2.745 5.43 2.625C5.56 2.5 5.7075 2.4375 5.8725 2.4375M5.25 9.795L6.135 11.46H7.4775L6 9.045L7.4475 6.6675H6.165L5.3475 8.175L5.3175 8.22L5.295 8.2725C5.165 8.0075 5.025 7.74 4.875 7.47C4.75 7.205 4.6175 6.9375 4.4775 6.6675H3.12L4.5375 9.06L3 11.46H4.335M10.41 14.625V12.75H6.1875V14.625M10.41 11.8125V9.4725H9V11.8125M10.41 8.535V6.1875H9V8.535M10.41 5.25V3.375H6.1875V5.25M15.5625 14.625V12.75H11.3475V14.625M15.5625 11.8125V9.4725H11.3475V11.8125M15.5625 8.535V6.1875H11.3475V8.535M15.5625 5.25V3.375H11.3475V5.25H15.5625Z'
        fill={fill}
      />
    </svg>
  )
}

export default MicrosoftExcelIconSvg
