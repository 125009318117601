import { useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import Button from '../Common/Button/Button'
import ModalWindow from '../ModalWindow/ModalWindow'
import PaymentContainer from '../PaymentContainer/PaymentContainer'
import PaymentPlans from '../PaymentPlans/PaymentPlans'
import './PaymentPlansModal.scss'

const PaymentPlansModal = ({
  closeModal,
  onPaymentSuccess,
  updateSubscription = false,
  disableCloseOnSuccess = false,
}) => {
  const [selectedPlan, setSelectedPlan] = useState(null)
  const [success, setSuccess] = useState(false)
  const { t } = useTranslation('paymentPlans')

  const onPaymentSuccessHandler = () => {
    setSuccess(true)
  }

  return createPortal(
    <>
      <ModalWindow
        closeOnOutsideClick={false}
        closeModal={closeModal}
        showCloseIcon={!success || (success && !disableCloseOnSuccess)}
      >
        <div className='payment-plans-modal'>
          <div className='payment-plans-modal-icon'>
            <img src='./assets/cards-pack/cards-pack.png' alt='ico' />
          </div>
          {!!!selectedPlan ? (
            <>
              <div className='payment-plans-modal-header'>{t('modalHeader')}</div>
              <PaymentPlans selectPlan={setSelectedPlan} selectedPlan={selectedPlan} />
            </>
          ) : (
            <div className='payment-plans-modal-paymentframe-container'>
              <div className='payment-plans-modal-paymentframe'>
                <div className='payment-plans-modal-paymentframe-content'>
                  <PaymentContainer
                    onPaymentSuccess={onPaymentSuccessHandler}
                    subscription={selectedPlan}
                    updateSubscription={updateSubscription}
                    successSubmitHandler={onPaymentSuccess}
                  />
                </div>
              </div>
              <div className='payment-plans-modal-paymentframe-footer'>
                {!success && <Button caption={t('chooseAnotherPlanBtn')} onClick={() => setSelectedPlan(null)} />}
              </div>
            </div>
          )}
        </div>
      </ModalWindow>
    </>,
    document.body
  )
}

export default PaymentPlansModal
