import classNames from 'classnames'
import { useState } from 'react'
import useClickOutside from '../../../hooks/useClickOutside'
import { hebrew } from '../../../i18n'
import CheckMark from '../Marks/CheckMark/CheckMark'
import './MonthCalendar.scss'

const getMonthStart = date => new Date(date.getFullYear(), date.getMonth())
const getMonthEnd = date => new Date(date.getFullYear(), date.getMonth() + 1, 0)

const MonthCalendar = ({
  date,
  selectDate,
  selectedDate,
  endDate,
  startDate,
  from,
  to,
  fromCalendar = false,
  selectFromTo,
}) => {
  const [currentDate, setCurrentDate] = useState(new Date(date))
  const [showMonthsList, setShowMonthsList] = useState(false)
  const monthStart = getMonthStart(currentDate ?? new Date(date))
  const monthEnd = getMonthEnd(currentDate ?? new Date(date))
  const dateEnabled = ({ date }) => date >= monthStart && date <= monthEnd
  const locale = hebrew() ? 'he-IL' : 'en-US'

  const monthListRef = useClickOutside(() => {
    setShowMonthsList(false)
  })

  const buildCalendar = () => {
    const mStart = getMonthStart(monthStart)
    const currMonth = currentDate.getMonth()
    let week = 1
    let monthDate
    let day
    let month
    let year
    const weeks = {}
    while (mStart.getMonth() === currMonth || (mStart.getMonth() !== currMonth && day !== 6)) {
      monthDate = mStart.getDate()
      day = mStart.getDay()
      month = mStart.getMonth()
      year = mStart.getFullYear()
      const dayInfo = {
        date: new Date(mStart),
        monthDate,
        day,
        month,
        year,
      }
      weeks[week] ? weeks[week].push(dayInfo) : (weeks[week] = [dayInfo])
      day === 6 && week++
      mStart.setDate(mStart.getDate() + 1)
    }
    mStart.setMonth(mStart.getMonth() - 1)
    mStart.setDate(1)
    while (!weeks[1].map(({ day }) => day).includes(0)) {
      mStart.setDate(mStart.getDate() - 1)
      weeks[1].unshift({
        date: new Date(mStart),
        monthDate: mStart.getDate(),
        day: mStart.getDay(),
        month: mStart.getMonth(),
        year: mStart.getFullYear(),
      })
    }
    return weeks
  }

  const monthUp = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1))
  }

  const monthDown = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1))
  }

  const dateSelected = ({ date }) =>
    (from && from.getTime() === date.getTime() && dateEnabled({ date: from })) ||
    (to && to.getTime() === date.getTime() && dateEnabled({ date: to }))

  const selectDateHandler = (e, date) => {
    const doubleClick = e.detail === 2
    doubleClick ? selectFromTo(date) : selectDate(date)
  }

  const getMonthsList = () => {
    const date = getMonthStart(startDate)
    const list = []
    while (date < endDate) {
      list.push(new Date(date))
      date.setMonth(date.getMonth() + 1)
    }
    return fromCalendar ? list : list.reverse()
  }

  return (
    <div className='month-calendar-container'>
      <div className='month-calendar-controls'>
        <div className='month-calendar-date' ref={monthListRef} onClick={e => e.stopPropagation()}>
          <button onClick={() => setShowMonthsList(!showMonthsList)}>
            <div>{currentDate.toLocaleDateString(locale, { month: 'long' })}</div>
            <div>{currentDate.getFullYear()}</div>
            <div className={classNames('month-calendar-date-btn-icon', { opened: showMonthsList })}>
              <img src='./assets/arrow-dropdown-down/arrow_drop_down_24px@2x.png' alt='open' />
            </div>
          </button>
          {showMonthsList && (
            <div className='month-calendar-date-months-list'>
              {getMonthsList().map(m => (
                <div
                  key={m.toString() + m.toString()}
                  className='month-calendar-date-months-list-item'
                  onClick={() => {
                    setCurrentDate(new Date(m.getFullYear(), m.getMonth()))
                    setShowMonthsList(false)
                  }}
                >
                  <div>
                    {m.toLocaleDateString(locale, { month: 'long' })} {m.getFullYear()}
                  </div>

                  {m.getMonth() === currentDate.getMonth() && m.getFullYear() === currentDate.getFullYear() && (
                    <CheckMark green />
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className='month-calendar-set-month'>
          <button
            onClick={monthDown}
            disabled={getMonthEnd(currentDate).getTime() === getMonthEnd(startDate).getTime()}
          >
            <i className='fas fa-chevron-right '></i>
          </button>
          <button onClick={monthUp} disabled={getMonthEnd(currentDate).getTime() === getMonthEnd(endDate).getTime()}>
            <i className='fas fa-chevron-left '></i>
          </button>
        </div>
      </div>
      <div className='week-row day-names'>
        {buildCalendar()[1].map(({ date }) => (
          <div key={date} className='day-cell'>
            {date.toLocaleDateString(locale, { weekday: 'narrow' })[0]}
          </div>
        ))}
      </div>
      <div className='month-dates'>
        <div className='week-row'>
          {Object.values(buildCalendar())
            .flatMap(days => days)
            .map(d => (
              <div
                key={d.date}
                className={classNames('day-cell', {
                  selected: dateSelected(d),
                  from: from && d.date.getTime() === from.getTime(),
                  to: to && d.date.getTime() === to.getTime(),
                  disabled: !dateEnabled(d) || !(d.date <= endDate && d.date >= startDate),
                  'in-range': from && to && d.date <= to && d.date >= from,
                  today:
                    dateEnabled(d) &&
                    d.date.getFullYear() === new Date().getFullYear() &&
                    d.date.getMonth() === new Date().getMonth() &&
                    d.date.getDate() === new Date().getDate(),
                })}
                {...(dateEnabled(d) &&
                  d.date <= endDate &&
                  d.date >= startDate && {
                    onClick: e => selectDateHandler(e, d.date),
                  })}
              >
                {d.date.getFullYear() === currentDate.getFullYear() &&
                  d.date.getMonth() === currentDate.getMonth() &&
                  d.monthDate}
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default MonthCalendar
