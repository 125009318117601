import { useTranslation } from 'react-i18next'
import ModalWindow from '../../../../components/ModalWindow/ModalWindow'
import './PolicyUpdateCommissionRateModal.scss'
import Button from '../../../../components/Common/Button/Button'
import { formatAsPercent } from '../../../../utils/formatAs'
import { useEffect, useState } from 'react'
import { floatingNumbersOnly } from '../../../../services/inputs'
import { updatePolicyCommissionRateRequest } from '../../../../http/requests/policyRequests'
import { getPolicyTypeRequest, updateAgencyPolicyTypeRequest } from '../../../../http/requests/policiyMappingRequests'
import useApiRequest from '../../../../hooks/useApiRequest'

const charToIgnore = '%'

const PolicyUpdateCommissionRateModal = ({ closeModal, policyId, policyTypeId }) => {
  const [updatedCommissionRate, setUpdatedCommissionRate] = useState('')
  const [formIsDirty, setFormIsDirty] = useState(false)
  const [updateLoading, setUpdateLoading] = useState(false)
  const { t } = useTranslation('policies')
  const validationCommissionRate = +updatedCommissionRate.replace(charToIgnore, '')
  const { data: policyType, loading: policyTypeLoading } = useApiRequest(getPolicyTypeRequest, {
    requestParams: policyTypeId,
    runOnInit: true,
  })

  const onChangeHandler = ({ target: { value } }) => {
    const rawValue = value.replace(charToIgnore, '')
    if (rawValue === '' || floatingNumbersOnly(rawValue)) {
      setFormIsDirty(true)
      setUpdatedCommissionRate(rawValue + charToIgnore)
    }
  }

  const updatePolicyRateHandler = async updatedAllRelated => {
    try {
      setUpdateLoading(true)
      let result
      if (updatedAllRelated) {
        const {
          data: { data },
        } = await updateAgencyPolicyTypeRequest({ ...policyType, validationCommissionRate })
        result = data
      } else {
        const {
          data: {
            data: { success },
          },
        } = await updatePolicyCommissionRateRequest({ policyId, policyTypeId, validationCommissionRate })
        result = success
      }
      if (result) {
        closeModal()
      }
      setUpdateLoading(false)
    } catch (error) {
      setUpdateLoading(false)
    }
  }

  const buttonsDisabled =
    !policyType ||
    !formIsDirty ||
    +validationCommissionRate === +policyType?.expectedCommissionRate ||
    updateLoading ||
    policyTypeLoading

  useEffect(() => {
    setUpdatedCommissionRate(formatAsPercent(policyType?.expectedCommissionRate))
  }, [policyType])

  return (
    <ModalWindow closeModal={closeModal} renderPortal>
      <div className='policy-update-commission-rate-modal'>
        <h5>{t('updateCommissionRateModal.title')}</h5>
        <p>{t('updateCommissionRateModal.text')}</p>
        <div>
          <div>
            <label htmlFor='current-rate'>{t('updateCommissionRateModal.current')}</label>
            <input type='text' id='current-rate' disabled value={formatAsPercent(policyType?.expectedCommissionRate)} />
          </div>
          <div className='direction-icon'>{'->'}</div>
          <div>
            <label htmlFor='expected-rate'>{t('updateCommissionRateModal.expected')}</label>
            <input
              type='text'
              id='expected-rate'
              value={updatedCommissionRate}
              onChange={onChangeHandler}
              disabled={policyTypeLoading || updateLoading}
            />
          </div>
        </div>
        <footer>
          <Button
            caption={t('updateCommissionRateModal.checkOnAll')}
            disabled={buttonsDisabled}
            onClick={() => updatePolicyRateHandler(true)}
          />
          <Button
            caption={t('updateCommissionRateModal.checkOnThis')}
            disabled={buttonsDisabled}
            onClick={() => updatePolicyRateHandler(false)}
          />
        </footer>
      </div>
    </ModalWindow>
  )
}

export default PolicyUpdateCommissionRateModal
