import { useState } from 'react'
import './AssociateAgencySummary.scss'
import AddAgencySummaryContainer from '../AddAgencySummaryContainer/AddAgencySummaryContainer'
import { useTranslation } from 'react-i18next'
import { hebrew } from '../../../../i18n'
import { agencyPaymentType } from '../../../../types/agencyTypes'
import { userPreference } from '../../../../types/agencyUserTypes'
import AddAgencySummaryItem from '../AddAgencySummaryItem/AddAgencySummaryItem'
import { BusinessTwoTone, CardTravel, DeviceHubTwoTone } from '@mui/icons-material'
import CashPlusIconSvg from '../../../../components/Common/SvgIcons/CashPlusIconSvg/CashPlusIconSvg'
import ButtonText from '../../../../components/Common/ButtonText/ButtonText'

const DEFAULT_ITEMS_TO_SHOW = 5

const AssociateAgencySummary = ({ addOneMore, finish, associatedAgency }) => {
  const { t } = useTranslation('agencyHouse')
  const tAssociateAgencyForm = key => `${t('addAgencyStep.associateAgencyForm.' + key)}`
  const tAssociateAgencySummary = (key, params) => `${t('addAgencyStep.associateAgencySummary.' + key, { ...params })}`
  const tNewAgencySummary = (key, params) => `${t('addAgencyStep.addAgencySummary.' + key, { ...params })}`

  const [companiesExpanded, setCompaniesExpanded] = useState(false)
  const [prefsExpanded, setPrefsExpanded] = useState(false)

  return (
    <AddAgencySummaryContainer addOneMore={addOneMore} finish={finish}>
      <AddAgencySummaryItem
        title={tAssociateAgencySummary('personalInformation')}
        icon={<CardTravel style={{ fontSize: '40px' }} />}
      >
        <div>
          <p>
            {tAssociateAgencySummary('phone')}: {associatedAgency.phone}
          </p>
          <p>
            {tAssociateAgencySummary('email')}: {associatedAgency.email}
          </p>
        </div>
      </AddAgencySummaryItem>
      <AddAgencySummaryItem title={tAssociateAgencySummary('payment')} icon={<CashPlusIconSvg />}>
        <p>
          {tAssociateAgencyForm(
            `paymentBy${associatedAgency.paymentType === agencyPaymentType.byAgency ? 'Agency' : 'Agent'}`
          )}
        </p>
      </AddAgencySummaryItem>
      {associatedAgency.prefs.length > 0 && (
        <AddAgencySummaryItem
          title={tAssociateAgencySummary('accessRequest')}
          icon={<DeviceHubTwoTone style={{ fontSize: '40px' }} />}
          styles={prefsExpanded && { height: 'fit-content' }}
        >
          <div className='add-agency-summary-item-card-content-pairs pairs-grid'>
            {associatedAgency.prefs
              .slice(0, prefsExpanded ? associatedAgency.prefs.length : DEFAULT_ITEMS_TO_SHOW)

              .map(pr => (
                <div key={pr.id}>
                  <p>{hebrew() ? userPreference[pr.id].name : userPreference[pr.id].nameEn}</p>
                </div>
              ))}
            {associatedAgency.prefs.length > 5 && (
              <ButtonText onClick={() => setPrefsExpanded(!prefsExpanded)}>
                {tNewAgencySummary(`show${prefsExpanded ? 'Less' : 'More'}Btn`)}
              </ButtonText>
            )}
          </div>
        </AddAgencySummaryItem>
      )}
      {associatedAgency.companies.length > 0 && (
        <AddAgencySummaryItem
          title={tAssociateAgencySummary('companies', { count: associatedAgency.companies.length })}
          icon={<BusinessTwoTone style={{ fontSize: '40px' }} />}
          styles={companiesExpanded && { height: 'fit-content' }}
        >
          <div className='add-agency-summary-item-card-content-pairs pairs-grid'>
            {associatedAgency.companies
              .slice(0, companiesExpanded ? associatedAgency.companies.length : DEFAULT_ITEMS_TO_SHOW)
              .map(c => (
                <div key={c.companyId ?? c.id}>
                  <img src={`./assets/companies-logos-light/${c.companyId ?? c.id}.png`} alt={c.name} />
                  <p>{hebrew() ? c.name : c.nameEn}</p>
                </div>
              ))}
            {associatedAgency.companies.length > 5 && (
              <ButtonText onClick={() => setCompaniesExpanded(!companiesExpanded)}>
                {tNewAgencySummary(`show${prefsExpanded ? 'Less' : 'More'}Btn`)}
              </ButtonText>
            )}
          </div>
        </AddAgencySummaryItem>
      )}
    </AddAgencySummaryContainer>
  )
}

export default AssociateAgencySummary
