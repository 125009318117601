import { COOKIES_MAX_AGE } from '../constants/cookies'
import { useCrossDomainCookies } from '../environment/urls'
import { agencyType } from '../types/agencyTypes'
import { buildUserPreferences } from '../types/agencyUserTypes'
import { setItemToLocalStorage } from './localStorageService'

export const saveLoginInfo = (data, cookiesAge = COOKIES_MAX_AGE) => {
  const {
    Id: userId,
    FullName: UserName,
    AppToken,
    Role: role,
    Preferences,
    AgencyType = agencyType.regular,
    AgreementConfirmed,
  } = data

  const preferences = buildUserPreferences(Preferences)

  setItemToLocalStorage('AccessToken', AppToken.AccessToken)
  setItemToLocalStorage('RefreshToken', AppToken.RefreshToken)
  setItemToLocalStorage('RefreshTokenExpireAt', AppToken.RefreshTokenExpireAt)
  setItemToLocalStorage('UserId', AppToken.UserId)
  setItemToLocalStorage('id', AppToken.id)
  setItemToLocalStorage('role', role)
  setItemToLocalStorage('preferences', preferences.join(';'))
  setItemToLocalStorage('UserName', UserName)
  setItemToLocalStorage('AgencyType', AgencyType)
  setItemToLocalStorage('AgreementConfirmed', AgreementConfirmed)

  document.cookie = `UserId=${userId}; Max-Age=${cookiesAge}${useCrossDomainCookies}`
  document.cookie = `role=${role}; Max-Age=${cookiesAge}${useCrossDomainCookies}`
}
