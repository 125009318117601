import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { WORKSTATION_FILTERS_CLEAR } from '../../../../types/actionTypes'
import './WorkstationFiltersClear.scss'

const WorkstationFiltersClear = () => {
  const { filters, from, to } = useSelector(state => state.workstation)
  const dispatch = useDispatch()
  const { t } = useTranslation('workstation')
  const appliedFiltersLength = Object.values(filters)
    .flatMap(({ values }) => values)
    .filter(v => v.checked).length

  return (
    <div className='workstation-filter-clear'>
      <button
        disabled={appliedFiltersLength === 0 && !from && !to}
        onClick={() => dispatch({ type: WORKSTATION_FILTERS_CLEAR })}
      >
        {t('clearFilters')}
      </button>
    </div>
  )
}

export default WorkstationFiltersClear
