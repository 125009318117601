export const lobbyTotalsInitial = {
  AverageCommission: { Value: 0, Trend: false, Difference: 0 },
  AverageAgencyCommission: { Value: 0, Trend: false, Difference: 0 },
  AverageTotalCommission: { Value: 0, Trend: false, Difference: 0 },
  CommissionAverageForLastThreeMonth: [],
  AgencyCommissionAverageForLastThreeMonth: [],
  TotalCommissionAverageForLastThreeMonth: [],
  MonthlyAccumulation: { Value: 0, Trend: false, Difference: 0 },
  MonthlyCommission: { Value: 0, Trend: false, Difference: 0 },
  MonthlyAgencyCommission: { Value: 0, Trend: false, Difference: 0 },
  MonthlyTotalCommission: { Value: 0, Trend: false, Difference: 0 },
  MonthlyPremium: { Value: 0, Trend: false, Difference: 0 },
  NewCommission: { Value: 0, Trend: false, Difference: 0 },
  NewAgencyCommission: { Value: 0, Trend: false, Difference: 0 },
  NewTotalCommission: { Value: 0, Trend: false, Difference: 0 },
  NewPoliciesCount: 0,
  NewPoliciesFromExistingClientsCount: 0,
  NewPoliciesFromNewClientsCount: 0,
  MissingOrNoPremiumIrregularitiesCount: 0,
  MissingOrNoPremiumIrregularitiesValue: 0,
  NegativePaymentIrregularitiesCount: 0,
  NegativePaymentIrregularitiesValue: 0,
  CommissionRateIrregularitiesCount: 0,
  CommissionRateIrregularitiesValue: 0,
  PoliciesThatEndsNextMonthCount: 0,
  PoliciesThatEndsNextMonthValue: 0,
}
