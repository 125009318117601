import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import './AgentGroupsBanner.scss'

const groupsToShow = 3

const AgentGroupsBanner = () => {
  const { selectedGroupsIds, agentGroups } = useSelector(({ shared }) => shared)
  const groups = agentGroups.filter(gr => selectedGroupsIds.includes(gr.id))
  const { t } = useTranslation('common')
  if (groups.length === 0) return null
  let displayedNames = groups.slice(0, groupsToShow).map(gr => gr.name)
  if (groups.length > groupsToShow)
    displayedNames.push(
      t('agentGroups.remainingGroupsPlaceholder', { groupsCount: groups.length - displayedNames.length })
    )
  return (
    <div className='agent-groups-banner'>
      <div className='agent-groups-banner-ico'>
        <img src='./assets/info-icon-md/Info.png' alt='!' />
      </div>
      <div className='agent-groups-banner-text'>
        {t('agentGroups.selectedGroupsPlaceholder')}
        <span>{displayedNames.join(', ')}</span>
      </div>
    </div>
  )
}

export default AgentGroupsBanner
