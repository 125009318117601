import { createPortal } from 'react-dom'
import { rtlDir } from '../../../i18n'
import { useLayoutEffect, useState } from 'react'
import './RelativeFixedPosition.scss'
import { useTranslation } from 'react-i18next'

const RelativeFixedPosition = ({
  children,
  usePortal = true,
  useOverlay = true,
  anchorRef,
  topOffset = 8,
  onOverlayClick,
}) => {
  const [styles, setStyles] = useState({})
  const {
    i18n: { language },
  } = useTranslation()

  useLayoutEffect(() => {
    const getParentPostition = () => {
      const { bottom, left, right } = anchorRef?.current?.getBoundingClientRect()
      return { left, right, y: bottom }
    }
    const updateStyles = () =>
      setStyles(
        anchorRef?.current
          ? {
              top: getParentPostition().y + topOffset + 'px',
              ...(rtlDir()
                ? { left: getParentPostition().left + 'px' }
                : { left: getParentPostition().right + 'px', transform: 'translateX(-100%)' }),
            }
          : {}
      )
    updateStyles()
    window.addEventListener('resize', updateStyles)
    window.addEventListener('scroll', updateStyles)
    // Clean up event listener on unmount
    return () => {
      window.removeEventListener('resize', updateStyles)
      window.addEventListener('scroll', updateStyles)
    }
  }, [anchorRef, topOffset, language])

  const baseContent = (
    <div
      className='relative-fixed-container'
      style={styles}
      {...(onOverlayClick && {
        onClick: e => {
          e.stopPropagation()
        },
      })}
    >
      {children}
    </div>
  )

  const content =
    useOverlay || onOverlayClick ? (
      <div
        className='relative-fixed-overlay'
        {...(onOverlayClick && {
          onClick: e => {
            onOverlayClick()
          },
        })}
      >
        {baseContent}
      </div>
    ) : (
      baseContent
    )
  return usePortal ? createPortal(content, document.body) : content
}

export default RelativeFixedPosition
