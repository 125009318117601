import {
  addAgencyHouseAgenciesGroupRequest,
  setAgencyHouseFavoriteGroupRequest,
  deleteAgencyHouseGroupRequest,
  getAgencyHouseAllAgenciesRequest,
  getAgencyHouseGroupsRequest,
  updateAgencyHouseAgenciesGroupRequest,
} from '../../../http/requests/agencyHouseRequests'
import { getRandomHexColor, getRandomInt } from '../../../services/getRandom'
import {
  AGENT_HOUSE_ADD_GROUP_FAIL,
  AGENT_HOUSE_ADD_GROUP_REQUEST,
  AGENT_HOUSE_ADD_GROUP_SUCCESS,
  AGENT_HOUSE_AGENCIES_FAIL,
  AGENT_HOUSE_AGENCIES_REQUEST,
  AGENT_HOUSE_AGENCIES_SUCCESS,
  AGENT_HOUSE_DELETE_GROUP_FAIL,
  AGENT_HOUSE_DELETE_GROUP_REQUEST,
  AGENT_HOUSE_DELETE_GROUP_SUCCESS,
  AGENT_HOUSE_GROUPS_FAIL,
  AGENT_HOUSE_GROUPS_REQUEST,
  AGENT_HOUSE_GROUPS_SUCCESS,
  AGENT_HOUSE_UPDATE_FAVORITE_GROUP_SUCCESS,
  AGENT_HOUSE_UPDATE_GROUP_FAIL,
  AGENT_HOUSE_UPDATE_GROUP_REQUEST,
  AGENT_HOUSE_UPDATE_GROUP_SUCCESS,
} from '../../../types/actionTypes'

export const getAgentHouseGroups = () => async dispatch => {
  dispatch({ type: AGENT_HOUSE_GROUPS_REQUEST })
  try {
    const {
      data: { Data: groups },
    } = await getAgencyHouseGroupsRequest()
    const favoriteGroups = groups.FavoriteGroups.map(
      ({ Agencies: agencies, Id: id, IsAgency: isAgency, IsFavourite: isFavourite, Name: name }) => ({
        id,
        isAgency,
        isFavourite,
        name,
        agencies: agencies.map(a => ({ id: a.Key, name: a.Value, backgroundColor: getRandomHexColor() })),
      })
    )
    const allGroups = groups.AllGroups.map(
      ({ Agencies: agencies, Id: id, IsAgency: isAgency, IsFavourite: isFavourite, Name: name }) => ({
        id,
        isAgency,
        isFavourite,
        name,
        agencies: agencies.map(a => ({ id: a.Key, name: a.Value, backgroundColor: getRandomHexColor() })),
      })
    )
    dispatch({ type: AGENT_HOUSE_GROUPS_SUCCESS, payload: { favoriteGroups, allGroups } })
  } catch (error) {
    dispatch({
      type: AGENT_HOUSE_GROUPS_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const getAgentHouseAllAgencies = () => async dispatch => {
  dispatch({ type: AGENT_HOUSE_AGENCIES_REQUEST })
  try {
    const {
      data: { Data },
    } = await getAgencyHouseAllAgenciesRequest()
    const agencies = Data.map(({ Key, Value }) => ({ id: Key, name: Value, colorIndex: getRandomInt(1, 3) - 1 }))
    dispatch({ type: AGENT_HOUSE_AGENCIES_SUCCESS, payload: agencies })
  } catch (error) {
    dispatch({
      type: AGENT_HOUSE_AGENCIES_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const addAgenciesGroup =
  ({ name, agencies }) =>
  async dispatch => {
    dispatch({ type: AGENT_HOUSE_ADD_GROUP_REQUEST })
    try {
      let success = false
      let group = null
      const agenciesIds = agencies.map(a => a.id.toString())
      const {
        data: { Data: groupId },
      } = await addAgencyHouseAgenciesGroupRequest({ name, agencies: agenciesIds })
      if (groupId) {
        success = true
        group = {
          id: groupId,
          name,
          agencies: agencies.map(a => ({ ...a, backgroundColor: getRandomHexColor() })),
          colorIndex: getRandomInt(1, 3) - 1,
        }
      }
      dispatch({
        type: AGENT_HOUSE_ADD_GROUP_SUCCESS,
        payload: { success, group },
      })
    } catch (error) {
      dispatch({
        type: AGENT_HOUSE_ADD_GROUP_FAIL,
        payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      })
    }
  }

export const updateAgenciesGroup = group => async dispatch => {
  dispatch({ type: AGENT_HOUSE_UPDATE_GROUP_REQUEST, payload: group.id })
  try {
    const {
      data: { Data: success },
    } = await updateAgencyHouseAgenciesGroupRequest({ ...group, agencies: group.agencies.map(a => a.id.toString()) })
    dispatch({
      type: AGENT_HOUSE_UPDATE_GROUP_SUCCESS,
      payload: { success, group },
    })
  } catch (error) {
    dispatch({
      type: AGENT_HOUSE_UPDATE_GROUP_FAIL,
      payload: {
        error: error.response && error.response.data.message ? error.response.data.message : error.message,
        id: group.id,
      },
    })
  }
}

export const setAgenciesFavoriteGroup = group => async dispatch => {
  dispatch({ type: AGENT_HOUSE_UPDATE_GROUP_REQUEST, payload: group.id })
  try {
    const {
      data: { Data: success },
    } = await setAgencyHouseFavoriteGroupRequest(group.id)
    dispatch({
      type: AGENT_HOUSE_UPDATE_FAVORITE_GROUP_SUCCESS,
      payload: { success, group },
    })
  } catch (error) {
    dispatch({
      type: AGENT_HOUSE_UPDATE_GROUP_FAIL,
      payload: {
        error: error.response && error.response.data.message ? error.response.data.message : error.message,
        id: group.id,
      },
    })
  }
}

export const deleteAgencyGroup = id => async dispatch => {
  dispatch({ type: AGENT_HOUSE_DELETE_GROUP_REQUEST, payload: id })
  try {
    const {
      data: { Data },
    } = await deleteAgencyHouseGroupRequest(id)
    dispatch({
      type: AGENT_HOUSE_DELETE_GROUP_SUCCESS,
      payload: { success: !!Data, id },
    })
  } catch (error) {
    dispatch({
      type: AGENT_HOUSE_DELETE_GROUP_FAIL,
      payload: {
        error: error.response && error.response.data.message ? error.response.data.message : error.message,
      },
    })
  }
}
