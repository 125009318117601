import { useTranslation } from 'react-i18next'
import useApiRequest from '../../../../hooks/useApiRequest'
import {
  getProfileMappingSettingsRequest,
  toggleProfileMappingSettingsRequest,
} from '../../../../http/requests/profileRequests'
import ProfileSettingSwitch from '../ProfileSettingSwitch/ProfileSettingSwitch'
import './ProfileMapSettings.scss'

const ProfileMapSettings = () => {
  const { t } = useTranslation('profile')
  const {
    data: mapSettingsEnabled,
    fetch: getMapSettings,
    loading,
  } = useApiRequest(getProfileMappingSettingsRequest, { runOnInit: true })
  const { fetch: toggleMapSettings, loading: toggleMapSettingsLoading } = useApiRequest(
    toggleProfileMappingSettingsRequest,
    { loadingOnInit: false }
  )

  const toggleMapSettingHandler = async () => {
    await toggleMapSettings()
    await getMapSettings()
  }

  return (
    <div className='profile-map-settings'>
      <ProfileSettingSwitch
        title={t('mapSettings.title')}
        desc={t('mapSettings.desc')}
        tooltip={t('mapSettings.tooltip')}
        disabled={loading || toggleMapSettingsLoading}
        checked={!!mapSettingsEnabled}
        toggleSwitch={toggleMapSettingHandler}
      />
    </div>
  )
}

export default ProfileMapSettings
