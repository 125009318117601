import { Done, ErrorTwoTone, HelpOutline } from '@mui/icons-material'

export const agencyType = {
  regular: 0,
  big: 1,
  association: 2,
  delta: 3,
}

export const agencyExistType = {
  new: 1,
  existing: 2,
}

export const agencyPaymentType = {
  byAgency: 1,
  byAgent: 2,
}

export const agencyPayment = {
  [agencyPaymentType.byAgency]: {
    id: agencyPaymentType.byAgency,
    name: 'תשלום ע”י הסוכנות',
    nameEn: 'payment by the agency',
  },
  [agencyPaymentType.byAgent]: {
    id: agencyPaymentType.byAgent,
    name: 'תשלום ע”י הסוכן',
    nameEn: 'payment by the agent',
  },
}

export const agencyApprovalStatusType = {
  approved: 0,
  waitingForApproval: 1,
  denied: 2,
}

export const agencyApprovalStatus = {
  [agencyApprovalStatusType.approved]: {
    id: agencyApprovalStatusType.approved,
    name: 'אושרה',
    nameEn: 'approved',
    icon: <Done style={{ fontSize: '18px' }} />,
  },
  [agencyApprovalStatusType.waitingForApproval]: {
    id: agencyApprovalStatusType.waitingForApproval,
    name: 'בהמתנה',
    nameEn: 'approve waiting',
    icon: <HelpOutline style={{ fontSize: '18px' }} />,
  },
  [agencyApprovalStatusType.denied]: {
    id: agencyApprovalStatusType.denied,
    name: 'סירוב',
    nameEn: 'denied',
    icon: <ErrorTwoTone style={{ fontSize: '18px' }} />,
  },
}
