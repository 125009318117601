import { useTranslation } from 'react-i18next'
import ModalWindow from '../../../../components/ModalWindow/ModalWindow'
import ButtonRounded from '../../../../components/Common/ButtonRounded/ButtonRounded'
import { useState } from 'react'
import { CheckBox, CheckBoxOutlineBlank, IndeterminateCheckBox } from '@mui/icons-material'
import './PolicyTypesChangesModal.scss'
import { confirmPolicyTypesChangesRequest } from '../../../../http/requests/policiyMappingRequests'
import { hebrew } from '../../../../i18n'

const PolicyTypesChangesModal = ({ changes, closeModal }) => {
  const [selectedTypes, setSelectedTypes] = useState(changes.map(ch => ch.policyTypeId))
  const [applyChangesLoading, setApplyChangesLoading] = useState(false)
  const { t } = useTranslation('common')
  const allSelected = changes.length === selectedTypes.length
  const someSelected = !allSelected && selectedTypes.length > 0

  const applyChangesHandler = async (confirm = true) => {
    const allTypes = changes.map(ch => ch.policyTypeId)
    const declinedTypes = allTypes.filter(pt => !selectedTypes.includes(pt))
    try {
      setApplyChangesLoading(true)
      const requestDto = {
        confirmedPolicyTypes: confirm ? selectedTypes : [],
        declinedPolicyTypes: confirm ? declinedTypes : allTypes,
      }
      const {
        data: { data },
      } = await confirmPolicyTypesChangesRequest(requestDto)
      setApplyChangesLoading(false)
      if (data) {
        closeModal()
      }
    } catch (error) {
      setApplyChangesLoading(false)
    }
  }

  return (
    <ModalWindow renderPortal closeModal={closeModal} closeOnOutsideClick={false}>
      <div className='policy-types-changes-modal'>
        <header>
          <h5>{t('policyTypesChangesModal.title')}</h5>
          <p>{t('policyTypesChangesModal.subtitle')}</p>
        </header>
        <div>
          <p>{t('policyTypesChangesModal.listName')}</p>

          <div className='policy-types-changes-list'>
            <div className='policy-types-changes-modal-grid list-heading'>
              <div>{t('policyTypesChangesModal.oldTypeName')}</div>
              <div>{t('policyTypesChangesModal.oldCompany')}</div>
              <div>{t('policyTypesChangesModal.oldCategory')}</div>
              <div></div>
              <div>{t('policyTypesChangesModal.newTypeName')}</div>
              <div>{t('policyTypesChangesModal.newCompany')}</div>
              <div>{t('policyTypesChangesModal.newCategory')}</div>
              <div>
                <p>{t('policyTypesChangesModal.checkAll')}</p>
                <button onClick={() => setSelectedTypes(allSelected ? [] : changes.map(ch => ch.policyTypeId))}>
                  {allSelected ? (
                    <CheckBox style={{ color: 'var(--trend-up-color)' }} />
                  ) : someSelected ? (
                    <IndeterminateCheckBox style={{ color: 'var(--trend-up-color)' }} />
                  ) : (
                    <CheckBoxOutlineBlank style={{ color: 'var(--trend-down-color)' }} />
                  )}
                </button>
              </div>
            </div>
            {changes.map((pt, i) => (
              <div key={pt.policyTypeId} className='policy-types-changes-modal-grid list-item'>
                <div>{pt.policyTypeName}</div>
                <div>{pt[`companyName${hebrew() ? '' : 'En'}`]}</div>
                <div>{pt[`agencyCategoryName${hebrew() ? '' : 'En'}`]}</div>
                <div className='direction-icon'>{'->'}</div>
                <div>{pt.policyTypeName}</div>
                <div>{pt[`companyName${hebrew() ? '' : 'En'}`]}</div>
                <div>{pt[`masterCategoryName${hebrew() ? '' : 'En'}`]}</div>
                <div>
                  <button
                    onClick={() =>
                      setSelectedTypes(prev =>
                        prev.includes(pt.policyTypeId)
                          ? prev.filter(p => p !== pt.policyTypeId)
                          : [...prev, pt.policyTypeId]
                      )
                    }
                  >
                    {selectedTypes.includes(pt.policyTypeId) ? (
                      <CheckBox style={{ color: 'var(--trend-up-color)' }} />
                    ) : (
                      <CheckBoxOutlineBlank style={{ color: 'var(--trend-down-color)' }} />
                    )}
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
        <footer>
          <ButtonRounded variant='danger' disabled={applyChangesLoading} onClick={() => applyChangesHandler(false)}>
            {t('policyTypesChangesModal.btnDecline')}
          </ButtonRounded>
          <ButtonRounded
            disabled={selectedTypes.length === 0 || applyChangesLoading}
            onClick={() => applyChangesHandler(true)}
          >
            {t('policyTypesChangesModal.btnConfirm', { count: selectedTypes.length })}
          </ButtonRounded>
        </footer>
      </div>
    </ModalWindow>
  )
}

export default PolicyTypesChangesModal
