export const registerStep = {
  twoFactor: 1,
  twoFactorSms: 2,
  registerFlowType: 3,
  agentDetails: 4,
  agentHouseDetails: 100,
  fields: 5,
  companies: 6,
  payment: 7,
}

export const registerFlowType = {
  regular: 2,
  agentHouse: 1,
  deltaAgency: 3,
}

const buildRegisterStep = step => {
  const stepName = Object.entries(registerStep)
    .filter(([_, v]) => v === step)
    .map(([k, _]) => k)
    .at(0)
  step = +step
  return {
    number: step,
    allowedFromSteps:
      step <= registerStep.agentDetails
        ? //? [registerStep.twoFactor, registerStep.agentDetails]
          []
        : Object.values(registerStep).filter(
            st =>
              st !== registerStep.agentDetails &&
              st !== registerStep.twoFactor &&
              st !== registerStep.twoFactorSms &&
              st !== registerStep.registerFlowType &&
              st !== registerStep.agentHouseDetails
          ),
    name: stepName.charAt(0).toUpperCase() + stepName.slice(1),
    icon: `./assets/reg-icons/${step}/active.png`,
    iconInactive: `./assets/reg-icons/${step}/inactive.png`,
    iconMobile: `./assets/reg-icons/${step}/mobile-active.svg`,
  }
}

const buildRegisterSteps = () =>
  Object.values(registerStep).reduce((acc, step) => {
    if (!acc[step]) acc[step] = buildRegisterStep(step)
    return acc
  }, {})

export const registerSteps = buildRegisterSteps()
