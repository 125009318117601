import { useTranslation } from 'react-i18next'
import ModalWindow from '../ModalWindow/ModalWindow'
import PaymentFrame from '../PaymentFrame/PaymentFrame'
import './PaymentFrameModal.scss'
import { Close } from '@mui/icons-material'

const PaymentFrameModal = ({ closeModal, onPaymentSuccess }) => {
  const { t } = useTranslation('common')
  return (
    <ModalWindow renderPortal showCloseIcon={false}>
      <header>
        <p>{t('setPaymentDetails')}</p>
        <button onClick={closeModal}>
          <Close />
        </button>
      </header>
      <PaymentFrame onPaymentSuccess={onPaymentSuccess} />
    </ModalWindow>
  )
}

export default PaymentFrameModal
