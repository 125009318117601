import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import './LandingDownloads.scss'
import { hebrew } from '../../../../i18n'

const LandingDownloads = () => {
  const { t } = useTranslation('landing')
  return (
    <section
      className='landing-downloads'
      id='downloads'
      style={{
        backgroundImage: `url('assets/_landing/_downloads/bg.png'), url('assets/_landing/_downloads/image${
          hebrew() ? '' : '_en'
        }.png')`,
        backgroundPosition: `center top, ${hebrew() ? '85' : '10'}% center`,
        backgroundRepeat: 'no-repeat, no-repeat',
        backgroundSize: 'cover, auto',
      }}
    >
      <div className='landing-section-container'>
        <div className='landing-downloads-showcase'>
          <div className='landing-downloads-desc'>
            <p className='landing-downloads-tagline'>{t('downloads.tagline')}</p>
            <h5 className='landing-downloads-title'>
              <Trans
                i18nKey='downloads.title' // the key in your JSON file
                ns='landing'
                values={{
                  // The variables in your i18n entry from the JSON file
                  titleBold: t('downloads.titleBold'),
                }}
                components={[<strong></strong>]} // The component at index 0 (<strong />) will be parent of <0> ({{- key}}), and so on...
              />
            </h5>
            <p className='landing-downloads-subtitle'>{t('downloads.subTitle')}</p>
            <div className='landing-btns-container'>
              <a href='#/register'>{t('downloads.link')}</a>
            </div>
            <p className='landing-downloads-desc-title'>{t('downloads.descTitle')}</p>
            <p className='landing-downloads-desc-text'>{t('downloads.descText')}</p>
            <p className='landing-downloads-desc-remark'>{t('downloads.descRemark')}</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LandingDownloads
