import $api from '..'
import { urlPolicy } from '../../environment/urls'

export const setPoliciesCategoryRequest = async ({ policiesIds, categoryId, updateAllRelatedPolicies }) =>
  $api.patch(`${urlPolicy()}category`, { policiesIds, categoryId, updateAllRelatedPolicies })

export const updatePolicyCommissionRateRequest = async ({ policyId, policyTypeId, validationCommissionRate }) =>
  $api.post(`${urlPolicy()}update-policy-commission-rate`, { policyId, policyTypeId, validationCommissionRate })

export const deactivatePolicyRequest = async policyId => await $api.post(`${urlPolicy()}deactivate/${policyId}`)

export const cancelPolicyLogRequest = async logId => await $api.get(`${urlPolicy()}cancel-action/${logId}`)

export const getPolicyCoveragesRequest = async policyId =>
  await $api.get(`${urlPolicy()}coverages-by-policy/${policyId}`)
