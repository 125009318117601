const terms = `
תנאי השימוש באתר
תאריך שינוי אחרון 21.11.2022

השימוש באתר אינטרנט זה (ai-gentins.com)ובמערכת  AI.GENTוכן כל דף אחר תחת דומיין זה (יחדיו: "האתר") של ט.ו.מ.ר. תוכנה לביטוח, שותפות מוגבלת ו/או כל חברת אם ו/או חברות בנות ו/או אחיות ו/או קשורות (להלן כולן יחד: "ט.ו.מ.ר."), לרבות השימוש במידע ו/או השימוש בקישוריות למקורות מידע אחרים באתר ו/או באמצעותו, וכן מסירת כל מידע שהוא, קבלת הצעות ו/או רכישת פוליסות ביטוח ו/או מוצרים ו/או שירותים כלשהם באמצעות אתר זה, הינו בכפוף לתנאים המפורטים להלן, ועצם השימוש באתר על ידך מהווה הסכמה מצידך לקבלתם והתחייבות לנהוג לפיהם. לפיכך, במידה ואינך מקבל תנאי כלשהו מתנאי שימוש אלה, הינך מתבקש שלא לעשות כל שימוש באתר זה. 

הסכם זה מסדיר את מערכת היחסים שבינך לבין ט.ו.מ.ר. תוכנה לביטוח שותפות מוגבלת, בעלת המערכת, או חלפיהם (להלן -  "אנחנו, בעלי המערכת ו/או AI.GENT") ביחס לגלישה, לרכישה ו/או לשימוש בשירותי המערכת בתקופת הפיילוט ולאחריה, וכן את מערכת היחסים שבין המשתמשים לבין עצמם ביחס לגלישה או לשימוש בשירותי המערכת בתקופה זו.

אזהרה חשובה! המערכת הינה בשלב הרצה בלבד, על כן צפויים כשלים, נפילות, בעיות התחברות ויציבות, חוסרי מידע, חוסר התאמת נתונים, פערי מידע, באגים, בעיות בשירות וכו'. אתה מאשר בזאת כי ידוע לך כי מדובר בשלב ראשוני של המערכת ולכן לא תוכל להסתמך באופן בלעדי על ניתוח הנתונים, הורדת וטעינת הנתונים, זמני החיבור ומהירות השירות והשימוש במערכת.

הצוות שלנו יעמוד לרשותך ככל האפשר, בפנייה באמצעות הצ׳ט בוט באתר ו/או בתיאום מראש בין השעות 9:00-13:00 בימים א׳ -ה׳: בתמיכה טכנית (בכל הנוגע לבעיות התחברות וכשלים טכניים במערכת) ובתמיכת שימוש (בכל הנוגע לחוסרי מידע, הדרכת שימוש וכו'), ובאמצעות כתובת המייל info@ai-gentins.com. וכן בפניה ישירה למנהלת הלקוחות בכתובת המייל hila@ai-gentins.com 
האתר
AI. GENT (בשמה הנוכחי) הינה מערכת לניהול רווחיות תיק סוכן הביטוח. באתר יכול הסוכן לבצע הורדה וטעינה אוטומטית של הדוחות הפיננסים מחברות הביטוח איתן הוא עובד ואליהם אנו מאפשרים גישה, לקבל תמונת מצב חודשית עדכנית על תיק הביטוח שבידו, לעקוב אחר הנתונים החודשיים וללמוד על רווחיות תיק הביטוח שלו, האפשריות לשיפורו ולשיפור הרווחיות בעסק שלו.  
הגישה לאתר זה נמסרה לך במסגרת תכנית ניסיונית של ט.ו.מ.ר. (הרצה). הינך מבין כי ייתכן כי פעילויות מסויימות, פונקציות ו/או כלים מסויימים לא יהיו זמינים עבורך (בין אם באופן מלא או חלקי), כי תוצאות האתר ו/או המידע שהוא מספק ייתכן ולא יהיו מדוייקים וכי אין התחייבות לתצוגת הנתונים ולגיבוי כלשהו של המידע שלך על ידי ט.ו.מ.ר. ו/או מי מטעמה. מבלי לגרוע מן האמור לעיל, האתר ו/או התוצאות המוצגות בו משמשות כלי עזר לקבלת החלטות בלבד, ואין להסתמך עליהן ו/או לתת להן משקל מכריע בקבלת החלטות ו/או ביצוע פעולות כלשהן.
אין באמור בדפי האתר והמידע בו משום הצעה, שידול או ייעוץ לביצוע עסקאות עם ט.ו.מ.ר., חברות הביטוח המחוברות לאתר ו/או כל גורם אחר. מובהר כי האתר מהווה ממשק מקשר בלבד, ואין לראות בכל מידע בו, קישור ו/או כל שירות הניתן בקשר אליו, כשירות הניתן על ידי ט.ו.מ.ר. ו/או הצעה לרכישת ביטוח (או כל מוצר או שירות אחר). כמו כן, לחברה אין כל שליטה על מוצרים המוצעים על ידי חברות הביטוח השונות (והיא אינה צד לעסקאות שכאלו) ו/או כל שימוש שייעשה על ידי חברת הביטוח במידע שתבחר למסור לה, וט.ו.מ.ר. לא תהא אחראית לכל נזק, תלונה ו/או דרישה בנושא.
ט.ו.מ.ר. איננה מחזיקה ברישיון ו/או כל אישור אחר הנדרש לצורך הצעת ו/או מכירת מוצרי ביטוח ועל כן אין להסתמך על כל מצג ו/או מידע המופיע באתר ו/או לראות בו כהצעה / שידול כאמור. ככל שהאתר מכיל מידע ו/או תוכן אשר סופק על ידי חברת הביטוח הרלבנטית, אזי חברת הביטוח תהא האחראית הבלעדית לתקינותו, נכונותו ותוקפו.
כל התקשרות שתבחר לבצע מול חברות הביטוח תהא באחריותך בלבד, וט.ו.מ.ר. לא תשא בכל אחריות לכך, והיא איננה צד לכל התקשרות כאמור.
בהרשמתך לאתר ועל מנת לקבל את השירות, עליך לספק את פרטי ההתחברות לפורטל השונים של חברות הביטוח איתן בהן ניתנת ההתחברות ולממשק את המערכת ליוזר שלך בכל חברה, טרם ההורדה הראשונה (עליך לבדוק שהפרטים שלך עדכניים, שיש לך את כל ההרשאות המבוקשות, המערכת לא תוכל לבצע הורדה אוטומטית). שים לב! ללא הפרטים הנכונים, המעודכנים, וההרשאה המתאימה למידע מחברת הביטוח האתר לא יוכל לבצע  הורדה של המידע הרלוונטי עבורך. פרטי ההתחברות יישמרו במערכת באופן מוצפן. ההתחברות לפורטלים השונים תתאפשר אך ורק באימות כפול על ידי הזנת סיסמא שתתקבל במכשיר הנייד שבידיך. אנא שים לב, על מנת להתחבר למערכת יש להזין את פרטי המשתמש לפורטלים לו קיימת הגישה הנדרשת לנתוני אלמנטרי ולנתוני חיים יחד או לחוד. המערכת תאפשר לך להזין כל משתמש שתרצה, אך תהליך הורדת הנתונים ייכשל בשל חוסר הרשאה נכונה לנתונים. המערכת שומרת בסודיות ומצפינה נתונים אלו על מנת למנוע גישה שאיננה מתבצעת על ידך למערכות חברות הביטוח.
קניין רוחני
המידע וכל הדפים המצויים באתר הינם רכושה הבלעדי של ט.ו.מ.ר. "מידע" - כל חומר ו/או מידע המוצג באתר בכל מדיה ובכלל זה במילים, תמונות, ציור, שרטוט, רישום ולרבות אופן עיצובם של הנזכרים מעלה. על אף האמור לעיל, "מידע" לא יכלול נתונים כספיים ו/או פרטים אישייים אודותיך.
האתר מכיל נתונים ותכנים שונים המוגנים על ידי זכויות יוצרים, סימני מסחר ושאר זכויות קנייניות. כמו כן, האתר כמכלול מוגן כיצירה שלמה. אין לעשות כל פעולה שיש בה כדי לפגוע בזכויות קנייניות אלה. חל איסור להכניס שינויים, לשדר, להעביר, להפיץ, לשכפל, להציג, לפרסם, להעתיק ולמכור את המידע, התכנים והשירותים באתר ו/או שמקורם באתר, כולם או חלקם.
האתר בכללותו, כולל כל המידע המופיע בו, התוכנה שעליה מבוסס האתר, מידע וחומר שהגישה אליהם נעשית באמצעות האתר, מוגשים ועומדים לרשותך כמות שהם.
הגבלת אחריות
ט.ו.מ.ר. ו/או מי מטעמה אינה אחראית לנזק שעלול להיגרם לך עקב פגם או תקלה בתוכנה המפעילה את האתר או את הגישה לאתר או בגין ביטול או הפסקת השירותים הניתנים באתר. האתר וכן כל השירותים ו/או הכלים בו ניתנים ע"ב כמו שהוא, וכאשר זמין – As-is and As Available.
תיתכן אפשרות שבמידע המופיע באתר נפלו שיבושים ו/או שגיאות ו/או אי דיוקים, למרות שט.ו.מ.ר. עושה מאמצים סבירים לוודא את איכות, אמינות, שלמות ורמת הדיוק והעדכון של המידע והתוכן הכלולים באתר. ט.ו.מ.ר. לא תהא אחראית לנזק שעלול להיגרם עקב קיום שיבושים אלה. כמו כן, ט.ו.מ.ר. ו/או מי מטעמה לא תהיה אחראית למידע ו/או תוכן המצוי באתרים אחרים חיצוניים, המקושרים לאתר באמצעות קישורית ("לינק") או בכל דרך אחרת, והיא לא תישא באחריות לנזקים שייגרמו עקב הסתמכות על המידע המצוי באתרים אלה או השימוש בו.
האחריות על השימוש באתר והאחריות על תוצאות השימוש בו הינה עליך בלבד וט.ו.מ.ר. לא תישא בכל אחריות בגין נזק ו/או הפסד העלולים להיגרם, בין במישרין ובין בעקיפין, כתוצאה מהשימוש באתר ו/או אי השימוש בו.
אבטחת מידע
ט.ו.מ.ר. משתמשת באמצעי אבטחת מידע סבירים ומתקדמים התואמים את הנהוג בעולם האינטרנט. יחד עם זאת, קיים סיכון של חדירה למאגרי הנתונים. כל עוד נוקטת ט.ו.מ.ר. באמצעי אבטחה סבירים, היא לא תהא אחראית לנזק שנגרם בעקבות פריצה כאמור ועל העברת מידע אודותיך או מידע שנמסר על ידך לצד ג' כלשהו כתוצאה מפריצה כזו. עליך מוטלת האחריות לגרום לכך שוירוסים לא יחדרו למערכת המצויה אצלך, והנך פוטר את ט.ו.מ.ר. ו/או מי מטעמה עצמה מכל אחריות לכך.
הרישום לאתר
במידה ותחליט להירשם לאתר, תצטרך לספק את פרטים אישיים (כגון פרטי זיהוי התקשרות) לרבות מספר טלפון נייד אליו ישלח סיסמא ייחודית וחד פעמית לחיבור לצורך שימושך האישי בלבד, ואינך רשאי להעבירו לאף גורם אחר ללא הרשאתה המפורשת של ט.ו.מ.ר. וכן הנך מתחייב לשאת בכל נזק שייגרם עקב השימוש בהם על ידי גורם בלתי מורשה.
הנך מבין כי השימוש באתר טעון מסירת גישה, במידת הצורך להרשאות השימוש שלך בחברות הביטוח השונות (כגון שם משתמש, סיסמה, וכו' – יחדיו: "פרטי ההתחברות"). זאת לצורך בניית נתונים והתאמה אישית (customization). במידה ולא תמסור לחברה את הפרטים כאמור, לא תוכל לעשות שימוש באתר. במסירת פרטים אלו הינך מאשר כי הינך מוסמך למסור את פרטי ההתחברות לצורך שימוש ט.ו.מ.ר. באופן המתואר לעיל וכן עבור שימושים הנספחים לו, וכי מסירת פרטי ההתחברות והשימוש בהם על ידי ט.ו.מ.ר. לא מהווים הפרה של דין ו/או הסכם כלשהו.
השימוש באתר והעלאת מידע
הנך מתחייב לעשות שימוש באתר אך ורק למטרות חוקיות ולמטרות אשר לשמן נועד האתר, שהינם קבלת מידע לצורך בחינת אלטרנטיבות למוצרי ביטוח מסויימים. כל שימוש באתר על ידך אשר לפי שיקול דעתה הבלעדי והמוחלט של ט.ו.מ.ר. יש בו כדי להגביל, להרתיע או למנוע מאחר את השימוש באתר, הינו אסור בהחלט ומהווה הפרה של תנאי שימוש אלה. מובהר, כי אין באמור לעיל כדי לגרוע מכל סעד חוקי העומד לרשות ט.ו.מ.ר. בגין הפרת תנאי ו/או הוראה כלשהיא בתנאי שימוש אלה. כמו כן, נאסר עליך להעביר את פרטי המשתמש ו/או הגישה שלך לאתר לכל גורם שהוא.
המידע מוצג לשימושך האישי בלבד, וחל איסור לעשות כל פעולה במידע, כולו או חלקו, שיש בה כדי לפגוע בזכויות קניין אלה של ט.ו.מ.ר, ולרבות להעתיק, לשכפל, להפיץ, להעביר, להציג, לפרסם, לשדר, ליצור יצירה נגזרת, למכור ו/או לסחור במידע, לבצע קצירת מידע ולעשות במידע המופיע באתר כל שימוש מסחרי שהוא. וכן חל איסור לשלוף מידע מהאתר באמצעות זחלן (crawler)  או טכנולוגיה דומה.​
הנך מתחייב שלא להעלות (upload), לשלוף, לשדר, להפיץ או לפרסם, מידע או חומר אחר:
העלולים להפריע ו/או להגביל ו/או למנוע מאחרים את השימוש באתר;
העלולים להשפיע על הביצועים ו/או התפקוד של האתר;
האסורים לפרסום או לשימוש, או שהם בגדר איום, פגיעה, העלבה, הוצאת דיבה, השמצה, פורנוגרפיה או ביטוי וולגרי אחר הכוללים מידע כוזב או מטעה;
שהינם בגדר דברים העלולים לעודד, לשדל, להמריץ או לסייע לאחר לבצע מעשה האסור על פי חיקוק או העלולים להטיל חבות ו/או אחריות משפטית;
העלולים להפר זכויות קניין של אחר, לרבות זכויות קניין רוחני, זכויות יוצרים, פטנטים, זכויות להגנת הפרטיות ו/או כל זכות קניינית אחרת;
הכוללים וירוס ו/או סוס טרויאני ו/או תוכנה אחרת העלולה לחבל במערכות מחשב או לגרום להן נזק מכל סוג שהוא;
הכוללים פרסומת מכל סוג שהוא בלי הרשאה מפורשת מראש של ט.ו.מ.ר.;
מובהר, כי ט.ו.מ.ר. אינה יכולה לפקח על כל מידע ו/או הודעה המועלים לאתר על ידי משתמשים אחרים והיא לא תהיה אחראית לכל נזק ו/או הפסד העלול להיגרם כתוצאה ו/או בקשר לכך.
מבלי לגרוע מהאמור לעיל, ט.ו.מ.ר. שומרת לעצמה את הזכות, בכל עת, למסור כל מידע אשר יידרש ממנה על פי דין, כמו גם על זכותה לערוך, להסיר ולסרב להעלות לאתר כל מידע ו/או הודעה אשר לפי שיקול דעתה הבלעדי של ט.ו.מ.ר. פוגע בעליל או מפר תנאי מתנאי שימוש אלה.
בהעלותך מידע כלשהו לאתר, לרבות מידע אישי ו/או מסמכים ו/או הודעות (להלן בסעיף זה: "התקשורת"), הנך מעניק בזאת לחברה אישור בלתי מוגבל ובלתי חוזר, לעשות בתקשורת כל שיידרש לפי שיקול דעתה הבלעדי כדי להשיג את המטרות החוקיות שלשמן הועלתה התקשורת לאתר, בכפוף להוראות תנאי שימוש אלה ולמדיניות שמירת הפרטיות והנך מוותר בזאת על כל טענה ו/או תביעה כנגד ט.ו.מ.ר. בגין הפרת זכויות קניין ופרטיות בכל הנוגע לתקשורת.
הנך מסכים ומתחייב לשפות ולפצות את ט.ו.מ.ר., מנהליה, נושאי משרה בה, בעלי מניות, עובדיה, סוכניה, והבאים מכוחה ("המשופים") כנגד כל תביעה, דרישה והוצאה, לרבות שכ"ט עו"ד, הנובעים מכל שימוש שתעשה באתר בניגוד לתנאי השימוש ו/או בגין כל תביעה ו/או דרישה כלשהי כנגד המשופים, אלא אם מדובר בפעולה בזדון של המשופים בניגוד לתנאי שימוש אלו.
 מסירת מידע אישי
מבלי לגרוע מהאמור לעיל, הנך מצהיר, מאשר ומסכים בזאת, כי כל מידע אשר יימסר על ידך באמצעות האתר נמסר מרצונך החופשי וכי עצם הקלדת הנתונים על ידך, לרבות הרישום לאתר, מהווה הסכמה מצדך כי מידע כאמור מועבר במישרין לחברת הביטוח הרלבנטית בהתאם לאמור בתנאי שימוש אלו ומדיניות הפרטיות של האתר וכי יש לך את הזכות המלאה למסור מידע זה על ידי הסכמה מדעת ובכתב על ידי נשוא המידע הרלבנטי. כמו כן, הנך מאשר ומסכים בזאת, כי ט.ו.מ.ר. תהיה רשאית לעשות במידע הנמסר על ידך עיבודים סטטיסטיים ו/או עיבודים שונים לשם שינוי ושיפור האתר ותכניו, כל זאת בכפוף לתנאי שימוש אלה ולמדיניות שמירת הפרטיות [ראה כאן].
הגבלת/חסימת גישה
ט.ו.מ.ר. שומרת על זכותה לסרב להעניק גישה לאתר או לחלקים ממנו לכל משתמש, לפי שיקול דעתה הבלעדי וללא מתן התראה מוקדמת. ט.ו.מ.ר. רשאית, בכל עת, על פי שיקול דעתה הבלעדי והמוחלט, לשנות ו/או להפסיק את מתן השירותים באתר או אפשרות השימוש באתר ו/או בחלקם, לרבות בכל הקשור לתוכן האתר ו/או השירותים, השעות בהן ניתן להשתמש באתר ו/או הציוד הדרוש לשם קבלת גישה לאתר ו/או לצורך השימוש בו.
מבלי לגרוע מהאמור לעיל, תהא ט.ו.מ.ר., רשאית למנוע ממשתמש לבצע התקשרות עמה באמצעות חסימה של משתמש בכל אחד מהמקרים הבאים:
המשתמש הפר תנאי מתנאי השימוש.
המשתמש מסר בעת הרישום לאתר ו/או בעת השימוש באתר ו/או לאחר מכן בכל אמצעי שהוא פרטים שגויים במתכוון.
המשתמש ביצע מעשה או מחדל שיש בו כדי לפגוע בחברה או בפעילות התקינה של האתר ו/או בזכות לפרטיות של נשוא מידע כלשהו.
כללי
ט.ו.מ.ר. שומרת על זכותה לשנות את תנאי השימוש בכל עת, ולפי שיקול דעתה המוחלט והבלעדי. עצם השימוש באתר ייחשב כהסכמה מצדך לתנאי שימוש אלה ולכל שינוי אשר יבוצע בהם על ידי ט.ו.מ.ר., וכן יחולו על כל שימוש באתר שיעשה על ידך, לרבות מסירת מידע ורכישה.
מקום השיפוט הבלעדי בכל עניין ומחלוקת בכל הנוגע לאתר יהיה בבית המשפט המוסמך בתל אביב, אשר ידון בעניין בהתאם לדיני מדינת ישראל, ותנאי שימוש אלה יהוו את הבסיס לדיון המשפטי.
אשר לכל הנוסחים המופיעים באתר - מטעמי נוחות הניסוח הינו בלשון זכר. כמו כן, לעיתים הינו בלשון יחיד ולעיתים בלשון רבים, אך הוא מתייחס גם לנקבה, גם לרבים וגם ליחיד, בהתאמה.

`

export default terms
