export const reportErrors = [
  {
    status: 10,
    title: 'none',
    titleEn: 'none',
    description: 'none',
    descriptionEn: 'none',
    links: [],
    buttons: [],
  },
  {
    status: 15,
    title: 'פרטי כניסה שגויים',
    titleEn: 'Incorrect Login Information',
    description:
      'המערכת לא יכולה להתחבר לפורטל החברה עקב הזנת פרטי כניסה שגויים ולכן לא יכלה להוריד נתונים.\nיש להזין מחדש את פרטי הכניסה אחרי שבדקת את תקינותם בפורטל חברת הביטוח.',
    descriptionEn:
      "The system cannot connect to the company portal due to entering incorrect login details and therefore could not download data.\nThe login details must be re-entered after you have checked their correctness on the insurance company's portal.",
    links: [
      {
        to: 'site',
        text: 'פורטל החברה',
        textEn: 'Company Site',
      },
    ],
    buttons: [
      {
        action: 'Retry',
        text: 'נסה שנית',
        textEn: 'Try again',
      },
      {
        action: 'UpdateCredentials',
        text: 'עדכון פרטי כניסה',
        textEn: 'Update Login Information',
      },
    ],
  },
  {
    status: 16,
    title: 'נדרש איפוס סיסמא',
    titleEn: 'Reset Password Required',
    description: 'נדרש איפוס סיסמא',
    descriptionEn: 'Reset Password Required',
  },
  {
    status: 17,
    title: 'פרטי כניסה לכספת שגויים',
    titleEn: 'Incorrect Vault Login Information',
    description:
      'המערכת לא הצליחה להתחבר לכספת החברה ולכן לא ירדו נתונים, יש לעדכן הרשאה במערכת החברה, בפרופיל האישי ולאחר מכן לבצע הורדה נוספת',
    descriptionEn:
      'The system was unable to connect to the company site using vault credentials and therefore no data was downloaded, vault credentials must be updated in the company system, in the personal profile and then make another download',
    links: [
      {
        to: 'site',
        text: 'פורטל החברה',
        textEn: 'Company Site',
      },
    ],
    buttons: [
      {
        action: 'Retry',
        text: 'נסה שנית',
        textEn: 'Try again',
      },
      {
        action: 'UpdateVaultCredentials',
        text: 'עדכון פרטי כניסה',
        textEn: 'Update Vault Login Information',
      },
    ],
  },
  {
    status: 18,
    title: 'ההורדה בוטלה',
    titleEn: 'Download canceled',
    description: 'בוטלה הורדת הנתונים בחברה',
    descriptionEn: "The company's data download was canceled",
    links: [],
    buttons: [
      {
        action: 'Retry',
        text: 'נסה שנית',
        textEn: 'Try again',
      },
    ],
  },
  {
    status: 19,
    title: 'דף לא צפוי',
    titleEn: 'Unexpected Page',
    description: 'המערכת זיהתה דף לא צפוי, יש להתחבר לפורטל החברה ולהתייחס לכתוב בעמוד',
    descriptionEn:
      'The system has detected an unexpected page, you must connect to the company site and refer to the post on the page',
    links: [
      {
        to: 'support',
        text: 'שלח פנייה לתמיכה הטכנית',
        textEn: 'Contact Technical Support',
      },
    ],
    buttons: [
      {
        action: 'Retry',
        text: 'נסה שנית',
        textEn: 'Try again',
      },
    ],
  },
  {
    status: 20,
    title: 'קובץ לא ירד',
    titleEn: 'File was not downloaded',
    description: 'הדוח לא ירד בשל תקלה טכנית באתר חברת הביטוח, אנא נסית שנית מאוחר יותר או פנה לתמיכה הטכנית',
    descriptionEn:
      "The report was not downloaded to a technical fail on the insurance company's website, please try again later or contact technical support",
    links: [
      {
        to: 'support',
        text: 'שלח פנייה לתמיכה הטכנית',
        textEn: 'Contact Technical Support',
      },
    ],
    buttons: [],
  },
  {
    status: 25,
    title: 'אין גישה לקובץ',
    titleEn: 'No file access',
    description: 'אין למשתמש שהוזן הרשאה לדוח זה, אנא פנה לחברת הביטוח על מנת לטפל בכך',
    descriptionEn:
      'The user who entered this report does not have access, please contact the insurance company to handle it',
    links: [
      {
        to: 'support',
        text: 'שלח פנייה לתמיכה הטכנית',
        textEn: 'Contact Technical Support',
      },
    ],
    buttons: [],
  },
  {
    status: 27,
    title: 'No Permission',
    titleEn: 'No Permission',
    description: 'No Permission',
    descriptionEn: 'No Permission',
    links: [
      {
        to: 'support',
        text: 'שלח פנייה לתמיכה הטכנית',
        textEn: 'Contact Technical Support',
      },
    ],
    buttons: [],
  },
  {
    status: 29,
    title: 'שגיאה בפורטל החברה',
    titleEn: 'Error at Company Site',
    description:
      'נמצאה תקלה בפורטל החברה אשר מונעת את הגישה לנתונים. יש לנסות שוב במועד מאוחר יותר או לפנות ליצרן הביטוח על מנת לפתור את הבעיה',
    descriptionEn:
      "An error was found in the company's portal which prevents access to the data. Try again later or contact the insurance manufacturer to resolve the issue",
    links: [
      {
        to: 'support',
        text: 'שלח פנייה לתמיכה הטכנית',
        textEn: 'Contact Technical Support',
      },
    ],
    buttons: [],
  },
  {
    status: 30,
    title: 'שגיאת הורדה',
    titleEn: 'Unknown Scan Error',
    description: 'הדוח לא ירד בשל תקלה טכנית בפורטל חברת הביטוח, אנא נסה שנית או פנה אלינו לתמיכה הטכנית',
    descriptionEn:
      "The report did not download due to a technical fault in the insurance company's portal, please try again or contact us for technical support",
    links: [
      {
        to: 'support',
        text: 'שלח פנייה לתמיכה הטכנית',
        textEn: 'Contact Technical Support',
      },
    ],
    buttons: [],
  },
  {
    status: 50,
    title: 'ההורדה בוצעה בהצלחה',
    titleEn: 'Downloaded Successfully',
    description: 'הורד בהצלחה, עבור לחברה הבאה',
    descriptionEn: 'Downloaded successfully, proceed to the next company',
    links: [],
    buttons: [],
  },
  {
    status: 55,
    title: 'קובץ לא ירד',
    titleEn: 'Filed to Save',
    description: 'שמירת הקובץ נכשלה, אנא נסה שנית. אם הבעיה חוזרת אנא פנה לתמיכה הטכנית',
    descriptionEn: 'Failed to save file, please try again. If the problem persists please contact technical support',
    links: [
      {
        to: 'support',
        text: 'שלח פנייה לתמיכה הטכנית',
        textEn: 'Contact Technical Support',
      },
    ],
    buttons: [
      {
        action: 'Retry',
        text: 'נסה שנית',
        textEn: 'Try again',
      },
    ],
  },
  {
    status: 57,
    title: 'הדוח שירד תקול',
    titleEn: 'Downloaded File is Corrupted',
    description: 'הדוח שירד מפורטל החברה אינו תקין. אנא נסה שנית ואם הבעיה חוזרת אנא פנה לתמיכה הטכנית',
    descriptionEn:
      'The downloaded report from the company portal is corrupted. Please try again and if the problem persists please contact technical support',
    links: [
      {
        to: 'support',
        text: 'שלח פנייה לתמיכה הטכנית',
        textEn: 'Contact Technical Support',
      },
    ],
    buttons: [
      {
        action: 'Retry',
        text: 'נסה שנית',
        textEn: 'Try again',
      },
    ],
  },
  {
    status: 60,
    title: 'קובץ לא נמצא',
    titleEn: 'No Data',
    description:
      'המערכת קלטה שדוח זה אינו זמין בפורטל חברת הביטוח. ייתכן כי אין הרשאה מתאימה לנתוני דוח זה או שדוח זה אינו זמין כעת בחברת הביטוח.\nאנא בדקו אם קיימות לכם ההרשאות לדוח זה מול חברת הביטוח. במידה ודוח זה אינו זמין זמנית, אנו נעדכן אותך כשהוא יהיה זמין להורדה.',
    descriptionEn:
      "The system has detected that this report is not available on the insurance company's portal. It is possible that there is no appropriate authorization for the data of this report or that this report is not currently available at the insurance company.\nPlease check if you have the permissions for this report with the insurance company. If this report is temporarily unavailable, we will update you when it is available for download.",
    links: [
      {
        to: 'site',
        text: 'פורטל החברה',
        textEn: 'Company Site',
      },
    ],
    buttons: [],
  },
  {
    status: 61,
    title: 'ירד דוח ריק',
    titleEn: 'Downloaded Report is Empty',
    description: 'הדוח שירד הינו ריק מנתונים, אנא פנה לחברת הביטוח בכדי לבדוק האם זה תקין.',
    descriptionEn: 'The downloaded report is empty, please contact the insurance company to check if it is correct.',
    links: [],
    buttons: [],
  },
  {
    status: 65,
    title: 'דוח לא זמין בחברה',
    titleEn: 'Report not Found',
    description:
      'המערכת קלטה שדוח זה אינו זמין בפורטל חברת הביטוח. ייתכן כי אין הרשאה מתאימה לנתוני דוח זה או שדוח זה אינו זמין כעת בחברת הביטוח.\nאנא בדקו אם קיימות לכם ההרשאות לדוח זה מול חברת הביטוח. במידה ודוח זה אינו זמין זמנית, אנו נעדכן אותך כשהוא יהיה זמין להורדה.',
    descriptionEn:
      "The system has detected that this report is not available on the insurance company's portal. It is possible that there is no appropriate authorization for the data of this report or that this report is not currently available at the insurance company.\nPlease check if you have the permissions for this report with the insurance company. If this report is temporarily unavailable, we will update you when it is available for download.",
    links: [
      {
        to: 'site',
        text: 'פורטל החברה',
        textEn: 'Company Site',
      },
    ],
    buttons: [
      {
        action: 'Retry',
        text: 'נסה שנית',
        textEn: 'Try again',
      },
    ],
  },
  {
    status: 70,
    title: 'ירדו נתוני חודש שגוי',
    titleEn: 'Incorrect Month Report Downloaded',
    description: 'הנתונים שיורדו הם נתונים מחודש קודם. אנא נסה שנית להוריד ואם הבעיה חוזרת אנא פנה לתמיכה הטכנית.',
    descriptionEn:
      'The data downloaded is data from a previous month. Please try downloading again and if the problem persists please contact technical support',
    links: [
      {
        to: 'support',
        text: 'שלח פנייה לתמיכה הטכנית',
        textEn: 'Contact Technical Support',
      },
    ],
    buttons: [
      {
        action: 'Retry',
        text: 'נסה שנית',
        textEn: 'Try again',
      },
    ],
  },
]
