export const getMonthName = (date, language, length = 'long') => {
  const locale = language && language === 'he' ? 'he-Il' : language === 'en' ? 'en-Us' : 'default'
  if (isNotaDate(date)) return '--'
  return new Date(date).toLocaleString(locale, {
    month: length,
  })
}

/**
 * @param {number} monthNumber - Month Number starting from 1
 */
export const getMonthNameByNumber = (monthNumber, language, length) => {
  const date = new Date(new Date().getFullYear(), monthNumber - 1, 1)
  if (isNotaDate(date)) return '--'
  return getMonthName(date, language, length)
}

export const getYearAndMonth = (date, { language, length = 'long', noValuePlaceHoder = '--' } = {}) => {
  const locale = language === 'he' ? 'he-Il' : language === 'en' ? 'en-Us' : 'default'
  if (isNotaDate(date)) return noValuePlaceHoder
  const resultDate = new Date(date)
  return (
    resultDate.toLocaleString(locale, {
      month: length,
    }) +
    ' ' +
    resultDate.getFullYear()
  )
}

export const isNotaDate = date => isNaN(Date.parse(date))
