import { useTranslation } from 'react-i18next'
import Loader from '../../../../components/Common/Loader/Loader'
import NoData from '../../../../components/Common/NoData/NoData'
import PoliciesAllListItem from './PoliciesAllListItem'
import LinedTitle from '../../../../components/Common/LinedTitle/LinedTitle'
import { allPoliciesList, allPoliciesListType } from '../../../../types/tablesTypes'
import { cn } from '../../../../utils/stylesUtils'
import ButtonRounded from '../../../../components/Common/ButtonRounded/ButtonRounded'
import PolicyListItemCard from '../PolicyListItemCard/PolicyListItemCard'
import { formatAsCurrency } from '../../../../utils/formatAs'
import './PoliciesAllList.scss'
import {
  commissionViewTranslationPrefix as commissionPrefix,
  commissionKeySuffix,
  deltaCommissionSplitView,
} from '../../../shared/reducers/sharedSelectors'
import SortableColumn from '../../../../components/SortableColumn/SortableColumn'
import { sortBy } from '../../../../types/sortByTypes'
import { useDispatch, useSelector } from 'react-redux'
import { POLICIES_SET_SORT_BY } from '../../../../types/actionTypesPolicies'

export const PoliciesAllList = ({
  list,
  listType,
  loading,
  collapsed,
  onCollapse,
  title,
  showDetails,
  selectPolicy,
  selectedPolicies,
}) => {
  const { t } = useTranslation('policies')
  const elementaryListType = listType === allPoliciesListType.elementary
  const { sort } = useSelector(({ policies }) => policies)
  const dispatch = useDispatch()
  const setSortByHandler = listKey => sortBy => dispatch({ type: POLICIES_SET_SORT_BY, payload: { sortBy, listKey } })
  const sortListKey = elementaryListType ? 'Elementary' : 'Life'

  return (
    <div className='policies-all-list-wrapper'>
      {title && (
        <LinedTitle
          title={title}
          collapsible
          onCollapse={onCollapse}
          collapsed={collapsed}
          count={t('placeholders.policiesCount', { count: list?.totalCount ?? '--' })}
        />
      )}
      {(!title || (title && !collapsed)) && (
        <>
          {loading ? <Loader /> : list?.items?.length === 0 && <NoData />}
          {!loading && list?.items?.length > 0 && (
            <>
              <div
                className={cn('policies-all-list-header policies-all-list-grid', allPoliciesList[listType].key, {
                  extended: deltaCommissionSplitView(),
                })}
              >
                {listType !== allPoliciesListType.specialPayments ? (
                  <>
                    <div></div>
                    <SortableColumn
                      title={t('list.heading.policyNumber')}
                      columnSortBy={sortBy.policyNumber}
                      columnSortOrder={sort[`order${sortListKey}`]}
                      currentSortBy={sort[`by${sortListKey}`]}
                      setSort={setSortByHandler(sortListKey)}
                      textSort
                    />
                    <SortableColumn
                      title={t('list.heading.customerName')}
                      columnSortBy={sortBy.customerName}
                      columnSortOrder={sort[`order${sortListKey}`]}
                      currentSortBy={sort[`by${sortListKey}`]}
                      setSort={setSortByHandler(sortListKey)}
                      textSort
                    />
                    <SortableColumn
                      title={t('list.heading.customerIdNumber')}
                      columnSortBy={sortBy.customerIdNumber}
                      columnSortOrder={sort[`order${sortListKey}`]}
                      currentSortBy={sort[`by${sortListKey}`]}
                      setSort={setSortByHandler(sortListKey)}
                      textSort
                    />
                    <SortableColumn
                      title={t('list.heading.company')}
                      columnSortBy={sortBy.company}
                      columnSortOrder={sort[`order${sortListKey}`]}
                      currentSortBy={sort[`by${sortListKey}`]}
                      setSort={setSortByHandler(sortListKey)}
                      textSort
                    />
                    <SortableColumn
                      title={t('list.heading.category')}
                      columnSortBy={sortBy.category}
                      columnSortOrder={sort[`order${sortListKey}`]}
                      currentSortBy={sort[`by${sortListKey}`]}
                      setSort={setSortByHandler(sortListKey)}
                      textSort
                    />
                    <SortableColumn
                      title={t('list.heading.startDate')}
                      columnSortBy={sortBy.startDate}
                      columnSortOrder={sort[`order${sortListKey}`]}
                      currentSortBy={sort[`by${sortListKey}`]}
                      setSort={setSortByHandler(sortListKey)}
                    />
                    {elementaryListType && (
                      <SortableColumn
                        title={t('list.heading.endDate')}
                        columnSortBy={sortBy.endDate}
                        columnSortOrder={sort[`order${sortListKey}`]}
                        currentSortBy={sort[`by${sortListKey}`]}
                        setSort={setSortByHandler(sortListKey)}
                      />
                    )}
                    <SortableColumn
                      title={t('list.heading.policyAge')}
                      columnSortBy={sortBy.policyAge}
                      columnSortOrder={sort[`order${sortListKey}`]}
                      currentSortBy={sort[`by${sortListKey}`]}
                      setSort={setSortByHandler(sortListKey)}
                    />
                    {deltaCommissionSplitView() && (
                      <SortableColumn
                        title={
                          t(`list.heading.commission${elementaryListType ? 'LastMonth' : ''}`) + ' ' + t('agencyPrefix')
                        }
                        columnSortBy={sortBy.commissionAgency}
                        columnSortOrder={sort[`order${sortListKey}`]}
                        currentSortBy={sort[`by${sortListKey}`]}
                        setSort={setSortByHandler(sortListKey)}
                      />
                    )}
                    <SortableColumn
                      title={t(`list.heading.commission${elementaryListType ? 'LastMonth' : ''}`) + commissionPrefix(t)}
                      columnSortBy={sortBy['commission' + commissionKeySuffix()]}
                      columnSortOrder={sort[`order${sortListKey}`]}
                      currentSortBy={sort[`by${sortListKey}`]}
                      setSort={setSortByHandler(sortListKey)}
                    />
                    <SortableColumn
                      title={t(`list.heading.premium${elementaryListType ? 'LastMonth' : ''}`)}
                      columnSortBy={sortBy.premium}
                      columnSortOrder={sort[`order${sortListKey}`]}
                      currentSortBy={sort[`by${sortListKey}`]}
                      setSort={setSortByHandler(sortListKey)}
                    />
                    {!elementaryListType && (
                      <>
                        <SortableColumn
                          title={t(`list.heading.accumulation`)}
                          columnSortBy={sortBy.accumulation}
                          columnSortOrder={sort[`order${sortListKey}`]}
                          currentSortBy={sort[`by${sortListKey}`]}
                          setSort={setSortByHandler(sortListKey)}
                        />
                        {deltaCommissionSplitView() && (
                          <div>
                            <div>
                              {t('list.heading.cRate')}
                              {' ' + t('agencyPrefix')}
                            </div>
                            <div className='commission-rate-cells'>
                              <SortableColumn
                                title={t('list.heading.premium')}
                                columnSortBy={sortBy.commissionRatePremiumAgency}
                                columnSortOrder={sort[`order${sortListKey}`]}
                                currentSortBy={sort[`by${sortListKey}`]}
                                setSort={setSortByHandler(sortListKey)}
                              />
                              <SortableColumn
                                title={t('list.heading.accumulation')}
                                columnSortBy={sortBy.commissionRateAccumulationAgency}
                                columnSortOrder={sort[`order${sortListKey}`]}
                                currentSortBy={sort[`by${sortListKey}`]}
                                setSort={setSortByHandler(sortListKey)}
                              />
                            </div>
                          </div>
                        )}
                        <div>
                          <div>
                            {t('list.heading.cRate')}
                            {commissionPrefix(t)}
                          </div>
                          <div className='commission-rate-cells'>
                            <SortableColumn
                              title={t('list.heading.premium')}
                              columnSortBy={sortBy['commissionRatePremium' + commissionKeySuffix()]}
                              columnSortOrder={sort[`order${sortListKey}`]}
                              currentSortBy={sort[`by${sortListKey}`]}
                              setSort={setSortByHandler(sortListKey)}
                            />
                            <SortableColumn
                              title={t('list.heading.accumulation')}
                              columnSortBy={sortBy['commissionRateAccumulation' + commissionKeySuffix()]}
                              columnSortOrder={sort[`order${sortListKey}`]}
                              currentSortBy={sort[`by${sortListKey}`]}
                              setSort={setSortByHandler(sortListKey)}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    {elementaryListType && (
                      <>
                        {deltaCommissionSplitView() && (
                          <SortableColumn
                            title={t('list.heading.commissionMonthly') + ' ' + t('agencyPrefix')}
                            columnSortBy={sortBy.commissionMonthlyAgency}
                            columnSortOrder={sort[`order${sortListKey}`]}
                            currentSortBy={sort[`by${sortListKey}`]}
                            setSort={setSortByHandler(sortListKey)}
                          />
                        )}
                        <SortableColumn
                          title={t('list.heading.commissionMonthly') + commissionPrefix(t)}
                          columnSortBy={sortBy['commissionMonthly' + commissionKeySuffix()]}
                          columnSortOrder={sort[`order${sortListKey}`]}
                          currentSortBy={sort[`by${sortListKey}`]}
                          setSort={setSortByHandler(sortListKey)}
                        />
                        <SortableColumn
                          title={t('list.heading.premiumMonthly')}
                          columnSortBy={sortBy.premiumMonthly}
                          columnSortOrder={sort[`order${sortListKey}`]}
                          currentSortBy={sort[`by${sortListKey}`]}
                          setSort={setSortByHandler(sortListKey)}
                        />
                        {deltaCommissionSplitView() && (
                          <SortableColumn
                            title={t('list.heading.commissionRate') + ' ' + t('agencyPrefix')}
                            columnSortBy={sortBy.commissionRateAgency}
                            columnSortOrder={sort[`order${sortListKey}`]}
                            currentSortBy={sort[`by${sortListKey}`]}
                            setSort={setSortByHandler(sortListKey)}
                          />
                        )}
                        <SortableColumn
                          title={t('list.heading.commissionRate') + commissionPrefix(t)}
                          columnSortBy={sortBy['commissionRate' + commissionKeySuffix()]}
                          columnSortOrder={sort[`order${sortListKey}`]}
                          currentSortBy={sort[`by${sortListKey}`]}
                          setSort={setSortByHandler(sortListKey)}
                        />
                      </>
                    )}
                    <div></div>
                    <div></div>
                  </>
                ) : (
                  <>
                    <SortableColumn
                      title={t('list.heading.paymentNumber')}
                      columnSortBy={sortBy.paymentNumber}
                      columnSortOrder={sort.orderSpecial}
                      currentSortBy={sort.bySpecial}
                      setSort={setSortByHandler('Special')}
                    />
                    <SortableColumn
                      title={t('list.heading.paymentName')}
                      columnSortBy={sortBy.paymentName}
                      columnSortOrder={sort.orderSpecial}
                      currentSortBy={sort.bySpecial}
                      setSort={setSortByHandler('Special')}
                    />
                    <SortableColumn
                      title={t('list.heading.customerIdNumber')}
                      columnSortBy={sortBy.customerIdNumber}
                      columnSortOrder={sort.orderSpecial}
                      currentSortBy={sort.bySpecial}
                      setSort={setSortByHandler('Special')}
                      textSort
                    />
                    <SortableColumn
                      title={t('list.heading.company')}
                      columnSortBy={sortBy.company}
                      columnSortOrder={sort.orderSpecial}
                      currentSortBy={sort.bySpecial}
                      setSort={setSortByHandler('Special')}
                      textSort
                    />
                    <SortableColumn
                      title={t('list.heading.category')}
                      columnSortBy={sortBy.category}
                      columnSortOrder={sort.orderSpecial}
                      currentSortBy={sort.bySpecial}
                      setSort={setSortByHandler('Special')}
                      textSort
                    />
                    <div>{t('list.heading.month')}</div>
                    {deltaCommissionSplitView() && (
                      <SortableColumn
                        title={t('list.heading.commission') + ' ' + t('agencyPrefix')}
                        columnSortBy={sortBy.commissionAgency}
                        columnSortOrder={sort.orderSpecial}
                        currentSortBy={sort.bySpecial}
                        setSort={setSortByHandler('Special')}
                        textSort
                      />
                    )}
                    <SortableColumn
                      title={t('list.heading.commission') + commissionPrefix(t)}
                      columnSortBy={sortBy['commission' + commissionKeySuffix()]}
                      columnSortOrder={sort.orderSpecial}
                      currentSortBy={sort.bySpecial}
                      setSort={setSortByHandler('Special')}
                    />
                  </>
                )}
              </div>
              <div className='policies-all-list'>
                {list.items.map(p => (
                  <PoliciesAllListItem
                    key={p.id}
                    listType={listType}
                    policy={p}
                    classes={
                      'policies-all-list-grid ' +
                      allPoliciesList[listType].key +
                      (deltaCommissionSplitView() ? ' extended' : '')
                    }
                    selectPolicy={selectPolicy}
                    selected={selectedPolicies.includes(p.id)}
                  />
                ))}
              </div>
              <PolicyListItemCard
                classes={
                  'policies-all-list-grid policies-all-list-total ' +
                  allPoliciesList[listType].key +
                  (deltaCommissionSplitView() ? ' extended' : '')
                }
              >
                {listType !== allPoliciesListType.specialPayments ? (
                  <>
                    <div>{t('list.heading.listTotal')}</div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    {elementaryListType && <div></div>}
                    <div></div>
                    {deltaCommissionSplitView() && (
                      <div className='financial-value-cell'>
                        {formatAsCurrency(list['totalCommissionAgency'], { dashesForZero: false })}
                      </div>
                    )}
                    <div className='financial-value-cell'>
                      {formatAsCurrency(list['totalCommission' + commissionKeySuffix()], { dashesForZero: false })}
                    </div>
                    <div className='financial-value-cell'>
                      {formatAsCurrency(list.totalPremium, { dashesForZero: false })}
                    </div>
                    {!elementaryListType && (
                      <>
                        <div className='financial-value-cell'>
                          {formatAsCurrency(list.totalAccumulation, { dashesForZero: false })}
                        </div>
                        <div></div>
                      </>
                    )}
                    {elementaryListType && (
                      <>
                        {deltaCommissionSplitView() && (
                          <div className='financial-value-cell'>
                            {formatAsCurrency(list['totalCommissionMonthlyAgency'], { dashesForZero: false })}
                          </div>
                        )}
                        <div className='financial-value-cell'>
                          {formatAsCurrency(list['totalCommissionMonthly' + commissionKeySuffix()], {
                            dashesForZero: false,
                          })}
                        </div>
                        <div className='financial-value-cell'>
                          {formatAsCurrency(list.premiumMonthly, { dashesForZero: false })}
                        </div>
                      </>
                    )}
                    <div></div>
                    <div></div>
                  </>
                ) : (
                  <>
                    <div>{t('list.heading.listTotal')}</div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    {deltaCommissionSplitView() && (
                      <div className='financial-value-cell'>
                        {formatAsCurrency(list['totalCommissionAgency'], { dashesForZero: false })}
                      </div>
                    )}
                    <div>
                      {formatAsCurrency(list['totalCommission' + commissionKeySuffix()], { dashesForZero: false })}
                    </div>
                  </>
                )}
              </PolicyListItemCard>
            </>
          )}
          {title && !loading && (
            <div className='policies-all-list-footer'>
              <ButtonRounded onClick={showDetails}>{t('placeholders.showMoreBtn')}</ButtonRounded>
            </div>
          )}
        </>
      )}
    </div>
  )
}
