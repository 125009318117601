const HomeIconSvg = ({ fill = 'var(--text-primary-color)' }) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' width='24' height='24'>
    <g id='home'>
      <path
        id='Vector'
        fill={fill}
        d='M 12 3 L 2 12 H 5 V 20 H 11 V 14 H 13 V 20 H 19 V 12 H 22 L 12 3 Z M 17 18 H 15 V 12 H 9 V 18 H 7 V 10.19 L 12 5.69 L 17 10.19 V 18 Z'
      />
      <path
        id='Vector_2'
        opacity='0.3'
        fill={fill}
        d='M 7 10.1899 V 17.9999 H 9 V 11.9999 H 15 V 17.9999 H 17 V 10.1899 L 12 5.68994 L 7 10.1899 Z'
      />
    </g>
  </svg>
)

export default HomeIconSvg
