import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AddUserModal from '../AddUserModal/AddUserModal'
import LinedTitle from '../../../../components/Common/LinedTitle/LinedTitle'
import ModalWithNote from '../../../../components/Common/ModalWithNote/ModalWithNote'
import ButtonsChangeStatus from '../../../../components/Common/ButtonsChangeStatus/ButtonsChangeStatus'
import PolicyStatusMarker from '../../../../components/Common/PolicyStatusMarker/PolicyStatusMarker'
import { irregularReason, irregularReasonObject } from '../../../../types/irregularReasonTypes'
import { irregularStatus } from '../../../../types/irregularStatusTypes'
import { getMonthName } from '../../../../services/getMonthName'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { formatAsShortDate } from '../../../../utils/formatAs'
import './CustomerPolicyIrregularities.scss'
import {
  deactivatePolicy,
  fixIrregularity,
  ignoreIrregularity,
  sendForTreat,
} from '../../../policy/actions/policyActions'
import { getAgents } from '../../../shared/actions/sharedActions'
import { customerTaskType } from '../../../../types/customerTaskTypes'

const CustomerPolicyIrregularities = ({ policy, irregularities, notify }) => {
  const [showAgentsModal, setShowAgentsModal] = useState(false)
  const [showTreatNoteModal, setShowTreatNoteModal] = useState(false)
  const [showTreatedNoteModal, setShowTreatedNoteModal] = useState(false)
  const [selectedIrrty, setSelectedIrrty] = useState()
  const [treatInfo, setTreatInfo] = useState(null)
  const { t, i18n } = useTranslation('customer')
  const dispatch = useDispatch()
  const agents = useSelector(state => state.shared).agents
  const agentsLoading = useSelector(state => state.shared).agentsLoading

  const addTreatAgent = agent => {
    setTreatInfo({ ...treatInfo, agentId: agent.id, agentName: agent.name })
    setShowAgentsModal(false)
    setShowTreatNoteModal(true)
  }

  const addTreatAgentNote = note => {
    setTreatInfo({ ...treatInfo, note })
    const treatDto = {
      agentId: treatInfo.agentId,
      irregularityId: treatInfo.irrtyId,
      customerId: policy.clientId ?? policy.customerId,
      note,
      type: customerTaskType.irregularity,
    }
    if (notify) {
      notify({
        text: `החריגה הועברה לטיפול של ${treatInfo.agentName}`,
        textEn: `The irregularity was transferred to the treatment to ${treatInfo.agentName}`,
        action: () => dispatch(sendForTreat(treatDto)),
        actionText: 'ביטול',
        actionTextEn: 'Cancel',
        id: policy.id.toString() + treatInfo.irrtyId.toString() + 'setTreatNote',
      })
    } else {
      dispatch(sendForTreat(treatDto))
    }
  }

  const ignoreIrregularityHandler = irrty => {
    const irregularityId = irrty.id
    const month = irrty.fromMonth
    if (notify) {
      notify({
        text: 'החריגה הוגדרה כתקינה',
        textEn: 'The irregularity was defined as correct',
        action: () =>
          dispatch(
            ignoreIrregularity({ irregularityId, month, policyId: policy.id, irregsCount: irregularities.length })
          ),
        actionText: 'ביטול',
        actionTextEn: 'Cancel',
        id: irrty.id.toString() + 'correct',
      })
    } else {
      dispatch(ignoreIrregularity({ irregularityId, month, policyId: policy.id, irregsCount: irregularities.length }))
    }
  }

  const deactivate = () => {
    if (notify) {
      notify({
        text: `הפוליסה ${policy.policyNumber} הוגדרה כלא פעילה`,
        textEn: `The policy ${policy.policyNumber} has been deactivated`,
        action: () => dispatch(deactivatePolicy(policy.id)),
        actionText: 'ביטול',
        actionTextEn: 'Cancel',
        id: policy.id.toString() + 'deactivate',
      })
    } else {
      dispatch(deactivatePolicy(policy.id))
    }
  }

  const addTreatedNote = note => {
    const irregularityId = selectedIrrty.id
    const month = selectedIrrty.fromMonth
    if (notify) {
      notify({
        text: `החריגה טופלה ותועדה`,
        textEn: `The irregularity has been already treated and documented`,
        action: () =>
          dispatch(
            fixIrregularity({ irregularityId, month, note, policyId: policy.id, irregsCount: irregularities.length })
          ),
        actionText: 'ביטול',
        actionTextEn: 'Cancel',
        id: policy.id.toString() + 'treated',
      })
    } else {
      dispatch(
        fixIrregularity({ irregularityId, month, note, policyId: policy.id, irregsCount: irregularities.length })
      )
    }
  }

  const buildTreatMsg = ({ agentName, date }) => {
    const formattedDate = formatAsShortDate(date, '.')
    return t('sendForTreatMsg', { agentName, date: formattedDate })
  }

  useEffect(() => {
    if (showAgentsModal) {
      dispatch(getAgents())
    }
  }, [showAgentsModal, dispatch])

  return (
    <>
      {showAgentsModal && (
        <AddUserModal
          users={agents}
          loading={agentsLoading}
          closeModal={() => {
            setShowAgentsModal(false)
          }}
          closeOnOutsideClick={false}
          onAddUser={addTreatAgent}
          currentUser={selectedIrrty?.treatmentInfo?.agentId}
          askConfirm={
            selectedIrrty?.treatmentInfo?.agentId && selectedIrrty?.treatmentInfo?.agentId !== treatInfo?.currAgent
          }
        />
      )}
      {showTreatNoteModal && (
        <ModalWithNote
          closeModal={() => setShowTreatNoteModal(false)}
          closeOnOutsideClick={false}
          title={t('treatNoteModal.modalTitle')}
          subtitle={t('treatNoteModal.modalSubtitle')}
          label={t('treatNoteModal.inputLabel')}
          btnCaption={t('treatNoteModal.btnTitle')}
          back={() => setShowAgentsModal(true)}
          onAddNote={addTreatAgentNote}
        />
      )}
      {showTreatedNoteModal && (
        <ModalWithNote
          closeModal={() => setShowTreatedNoteModal(false)}
          closeOnOutsideClick={false}
          title={t('alreadyTreatedNoteModal.modalTitle')}
          subtitle={t('alreadyTreatedNoteModal.modalSubtitle')}
          label={t('alreadyTreatedNoteModal.inputLabel')}
          btnCaption={t('alreadyTreatedNoteModal.btnTitle')}
          onAddNote={addTreatedNote}
        />
      )}
      <div className='customer-policy-payments-list-header'>
        <div className='customer-policy-irreg-title-container'>
          <LinedTitle count={irregularities.length} title={t('tableHeadings.irregularities')} />
        </div>
        <div className='customer-policy-irreg-grid heading'>
          <div>{t('tableHeadings.irregularityBeginning')}</div>
          <div>{t('tableHeadings.irregularReason')}</div>
          <div></div>
          <div></div>
        </div>
      </div>
      {irregularities.map(irr => {
        const year = new Date(irr.fromMonth).getFullYear()
        const month = getMonthName(irr.fromMonth, i18n.language)
        return (
          <div className='irreg-item' key={irr.id}>
            <div className={classNames('customer-policy-irreg-grid')}>
              <div>
                {month} {year}
              </div>
              <div>
                {i18n.language === 'he'
                  ? irregularReasonObject[irr.irregularReason]?.name
                  : irregularReasonObject[irr.irregularReason]?.nameEn}
              </div>
              <div>
                <ButtonsChangeStatus
                  treat={() => {
                    setSelectedIrrty(irr)
                    setTreatInfo({ ...treatInfo, currAgent: irr.agentId, irrtyId: irr.id })
                    setShowAgentsModal(true)
                  }}
                  deactivate={policy.active && irr.irregularReason === irregularReason.missingPayment && deactivate}
                  //fix irregularity, mark it has been treated
                  markTreated={
                    irr.irregularReason !== irregularReason.missingPayment &&
                    (() => {
                      setSelectedIrrty(irr)
                      setShowTreatedNoteModal(true)
                    })
                  }
                  // ignore, meaning that irregularity is correct
                  ignore={() => ignoreIrregularityHandler(irr)}
                />
              </div>
              <div>
                <PolicyStatusMarker active={true} irregular={irregularStatus.irregular} />
              </div>
            </div>
            {irr.treatmentInfo && irr.treatmentInfo.length > 0 && (
              <div className='irreg-remark'>
                {buildTreatMsg(irr.treatmentInfo.sort((a, b) => new Date(b.date) - new Date(a.date))[0])}
              </div>
            )}
          </div>
        )
      })}
    </>
  )
}

export default CustomerPolicyIrregularities
