import $api, { getCancelToken } from '..'
import { urlCustomer } from '../../environment/urls'
import { delay } from '../../services/delay'

export const getCustomerTotalsRequest = async ({ id, calculateByGroup = false } = {}) =>
  await $api.post(
    `${urlCustomer()}get-customer-totals-info/`,
    { id: +id, calculateByGroup },
    getCancelToken('getCustomerTotalsRequest')
  )

export const getCustomerTotalsDetailsRequest = async ({
  id,
  calculateByGroup = false,
  dataType,
  entityTypeOrder,
} = {}) =>
  await $api.post(
    `${urlCustomer()}get-customer-sidecard-info`,
    { id, calculateByGroup, dataType, entityTypeOrder },
    getCancelToken('getCustomerTotalsDetailsRequest')
  )

export const getCustomerPoliciesInfoRequest = async ({
  id,
  calculateByGroup = false,
  categories,
  companies,
  months = [],
  search,
} = {}) =>
  await $api.post(
    `${urlCustomer()}get-customer-policies-info`,
    { id: +id, isFamilyGroupRequest: calculateByGroup, categories, companies, months, searchText: search },
    getCancelToken('getCustomerPoliciesInfoRequest')
  )

export const getCustomerPoliciesByCategoryRequest = async ({
  id,
  calculateByGroup = false,
  companies,
  months,
  search,
  entityId,
} = {}) =>
  await $api.post(
    `${urlCustomer()}get-customer-policies-by-category`,
    { id, isFamilyGroupRequest: calculateByGroup, companies, months, searchText: search, categoryId: entityId },
    getCancelToken('getCustomerPoliciesByCategoryRequest')
  )

export const getCustomerPoliciesByGroupRequest = async ({
  id,
  calculateByGroup = false,
  categories = [],
  companies = [],
  months = [],
  search,
  entityId,
} = {}) =>
  await $api.post(
    `${urlCustomer()}get-customer-policies-by-status`,
    { id, isFamilyGroupRequest: calculateByGroup, categories, companies, months, searchText: search, status: entityId },
    getCancelToken('getCustomerPoliciesByGroupRequest')
  )

export const getCustomerSalesPotentialRequest = async (id, calculateByGroup = false) =>
  await $api.get(
    `${urlCustomer()}get-customer-sales-potential/${id}/${calculateByGroup}`,
    getCancelToken('getCustomerSalesPotentialRequest')
  )
