import { useTranslation } from 'react-i18next'
import { ArrowDownwardRounded, ArrowUpwardRounded } from '@mui/icons-material'
import './AgencyHouseLobbyChanges.scss'

const AgencyHouseLobbyChanges = ({
  value,
  showTrend = true,
  showSign = true,
  colorChanges = true,
  changesText,
  showValue = true,
  showCurrencySymbol = true,
  showPercentSymbol,
  maximumFractionDigits = 2,
  minimumFractionDigits = 2,
}) => {
  const { t } = useTranslation('agencyHouseLobby')
  if (!changesText) {
    changesText = t('totals.prevMonthChanges')
  }
  const noValue = value === null || value === undefined
  const icon =
    noValue || !showTrend ? null : value > 0 ? (
      <ArrowUpwardRounded style={{ ...(colorChanges && { color: 'var(--trend-up-color)' }), fontSize: '32px' }} />
    ) : (
      <ArrowDownwardRounded style={{ ...(colorChanges && { color: 'var(--trend-down-color)' }), fontSize: '32px' }} />
    )
  const formattedValue = noValue ? (
    '--'
  ) : (
    <span
      dir='ltr'
      className='agency-house-lobby-changes-value'
      style={{
        ...(colorChanges && value !== 0 && { color: `var(--trend-${value > 0 ? 'up' : 'down'}-color)` }),
        textAlign: 'end',
        marginInlineEnd: 'auto',
      }}
    >
      {showSign && <span>{value > 0 ? '+ ' : '- '}</span>}
      {showCurrencySymbol && <span className='agency-house-lobby-changes-value-currency'>₪</span>}
      <span>
        {new Intl.NumberFormat('en-US', {
          style: 'decimal',
          maximumFractionDigits,
          minimumFractionDigits,
        }).format(Math.abs(value))}
      </span>
      {showPercentSymbol && <span className='agency-house-lobby-changes-value-currency'>%</span>}
    </span>
  )
  return (
    <div className='agency-house-lobby-changes'>
      {icon}
      {showValue && (
        <div>
          {formattedValue}
          <span className='agency-house-lobby-changes-text'>{changesText}</span>
        </div>
      )}
    </div>
  )
}

export default AgencyHouseLobbyChanges
