import { POLICIES_FILTERS_FAIL, POLICIES_FILTERS_REQUEST, POLICIES_FILTERS_SUCCESS } from '../../../types/actionTypes'
import { getResponseErrorMessage } from '../../../http'
import { insuranceCategory } from '../../../types/insuranceCategory'
import { getCustomersFiltersRequest } from '../../../http/requests/customersRequests'
import {
  GET_POLICIES_FILTERS_FAIL,
  GET_POLICIES_FILTERS_REQUEST,
  GET_POLICIES_FILTERS_SUCCESS,
} from '../../../types/actionTypesPolicies'
import { getPoliciesFiltersRequest } from '../../../http/requests/agencyPoliciesRequests'

export const getPoliciesFilter = () => async dispatch => {
  try {
    dispatch({ type: GET_POLICIES_FILTERS_REQUEST })
    const {
      data: { data: resp },
    } = await getPoliciesFiltersRequest()
    const getFromTo = arr => {
      if (arr.length === 0) {
        return { from: null, to: null }
      }
      return {
        from: arr[0].date,
        to: arr.at(-1).date,
      }
    }
    const select = {
      categories: resp.categories,
      companies: resp.companies,
      agents: resp.agents.map(a => ({
        ...a,
        name: a.name + ` (${a.agentNumber})`,
        nameEn: a.name + ` (${a.agentNumber})`,
      })),
      policyTypes: resp.policyTypes,
    }
    const dates = {
      policyStartDatesInit: resp.policyStartDates,
      policyEndDatesInit: resp.policyEndDates,
      anyMonthInReportsDatesInit: getFromTo(resp.anyMonthInReportsDates),
      firstTimeInReportDatesInit: getFromTo(resp.firstTimeInReportDates),
      irregularityStartDateInit: getFromTo(resp.irregularityStartDates),
    }
    const range = {
      premiumRange: resp.premiumRange,
      commissionRange: resp.commissionRange,
      accumulationRange: resp.accumulationRange,
      commissionRateByPremiumRange: resp.commissionRateByPremiumRange,
      commissionRateByAccumulationRange: resp.commissionRateByAccumulationRange,
      averageCommissionRateRange: { from: 0, to: 0 },
      expectedCommissionRateRange: { from: 0, to: 0 },
    }
    dispatch({
      type: GET_POLICIES_FILTERS_SUCCESS,
      payload: {
        select,
        dates,
        //range,
      },
    })
  } catch (error) {
    dispatch({ type: GET_POLICIES_FILTERS_FAIL, payload: getResponseErrorMessage(error) })
  }
}

export const getPoliciesFilters = () => async dispatch => {
  dispatch({ type: POLICIES_FILTERS_REQUEST })
  try {
    const {
      data: { data },
    } = await getCustomersFiltersRequest()
    const companiesFilter = data.map(company => {
      return { id: company.id, name: company.name, nameEn: company.nameEn }
    })
    const categoriesFilter = data
      .map(company => company.categories)
      .flat()
      .filter(item => item)
      .filter((item, index, self) => index === self.findIndex(categ => categ.id === item.id))
      .map(ctgr => {
        return { id: ctgr.id, name: ctgr.name, nameEn: ctgr.englishName, fieldId: ctgr.fieldId }
      })
      .sort((a, b) => insuranceCategory[a.id].sortOrder - insuranceCategory[b.id].sortOrder)

    dispatch({
      type: POLICIES_FILTERS_SUCCESS,
      payload: { companiesFilter, categoriesFilter },
    })
  } catch (error) {
    dispatch({
      type: POLICIES_FILTERS_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}
