import { useTranslation } from 'react-i18next'
import { getMonthNameByNumber } from '../../../../services/getMonthName'
import './AgencyHouseDownloadHistory.scss'
import { useDispatch } from 'react-redux'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import {
  AGENT_HOUSE_DOWNLOAD_HISTORY_SHOW_AGENCIES,
  AGENT_HOUSE_DOWNLOAD_HISTORY_SHOW_COMPANIES,
} from '../../../../types/actionTypes'
import AgencyHouseDownloadSummaryItem from '../AgencyHouseDownloadSummaryItem/AgencyHouseDownloadSummaryItem'
const AgencyHouseDownloadHistory = ({ history = [] }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('download')
  const dispatch = useDispatch()
  const noCompanies = history.every(d =>
    d.Agencies.every(a => [...a.NoAttempts, ...a.AllFailed, ...a.SomeSucceed, ...a.AllSucceed].length === 0)
  )

  if (noCompanies) {
    return (
      <div className='agency-house-download-history'>
        <p style={{ textAlign: 'center' }}>{t('noHistory')}</p>
      </div>
    )
  }
  return (
    <div className='agency-house-download-history'>
      {history
        .filter(
          d => !d.Agencies.every(a => [...a.NoAttempts, ...a.AllFailed, ...a.SomeSucceed, ...a.AllSucceed].length === 0)
        )
        .map(d => (
          <div key={d.Month + '' + d.Year}>
            <header className='agency-house-download-screen-collapsible-header'>
              <p>
                {getMonthNameByNumber(d.Month, language)} {d.Year}
              </p>
              <span></span>
              <button
                onClick={() =>
                  dispatch({
                    type: AGENT_HOUSE_DOWNLOAD_HISTORY_SHOW_AGENCIES,
                    payload: { year: d.Year, month: d.Month },
                  })
                }
              >
                {d.showAgencies ? <ArrowDropUp /> : <ArrowDropDown />}
              </button>
            </header>
            {d.showAgencies &&
              d.Agencies.map(a => (
                <AgencyHouseDownloadSummaryItem
                  key={a.Id}
                  agency={a}
                  showCompaniesHandler={() =>
                    dispatch({
                      type: AGENT_HOUSE_DOWNLOAD_HISTORY_SHOW_COMPANIES,
                      payload: { month: d.Month, year: d.Year, id: a.Id },
                    })
                  }
                />
              ))}
          </div>
        ))}
    </div>
  )
}

export default AgencyHouseDownloadHistory
