import { useHistory, useParams } from 'react-router-dom'
import CustomerDetails from '../CustomerDetails/CustomerDetails'
import { useTranslation } from 'react-i18next'
import FamilyGroupsToggle from '../../../../components/FamilyGroupsToggle/FamilyGroupsToggle'
import './CustomerInfoContainer.scss'
import { familyGroupMode } from '../../../shared/reducers/sharedSelectors'
import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getCustomerGroupTypes, getCustomerGroups } from '../../actions/customerActions'
import CustomerFamilyGroupDetails from '../CustomerDetails/CustomerFamilyGroupDetails'

const CustomerInfoContainer = ({ customerInfo, customerInfoLoading, customerGroupMembers }) => {
  const { t } = useTranslation('customer')
  let history = useHistory()
  const dispatch = useDispatch()
  const { id } = useParams()
  const fetchCustomerGroups = useCallback(() => {
    dispatch(getCustomerGroups(id))
  }, [dispatch, id])

  useEffect(() => {
    fetchCustomerGroups()
    dispatch(getCustomerGroupTypes())
  }, [dispatch, fetchCustomerGroups])

  return (
    <div className='customer-info-container'>
      <div className='customer-info-container-header'>
        <div className='customer-info-container-back' onClick={history.goBack}>
          <div className='customer-info-container-back-icon'>
            <img src='./assets/arrow-right-common/Right.png' alt='back' className='img-icon' />
          </div>
          <div className='customer-info-container-back-text'>{t('goBackToCustomers')}</div>
        </div>
        <FamilyGroupsToggle />
      </div>

      <div className='customer-info-cards-container'>
        {familyGroupMode() ? (
          <CustomerFamilyGroupDetails />
        ) : (
          <CustomerDetails
            customerInfo={customerInfo}
            customerInfoLoading={customerInfoLoading}
            customerGroupMembers={customerGroupMembers}
          />
        )}
      </div>
    </div>
  )
}

export default CustomerInfoContainer
