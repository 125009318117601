import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'

import './ProfileConnectedAgencies.scss'
import { getProfileConnectedAgenciesRequest } from '../../../../http/requests/profileRequests'
import Loader from '../../../../components/Common/Loader/Loader'
import NoData from '../../../../components/Common/NoData/NoData'
import ProfileConnectedAgency from '../ProfileConnectedAgency/ProfileConnectedAgency'

const ProfileConnectedAgencies = () => {
  const { t } = useTranslation('profile')
  const [agencies, setAgencies] = useState([])
  const [agenciesLoading, setAgenciesLoading] = useState([])

  useEffect(() => {
    const fetchAgencies = async () => {
      try {
        setAgenciesLoading(true)
        const {
          data: { data },
        } = await getProfileConnectedAgenciesRequest()
        setAgencies(data)
        setAgenciesLoading(false)
      } catch (error) {
        console.log(error)
        setAgenciesLoading(false)
      }
    }
    fetchAgencies()
  }, [])

  return (
    <div className='profile-connected-agencies'>
      <header>
        <p>{t('agencies.header')}</p>
      </header>
      <div>
        {agenciesLoading ? (
          <Loader />
        ) : agencies.length === 0 ? (
          <NoData text={t('agencies.noConnectedAgencies')} />
        ) : (
          agencies.map(a => (
            <ProfileConnectedAgency
              key={a.id}
              agency={a}
              updateAgency={ag => setAgencies(prev => prev.map(a => ({ ...(ag.id === a.id ? ag : a) })))}
            />
          ))
        )}
      </div>
    </div>
  )
}

export default ProfileConnectedAgencies
