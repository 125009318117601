export const customerTaskType = {
  unknown: 0,
  setCategory: 'setCategory',
  examineProducts: 1,
  groupAffiliation: 2,
  externalCommission: 3,
  appointment: 4,
  irregularity: 5,
  policy: 6,
  customer: 7,
  other: 8,
}

export const customerTask = {
  [customerTaskType.setCategory]: {
    text: () => 'Set Category',
    textEn: () => 'Set Category',
    addDisabled: true,
  },
  [customerTaskType.examineProducts]: {
    textEn: () => 'Examine Products Basket',
    text: () => 'בחינת סל מוצרים',
  },
  [customerTaskType.groupAffiliation]: {
    textEn: () => 'Group Affiliation',
    text: () => 'שיוך לקבוצה',
    addDisabled: true,
  },
  [customerTaskType.appointment]: {
    textEn: () => 'Schedule an Appointment',
    text: () => 'תיאום פגישה',
  },
  [customerTaskType.irregularity]: {
    text: () => 'חריגות',
    textEn: () => 'Irregularity',
    addDisabled: true,
  },
  [customerTaskType.externalCommission]: {
    text: () => 'עמלה חיצונית',
    textEn: () => 'External Commission',
  },
  [customerTaskType.policy]: {
    text: () => 'טיפול בפוליסה',
    textEn: () => 'Policy Handling',
  },
  [customerTaskType.unknown]: {
    text: () => 'Unknown task type',
    textEn: () => 'Unknown task type',
    addDisabled: true,
  },
  [customerTaskType.customer]: {
    text: () => 'טיפול בלקוח',
    textEn: () => 'Customer Treatment',
  },
  [customerTaskType.other]: {
    text: () => 'אחר',
    textEn: () => 'Other',
  },
}

export const customerTaskStatusType = {
  treatWaiting: 0,
  treated: 1,
}

export const customerTaskStatus = {
  [customerTaskStatusType.treatWaiting]: {
    text: () => 'לטיפול',
    textEn: () => 'Treat Waiting',
  },
  [customerTaskStatusType.treated]: {
    text: () => 'טופל',
    textEn: () => 'Treated',
  },
}
