import './PaginateIrreg.css'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

const PaginateIrreg = ({
  pages,
  page,
  pageSize = 100,
  totalItems,
  setPageSize,
  forwardHandler = () => {},
  backwardHandler = () => {},
}) => {
  const { t } = useTranslation('common')
  const onInputHandler = e => {
    e.target.value = e.target.value.replace(/\D/g, '')
  }

  const onChangeHandler = e => {
    if (e.target.value >= 1 && e.target.value <= 100) {
      setPageSize(e.target.value)
    } else if (e.target.value < 1) {
      setPageSize(5)
    } else if (e.target.value > 100) {
      setPageSize(100)
    } else if (e.target.value === '') {
      return
    }
  }
  return (
    <div className='paginate-container'>
      <div className='paginate-size'>
        <div className='paginate-size-text'>{t('paginate.itemsPerPage')}</div>
        <div className='paginate-size-selector'>
          <input type='text' value={pageSize} onChange={onChangeHandler} onInput={onInputHandler} />
        </div>
      </div>
      <div className='paginate-content' dir='ltr'>
        {pageSize * page + 1 !== totalItems ? pageSize * page + 1 + '-' : ''}
        {pageSize * (page + 1) > totalItems ? totalItems : pageSize * (page + 1)} of {totalItems}{' '}
      </div>
      <div className='paginate-btns-container'>
        <button
          onClick={() => {
            if (page > 0) backwardHandler()
          }}
          className={classNames('paginate-btn', { disabled: page === 0 })}
        >
          <i className='fas fa-chevron-right '></i>
        </button>
        <button
          onClick={() => {
            if (page + 1 < pages) forwardHandler()
          }}
          className={classNames('paginate-btn', {
            disabled: page + 1 === pages,
          })}
        >
          <i className='fas fa-chevron-left'></i>
        </button>
      </div>
      {process.env.REACT_APP_API_ENV === 'development' && (
        <div style={{ color: 'red', display: 'flex', gap: '10px' }}>
          <div>Page: {page + 1}</div>
          <div>Total: {pages}</div>
        </div>
      )}
    </div>
  )
}

export default PaginateIrreg
