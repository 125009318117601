import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { LANDING_INFO_SET } from '../../../../types/actionTypes'
import './LandingContacts.scss'
import { useState } from 'react'

const LandingContacts = () => {
  const [data, setData] = useState({
    fullName: '',
    mobilePhone: '',
    messageContent: '',
  })
  const { t } = useTranslation('landing')
  const dispatch = useDispatch()

  const setDataHandler = e => setData(prev => ({ ...prev, [e.target.name]: e.target.value }))

  const linkClickHandler = e => {
    dispatch({ type: LANDING_INFO_SET, payload: { ...data } })
  }

  return (
    <section className='landing-contacts' id='contacts'>
      <div className='landing-section-container'>
        <div className='landing-contacts-container'>
          <div className='landing-contacts-form-container'>
            <div className='landing-contacts-form-header'>{t('contacts.formHeader')}</div>
            <form className='landing-contacts-form'>
              <div className='landing-contacts-form-inputs'>
                <input
                  type='text'
                  name='fullName'
                  placeholder={t('contacts.inputName')}
                  value={data.fullName}
                  onChange={setDataHandler}
                />
                <input
                  type='tel'
                  name='mobilePhone'
                  placeholder={t('contacts.inputPhone')}
                  value={data.mobilePhone}
                  onChange={setDataHandler}
                />
                <textarea
                  name='messageContent'
                  placeholder={t('contacts.inputMessage')}
                  value={data.messageContent}
                  style={{ resize: 'none' }}
                  onChange={setDataHandler}
                />
              </div>
              <div className='landing-contacts-form-link'>
                <a href='#/early' onClick={linkClickHandler}>
                  {t('contacts.btnCaption')}
                </a>
              </div>
            </form>
          </div>
          <div className='landing-contacts-nav-container'>
            <nav className='landing-contacts-navbar'>
              <ul>
                <li>
                  <a href='#home' className='current'>
                    {t('navbar.home')}
                  </a>
                </li>
                <li>
                  <a href='/#marketing'>{t('navbar.marketing')}</a>
                </li>
                <li>
                  <a href='#contacts'>{t('navbar.contactUs')}</a>
                </li>
              </ul>
            </nav>
          </div>
          <div className='landing-contacts-logo-container'>
            <div>
              <img src='./assets/logo/Logo_full.svg' alt='AGent_logo' />
            </div>
            <div>
              איי.ג'נט
              <br />
              ט.ו.מ.ר תוכנה לביטוח
            </div>
            <div>
              <a href='mailto:info@ai-gentins.com'>info@ai-gentins.com</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LandingContacts
