import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import store from './app/store'
import { Provider } from 'react-redux'
import ThemeProvider from './HOCs/ThemeProvider'
import './i18n'
import { HashRouter } from 'react-router-dom'

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <ThemeProvider>
      <HashRouter>
        <App />
      </HashRouter>
    </ThemeProvider>
  </Provider>,
  // </React.StrictMode>,
  document.getElementById('root')
)
