import { useTranslation } from 'react-i18next'
import PoliciesInput from '../PoliciesInput/PoliciesInput'
import { Cancel } from '@mui/icons-material'
import { floatingNumbersOnly } from '../../../../services/inputs'
import './PoliciesInputRange.scss'

const inputIsValid = val => val === '' || floatingNumbersOnly(val)

const PoliciesInputRange = ({ range, name = 'Range', setRange }) => {
  const { t } = useTranslation('common')
  const setRangeHandler = (value, rangeType) => {
    setRange({
      ...range,
      [rangeType]: isNaN(value) || value === '' ? null : parseFloat(value),
    })
  }

  const clearRange = () => setRange({ from: null, to: null })

  return (
    <div className='polices-input-range-container'>
      <p>{name}</p>
      <div>
        <PoliciesInput
          placeholder={t('placeholders.from')}
          setValue={val => setRangeHandler(val, 'from')}
          value={range.from}
          inputIsValid={inputIsValid}
        />
        <p>-</p>
        <PoliciesInput
          placeholder={t('placeholders.to')}
          setValue={val => setRangeHandler(val, 'to')}
          value={range.to}
          inputIsValid={inputIsValid}
        />
        {((range.to !== null && range?.to?.length !== 0) || (range.from !== null && range?.from?.length !== 0)) && (
          <button onClick={clearRange}>
            <Cancel />
          </button>
        )}
      </div>
    </div>
  )
}

export default PoliciesInputRange
