import React from 'react'
import { useTranslation } from 'react-i18next'
import './LandingHero.scss'
import { hebrew } from '../../../../i18n'

const LandingHero = () => {
  const { t } = useTranslation('landing')
  return (
    <section
      className='landing-hero'
      id='hero'
      style={{
        backgroundImage: `url('assets/_landing/_hero/bg${hebrew() ? '' : '_en'}.png')`,
        backgroundPosition: `${hebrew() ? 'left' : 'right'} center`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
      }}
    >
      <div className='landing-section-container'>
        <div className='landing-hero-content'>
          <p className='landing-hero-pretitle'>{t('hero.preTitle')}</p>
          <h3 className='landing-hero-title'>{t('hero.title')}</h3>
          <p className='landing-hero-subtitle'>{t('hero.subTitle')}</p>
          <div className='landing-btns-container'>
            <a href='#/register'>{t('hero.link')}</a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LandingHero
