import classNames from 'classnames'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useLocation } from 'react-router-dom'
import ButtonsChangeStatus from '../../../../components/Common/ButtonsChangeStatus/ButtonsChangeStatus'
import Loader from '../../../../components/Common/Loader/Loader'
import ModalSidebar from '../../../../components/Common/ModalSidebar/ModalSidebar'
import ModalWithNote from '../../../../components/Common/ModalWithNote/ModalWithNote'
import NoData from '../../../../components/Common/NoData/NoData'
import PolicyStatusMarker from '../../../../components/Common/PolicyStatusMarker/PolicyStatusMarker'
import {
  POLICY_AGG_RESET,
  POLICY_HISTORY_RESET,
  POLICY_IRREG_RESET,
  POLICY_SET_CATEGORY_RESET,
  POLICY_TREAT_RESET,
} from '../../../../types/actionTypes'
import { customerTaskType } from '../../../../types/customerTaskTypes'
import { insuranceField, insuranceFieldType } from '../../../../types/insuranceField'
import { policyTableType } from '../../../../types/tablesTypes'
import AddUserModal from '../../../customer/components/AddUserModal/AddUserModal'
import CustomerActionModal from '../../../customer/components/CustomerActionModal/CustomerActionModal'
import CustomerPolicyHistory from '../../../customer/components/CustomerPolicyHistory/CustomerPolicyHistory'
import CustomerPolicyIrregularities from '../../../customer/components/CustomerPolicyIrregularities/CustomerPolicyIrregularities'
import CustomerPolicyPayments from '../../../customer/components/CustomerPolicyPayments/CustomerPolicyPayments'
import CustomerTablesNavbar from '../../../customer/components/CustomerTablesNavbar/CustomerTablesNavbar'
import { getAgents } from '../../../shared/actions/sharedActions'
import CategoriesSelector from '../../../shared/components/CategoriesSelector/CategoriesSelector'
import {
  getPolicyAgg,
  getPolicyHistory,
  getPolicyIrregularities,
  sendForTreat,
  setPoliciesCategory,
} from '../../actions/policyActions'
import './PolicyDetails.scss'

const PolicyDetails = ({ policy, notify, closeDetails }) => {
  const cancellationToken = useRef(null)
  const { t, i18n } = useTranslation('customer')
  const dispatch = useDispatch()
  const location = useLocation()

  const irregularities = useSelector(state => state.policy).irregularities.sort(
    (a, b) => new Date(b.fromMonth) - new Date(a.fromMonth)
  )
  const paymentsAgg = useSelector(state => state.policy).paymentsAgg.sort((a, b) => new Date(b.date) - new Date(a.date))
  const paymentsAggLoading = useSelector(state => state.policy).paymentsAggLoading
  const irregularitiesLoading = useSelector(state => state.policy).irregularitiesLoading
  const treatSuccess = useSelector(state => state.policy).treatSuccess
  const treatLoading = useSelector(state => state.policy).treatLoading
  const updateRequest = useSelector(state => state.policy).updateRequest
  const setCategoryLoading = useSelector(state => state.policy).setCategoryLoading
  const setCategorySuccess = useSelector(state => state.policy).setCategorySuccess

  const agents = useSelector(state => state.shared).agents
  const agentsLoading = useSelector(state => state.shared).agentsLoading
  const categories = useSelector(state => state.shared).categories

  const [showCategoriesSelector, setShowCategoriesSelector] = useState(false)
  const [newCategory, setNewCategory] = useState(null)
  const [showAddTreatUserModal, setShowAddTreatUserModal] = useState(false)
  const [showTreatNoteModal, setShowTreatNoteModal] = useState(false)
  const [showTreatActionModal, setShowTreatActionModal] = useState(false)
  const [user, setUser] = useState({ currAgent: 2 })
  const [policyCategories, setPolicyCategories] = useState([])
  const [currentTableType, setCurrentTableType] = useState(policyTableType.payments)

  const setCurrentTableHandler = table => {
    const tableType = table.type
    if (currentTableType !== tableType) {
      setCurrentTableType(tableType)
    }
  }

  const navbarItems = [
    {
      text: t('navbar.payments'),
      type: policyTableType.payments,
    },
    {
      text: t('navbar.policyHistory'),
      type: policyTableType.history,
    },
  ]

  const showCategoriesSelectorHandler = e => {
    e.stopPropagation()
    setShowCategoriesSelector(!showCategoriesSelector)
  }

  const updateCategoryHandler = (policiesIds, categoryId, updateAllRelatedPolicies) => {
    setNewCategory(categoryId)
    setShowCategoriesSelector(!showCategoriesSelector)
    const newCategory = categories.find(c => c.id === categoryId)
    const prevCategory = categories.find(c => c.id === policy.categoryId)
    const text = `ענף ${prevCategory?.name} שונה ל${newCategory.name}\n${
      updateAllRelatedPolicies ? 'לכל הפוליסות' : 'עבור פוליסה זו בלבד'
    }`
    const textEn = `The category ${prevCategory.englishName} changed to ${newCategory.englishName}\n${
      updateAllRelatedPolicies ? 'For all policies' : 'For this policy only'
    }`
    if (notify) {
      notify({
        text,
        textEn,
        action: () => dispatch(setPoliciesCategory(policiesIds, categoryId, updateAllRelatedPolicies)),
        cancelAction: () => !cancellationToken.current && setNewCategory(null),
        actionText: 'ביטול',
        actionTextEn: 'Cancel',
        id: policy.id.toString() + 'setCategory',
      })
    } else {
      dispatch(setPoliciesCategory(policiesIds, categoryId, updateAllRelatedPolicies))
    }
  }

  const sendPolicyForTreat = () => {
    dispatch({ type: POLICY_TREAT_RESET })
    setUser({})
    setShowAddTreatUserModal(true)
  }

  const addUser = newUser => {
    setUser({ ...user, ...newUser })
    setShowAddTreatUserModal(false)
    setShowTreatNoteModal(true)
    //setShowTreatActionModal(true)
  }

  const selectAction = actionId => {
    setUser({ ...user, actionId })
  }

  const sendForTreatHandler = note => {
    const agentId = user.id
    const policyId = policy.id
    const type = customerTaskType.policy
    const customerId = policy.clientId
    if (notify) {
      setShowTreatNoteModal(false)
      notify({
        text: `פוליסה הועברה לטיפול של ${user.name}`,
        textEn: `The policy was transferred to the treatment to ${user.name}`,
        action: () => dispatch(sendForTreat({ customerId, agentId, policyId, type, note })),
        actionText: 'ביטול',
        actionTextEn: 'Cancel',
        id: policy.id.toString() + agentId.toString() + 'setTreatNote',
      })
    } else {
      dispatch(sendForTreat({ customerId, agentId, policyId, type, note }))
    }
  }

  const requestData = useCallback(() => {
    switch (currentTableType) {
      case policyTableType.payments:
        dispatch(getPolicyIrregularities(policy.id))
        dispatch(getPolicyAgg(policy.id))
        break
      case policyTableType.history:
        dispatch(getPolicyHistory(policy.id))
        break
      default:
        break
    }
  }, [dispatch, currentTableType, policy.id])

  useEffect(() => {
    cancellationToken.current = false
    return () => {
      cancellationToken.current = true
    }
  }, [])

  useEffect(() => {
    requestData()
    return () => {
      switch (currentTableType) {
        case policyTableType.payments:
          dispatch({ type: POLICY_IRREG_RESET })
          dispatch({ type: POLICY_AGG_RESET })
          break
        case policyTableType.history:
          dispatch({ type: POLICY_HISTORY_RESET })
          break
        default:
          break
      }
    }
  }, [dispatch, currentTableType, requestData])

  useEffect(() => {
    if (updateRequest) requestData()
  }, [updateRequest, requestData])

  useEffect(() => {
    if (showAddTreatUserModal) {
      dispatch(getAgents())
    }
  }, [showAddTreatUserModal, dispatch])

  useEffect(() => {
    setPolicyCategories(
      policy?.fieldId === insuranceFieldType.lifeFinance
        ? categories.filter(c => c.fieldId === insuranceFieldType.lifeFinance)
        : categories.filter(c => c.fieldId !== insuranceFieldType.lifeFinance)
    )
  }, [categories, policy])

  useEffect(() => {
    if (setCategorySuccess) {
      dispatch({ type: POLICY_SET_CATEGORY_RESET })
      setShowCategoriesSelector(false)
    }
  }, [setCategorySuccess, dispatch])

  return (
    <ModalSidebar closeSidebar={closeDetails} width='1200px'>
      <div className='customer-policy-container'>
        <div className='customer-policy-details-container'>
          {policy.clientId && !location.pathname.includes('customers') && (
            <NavLink exact to={`/customers/${policy.clientId}`}>
              <div className='customer-policy-customer-link'>
                <div className='customer-policy-customer-link-text'>{t('tableHeadings.goToCustomer')}</div>
                <div className='customer-policy-customer-link-icon'>
                  <img src='./assets/arrow-right-common/Right.png' alt='back' className='img-icon' />
                </div>
              </div>
            </NavLink>
          )}
          <div className='customer-policy-details-heading'>
            <h3>{policy.policyNumber}</h3>
            <div>
              {Object.hasOwn(policy, 'active') && (
                <PolicyStatusMarker
                  active={policy.active}
                  irregular={policy.status ?? policy.irregularStatus}
                  size='md'
                />
              )}
            </div>
          </div>
          {policy?.clientName && !location.pathname.includes('customers') && (
            <div className='customer-policy-details'>
              <div>{t('tableHeadings.customer')}</div>
              <div>
                <span>{policy.clientName}</span>
              </div>
            </div>
          )}
          <div className='customer-policy-details'>
            <div>{t('tableHeadings.category')}</div>
            <div
              className={classNames('categories-selector', {
                selectable: categories.length > 0,
                opened: showCategoriesSelector,
              })}
              {...(categories.length > 0 &&
                !setCategoryLoading && {
                  onClick: showCategoriesSelectorHandler,
                })}
            >
              <div className='categories-selector-item'>
                {i18n.language === 'he'
                  ? policyCategories.find(c => c.id === (newCategory ?? policy.categoryId))?.name
                  : policyCategories.find(c => c.id === (newCategory ?? policy.categoryId))?.englishName}
              </div>
              {categories.length > 0 && (
                <div className='categories-selector-icon'>
                  <img src='./assets/dropdown-icon-light/dropdown-icon-light.png' alt='opn' />
                </div>
              )}
            </div>
            {showCategoriesSelector && (
              <CategoriesSelector
                policies={[policy]}
                closeSelector={() => setShowCategoriesSelector(false)}
                setPoliciesCategory={updateCategoryHandler}
              />
            )}
          </div>
          <div className='customer-policy-details'>
            <div>{t('tableHeadings.company')}</div>
            <div>{i18n.language === 'he' ? policy.companyName : policy.companyNameEn}</div>
          </div>
          <div className='customer-policy-details'>
            <div>{t('tableHeadings.field')}</div>
            <div>
              {i18n.language === 'he' ? insuranceField[policy.fieldId]?.name : insuranceField[policy.fieldId]?.nameEn}
            </div>
          </div>
          {policy.paymentStatus && (
            <div className='customer-policy-details'>
              <div>{t('tableHeadings.paymentStatus')}</div>
              <div>payment status</div>
            </div>
          )}
        </div>
        <CustomerTablesNavbar
          navbarItems={navbarItems}
          currentTableType={currentTableType}
          setCurrentTable={setCurrentTableHandler}
        />
        {currentTableType === policyTableType.payments && (
          <>
            <div className='customer-policy-btns-container'>
              <ButtonsChangeStatus treat={sendPolicyForTreat} />
            </div>
            <div className='customer-policy-payments-container'>
              {paymentsAggLoading || irregularitiesLoading ? (
                <Loader />
              ) : irregularities.length > 0 ||
                paymentsAgg.filter(agg => agg.commission || agg.premium || agg.accumulation).length > 0 ? (
                <div className='customer-policy-payments-list-container'>
                  {irregularities.length > 0 && (
                    <div className='customer-policy-payments-list'>
                      <CustomerPolicyIrregularities policy={policy} irregularities={irregularities} notify={notify} />
                    </div>
                  )}
                  {paymentsAgg.filter(agg => agg.commission || agg.premium || agg.accumulation).length !== 0 && (
                    <div className='customer-policy-payments-list'>
                      <CustomerPolicyPayments
                        policy={{ ...policy, monthAgg: paymentsAgg }}
                        monthAgg={paymentsAgg.filter(agg => agg.commission || agg.premium || agg.accumulation)}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <NoData text={t('policies.noPaymentsIrregularities')} />
              )}
            </div>
          </>
        )}
        {currentTableType === policyTableType.history && (
          <div className='customer-policy-history-container'>
            <CustomerPolicyHistory />
          </div>
        )}
        {showAddTreatUserModal && (
          <AddUserModal
            users={agents}
            loading={agentsLoading}
            closeModal={() => setShowAddTreatUserModal(false)}
            closeOnOutsideClick={false}
            onAddUser={addUser}
            currentUser={user?.currAgent}
            askConfirm={user?.currAgent}
          />
        )}
        {showTreatNoteModal && (
          <ModalWithNote
            closeModal={() => setShowTreatNoteModal(false)}
            closeOnOutsideClick={false}
            title={t('treatNoteModal.modalTitle')}
            subtitle={t('treatNoteModal.modalSubtitle')}
            label={t('treatNoteModal.inputLabel')}
            btnCaption={t('treatNoteModal.btnTitle')}
            back={() => setShowAddTreatUserModal(true)}
            onAddNote={sendForTreatHandler}
          />
        )}
        {showTreatActionModal && (
          <CustomerActionModal
            closeModal={() => setShowTreatActionModal(false)}
            closeOnOutsideClick={false}
            onSelectAction={selectAction}
            onSubmit={sendForTreatHandler}
            submitSuccess={treatSuccess}
            resetSuccess={() => dispatch({ type: POLICY_TREAT_RESET })}
            loading={treatLoading}
            currentAction={user?.actionId}
            back={() => setShowAddTreatUserModal(true)}
            agentName={user?.name}
          />
        )}
      </div>
    </ModalSidebar>
  )
}

export default PolicyDetails
