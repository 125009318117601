import { useTranslation } from 'react-i18next'
import Button from '../../../../components/Common/Button/Button'
import InputText from '../../../../components/Common/InputText/InputText'
import Loader from '../../../../components/Common/Loader/Loader'
import ModalContainer from '../../../../components/ModalContainer/ModalContainer'
import { useState } from 'react'
import './PolicyAddNewCustomerModal.scss'

const PolicyAddNewCustomerModal = ({ closeModal, policy, addNewCustomer, loading }) => {
  const { t } = useTranslation('policies')
  const [name, setName] = useState(policy?.clientName ?? '')
  const [idNumber, setIdNumber] = useState('')
  const [address, setAdress] = useState('')
  const [phone, setPhone] = useState('')

  const disabled = name.length === 0 || idNumber.length === 0
  const submitHandler = e => {
    e.preventDefault()
    addNewCustomer({ name, idNumber, address, phone })
  }

  return (
    <ModalContainer onOverlayClick={closeModal}>
      <div className='add-policy-customer-modal-container'>
        <div onClick={closeModal} className='add-policy-customer-modal-close-icon'>
          <img src='./assets/close-icon-sm/Close.png' alt='cls' className='img-icon' />
        </div>
        {loading && (
          <div className='add-policy-customer-modal-content-loader-overlay'>
            <Loader />
          </div>
        )}
        <div className='add-policy-customer-modal-content'>
          <div className='add-policy-customer-modal-title'>{t('addCustomerModal.title')}</div>
          <div className='add-policy-customer-modal-inputs-group-container'>
            <div className='add-policy-customer-modal-inputs-group-title'>{t('addCustomerModal.subtitle')}</div>
            <form action='' onSubmit={submitHandler}>
              <div className='add-policy-customer-modal-inputs-group'>
                <InputText
                  name='name'
                  id='name'
                  label={t('addCustomerModal.customerName')}
                  required
                  value={name}
                  onChange={setName}
                />
                <InputText
                  inputType='number'
                  name='idNumber'
                  id='idNumber'
                  label={t('addCustomerModal.customerId')}
                  value={idNumber}
                  onChange={setIdNumber}
                  required
                  showCharsCount
                  charsCount={9}
                />
                <InputText
                  name='address'
                  id='address'
                  label={t('addCustomerModal.customerAddress')}
                  value={address}
                  onChange={setAdress}
                />
                <InputText
                  type={'number'}
                  name='phone'
                  id='phone'
                  label={t('addCustomerModal.customerPhone')}
                  value={phone}
                  onChange={setPhone}
                />
              </div>
              <div className='add-policy-customer-modal-add-btn'>
                <Button caption={t('addCustomerModal.addBtn')} typeSubmit disabled={disabled} />
              </div>
            </form>
          </div>
        </div>
      </div>
    </ModalContainer>
  )
}

export default PolicyAddNewCustomerModal
