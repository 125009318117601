import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../../../components/Common/Button/Button'
import ModalWindow from '../../../../components/ModalWindow/ModalWindow'
import { customerTaskType } from '../../../../types/customerTaskTypes'
import './CustomerActionModal.scss'

const CustomerActionModal = ({
  onSelectAction,
  closeModal,
  closeOnOutsideClick,
  back,
  currentAction,
  agentName,
  onSubmit,
  submitSuccess,
  resetSuccess,
  loading,
}) => {
  const { t } = useTranslation('customer')
  const [selectedAction, setSelectedAction] = useState(currentAction)

  const modalTitle = t('customerActionModal.modalTitle')
  const modalSubtitle = t('customerActionModal.modalSubtitle', { agent: agentName })
  const btnCaption = t('customerActionModal.btnTitle')

  const actions = [
    {
      id: customerTaskType.examineProducts,
      title: t('customerActions.products'),
      icon: './assets/customer-actions/add-to-cart/Add  to cart.png',
      action: () => setSelectedAction(customerTaskType.examineProducts),
    },
    {
      id: customerTaskType.groupAffiliation,
      title: t('customerActions.groupMembers'),
      icon: './assets/customer-actions/group-members/3 User.png',
      action: () => setSelectedAction(customerTaskType.groupAffiliation),
    },
    {
      id: customerTaskType.externalCommission,
      title: t('customerActions.externalCommission'),
      icon: './assets/customer-actions/external-commission/price-tag.png',
      action: () => setSelectedAction(customerTaskType.externalCommission),
    },
    {
      id: customerTaskType.appointment,
      title: t('customerActions.appointment'),
      icon: './assets/customer-actions/calendar/calendar.png',
      action: () => setSelectedAction(customerTaskType.appointment),
    },
  ]

  const selectAction = id => {
    setSelectedAction(selectedAction !== id && id)
    onSelectAction(id)
  }

  const backHandler = () => {
    back()
    closeModal()
  }

  const closeModalHandler = () => {
    closeModal()
    resetSuccess && resetSuccess()
  }

  useEffect(() => {
    if (submitSuccess) {
      closeModal()
      return () => resetSuccess()
    }
  }, [submitSuccess, closeModal, resetSuccess])

  return (
    <ModalWindow closeModal={closeModalHandler} closeOnOutsideClick={closeOnOutsideClick}>
      <div className='customer-actions-modal-container'>
        <div className='customer-actions-modal-title'>{modalTitle}</div>
        <div className='customer-actions-modal-subtitle'>{modalSubtitle}</div>
        <div className='customer-actions-modal-actions'>
          {actions.map(act => (
            <div
              className={classNames('customer-actions-modal-action', { selected: selectedAction === act.id })}
              key={act.icon}
              onClick={() => selectAction(act.id)}
            >
              <div className='customer-actions-modal-action-icon'>
                <img src={act.icon} alt='' />
              </div>
              <div className='customer-actions-modal-action-title'>{act.title}</div>
            </div>
          ))}
        </div>
        <div className='customer-actions-modal-btns'>
          {back && (
            <button className='back-button' onClick={backHandler}>
              <img src='./assets/arrow-dropdown-right/Right Square.png' alt='bck' />
            </button>
          )}
          <Button caption={btnCaption} disabled={!selectedAction || loading} onClick={onSubmit} />
        </div>
      </div>
    </ModalWindow>
  )
}

export default CustomerActionModal
