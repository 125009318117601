import { AddRounded, FolderSharedRounded } from '@mui/icons-material'
import { getUserNameInitials } from '../../../../services/getUserNameInitials'
import { useState } from 'react'
import AgenciesGroupsPanel from '../AgenciesGroupsPanel/AgenciesGroupsPanel'
import { useSelector } from 'react-redux'
import './AgenciesGroupsSidebar.scss'
import classNames from 'classnames'
import AddAgencyGroupModal from '../../../../components/AddAgencyGroupModal/AddAgencyGroupModal'

const AgenciesGroupsSidebar = () => {
  const { favoriteGroups, allGroups, selectedGroups } = useSelector(({ agentHouse }) => agentHouse)
  const [showGroupsPanel, setShowGroupsPanel] = useState(false)
  const [showAddAgencyGroupModal, setShowAddAgencyGroupModal] = useState(false)

  const groups = [...allGroups.filter(gr => selectedGroups.includes(gr.id)), ...favoriteGroups]

  return (
    <>
      <div className='agencies-groups-sidebar'>
        <div>
          <button onClick={() => setShowGroupsPanel(!showGroupsPanel)}>
            <FolderSharedRounded />
          </button>
        </div>
        {groups.length > 0 && (
          <div>
            {groups.map(gr => (
              <AgenciesGroupsSidebarItem key={gr.id} group={gr} />
            ))}
          </div>
        )}
        <div>
          <button onClick={() => setShowAddAgencyGroupModal(true)}>
            <AddRounded />
          </button>
        </div>
      </div>
      {showGroupsPanel && <AgenciesGroupsPanel closePanel={() => setShowGroupsPanel(false)} />}
      {showAddAgencyGroupModal && <AddAgencyGroupModal closeModal={() => setShowAddAgencyGroupModal(false)} />}
    </>
  )
}

export default AgenciesGroupsSidebar

const AgenciesGroupsSidebarItem = ({ group }) => {
  const { selectedGroups } = useSelector(({ agentHouse }) => agentHouse)

  return (
    <div className={classNames('agencies-group-avatar-container', { selected: selectedGroups.includes(group.id) })}>
      {group.agencies.length > 1 ? (
        <>
          <div className='agencies-group-avatar-sm' style={{ background: group.agencies[1].backgroundColor }}>
            <span style={{ direction: 'ltr' }}>
              {group.agencies.length > 2
                ? `+${group.agencies.length - 2}`
                : getUserNameInitials(group.agencies[1]?.name)}
            </span>
          </div>
          <div className='agencies-group-avatar-sm' style={{ background: group.agencies[0].backgroundColor }}>
            {getUserNameInitials(group.agencies[0]?.name)}
          </div>
        </>
      ) : (
        <div className='agencies-group-avatar' style={{ background: group.agencies[0].backgroundColor }}>
          {getUserNameInitials(group.agencies[0].name)}
        </div>
      )}
    </div>
  )
}
