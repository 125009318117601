export const hideSidebar = () => {
  const sidebar = document.getElementsByClassName('sidebar')
  if (sidebar && sidebar.length > 0) {
    sidebar[0].classList.remove('mobile-open')
  }
}

export const showSidebar = () => {
  const sidebar = document.getElementsByClassName('sidebar')
  if (sidebar && sidebar.length > 0) {
    sidebar[0].classList.add('mobile-open')
  }
}

export const sidebarIsVisible = () => {
  const sidebar = document.getElementsByClassName('sidebar')
  if (sidebar && sidebar.length > 0) {
    return sidebar[0].classList.contains('mobile-open')
  }
  return false
}
