import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { formatAsCurrency, formatAsPercent } from '../../../../utils/formatAs'
import { customersFilterByGroup } from '../../../customers/actions/customersActions'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ArrowBack } from '@mui/icons-material'
import { hebrew } from '../../../../i18n'
import './AgencyHouseAnalyticsRatingCards.scss'
import { commissionKeySuffix, deltaCommissionSplitView } from '../../../shared/reducers/sharedSelectors'
import { isDeltaAgency } from '../../../login/reducers/loginSelectors'
import { deltaCommissionViewType } from '../../../../types/deltaCommissionViewTypes'

const AgencyHouseAnalyticsRatingCards = ({ cards, selectCard }) => {
  const { t } = useTranslation('agencyHouseAnalytics')
  const { commissionView } = useSelector(({ shared }) => shared)
  const history = useHistory()
  const dispatch = useDispatch()
  const distributionChartColors = [
    '#02b64a',
    'rgba(120, 192, 149, 1)',
    'rgba(200, 241, 217, 1)',
    'rgba(208, 117, 129, 1)',
    '#fb4659',
  ]
  const distributionChartTextColors = [
    'var(--text-primary-color)',
    null,
    null,
    'var(--text-primary-color)',
    'var(--text-primary-color)',
  ]

  const distributionCardClickHandler = groupIndex => {
    dispatch(customersFilterByGroup(groupIndex, true))
    history.push('/customers?type=distribution')
  }

  const getCommissionKeySuffix = () => (deltaCommissionSplitView() ? 'Agency' : commissionKeySuffix())
  const getMainCardName = () => {
    if (
      commissionView === deltaCommissionViewType.agency ||
      commissionView === deltaCommissionViewType.bothSideBySide
    ) {
      return t('agencyPrefix')
    }
    if (commissionView === deltaCommissionViewType.agent) {
      return t('agentPrefix')
    }
    if (commissionView === deltaCommissionViewType.bothTogether) {
      return t('agencyAgentPrefix')
    }
  }

  return (
    <div className='agency-house-analytics-cards'>
      {cards.map(card => (
        <div
          key={card.order}
          className={classNames('agency-house-analytics-card-wrapper', { selected: card.selected })}
          {...(!card.selected && { onClick: () => selectCard(card.order) })}
        >
          <div className='agency-house-analytics-card'>
            <header className='agency-house-analytics-card-chips'>
              <div
                className='agency-house-analytics-card-chip'
                style={{
                  background: distributionChartColors[card.order - 1],
                  color: distributionChartTextColors[card.order - 1],
                }}
              >
                {t('rank', { rank: card.order })}
              </div>
              <div className='agency-house-analytics-card-chip'>
                {t('customersCount', { customersCount: card.customersCount })}
              </div>
            </header>
            <div className='agency-house-analytics-card-content'>
              <div className='agency-house-analytics-card-main'>
                {isDeltaAgency() && <p className='agency-house-analytics-card-name'>{getMainCardName()}</p>}
                <div
                  className='agency-house-analytics-card-main-value'
                  style={{ textAlign: hebrew() ? 'end' : 'start' }}
                >
                  {formatAsCurrency(card[`total${getCommissionKeySuffix()}`], { dashesForZero: false })}
                </div>
                <p>{t('ratingTotalCommission')}</p>
              </div>
              <div className='agency-house-analytics-card-details'>
                <div>
                  <div
                    className='agency-house-analytics-card-details-value'
                    style={{ textAlign: hebrew() ? 'end' : 'start' }}
                  >
                    {formatAsPercent(card[`value${getCommissionKeySuffix()}`], { dashesForZero: false })}
                  </div>
                  <p>{t('ratingPercent')}</p>
                </div>
                <div>
                  <div
                    className='agency-house-analytics-card-details-value'
                    style={{ textAlign: hebrew() ? 'end' : 'start' }}
                  >
                    {formatAsCurrency(card[`avrgCommission${getCommissionKeySuffix()}`], { dashesForZero: false })}
                  </div>
                  <p>{t('ratingAverage')}</p>
                </div>
              </div>
            </div>
            {deltaCommissionSplitView() && (
              <div className='agency-house-analytics-card-content'>
                <div className='agency-house-analytics-card-main'>
                  <p className='agency-house-analytics-card-name'>{t('agentPrefix')}</p>
                  <div
                    className='agency-house-analytics-card-main-value'
                    style={{ textAlign: hebrew() ? 'end' : 'start' }}
                  >
                    {formatAsCurrency(card.total, { dashesForZero: false })}
                  </div>
                  <p>{t('ratingTotalCommission')}</p>
                </div>
                <div className='agency-house-analytics-card-details'>
                  <div>
                    <div
                      className='agency-house-analytics-card-details-value'
                      style={{ textAlign: hebrew() ? 'end' : 'start' }}
                    >
                      {formatAsPercent(card.value, { dashesForZero: false })}
                    </div>
                    <p>{t('ratingPercent')}</p>
                  </div>
                  <div>
                    <div
                      className='agency-house-analytics-card-details-value'
                      style={{ textAlign: hebrew() ? 'end' : 'start' }}
                    >
                      {formatAsCurrency(card.avrgCommission, { dashesForZero: false })}
                    </div>
                    <p>{t('ratingAverage')}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
          {card.selected && (
            <div className='agency-house-analytics-card-footer'>
              <button onClick={() => distributionCardClickHandler(card.order - 1)}>
                <span>{t('goToCustomers')}</span>
                <ArrowBack />
              </button>
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export default AgencyHouseAnalyticsRatingCards
