import classNames from 'classnames'
import React from 'react'
import './Tooltip.css'

const Tooltip = ({ title, content, position, size, textWidth }) => {
  return (
    <div
      className={classNames('tooltip-container', {
        [position]: position,
        [size]: size,
      })}
      {...(textWidth && { style: { whiteSpace: 'unset' } })}
    >
      {title && (
        <div className='tooltip-title' {...(textWidth && { style: { width: textWidth } })}>
          {title}
        </div>
      )}
      {/* <div className='tooltip-content'>{content}</div> */}
    </div>
  )
}

export default Tooltip
