export const customerPolicyListType = {
  all: 1,
  active: 2,
  new: 3,
  lost: 4,
  notActive: 5,
  irregular: 6,
}

export const customerPolicyList = {
  [customerPolicyListType.all]: {
    key: 'allPolicies',
    type: customerPolicyListType.all,
  },
  [customerPolicyListType.active]: {
    key: 'activePolicies',
    type: customerPolicyListType.active,
  },
  [customerPolicyListType.notActive]: {
    key: 'inactivePolicies',
    type: customerPolicyListType.notActive,
  },
  [customerPolicyListType.irregular]: {
    key: 'irregularPolicies',
    type: customerPolicyListType.irregular,
  },
}
