import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import InputSwitch from '../../../../components/Common/InputSwitch/InputSwitch'
import { updateNotificationsSettings } from '../../../../http/requests'
import { getProfileInfo } from '../../actions/profileActions'
import './ProfileSettings.scss'

const ProfileSettings = () => {
  const {
    details: { allowToSendNotifications },
  } = useSelector(state => state.profile)
  const dispatch = useDispatch()
  const [notificationsLoading, setNotificationsLoading] = useState(false)
  const { t } = useTranslation('profile')

  const updateNotificationsHandler = async () => {
    try {
      setNotificationsLoading(true)
      const result = await updateNotificationsSettings()
      if (result.data.Data) {
        dispatch(getProfileInfo())
      }
    } catch (error) {
      console.log(error)
    } finally {
      setNotificationsLoading(false)
    }
  }

  return (
    <div className='profile-settings-container'>
      <div className='profile-settings-toggle'>
        <label htmlFor={'notificationsToggle'}>{t('notifications.receiveNotifications')}</label>
        <InputSwitch
          id={'notificationsToggle'}
          checked={allowToSendNotifications}
          onChange={updateNotificationsHandler}
          value={t('notifications.receiveNotifications')}
          // disabled={notificationsLoading}
        />
      </div>
    </div>
  )
}

export default ProfileSettings
