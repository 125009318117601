import {createPortal} from 'react-dom'
import ModalWindow from '../ModalWindow/ModalWindow'
import './EarlyAccessSuccessModal.scss'
import { NavLink } from 'react-router-dom'

const EarlyAccessSuccessModal = ({closeModal}) => {
    return createPortal(
        <>
            <ModalWindow closeOnOutsideClick={false} showCloseIcon={false} closeModal={closeModal}>
                <div className='early-access-success-modal'>
                    <div className='early-access-success-icon'>
                        <img src='./assets/cards-pack/calendar.png' alt='calendar'/>
                    </div>
                    <div className='early-access-success-modal-container'>
                        <h3>פרטייך נשלחו בהצלחה!</h3>
                        <p>נציג מטעמינו יצרו איתך קשר בהקדם</p>
                        <NavLink to={"/"}>חזור לדף הבית</NavLink>
                    </div>
                </div>
            </ModalWindow>
        </>,
        document.body
    )
}

export default EarlyAccessSuccessModal
