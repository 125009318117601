import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import CustomersFilterDropdown from '../CustomersFilter/CustomersFilterDropdown'
import InputSwitch from '../../../../components/Common/InputSwitch/InputSwitch'
import { CUSTOMERS_FILTER_BY_ADVANCED } from '../../../../types/actionTypes'
import { rtlDir } from '../../../../i18n'
import './CustomersFilterAdvanced.scss'

const CustomersFilterAdvanced = () => {
  const {
    companiesFilter,
    categoriesFilter,
    advancedFiltersApplied,
    companiesAndFilter,
    categoriesAndFilter,
    companiesNoFilter,
    categoriesNoFilter,
  } = useSelector(({ customers }) => customers)
  const [showFilters, setShowFilters] = useState(false)
  const [companies, setCompanies] = useState(companiesFilter)
  const [companiesAnd, setCompaniesAnd] = useState(companiesAndFilter)
  const [companiesNo, setCompaniesNo] = useState(companiesNoFilter)
  const [categories, setCategories] = useState(categoriesFilter)
  const [categoriesAnd, setCategoriesAnd] = useState(categoriesAndFilter)
  const [categoriesNo, setCategoriesNo] = useState(categoriesNoFilter)
  const [applyAdvancedFilters, setApplyAdvancedFilters] = useState(false)
  const [appliedFiltersLength, setAppliedFiltersLength] = useState(0)
  const [filtersDirty, setFiltersDirty] = useState(false)
  const dispatch = useDispatch()
  const { t } = useTranslation('customers')
  const btnRef = useRef()

  const setFiltersHandler = setFilters => (id, checked) => {
    setFiltersDirty(true)
    setFilters(prev => prev.map(f => ({ ...f, checked: f.id === id ? checked : f.checked })))
  }

  const setAdvancedFiltersHandler = (filters, setFiltersChecked, setFiltersDisabled) => (id, checked) => {
    //debugger
    const appliedFiltersIds = filters.filter(f => f.checked && f.id !== id).map(({ id }) => id)
    setFiltersHandler(setFiltersChecked)(id, checked)
    setFiltersDisabled(prev =>
      prev.map(f => {
        const disabled = (f.id === id && checked) || appliedFiltersIds.includes(f.id)
        return { ...f, disabled, disabledDesc: disabled ? t('filters.alreadySelected') : null }
      })
    )
  }

  const getButtonPostition = () => {
    const { bottom, left, right } = btnRef?.current?.getBoundingClientRect()
    return { left, right, y: bottom }
  }

  const setApplyAdvancedFiltersHandler = () => {
    setFiltersDirty(true)
    setApplyAdvancedFilters(!applyAdvancedFilters)
  }

  const getAppliedFiltersLength = filters => filters.filter(f => f.checked).length

  const applyFilters = () => {
    filtersDirty &&
      dispatch({
        type: CUSTOMERS_FILTER_BY_ADVANCED,
        payload: {
          companiesFilter: companies,
          categoriesFilter: categories,
          companiesAndFilter: companiesAnd,
          categoriesAndFilter: categoriesAnd,
          companiesNoFilter: companiesNo,
          categoriesNoFilter: categoriesNo,
          advancedFiltersApplied: applyAdvancedFilters,
        },
      })
    setShowFilters(false)
  }

  useEffect(() => {
    setCompanies(companiesFilter)
    setCompaniesAnd(companiesAndFilter)
    setCompaniesNo(companiesNoFilter)
    setCategories(categoriesFilter)
    setCategoriesAnd(categoriesAndFilter)
    setCategoriesNo(categoriesNoFilter)
    setApplyAdvancedFilters(advancedFiltersApplied)
    const filtersLength = getAppliedFiltersLength(
      advancedFiltersApplied
        ? [...companiesAndFilter, ...categoriesAndFilter, ...companiesNoFilter, ...categoriesNoFilter]
        : [...companiesFilter, ...categoriesFilter]
    )
    setAppliedFiltersLength(filtersLength)
  }, [
    companiesFilter,
    categoriesFilter,
    companiesAndFilter,
    categoriesAndFilter,
    companiesNoFilter,
    categoriesNoFilter,
    advancedFiltersApplied,
    showFilters,
  ])

  return (
    <div className='customers-filter-advanced-container'>
      <div className='customers-filter-advanced-btn-container' ref={btnRef}>
        <button
          className={classNames('customers-filter-advanced-btn', {
            open: showFilters,
            'has-applied': appliedFiltersLength > 0,
          })}
          onClick={() => setShowFilters(!showFilters)}
        >
          <div className='customers-filter-advanced-btn-icon'>
            <img src='./assets/filter/filter.png' alt='fltr' />
          </div>
          {appliedFiltersLength > 0 && (
            <div className='customers-filter-advanced-btn-count'>{appliedFiltersLength}</div>
          )}
        </button>
      </div>
      {showFilters && (
        <div className='customers-filter-advanced-overlay'>
          <div
            className='customers-filter-advanced-filters-container'
            style={{
              top: getButtonPostition().y + 14 + 'px',
              ...(rtlDir()
                ? { left: getButtonPostition().left + 'px' }
                : { left: getButtonPostition().right + 'px', transform: 'translateX(-100%)' }),
            }}
          >
            <header>
              <p>{t('filters.advancedFilters')}</p>
              <div>
                <button onClick={() => setShowFilters(false)}>
                  <img src='./assets/close-icon-square/close-square.png' alt='cls' />
                </button>
              </div>
            </header>
            <main>
              <section>
                <div className='customers-filter-advanced-filters-row'>
                  <InputSwitch checked={!applyAdvancedFilters} onChange={setApplyAdvancedFiltersHandler} />
                  <p className={classNames({ disabled: applyAdvancedFilters })}>{t('filters.atLeastOne')}</p>
                  <div className='customers-filter-advanced-filters'>
                    <CustomersFilterDropdown
                      title={t('filters.categories')}
                      filterItems={categories ?? []}
                      setChecked={setFiltersHandler(setCategories)}
                      disabled={applyAdvancedFilters}
                    />
                    <CustomersFilterDropdown
                      title={t('filters.companies')}
                      filterItems={companies ?? []}
                      setChecked={setFiltersHandler(setCompanies)}
                      disabled={applyAdvancedFilters}
                    />
                  </div>
                </div>
              </section>
              <section>
                <div className='customers-filter-advanced-filters-row'>
                  <InputSwitch checked={applyAdvancedFilters} onChange={setApplyAdvancedFiltersHandler} />
                  <p className={classNames({ disabled: !applyAdvancedFilters })}>{t('filters.noOne')}</p>
                  <div className='customers-filter-advanced-filters'>
                    <CustomersFilterDropdown
                      title={t('filters.categories')}
                      filterItems={categoriesNo ?? []}
                      setChecked={setAdvancedFiltersHandler(categoriesNo, setCategoriesNo, setCategoriesAnd)}
                      disabled={!applyAdvancedFilters}
                    />
                    <CustomersFilterDropdown
                      title={t('filters.companies')}
                      filterItems={companiesNo ?? []}
                      setChecked={setAdvancedFiltersHandler(companiesNo, setCompaniesNo, setCompaniesAnd)}
                      disabled={!applyAdvancedFilters}
                    />
                  </div>
                </div>
                <div className='customers-filter-advanced-filters-row'>
                  <div></div>
                  <p className={classNames({ disabled: !applyAdvancedFilters })}>{t('filters.containEvery')}</p>
                  <div className='customers-filter-advanced-filters'>
                    <CustomersFilterDropdown
                      title={t('filters.categories')}
                      filterItems={categoriesAnd ?? []}
                      setChecked={setAdvancedFiltersHandler(categoriesAnd, setCategoriesAnd, setCategoriesNo)}
                      disabled={!applyAdvancedFilters}
                    />
                    <CustomersFilterDropdown
                      title={t('filters.companies')}
                      filterItems={companiesAnd ?? []}
                      setChecked={setAdvancedFiltersHandler(companiesAnd, setCompaniesAnd, setCompaniesNo)}
                      disabled={!applyAdvancedFilters}
                    />
                  </div>
                </div>
              </section>
            </main>
            <footer>
              <div>
                <button
                  onClick={applyFilters}
                  disabled={
                    getAppliedFiltersLength(
                      applyAdvancedFilters
                        ? [...companiesAnd, ...categoriesAnd, ...companiesNo, ...categoriesNo]
                        : [...companies, ...categories]
                    ) === 0
                  }
                >
                  {t('filters.applyBtn')}
                </button>
              </div>
            </footer>
          </div>
        </div>
      )}
    </div>
  )
}

export default CustomersFilterAdvanced
