import $api from '..'
import { urlTreatment } from '../../environment/urls'

export const addTreatmentRequest = async ({ customerId, agentId, policyId, irregularityId, note, type }) => {
  const treatDto = {
    agentId: agentId || null,
    policyId: policyId || null,
    irregularityId: irregularityId || null,
    customerId: customerId || null,
    note: note?.toString() || null,
    type: +type || null,
  }
  return await $api.put(`${urlTreatment()}add`, treatDto)
}

export const addTreatmentBatchRequest = async treatments => await $api.put(`${urlTreatment()}add-batch`, treatments)

export const fixIrregularityRequest = async ({ irregularityId, note }) =>
  await $api.post(`${urlTreatment()}schedule-irregularity-treatment`, { irregularityId, note })
