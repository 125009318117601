import { useTranslation } from 'react-i18next'
import Button from '../../../../components/Common/Button/Button'
import ModalWindow from '../../../../components/ModalWindow/ModalWindow'
import './CategoriesConfirmModal.scss'
import { hebrew } from '../../../../i18n'

const CategoriesConfirmModal = ({
  closeModal,
  closeOnOutsideClick = false,
  categories,
  newCategoryId,
  updateAllRelatedPoliciesCategory,
  updatePolicyCategory,
  loading,
}) => {
  const { t } = useTranslation('common')
  const newCategory = categories.find(c => c.id === newCategoryId)

  return (
    <ModalWindow
      closeModal={() => {
        if (!loading) {
          closeModal()
        }
      }}
      closeOnOutsideClick={closeOnOutsideClick}
    >
      <div className='modal-category-wrapper'>
        <div className='modal-category-title'>{t('changeCategoryModal.title')}</div>
        <div className='modal-category-text'>
          {t('changeCategoryModal.text', {
            newCategory: hebrew() ? newCategory.name : newCategory.englishName,
          })}
        </div>
        <div className='modal-category-btns'>
          <Button
            caption={t('changeCategoryModal.btnAll')}
            onClick={updateAllRelatedPoliciesCategory}
            disabled={loading}
          />
          <Button caption={t(`changeCategoryModal.btnSelected`)} onClick={updatePolicyCategory} disabled={loading} />
        </div>
      </div>
    </ModalWindow>
  )
}

export default CategoriesConfirmModal
