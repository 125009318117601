import './TasksSearchButtonMobile.scss'

const TasksSearchButtonMobile = () => {
  return (
    <button className='tasks-search-button-mobile'>
      <img src={`./assets/loupe-icon/loupe.png`} className='img-icon' alt='search' />
    </button>
  )
}

export default TasksSearchButtonMobile
