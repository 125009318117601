export const deltaCommissionViewType = {
  agency: 1,
  agent: 2,
  bothTogether: 3,
  bothSideBySide: 4,
}

export const deltaCommissionView = {
  [deltaCommissionViewType.agency]: {
    type: deltaCommissionViewType.agency,
    name: 'עמלת סוכנות בלבד',
    nameEn: 'Agency Commission Only',
    abbr: 'עמלת סוכנות בלבד',
    abbrEn: 'Agency Comm. Only',
  },
  [deltaCommissionViewType.agent]: {
    type: deltaCommissionViewType.agent,
    name: 'עמלת סוכני משנה בלבד',
    nameEn: 'Agents Commission Only',
    abbr: 'עמלת סוכני משנה בלבד',
    abbrEn: 'Agents Comm. Only',
  },
  [deltaCommissionViewType.bothTogether]: {
    type: deltaCommissionViewType.bothTogether,
    name: 'סה"כ ביחד',
    nameEn: 'Both Together',
    abbr: 'סה"כ ביחד',
    abbrEn: 'Both Comm.',
  },
  [deltaCommissionViewType.bothSideBySide]: {
    type: deltaCommissionViewType.bothSideBySide,
    name: 'מצב השוואה',
    nameEn: 'Both Side by Side',
    abbr: 'מצב השוואה',
    abbrEn: 'Side by Side Comm.',
  },
}
