import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import Loader from '../../../../components/Common/Loader/Loader'
import AgencyHouseLobbySignalCard from './AgencyHouseLobbySignalCard'
import './AgencyHouseLobbySignals.scss'
import { agencyType } from '../../../../types/agencyTypes'
import { useDispatch, useSelector } from 'react-redux'
import { POLICIES_SIGNALS_FILTER_APPLY } from '../../../../types/actionTypes'
import { irregularSignal } from '../../../../types/irregularSignals'
import { useHistory } from 'react-router'
import { agencyRoutes } from '../../../../environment/urls'

const AgencyHouseLobbySignals = ({ signals, signalsLoading }) => {
  const ref = useRef()
  const { t } = useTranslation('agencyHouseLobby')
  const history = useHistory()
  const { AgencyType: loggedInAgencyType = agencyType.regular } = useSelector(({ login }) => login)

  const regularAgency = loggedInAgencyType === agencyType.regular

  const dispatch = useDispatch()

  useEffect(() => {
    ref.current.scrollIntoView()
  }, [])

  return (
    <div className='agency-house-lobby-signals' ref={ref}>
      <header>
        <h5>{t('signalsHeader')}</h5>
      </header>
      <section>
        {signalsLoading ? (
          <Loader />
        ) : Object.entries(signals).length === 0 ? (
          <div className='no-signals-data'>{t('noSignals')}</div>
        ) : (
          Object.entries(signals).map(([k, s]) => (
            <AgencyHouseLobbySignalCard
              key={k}
              signalKey={k}
              count={s.count}
              value={s.value}
              {...(regularAgency &&
                Object.values(irregularSignal).find(s => s.key === k).irregularReasons && {
                  onCardClick: () => {
                    dispatch({
                      type: POLICIES_SIGNALS_FILTER_APPLY,
                      payload: { signalId: Object.values(irregularSignal).find(s => s.key === k).id, checked: true },
                    })
                    history.push(`/${agencyRoutes.policies}?type=signals`)
                  },
                })}
            />
          ))
        )}
      </section>
    </div>
  )
}

export default AgencyHouseLobbySignals
