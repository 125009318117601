import WorkstationHeaderFull from '../WorkstationHeaderFull/WorkstationHeaderFull'
import WorkstationHeaderMobile from '../WorkstationHeaderMobile/WorkstationHeaderMobile'
import './WorkstationHeader.scss'

const WorkstationHeader = () => {
  return (
    <>
      <WorkstationHeaderFull />
      <WorkstationHeaderMobile />
    </>
  )
}

export default WorkstationHeader
