import { NavLink } from 'react-router-dom'
import './ExportButton.css'

const ExportButton = ({ onClick = () => {}, link }) => {
  return (
    <>
      {link ? (
        <NavLink to={link} className='export-btn'>
          <img src='./assets/export/Export.png' alt='export' className='export-btn img-icon' onClick={onClick} />
        </NavLink>
      ) : (
        <img src='./assets/export/Export.png' alt='export' className='export-btn img-icon' onClick={onClick} />
      )}
    </>
  )
}

export default ExportButton
