import StepDetails from '../StepDetails/StepDetails'
import StepPhoneNumber from '../StepPhoneNumber/StepPhoneNumber'
import StepPhoneSms from '../StepPhoneSms/StepPhoneSms'
import StepCompanies from '../StepCompanies/StepCompanies'
import StepFields from '../StepFields/StepFields'
import StepPaymentOptions from '../StepPaymentOptions/StepPaymentOptions'
import { registerStep } from '../../../../types/registerStep'
import RegisterFlowType from '../RegisterFlowType/RegisterFlowType'
import StepAgentHouseDetails from '../StepAgentHouseDetails/StepAgentHouseDetails'
import './RegisterStep.scss'

const RegisterStep = ({ step, setStep, data, setData, setHideRegisterStep }) => {
  const nextStep = () => {
    setStep(step + 1)
  }
  const prevStep = () => setStep(step - 1)

  const setAgencyType = agencyType => setData(prev => ({ ...prev, agencyType }))

  const currentStep = () => {
    switch (step) {
      case registerStep.twoFactorSms:
        return <StepPhoneSms nextStep={nextStep} data={data} setData={setData} prevStep={prevStep} />
      case registerStep.registerFlowType:
        return <RegisterFlowType setStep={setStep} setAgencyType={setAgencyType} />
      case registerStep.agentDetails:
        return <StepDetails nextStep={nextStep} data={data} setData={setData} prevStep={prevStep} />
      case registerStep.fields:
        return <StepFields nextStep={nextStep} data={data} setData={setData} />
      case registerStep.companies:
        return <StepCompanies nextStep={nextStep} data={data} setData={setData} prevStep={prevStep} />
      case registerStep.payment:
        return <StepPaymentOptions prevStep={prevStep} data={data} setHideRegisterStep={setHideRegisterStep} />
      case registerStep.agentHouseDetails:
        return (
          <StepAgentHouseDetails
            prevStep={() => setStep(registerStep.registerFlowType)}
            data={data}
            setData={setData}
          />
        )
      case registerStep.twoFactor:
      default:
        return <StepPhoneNumber nextStep={nextStep} data={data} setData={setData} />
    }
  }

  return currentStep()
}

export default RegisterStep
