import { useTranslation } from 'react-i18next'
import { getAgencyAnalyticsCustomersRatingRequest } from '../../../../http/requests/agencyAnalyticsRequests'
import { getAgencyHouseAnalyticsCustomersRatingRequest } from '../../../../http/requests/agencyHouseRequests'
import { isDeltaAgency, isRegularOrDeltaAgency } from '../../../login/reducers/loginSelectors'
import { useEffect, useState } from 'react'
import AgencyHouseAnalyticsRatingChart from '../AgencyHouseAnalyticsRatingChart/AgencyHouseAnalyticsRatingChart'
import AgencyHouseAnalyticsRatingCards from '../AgencyHouseAnalyticsRatingCards/AgencyHouseAnalyticsRatingCards'
import { lowercaseObjectKeys } from '../../../../utils/objectUtils'
import Loader from '../../../../components/Common/Loader/Loader'
import NoData from '../../../../components/Common/NoData/NoData'
import {
  commissionKeySuffix,
  deltaCommissionSplitView,
  familyGroupMode,
} from '../../../shared/reducers/sharedSelectors'
import FamilyGroupsToggle from '../../../../components/FamilyGroupsToggle/FamilyGroupsToggle'
import { useSelector } from 'react-redux'
import './AgencyHouseAnalyticsRating.scss'
import { deltaCommissionViewType } from '../../../../types/deltaCommissionViewTypes'

const AgencyHouseAnalyticsRating = () => {
  const [rating, setRating] = useState({ customers: [], familyGroups: [] })
  const [currRating, setCurrRating] = useState(familyGroupMode() ? 'familyGroups' : 'customers')
  const [ratingLoading, setRatingLoading] = useState(true)
  const { t } = useTranslation('agencyHouseAnalytics')
  const { familyGroupsType, selectedGroupsIds, commissionView } = useSelector(({ shared }) => shared)

  const distributionChartColors = [
    '#02b64a',
    'rgba(120, 192, 149, 1)',
    'rgba(200, 241, 217, 1)',
    'rgba(208, 117, 129, 1)',
    '#fb4659',
  ]

  useEffect(() => {
    const fetchRating = async () => {
      try {
        setRating([])
        setRatingLoading(true)
        const request = isRegularOrDeltaAgency()
          ? getAgencyAnalyticsCustomersRatingRequest
          : getAgencyHouseAnalyticsCustomersRatingRequest
        const { data: resp } = await request()

        setRating(lowercaseObjectKeys(resp.Data ?? resp.data))
        setRatingLoading(false)
      } catch (error) {
        setRatingLoading(false)
      }
    }
    fetchRating()
  }, [selectedGroupsIds])

  useEffect(() => {
    setCurrRating(familyGroupMode() ? 'familyGroups' : 'customers')
  }, [familyGroupsType])

  const getMainCardName = () => {
    if (
      commissionView === deltaCommissionViewType.agency ||
      commissionView === deltaCommissionViewType.bothSideBySide
    ) {
      return t('agencyPrefix')
    }
    if (commissionView === deltaCommissionViewType.agent) {
      return t('agentPrefix')
    }
    if (commissionView === deltaCommissionViewType.bothTogether) {
      return t('agencyAgentPrefix')
    }
  }

  const getCommissionKeySuffix = () => (deltaCommissionSplitView() ? 'Agency' : commissionKeySuffix())

  return (
    <div className='agency-house-analytics-rating'>
      <div>
        <FamilyGroupsToggle />
      </div>
      <h5>{t('ratingHeader')}</h5>
      <p>{t('ratingDesc')}</p>
      <main>
        {ratingLoading ? (
          <Loader />
        ) : rating[currRating].length === 0 ? (
          <NoData text='No Data' />
        ) : (
          <>
            <div className='agency-house-analytics-charts'>
              <div>
                {isDeltaAgency() && <p>{getMainCardName()}</p>}
                <AgencyHouseAnalyticsRatingChart
                  series={rating[currRating].map(r => r[`value${getCommissionKeySuffix()}`])}
                  labels={rating[currRating].map(r => t('rank', { rank: r.order }))}
                  colors={distributionChartColors}
                />
              </div>
              {deltaCommissionSplitView() && (
                <div>
                  <p>{t('agentPrefix')}</p>
                  <AgencyHouseAnalyticsRatingChart
                    series={rating[currRating].map(r => r.value)}
                    labels={rating[currRating].map(r => t('rank', { rank: r.order }))}
                    colors={distributionChartColors}
                  />
                </div>
              )}
            </div>
            <AgencyHouseAnalyticsRatingCards
              cards={rating[currRating]}
              selectCard={order =>
                setRating(prev => ({
                  ...prev,
                  [currRating]: prev[currRating].map(r => ({ ...r, selected: r.order === order })),
                }))
              }
            />
          </>
        )}
      </main>
    </div>
  )
}

export default AgencyHouseAnalyticsRating
