import { useTranslation } from 'react-i18next'
import { registerFlowType, registerStep } from '../../../../types/registerStep'
import RegisterTermsFooter from '../RegisterTermsFooter/RegisterTermsFooter'
import RegisterFlowTypeCard from '../RegisterFlowTypeCard/RegisterFlowTypeCard'
import './RegisterFlowType.scss'
import { agencyType } from '../../../../types/agencyTypes'

const flowTypes = [
  // {
  //   type: registerFlowType.agentHouse,
  //   featuresCount: 1,
  // },
  {
    type: registerFlowType.regular,
    featuresCount: 2,
  },
  {
    type: registerFlowType.deltaAgency,
    featuresCount: 3,
  },
]

const RegisterFlowType = ({ setStep, setAgencyType }) => {
  const { t } = useTranslation('registration')

  const selectFlowType = type => {
    const bigAgencyFlow = type === registerFlowType.agentHouse
    const nextStep = bigAgencyFlow ? registerStep.agentHouseDetails : registerStep.agentDetails
    if (!bigAgencyFlow) {
      const agencyTypeToSet =
        type === registerFlowType.deltaAgency
          ? agencyType.delta
          : type === registerFlowType.regular
          ? agencyType.regular
          : null
      setAgencyType(agencyTypeToSet)
    }
    setStep(nextStep)
  }

  return (
    <section className='register-flow-types'>
      <h2>{t('stepFlowType.title')}</h2>
      <p>{t('stepFlowType.desc')}</p>
      <div className='register-flow-types-cards'>
        {flowTypes.map(({ type, featuresCount }) => (
          <RegisterFlowTypeCard
            flowType={type}
            featuresCount={featuresCount}
            key={type}
            selectFlowType={selectFlowType}
          />
        ))}
      </div>
      <RegisterTermsFooter />
    </section>
  )
}
export default RegisterFlowType
