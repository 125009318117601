import React from 'react'
import { useTranslation } from 'react-i18next'
import './LandingIrregs.scss'
import { hebrew } from '../../../../i18n'

const LandingIrregs = () => {
  const { t } = useTranslation('landing')
  return (
    <section
      className='landing-irregs'
      id='preferences'
      style={{
        backgroundImage: `url('assets/_landing/_irregs/bg.png'), url('assets/_landing/_irregs/image${
          hebrew() ? '' : '_en'
        }.png')`,
        backgroundPosition: `${hebrew() ? 'left' : 'right'} center`,
        backgroundRepeat: 'no-repeat, no-repeat',
        backgroundSize: 'auto, 50%',
      }}
    >
      <div className='landing-section-container'>
        <div className='landing-irregs-desc'>
          <p className='landing-irregs-tagline'> {t('irregs.tagline')}</p>
          <h5 className='landing-irregs-title'>{t('irregs.title')}</h5>
          <p className='landing-irregs-subtitle'>{t('irregs.subTitle')}</p>

          <div className='landing-irregs-cards'>
            <div className='landing-irregs-card'>
              <div className='landing-irregs-card-icon'>
                <img src='./assets/_landing/_irregs/1.png' alt='1' />
              </div>
              <div className='landing-irregs-card-content'>
                <div className='landing-irregs-card-title'>{t('irregs.card1Title')}</div>
                <div className='landing-irregs-card-text'>{t('irregs.card1Text')}</div>
              </div>
            </div>
            <div className='landing-irregs-card'>
              <div className='landing-irregs-card-icon'>
                <img src='./assets/_landing/_irregs/2.png' alt='2' />
              </div>
              <div className='landing-irregs-card-content'>
                <div className='landing-irregs-card-title'>{t('irregs.card2Title')}</div>
                <div className='landing-irregs-card-text'>{t('irregs.card2Text')}</div>
              </div>
            </div>
            <div className='landing-irregs-card'>
              <div className='landing-irregs-card-icon'>
                <img src='./assets/_landing/_irregs/3.png' alt='3' />
              </div>
              <div className='landing-irregs-card-content'>
                <div className='landing-irregs-card-title'>{t('irregs.card3Title')}</div>
                <div className='landing-irregs-card-text'>{t('irregs.card3Text')}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LandingIrregs
