import { CloseRounded } from '@mui/icons-material'
import ModalWindow from '../ModalWindow/ModalWindow'
import './ReportsDownloadListModal.scss'
import { useTranslation } from 'react-i18next'
import { Download } from '@mui/icons-material'

const ReportsDownloadListItem = ({ report, downloadItem }) => {
  const { t } = useTranslation('download')
  return (
    <div className='reports-download-list-item'>
      <p>{report.name}</p>
      <button className={'download-button'} onClick={() => downloadItem(report.id, report.name)}>
        <span>{t('download')}</span>
        <Download />
      </button>
    </div>
  )
}

const ReportsDownloadListModal = ({ closeModal, reports = [], downloadReport }) => {
  const { t } = useTranslation('download')
  const modalHeader = t('reportsDownloadList.modalHeader')
  const listHeader = t('reportsDownloadList.listHeader')
  return (
    <ModalWindow renderPortal showCloseIcon={false}>
      <div className='reports-download-list-modal'>
        <header>
          <h4>{modalHeader}</h4>
          <button onClick={closeModal}>
            <CloseRounded />
          </button>
        </header>
        <p>{listHeader}</p>
        <main>
          {reports.map(r => (
            <ReportsDownloadListItem key={r.id} report={r} downloadItem={downloadReport} />
          ))}
        </main>
      </div>
    </ModalWindow>
  )
}

export default ReportsDownloadListModal
