import $api, { getCancelToken } from '..'
import { urlPolicies, urlPolicy } from '../../environment/urls'
import { delay } from '../../services/delay'
import { getRandomBoolean, getRandomInt } from '../../services/getRandom'

export const getAllPoliciesRequest = async ({
  customerName,
  customerIdNumber,
  policyNumber,
  policyTypes,
  companies = [],
  categories = [],
  policyStartDates,
  policyEndDates,
  policyFirstTimeInReportsDates,
  anyMonthInReportsDates,
  irregularityStartDate,
  commissionRange,
  premiumRange,
  accumulationRange,
  commissionRateByPremiumRange,
  commissionRateByAccumulationRange,
  statuses,
  tabType,
  agents,
  pageSize = 50,
  pageNumber = 1,
  sortBy,
  descending,
} = {}) =>
  $api.post(
    `${urlPolicies()}policies-list`,
    {
      clientName: customerName,
      clientIdNumber: customerIdNumber,
      policyNumber,
      policyTypes,
      companies,
      categories,
      policyStartDates,
      policyEndDates,
      policyFirstTimeInReportsDates,
      anyMonthInReportsDates,
      irregularityStartDate,
      commissionRange,
      premiumRange,
      accumulationRange,
      commissionRateByPremiumRange,
      commissionRateByAccumulationRange,
      statuses,
      tabType,
      agents,
      pageSize,
      pageNumber,
      sortBy,
      descending,
    },
    getCancelToken('getAllPoliciesRequest' + tabType)
  )

export const getSpecialPaymentsRequest = async ({
  paymentIdentifierNumber,
  paymentName,
  companies = [],
  categories = [],
  commissionRange,
  pageSize = 10,
  pageNumber = 1,
  sortBy,
  descending,
} = {}) =>
  $api.post(
    `${urlPolicies()}special-payments-list`,
    {
      paymentIdentifierNumber,
      paymentName,
      companies,
      categories,
      commissionRange,
      pageSize,
      pageNumber,
      sortBy,
      descending,
    },
    getCancelToken('getSpecialPaymentsRequest')
  )

export const getPolicyCardRequest = async id => $api.get(`${urlPolicies()}get-policy-info/${id}`)

export const getNewPoliciesRequest = async ({
  customerName,
  customerIdNumber,
  policyNumber,
  policyTypes,
  companies = [],
  categories = [],
  policyStartDates,
  policyEndDates,
  policyFirstTimeInReportsDates,
  anyMonthInReportsDates,
  irregularityStartDate,
  commissionRange,
  premiumRange,
  accumulationRange,
  commissionRateByPremiumRange,
  commissionRateByAccumulationRange,
  statuses,
  tabType,
  agents,
  pageSize = 50,
  pageNumber = 1,
  sortBy,
  descending,
} = {}) =>
  $api.post(
    `${urlPolicies()}get-new-sales`,
    {
      clientName: customerName,
      clientIdNumber: customerIdNumber,
      policyNumber,
      companies,
      categories,
      policyTypes,
      policyStartDates,
      policyEndDates,
      policyFirstTimeInReportsDates,
      anyMonthInReportsDates,
      irregularityStartDate,
      commissionRange,
      premiumRange,
      accumulationRange,
      commissionRateByPremiumRange,
      commissionRateByAccumulationRange,
      statuses,
      tabType,
      agents,
      pageSize,
      pageNumber,
      sortBy,
      descending,
    },
    getCancelToken('getNewPoliciesRequest' + tabType)
  )

export const getLostPoliciesRequest = async ({
  customerName,
  customerIdNumber,
  policyNumber,
  policyTypes,
  companies = [],
  categories = [],
  policyStartDates,
  policyEndDates,
  policyFirstTimeInReportsDates,
  anyMonthInReportsDates,
  irregularityStartDate,
  commissionRange,
  premiumRange,
  accumulationRange,
  commissionRateByPremiumRange,
  commissionRateByAccumulationRange,
  statuses,
  tabType,
  agents,
  pageSize = 50,
  pageNumber = 1,
  sortBy,
  descending,
} = {}) =>
  $api.post(
    `${urlPolicies()}get-lost-policies`,
    {
      clientName: customerName,
      clientIdNumber: customerIdNumber,
      policyNumber,
      policyTypes,
      companies,
      categories,
      policyStartDates,
      policyEndDates,
      policyFirstTimeInReportsDates,
      anyMonthInReportsDates,
      irregularityStartDate,
      commissionRange,
      premiumRange,
      accumulationRange,
      commissionRateByPremiumRange,
      commissionRateByAccumulationRange,
      statuses,
      tabType,
      agents,
      pageSize,
      pageNumber,
      sortBy,
      descending,
    },
    getCancelToken('getLostPoliciesRequest' + tabType)
  )

export const exportPoliciesByRankByGroupRequest = async ({ rankFilter = [], groupsFilter = [], ...filters } = {}) =>
  await $api
    .post(`${urlPolicy()}export-policies-by-rank`, { rankFilter, groupsFilter, ...filters }, { responseType: 'blob' })
    .then(response => {
      const blob = response.data
      let filename = response.headers['content-disposition']
        .split(';')
        .find(n => n.includes('filename='))
        .replace('filename=', '')
        .trim()
      const link = document.createElement('a')
      const url = URL.createObjectURL(blob)
      link.href = url
      link.download = filename
      link.click()
      link.remove()
    })
    .catch(response => {
      console.error('Could not Download the Excel report from the backend.', response)
    })

export const getPoliciesSignalsRequest = async ({
  customerName,
  customerIdNumber,
  policyNumber,
  policyTypes = [],
  companies = [],
  categories = [],
  months = [],
  commissionRange,
  premiumRange,
  accumulationRange,
  commissionRateByPremiumRange,
  commissionRateByAccumulationRange,
  signalType,
  pageSize = 50,
  pageNumber,
  sortBy,
  descending,
} = {}) =>
  $api.post(
    `${urlPolicies()}get-irregular-policies-list`,
    {
      clientName: customerName,
      clientIdNumber: customerIdNumber,
      policyNumber: policyNumber,
      PolicyTypeIds: policyTypes,
      categories,
      companies,
      months,
      commissionRange,
      premiumRange,
      accumulationRange,
      commissionRateByPremiumRange,
      commissionRateByAccumulationRange,
      signalType,
      pageSize,
      pageNumber,
      sortBy,
      descending,
    },
    getCancelToken('getPoliciesSignalsRequest' + signalType)
  )

export const addNewCustomerToPoliciesRequest = async ({ policies, name, idNumber, address, phone }) => {
  return await $api.post(`${urlPolicies()}add-new-customer`, {
    policies,
    name,
    idNumber,
    address,
    phone,
  })
}

export const addExistingCustomerToPoliciesRequest = async ({ policies, id }) => {
  return await $api.post(`${urlPolicies()}add-existing-customer`, {
    policies,
    id,
  })
}

export const exportPoliciesFile = async ({
  customerName,
  customerIdNumber,
  policyNumber,
  policyTypes,
  companies = [],
  categories = [],
  policyStartDates,
  policyEndDates,
  policyFirstTimeInReportsDates,
  anyMonthInReportsDates,
  irregularityStartDate,
  commissionRange,
  premiumRange,
  accumulationRange,
  commissionRateByPremiumRange,
  commissionRateByAccumulationRange,
  statuses,
  listType,
  agents,
  tabType,
  commissionView,
}) => {
  await $api
    .post(
      `${urlPolicies()}export-policies`,
      {
        clientName: customerName,
        clientIdNumber: customerIdNumber,
        policyNumber,
        policyTypes,
        companies,
        categories,
        policyStartDates,
        policyEndDates,
        policyFirstTimeInReportsDates,
        anyMonthInReportsDates,
        irregularityStartDate,
        commissionRange,
        premiumRange,
        accumulationRange,
        commissionRateByPremiumRange,
        commissionRateByAccumulationRange,
        statuses,
        agents,
        listType,
        tabType,
        deltaViewType: commissionView,
      },
      {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then(response => {
      const blob = response.data
      const link = document.createElement('a')
      const url = URL.createObjectURL(blob)
      link.href = url
      link.download = 'Policies.xlsx'
      link.click()
      link.remove()
    })
    .catch(response => {
      console.error('Could not Export the policies from the backend.', response)
    })
}

export const exportIrregularPoliciesFile = async ({
  customerName,
  customerIdNumber,
  policyNumber,
  policyTypeIds = [],
  companies = [],
  categories = [],
  months = [],
  commissionRange,
  premiumRange,
  accumulationRange,
  commissionRateByPremiumRange,
  commissionRateByAccumulationRange,
  signalType,
  sortByMonth,
  commissionView,
}) => {
  await $api
    .post(
      `${urlPolicies()}export-irregular-policies`,
      {
        customerName,
        customerIdNumber,
        policyNumber,
        policyTypeIds,
        companies,
        categories,
        months,
        commissionRange,
        premiumRange,
        accumulationRange,
        commissionRateByPremiumRange,
        commissionRateByAccumulationRange,
        signalType,
        sortByMonth,
        deltaViewType: commissionView,
      },
      {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then(response => {
      const blob = response.data
      const link = document.createElement('a')
      const url = URL.createObjectURL(blob)
      link.href = url
      link.download = 'irregluar_polices.xlsx'
      link.click()
      link.remove()
    })
    .catch(response => {
      console.error('Could not Export the irregular report from the backend.', response)
    })
}
