import ButtonText from '../../../../components/Common/ButtonText/ButtonText'
import { EditTwoTone } from '@mui/icons-material'
import './AddAgencySummaryItem.scss'

const AddAgencySummaryItem = ({ title, icon, children, styles }) => {
  return (
    <div className='add-agency-summary-item-card' style={{ ...styles }}>
      <header className='add-agency-summary-item-card-header'>
        <div className='add-agency-summary-item-card-icon'>{icon}</div>
        {/* <ButtonText>
          <EditTwoTone />
        </ButtonText> */}
      </header>
      <p className='add-agency-summary-item-card-heading'>{title}</p>
      <div className='add-agency-summary-item-card-content'>{children}</div>
    </div>
  )
}

export default AddAgencySummaryItem
