import classNames from 'classnames'
import { policyStatus } from '../../../types/policyStatusType'
import { hebrew } from '../../../i18n'
import './PolicyStatusChip.scss'

const PolicyStatusChip = ({ status }) => {
  if (!policyStatus[status]) {
    return null
  }

  const { name, nameEn } = policyStatus[status]
  return (
    <>
      {status && (
        <div className='policy-status-chip-container'>
          <div className={classNames('policy-status-chip', policyStatus[status].type + '-status')}>
            {hebrew() ? name : nameEn}
          </div>
        </div>
      )}
    </>
  )
}

export default PolicyStatusChip
