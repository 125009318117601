import { useState } from 'react'
import ButtonRounded from '../../../../components/Common/ButtonRounded/ButtonRounded'
import { useTranslation } from 'react-i18next'
import './ScanCompanyCredentialsForm.scss'
import { cn } from '../../../../utils/stylesUtils'

const ScanCompanyCredentialsForm = ({ authFields, submit, setAuthFields, warn, error, submitDisabled }) => {
  const { t } = useTranslation('scan')
  const [showPassword, setShowPassword] = useState({})

  const changeInputHandler = e =>
    setAuthFields(
      authFields.map(field => (field.fieldType === e.target.name ? { ...field, value: e.target.value } : field))
    )

  const submitHandler = e => {
    e.preventDefault()
    submit()
  }
  return (
    <form onSubmit={submitHandler} className='scan-company-creds-form'>
      <div className={'inputs-container'}>
        {authFields.map(field => (
          <div className={'input-container'} key={field.fieldType}>
            <input
              type={field.fieldType.includes('ass') && !showPassword[field.fieldType] ? 'password' : 'text'}
              name={field.fieldType}
              id={field.fieldType}
              placeholder=' '
              autoComplete={field?.fieldType.includes('ass') ? 'one-time-code' : 'off'}
              value={field.value}
              onChange={changeInputHandler}
              className={cn({ warn })}
            />
            {field.fieldType.includes('ass') && (
              <div
                className='password-icon'
                onMouseDownCapture={() =>
                  setShowPassword(prev => {
                    return { ...prev, [field.fieldType]: true }
                  })
                }
                onMouseUp={() =>
                  setShowPassword(prev => {
                    return { ...prev, [field.fieldType]: false }
                  })
                }
              >
                {showPassword[field.fieldType] ? (
                  <img src='./assets/eye/Hide.png' alt='hide' />
                ) : (
                  <img src='./assets/eye/Show.png' alt='show' />
                )}
              </div>
            )}
            <label htmlFor={field.fieldType} className={cn({ warn })}>
              {field.name}
            </label>
            <span className={cn('line', { warn })}></span>
          </div>
        ))}
        {authFields.length === 0 && <div>no fields found</div>}
        {error && error}
      </div>
      {submit && (
        <div>
          <ButtonRounded onClick={submitHandler} disabled={submitDisabled} typeSubmit>
            {t('registerForm.continueBtn')}
          </ButtonRounded>
        </div>
      )}
    </form>
  )
}

export default ScanCompanyCredentialsForm
