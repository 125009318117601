import { ArrowDownward, ArrowUpward } from '@mui/icons-material'
import { cn } from '../../../../utils/stylesUtils'
import './AnalyticsChangesValue.scss'

const AnalyticsChangesValue = ({
  value = null,
  currency,
  percent,
  maximumFractionDigits = 2,
  minimumFractionDigits = 2,
}) => {
  if (value === null) return <div className='analytics-changes-value'>--</div>

  const trend = value > 0
  const zeroValue = value === 0

  return (
    <div className={cn('analytics-changes-value', { positive: trend && !zeroValue, negative: !trend && !zeroValue })}>
      {!zeroValue && <span className='analytics-changes-value-sign'>{trend ? '+ ' : '- '}</span>}
      {currency && <span>₪</span>}
      <span>
        {new Intl.NumberFormat('he-IL', {
          style: 'decimal',
          maximumFractionDigits,
          minimumFractionDigits,
        }).format(Math.abs(value))}
      </span>
      {percent && <span>%</span>}
      {!zeroValue && (trend ? <ArrowUpward /> : <ArrowDownward />)}
    </div>
  )
}

export default AnalyticsChangesValue
