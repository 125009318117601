import classNames from 'classnames'
import React, { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import './PoliciesFilterDropdown.css'
import PoliciesFilterDropdownItem from './PoliciesFilterDropdownItem'

const PoliciesFilterDropdown = ({ title, filterItems, setChecked, multiSelect = true, clearFilter = () => {} }) => {
  const [open, setOpen] = useState(false)
  const refDropdown = useRef(null)
  const hasChecked = filterItems && filterItems.some(item => item.checked)

  const { i18n } = useTranslation()

  const clearFilters = () => {
    const checkedItem = filterItems.find(item => item.checked === true)
    if (checkedItem) {
      setChecked(checkedItem.id, false)
      filterItems.forEach(item => (item.checked = false))
    }

    clearFilter()
  }

  const handleHideDropdown = event => {
    if (event.key === 'Escape' || (refDropdown.current && !refDropdown.current.contains(event.target))) {
      setOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleHideDropdown, true)
    document.addEventListener('click', handleHideDropdown, true)
    return () => {
      document.removeEventListener('keydown', handleHideDropdown, true)
      document.removeEventListener('click', handleHideDropdown, true)
    }
  })
  return (
    <div
      className={classNames('policies-filter-dropdown', {
        open: (open && filterItems) || hasChecked,
      })}
      ref={refDropdown}
    >
      <div className='policies-filter-content' onClick={() => setOpen(!open)}>
        <div>
          {!multiSelect
            ? hasChecked
              ? `${title}: ` + filterItems.find(item => item.checked).name
              : `${title}: הכול`
            : title}
        </div>
        <div className='policies-filter-dropdown-icon'>
          <img src='./assets/arrow-dropdown-down/arrow_drop_down_24px.png' alt='dwn' className='img-icon' />
        </div>
      </div>

      {open && filterItems && (
        <div className='policies-filter-dropdown-items'>
          {!multiSelect && (
            <PoliciesFilterDropdownItem
              value={i18n.language === 'he' ? 'הכול' : 'all'}
              checked={!hasChecked}
              setChecked={() => clearFilters()}
              hideDropdown={() => setOpen(false)}
            />
          )}
          {filterItems.map((item, i) => (
            <PoliciesFilterDropdownItem
              value={i18n.language === 'he' ? item.name : item.nameEn}
              checked={item.checked}
              key={item.id + ' ' + i}
              setChecked={() => setChecked(item.id, !item.checked)}
              hideDropdown={() => {
                if (!multiSelect) setOpen(false)
              }}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default PoliciesFilterDropdown
