import classNames from 'classnames'
import { useState } from 'react'
import { formatAsNumber } from '../../utils/formatAs'
import './TrendValue.css'

const TrendValue = ({
  value,
  trend,
  showTrend = true,
  showPlusSign = false,
  showPercentSign = false,
  showCurrency = false,
  headingClass = '',
  extraSmall = false,
  fontSize,
}) => {
  const trendDirection = trend ? 'up' : 'down'
  const trendIconFile = trendDirection.charAt(0).toUpperCase() + trendDirection.slice(1)
  const [currency] = useState('₪')
  const formattedValue = formatAsNumber(Math.abs(value))

  return (
    <div className={classNames('trend-container', { xs: extraSmall })}>
      <div className='trend-value-container'>
        <div
          dir='ltr'
          className={classNames('trend-value', headingClass)}
          title={formattedValue}
          style={fontSize && { fontSize: fontSize + 'px', lineHeight: 'unset' }}
        >
          {showPlusSign &&
            Number(formattedValue) !== 0 &&
            formattedValue !== '0' &&
            formattedValue !== '' &&
            (trend !== undefined && trend ? '+' : '')}
          {formattedValue}
          {showPercentSign && formattedValue !== '' && <span className='percent-sign'>%</span>}
        </div>
        {showCurrency && value !== '' && (
          <div className='trend-currency' dir='ltr'>
            {value < 0 && '-'}
            {currency}
          </div>
        )}
      </div>
      {trend !== undefined &&
        trend !== null &&
        value !== null &&
        value !== undefined &&
        Number(value) !== 0 &&
        showTrend && (
          <div className='trend-icon'>
            <img src={`./assets/arrow-${trendDirection}/${trendIconFile} 3.png`} alt={`trend-${trendDirection}`} />
          </div>
        )}
    </div>
  )
}

export default TrendValue
