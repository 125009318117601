import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Button from '../../../../components/Common/Button/Button'
import './ProfileAddCompanyModal.css'
import Loader from '../../../../components/Common/Loader/Loader'
import { addCompanies, getAvailableCompanies } from '../../actions/profileActions'
import { useTranslation } from 'react-i18next'
import NoData from '../../../../components/Common/NoData/NoData'
import { PROFILE_GET_COMPANIES_CLEAR } from '../../../../types/actionTypes'
import { companyConnectionType } from '../../../../types/companies'

const ProfileAddCompanyModal = ({ closeModal }) => {
  const {
    availableCompanies,
    addCompaniesSuccess = false,
    availableCompaniesLoading,
    addCompaniesLoading,
  } = useSelector(state => state.profile)

  const [companies, setCompanies] = useState([])
  const { t, i18n } = useTranslation('profile')

  const dispatch = useDispatch()

  const selectCompanyHandler = companyId => {
    setCompanies(
      companies.map(company => (company.id === companyId ? { ...company, selected: !company.selected } : company))
    )
  }

  const addCompaniesHandler = () => {
    const selectedCompanies = companies
      .filter(company => company.selected)
      .map(c => {
        return { CompanyId: c.id, InsuranceFields: c.fields.map(f => f.id) }
      })

    dispatch(addCompanies(selectedCompanies))
  }

  const closeModalHandler = () => {
    if (!companies.some(company => company.selected)) {
      closeModal()
    }
  }

  const renderCompaniesList = companies => (
    <ul>
      {companies.map(company => (
        <div
          className='add-company-modal-company-item'
          key={company.id}
          onClick={() => selectCompanyHandler(company.id)}
        >
          <div className='company-logo'>
            <img src={`./assets/companies-logos-light/${company.id}.png`} alt={company.id} />
          </div>
          <div className='company-name-container'>
            <div className='company-name'>{i18n.language === 'he' ? company.name : company.nameEn}</div>
            <div className='company-select'>
              <img src={`./assets/checkbox-lg-${company.selected ? 'checked' : 'unchecked'}/Square.png`} alt='chkbx' />
            </div>
          </div>
        </div>
      ))}
    </ul>
  )

  useEffect(() => {
    if (addCompaniesSuccess) {
      closeModal()
    }
  })

  useEffect(() => {
    dispatch(getAvailableCompanies())
  }, [dispatch])

  useEffect(() => {
    setCompanies(
      availableCompanies.map(company => {
        return { ...company, selected: false }
      })
    )
  }, [availableCompanies])

  useEffect(() => {
    return () => {
      dispatch({ type: PROFILE_GET_COMPANIES_CLEAR })
    }
  }, [dispatch])

  return (
    <div className='add-company-modal-wrapper' onClick={closeModalHandler}>
      <div className='profile-add-company-modal-container' onClick={e => e.stopPropagation()}>
        <div onClick={closeModal} className='add-company-modal-close-icon'>
          <img src='./assets/close-icon-sm/Close.png' alt='cls' className='img-icon' />
        </div>
        <div className='add-company-modal-content'>
          <div className='add-company-modal-title'>{t('addCompanyModal.modalTitle')}</div>
          <div className='add-company-modal-companies-list-title'>{t('addCompanyModal.modalText')}</div>
          {availableCompaniesLoading || addCompaniesLoading ? (
            <Loader />
          ) : companies.length > 0 ? (
            <div className='add-company-modal-companies-list'>
              {renderCompaniesList(companies.filter(c => c.connectionType === companyConnectionType.direct))}
              <h4>{t('chooseInvestCompany')}</h4>
              {renderCompaniesList(companies.filter(c => c.connectionType === companyConnectionType.email))}
            </div>
          ) : (
            <NoData text={t('addCompanyModal.modalNoData')} />
          )}
          <div className='add-company-modal-submit-btn'>
            <Button
              caption={t('addCompanyModal.modalButton.save')}
              disabled={
                companies.filter(company => company.selected).length === 0 ||
                availableCompaniesLoading ||
                addCompaniesLoading
              }
              onClick={addCompaniesHandler}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfileAddCompanyModal
