import { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import Loader from '../Common/Loader/Loader'
import './ModalWindow.scss'

const ModalWindow = ({
  closeModal = () => {},
  closeOnOutsideClick = true,
  showCloseIcon = true,
  children,
  renderPortal = false,
  loadingOverlay = false,
}) => {
  const bodyOverflowRef = useRef()

  const closeModalHandler = e => {
    e.stopPropagation()
    closeModal()
  }

  useEffect(() => {
    if (!renderPortal) {
      bodyOverflowRef.current = document.body.style.overflow
      document.body.style.overflow = 'hidden'
      return () => {
        document.body.style.overflow = bodyOverflowRef.current
      }
    }
  }, [renderPortal])

  if (renderPortal) {
    return createPortal(
      <div className='modal-wrapper' {...(closeOnOutsideClick && { onClick: closeModalHandler })}>
        <div className='modal-container' onClick={e => e.stopPropagation()}>
          {showCloseIcon && (
            <div onClick={closeModal} className='modal-close-icon'>
              <img src='./assets/close-icon-sm/Close.png' alt='cls' className='img-icon' />
            </div>
          )}
          <div className='modal-content'>{children}</div>
          {loadingOverlay && (
            <div className='modal-content-loading-overlay'>
              <Loader />
            </div>
          )}
        </div>
      </div>,
      document.body
    )
  }

  return (
    <div className='modal-wrapper' {...(closeOnOutsideClick && { onClick: closeModalHandler })}>
      <div className='modal-container' onClick={e => e.stopPropagation()}>
        {showCloseIcon && (
          <div onClick={closeModal} className='modal-close-icon'>
            <img src='./assets/close-icon-sm/Close.png' alt='cls' className='img-icon' />
          </div>
        )}
        <div className='modal-content'>{children}</div>
        {loadingOverlay && (
          <div className='modal-content-loading-overlay'>
            <Loader />
          </div>
        )}
      </div>
    </div>
  )
}

export default ModalWindow
