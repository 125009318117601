import $api from '..'
import { urlProfile } from '../../environment/urls'

export const getProfileConnectedAgenciesRequest = async () => await $api.get(`${urlProfile()}get-invites`)

export const cancelProfileConnectedAgenciesRequest = async inviteId =>
  await $api.delete(`${urlProfile()}cancel-association/${inviteId}`)

export const updateUserInfoRequest = async userInfo => await $api.put(`${urlProfile()}user-info`, userInfo)

export const getProfileNextPaymentRequest = async () => await $api.get(`${urlProfile()}next-payment`)

export const getProfileNextPaymentDetailsRequest = async () =>
  await $api.get(`${urlProfile()}next-payment-detailed-info`)

export const getProfilePaymentsHistoryRequest = async () => await $api.get(`${urlProfile()}payment-history`)

export const getProfileMappingSettingsRequest = async () => await $api.get(`${urlProfile()}get-policy-mapping-setting`)

export const toggleProfileMappingSettingsRequest = async () =>
  await $api.get(`${urlProfile()}change-policy-mapping-setting`)

export const getProfileIrregSettingsRequest = async () => await $api.get(`${urlProfile()}irregularity-settings`)

export const getProfileIrregLimitsRequest = async () => await $api.get(`${urlProfile()}irregularity-limits`)
export const updateProfileIrregSettingsRequest = async ({ reason, active }) =>
  await $api.patch(`${urlProfile()}update-irregularity-settings`, { reason, active })

export const updateProfileIrregLimitsRequest = async ({
  commissionAmountLimit,
  commissionRateLimit,
  negativeCommissionAmount,
  negativePremiumAmount,
}) =>
  await $api.put(`${urlProfile()}update-irregularity-limits`, {
    commissionAmountLimit,
    commissionRateLimit,
    negativeCommissionAmount,
    negativePremiumAmount,
  })
