import React, { useCallback, useEffect, useState } from 'react'
import CustomersPoliciesTotals from '../CustomersPoliciesTotals/CustomersPoliciesTotals'
import { echo } from '../../../../utils/consoleUtils'
import { useParams } from 'react-router-dom'
import {
  getCustomerPoliciesByCategoryRequest,
  getCustomerPoliciesByGroupRequest,
  getCustomerPoliciesInfoRequest,
} from '../../../../http/requests/customerRequests'
import CustomersFieldsPolicies from '../CustomersFieldsPolicies/CustomersFieldsPolicies'
import { hebrew } from '../../../../i18n'
import CustomerPoliciesList from '../CustomerPoliciesList/CustomerPoliciesList'
import CustomerPoliciesGroup from '../CustomerPoliciesGroup/CustomerPoliciesGroup'
import Loader from '../../../../components/Common/Loader/Loader'
import { useTranslation } from 'react-i18next'
import { familyGroupMode } from '../../../shared/reducers/sharedSelectors'
import CustomersPoliciesSearchInput from '../../../../components/CustomersPoliciesSearchInput/CustomersPoliciesSearchInput'
import PoliciesFilterDropdown from '../../../policies/components/PoliciesFilter/PoliciesFilterDropdown'
import { useSelector } from 'react-redux'
import useDebouncedCallback from '../../../../hooks/useDebouncedCallback'
import { getCheckedIds } from '../../../../utils/objectUtils'
import { customerPolicyList } from '../../../../types/customerPolicyListTypes'
import NoData from '../../../../components/Common/NoData/NoData'
import './CustomerExistingPoliciesContainer.scss'

const listBy = {
  group: 'group',
  category: 'category',
}

const listByCategory = by => by === listBy.category

const selectedCategoryName = (fields, categoryId) => {
  const { name = 'N/A', nameEn = 'N/A' } = fields.flatMap(f => f.categoryInfos).find(c => c.categoryId === categoryId)
  return hebrew() ? name : nameEn
}

const CustomerExistingPoliciesContainer = () => {
  const { t } = useTranslation('customer')
  const { id } = useParams()
  const [totals, setTotals] = useState({})
  const [fields, setFields] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [policiesList, setPoliciesList] = useState(null)
  const calculateByGroup = familyGroupMode()
  const [searchTerm, setSearchTerm] = useState('')
  const [filters, setFilters] = useState({ companies: [], categories: [], search: '' })

  const { companiesFilter, categoriesFilter } = useSelector(({ policies }) => policies)
  const { customerGroupsLoading } = useSelector(({ customer }) => customer)
  const { selectedGroupsIds } = useSelector(({ shared }) => shared)

  const setSearchFilter = val => setFilters(prev => ({ ...prev, search: val }))

  const deboucedSearchFilter = useDebouncedCallback(() => {
    setSearchFilter(searchTerm)
  }, 500)

  const searchTermHandler = val => {
    setSearchTerm(val)
    if (val.length === 0) {
      setSearchFilter('')
    } else {
      deboucedSearchFilter()
    }
  }

  const applyFilter = filtersKey => id => {
    setFilters(prev => ({
      ...prev,
      [filtersKey]: prev[filtersKey].map(pr => ({ ...pr, checked: pr.id === id ? !pr.checked : pr.checked })),
    }))
  }

  const clearFilters = () => {
    setFilters({
      companies: companiesFilter,
      categories: categoriesFilter,
      search: '',
    })
    setSearchTerm('')
  }

  const filtersApplied =
    filters.search.length > 0 || filters.companies.some(c => c.checked) || filters.categories.some(c => c.checked)

  const showListHandler = by => async entityId => {
    const request = listByCategory(by) ? getCustomerPoliciesByCategoryRequest : getCustomerPoliciesByGroupRequest
    try {
      setPoliciesList(prev => ({ ...prev, by, entityId, loading: true }))
      setPoliciesList(prev => ({ ...prev, loading: true }))
      const {
        data: { data: list },
      } = await request({
        id: +id,
        entityId,
        calculateByGroup,
        companies: getCheckedIds(filters.companies),
        categories: getCheckedIds(filters.categories),
        search: filters.search,
      })
      setPoliciesList(prev => ({ ...prev, loading: false, list }))
    } catch (error) {
      console.log(error)
      setPoliciesList(prev => ({ ...prev, loading: false }))
    }
  }

  const fetch = useCallback(async () => {
    try {
      setError(null)
      setLoading(true)
      const {
        data: { data },
      } = await getCustomerPoliciesInfoRequest({
        id,
        calculateByGroup,
        companies: getCheckedIds(filters.companies),
        categories: getCheckedIds(filters.categories),
        search: filters.search,
      })
      setTotals(data)
      setFields(data.existingFields ?? [])
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setError(error)
    }
  }, [id, calculateByGroup, filters])

  useEffect(() => {
    setFilters(prev => ({ ...prev, companies: companiesFilter, categories: categoriesFilter }))
  }, [companiesFilter, categoriesFilter])

  useEffect(() => {
    setPoliciesList(null)
    if (filters.companies.length > 0 && filters.categories.length > 0 && !customerGroupsLoading) {
      fetch()
    }
  }, [fetch, filters, customerGroupsLoading, selectedGroupsIds])

  return (
    <div className='customer-existing-policies-container'>
      <div className='customer-existing-policies-filters'>
        <CustomersPoliciesSearchInput
          placeholder={t('filters.search')}
          searchValue={searchTerm}
          setSearchValue={searchTermHandler}
        />
        <PoliciesFilterDropdown
          title={t('filters.companies')}
          filterItems={filters.companies}
          setChecked={applyFilter('companies')}
        />
        <PoliciesFilterDropdown
          title={t('filters.categories')}
          filterItems={filters.categories}
          setChecked={applyFilter('categories')}
        />
        <button onClick={clearFilters} className='customer-existing-policies-clear-filters' disabled={!filtersApplied}>
          {t('filters.clear')}
        </button>
      </div>
      <div>
        {policiesList ? (
          <>
            <header>
              <button onClick={() => setPoliciesList(null)}>
                <img
                  src={`./assets/arrow-${hebrew() ? 'right' : 'left'}-common/${hebrew() ? 'Right' : 'Left'}.png`}
                  alt='back'
                  className='img-icon'
                />
                <span>
                  {listByCategory(policiesList.by)
                    ? selectedCategoryName(fields, policiesList.entityId)
                    : t(`policiesTotals.${customerPolicyList[policiesList.entityId].key}`)}
                </span>
              </button>
            </header>
            {policiesList.loading && <Loader />}
            {!policiesList.loading &&
              (policiesList.by === listBy.category ? (
                <CustomerPoliciesList
                  policies={policiesList.list.policies}
                  policiesTotals={policiesList.list.policiesTotals}
                />
              ) : (
                <div className='customer-policies-groups'>
                  {policiesList.list.length === 0 && <NoData text='Nothing found' />}
                  {policiesList.list.map(gr => (
                    <CustomerPoliciesGroup
                      key={gr.id}
                      policies={gr.policies}
                      groupName={hebrew() ? gr.name : gr.nameEn}
                      policiesTotals={gr.policiesTotals}
                    />
                  ))}
                </div>
              ))}
          </>
        ) : (
          <>
            <CustomersPoliciesTotals totals={totals} loading={loading} showList={showListHandler(listBy.group)} />
            <CustomersFieldsPolicies fields={fields} loading={loading} showList={showListHandler(listBy.category)} />
          </>
        )}
      </div>
    </div>
  )
}

export default CustomerExistingPoliciesContainer
