import { BarChartRounded } from '@mui/icons-material'
import { getKeyByValue } from '../utils/objectUtils'
import MoneyShekelIconSvg from '../components/Common/SvgIcons/MoneyShekelIconSvg/MoneyShekelIconSvg'
import CashPlusIconSvg from '../components/Common/SvgIcons/CashPlusIconSvg/CashPlusIconSvg'

export const customerDataType = {
  premium: 1,
  premiumPayments: 2,
  commission: 3,
  commissionPayments: 4,
  accumulation: 5,
  commissionAgency: 6,
  commissionPaymentsAgency: 7,
  commissionBothTotal: 8,
  commissionPaymentsBothTotal: 9,
}

export const customerDataTypeCard = {
  [customerDataType.premium]: {
    icon: <BarChartRounded style={{ fontSize: '40px' }} />,
    typeKey: getKeyByValue(customerDataType, customerDataType.premium),
  },
  [customerDataType.premiumPayments]: {
    icon: <BarChartRounded style={{ fontSize: '40px' }} />,
    typeKey: getKeyByValue(customerDataType, customerDataType.premiumPayments),
  },
  [customerDataType.commission]: {
    icon: <MoneyShekelIconSvg />,
    typeKey: getKeyByValue(customerDataType, customerDataType.commission),
  },
  [customerDataType.commissionPayments]: {
    icon: <MoneyShekelIconSvg />,
    typeKey: getKeyByValue(customerDataType, customerDataType.commissionPayments),
  },
  [customerDataType.accumulation]: {
    icon: <CashPlusIconSvg />,
    typeKey: getKeyByValue(customerDataType, customerDataType.accumulation),
  },
  [customerDataType.commissionAgency]: {
    icon: <MoneyShekelIconSvg />,
    typeKey: getKeyByValue(customerDataType, customerDataType.commissionAgency),
  },
  [customerDataType.commissionPaymentsAgency]: {
    icon: <MoneyShekelIconSvg />,
    typeKey: getKeyByValue(customerDataType, customerDataType.commissionPaymentsAgency),
  },
  [customerDataType.commissionBothTotal]: {
    icon: <MoneyShekelIconSvg />,
    typeKey: getKeyByValue(customerDataType, customerDataType.commissionBothTotal),
  },
  [customerDataType.commissionPaymentsBothTotal]: {
    icon: <MoneyShekelIconSvg />,
    typeKey: getKeyByValue(customerDataType, customerDataType.commissionPaymentsBothTotal),
  },
}
