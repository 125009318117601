export function capitalizeObjectKeys(obj) {
  if (typeof obj !== 'object' || obj === null) {
    throw new Error('Input must be a valid object.')
  }

  if (Array.isArray(obj)) {
    return obj.map(item => capitalizeObjectKeys(item))
  }

  const capitalizedObj = {}

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1)
      let value = obj[key]

      if (typeof value === 'object' && value !== null) {
        value = capitalizeObjectKeys(value) // Recursively capitalize nested objects and arrays
      }

      capitalizedObj[capitalizedKey] = value
    }
  }

  return capitalizedObj
}

export function lowercaseObjectKeys(obj) {
  if (typeof obj !== 'object' || obj === null) {
    throw new Error('Input must be a valid object.')
  }

  if (Array.isArray(obj)) {
    return obj.map(item => lowercaseObjectKeys(item))
  }

  const lowercasedObj = {}

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const lowercasedKey = key.charAt(0).toLowerCase() + key.slice(1)
      let value = obj[key]

      if (Array.isArray(value) && value.every(item => typeof item === 'object' && item !== null)) {
        value = value.map(item => lowercaseObjectKeys(item))
      } else if (!Array.isArray(value) && typeof value === 'object' && value !== null) {
        value = lowercaseObjectKeys(value) // Recursively lowercase nested objects and arrays
      }

      lowercasedObj[lowercasedKey] = value
    }
  }

  return lowercasedObj
}

export const groupBy = function (arr, key) {
  return arr.reduce(function (rv, x) {
    ;(rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}

export const getCheckedIds = (list, checkPropName = 'checked') => list.filter(c => c[checkPropName]).map(c => c.id)

export const getKeyByValue = (obj, value) => {
  const entry = Object.entries(obj).find(([_, v]) => v === value)
  return entry.length === 0 ? '' : entry[0]
}
