import {
  PROFILE_ADD_COMPANIES_FAIL,
  PROFILE_ADD_COMPANIES_REQUEST,
  PROFILE_ADD_COMPANIES_SUCCESS,
  PROFILE_COMPANIES_FAIL,
  PROFILE_COMPANIES_REQUEST,
  PROFILE_COMPANIES_SUCCESS,
  PROFILE_DELETE_COMPANY_FAIL,
  PROFILE_DELETE_COMPANY_REQUEST,
  PROFILE_DELETE_COMPANY_SUCCESS,
  PROFILE_DETAILS_FAIL,
  PROFILE_DETAILS_REQUEST,
  PROFILE_DETAILS_SUCCESS,
  PROFILE_EDIT_COMPANY_FAIL,
  PROFILE_EDIT_COMPANY_REQUEST,
  PROFILE_EDIT_COMPANY_RESET,
  PROFILE_EDIT_COMPANY_SUCCESS,
  PROFILE_FIELDS_FAIL,
  PROFILE_FIELDS_REQUEST,
  PROFILE_FIELDS_SUCCESS,
  PROFILE_GET_COMPANIES_CLEAR,
  PROFILE_GET_COMPANIES_FAIL,
  PROFILE_GET_COMPANIES_REQUEST,
  PROFILE_GET_COMPANIES_SUCCESS,
  PROFILE_EMPLOYEES_REQUEST,
  PROFILE_EMPLOYEES_SUCCESS,
  PROFILE_EMPLOYEES_FAIL,
  PROFILE_EMPLOYEES_ADD_REQUEST,
  PROFILE_EMPLOYEES_ADD_SUCCESS,
  PROFILE_EMPLOYEES_ADD_FAIL,
  PROFILE_EMPLOYEES_ADD_RESET,
  PROFILE_EMPLOYEES_DELETE_REQUEST,
  PROFILE_EMPLOYEES_DELETE_SUCCESS,
  PROFILE_EMPLOYEES_DELETE_FAIL,
  PROFILE_EMPLOYEES_EDIT_REQUEST,
  PROFILE_EMPLOYEES_EDIT_SUCCESS,
  PROFILE_EMPLOYEES_EDIT_FAIL,
  PROFILE_EMPLOYEES_EDIT_RESET,
} from '../../../types/actionTypes'

const initialState = {
  fields: [],
  fieldsLoading: false,
  fieldsError: null,

  companies: [],
  companiesLoading: false,
  companiesError: null,

  availableCompanies: [],
  availableCompaniesLoading: true,
  availableCompaniesError: null,

  addCompaniesSuccess: false,
  addCompaniesLoading: false,
  addCompaniesError: null,

  editCompanySuccess: false,
  editCompanyLoading: false,
  editCompanyError: null,

  deleteCompanySuccess: false,
  deleteCompanyLoading: false,
  deleteCompanyError: null,

  details: {},
  detailsLoading: false,
  detailsError: null,

  employees: [],
  employeesLoading: true,
  employeesError: null,

  addEmployeeSuccess: false,
  addEmployeeLoading: false,
  addEmployeeError: null,

  editEmployeeSuccess: false,
  editEmployeeLoading: false,
  editEmployeeError: null,

  deleteEmployeeSuccess: false,
  deleteEmployeeIds: [],
  deleteEmployeeLoading: false,
  deleteEmployeeError: null,
}

export const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROFILE_DETAILS_REQUEST:
      return {
        ...state,
        detailsLoading: true,
        detailsError: null,
      }
    case PROFILE_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload,
        detailsLoading: false,
        detailsError: null,
      }
    case PROFILE_DETAILS_FAIL:
      return {
        ...state,
        detailsLoading: false,
        detailsError: action.payload,
      }
    case PROFILE_FIELDS_REQUEST:
      return {
        ...state,
        fieldsLoading: true,
        fieldsError: null,
      }
    case PROFILE_FIELDS_SUCCESS:
      return {
        ...state,
        fields: action.payload,
        fieldsLoading: false,
        fieldsError: null,
      }
    case PROFILE_FIELDS_FAIL:
      return {
        ...state,
        fieldsLoading: false,
        fieldsError: action.payload,
      }
    case PROFILE_COMPANIES_REQUEST:
      return {
        ...state,
        companiesLoading: true,
        companiesError: null,
      }
    case PROFILE_COMPANIES_SUCCESS:
      return {
        ...state,
        companies: action.payload,
        companiesLoading: false,
        companiesError: null,
      }
    case PROFILE_COMPANIES_FAIL:
      return {
        ...state,
        companiesLoading: false,
        companiesError: action.payload,
      }
    case PROFILE_GET_COMPANIES_REQUEST:
      return {
        ...state,
        availableCompaniesLoading: true,
        companiesError: null,
      }
    case PROFILE_GET_COMPANIES_SUCCESS:
      return {
        ...state,
        availableCompanies: action.payload,
        availableCompaniesLoading: false,
        availableCompaniesError: null,
      }
    case PROFILE_GET_COMPANIES_FAIL:
      return {
        ...state,
        availableCompaniesLoading: false,
        availableCompaniesError: action.payload,
      }
    case PROFILE_GET_COMPANIES_CLEAR:
      return {
        ...state,
        availableCompanies: [],
        availableCompaniesLoading: true,
        availableCompaniesError: null,
        addCompaniesSuccess: false,
        addCompaniesLoading: false,
        addCompaniesError: null,
      }
    case PROFILE_ADD_COMPANIES_REQUEST:
      return {
        ...state,
        addCompaniesSuccess: false,
        addCompaniesLoading: true,
        addCompaniesError: null,
      }
    case PROFILE_ADD_COMPANIES_SUCCESS:
      return {
        ...state,
        addCompaniesSuccess: true,
        addCompaniesLoading: false,
        addCompaniesError: null,
      }
    case PROFILE_ADD_COMPANIES_FAIL:
      return {
        ...state,
        addCompaniesSuccess: false,
        addCompaniesLoading: false,
        addCompaniesError: action.payload,
      }
    case PROFILE_EDIT_COMPANY_REQUEST:
      return {
        ...state,
        editCompanySuccess: false,
        editCompanyLoading: true,
        editCompanyError: null,
      }
    case PROFILE_EDIT_COMPANY_SUCCESS:
      return {
        ...state,
        editCompanySuccess: true,
        editCompanyLoading: false,
        editCompanyError: null,
      }
    case PROFILE_EDIT_COMPANY_FAIL:
      return {
        ...state,
        editCompanySuccess: false,
        editCompanyLoading: false,
        editCompanyError: action.payload,
      }
    case PROFILE_EDIT_COMPANY_RESET:
      return {
        ...state,
        editCompanySuccess: false,
        editCompanyLoading: false,
        editCompanyError: null,
      }
    case PROFILE_DELETE_COMPANY_REQUEST:
      return {
        ...state,
        deleteCompanySuccess: false,
        deleteCompanyLoading: true,
        deleteCompanyError: null,
      }
    case PROFILE_DELETE_COMPANY_SUCCESS:
      return {
        ...state,
        deleteCompanySuccess: true,
        deleteCompanyLoading: false,
        deleteCompanyError: null,
      }
    case PROFILE_DELETE_COMPANY_FAIL:
      return {
        ...state,
        deleteCompanySuccess: false,
        deleteCompanyLoading: false,
        deleteCompanyError: action.payload,
      }
    case PROFILE_EMPLOYEES_REQUEST:
      return {
        ...state,
        employeesLoading: true,
        employeesError: null,
      }
    case PROFILE_EMPLOYEES_SUCCESS:
      return {
        ...state,
        employees: action.payload,
        employeesLoading: false,
        employeesError: null,
      }
    case PROFILE_EMPLOYEES_FAIL:
      return {
        ...state,
        employeesLoading: false,
        employeesError: action.payload,
      }
    case PROFILE_EMPLOYEES_ADD_REQUEST:
      return {
        ...state,
        addEmployeeSuccess: false,
        addEmployeeLoading: true,
        addEmployeeError: null,
      }
    case PROFILE_EMPLOYEES_ADD_SUCCESS:
      return {
        ...state,
        addEmployeeSuccess: action.payload,
        addEmployeeLoading: false,
        addEmployeeError: null,
      }
    case PROFILE_EMPLOYEES_ADD_FAIL:
      return {
        ...state,
        addEmployeeSuccess: false,
        addEmployeeLoading: false,
        addEmployeeError: action.payload ?? ['Server Error'],
      }
    case PROFILE_EMPLOYEES_ADD_RESET:
      return {
        ...state,
        addEmployeeSuccess: false,
        addEmployeeLoading: false,
        addEmployeeError: null,
      }
    case PROFILE_EMPLOYEES_EDIT_REQUEST:
      return {
        ...state,
        editEmployeeSuccess: false,
        editEmployeeLoading: true,
        editEmployeeError: null,
      }
    case PROFILE_EMPLOYEES_EDIT_SUCCESS:
      return {
        ...state,
        editEmployeeSuccess: action.payload,
        editEmployeeLoading: false,
        editEmployeeError: null,
      }
    case PROFILE_EMPLOYEES_EDIT_FAIL:
      return {
        ...state,
        editEmployeeSuccess: false,
        editEmployeeLoading: false,
        editEmployeeError: action.payload,
      }
    case PROFILE_EMPLOYEES_EDIT_RESET:
      return {
        ...state,
        editEmployeeSuccess: false,
        editEmployeeLoading: false,
        editEmployeeError: null,
      }
    case PROFILE_EMPLOYEES_DELETE_REQUEST:
      return {
        ...state,
        deleteEmployeeSuccess: false,
        deleteEmployeeIds: [state.deleteEmployeeIds, action.payload],
        deleteEmployeeLoading: true,
        deleteEmployeeError: null,
      }
    case PROFILE_EMPLOYEES_DELETE_SUCCESS:
      const { success, employeeId } = action.payload
      return {
        ...state,
        deleteEmployeeSuccess: success,
        employees: success ? state.employees.filter(e => e.Id !== employeeId) : state.employees,
        deleteEmployeeLoading: false,
        deleteEmployeeError: null,
      }
    case PROFILE_EMPLOYEES_DELETE_FAIL:
      return {
        ...state,
        deleteEmployeeSuccess: false,
        deleteEmployeeLoading: false,
        deleteEmployeeError: action.payload,
      }
    default:
      return state
  }
}
