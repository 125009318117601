import { useState, useRef } from 'react'
import { rtlDir } from '../../../../i18n'
import { cn } from '../../../../utils/stylesUtils'
import PoliciesDateRange from '../PoliciesDateRange/PoliciesDateRange'
import { ArrowDropDown, ArrowDropUp, Cancel } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import ButtonRounded from '../../../../components/Common/ButtonRounded/ButtonRounded'
import './PoliciesDatesFilterDropdown.scss'

const rangeNull = { from: null, to: null }

const getDateRangeProps = (initFilter, filter, setFilter) => ({
  initFrom: initFilter?.from,
  initTo: initFilter?.to,
  from: filter?.from,
  to: filter?.to,
  setFrom: value => setFilter(prev => ({ ...prev, from: value })),
  setTo: value => setFilter(prev => ({ ...prev, to: value })),
})

const checkFiltersDirty = filterPairs => filterPairs.some(fp => fp[0]?.from !== fp[1]?.from || fp[0]?.to !== fp[1]?.to)
const checkFiltersApplied = filters => filters.some(f => f?.from || f?.to)

const PoliciesDatesFilterDropdown = ({
  filtersLoading,
  policyStartDatesInit,
  policyEndDatesInit,
  anyMonthInReportsDatesInit,
  firstTimeInReportDatesInit,
  irregularityStartDateInit,
  policyStartDates,
  policyEndDates,
  anyMonthInReportsDates,
  firstTimeInReportDates,
  irregularityStartDate,
  applyFilters,
}) => {
  const { t } = useTranslation('policies')
  const [showPicker, setShowPicker] = useState(false)
  const [policyStart, setPolicyStart] = useState(policyStartDates)
  const [policyEnd, setPolicyEnd] = useState(policyEndDates)
  const [anyMonthInReports, setAnyMonthInReports] = useState(anyMonthInReportsDates)
  const [firstTimeInReport, setFirstTimeInReport] = useState(firstTimeInReportDates)
  const [irregularityStart, setIrregularityStart] = useState(irregularityStartDate)
  const btnRef = useRef()
  const overlayRef = useRef()

  const hasFiltersApplied = checkFiltersApplied([policyStart, policyEnd, anyMonthInReports, firstTimeInReport, irregularityStart])
  const filtersDirty = checkFiltersDirty([
    [policyStartDates, policyStart],
    [policyEndDates, policyEnd],
    [anyMonthInReportsDates, anyMonthInReports],
    [firstTimeInReportDates, firstTimeInReport],
    [irregularityStartDate, irregularityStart],
  ])
  const applyFiltersHandler = () => {
    filtersDirty &&
      applyFilters({
        policyStartDates: policyStart,
        policyEndDates: policyEnd,
        anyMonthInReportsDates: anyMonthInReports,
        firstTimeInReportDates: firstTimeInReport,
        irregularityStartDate: irregularityStart,
      })
    closeFilterDropdown()
  }

  const clearFiltersHandler = () => {
    setPolicyStart(rangeNull)
    setPolicyEnd(rangeNull)
    setAnyMonthInReports(rangeNull)
    setFirstTimeInReport(rangeNull)
    setIrregularityStart(rangeNull)
    if (
      !showPicker ||
      checkFiltersDirty(
        [policyStartDates, rangeNull],
        [policyEndDates, rangeNull],
        [anyMonthInReportsDates, rangeNull],
        [firstTimeInReportDates, rangeNull],
        [irregularityStartDate, rangeNull]
      ) ||
      hasFiltersApplied
    ) {
      applyFilters({
        policyStartDates: rangeNull,
        policyEndDates: rangeNull,
        anyMonthInReportsDates: rangeNull,
        firstTimeInReportDates: rangeNull,
        irregularityStartDate: rangeNull,
      })
    }
    closeFilterDropdown()
  }

  const cancelFiltersHandler = () => {
    setPolicyStart(policyStartDates)
    setPolicyEnd(policyEndDates)
    setAnyMonthInReports(anyMonthInReportsDates)
    setFirstTimeInReport(firstTimeInReportDates)
    setIrregularityStart(irregularityStartDate)
    closeFilterDropdown()
  }

  const closeFilterDropdown = () => {
    setShowPicker(false)
  }
  const openFilterDropdown = () => setShowPicker(true)

  const getButtonPostition = () => {
    const { bottom, left, right, width } = btnRef?.current?.getBoundingClientRect()
    return { left, right, y: bottom, width }
  }

  return (
    <div className='policies-dates-filter-dropdown-container'>
      <div className='policies-dates-filter-dropdown-btn-container' ref={btnRef}>
        <div
          className={cn('policies-dates-filter-dropdown-btn', {
            'has-applied': hasFiltersApplied,
            open: showPicker,
          })}
          onClick={() => (showPicker ? applyFiltersHandler() : openFilterDropdown())}
          disabled={filtersLoading}
        >
          <span>{t('placeholders.date')}</span>
          {hasFiltersApplied && !showPicker ? (
            <button
              onClick={e => {
                e.stopPropagation()
                clearFiltersHandler()
              }}
            >
              <Cancel />
            </button>
          ) : showPicker ? (
            <ArrowDropUp />
          ) : (
            <ArrowDropDown />
          )}
        </div>
      </div>
      {showPicker && (
        <div
          className='policies-dates-filter-dropdown-overlay'
          ref={overlayRef}
          onClick={e => {
            overlayRef.current === e.target && cancelFiltersHandler()
          }}
        >
          <div
            className='policies-dates-filter-dropdown-main-container'
            style={{
              top: getButtonPostition().y + 8 + 'px',
              ...(rtlDir()
                ? { left: getButtonPostition().left + 'px' }
                : { left: getButtonPostition().right + 'px', transform: 'translateX(-100%)' }),
            }}
          >
            <div className='policies-dates-filter-dropdown-body'>
              <div>
                <p>{t('placeholders.policyStart')}</p>
                <PoliciesDateRange {...getDateRangeProps(policyStartDatesInit, policyStart, setPolicyStart)} />
              </div>
              <div>
                <p>{t('placeholders.policyEnd')}</p>
                <PoliciesDateRange {...getDateRangeProps(policyEndDatesInit, policyEnd, setPolicyEnd)} />
              </div>
              <div>
                <p>{t('placeholders.anyMonthInReports')}</p>
                <PoliciesDateRange
                  view={'year'}
                  {...getDateRangeProps(anyMonthInReportsDatesInit, anyMonthInReports, setAnyMonthInReports)}
                />
              </div>
              <div>
                <p>{t('placeholders.firstTimeInReport')}</p>
                <PoliciesDateRange
                  view={'year'}
                  {...getDateRangeProps(firstTimeInReportDatesInit, firstTimeInReport, setFirstTimeInReport)}
                />
              </div>
              <div>
                <p>{t('placeholders.irregularityStart')}</p>
                <PoliciesDateRange
                  view={'year'}
                  {...getDateRangeProps(irregularityStartDateInit, irregularityStart, setIrregularityStart)}
                />
              </div>
            </div>
            <div className='policies-dates-filter-dropdown-footer'>
              <ButtonRounded onClick={cancelFiltersHandler} variant='outlined'>
                {t('placeholders.cancelDateFilter')}
              </ButtonRounded>
              <ButtonRounded onClick={clearFiltersHandler} variant='danger' disabled={!hasFiltersApplied}>
                {t('placeholders.clearDateFilter')}
              </ButtonRounded>
              <ButtonRounded onClick={applyFiltersHandler} disabled={!hasFiltersApplied && !filtersDirty}>
                {t('placeholders.applyDateFilter')}
              </ButtonRounded>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default PoliciesDatesFilterDropdown
