import { colorTypes } from './colorsTypes'
import { customersListType } from './tablesTypes'

export const policyRatingType = {
  green: 10,
  orange: 20,
  red: 30,
}

export const policyRating = [
  {
    key: policyRatingType.green,
    value: 'ירוק',
    valuePlural: 'ירוקים',
    name: 'ירוקים',
    nameEn: 'Green',
    desc: 'הכנסה גבוהה מ־50₪',
    descEn: 'Income higher than 50₪',
    color: colorTypes.green,
    cssColor: 'var(--green-client)',
    listTypeCustomers: customersListType.ratingGreen,
  },
  {
    key: policyRatingType.orange,
    value: 'כתום',
    valuePlural: 'כתומים',
    name: 'כתומים',
    nameEn: 'Orange',
    desc: 'הכנסה נמוכה מ־50₪',
    descEn: 'Income lower than 50₪',
    color: colorTypes.orange,
    cssColor: 'var(--orange-client)',
    listTypeCustomers: customersListType.ratingOrange,
  },
  {
    key: policyRatingType.red,
    value: 'אדום',
    valuePlural: 'אדומים',
    name: 'אדומים',
    nameEn: 'Red',
    desc: 'הכנסה נמוכה מ־30₪',
    descEn: 'Income lower than 30₪',
    color: colorTypes.red,
    cssColor: 'var(--red-client)',
    listTypeCustomers: customersListType.ratingRed,
  },
]
