import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import './LandingPreferences.scss'
import { hebrew } from '../../../../i18n'

const LandingPreferences = () => {
  const { t } = useTranslation('landing')
  return (
    <section
      className='landing-preferences'
      id='preferences'
      style={{
        backgroundImage: `url('assets/_landing/_preferences/bg.png')`,
        backgroundPosition: 'center top',
        backgroundRepeat: 'no-repeat',
        backgroundSize: ' cover',
        backgroundColor: 'rgba(39, 45, 62, 0.38)',
      }}
    >
      <div
        className='landing-section-container landing-preferences-container'
        style={{ backgroundImage: `url('assets/_landing/_preferences/image.png')`, backgroundRepeat: 'no-repeat' }}
      >
        <div
          className='landing-preferences-showcase'
          style={{
            backgroundImage: `url('assets/_landing/_preferences/image.png')`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: hebrew() ? 'left' : 'right',
          }}
        >
          <div className='landing-preferences-desc'>
            <p className='landing-preferences-tagline'>
              <Trans
                i18nKey='preferences.tagline' // the key in your JSON file
                ns='landing'
                values={{
                  // The variables in your i18n entry from the JSON file
                  taglineWord: t('preferences.taglineWord'),
                }}
                components={[<strong></strong>]} // The component at index 0 (<strong />) will be parent of <0> ({{- key}}), and so on...
              />
            </p>
            <h5 className='landing-preferences-title'>{t('preferences.title')}</h5>
            <p className='landing-preferences-subtitle'>
              {' '}
              <Trans
                i18nKey='preferences.subTitle' // the key in your JSON file
                ns='landing'
                values={{
                  // The variables in your i18n entry from the JSON file
                  subTitleBold: t('preferences.subTitleBold'),
                }}
                components={[<strong></strong>]} // The component at index 0 (<strong />) will be parent of <0> ({{- key}}), and so on...
              />
            </p>
          </div>
        </div>
        <div className='landing-preferences-cards'>
          <div className='landing-preferences-card'>
            <div className='landing-preferences-card-icon'>
              <img src='./assets/_landing/_preferences/1.png' alt='1' />
            </div>
            <div className='landing-preferences-card-title'>{t('preferences.card1Title')}</div>
            <div className='landing-preferences-card-text'>{t('preferences.card1Text')}</div>
          </div>
          <div className='landing-preferences-card'>
            <div className='landing-preferences-card-icon'>
              <img src='./assets/_landing/_preferences/2.png' alt='2' />
            </div>
            <div className='landing-preferences-card-title'>{t('preferences.card2Title')}</div>
            <div className='landing-preferences-card-text'>{t('preferences.card2Text')}</div>
          </div>
          <div className='landing-preferences-card'>
            <div className='landing-preferences-card-icon'>
              <img src='./assets/_landing/_preferences/3.png' alt='3' />
            </div>
            <div className='landing-preferences-card-title'>{t('preferences.card3Title')}</div>
            <div className='landing-preferences-card-text'>{t('preferences.card3Text')}</div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LandingPreferences
