import { useTranslation } from 'react-i18next'
import { DescriptionSharp, WarningSharp } from '@mui/icons-material'
import Loader from '../../../../components/Common/Loader/Loader'
import CustomerTablesNavbar from '../../../customer/components/CustomerTablesNavbar/CustomerTablesNavbar'
import { useState } from 'react'
import AgencyHouseDownloadSummary from '../AgencyHouseDownloadSummary/AgencyHouseDownloadSummary'
import AgencyHouseDownloadHistory from '../AgencyHouseDownloadHistory/AgencyHouseDownloadHistory'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { setAgencyDbName } from '../../../download/actions/downloadActions'
import { getCrawlerCompanies } from '../../../shared/actions/sharedActions'
import './AgencyHouseDownloadSummaryContainer.scss'

const downloadSummaryType = {
  summary: 1,
  history: 2,
}

const AgencyHouseDownloadSummaryContainer = ({
  Agencies,
  agenciesLoading,
  AvailableReports,
  FailedReports,
  history,
  singleAgency = false,
}) => {
  const [currentSummaryType, setCurrentSummaryType] = useState(downloadSummaryType.summary)
  const { t } = useTranslation('download')
  const dispatch = useDispatch()

  const { agencyDbName } = useParams()

  useEffect(() => {
    if (agencyDbName) {
      dispatch(setAgencyDbName(agencyDbName))
      dispatch(getCrawlerCompanies())
    }
  }, [agencyDbName, dispatch])

  if (agenciesLoading)
    return (
      <div className='agency-house-download-summary-container'>
        <div className='agency-house-download-summary-padding-wrapper' style={{ display: 'flex', flex: '1' }}>
          <div className='agency-house-download-summary-width-wrapper' style={{ display: 'flex' }}>
            <Loader />
          </div>
        </div>
      </div>
    )

  return (
    <div className='agency-house-download-summary-container'>
      <div className='agency-house-download-summary-padding-wrapper'>
        <div className='agency-house-download-summary-width-wrapper'>
          <h5>{t('sidebar.heading')}</h5>
          <div className='agency-house-download-summary-cards'>
            <div>
              <div>
                <p>{AvailableReports}</p>
                <div>
                  <DescriptionSharp />
                </div>
              </div>
              <p>{t('availableReports')}</p>
            </div>
            <div>
              <div>
                <p>{FailedReports}</p>
                <div>
                  <WarningSharp />
                </div>
              </div>
              <p>{t('failedReports')}</p>
            </div>
          </div>
          <CustomerTablesNavbar
            navbarItems={[
              { text: t('summaryNavbar.summary'), type: downloadSummaryType.summary },
              { text: t('summaryNavbar.history'), type: downloadSummaryType.history },
            ]}
            currentTableType={currentSummaryType}
            setCurrentTable={({ type }) => setCurrentSummaryType(type)}
          />
        </div>
      </div>
      <div className='agency-house-download-summary-content'>
        <div className='agency-house-download-summary-padding-wrapper' style={{ display: 'flex', height: '100%' }}>
          <div
            className='agency-house-download-summary-width-wrapper'
            // style={{ display: 'flex', flex: '1' }}
          >
            {currentSummaryType === downloadSummaryType.summary && (
              <AgencyHouseDownloadSummary
                agencies={Agencies.filter(a => (agencyDbName ? a.DbName === agencyDbName : true))}
                singleAgency={singleAgency}
              />
            )}
            {currentSummaryType === downloadSummaryType.history && (
              <AgencyHouseDownloadHistory
                history={history.map(h => ({
                  ...h,
                  Agencies: h.Agencies.filter(a => (agencyDbName ? a.DbName === agencyDbName : true)),
                }))}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AgencyHouseDownloadSummaryContainer
