import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { entityStatus } from '../../../types/entityStatusType'
import './EntityStatusChip.scss'

const EntityStatusChip = ({ status, shortName = false, styles }) => {
  const { i18n } = useTranslation()
  if (!entityStatus[status]) {
    return null
  }

  const { backgroundColor, color, text, textEn, textShort, textEnShort } = entityStatus[status]
  const chipContent = i18n.language === 'he' ? (shortName ? textShort : text) : shortName ? textEnShort : textEn
  return (
    <>
      {status && (
        <div className='entity-status-chip-container'>
          <div className={classNames('entity-status-chip')} style={{ backgroundColor, color, ...styles }}>
            {chipContent}
          </div>
        </div>
      )}
    </>
  )
}

export default EntityStatusChip
