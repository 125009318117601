import { createPortal } from 'react-dom'
import MobileHeaderBase from '../MobileHeaderBase/MobileHeaderBase'
import './BottomSheetBase.scss'

const BottomSheetBase = ({ open, sheetTitle, closeSheet = () => {}, closeOnOutsideClick = true, children }) => {
  const closeSheetHandler = () => {
    closeSheet()
  }

  if (!open) {
    return null
  }

  return createPortal(
    <div className='bottomsheet-wrapper' {...(closeOnOutsideClick && { onClick: closeSheetHandler })}>
      <div className='bottomsheet-container' onClick={e => e.stopPropagation()}>
        <div className='bottomsheet-handle'></div>
        <MobileHeaderBase text={sheetTitle} goBack={closeSheetHandler} styles={{ background: 'transparent' }} />
        <div className='bottomsheet-content'>{children}</div>
      </div>
    </div>,
    document.body
  )
}

export default BottomSheetBase
