import { useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import PaymentFrame from '../../components/PaymentFrame/PaymentFrame'
import { agencyAfterLoginPath } from '../../routes/routes'
import { useSelector } from 'react-redux'
import './NoPayment.scss'

const NoPayment = () => {
  const { noPayment } = useSelector(({ shared }) => shared)
  const { t } = useTranslation('common')
  const history = useHistory()
  const paymentSuccess = false
  const { nocard } = useParams()

  const goAfterPaymentPath = useCallback(() => history.push(agencyAfterLoginPath()), [history])

  useEffect(() => {
    !noPayment && goAfterPaymentPath()
  }, [noPayment, goAfterPaymentPath])

  return (
    <>
      <div className='no-payment-wrapper'>
        {!paymentSuccess ? (
          <>
            <h3 className='no-payment-title'>{t('noPaymentTitle')}</h3>
            {!nocard && <h4 className='no-payment-subtitle'>{t('noPaymentSubtitle')}</h4>}
          </>
        ) : (
          <>
            <h3>{t('paymentThankMsg')}</h3>
            <h4>{t('paymentSuccess')}</h4>
            <h4>{t('paymentDetailsSuccess')}</h4>
          </>
        )}
        <PaymentFrame onPaymentSuccess={() => goAfterPaymentPath()} />
      </div>
    </>
  )
}

export default NoPayment
