import { useTranslation } from 'react-i18next'
import PolicyListItemCard from '../PolicyListItemCard/PolicyListItemCard'
import './PolicyIrregs.scss'
import { getMonthName } from '../../../../services/getMonthName'
import { irregularReason, irregularReasonObject } from '../../../../types/irregularReasonTypes'
import { hebrew } from '../../../../i18n'
import PolicyStatusChip from '../../../../components/Common/PolicyStatusChip/PolicyStatusChip'
import { policyStatusType } from '../../../../types/policyStatusType'
import ButtonsChangeStatus from '../../../../components/Common/ButtonsChangeStatus/ButtonsChangeStatus'
import { useState } from 'react'
import { ignoreIrregularityRequest } from '../../../../http/requests/irregularitiesRequests'
import AddUserModal from '../../../customer/components/AddUserModal/AddUserModal'
import { useSelector } from 'react-redux'
import ModalWithNote from '../../../../components/Common/ModalWithNote/ModalWithNote'
import { customerTaskType } from '../../../../types/customerTaskTypes'
import { addTreatmentRequest, fixIrregularityRequest } from '../../../../http/requests/treatmentRequests'
import { deactivatePolicyRequest } from '../../../../http/requests/policyRequests'
import LoadingOverlay from '../../../../components/Common/LoadingOverlay/LoadingOverlay'
import useApiRequest from '../../../../hooks/useApiRequest'
import { formatAsCurrency, formatAsPercent, formatAsShortDate } from '../../../../utils/formatAs'
import { cn } from '../../../../utils/stylesUtils'

const PolicyIrregs = ({ irregs, policy, onIrregularityAction = () => {} }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('policies')
  const { t: tCustomer } = useTranslation('customer')
  const { loading: addTreatLoading, fetch: addTreat } = useApiRequest(addTreatmentRequest, { loadingOnInit: false })
  const { loading: fixLoading, fetch: fixIrregualrity } = useApiRequest(fixIrregularityRequest, {
    loadingOnInit: false,
  })
  //fixIrregularitiesRequest
  const { loading: ignoreLoading, fetch: ignoreIrregualrity } = useApiRequest(ignoreIrregularityRequest, {
    loadingOnInit: false,
  })
  const { loading: deactivateLoading, fetch: deactivatePolicy } = useApiRequest(deactivatePolicyRequest, {
    loadingOnInit: false,
  })

  const irregularityLoading = addTreatLoading || fixLoading || ignoreLoading || deactivateLoading

  // console.log('addTreatResult', addTreatResult)
  // console.log('addTreatError', addTreatError)
  // console.log('addTreatLoading', addTreatLoading)

  const [selectedIrrty, setSelectedIrrty] = useState()
  const [treatInfo, setTreatInfo] = useState(null)
  const [showAgentsModal, setShowAgentsModal] = useState(false)
  const [showTreatNoteModal, setShowTreatNoteModal] = useState(false)
  const [showTreatedNoteModal, setShowTreatedNoteModal] = useState(false)

  const { agents, agentsLoading } = useSelector(({ shared }) => shared)

  const addTreatAgent = agent => {
    setTreatInfo({ ...treatInfo, agentId: agent.id, agentName: agent.name })
    setShowAgentsModal(false)
    setShowTreatNoteModal(true)
  }

  const addTreatAgentNote = async note => {
    setTreatInfo({ ...treatInfo, note })
    const treatDto = {
      policyId: policy.id,
      agentId: treatInfo.agentId,
      irregularityId: treatInfo.irrtyId,
      customerId: policy.clientId ?? policy.customerId,
      note,
      type: customerTaskType.irregularity,
    }
    await addTreat({ params: treatDto })
    onIrregularityAction()
  }

  const addTreatedNote = async note => {
    await fixIrregualrity({ params: { irregularityId: selectedIrrty.id, note } })
    onIrregularityAction()
  }

  const ignoreIrregularityHandler = async irregularityId => {
    await ignoreIrregualrity({ params: [irregularityId] })
    onIrregularityAction()
  }

  const deactivate = async () => {
    await deactivatePolicy({ params: policy.id })
    onIrregularityAction()
  }

  const buildTreatMsg = ({ agentName, date }) => {
    const formattedDate = formatAsShortDate(date, '.')
    return t('policyCard.sendForTreatMsg', { agentName, date: formattedDate })
  }

  if (irregs.length === 0) {
    return null
  }

  return (
    <>
      <div>
        <div className='policy-irregs-grid policy-sidecard-list-heading'>
          <div>{t('list.heading.irregStartDate')}</div>
          <div>{t('list.heading.irregReason')}</div>
          <div>{t('list.heading.irregSum')}</div>
          <div></div>
          <div></div>
        </div>
        {irregs.map(irr => (
          <PolicyListItemCard classes={' policy-irreg-item'} key={irr.id}>
            <div className='policy-irregs-grid'>
              {irregularityLoading && selectedIrrty.id === irr.id && <LoadingOverlay />}
              <div>
                {getMonthName(irr.fromMonth, language)} {new Date(irr.fromMonth).getFullYear()}
              </div>
              <div>{irregularReasonObject[irr.irregularReason]?.[`name${hebrew() ? '' : 'En'}`]}</div>
              <div className={cn('financial-value-cell', { 'irreg-value': irr.amountRelevant != null })}>
                {irr.irregularReason === irregularReason.commissionRate
                  ? formatAsPercent(irr.amountRelevant)
                  : formatAsCurrency(irr.amountRelevant)}
              </div>
              <div>
                <ButtonsChangeStatus
                  treat={() => {
                    setSelectedIrrty(irr)
                    setTreatInfo({ ...treatInfo, currAgent: irr.agentId, irrtyId: irr.id })
                    setShowAgentsModal(true)
                  }}
                  deactivate={
                    policy.status !== policyStatusType.notActive &&
                    irr.irregularReason === irregularReason.missingPayment &&
                    (() => {
                      setSelectedIrrty(irr)
                      deactivate()
                    })
                  }
                  //fix irregularity, mark it has been treated
                  markTreated={
                    irr.irregularReason !== irregularReason.missingPayment &&
                    (() => {
                      setSelectedIrrty(irr)
                      setShowTreatedNoteModal(true)
                    })
                  }
                  // ignore, meaning that irregularity is correct
                  ignore={() => {
                    setSelectedIrrty(irr)
                    ignoreIrregularityHandler(irr.id)
                  }}
                />
              </div>
              <div>
                <PolicyStatusChip status={policyStatusType.irregular} />
              </div>
            </div>
            {irr.treatmentInfo.hasActiveTreatment && (
              <div className='irreg-remark'>
                {/* {buildTreatMsg(irr.treatmentInfo.sort((a, b) => new Date(b.date) - new Date(a.date))[0])} */}
                {buildTreatMsg(irr.treatmentInfo)}
              </div>
            )}
          </PolicyListItemCard>
        ))}
      </div>
      {showAgentsModal && (
        <AddUserModal
          users={agents}
          loading={agentsLoading}
          closeModal={() => {
            setShowAgentsModal(false)
          }}
          closeOnOutsideClick={false}
          onAddUser={addTreatAgent}
          currentUser={selectedIrrty?.treatmentInfo?.agentId}
          askConfirm={
            selectedIrrty?.treatmentInfo?.agentId && selectedIrrty?.treatmentInfo?.agentId !== treatInfo?.currAgent
          }
        />
      )}
      {showTreatNoteModal && (
        <ModalWithNote
          closeModal={() => setShowTreatNoteModal(false)}
          closeOnOutsideClick={false}
          title={tCustomer('treatNoteModal.modalTitle')}
          subtitle={tCustomer('treatNoteModal.modalSubtitle')}
          label={tCustomer('treatNoteModal.inputLabel')}
          btnCaption={tCustomer('treatNoteModal.btnTitle')}
          back={() => setShowAgentsModal(true)}
          onAddNote={addTreatAgentNote}
        />
      )}
      {showTreatedNoteModal && (
        <ModalWithNote
          closeModal={() => setShowTreatedNoteModal(false)}
          closeOnOutsideClick={false}
          title={tCustomer('alreadyTreatedNoteModal.modalTitle')}
          subtitle={tCustomer('alreadyTreatedNoteModal.modalSubtitle')}
          label={tCustomer('alreadyTreatedNoteModal.inputLabel')}
          btnCaption={tCustomer('alreadyTreatedNoteModal.btnTitle')}
          onAddNote={addTreatedNote}
        />
      )}
    </>
  )
}

export default PolicyIrregs
