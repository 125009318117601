import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SHARED_AGENCY_INVITE_UPDATE } from '../../../../types/actionTypesShared'
import { getAgencyInvites } from '../../actions/sharedActions'
import ModalAgencyInvite from '../ModalAgencyInvite/ModalAgencyInvite'
import { agencyUserRoleType } from '../../../../types/agencyUserTypes'

const AgencyHouseInviteNotyfier = () => {
  const { UserId, role } = useSelector(({ login }) => login)

  const { agencyInvites } = useSelector(({ shared }) => shared)
  const dispatch = useDispatch()

  const [showInviteAgencyModal, setShowInviteAgencyModal] = useState(false)
  const closeInviteAgencyModalHandler = () => {
    setShowInviteAgencyModal(false)
    dispatch({ type: SHARED_AGENCY_INVITE_UPDATE, payload: { ...notShownAgencyInvite, shown: true } })
  }
  const notShownAgencyInvite = agencyInvites.find(i => !i.shown)
  useEffect(() => {
    UserId && role === agencyUserRoleType.fullUserAccess && dispatch(getAgencyInvites())
  }, [UserId, role, dispatch])

  useEffect(() => {
    agencyInvites.length > 0 && agencyInvites.find(i => !i.shown) && setShowInviteAgencyModal(true)
  }, [agencyInvites])

  return (
    <>
      {showInviteAgencyModal && UserId && (
        <ModalAgencyInvite closeModal={closeInviteAgencyModalHandler} invite={notShownAgencyInvite} />
      )}
    </>
  )
}

export default AgencyHouseInviteNotyfier
