import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import common_he from './translations/he/common.json'
import common_en from './translations/en/common.json'
import dashboard_he from './translations/he/dashboard.json'
import dashboard_en from './translations/en/dashboard.json'
import download_he from './translations/he/download.json'
import download_en from './translations/en/download.json'
import customers_he from './translations/he/customers.json'
import customers_en from './translations/en/customers.json'
import customer_he from './translations/he/customer.json'
import customer_en from './translations/en/customer.json'
import analytics_he from './translations/he/analytics.json'
import analytics_en from './translations/en/analytics.json'
import agencyHouse_he from './translations/he/agencyHouse.json'
import agencyHouse_en from './translations/en/agencyHouse.json'
import agencyHouseLobby_he from './translations/he/agencyHouseLobby.json'
import agencyHouseLobby_en from './translations/en/agencyHouseLobby.json'
import agencyHouseAnalytics_he from './translations/he/agencyHouseAnalytics.json'
import agencyHouseAnalytics_en from './translations/en/agencyHouseAnalytics.json'
import profile_he from './translations/he/profile.json'
import profile_en from './translations/en/profile.json'
import landing_he from './translations/he/landing.json'
import landing_en from './translations/en/landing.json'
import policies_he from './translations/he/policies.json'
import policies_en from './translations/en/policies.json'
import workstation_he from './translations/he/workstation.json'
import workstation_en from './translations/en/workstation.json'
import lobby_he from './translations/he/lobby.json'
import lobby_en from './translations/en/lobby.json'
import registration_he from './translations/he/registration.json'
import registration_en from './translations/en/registration.json'
import early_he from './translations/he/early.json'
import early_en from './translations/en/early.json'
import paymentPlans_he from './translations/he/paymentPlans.json'
import paymentPlans_en from './translations/en/paymentPlans.json'
import scan_he from './translations/he/scan.json'
import scan_en from './translations/en/scan.json'
import { getItemFromLocalStorage } from './services/localStorageService'

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
const resources = {
  he: {
    common: common_he,
    analytics: analytics_he,
    agencyHouse: agencyHouse_he,
    agencyHouseLobby: agencyHouseLobby_he,
    agencyHouseAnalytics: agencyHouseAnalytics_he,
    customers: customers_he,
    customer: customer_he,
    dashboard: dashboard_he,
    download: download_he,
    profile: profile_he,
    landing: landing_he,
    policies: policies_he,
    workstation: workstation_he,
    lobby: lobby_he,
    registration: registration_he,
    early: early_he,
    paymentPlans: paymentPlans_he,
    scan: scan_he,
  },
  en: {
    common: common_en,
    analytics: analytics_en,
    agencyHouse: agencyHouse_en,
    agencyHouseLobby: agencyHouseLobby_en,
    agencyHouseAnalytics: agencyHouseAnalytics_en,
    customers: customers_en,
    customer: customer_en,
    dashboard: dashboard_en,
    download: download_en,
    profile: profile_en,
    landing: landing_en,
    policies: policies_en,
    workstation: workstation_en,
    lobby: lobby_en,
    registration: registration_en,
    early: early_en,
    paymentPlans: paymentPlans_en,
    scan: scan_en,
  },
}

const currLang = getItemFromLocalStorage('lang') === '' ? 'he' : getItemFromLocalStorage('lang')

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: currLang, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n

export const hebrew = () => i18n.language === 'he'
export const rtlDir = () => i18n.dir() === 'rtl'
