import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ProfileCompanyItem from '../ProfileCompanyItem/ProfileCompanyItem'
import ProfileAddCompanyModal from '../ProfileAddCompanyModal/ProfileAddCompanyModal'
import ConfirmDialog from '../../../../components/ConfirmDialog/ConfirmDialog'
import NotifyDialog from '../../../../components/NotifyDialog/NotifyDialog'
import { nanoid } from '@reduxjs/toolkit'
import { useTranslation } from 'react-i18next'
import { deleteCompany } from '../../actions/profileActions'
import Loader from '../../../../components/Common/Loader/Loader'
import ProfileEditEmailCompany from '../ProfileEditEmailCompany/ProfileEditEmailCompany'
import ButtonRounded from '../../../../components/Common/ButtonRounded/ButtonRounded'
import { Add } from '@mui/icons-material'
import './ProfileCompanies.scss'

const ProfileCompanies = () => {
  const { companies, companiesLoading } = useSelector(state => state.profile)

  const [companiesToRemove, setCompaniesToRemove] = useState([])
  const [currentCompany, setCurrentCompany] = useState({})
  const [companyToEdit, setCompanyToEdit] = useState({})
  const [showEditEmailModal, setShowEditEmailModal] = useState(false)
  const [showAddModal, setShowAddModal] = useState(false)
  const [showRemoveModal, setShowRemoveModal] = useState(false)
  const [notifications, setNotifications] = useState([])
  const [selectedCompanies, setSelectedCompanies] = useState([])

  const dispatch = useDispatch()
  const { t, i18n } = useTranslation('profile')

  const availableCompanies = companies.filter(c => !companiesToRemove.includes(c.id))
  const companiesCountText = companiesLoading ? '' : ` (${availableCompanies.length})`

  const selectCompany = id => {
    setSelectedCompanies(prev => (prev.includes(id) ? prev.filter(pr => pr !== id) : [...prev, id]))
  }

  const showRemoveDialog = company => {
    setShowRemoveModal(true)
    setCurrentCompany(company)
  }

  const markCompanyToRemove = () => {
    const notificationId = nanoid()
    setNotifications(prev => [
      ...prev,
      {
        id: notificationId,
        element: (
          <NotifyDialog
            text={i18n.language === 'he' ? `חברת ${currentCompany.name} הוסרה` : `Removing ${currentCompany.name}`}
            closeNotify={() => removeNotification(notificationId)}
            closeNotifyTimout={5000}
            key={notificationId}
            confirm={() => cancelCompanyToRemove(currentCompany.id)}
            onCloseAction={() => removeCompanyHandler(currentCompany.id)}
            confirmText={t('deleteCompanyNotify.buttonText')}
          />
        ),
      },
    ])
    setCompaniesToRemove(prev => {
      if (!companiesToRemove.includes(currentCompany.id)) return [...prev, currentCompany.id]
      else return prev
    })
  }

  const cancelCompanyToRemove = companyId => {
    setCompaniesToRemove(prev => prev.filter(cmpnId => cmpnId !== companyId))
  }

  const editEmailCompanyHandler = companyId => {
    const company = companies.find(company => company.id === companyId)
    setCompanyToEdit(company)
    setShowEditEmailModal(true)
  }

  const removeCompanyHandler = companyId => {
    dispatch(deleteCompany(companyId))
    setCompaniesToRemove(prev => prev.filter(company => company !== companyId))
  }

  const removeNotification = notificationId => {
    setNotifications(prev => prev.filter(notification => notification.id !== notificationId))
  }

  return (
    <div className='profile-companies-container'>
      <header>
        <p>
          {t('companies.companiesYouWork')}
          {companiesCountText}
        </p>
        <ButtonRounded size='large' onClick={() => setShowAddModal(true)}>
          <Add />
          <span>{t('companies.addCompanyBtn')}</span>
        </ButtonRounded>
      </header>
      {companiesLoading && <Loader />}
      {!companiesLoading && (
        <div>
          <div>
            <div className='profile-companies-table-header profile-company-item-grid'>
              <p className='profile-company-info'>{t('companies.companyNameHeading')}</p>
              <p className='profile-company-fields'>{t('companies.companyFieldsHeading')}</p>
              <p className='profile-company-site'>{t('companies.companySiteHeading')}</p>
              <div></div>
            </div>
          </div>
          {availableCompanies.map(c => (
            <ProfileCompanyItem
              key={c.id}
              company={c}
              editCompany={() => editEmailCompanyHandler(c.id)}
              removeCompany={() => showRemoveDialog(c)}
              selected={selectedCompanies.includes(c.id)}
              selectCompany={() => selectCompany(c.id)}
            />
          ))}
        </div>
      )}
      {showEditEmailModal && (
        <ProfileEditEmailCompany closeSidebar={() => setShowEditEmailModal(false)} company={companyToEdit} />
      )}
      {showAddModal && <ProfileAddCompanyModal closeModal={() => setShowAddModal(false)} />}
      {showRemoveModal && (
        <ConfirmDialog
          confirm={markCompanyToRemove}
          decline={() => {
            setShowRemoveModal(false)
          }}
          title={t('deleteCompanyModal.modalTitle')}
          text={
            i18n.language === 'he'
              ? `האם אתה בטוח שאתה רוצה להסיר את ${currentCompany.name}?`
              : `Are you sure you want to remove ${currentCompany.nameEn}?`
          }
          confirmText={t('deleteCompanyModal.modalButton.remove')}
          declineText={t('deleteCompanyModal.modalButton.cancel')}
        />
      )}

      {notifications.length > 0 && (
        <div className='notify-modals-wrapper'>
          <div className='notify-modals-container'>{notifications.map(modal => modal.element)}</div>
        </div>
      )}
    </div>
  )
}

export default ProfileCompanies
