import AddAgencySummaryContainer from '../AddAgencySummaryContainer/AddAgencySummaryContainer'
import { useTranslation } from 'react-i18next'
import { hebrew } from '../../../../i18n'
import { insuranceField } from '../../../../types/insuranceField'
import AddAgencySummaryItem from '../AddAgencySummaryItem/AddAgencySummaryItem'
import { BallotTwoTone, BusinessTwoTone, CardTravel } from '@mui/icons-material'
import CashPlusIconSvg from '../../../../components/Common/SvgIcons/CashPlusIconSvg/CashPlusIconSvg'
import { useState } from 'react'
import ButtonText from '../../../../components/Common/ButtonText/ButtonText'

const DEFAULT_ITEMS_TO_SHOW = 5

const AddNewAgencySummary = ({ newAgency, addOneMore, finish }) => {
  const { t } = useTranslation('agencyHouse')
  const tNewAgencyForm = key => `${t('addAgencyStep.addNewAgencyForm.' + key)}`
  const tNewAgencySummary = (key, params) => `${t('addAgencyStep.addAgencySummary.' + key, { ...params })}`
  const [companiesExpanded, setCompaniesExpanded] = useState(false)

  return (
    <AddAgencySummaryContainer addOneMore={addOneMore} finish={finish}>
      <AddAgencySummaryItem
        title={tNewAgencySummary('agencyDetails')}
        icon={<CardTravel style={{ fontSize: '40px' }} />}
      >
        <div>
          <p>
            {tNewAgencyForm('agencyName')}: {newAgency.agencyName}
          </p>
          <p>
            {tNewAgencyForm('license')}: {newAgency.agencyNumber}
          </p>
        </div>
      </AddAgencySummaryItem>
      <AddAgencySummaryItem title={tNewAgencySummary('payment')} icon={<CashPlusIconSvg />}>
        <p>{tNewAgencySummary('paymentByAgency')}</p>
      </AddAgencySummaryItem>
      <AddAgencySummaryItem title={tNewAgencySummary('fields')} icon={<BallotTwoTone style={{ fontSize: '40px' }} />}>
        <div className='add-agency-summary-item-card-content-pairs'>
          {newAgency.fields.map(f => (
            <div key={f.Id}>
              {insuranceField[f.Id].svgIcon}
              <p>{hebrew() ? f.Name : f.EngilshName}</p>
            </div>
          ))}
        </div>
      </AddAgencySummaryItem>
      <AddAgencySummaryItem
        title={tNewAgencySummary('companies', { count: newAgency.companies.length })}
        icon={<BusinessTwoTone style={{ fontSize: '40px' }} />}
        styles={companiesExpanded && { height: 'fit-content' }}
      >
        <div className='add-agency-summary-item-card-content-pairs pairs-grid'>
          {newAgency.companies
            .slice(0, companiesExpanded ? newAgency.companies.length : DEFAULT_ITEMS_TO_SHOW)
            .map(c => (
              <div key={c.companyId}>
                <img src={`./assets/companies-logos-light/${c.companyId}.png`} alt={c.name} />
                <p>{hebrew() ? c.Name : c.EnglishName}</p>
              </div>
            ))}
          {newAgency.companies.length > 5 && (
            <ButtonText onClick={() => setCompaniesExpanded(!companiesExpanded)}>
              {tNewAgencySummary(`show${companiesExpanded ? 'Less' : 'More'}Btn`)}
            </ButtonText>
          )}
        </div>
      </AddAgencySummaryItem>
    </AddAgencySummaryContainer>
  )
}

export default AddNewAgencySummary
