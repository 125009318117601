import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { customerRemoveMember } from '../../actions/customerActions'
import { infoDetailsType, infoDetails } from '../../../../types/infoDetailsTypes'
import ExportButton from '../../../../components/Common/ExportButton/ExportButton'
import CustomerAddFamilyMember from '../CustomerAddFamilyMember/CustomerAddFamilyMember'
import CustomerDetailsInput from './CustomerDetailsInput'
import './CustomerDetailsListItem.scss'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

const CustomerDetailsListItem = ({ detailsType, content, setContent }) => {
  const [showInput, setShowInput] = useState(false)
  const { t, i18n } = useTranslation('customer')
  const dispatch = useDispatch()

  if (detailsType === infoDetailsType.phone && content && content.length === 13) {
    content = content.substring(0, 4) + ' ' + content.substring(4, 6) + ' ' + content.substring(6, 13)
  }

  const deleteMemberHandler = memberId => {
    dispatch(customerRemoveMember(memberId))
  }

  return (
    <div className='customer-details-list-item'>
      <div className='customer-details-list-item-icon'>
        <img src={infoDetails[detailsType].iconPath} alt='' className='img-icon' />
      </div>
      <div className='customer-details-list-item-content'>
        {detailsType === infoDetailsType.group ? (
          <>
            <div className='customer-details-list-item-title-container'>
              <div className='customer-details-list-item-title'>
                {content && content.length
                  ? `${i18n.language === 'he' ? infoDetails[detailsType].value : infoDetails[detailsType].valueEn} (${
                      content.length
                    })`
                  : t('infoCard.belongsToGroup')}
              </div>
              <CustomerAddFamilyMember />
            </div>
            {content && content.length > 0 && (
              <div className='customer-details-list-item-family'>
                {content.map(item => (
                  <div className='customer-details-list-item-family-member' key={item.id}>
                    <div className={classNames('family-member-name', { inactive: !item.active })}>{item.name}</div>
                    <div className='family-member-icons'>
                      <div className='family-member-delete-icon' onClick={() => deleteMemberHandler(item.id)}>
                        <img src='./assets/delete-icon/Delete.png' alt='' className='img-icon' />
                      </div>
                      <NavLink to={`/customers/${item.id}`}>
                        <ExportButton />
                      </NavLink>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </>
        ) : content && content.length > 0 ? (
          <>
            <div className='customer-details-list-item-title'>
              {i18n.language === 'he' ? infoDetails[detailsType].value : infoDetails[detailsType].valueEn}
            </div>
            <div className='customer-details-list-item-text'>{content}</div>
          </>
        ) : !showInput ? (
          <>
            <div className='customer-details-list-item-title'>
              {i18n.language === 'he' ? infoDetails[detailsType].value : infoDetails[detailsType].valueEn}
            </div>
            <div className='customer-details-list-item-empty'>
              <div className='customer-details-list-item-text' onClick={() => setShowInput(true)}>
                {i18n.language === 'he' ? infoDetails[detailsType].placeHolder : infoDetails[detailsType].placeHolderEn}
              </div>
              <div className='customer-details-list-item-empty-icon' onClick={() => setShowInput(true)}>
                <img src='./assets/edit-icon/1.png' alt='' className='img-icon' />
              </div>
            </div>
          </>
        ) : (
          <CustomerDetailsInput
            title={i18n.language === 'he' ? infoDetails[detailsType].value : infoDetails[detailsType].valueEn}
            detailsType={detailsType}
            closeInput={() => setShowInput(false)}
            applyChanges={setContent}
          />
        )}
      </div>
    </div>
  )
}

export default CustomerDetailsListItem
