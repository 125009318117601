export const phoneCodes = {
  ua: {
    countryCode: '+380',
    areaCodes: [50, 63, 73, 93, 95, 96],
  },
  il: {
    countryCode: '+972',
    areaCodes: [50, 51, 52, 53, 54, 55, 58, 59, 71, 72, 73, 74, 76, 77, 79],
  },
}
