import { useCallback, useEffect, useState } from 'react'
import { hebrew } from '../../../../i18n'
import {
  deleteCompanyUserRequest,
  getCompanyFieldsRequest,
  getCompanyUsersRequest,
} from '../../../../http/requests/agencyCompanyRequests'
import Loader from '../../../../components/Common/Loader/Loader'
import { useTranslation } from 'react-i18next'
import ButtonRounded from '../../../../components/Common/ButtonRounded/ButtonRounded'
import NoData from '../../../../components/Common/NoData/NoData'
import { ArrowBack, ArrowForward } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import ScanCompanyRegisterUserModal from '../ScanCompanyRegisterUserModal/ScanCompanyRegisterUserModal'
import LoadingOverlay from '../../../../components/Common/LoadingOverlay/LoadingOverlay'
import ConfirmDeleteDialog from '../../../../components/ConfirmDeleteDialog/ConfirmDeleteDialog'
import './ScanCompanyNoLoginInfo.scss'
import { lowercaseObjectKeys } from '../../../../utils/objectUtils'

const ScanCompanyNoLoginInfo = ({ company, addUser, onAddUser = () => {}, onUsersUpdated = () => {} }) => {
  const { t } = useTranslation('scan')
  const [users, setUsers] = useState([])
  const [usersLoading, setUsersLoading] = useState(true)
  const [availableFields, setAvailableFields] = useState([])
  const [userToEdit, setUserToEdit] = useState(null)
  const [userToDelete, setUserToDelete] = useState(null)
  const [deleteUsersLoading, setDeleteUsersLoading] = useState([])
  const closeRegisterModalHandler = () => {
    if (addUser) {
      onAddUser()
    } else {
      setUserToEdit(null)
    }
  }

  const onUserSuccesHandler = data => {
    addUser ? onAddUser() : setUserToEdit(null)
    fetchUsers({ showLoading: false })
    onUsersUpdated('add or update')
  }

  const deleteUser = async id => {
    try {
      setUserToDelete(null)
      setDeleteUsersLoading(prev => [...prev, id])
      const {
        data: { data },
      } = await deleteCompanyUserRequest(id)
      setDeleteUsersLoading(prev => prev.filter(pr => pr !== id))
      if (data) {
        fetchUsers({ showLoading: false })
        onUsersUpdated('deleted')
      }
    } catch (error) {
      setDeleteUsersLoading(prev => prev.filter(pr => pr !== id))
    }
  }

  const fetchUsers = useCallback(
    async ({ showLoading = true } = {}) => {
      try {
        showLoading && setUsersLoading(true)
        const {
          data: { data },
        } = await getCompanyUsersRequest(company.companyId)
        const {
          data: { Data: fields },
        } = await getCompanyFieldsRequest(company.companyId)
        setUsersLoading(false)
        setUsers(data)
        setAvailableFields(lowercaseObjectKeys(fields))
      } catch (error) {
        setUsersLoading(false)
      }
    },
    [company.companyId]
  )

  useEffect(() => {
    fetchUsers()
  }, [fetchUsers])

  if (usersLoading || users.length === 0) {
    return (
      <>
        <div className='scan-company-no-login'>
          {usersLoading ? (
            <Loader />
          ) : (
            <div>
              <NoData text={t('companyUsers.noUsers')} />
            </div>
          )}
        </div>
        {!usersLoading && (userToEdit || addUser) && (
          <ScanCompanyRegisterUserModal
            availableFields={availableFields}
            company={company}
            closeModal={closeRegisterModalHandler}
            user={userToEdit}
            onSuccessUpdate={onUserSuccesHandler}
          />
        )}
      </>
    )
  }

  return (
    <>
      <div className='scan-company-no-login'>
        <div className='scan-company-no-login-header'>
          <p>{t('companyUsers.selectUserToEdit')}</p>
          <Link to={`${company.companyId}`}>
            <span>{t('companyUsers.backToReports')}</span>
            {hebrew() ? <ArrowBack /> : <ArrowForward />}
          </Link>
        </div>
        <div className='scan-company-users-list'>
          {users.map(u => (
            <div key={u.id} className='scan-company-user-card'>
              {deleteUsersLoading.includes(u.id) && <LoadingOverlay />}
              <div className='user-info'>
                <p>{u.name}</p>
                <div className='scan-company-user-fields'>
                  {availableFields
                    .filter(f => u.insuranceFieldsArray.includes(f.id))
                    .map(f => (
                      <div key={f.id}>{hebrew() ? f.name : f.engilshName}</div>
                    ))}
                </div>
              </div>
              <div className='user-actions'>
                <ButtonRounded onClick={() => setUserToEdit(u)}>{t('companyUsers.editBtn')}</ButtonRounded>
                <ButtonRounded onClick={() => setUserToDelete(u.id)}>{t('companyUsers.deleteBtn')}</ButtonRounded>
              </div>
            </div>
          ))}
        </div>
      </div>
      {(userToEdit || addUser) && (
        <ScanCompanyRegisterUserModal
          availableFields={availableFields}
          company={company}
          closeModal={closeRegisterModalHandler}
          user={userToEdit}
          onSuccessUpdate={onUserSuccesHandler}
        />
      )}
      {userToDelete && (
        <ConfirmDeleteDialog
          deleteHandler={() => deleteUser(userToDelete)}
          cancelHandler={() => setUserToDelete(null)}
          text={t('companyUsers.deleteUserText')}
          title={t('companyUsers.deleteUserTitle')}
          cancelText={t('companyUsers.cancelBtn')}
          deleteText={t('companyUsers.deleteBtn')}
        />
      )}
    </>
  )
}

export default ScanCompanyNoLoginInfo
