import PaymentIframe from '../PaymentIframe/PaymentIframe'
import './PaymentContainer.scss'

const PaymentContainer = ({ onPaymentSuccess = () => {},successSubmitHandler=()=>{}, subscription = null, updateSubscription = false }) => {
  return (
    <div className='payment-conatiner'>
      <PaymentIframe
        onPaymentSuccess={onPaymentSuccess}
        subscription={subscription}
        updateSubscription={updateSubscription}
        successSubmitHandler = {successSubmitHandler}
      />
    </div>
  )
}

export default PaymentContainer
