import { useTranslation } from 'react-i18next'
import './RegisterFlowTypeCard.scss'

const RegisterFlowTypeCard = ({ flowType, featuresCount, selectFlowType }) => {
  const { t } = useTranslation('registration')

  return (
    <div className='register-flow-type-card'>
      <div className='register-flow-type-card-body'>
        <div className='register-flow-type-card-body-ico'>
          <img src={`./assets/_register/step-flow-type/${flowType}.png`} alt={flowType} />
        </div>
        <p className='register-flow-type-card-body-title'>{t(`stepFlowType.${flowType}.name`)}</p>
        <p className='register-flow-type-card-body-desc'>{t(`stepFlowType.${flowType}.desc`)}</p>
      </div>
      <div className='register-flow-type-card-footer'>
        <ul className='register-flow-type-card-features'>
          {[...Array(featuresCount)].map((_, i) => (
            <li key={'features' + i}>{t(`stepFlowType.${flowType}.features.${i + 1}`)}</li>
          ))}
        </ul>
        <button onClick={() => selectFlowType(flowType)}>{t(`stepFlowType.selectBtn`)}</button>
      </div>
    </div>
  )
}
export default RegisterFlowTypeCard
