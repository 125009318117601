import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { Route, Redirect, Switch, useRouteMatch } from 'react-router-dom'
import { getPoliciesFilter } from '../../features/policies/actions/policiesActions'
import { getCategories } from '../../features/shared/actions/sharedActions'
import AgentGroupsBanner from '../../components/AgentGroupsBanner/AgentGroupsBanner'
import BackToBigAgency from '../../features/shared/components/BackToBigAgency/BackToBigAgency'
import { regularAgencyPrivateRoutes } from '../../routes/routes'
import './PoliciesScreen.scss'

const PoliciesScreen = () => {
  const { filtersLoading } = useSelector(({ policies }) => policies)
  const dispatch = useDispatch()
  const { path } = useRouteMatch()
  const routes = Object.values(regularAgencyPrivateRoutes.find(r => r.path === path).subroute)
  useEffect(() => {
    dispatch(getPoliciesFilter())
    dispatch(getCategories())
  }, [dispatch])

  return (
    <div className='policies-screen-wrapper'>
      <div className='policies-screen-banners'>
        <BackToBigAgency />
        <AgentGroupsBanner />
      </div>
      {!filtersLoading && (
        <Switch>
          <Route path={`${path}`} exact>
            <Redirect to={`${path}${routes[0].path}`} />
          </Route>
          {routes.map(r => (
            <Route key={r.path} path={`${path}${r.path}`} component={r.component} />
          ))}
          <Route path={`${path}/*`}>
            <Redirect to={`${path}${routes[0].path}`} />
          </Route>
        </Switch>
      )}
    </div>
  )
}

export default PoliciesScreen
