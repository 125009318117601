import { useDispatch } from 'react-redux'
import {
  AGENT_HOUSE_DOWNLOAD_AGENCIES_SHOW_COMPANIES,
  AGENT_HOUSE_DOWNLOAD_AGENCIES_SHOW_GROUPS,
} from '../../../../types/actionTypes'
import AgencyHouseDownloadSummaryItem from '../AgencyHouseDownloadSummaryItem/AgencyHouseDownloadSummaryItem'
import { useTranslation } from 'react-i18next'
import './AgencyHouseDownloadSummary.scss'

const AgencyHouseDownloadSummary = ({ agencies = [], singleAgency = false }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('download')

  if (agencies.every(a => [...a.NoAttempts, ...a.AllFailed, ...a.SomeSucceed, ...a.AllSucceed].length === 0)) {
    return (
      <div className='agency-house-download-summary'>
        <p style={{ textAlign: 'center' }}>{t('noAgenicesCompanies')}</p>
      </div>
    )
  }

  return (
    <div className='agency-house-download-summary'>
      {agencies.map(a => (
        <AgencyHouseDownloadSummaryItem
          key={a.Id}
          agency={a}
          groupCompanies
          singleAgency={singleAgency}
          showCompaniesHandler={() => dispatch({ type: AGENT_HOUSE_DOWNLOAD_AGENCIES_SHOW_COMPANIES, payload: a.Id })}
          showGroupsHandler={showGroupType =>
            dispatch({ type: AGENT_HOUSE_DOWNLOAD_AGENCIES_SHOW_GROUPS, payload: { agencyId: a.Id, showGroupType } })
          }
        />
      ))}
    </div>
  )
}

export default AgencyHouseDownloadSummary
