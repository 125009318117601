import { cssColor } from './cssColors'
import { policyRatingType } from './policyRatingTypes'

export const entityStatusType = {
  normal: 'normal',
  active: 'active',
  inactive: 'inactive',
  irregular: 'irregular',
  new: 'new',
  lostData: 'lostData',
  [policyRatingType.red]: 'red',
  [policyRatingType.orange]: 'orange',
  [policyRatingType.green]: 'green',
}

export const entityStatus = {
  [entityStatusType.normal]: {
    text: 'normal',
    textEn: 'normal',
    textShort: 'normal',
    textEnShort: 'normal',
    color: cssColor.primaryText,
    backgroundColor: cssColor.whiteBackgroundTransparent,
  },
  [entityStatusType.active]: {
    text: 'פעילה',
    textEn: 'active',
    textShort: 'פעילה',
    textEnShort: 'act',
    color: cssColor.greenText,
    backgroundColor: cssColor.greenBackgroundTransparent,
  },
  [entityStatusType.inactive]: {
    text: 'לא פעילה',
    textEn: 'inactive',
    textShort: 'לא פעילה',
    textEnShort: 'inact',
    color: cssColor.primaryText,
    backgroundColor: cssColor.whiteBackgroundTransparent,
  },
  [entityStatusType.irregular]: {
    text: 'חריג',
    textEn: 'irregular',
    textShort: 'חריג',
    textEnShort: 'irr',
    color: cssColor.redText,
    backgroundColor: cssColor.redBackgroundTransparent,
  },
  [entityStatusType.new]: {
    text: 'חדש',
    textEn: 'new',
    textShort: 'חדש',
    textEnShort: 'new',
    color: cssColor.greenText,
    backgroundColor: cssColor.greenBackgroundTransparent,
  },
  [entityStatusType.lostData]: {
    text: 'נתונים אבודים',
    textEn: 'lost data',
    textShort: 'נ. אבודים',
    textEnShort: 'l.data',
    color: cssColor.yellowText,
    backgroundColor: cssColor.yellowBackgroundTransparent,
  },
  [policyRatingType.green]: {
    text: 'ירוק',
    textEn: 'green',
    textShort: 'ירוק',
    textEnShort: 'grn',
    color: cssColor.primaryText,
    backgroundColor: cssColor.greenText,
  },
  [policyRatingType.red]: {
    text: 'אדום',
    textEn: 'red',
    textShort: 'אדום',
    textEnShort: 'red',
    color: cssColor.primaryText,
    backgroundColor: cssColor.redText,
  },
  [policyRatingType.orange]: {
    text: 'כתום',
    textEn: 'orange',
    textShort: 'כתו',
    textEnShort: 'orn',
    color: cssColor.primaryText,
    backgroundColor: cssColor.yellowText,
  },
}
