const CheckCircleIconSvg = ({ fill = 'var(--trend-up-color)' }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30' fill='none'>
    <path
      d='M15 2.5C8.1 2.5 2.5 8.1 2.5 15C2.5 21.9 8.1 27.5 15 27.5C21.9 27.5 27.5 21.9 27.5 15C27.5 8.1 21.9 2.5 15 2.5ZM11.6125 20.3625L7.125 15.875C6.6375 15.3875 6.6375 14.6 7.125 14.1125C7.6125 13.625 8.4 13.625 8.8875 14.1125L12.5 17.7125L21.1 9.1125C21.5875 8.625 22.375 8.625 22.8625 9.1125C23.35 9.6 23.35 10.3875 22.8625 10.875L13.375 20.3625C12.9 20.85 12.1 20.85 11.6125 20.3625Z'
      fill={fill}
    />
  </svg>
)

export default CheckCircleIconSvg
