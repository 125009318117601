import CustomerDetailsListItem from './CustomerDetailsListItem'
import { infoDetailsType } from '../../../../types/infoDetailsTypes'
import CustomerGroups from '../CustomerGroups/CustomerGroups'
import './CustomerDetailsList.scss'
import { formatAsShortDate } from '../../../../utils/formatAs'

const CustomerDetailsList = ({ person, changePerson }) => {
  return (
    <div className='customer-details-list-container'>
      <CustomerDetailsListItem
        detailsType={infoDetailsType.address}
        content={person.address}
        setContent={changePerson}
      />
      <CustomerDetailsListItem detailsType={infoDetailsType.phone} content={person.phone} setContent={changePerson} />
      {person.gender && <CustomerDetailsListItem detailsType={infoDetailsType.gender} content={person.gender} />}
      {person.birthDate && (
        <CustomerDetailsListItem
          detailsType={infoDetailsType.birthDate}
          content={formatAsShortDate(person.birthDate)}
        />
      )}
      <CustomerGroups />
    </div>
  )
}

export default CustomerDetailsList
