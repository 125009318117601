import { colorTypes } from './colorsTypes'

export const irregularStatus = {
  tracking: 0,
  irregular: 1,
  normal: 2,
  new: 3,
  fixed: 4
}

export const irregularStatusObjects = [
  { key: irregularStatus.tracking, value: 'בטיפול', valueEn: 'tracking', color: colorTypes.white },
  { key: irregularStatus.irregular, value: 'חריג', valueEn: 'irregular', color: colorTypes.redFaded },
  { key: irregularStatus.normal, value: 'normal', valueEn: 'normal', color: colorTypes.white },
  { key: irregularStatus.new, value: 'חדש', valueEn: 'new', color: colorTypes.green },
  { key: irregularStatus.fixed, value: 'ignored', valueEn: 'ignored', color: colorTypes.green },
]
