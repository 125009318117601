import {
  urlAnalytics,
  urlBigAgency,
  urlBigAgencyAnalytics,
  urlCompany,
  urlDashboard,
  urlMaster,
} from '../../environment/urls'
import $api, { getCancelToken } from '..'

const bigAgencyUrl = urlBigAgency()

export const getAssociatedAgenciesRequest = async filters =>
  await $api.post(`${bigAgencyUrl}/associated-agencies`, { ...filters }, getCancelToken('getAssociatedAgenciesRequest'))

export const getSubAgenciesRequest = async () =>
  await $api.get(`${bigAgencyUrl}/sub-agencies`, getCancelToken('getSubAgenciesRequest'))

export const addSubagencyRequest = async ({ agencyNumber, agencyName, companies }) =>
  await $api.post(`${bigAgencyUrl}/add-sub-agency`, { agencyNumber, agencyName, companies })

export const editSubagencyRequest = async ({ id, agencyNumber, agencyName, companies }) =>
  await $api.post(`${bigAgencyUrl}/update-sub-agency`, { id, agencyNumber, agencyName, companies })

export const checkExistingAgentRequest = async phoneNumber =>
  await $api.get(`${urlMaster()}account/check-user/${phoneNumber}`, getCancelToken('checkExistingAgentRequest'))

export const checkExistingEmailRequest = async ({ email, phone }) =>
  await $api.post(`${bigAgencyUrl}/check-user-email`, { email, phone }, getCancelToken('checkExistingEmailRequest'))

export const addAssociatedAgencyRequest = async ({
  userPhone,
  userEmail,
  isPaidByAssociation,
  preferences,
  companyIds,
}) =>
  await $api.post(`${bigAgencyUrl}/add-association-invite`, {
    userPhone,
    userEmail,
    isPaidByAssociation,
    preferences,
    companyIds,
  })

export const addNewUserInviteRequest = async ({ userPhone, userEmail, isPaidByAssociation, preferences, companyIds }) =>
  await $api.post(`${bigAgencyUrl}/add-new-user-invite`, {
    userPhone,
    userEmail,
    isPaidByAssociation,
    preferences,
    companyIds,
  })

export const getAgencyInvitesRequest = async () => await $api.get(`${bigAgencyUrl}/get-invites`)

export const resendAgencyInviteRequest = async agencyId => await $api.get(`${bigAgencyUrl}/resend-invite/${agencyId}`)

export const editAgencyInviteRequest = async ({ agencyId, preferences, companyIds }) =>
  await $api.post(`${bigAgencyUrl}/edit-invite`, { agencyId, preferences, companyIds })

export const processAgencyInvitesRequest = async ({ inviteId, accepted, companyIds, preferences }) =>
  await $api.post(`${bigAgencyUrl}/invitation-answer`, { inviteId, accepted, companyIds, preferences })

export const getLobbyTotalsRequest = async ({
  regularAgency = false,
  agency = '{agency}',
  familyGroup = false,
} = {}) => {
  return regularAgency
    ? await $api.get(urlDashboard(agency) + 'GetDashboardData/' + familyGroup.toString())
    : await $api.post(urlBigAgency() + '/dashboard-data')
}

export const getDownloadAgenciesRequest = async (singleAgency = false) =>
  await $api.get(`${singleAgency ? urlCompany() : bigAgencyUrl + '/'}get-downloads`)

export const getDownloadAgenciesHistoryRequest = async (singleAgency = false) =>
  await $api.get(`${singleAgency ? urlCompany() : bigAgencyUrl + '/'}get-history`)

export const getAgencyHouseSales = async ({ regularAgency = false, agency = '{agency}' } = {}) => {
  return regularAgency
    ? await $api.get(urlDashboard(agency) + 'GetSalesRecomendation')
    : await $api.post(urlBigAgency() + '/sales-recomendation')
}

export const getAgencyHouseGroupsRequest = async () => {
  return await $api.get(`${bigAgencyUrl}/get-agency-groups`)
}

export const getAgencyHouseAllAgenciesRequest = async () => {
  return await $api.get(`${bigAgencyUrl}/related-agencies`)
}

export const addAgencyHouseAgenciesGroupRequest = async ({ name, agencies }) => {
  return await $api.post(`${bigAgencyUrl}/add-agency-group`, { name, agencies, isFavourite: false, isAgency: false })
}

export const updateAgencyHouseAgenciesGroupRequest = async group => {
  return await $api.post(`${bigAgencyUrl}/update-agency-group`, group)
}

export const setAgencyHouseFavoriteGroupRequest = async groupId => {
  return await $api.post(`${bigAgencyUrl}/set-favourite-agency-group`, +groupId, {
    headers: {
      'Content-Type': 'application/json;',
    },
  })
}

export const deleteAgencyHouseGroupRequest = async groupId =>
  await $api.delete(`${bigAgencyUrl}/delete-agency-group/${groupId}`)

export const getAgencyHouseDetailsRequest = async () => await $api.get(`${bigAgencyUrl}/get-agency-house-details`)

export const updateAgencyHouseDetailsRequest = async details =>
  await $api.post(`${bigAgencyUrl}/update-agency-house-details`, details)

export const getAgencyHouseAnalyticsDatesRequest = async () =>
  await $api.get(`${urlBigAgencyAnalytics()}get-dates-filter-values`)

export const getAgencyHouseAnalyticsAgenciesFilterRequest = async () =>
  await $api.get(`${urlBigAgencyAnalytics()}get-agencys-filter-values`)

export const getAgencyHouseAnalyticsCompaniesCategoriesFilterRequest = async () =>
  await $api.get(`${urlBigAgencyAnalytics()}get-filters-values`)

export const getAgencyHouseAnalyticsPoliciesTotalsRequest = async ({
  agenciesIds = [],
  timePeriods = [],
  fromNewPolicies = false,
  periodType,
  categories,
  companies,
}) =>
  await $api.post(
    `${urlBigAgencyAnalytics()}get-policies-totals-values`,
    { agenciesIds, timePeriods, fromNewPolicies, periodType, categories, companies },
    getCancelToken('getAgencyHouseAnalyticsPoliciesTotalsRequest')
  )

export const getAgencyHouseAnalyticsPoliciesListRequest = async ({
  agenciesIds = [],
  timePeriods = [],
  entityType,
  fromNewPolicies = false,
  categories,
  companies,
}) =>
  await $api.post(
    `${urlBigAgencyAnalytics()}get-analytics-policies-table-data`,
    {
      agenciesIds,
      timePeriods,
      entityType,
      fromNewPolicies,
      categories,
      companies,
    },
    getCancelToken('getAgencyHouseAnalyticsPoliciesListRequest')
  )

export const getAgencyHouseAnalyticsPoliciesDetailsRequest = async ({
  agenciesIds = [],
  timePeriods = [],
  mainEntityType,
  mainEntityId,
  topLevelEntityType,
  fromNewPolicies = false,
  categories,
  companies,
}) =>
  await $api.post(
    `${urlBigAgencyAnalytics()}get-analytics-policies-sidecard-data`,
    {
      agenciesIds,
      timePeriods,
      filteringEntityType: mainEntityType,
      filteringEntityId: mainEntityId,
      topLevelEntityType: topLevelEntityType,
      fromNewPolicies,
      categories,
      companies,
    },
    getCancelToken('getAgencyHouseAnalyticsPoliciesDetailsRequest')
  )

export const getAgencyHouseAnalyticsPoliciesPeriodDetailsRequest = async ({
  agenciesIds = [],
  period,
  dataType,
  entityTypeOrder = [],
  fromNewPolicies = false,
  categories,
  companies,
}) =>
  await $api.post(
    `${urlBigAgencyAnalytics()}get-policies-sidecard-data-by-period`,
    {
      agenciesIds,
      period,
      dataType,
      entityTypeOrder,
      fromNewPolicies,
      categories,
      companies,
    },
    getCancelToken('getAgencyHouseAnalyticsPoliciesPeriodDetailsRequest')
  )

export const getAgencyHouseAnalyticsTotalsRequest = async ({
  agenciesIds = [],
  timePeriods = [],
  dataType,
  fromNewPolicies = false,
  periodType,
  companies = [],
  categories = [],
}) =>
  await $api.post(
    `${urlBigAgencyAnalytics()}get-totals-values`,
    { agenciesIds, timePeriods, dataType, fromNewPolicies, periodType, companies, categories },
    getCancelToken('getAgencyHouseAnalyticsTotalsRequest')
  )

export const getAgencyHouseAnalyticsListRequest = async ({
  agenciesIds = [],
  timePeriods = [],
  dataType,
  periodType,
  entityType,
  fromNewPolicies = false,
  isChartView,
  categories,
  companies,
}) =>
  await $api.post(
    `${urlBigAgencyAnalytics()}get-analytics-table-data`,
    {
      agenciesIds,
      timePeriods,
      dataType,
      entityType,
      periodType,
      fromNewPolicies,
      isChartView,
      categories,
      companies,
    },
    getCancelToken('getAgencyHouseAnalyticsListRequest')
  )

export const getAgencyHouseAnalyticsDetailsRequest = async ({
  agenciesIds = [],
  timePeriods = [],
  dataType,
  mainEntityType,
  mainEntityId,
  topLevelEntityType,
  fromNewPolicies = false,
  isChartView,
  companies,
  categories,
}) =>
  await $api.post(
    `${urlBigAgencyAnalytics()}get-analytics-sidecard-data`,
    {
      agenciesIds,
      timePeriods,
      dataType,
      filteringEntityType: mainEntityType,
      filteringEntityId: mainEntityId,
      topLevelEntityType: topLevelEntityType,
      fromNewPolicies,
      isChartView,
      companies,
      categories,
    },
    getCancelToken('getAgencyHouseAnalyticsDetailsRequest')
  )

export const getAgencyHouseAnalyticsPeriodDetailsRequest = async ({
  agenciesIds = [],
  period,
  dataType,
  entityTypeOrder = [],
  fromNewPolicies = false,
  isChartView,
  companies,
  categories,
} = {}) =>
  await $api.post(
    `${urlBigAgencyAnalytics()}get-analytics-sidecard-data-by-period`,
    {
      agenciesIds,
      period,
      dataType,
      entityTypeOrder,
      fromNewPolicies,
      isChartView,
      companies,
      categories,
    },
    getCancelToken('getAgencyHouseAnalyticsPeriodDetailsRequest')
  )

export const getAgencyHouseAnalyticsCustomersListRequest = async ({
  agenciesIds = [],
  timePeriods = [],
  entityType,
  isChartView,
  categories,
  companies,
}) =>
  await $api.post(
    `${urlBigAgencyAnalytics()}get-analytics-customers-table-data`,
    {
      agenciesIds,
      timePeriods,
      entityType,
      isChartView,
      categories,
      companies,
    },
    getCancelToken('getAgencyHouseAnalyticsCustomersListRequest')
  )

export const getAgencyHouseAnalyticsCustomersDetailsRequest = async ({
  agenciesIds = [],
  timePeriods = [],
  mainEntityType,
  mainEntityId,
  topLevelEntityType,
  categories,
  companies,
}) =>
  await $api.post(
    `${urlBigAgencyAnalytics()}get-analytics-customers-sidecard-data`,
    {
      agenciesIds,
      timePeriods,
      filteringEntityType: mainEntityType,
      filteringEntityId: mainEntityId,
      topLevelEntityType: topLevelEntityType,
      categories,
      companies,
    },
    getCancelToken('getAgencyHouseAnalyticsCustomersDetailsRequest')
  )

export const getAgencyHouseAnalyticsCustomersPeriodDetailsRequest = async ({
  agenciesIds = [],
  period,
  dataType,
  entityTypeOrder = [],
  fromNewPolicies = false,
  categories,
  companies,
}) =>
  await $api.post(
    `${urlBigAgencyAnalytics()}get-customers-sidecard-data-by-period`,
    {
      agenciesIds,
      period,
      dataType,
      entityTypeOrder,
      fromNewPolicies,
      categories,
      companies,
    },
    getCancelToken('getAgencyHouseAnalyticsCustomersPeriodDetailsRequest')
  )

export const getAgencyHouseAnalyticsSignalsRequest = async ({ agenciesIds = [], companies, categories } = {}) =>
  await $api.post(
    `${urlBigAgency()}/signal-types`,
    {
      agenciesIds,
      companies,
      categories,
    },
    getCancelToken('getAgencyHouseAnalyticsSignalsRequest')
  )

export const getAgencyHouseAnalyticsSignalDetailsRequest = async ({
  agenciesIds = [],
  signalType,
  companies,
  categories,
}) =>
  await $api.post(
    `${urlBigAgency()}/signal-data`,
    {
      agenciesIds,
      signalType,
      companies,
      categories,
    },
    getCancelToken('getAgencyHouseAnalyticsSignalDetailsRequest')
  )

export const getAgencyHouseAnalyticsCustomersRatingRequest = async ({ timePeriods = [], agenciesIds = [] } = {}) =>
  await $api.post(
    `${urlAnalytics()}get-customer-analytics`,
    {
      timePeriods,
      agenciesIds,
    },
    getCancelToken('getAgencyHouseAnalyticsCustomersRatingRequest')
  )

export const getAssociatedAgencyDetailsRequest = async agencyId =>
  await $api.get(`${bigAgencyUrl}/associated-agency-details/${agencyId}`)

export const saveBigAgencyCardInfoRequest = async () => await $api.post(`${urlMaster()}payment/save-card-info`)

export const applySaveBigAgencyCardRequest = async (processId, processToken) =>
  await $api.post(`${urlMaster()}payment/apply-save-card/${processId}/${processToken}`)
