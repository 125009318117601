import SearchCustomerModal from '../../../../components/SearchCustomerModal/SearchCustomerModal'
import PopupContainer from '../../../../components/ModalContainer/ModalContainer'
import {
  addExistingCustomerToPoliciesRequest,
  addNewCustomerToPoliciesRequest,
} from '../../../../http/requests/policiesRequests'
import './PoliciesAddCustomerContainer.scss'
import { useState } from 'react'
import ConfirmDialog from '../../../../components/ConfirmDialog/ConfirmDialog'
import { useTranslation } from 'react-i18next'

const PoliciesAddCustomerContainer = ({ closeContainer, policies, onAddCustomers }) => {
  const { t } = useTranslation('policies')
  const [addLoading, setAddLoading] = useState(false)
  const [selectedCustomer, setSelectedCustomer] = useState(null)

  const addCustomerHandler = async customer => {
    const fetch = customer.id ? addExistingCustomerToPoliciesRequest : addNewCustomerToPoliciesRequest
    try {
      setAddLoading(true)
      const {
        data: { data },
      } = await fetch({ policies: policies.map(p => p.id), ...customer })
      if (data) {
        onAddCustomers()
        closeContainer()
      }
      setAddLoading(false)
    } catch (error) {
      setAddLoading(false)
      console.log('Add Error')
      console.log(error)
    }
  }

  const closeHandler = !addLoading && closeContainer

  return (
    <PopupContainer onOverlayClick={closeHandler}>
      <SearchCustomerModal
        closeModal={closeHandler}
        onSelectCustomer={setSelectedCustomer}
        policy={policies.length === 0 ? policies[0] : null}
        loading={addLoading}
      />
      {selectedCustomer && (
        <ConfirmDialog
          title={t('confirmAddPolicyCustomer', { customerName: selectedCustomer.name })}
          confirmText={t('confirmAddPolicyCustomerOk')}
          declineText={t('confirmAddPolicyCustomerCancel')}
          confirm={() => {
            addCustomerHandler(selectedCustomer)
            setSelectedCustomer(null)
          }}
          decline={() => setSelectedCustomer(null)}
          renderPortal
        />
      )}
    </PopupContainer>
  )
}

export default PoliciesAddCustomerContainer
