import { useState } from 'react'
import classNames from 'classnames'
import AddGroupModal from '../AddGroupModal/AddGroupModal'
import { useTranslation } from 'react-i18next'
import './CustomerAddFamilyMember.css'
import AddCustomerGroupModal from '../../../../components/AddCustomerGroupModal/AddCustomerGroupModal'
import { useSelector } from 'react-redux'

const CustomerAddFamilyMember = () => {
  const { customerInfo: customer } = useSelector(({ customer }) => customer)
  const [showAddGroupModal, setShowAddGroupModal] = useState(false)
  const { t } = useTranslation('customer')

  const clickHandler = () => {
    if (!showAddGroupModal) setShowAddGroupModal(true)
  }

  return (
    <>
      <div
        className={classNames('customer-add-family-member', {
          active: showAddGroupModal,
        })}
        onClick={clickHandler}
      >
        <div className='icon-tooltip'>{t('infoCard.addMemeberTooltip')}</div>
        <div className='icon-background-hover'></div>
        <img src='./assets/icon-add/Add.png' alt='add' className='img-icon' />
      </div>
      {showAddGroupModal && (
        <AddCustomerGroupModal closeModal={() => setShowAddGroupModal(false)} groupCustomers={[customer]} />
      )}
    </>
  )
}

export default CustomerAddFamilyMember
