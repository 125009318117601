import AddTask from '../AddTask/AddTask'
import SearchTasks from '../SearchTasks/SearchTasks'
import WorkstationFiltersContainer from '../WorkstationFiltersContainer/WorkstationFiltersContainer'
import './WorkstationHeaderFull.scss'

const WorkstationHeaderFull = () => {
  return (
    <header className='workstation-header-full'>
      <div className='workstation-header-search-container'>
        <SearchTasks />
      </div>
      <div className='workstation-header-filters-container'>
        <WorkstationFiltersContainer />
      </div>
      <div className='workstation-header-add-container'>
        <AddTask />
      </div>
    </header>
  )
}

export default WorkstationHeaderFull
