import {
  LANDING_EMAIL_FAIL,
  LANDING_EMAIL_REQUEST,
  LANDING_EMAIL_SUCCESS,
  LANDING_INFO_SET,
} from '../../../types/actionTypes'

const initialState = {
  info: {
    fullName: '',
    mobilePhone: '',
    messageContent: '',
  },
  sendEmailSuccess: false,
  sendEmailLoading: false,
  sendEmailError: null,
}

export const landingReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case LANDING_INFO_SET:
      return {
        ...state,
        info: payload,
      }
    case LANDING_EMAIL_REQUEST:
      return {
        ...state,
        sendEmailSuccess: false,
        sendEmailLoading: true,
        sendEmailError: null,
      }
    case LANDING_EMAIL_SUCCESS:
      return {
        ...state,
        sendEmailSuccess: true,
        sendEmailLoading: false,
        sendEmailError: null,
      }
    case LANDING_EMAIL_FAIL:
      return {
        ...state,
        sendEmailSuccess: false,
        sendEmailLoading: false,
        sendEmailError: payload,
      }
    default:
      return state
  }
}
