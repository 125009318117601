import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import AgentGroupsBanner from '../../../../components/AgentGroupsBanner/AgentGroupsBanner'
import MobileHeaderBase from '../../../../components/Common/MobileHeaderBase/MobileHeaderBase'
import { showSidebar } from '../../../../utils/sidebar'
import AddTask from '../AddTask/AddTask'
import SearchTasks from '../SearchTasks/SearchTasks'
import WorkstationFiltersContainer from '../WorkstationFiltersContainer/WorkstationFiltersContainer'
import './WorkstationHeaderMobile.scss'

const WorkstationHeaderMobile = () => {
  const { t } = useTranslation('workstation')
  const goBackHandler = () => {
    showSidebar()
  }
  return (
    <>
      <MobileHeaderBase text={t('tasks')} goBack={goBackHandler}>
        <div className='worksatation-header-extras-container'>
          <SearchTasks />
          <AddTask />
        </div>
      </MobileHeaderBase>
      {isMobile && (
        <div className='workstation-screen-banners'>
          <AgentGroupsBanner />
        </div>
      )}
      <div className='worksatation-header-mobile-filters'>
        <WorkstationFiltersContainer />
      </div>
    </>
  )
}

export default WorkstationHeaderMobile
