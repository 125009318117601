import { useTranslation } from 'react-i18next'
import { formatAsNumber } from '../../../utils/formatAs'
import NoData from '../../Common/NoData/NoData'
import TrendValue from '../../TrendValue/TrendValue'
import './CustomerGroupChart.scss'
import TrendChart from '../TrendChart'
import PrivateValue from '../../../HOCs/PrivateValue'
import NoAccess from '../../NoAccess/NoAccess'
import { useSelector } from 'react-redux'

const CustomerGroupChart = ({
  diff,
  trend,
  values,
  latest,
  title,
  remark,
  requiredPrefernce,
  showChanges = true,
  noDataText = 'Nothing Found',
}) => {
  const { t } = useTranslation('customer')
  const { preferences } = useSelector(({ login }) => login)

  const trendRenderedValue = <TrendValue value={latest} money trend={trend} showTrend showCurrency />
  const trendDiffRenderedValue = (
    <>
      {diff === 0 ? '' : trend ? '+₪' : '-₪'}
      {formatAsNumber(Math.abs(diff))}
    </>
  )
  return (
    <div className='customer-group-chart-wrapper'>
      {values.length === 0 || values.every(v => !v) ? (
        <NoData text={noDataText} />
      ) : (
        // : preferences.length > 0 && !preferences.includes(requiredPrefernce) ? (
        //   <NoAccess />
        // )
        <div className='customer-group-chart-container'>
          {preferences.length > 0 && !preferences.includes(requiredPrefernce) && (
            <div className='customer-group-chart-no-access-blured'></div>
          )}
          <div className='customer-group-chart-header'>
            <div className='customer-group-chart-value'>
              <div className='customer-group-chart-value-title'>{title}</div>
              <div className='customer-group-chart-value-content'>
                {requiredPrefernce ? (
                  <PrivateValue requiredPrefernces={[requiredPrefernce]}>{trendRenderedValue}</PrivateValue>
                ) : (
                  trendRenderedValue
                )}
              </div>
            </div>

            {showChanges && (
              <div className='customer-group-chart-changes'>
                <div className='customer-group-chart-changes-title'>{t('charts.monthChanges')}</div>
                <div className='customer-group-chart-changes-content'>
                  {requiredPrefernce ? (
                    <PrivateValue requiredPrefernces={[requiredPrefernce]}>{trendDiffRenderedValue}</PrivateValue>
                  ) : (
                    trendDiffRenderedValue
                  )}
                </div>
              </div>
            )}
          </div>
          <div className='customer-group-chart'>
            <TrendChart data={values} trend={trend} />
          </div>
          {remark && <div className='customer-group-chart-remark'>{remark}</div>}
        </div>
      )}
    </div>
  )
}

export default CustomerGroupChart
