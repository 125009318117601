import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import LinedTitle from '../../../../components/Common/LinedTitle/LinedTitle'
import TreatmentAppBar from '../../../../components/TreatmentAppBar/TreatmentAppBar'
import { WORKSTATION_SET_SORT } from '../../../../types/actionTypes'
import { sortBy, sortOrder } from '../../../../types/sortByTypes'
import TasksListItem from '../TasksListItem/TasksListItem'
import './TasksList.scss'

const TasksList = ({
  tasksTreated = [],
  tasksWaiting = [],
  showWaitingTasks,
  setShowWaitingTasks,
  showTreatedTasks,
  setShowTreatedTasks,
  collapseTasksHandler,
}) => {
  const { sort } = useSelector(({ workstation }) => workstation)
  const { t } = useTranslation('workstation')
  const dispatch = useDispatch()
  const [selectedTasks, setSelectedTasks] = useState([])

  const selectTaskHandler = id => {
    setSelectedTasks(prev => (prev.includes(id) ? prev.filter(t => t !== id) : [...prev, id]))
  }

  const setSortHandler = sortBy => {
    dispatch({ type: WORKSTATION_SET_SORT, payload: sortBy })
  }

  const listHeader = () => (
    <div className='tasks-list-header tasks-list-grid'>
      <div></div>
      <div className='sortable' onClick={() => setSortHandler(sortBy.customerName)}>
        <div>{t('listHeader.customerName')}</div>
        <div>
          {sort.by === sortBy.customerName && (
            <i className={`fas fa-sort-alpha-down${sort.order === sortOrder.asc ? '' : '-alt'} fa-lg`}></i>
          )}
        </div>
      </div>
      <div className='sortable' onClick={() => setSortHandler(sortBy.policyNumber)}>
        <div>{t('listHeader.policyNumber')}</div>
        <div>
          {sort.by === sortBy.policyNumber && (
            <i className={`fas fa-sort-numeric-down${sort.order === sortOrder.asc ? '' : '-alt'} fa-lg`}></i>
          )}
        </div>
      </div>
      <div className='sortable' onClick={() => setSortHandler(sortBy.taskType)}>
        <div>{t('listHeader.taskType')}</div>
        <div>
          {sort.by === sortBy.taskType && (
            <i className={`fas fa-sort-alpha-down${sort.order === sortOrder.asc ? '' : '-alt'} fa-lg`}></i>
          )}
        </div>
      </div>
      <div>{t('listHeader.taskDesc')}</div>
      <div>{t('listHeader.taskStatus')}</div>
      <div>{t('listHeader.monetaryValue')}</div>
      <div>{t('listHeader.category')}</div>
      <div>{t('listHeader.responsibleEmployee')}</div>
      <div>{t('listHeader.date')}</div>
      <div></div>
    </div>
  )

  return (
    <>
      <div className='tasks-lists-container'>
        {tasksWaiting.length > 0 && (
          <div className='tasks-list-container'>
            <LinedTitle
              count={tasksWaiting.length}
              title={t('tasksWaiting')}
              collapsible
              collapsed={!showWaitingTasks}
              onCollapse={() => collapseTasksHandler(showWaitingTasks, setShowWaitingTasks)}
            />
            {showWaitingTasks && (
              <>
                {listHeader()}
                <div className='tasks-list'>
                  {tasksWaiting.map(t => (
                    <TasksListItem
                      key={t.id}
                      task={t}
                      selectTask={selectTaskHandler}
                      selected={selectedTasks.includes(t.id)}
                    />
                  ))}
                </div>
              </>
            )}
          </div>
        )}
        {tasksTreated.length > 0 && (
          <div className='tasks-list-container'>
            <LinedTitle
              count={tasksTreated.length}
              title={t('tasksTreated')}
              collapsible
              collapsed={!showTreatedTasks}
              onCollapse={() => collapseTasksHandler(showTreatedTasks, setShowTreatedTasks)}
            />
            {showTreatedTasks && (
              <>
                {listHeader()}
                <div className='tasks-list'>
                  {tasksTreated.map(t => (
                    <TasksListItem
                      key={t.id}
                      task={t}
                      selectTask={selectTaskHandler}
                      selected={selectedTasks.includes(t.id)}
                    />
                  ))}
                </div>
              </>
            )}
          </div>
        )}
      </div>
      {selectedTasks.length > 0 && (
        <div className='tasks-list-snackbar-wrapper'>
          <TreatmentAppBar
            tasks={[...tasksTreated, ...tasksWaiting].filter(t => selectedTasks.includes(t.id))}
            closeBar={() => setSelectedTasks([])}
          >
            add actions
          </TreatmentAppBar>
        </div>
      )}
    </>
  )
}

export default TasksList
