import { useSelector } from 'react-redux'
import './PrivateValue.scss'

const PrivateValue = ({ children, requiredPrefernces = [], requireAllPreferences = false }) => {
  const { preferences } = useSelector(state => state.login)
  if (!Array.isArray(requiredPrefernces)) requiredPrefernces = [requiredPrefernces]
  if (requireAllPreferences) {
    if (requiredPrefernces.every(p => preferences.includes(p))) return children
  } else if (requiredPrefernces.some(p => preferences.includes(p))) return children
  return (
    <div className='private-value-wrapper'>
      <div className='private-value-wrapper-overlay'></div>
      <div>{children}</div>
    </div>
  )
}

export default PrivateValue
