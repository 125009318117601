import { getReportRecordsDatesRequest, getReportRecordsStatusRequest } from '../../../http/requests/scanRequests'
import {
  REPORTS_DATES_FAIL,
  REPORTS_DATES_REQUEST,
  REPORTS_DATES_SUCCESS,
  REPORTS_MONTH_STATUS_FAIL,
  REPORTS_MONTH_STATUS_REQUEST,
  REPORTS_MONTH_STATUS_SUCCESS,
} from '../../../types/actionTypesScan'

  export const getReportRecordsDates =
  (params = {}) =>
  async dispatch => {
    dispatch({ type: REPORTS_DATES_REQUEST })
    
    try {
      const agency = params?.agency
      const date = params?.date
      const {
        data: { data },
      } = await getReportRecordsDatesRequest(agency || {})
      dispatch({ type: REPORTS_DATES_SUCCESS, payload: {data, date} })
    } catch (error) {
      dispatch({ type: REPORTS_DATES_FAIL, payload: error })
    }
  }

export const getReportRecordsMonthStatus =
  ({ month, agency } = {}) =>
  async dispatch => {
    dispatch({ type: REPORTS_MONTH_STATUS_REQUEST })
    try {
      let {
        data: { data },
      } = await getReportRecordsStatusRequest({ month, agency })
      dispatch({ type: REPORTS_MONTH_STATUS_SUCCESS, payload: data })
    } catch (error) {
      dispatch({ type: REPORTS_MONTH_STATUS_FAIL, payload: error })
    }
  }
