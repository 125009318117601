import { NavLink } from 'react-router-dom'
import './AgenciesNavbar.scss'

const AgenciesNavbar = ({ navbarItems, setNavbar }) => {
  return (
    <nav className='agencies-navbar'>
      <ul>
        {navbarItems.map(({ path, text }) => (
          <li key={path ?? text}>
            {path ? (
              <NavLink to={path} activeClassName='agencies-navbar-active-item'>
                {text}
              </NavLink>
            ) : (
              text
            )}
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default AgenciesNavbar
