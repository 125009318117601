import { Tooltip } from '@mui/material'
import { SyncProblemOutlined } from '@mui/icons-material'

const PoliciesSyncIcon = ({ tooltip, enabled }) => {
  return (enabled && 
    <div className='policies-sync-icon'>
      {tooltip && (
        <Tooltip
          title= {tooltip}
          classes={{ popper: 'MuiTooltip-tooltip-custom' }}
          arrow
          placement='top'>
        <SyncProblemOutlined style={{ color: 'var(--orange-client)' }} />
        </Tooltip>)}
    </div>)
}

export default PoliciesSyncIcon