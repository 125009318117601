import { ArrowDropDown, ArrowDropUp, Check } from '@mui/icons-material'
import { getUserNameInitials } from '../../../../services/getUserNameInitials'
import { hebrew } from '../../../../i18n'
import './AgencyHouseDownloadSummaryItem.scss'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { formatAsShortDate } from '../../../../utils/formatAs'
import MicrosoftExcelIconSvg from '../../../../components/Common/SvgIcons/MicrosoftExcelIconSvg/MicrosoftExcelIconSvg'
import { Download } from '@mui/icons-material'
import { agencyHouseRoutes, agencyRoutes } from '../../../../environment/urls'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { agencyType } from '../../../../types/agencyTypes'
import { useState } from 'react'
import ReportsDownloadListModal from '../../../../components/ReportsDownloadListModal/ReportsDownloadListModal'
import { downloadReportRequest } from '../../../../http/requests'

const allreportsSaved = c => c.ReportsCount === c.SavedReportsCount

const SummaryCollapsibleHeader = ({ text, collapsed, collapse }) => {
  return (
    <div className='agency-house-download-screen-collapsible-header'>
      <p>{text}</p>
      <span></span>
      <button onClick={collapse}>{collapsed ? <ArrowDropUp /> : <ArrowDropDown />}</button>
    </div>
  )
}

const SummaryCompanies = ({ companies = [], dbName }) => {
  const [selectedCompany, setSelectedCompany] = useState(null)
  const { t } = useTranslation('download')
  const { AgencyType: loggedInAgencyType = agencyType.regular } = useSelector(({ login }) => login)
  const regularAgency = loggedInAgencyType === agencyType.regular

  const downloadFileHandler = async reportRecordId => {
    await downloadReportRequest({ reportRecordId, agency: dbName })
  }
  return (
    <>
      <main>
        {companies.map(c => (
          <div
            key={c.Id}
            className={classNames({
              success: allreportsSaved(c),
              fail: c.FailedReportsCount > 0,
            })}
          >
            <>
              <div>
                <img src={`./assets/companies-logos-light/${c.Id}.png`} alt={c.Name} />
                <p>{hebrew() ? c.Name : c.NameEn}</p>
                {!allreportsSaved(c) && (
                  <p>
                    <span dir='ltr'>{c.FailedReportsCount + '/' + c.ReportsCount}</span>
                    {c.FailedReportsCount > 0 && t('failed')}
                  </p>
                )}
              </div>
              <div>
                <p>{formatAsShortDate(c.LastTryout, '.')}</p>
                {c.SavedReportsCount < c.ReportsCount && !c.IsExpired && (
                  <NavLink
                    to={`${regularAgency ? '/' + agencyRoutes.download : agencyHouseRoutes.download + '/' + dbName}/${
                      c.Id
                    }`}
                    className={'download-button'}
                  >
                    <span>{t('download')}</span>
                    <Download />
                  </NavLink>
                )}
                {c.Files.length > 0 && (
                  <button className='download-button' onClick={() => setSelectedCompany(c)}>
                    <span>{t('downloadFiles')}</span>
                    <MicrosoftExcelIconSvg />
                  </button>
                )}
                {allreportsSaved(c) && <Check />}
              </div>
            </>
          </div>
        ))}
      </main>
      {selectedCompany && (
        <ReportsDownloadListModal
          closeModal={() => setSelectedCompany(null)}
          reports={selectedCompany.Files.map(f => ({ id: f.Key, name: f.Value, link: f.Value }))}
          downloadReport={downloadFileHandler}
        />
      )}
    </>
  )
}

const AgencyHouseDownloadSummaryItem = ({
  agency: {
    Id,
    Name,
    DbName,
    AvatarBackgroundColor,
    NoAttempts = [],
    AllFailed = [],
    SomeSucceed = [],
    AllSucceed = [],
    showCompanies = false,
    showNoAttempts,
    showAllFailed,
    showSomeSucceed,
    showAllSucceed,
  },
  singleAgency = false,
  groupCompanies,
  showCompaniesHandler,
  showGroupsHandler,
}) => {
  const { t } = useTranslation('download')
  const companiesCount = NoAttempts.length + AllFailed.length + SomeSucceed.length + AllSucceed.length
  if (companiesCount === 0) {
    return null
  }
  return (
    <div className='agency-house-download-summary-item'>
      {!singleAgency && (
        <header>
          <div>
            <div style={{ background: AvatarBackgroundColor }}>{getUserNameInitials(Name)}</div>
            <p>
              {Name}
              {' - '}
            </p>
            <p> {t('companiesCount', { companiesCount })}</p>
          </div>
          {companiesCount > 0 && (
            <button onClick={showCompaniesHandler}>{showCompanies ? <ArrowDropUp /> : <ArrowDropDown />}</button>
          )}
        </header>
      )}
      {(showCompanies || singleAgency) && (
        <>
          {groupCompanies ? (
            <>
              {NoAttempts.length > 0 && (
                <div>
                  <SummaryCollapsibleHeader
                    text={t('noAttemptsCompanies', { companiesCount: NoAttempts.length })}
                    collapse={() => showGroupsHandler('showNoAttempts')}
                    collapsed={showNoAttempts}
                  />
                  {showNoAttempts && <SummaryCompanies companies={NoAttempts} dbName={DbName} />}
                </div>
              )}
              {AllFailed.length > 0 && (
                <div>
                  <SummaryCollapsibleHeader
                    text={t('allFailedCompanies', { companiesCount: AllFailed.length })}
                    collapse={() => showGroupsHandler('showAllFailed')}
                    collapsed={showAllFailed}
                  />
                  {showAllFailed && <SummaryCompanies companies={AllFailed} dbName={DbName} />}
                </div>
              )}
              {SomeSucceed.length > 0 && (
                <div>
                  <SummaryCollapsibleHeader
                    text={t('someSucceedCompanies', { companiesCount: SomeSucceed.length })}
                    collapse={() => showGroupsHandler('showSomeSucceed')}
                    collapsed={showSomeSucceed}
                  />
                  {showSomeSucceed && <SummaryCompanies companies={SomeSucceed} dbName={DbName} />}
                </div>
              )}
              {AllSucceed.length > 0 && (
                <div>
                  <SummaryCollapsibleHeader
                    text={t('allSucceedCompanies', { companiesCount: AllSucceed.length })}
                    collapse={() => showGroupsHandler('showAllSucceed')}
                    collapsed={showAllSucceed}
                  />
                  {showAllSucceed && <SummaryCompanies companies={AllSucceed} dbName={DbName} />}
                </div>
              )}
            </>
          ) : (
            <SummaryCompanies
              companies={[...NoAttempts, ...AllFailed, ...SomeSucceed, ...AllSucceed]}
              dbName={DbName}
            />
          )}
        </>
      )}

      {/* {showCompanies && (
        
        <main>
          {companies.map(c => (
            <div
              key={c.id}
              className={classNames({
                success: allreportsSaved(c),
                fail: c.failedReportsCount > 0,
              })}
            >
              <div>
                <img src={`./assets/companies-logos-light/${c.id}.png`} alt={c.name} />
                <p>{hebrew() ? c.name : c.nameEn}</p>
                {!allreportsSaved(c) && (
                  <p>
                    <span dir='ltr'>{c.failedReportsCount + '/' + c.reportsCount}</span>
                    {c.failedReportsCount > 0 && t('failed')}
                  </p>
                )}
              </div>
              <div>
                <p>{formatAsShortDate(c.lastTryout, '.')}</p>
                {c.savedReportsCount < c.reportsCount && !c.expired && (
                  <NavLink to={`${agencyHouseRoutes.download}/${dbName}/${c.id}`} className={'download-button'}>
                    <span>{t('download')}</span>
                    <Download />
                  </NavLink>
                )}
                {c.files && (
                  <button className='download-button'>
                    <span>{t('downloadFiles')}</span>
                    <MicrosoftExcelIconSvg />
                  </button>
                )}
                {allreportsSaved(c) && <Check />}
              </div>
            </div>
          ))}
        </main>
      )} */}
    </div>
  )
}

export default AgencyHouseDownloadSummaryItem
