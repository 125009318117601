import { useEffect, useMemo, useRef, useState } from 'react'
import debounce from 'debounce'
import { Cancel } from '@mui/icons-material'
import './PoliciesInput.scss'

const PoliciesInput = ({
  value = '',
  setValue,
  placeholder = ' ',
  showSearchIco,
  enableClear,
  debounceTimeout = 500,
  inputIsValid = value => true,
}) => {
  const inputRef = useRef(null)
  const [inputValue, setInputValue] = useState(value ?? '')
  const iconClickHandler = () => {
    if (value !== '') {
      debounceSetValue.clear()
      setInputValue('')
      setValue('')
    }
    inputRef.current.focus()
  }

  const debounceSetValue = useMemo(
    () => debounce(value => setValue(value), debounceTimeout),
    [debounceTimeout, setValue]
  )

  const handleInputChange = value => {
    if (inputIsValid(value)) {
      setInputValue(value)
      debounceSetValue(value)
    }
  }

  useEffect(() => {
    return () => {
      debounceSetValue.clear()
    }
  }, [debounceSetValue])

  useEffect(() => {
    setInputValue(value)
  }, [value])

  return (
    <div className='policies-input-container-wrapper'>
      <div className='policies-input-container'>
        <input
          type='text'
          placeholder={placeholder}
          value={inputValue ?? ''}
          onChange={e => handleInputChange(e.target.value)}
          ref={inputRef}
        />
        {(enableClear || showSearchIco) && (
          <button className='policies-input-icon' onClick={iconClickHandler}>
            {inputValue !== ''
              ? enableClear && <Cancel />
              : showSearchIco && <img src='./assets/search-icon/Search.png' alt='srch' className='img-icon' />}
          </button>
        )}
      </div>
    </div>
  )
}

export default PoliciesInput
