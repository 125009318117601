import { AssignmentTurnedInRounded, DoneAllRounded, NewReleasesRounded, TrendingDownRounded } from '@mui/icons-material'
import { irregularReason } from './irregularReasonTypes'
import { getKeyByValue } from '../utils/objectUtils'

export const irregularSignalType = {
  cancellation: 1,
  negativePayment: 2,
  commissionDowngrade: 3,
  renewal: 4,
  commissionZero: 5,
  accumulationPremiumZero: 6,
}

export const irregularSignal = {
  [irregularSignalType.cancellation]: {
    id: irregularSignalType.cancellation,
    key: getKeyByValue(irregularSignalType, irregularSignalType.cancellation),
    cardIcon: <NewReleasesRounded style={{ fontSize: '32px' }} />,
    colored: true,
    signed: false,
    irregularReasons: [irregularReason.missingPayment, irregularReason.noPremium, irregularReason.noAccumulation],
    policiesFilterOrder: 1,
    name: 'פוליסות ביטולים ופיגורים',
    nameEn: 'Cancellation and delay policies',
  },
  [irregularSignalType.negativePayment]: {
    id: irregularSignalType.negativePayment,
    key: getKeyByValue(irregularSignalType, irregularSignalType.negativePayment),
    cardIcon: <TrendingDownRounded style={{ fontSize: '32px' }} />,
    colored: false,
    signed: true,
    irregularReasons: [
      irregularReason.negativePayment,
      irregularReason.elementaryNegativePayment,
      irregularReason.elementaryPositivePremiumNegativeCommission,
    ],
    policiesFilterOrder: 2,
    name: 'פוליסות עמלה פרמיה שלילית',
    nameEn: 'Negative premium commission policies',
  },
  [irregularSignalType.commissionDowngrade]: {
    id: irregularSignalType.commissionDowngrade,
    key: getKeyByValue(irregularSignalType, irregularSignalType.commissionDowngrade),
    cardIcon: <AssignmentTurnedInRounded style={{ fontSize: '32px' }} />,
    colored: true,
    signed: false,
    irregularReasons: [
      irregularReason.commissionRate,
      irregularReason.commissionAmountDowngrade,
      irregularReason.wrongCommissionAmountForNewPolicy,
      irregularReason.wrongCommissionRateForCoverage,
    ],
    policiesFilterOrder: 3,
    name: 'פוליסות שינוי בשיעור עמלה',
    nameEn: 'Commission rate change policies',
  },
  [irregularSignalType.renewal]: {
    id: irregularSignalType.renewal,
    key: getKeyByValue(irregularSignalType, irregularSignalType.renewal),
    cardIcon: <DoneAllRounded style={{ fontSize: '32px' }} />,
    colored: true,
    signed: true,
    irregularReasons: [irregularReason.lifePolicyAboutToEnd, irregularReason.elementaryPolicyAboutToEnd],
    name: 'חידושים ',
    nameEn: 'Renewal',
  },
  [irregularSignalType.commissionZero]: {
    id: irregularSignalType.commissionZero,
    cardIcon: <DoneAllRounded style={{ fontSize: '32px' }} />,
    key: getKeyByValue(irregularSignalType, irregularSignalType.commissionZero),
    name: 'פוליסות עמלה 0',
    nameEn: '0 commission policies',
    irregularReasons: [irregularReason.commissionZero],
  },
  [irregularSignalType.accumulationPremiumZero]: {
    id: irregularSignalType.accumulationPremiumZero,
    cardIcon: <DoneAllRounded style={{ fontSize: '32px' }} />,
    key: getKeyByValue(irregularSignalType, irregularSignalType.accumulationPremiumZero),
    name: 'צבירה מאופסת',
    nameEn: 'Zero Accumulation',
    irregularReasons: [irregularReason.accumulationPremiumZero],
  },
}
