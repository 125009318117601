import classNames from 'classnames'
import './ButtonText.scss'

const ButtonText = ({ onClick, disabled = false, typeSubmit = false, styles = '', children }) => {
  return (
    <button
      className={classNames('button-text', styles.split(' '))}
      disabled={disabled}
      onClick={onClick}
      {...(typeSubmit && { type: 'submit' })}
    >
      {children}
    </button>
  )
}

export default ButtonText
