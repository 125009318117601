import { groupBy } from '../../../../utils/objectUtils'
import LinedTitle from '../../../../components/Common/LinedTitle/LinedTitle'
import { Fragment, useState } from 'react'
import PolicyListItemCard from '../PolicyListItemCard/PolicyListItemCard'
import { policyHistory, policyHistoryType } from '../../../../types/policyHistoryTypes'
import { hebrew } from '../../../../i18n'
import { irregularReasonObject } from '../../../../types/irregularReasonTypes'
import { useSelector } from 'react-redux'
import { formatAsShortDate } from '../../../../utils/formatAs'
import { getMonthName } from '../../../../services/getMonthName'
import { useTranslation } from 'react-i18next'
import ButtonRounded from '../../../../components/Common/ButtonRounded/ButtonRounded'
import { cancelPolicyLogRequest } from '../../../../http/requests/policyRequests'
import './PolicyActionsHistory.scss'
import NoData from '../../../../components/Common/NoData/NoData'

const PolicyActionsHistory = ({ actions, onCancelAction }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('policies')
  const groupedActions = groupBy(actions, 'type')
  const [hiddenTypes, setHiddenTypes] = useState({})
  const { categories } = useSelector(({ shared }) => shared)
  const getCategory = id => categories.find(c => c.id === id)
  const [cancelLoading, setCanceLoading] = useState(false)

  if (actions.length === 0) {
    return <NoData text={t('policyCard.noActionsFound')} />
  }

  const cancelActionHandler = async actionId => {
    try {
      setCanceLoading(true)
      const {
        data: { data },
      } = await cancelPolicyLogRequest(actionId)
      if (data === 200) {
        onCancelAction()
      }
      setCanceLoading(false)
    } catch (error) {
      setCanceLoading(false)
    }
  }

  const renderHeaderContent = (type, irregActions) => {
    if (type === policyHistoryType.categoryChanged) {
      return (
        <div className='policy-sidecard-list-heading policy-card-actions-category-grid'>
          <div>{t('list.heading.prevCategory')}</div>
          <div className='actions-category-arrow-cell'>{'->'}</div>
          <div>{t('list.heading.newCategory')}</div>
          <div>{t('list.heading.comments')}</div>
          <div></div>
          <div className='border-inline-start-cell'>{t('list.heading.changeDate')}</div>
        </div>
      )
    }
    if (irregActions) {
      return (
        <div className='policy-sidecard-list-heading policy-card-actions-irreg-grid'>
          <div>{t('list.heading.irregReason')}</div>
          <div>{t('list.heading.comments')}</div>
          <div className='border-inline-start-cell'>{t('list.heading.irregStartDate')}</div>
          <div>{t('list.heading.changeDate')}</div>
          <div></div>
        </div>
      )
    }
    return (
      <div className='policy-sidecard-list-heading policy-card-actions-default-grid'>
        <div>{t('list.heading.user')}</div>
        <div>{t('list.heading.comments')}</div>
        <div></div>
        <div className='border-inline-start-cell'>{t('list.heading.changeDate')}</div>
      </div>
    )
  }

  const renderItemContent = action => {
    if (action.type === policyHistoryType.categoryChanged) {
      return (
        <PolicyListItemCard key={action.id} classes='policy-card-actions-category-grid'>
          <div>{getCategory(action.oldCategory)[`${hebrew() ? 'name' : 'englishName'}`]}</div>
          <div className='actions-category-arrow-cell'>{'->'}</div>
          <div>{getCategory(action.newCategory)[`${hebrew() ? 'name' : 'englishName'}`]}</div>
          <div>
            <div className='actions-comments-cell'>{action.comment ?? '--'}</div>
          </div>
          <div></div>
          <div className='border-inline-start-cell'>{formatAsShortDate(action.createDate, '.')}</div>
        </PolicyListItemCard>
      )
    }
    if (action.irregularityId) {
      const irregDate = new Date(action.irregularityStartDate)
      return (
        <PolicyListItemCard key={action.id} classes='policy-card-actions-irreg-grid'>
          {action.irregularReason && (
            <div>{irregularReasonObject[action.irregularReason][`name${hebrew() ? '' : 'En'}`]}</div>
          )}
          <div>
            <div className='actions-comments-cell'>{action.comment ?? '--'}</div>
          </div>
          <div className='border-inline-start-cell'>
            {getMonthName(irregDate, language)} {irregDate.getFullYear()}
          </div>
          <div className='border-inline-end-cell'>{formatAsShortDate(action.createDate, '.')}</div>
          <div>
            {action.cancelAllowed && (
              <ButtonRounded onClick={() => cancelActionHandler(action.id)} variant='outlined' disabled={cancelLoading}>
                Cancel
              </ButtonRounded>
            )}
          </div>
        </PolicyListItemCard>
      )
    }
    return (
      <PolicyListItemCard key={action.id} classes={'policy-card-actions-default-grid'}>
        <div>{action.responsibleName ?? '--'}</div>
        <div>
          <div className='actions-comments-cell'>{action.comment ?? '--'}</div>
        </div>
        <div></div>
        <div className='border-inline-start-cell'>{formatAsShortDate(action.createDate, '.')}</div>
      </PolicyListItemCard>
    )
  }

  return (
    <div className='policy-card-actions-list'>
      {Object.entries(groupedActions).map(([type, actions]) => (
        <Fragment key={type}>
          <LinedTitle
            collapsible
            collapsed={hiddenTypes[type]}
            onCollapse={() => setHiddenTypes(prev => ({ ...prev, [type]: !prev[type] }))}
            title={policyHistory[type][`text${hebrew() ? '' : 'En'}`]()}
            count={actions.length}
            hideLine
          >
            {renderHeaderContent(
              +type,
              actions.some(a => a.irregularityId)
            )}
            {actions.map(a => renderItemContent(a))}
          </LinedTitle>
        </Fragment>
      ))}
    </div>
  )
}

export default PolicyActionsHistory
