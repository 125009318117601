import { useEffect, useRef, useState } from 'react'
import { hebrew } from '../../../../i18n'
import { formatAsPercent } from '../../../../utils/formatAs'
import ModalWithSelection from '../../../../components/Common/ModalWithSelection/ModalWithSelection'
import { useSelector } from 'react-redux'
import PolicyListItemCard from '../PolicyListItemCard/PolicyListItemCard'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import { cn } from '../../../../utils/stylesUtils'
import LoadingOverlay from '../../../../components/Common/LoadingOverlay/LoadingOverlay'
import './PolicyTypeListItem.scss'
import { floatingNumbersOnly } from '../../../../services/inputs'

const PolicyTypeListItem = ({ policyType, updatePolicyType }) => {
  const {
    name,
    companyId,
    companyName,
    companyNameEn,
    categoryId,
    categoryName,
    categoryNameEn,
    reportName,
    averageCommissionRate,
    expectedCommissionRate,
    loading,
    categoryChanged,
    commissionRateChanged,
  } = policyType

  const { categories } = useSelector(({ shared }) => shared)
  const policyTypeCategory = categories.find(c => c.id === categoryId)
  const availableCategories = categories
    .filter(c => c.fieldId === policyTypeCategory.fieldId)
    .map(c => ({ ...c, value: c[`${hebrew() ? 'name' : 'englishName'}`] }))

  const updateCategoryHandler = value => {
    const newCategeory = categories.find(c => c.id === value)
    updatePolicyType({
      ...policyType,
      categoryName: newCategeory.name,
      categoryNameEn: newCategeory.englishName,
      categoryId: newCategeory.id,
    })
    setShowCategoriesSelector(false)
  }

  const inputRef = useRef()
  const commissionRateInitValue = formatAsPercent(expectedCommissionRate, {
    dashesForZero: false,
  })
  const [inputValue, setInputValue] = useState(commissionRateInitValue)
  const [resetInputValue, setResetInputValue] = useState(false)

  const changeInputValue = ({ target: { value } }) => {
    const charToExclude = '%'
    const rawValue = value.replace(charToExclude, '')
    if (rawValue === '' || floatingNumbersOnly(rawValue)) {
      setInputValue(rawValue + charToExclude)
    }
  }

  const updateComissionHandler = () => {
    if (inputValue.length > 1 && inputValue !== commissionRateInitValue && !resetInputValue) {
      const charToExclude = '%'
      const expectedCommissionRate = Number(inputValue.replace(charToExclude, ''))
      updatePolicyType({
        ...policyType,
        expectedCommissionRate,
      })
    } else setInputValue(commissionRateInitValue)
  }

  const [showCategoriesSelector, setShowCategoriesSelector] = useState(false)
  const categoriesCellRef = useRef()
  const selectorRef = useRef()
  let positionTop = false

  if (showCategoriesSelector) {
    const windowHeight = window.innerHeight
    const widndowBottomPadding = 50
    const cellBottom = categoriesCellRef?.current?.getBoundingClientRect().bottom
    positionTop = windowHeight - widndowBottomPadding - cellBottom < 300
  }

  useEffect(() => {
    resetInputValue && inputRef.current.blur()
  }, [resetInputValue])

  return (
    <PolicyListItemCard classes='policy-types-list-item policy-types-list-grid'>
      {loading && <LoadingOverlay />}
      <div>{name ?? '--'}</div>
      <div>
        <div className='company-info'>
          <img src={`./assets/companies-logos-light/${companyId}.png`} alt={companyId} />
          <p>{hebrew() ? companyName : companyNameEn}</p>
        </div>
      </div>
      <div ref={categoriesCellRef}>
        <div className='category-selector'>
          <button
            className={cn('item-input', { error: categoryChanged })}
            onClick={() => setShowCategoriesSelector(!showCategoriesSelector)}
          >
            <span>{hebrew() ? categoryName : categoryNameEn}</span>
            {showCategoriesSelector ? <ArrowDropUp /> : <ArrowDropDown />}
          </button>
          {showCategoriesSelector && (
            <div className={cn('modal-selection-wrapper', { 'position-top': positionTop })} ref={selectorRef}>
              <ModalWithSelection
                showSearch
                items={availableCategories}
                closeModal={() => setShowCategoriesSelector(false)}
                currentItemId={categoryId}
                onClickHandler={updateCategoryHandler}
              />
            </div>
          )}
        </div>
      </div>
      <div>{reportName ?? '--'}</div>
      <div className='financial-value-cell'>{formatAsPercent(averageCommissionRate, { dashesForZero: false })}</div>
      <div className='financial-value-cell'>
        <div className={cn('item-input', { error: commissionRateChanged })}>
          <input
            ref={inputRef}
            value={inputValue}
            onChange={changeInputValue}
            onFocus={() => {
              setResetInputValue(false)
              setInputValue('%')
            }}
            onBlur={updateComissionHandler}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                inputRef.current.blur()
              }
              if (e.key === 'Escape') {
                setResetInputValue(true)
              }
            }}
          />
        </div>
      </div>
    </PolicyListItemCard>
  )
}

export default PolicyTypeListItem
