import {
  COMPANY_MONTH_REPORTS_COUNT_UPDATE,
  REPORTS_DATES_FAIL,
  REPORTS_DATES_REQUEST,
  REPORTS_DATES_SUCCESS,
  REPORTS_DATE_SELECT,
  REPORTS_DATE_SELECT_LATEST,
  REPORTS_MONTH_STATUS_REQUEST,
  REPORTS_MONTH_STATUS_SUCCESS,
  SCAN_RESET_ALL,
} from '../../../types/actionTypesScan'

const monthStatusInit = {
  downloadPercent: 0,
  downloadedReports: 0,
  notDownloadedReports: 0,
  companies: [],
}

const initialState = {
  dates: [],
  datesLoading: true,
  monthStatus: monthStatusInit,
  monthStatusLoading: true,
}

export const scanReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case REPORTS_DATES_REQUEST: {
      return {
        ...state,
        datesLoading: true,
      }
    }
    case REPORTS_DATES_SUCCESS: {
      const latestDateIndex = payload.data.findLastIndex(d => d.hasValue)
      const updatedData = payload.date ?
       payload.data.map((d, i) => ({ ...d, selected:  payload.date === d.date })) : 
       payload.data.map((d, i) => ({ ...d, selected: i === latestDateIndex }))
    
      return {
        ...state,
        datesLoading: false,
        dates: updatedData,
      }
    }
    case REPORTS_DATES_FAIL: {
      return {
        ...state,
        datesLoading: false,
      }
    }
    case REPORTS_DATE_SELECT: {
      return {
        ...state,
        dates: state.dates.map(d => ({ ...d, selected: d.date === payload })),
      }
    }
    case REPORTS_DATE_SELECT_LATEST: {
      const latestDate = state.dates.findLast(d => d.hasValue).date
      return {
        ...state,
        dates: state.dates.map(d => ({ ...d, selected: d.date === latestDate })),
      }
    }
    case REPORTS_MONTH_STATUS_REQUEST: {
      return {
        ...state,
        monthStatusLoading: true,
      }
    }
    case REPORTS_MONTH_STATUS_SUCCESS: {
      return {
        ...state,
        monthStatusLoading: false,
        monthStatus: payload,
      }
    }
    case COMPANY_MONTH_REPORTS_COUNT_UPDATE: {
      const { id, availableReportsCount } = payload
      return {
        ...state,
        monthStatusLoading: false,
        monthStatus: {
          ...state.monthStatus,
          companies: state.monthStatus.companies.map(c => ({
            ...c,
            availableReportsCount: c.id === id ? availableReportsCount : c.availableReportsCount,
          })),
        },
      }
    }
    case SCAN_RESET_ALL:
      return initialState
    default:
      return state
  }
}
