import classNames from 'classnames'
import MarkContainer from '../MarkContainer'
import './CrossMark.scss'

const CrossMark = ({ green = false }) => {
  return (
    <MarkContainer>
      <div
        className={classNames('cross-mark', {
          green,
        })}
      ></div>
    </MarkContainer>
  )
}

export default CrossMark
