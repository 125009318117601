import { Switch } from '@mui/material'
import classNames from 'classnames'
import './ProfileFieldItem.css'

const ProfileFieldItem = ({ fieldName, svgIcon, selected, toggleHanlder, disabled = false }) => {
  return (
    <div className={classNames('profile-field-item', { selected })}>
      <div className='profile-field-item-content'>
        <div className='profile-field-item-icon'>{svgIcon}</div>
        <p className='profile-field-item-title'>{fieldName}</p>
      </div>
      <div className='profile-field-item-toggle'>
        <Switch checked={selected} onChange={toggleHanlder} disabled={disabled} />
      </div>
    </div>
  )
}

export default ProfileFieldItem
