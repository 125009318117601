import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import NoMobileLayout from '../../components/NoMobileLayout/NoMobileLayout'
import AgenciesGroupsBanner from '../../features/agentHouse/components/AgenciesGroupsBanner/AgenciesGroupsBanner'
import { Redirect, Route, useHistory, useParams, useRouteMatch } from 'react-router'
import { agencyHouseAnalyticsSubroute, agencyHouseRoutes } from '../../environment/urls'
import { bigAgencyPrivateRoutes, regularAgencyPrivateRoutes } from '../../routes/routes'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { getAgencyHouseAnalyticsFilters } from '../../features/agencyHouseAnalytics/actions/agencyHouseAnalitycsActions'
import { isRegularOrDeltaAgency } from '../../features/login/reducers/loginSelectors'
import AgentGroupsBanner from '../../components/AgentGroupsBanner/AgentGroupsBanner'
import './AgencyHouseAnalyticsScreen.scss'
import { AGENT_HOUSE_ANALYTICS_FILTERS_CLEAR } from '../../types/actionTypes'
import BackToBigAgency from '../../features/shared/components/BackToBigAgency/BackToBigAgency'

const AgencyHouseAnalyticsScreen = () => {
  const { path } = useRouteMatch()
  const dispatch = useDispatch()

  const { t: tCommon } = useTranslation('common')

  useEffect(() => {
    dispatch(getAgencyHouseAnalyticsFilters())
    return () => dispatch({ type: AGENT_HOUSE_ANALYTICS_FILTERS_CLEAR })
  }, [dispatch])

  if (isMobile) {
    return <NoMobileLayout pageName={tCommon('sidebar.lobby')} />
  }

  return (
    <div className='agency-house-analytics-screen-wrapper'>
      <BackToBigAgency />
      {isRegularOrDeltaAgency() ? <AgentGroupsBanner /> : <AgenciesGroupsBanner />}
      <div className='agency-house-analytics-screen' {...(isRegularOrDeltaAgency() && { style: { width: 'unset' } })}>
        <Route path={path} exact>
          <Redirect to={`${path}${agencyHouseAnalyticsSubroute.incomes}`} />
        </Route>
        <Route path={`${path}/:subpath`} component={AnalyticsSubrouteWrapper} />
      </div>
    </div>
  )
}

export default AgencyHouseAnalyticsScreen

function AnalyticsSubrouteWrapper() {
  const params = useParams()
  const { subpath } = params
  const history = useHistory()
  const agencyHouseAnalyticsRoute = (
    isRegularOrDeltaAgency() ? regularAgencyPrivateRoutes : bigAgencyPrivateRoutes
  ).find(r => r.path === agencyHouseRoutes.analytics)

  let subpathToRoute = agencyHouseAnalyticsRoute.subroute['/' + subpath]?.path
  if (!subpathToRoute) {
    subpathToRoute = agencyHouseAnalyticsRoute.subroute[agencyHouseAnalyticsSubroute.incomes].path
    history.replace({ pathname: agencyHouseAnalyticsRoute.path + subpathToRoute })
  }
  return agencyHouseAnalyticsRoute.subroute[subpathToRoute].component ?? <div>Under development</div>
}
