import {
  DOWNLOAD_COMPANIES_FAIL,
  DOWNLOAD_COMPANIES_REQUEST,
  DOWNLOAD_COMPANIES_SUCCESS,
  DOWNLOAD_COMPANY_FAIL,
  DOWNLOAD_COMPANY_REQUEST,
  DOWNLOAD_COMPANY_SUCCESS,
  DOWNLOAD_UPDATE_COMPANY_REQUEST,
  DOWNLOAD_RESET,
  DOWNLOAD_UPDATE_COMPANY_SUCCESS,
  DOWNLOAD_UPDATE_COMPANY_FAIL,
  DOWNLOAD_SET_AUTH,
  DOWNLOAD_SCAN_REQUEST,
  DOWNLOAD_SCAN_SUCCESS,
  DOWNLOAD_SCAN_FAIL,
  DOWNLOAD_SET_SCAN_STATUS,
  DOWNLOAD_SET_SCAN_PROGRESS,
  DOWNLOAD_SET_SCAN_RECORDS,
  DOWNLOAD_SCAN_STOP,
  DOWNLOAD_SCAN_SINGLE,
  DOWNLOAD_SMS_SUCCESS,
  DOWNLOAD_SCAN_ELEMENTARY,
  DOWNLOAD_HISTORY_REQUEST,
  DOWNLOAD_HISTORY_SUCCESS,
  DOWNLOAD_HISTORY_FAIL,
  DOWNLOAD_SCAN_PAUSE,
  DOWNLOAD_REMOVE_COMPANY_REQUEST,
  DOWNLOAD_REMOVE_COMPANY_SUCCESS,
  DOWNLOAD_REMOVE_COMPANY_FAIL,
  DOWNLOAD_EDIT_COMPANY_CREDS,
  DOWNLOAD_REMOVE_SCANNED,
  DOWNLOAD_DESELECT_COMPANY,
  DOWNLOAD_SET_AGENCY_DBNAME,
} from '../../../types/actionTypes'
import { companyAuthType } from '../../../types/companyAuthType'
import { scanStatusType } from '../../../types/scanStatus'

const initialState = {
  companies: [],
  companiesLoading: true,
  companiesError: null,

  selectedCompanyId: null,
  removeCompanyLoading: false,

  company: { reportRecords: [] },
  companyLoading: false,
  companyError: null,

  updateSuccess: false,
  updateLoading: false,
  updateError: false,

  scanLoading: false,
  scanStarted: false,
  scanPaused: false,
  scanAuth: companyAuthType.all,
  scanStatus: scanStatusType.None,
  scanProgress: 0,
  scanSms: '',
  singleReportScan: false,
  scanElementary: false,
  scannedCompanies: [],

  sendAnswerLoading: false,
  sendAnswerSuccess: false,
  sendAnswerError: null,

  history: [],
  historyLoading: true,
}

export const downloadReducer = (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOAD_COMPANIES_REQUEST:
      return {
        ...state,
        companiesLoading: true,
        companies: [],
        companiesError: null,
      }
    case DOWNLOAD_COMPANIES_SUCCESS:
      return {
        ...state,
        companiesLoading: false,
        companies: action.payload,
        companiesError: null,
      }
    case DOWNLOAD_COMPANIES_FAIL:
      return {
        ...state,
        companiesLoading: false,
        companies: [],
        companiesError: action.payload,
      }
    case DOWNLOAD_COMPANY_REQUEST:
      return {
        ...state,
        selectedCompanyId: action.payload,
        companyLoading: true,
        company: { reportRecords: [] },
        scanStatus: scanStatusType.None,
        companyError: null,
      }
    case DOWNLOAD_COMPANY_SUCCESS:
      return {
        ...state,
        companyLoading: false,
        company: { ...action.payload.company, waitingCredentialsEdit: action.payload.editCreds },
        companyError: null,
      }
    case DOWNLOAD_COMPANY_FAIL:
      return {
        ...state,
        companyLoading: false,
        company: { reportRecords: [] },
        companyError: action.payload,
      }
    case DOWNLOAD_EDIT_COMPANY_CREDS:
      return {
        ...state,
        company: { ...state.company, waitingCredentialsEdit: true },
      }
    case DOWNLOAD_UPDATE_COMPANY_REQUEST:
      return {
        ...state,
        updateLoading: true,
        updateSuccess: false,
        updateError: null,
      }
    case DOWNLOAD_UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        updateSuccess: action.payload,
        updateError: null,
        scanStatus: scanStatusType.None,
      }
    case DOWNLOAD_UPDATE_COMPANY_FAIL:
      return {
        ...state,
        updateLoading: false,
        updateSuccess: false,
        updateError: action.payload,
      }
    case DOWNLOAD_HISTORY_REQUEST:
      return {
        ...state,
        historyLoading: true,
        history: [],
        historyError: null,
      }
    case DOWNLOAD_HISTORY_SUCCESS:
      return {
        ...state,
        historyLoading: false,
        history: action.payload,
        historyError: null,
      }
    case DOWNLOAD_HISTORY_FAIL:
      return {
        ...state,
        historyLoading: false,
        history: [],
        historyError: action.payload,
      }
    case DOWNLOAD_SCAN_REQUEST:
      return {
        ...state,
        scanLoading: true,
      }
    case DOWNLOAD_SCAN_SUCCESS:
      return {
        ...state,
        scanLoading: false,
        scanStarted: true,
        scannedCompanies: [state.company.companyId, ...state.scannedCompanies],
      }
    case DOWNLOAD_SCAN_FAIL:
      return {
        ...state,
        scanLoading: false,
        scanStarted: false,
      }
    case DOWNLOAD_SCAN_PAUSE:
      return {
        scanPaused: action.payload,
      }
    case DOWNLOAD_SET_AUTH:
      return {
        ...state,
        scanAuth: action.payload,
      }
    case DOWNLOAD_SET_SCAN_STATUS:
      return {
        ...state,
        scanStatus: action.payload,
        //clear sms input on new scan status
        ...(action.payload === scanStatusType.WaitingSms && { scanSms: '' }),
      }
    case DOWNLOAD_SET_SCAN_PROGRESS:
      return {
        ...state,
        scanProgress: action.payload,
      }
    case DOWNLOAD_SET_SCAN_RECORDS:
      return {
        ...state,
        company: {
          ...state.company,
          reportRecords: state.company.reportRecords.map(rr => {
            const existingRecord = action.payload.find(r => r.id === rr.id)
            return {
              ...rr,
              disabled: existingRecord ? false : true,
              ...existingRecord,
            }
          }),
        },
      }
    case DOWNLOAD_SCAN_SINGLE:
      return {
        ...state,
        singleReportScan: action.payload,
      }
    case DOWNLOAD_SCAN_ELEMENTARY:
      return {
        ...state,
        scanElementary: action.payload,
      }
    case DOWNLOAD_SCAN_STOP:
      return {
        ...state,
        scanLoading: false,
        scanStarted: false,
        scanStatus: scanStatusType.None,
        scanProgress: 0,
        scanSms: '',
        sendAnswerLoading: false,
        sendAnswerSuccess: false,
        sendAnswerError: null,
      }
    case DOWNLOAD_REMOVE_COMPANY_REQUEST:
      return {
        ...state,
        removeCompanyLoading: true,
      }
    case DOWNLOAD_REMOVE_COMPANY_SUCCESS:
      return {
        ...state,
        removeCompanyLoading: false,
        companies: !action.payload.hasErrors
          ? state.companies.filter(c => c.companyId !== action.payload.companyId)
          : state.companies,
        selectedCompanyId:
          !action.payload.hasErrors && state.selectedCompanyId === action.payload.companyId
            ? null
            : state.selectedCompanyId,
      }
    case DOWNLOAD_REMOVE_COMPANY_FAIL:
      return {
        ...state,
        removeCompanyLoading: false,
      }
    case DOWNLOAD_REMOVE_SCANNED:
      return {
        ...state,
        scannedCompanies: state.scannedCompanies.filter(c => c !== action.payload),
      }
    case DOWNLOAD_DESELECT_COMPANY:
      return { ...state, selectedCompanyId: null }
    case DOWNLOAD_SET_AGENCY_DBNAME:
      return { ...state, agencyDbName: action.payload }
    case DOWNLOAD_RESET:
      return initialState
    default:
      return state
  }
}
