import classNames from 'classnames'
import { useRef, useState } from 'react'
import ClearButton from '../ClearButton/ClearButton'
import './InputSearch.scss'

const InputSearch = ({ placeholder, searchHandler = () => {}, autofocus = false }) => {
  const searchInputRef = useRef(null)
  const [searchText, setSearchText] = useState('')
  const onChangeHandler = value => {
    setSearchText(value)
    searchHandler(value)
  }

  return (
    <div
      className={classNames('search-input-container', {
        empty: searchText.length === 0,
      })}
    >
      <div className='search-input-icon' onClick={() => searchInputRef.current.focus()}>
        <img src='./assets/search-icon-sm/Search.png' alt='srch' className='img-icon' />
      </div>
      <input
        type='text'
        placeholder={placeholder}
        value={searchText}
        onChange={e => onChangeHandler(e.target.value)}
        ref={searchInputRef}
        autoFocus={autofocus}
      />
      {searchText.length > 0 && <ClearButton onCloseClick={() => onChangeHandler('')} />}
    </div>
  )
}

export default InputSearch
