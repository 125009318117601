export const paymentPlanType = {
  trial: 1,
  light: 2,
  popular: 3,
  pro: 4,
  bigAgency: 5,
  regularAgency: 6,
}

export const paymentPeriod = {
  monthly: 'monthly',
  yearly: 'yearly',
  weekly: 'weekly',
}

export const paymentPlan = {
  [paymentPlanType.trial]: {
    type: paymentPlanType.trial,
    benefits: [],
  },
  [paymentPlanType.light]: {
    type: paymentPlanType.light,
    customerCostMonthly: 0.14,
    monthCostMonthly: 349,
    symbol: '₪',
    customerCostYearly: 0.12,
    monthCostYearly: 279,
    benefits: [
      {
        text: 'משתמש סוכן עיקרי',
        textEn: 'Primary Agent User',
      },
      {
        text: 'שירות ותמיכה בסיסיים',
        textEn: 'Basic service and support',
      },
    ],
    discount: 20,
  },
  [paymentPlanType.popular]: {
    type: paymentPlanType.popular,
    customerCostMonthly: 0.13,
    monthCostMonthly: 399,
    symbol: '₪',
    customerCostYearly: 0.11,
    monthCostYearly: 329,
    benefits: [
      {
        text: 'משתמש סוכן עיקרי',
        textEn: 'Primary Agent User',
      },
      {
        text: 'עד 2 תתי-משתמשים ללא תשלום נוסף',
        textEn: 'Up to 2 sub-users at no extra charge',
      },
      {
        text: 'שירות ותמיכה בסיסיים',
        textEn: 'Basic service and support',
      },
    ],
    discount: 18,
    mostPopular: true,
  },
  [paymentPlanType.pro]: {
    type: paymentPlanType.pro,
    customerCostMonthly: '0.10',
    symbol: '₪',
    monthCostMonthly: 749,
    customerCostYearly: '0.10',
    monthCostYearly: 749,
    benefits: [
      {
        text: 'משתמש סוכן עיקרי',
        textEn: 'Primary Agent User',
      },
      {
        text: 'עד 6 תתי-משתמשים ללא תשלום נוסף',
        textEn: 'Up to 6 sub-users at no extra charge',
      },
      {
        text: 'הדרכה אישית לחיבור והטמעה של המערכת',
        textEn: 'Personal training for connection and implementation of the system',
      },
      {
        text: 'סיוע אישי בכל הנוגע להדרכה ושימוש אחת לחודש',
        textEn: 'Personal assistance regarding training and use once a month',
      },
    ],
  },
  [paymentPlanType.bigAgency]: {
    type: paymentPlanType.bigAgency,
    benefits: [],
  },
  [paymentPlanType.regularAgency]: {
    type: paymentPlanType.regularAgency,
    benefits: [],
  },
}
