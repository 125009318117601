const CheckboxCheckedSvg = ({ fill = 'var(--trend-up-color)' }) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' width='24' height='24'>
    <g id='check-box'>
      <path
        id='Vector'
        fill={fill}
        d='M 19 3 H 5 C 3.9 3 3 3.9 3 5 V 19 C 3 20.1 3.9 21 5 21 H 19 C 20.1 21 21 20.1 21 19 V 5 C 21 3.9 20.1 3 19 3 Z M 10.71 16.29 C 10.32 16.68 9.69 16.68 9.3 16.29 L 5.71 12.7 C 5.32 12.31 5.32 11.68 5.71 11.29 C 6.1 10.9 6.73 10.9 7.12 11.29 L 10 14.17 L 16.88 7.29 C 17.27 6.9 17.9 6.9 18.29 7.29 C 18.68 7.68 18.68 8.31 18.29 8.7 L 10.71 16.29 Z'
      />
    </g>
  </svg>
)

export default CheckboxCheckedSvg
