import { useState } from 'react'
import { registerStep, registerSteps } from '../../types/registerStep'
import store from '../../app/store'
import RegisterHeader from '../../features/registration/components/RegisterHeader/RegisterHeader'
import RegisterSidebar from '../../features/registration/components/RegisterSidebar/RegisterSidebar'
import LandingHeader from '../../features/landing/components/LandingHeader/LandingHeader'
import LandingFooter from '../../features/landing/components/LandingFooter/LandingFooter'
import RegisterStep from '../../features/registration/components/RegisterStep/RegisterStep'
import './RegisterScreen.scss'
import { twoFactorOption } from '../../types/twoFactorOptionTypes'

const phoneInitialData = {
  countryPhoneCode: store.getState().shared.countryPhoneCode,
  areaPhoneCode: store.getState().shared.areaPhoneCodes[0],
  phoneNumber: '',
  smsCode: [...Array(6)],
}

const agencyInitialData = {
  customerId: '',
  customerName: '',
  agencyName: '',
  agencyId: '',
  agentEmail: '',
  type: null,
  option: twoFactorOption.byPhone,
}

const RegisterScreen = () => {
  const [data, setData] = useState({
    ...phoneInitialData,
    ...agencyInitialData,
    selectedFields: [],
    fieldsCompanies: [],
    selectedCompanies: [],
  })
  const [step, setStep] = useState(registerStep.twoFactor)
  const steps = Object.values(registerSteps).filter(
    st => st.number !== registerStep.agentHouseDetails && st.number !== registerStep.registerFlowType
  )
  const [hideRegisterStep, setHideRegisterStep] = useState(false)

  const setStepHandler = step => {
    //if (step <= steps.length)
    setStep(step)
  }

  return (
    <>
      {step > registerStep.twoFactorSms ? (
        <div className='wrapper-register'>
          <RegisterHeader />
          {!hideRegisterStep &&
            step !== registerStep.registerFlowType &&
            step !== registerStep.agentHouseDetails &&
            step !== registerStep.payment && <RegisterSidebar steps={steps} currStep={step} setStep={setStepHandler} />}
          <RegisterStep
            step={step}
            setStep={setStepHandler}
            data={data}
            setData={setData}
            setHideRegisterStep={setHideRegisterStep}
          />
        </div>
      ) : (
        <div className='register-wrapper'>
          <LandingHeader />
          <RegisterStep step={step} setStep={setStepHandler} data={data} setData={setData} />
          <LandingFooter />
        </div>
      )}
    </>
  )
}

export default RegisterScreen
