import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { insuranceField } from '../../../../types/insuranceField'
import { getProfileFields, setProfileFields } from '../../actions/profileActions'
import ProfileFieldItem from '../ProfileField/ProfileFieldItem'
import { hebrew } from '../../../../i18n'
import { useTranslation } from 'react-i18next'
import './ProfileFields.css'

const ProfileFields = () => {
  const { t } = useTranslation('profile')
  const dispatch = useDispatch()
  const { companies, fields: availableFields } = useSelector(state => state.profile)
  const selectedFields = [...new Set(companies.flatMap(c => c.fields.map(f => f.id)))]

  const updateField = (fieldId, active) => {
    const updatedCompanies = companies.map(c => {
      let currentFields = c.fields.map(f => f.id)

      if (active && !currentFields.includes(fieldId)) {
        currentFields.push(fieldId)
      } else {
        currentFields = currentFields.filter(cf => cf !== fieldId)
      }
      return { companyId: c.id, InsuranceFields: currentFields }
    })
    dispatch(setProfileFields(updatedCompanies))
  }

  return (
    <div className='profile-fields'>
      <p>
        {t('fieldsCardTitle')} ({selectedFields.length})
      </p>
      <div className='fields-container'>
        {Object.values(insuranceField).map(field => (
          <ProfileFieldItem
            key={field.id}
            fieldName={hebrew() ? field.name : field.nameEn}
            svgIcon={field.svgIcon}
            toggleHanlder={() => updateField(field.id, !selectedFields.includes(field.id))}
            selected={selectedFields.includes(field.id)}
            disabled={!availableFields.includes(field.id)}
          />
        ))}
      </div>
    </div>
  )
}

export default ProfileFields
