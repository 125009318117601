import CheckMark from '../../../../components/Common/Marks/CheckMark/CheckMark'
import './PoliciesFilterDropdownItem.css'

const PoliciesFilterDropdownItem = ({ value, checked, setChecked, hideDropdown }) => {
  const clickHandler = () => {
    setChecked()
    hideDropdown()
  }
  return (
    <div className='policies-filter-dropdown-item' onClick={clickHandler}>
      <div className='policies-filter-dropdown-item-value'>{value}</div>
      {checked && (
        <div className='policies-filter-dropdown-item-checked-icon'>
          <CheckMark green={true} />
        </div>
      )}
    </div>
  )
}

export default PoliciesFilterDropdownItem
