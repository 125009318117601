import Loader from '../../../../components/Common/Loader/Loader'
import classNames from 'classnames'
import './CustomerTablesNavbar.scss'

const CustomerTablesNavbar = ({ navbarItems = [], currentTableType, setCurrentTable }) => {
  return (
    <nav className='customer-tables-navbar'>
      <ul>
        {navbarItems.map(item => {
          return (
            <li
              className={classNames({
                active: item.type === currentTableType,
                blured: item.blured,
                disabled: item.disabled,
              })}
              {...(!item.disabled && { onClick: () => setCurrentTable(item) })}
              key={item.text}
            >
              {item.text}
              {item.loading ? <Loader xs={true} /> : item.count && item.count !== 0 ? ' (' + item.count + ')' : ''}
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

export default CustomerTablesNavbar
