import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { agreement } from '../../../../components/ModalAgreement/ModalAgreement'
import './LandingFooter.scss'

const LandingFooter = () => {
  const { t } = useTranslation('early')

  const { pathname } = useLocation()

  const links = [
    {
      text: t('links.privacyPolicy'),
      href: `#${pathname}?agreement=${agreement.policy}`,
    },
    {
      text: t('links.termsOfUse'),
      href: `#${pathname}?agreement=${agreement.terms}`,
    },
  ]

  return (
    <>
      <footer className='landing-footer'>
        <div className='landing-footer-links'>
          <ul>
            {links.map(l => (
              <li key={l.text + l.href}>
                <a href={l.href}>{l.text}</a>
              </li>
            ))}
          </ul>
        </div>
        <div className='landing-footer-copyright' dir='ltr'>
          © 2023 by WeCo.Innovation
        </div>
      </footer>
    </>
  )
}

export default LandingFooter
