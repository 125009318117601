import './NoAccess.css'

const NoAccess = () => {
  return (
    <div className='no-access-container'>
      <img src='./assets/info-icon-md/Info.png' alt='' className='img-icon' />
      <div className='no-access-text'>No Access</div>
    </div>
  )
}

export default NoAccess
