import './NoData.css'

const NoData = ({ text = 'לא קיימים נתוני צבירה מחברת הביטוח עבור לקוח זה', showIcon = true }) => {
  return (
    <div className='no-data-container'>
      {showIcon && (
        <div className='no-data-icon'>
          <img src='./assets/info-icon-md/Info.png' alt='' className='img-icon' />
        </div>
      )}
      <div className='no-data-text'>{text}</div>
    </div>
  )
}

export default NoData
