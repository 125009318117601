import classNames from 'classnames'
import './ButtonRounded.scss'

const ButtonRounded = ({
  onClick,
  variant = 'contained',
  disabled = false,
  typeSubmit = false,
  styles = '',
  classes = '',
  children,
  size = 'medium',
  accent,
}) => {
  return (
    <button
      className={classNames(
        'button-rounded',
        { [variant]: variant, ...(accent && { [accent]: accent }) },
        size,
        classes,
        styles.split(' ')
      )}
      disabled={disabled}
      onClick={onClick}
      {...(typeSubmit && { type: 'submit' })}
    >
      {children}
    </button>
  )
}

export default ButtonRounded
