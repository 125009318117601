import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { WORKSTATION_TASKS_SEARCH } from '../../../../types/actionTypes'
import TasksSearchButtonMobile from '../TasksSearchButtonMobile/TasksSearchButtonMobile'
import TasksSearchInput from '../TasksSearchInput/TasksSearchInput'
import './SearchTasks.scss'

const SearchTasks = () => {
  const { t } = useTranslation('workstation')
  const { search } = useSelector(({ workstation }) => workstation)
  const dispatch = useDispatch()

  const setSearch = value => {
    dispatch({ type: WORKSTATION_TASKS_SEARCH, payload: value.trim() })
  }

  return (
    <>
      <TasksSearchInput placeholder={t('searchPlaceholder')} searchValue={search} setSearchValue={setSearch} />
      <TasksSearchButtonMobile />
    </>
  )
}

export default SearchTasks
