import { HubConnectionBuilder } from '@microsoft/signalr'
import { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { urlMaster, urlSignalR } from '../../environment/urls'
import ModalWindow from '../ModalWindow/ModalWindow'
import { getItemFromLocalStorage } from '../../services/localStorageService'
import './SignalRNotifications.scss'
import { useLocation } from 'react-router-dom'
import { adminMessage, sameRoutePath } from '../../types/adminMessageType'
import Button from '../Common/Button/Button'
import $api from '../../http'
import { useSelector } from 'react-redux'

const SignalRNotifications = () => {
  const { UserId } = useSelector(state => state.login)
  const [connection, setConnection] = useState(null)
  const [notifications, setNotifications] = useState([])
  const [notifyToRead, setNotifyToRead] = useState(null)
  const { pathname } = useLocation()

  const notificationsRef = useRef(null)

  notificationsRef.current = notifications

  const accessToken = getItemFromLocalStorage('AccessToken')

  const addNotification = notification => {
    let latestNotifications = notificationsRef.current
    if (!latestNotifications.map(n => n.id).includes(notification.id)) {
      const updatedNotifications = [...latestNotifications]
      updatedNotifications.push(notification)
      setNotifications(updatedNotifications)
    }
  }

  const markShown = () => {
    const updatedNotifications = notifications.map(n => (n.id === notifyToRead.id ? { ...n, shown: true } : n))
    setNotifyToRead(_ => null)
    setNotifications(_ => updatedNotifications)
  }

  const markRead = async () => {
    const res = await $api.post(`${urlMaster()}account/remove-notification`, {
      notificationId: notifyToRead.id,
      UserId,
    })
    markShown()
  }

  useEffect(() => {
    if (accessToken && !connection) {
      console.log('connecting')
      const connect = new HubConnectionBuilder()
        .withUrl(`${urlSignalR()}notficationshub`, {
          accessTokenFactory: () => accessToken,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .withAutomaticReconnect()
        .build()
      setConnection(connect)
    } else if (!accessToken && connection) {
      connection.stop().then(() => setConnection(null))
    } else if (connection && connection.state !== 'Connected') {
      connection
        .start()
        .then(() => {
          connection.on('Notification', notification => {
            addNotification(notification)
          })
        })
        .catch(error => {
          console.log(error)
        })
    }
  }, [connection, accessToken])

  useEffect(() => {
    const firstNotifyToRead = notifications.find(n => sameRoutePath(pathname, n) && !n.shown)
    if (firstNotifyToRead) setNotifyToRead(_ => firstNotifyToRead)
  }, [pathname, notifications])

  return createPortal(
    <>
      {notifyToRead && !notifyToRead.shown && (
        <ModalWindow showCloseIcon={false} closeOnOutsideClick={false}>
          <div className='notify-popup-container'>
            <div className='notify-popup-icon'>
              <img src='./assets/horn-icon/ic_horn.png' alt='atn' />
            </div>
            <div className='notify-popup-subject'>
              <h3>{notifyToRead.subject}</h3>
            </div>
            <div className='notify-popup-body'>
              <p>{notifyToRead.text}</p>
            </div>
            <div className='notify-popup-btns'>
              {/* Remind Me Later */}
              <Button caption='הזכר לי מאוחר יותר' onClick={markShown} />
              {/* I Have Read */}
              <Button caption='הבנתי' onClick={markRead} />
            </div>
          </div>
          {process.env.REACT_APP_API_ENV === 'development' && (
            <div className='notify-popup-service'>
              <div>notify id - {notifyToRead.id}</div>
              <div>notify type - {adminMessage[notifyToRead.type].path}</div>
            </div>
          )}
        </ModalWindow>
      )}
    </>,
    document.body
  )
}

export default SignalRNotifications
