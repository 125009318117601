import { useTranslation } from 'react-i18next'
import useApiRequest from '../../../../hooks/useApiRequest'
import {
  getProfileIrregLimitsRequest,
  getProfileIrregSettingsRequest,
  updateProfileIrregLimitsRequest,
  updateProfileIrregSettingsRequest,
} from '../../../../http/requests/profileRequests'
import ProfileSettingSwitch from '../ProfileSettingSwitch/ProfileSettingSwitch'
import { irregularSignal } from '../../../../types/irregularSignals'
import { hebrew } from '../../../../i18n'
import { irregularReasonObject } from '../../../../types/irregularReasonTypes'
import ButtonRounded from '../../../../components/Common/ButtonRounded/ButtonRounded'
import { floatingNumbersOnly } from '../../../../services/inputs'
import { useState } from 'react'
import { useEffect } from 'react'
import './ProfileIrregSettings.scss'
import ConfirmDeleteDialog from '../../../../components/ConfirmDeleteDialog/ConfirmDeleteDialog'
import { deleteAllIrregularitiesRequest } from '../../../../http/requests/irregularitiesRequests'

const limitsInit = {
  commissionAmountLimit: 0,
  commissionRateLimit: 0,
  negativeCommissionAmount: 0,
  negativePremiumAmount: 0,
}

const ProfileIrregSettings = () => {
  const { t } = useTranslation('profile')
  const [limits, setLimits] = useState(limitsInit)
  const [limitsDirty, setLimitsDirty] = useState(false)
  const [showConfirmDeleteAllIrregs, setShowConfirmDeleteAllIrregs] = useState(false)

  const {
    data: irregSettings,
    loading: irregSettingsLoading,
    fetch: getProfileIrregSettings,
  } = useApiRequest(getProfileIrregSettingsRequest, {
    runOnInit: true,
    dataInit: {},
  })
  const {
    data: irregLimits,
    loading: irregLimitsLoading,
    fetch: getProfileIrregLimits,
  } = useApiRequest(getProfileIrregLimitsRequest, {
    runOnInit: true,
  })
  const { loading: updateIrregSettingsLoading, fetch: updateProfileIrregSettings } = useApiRequest(
    updateProfileIrregSettingsRequest,
    { loadingOnInit: false }
  )
  const { loading: updateIrregLimitsLoading, fetch: updateProfileIrregLimits } = useApiRequest(
    updateProfileIrregLimitsRequest,
    { loadingOnInit: false }
  )
  const { loading: deleteAllIrregsLoading, fetch: deleteAllIrregs } = useApiRequest(deleteAllIrregularitiesRequest, {
    loadingOnInit: false,
  })

  const toggleIrregSettingHandler = reason => async e => {
    const active = e.target.checked
    await updateProfileIrregSettings({ params: { reason, active } })
    await getProfileIrregSettings({ silentLoading: true })
  }

  const onChangeHandler = ({ target }) => {
    const { value, id } = target
    if (value === '' || floatingNumbersOnly(value)) {
      setLimitsDirty(true)
      setLimits(prev => ({ ...prev, [id]: value }))
    }
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter' && limitsDirty) {
      applyLimitsHandler()
    }
    if (e.key === 'Escape') {
      setLimits(irregLimits)
    }
  }

  const applyLimitsHandler = async () => {
    const updatedLimits = Object.fromEntries(Object.entries(limits).map(l => [l[0], +l[1]]))
    await updateProfileIrregLimits({ params: updatedLimits })
    await getProfileIrregLimits({ silentLoading: true })
  }

  useEffect(() => {
    if (irregLimits) {
      setLimits(irregLimits)
      setLimitsDirty(false)
    }
  }, [irregLimits])

  return (
    <div className='profile-irreg-settings'>
      <div>
        {Object.entries(irregSettings).map(([key, value]) => (
          <section className='profile-irreg-settings-group' key={key}>
            <h5>{irregularSignal[key][`name${hebrew() ? '' : 'En'}`]}</h5>
            <div>
              {value.map(irr => (
                <ProfileSettingSwitch
                  title={irregularReasonObject[irr.reason][`name${hebrew() ? '' : 'En'}`]}
                  disabled={irregSettingsLoading || updateIrregSettingsLoading}
                  checked={irr.active}
                  toggleSwitch={toggleIrregSettingHandler(irr.reason)}
                  key={irr.reason}
                />
              ))}
            </div>
          </section>
        ))}
      </div>
      <div>
        <h5>{t('irregSettings.limitTitle')}</h5>
        <div className='profile-irreg-limits'>
          <div className='profile-irreg-limit-container'>
            <p>{t('irregSettings.negativeAmountSignal')}</p>
            <div className='profile-irreg-limit-form-container'>
              <div>
                <label htmlFor='negativePremiumAmount'>{t('irregSettings.premium')}</label>
                <div className='profile-irreg-limit-input-wrapper'>
                  <input
                    type='text'
                    value={limits.negativePremiumAmount}
                    id={'negativePremiumAmount'}
                    onChange={onChangeHandler}
                    onBlur={applyLimitsHandler}
                    disabled={irregLimitsLoading || updateIrregLimitsLoading}
                    onKeyDown={handleKeyDown}
                  />
                  <span>₪</span>
                </div>
              </div>
              <div>
                <label htmlFor='negativeCommissionAmount'>{t('irregSettings.commission')}</label>
                <div className='profile-irreg-limit-input-wrapper'>
                  <input
                    type='text'
                    value={limits.negativeCommissionAmount}
                    id={'negativeCommissionAmount'}
                    onChange={onChangeHandler}
                    onBlur={applyLimitsHandler}
                    disabled={irregLimitsLoading || updateIrregLimitsLoading}
                    onKeyDown={handleKeyDown}
                  />
                  <span>₪</span>
                </div>
              </div>
            </div>
          </div>
          <div className='profile-irreg-limit-container'>
            <p>{t('irregSettings.commissionAmountSignal')}</p>
            <div className='profile-irreg-limit-form-container'>
              <div>
                <label htmlFor='commissionAmountLimit'>{t('irregSettings.commissionAmount')}</label>
                <div className='profile-irreg-limit-input-wrapper'>
                  <input
                    type='text'
                    value={limits.commissionAmountLimit}
                    id={'commissionAmountLimit'}
                    onChange={onChangeHandler}
                    onBlur={applyLimitsHandler}
                    disabled={irregLimitsLoading || updateIrregLimitsLoading}
                    onKeyDown={handleKeyDown}
                  />
                  <span>₪</span>
                </div>
              </div>
            </div>
          </div>
          <div className='profile-irreg-limit-container'>
            <p>{t('irregSettings.commissionRateAmountSignal')}</p>
            <div className='profile-irreg-limit-form-container'>
              <div>
                <label htmlFor='commissionRateLimit'>{t('irregSettings.commissionRateAmount')}</label>
                <div className='profile-irreg-limit-input-wrapper'>
                  <input
                    type='text'
                    value={limits.commissionRateLimit}
                    id={'commissionRateLimit'}
                    onChange={onChangeHandler}
                    onBlur={applyLimitsHandler}
                    disabled={irregLimitsLoading || updateIrregLimitsLoading}
                    onKeyDown={handleKeyDown}
                  />
                  <span>%</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <p>{t('irregSettings.deleteAllIrregs')}</p>
        <ButtonRounded variant='danger' onClick={() => setShowConfirmDeleteAllIrregs(true)}>
          {t('irregSettings.deleteBtn')}
        </ButtonRounded>
      </div>
      {showConfirmDeleteAllIrregs && (
        <ConfirmDeleteDialog
          cancelHandler={() => setShowConfirmDeleteAllIrregs(false)}
          deleteHandler={async () => {
            await deleteAllIrregs()
            setShowConfirmDeleteAllIrregs(false)
          }}
          title={t('irregSettings.deleteAllIrregs')}
          text={t('irregSettings.deleteText')}
          cancelText={t('irregSettings.cancelBtn')}
          deleteText={t('irregSettings.deleteAllBtn')}
          disabled={deleteAllIrregsLoading}
        />
      )}
    </div>
  )
}

export default ProfileIrregSettings
