import { useTranslation } from 'react-i18next'
import InputText from '../../../../components/Common/InputText/InputText'
import './AgencyHouseDetailsForm.scss'

const AgencyHouseDetailsForm = ({
  agencyId,
  setAgencyId,
  setAgencyName,
  agencyName,
  setAgentEmail,
  agentEmail,
  setPhoneNumber,
  phoneNumber,
  enableEditPhoneNumber = false,
  setAgencyType,
  type,
  serverError,
  inputsDisabled,
  selected,
}) => {
  const { t } = useTranslation('registration')
  return (
    <div className='agent-house-form'>
      <div className='agent-house-avatar-container'>
        <div>{t('stepAgentHouseDetails.businessLogo')}</div>
      </div>
      <div className='agent-house-form-body'>
        <div className='agent-house-form-inputs'>
          <div className='agent-house-form-inputs-group'>
            <h5>{t('stepAgentHouseDetails.businessDetails')}</h5>
            <InputText
              disabled={inputsDisabled}
              autoFocus={true}
              inputName='agencyId'
              id='agentHouseLicense'
              label={t('stepAgentHouseDetails.license')}
              value={agencyId}
              onChange={setAgencyId}
              inputType='number'
            />
            <InputText
              disabled={inputsDisabled}
              inputName='agencyName'
              id='agentHouseName'
              label={t('stepAgentHouseDetails.businessName')}
              value={agencyName}
              onChange={setAgencyName}
            />
          </div>
          <div className='agent-house-form-inputs-group'>
            <h5>{t('stepAgentHouseDetails.contactInfo')}</h5>
            <InputText
              disabled={inputsDisabled}
              inputName={enableEditPhoneNumber ? 'phoneNumber' : 'agentEmail'}
              id={enableEditPhoneNumber ? 'phoneNumber' : 'agentHouseEmail'}
              label={t(`stepAgentHouseDetails.${enableEditPhoneNumber ? 'phone' : 'email'}`)}
              inputType={enableEditPhoneNumber ? 'tel' : 'email'}
              value={enableEditPhoneNumber ? phoneNumber : agentEmail}
              onChange={enableEditPhoneNumber ? setPhoneNumber : setAgentEmail}
              required
            />
          </div>
          {serverError && <span className='agent-house-form-error'>{serverError}</span>}
        </div>
        <p>{t('stepAgentHouseDetails.formRemark')} </p>
      </div>
    </div>
  )
}

export default AgencyHouseDetailsForm
