import React from 'react'

const CashPlusIconSvg = ({ fill = 'var(--text-primary-color)' }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' fill='none'>
    <path
      d='M25 25V28.3333H30V33.3333H33.3333V28.3333H38.3333V25H33.3333V20H30V25M24.95 19.35C24.75 17.1333 22.65 14.95 20 15C17.1667 15.05 15 17.1667 15 20C15 22.8333 17.1667 24.9 20 25C20.6333 25 21.2833 24.8667 21.9 24.6167C22.35 22.7833 23.1 21.05 24.95 19.35ZM21.6667 26.6667H11.6667C11.6667 24.8333 10.1833 23.3333 8.33333 23.3333V16.6667C10.1833 16.6667 11.6667 15.1833 11.6667 13.3333H28.3333C28.3333 15.1833 29.8333 16.6667 31.6667 16.6667V16.7667C32.7833 16.7667 33.9 16.9667 35 17.3333V10H5V30H22.2C21.8333 28.8833 21.6667 27.7667 21.6667 26.6667Z'
      fill={fill}
    />
  </svg>
)
export default CashPlusIconSvg
