import './SignoutButton.css'

const SignoutButton = ({ onClickHandler }) => {
  return (
    <>
      <div className='signout-btn' onClick={onClickHandler}>
        <img src='./assets/logout/Inactive.png' alt='' className='img-icon' />
      </div>
    </>
  )
}

export default SignoutButton
