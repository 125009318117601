export const policyStatusType = {
  active: 1,
  new: 2,
  notActive: 3,
  irregular: 4,
  future: 5,
}

export const policyStatus = {
  [policyStatusType.active]: {
    id: policyStatusType.active,
    type: 'active',
    name: 'פעילה',
    nameEn: 'active',
  },
  [policyStatusType.new]: {
    id: policyStatusType.new,
    type: 'new',
    name: 'חדש',
    nameEn: 'new',
  },
  [policyStatusType.notActive]: {
    id: policyStatusType.notActive,
    type: 'inactive',
    name: 'לא פעילה',
    nameEn: 'inactive',
  },
  [policyStatusType.irregular]: {
    id: policyStatusType.irregular,
    type: 'irregular',
    name: 'חריגה',
    nameEn: 'irregular',
  },
  [policyStatusType.future]: {
    id: policyStatusType.future,
    type: 'future',
    name: 'עתידית',
    nameEn: 'future',
  },
}
