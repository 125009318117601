const MoneyShekelIconSvg = ({ fill = 'var(--text-primary-color)' }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' fill={fill}>
    <path
      d='M15.1734 28.8561C14.9766 28.8561 14.8093 28.7872 14.6716 28.6494C14.5338 28.5117 14.4649 28.3346 14.4649 28.1181V18.1993C14.4649 17.9828 14.5338 17.8057 14.6716 17.6679C14.8093 17.5301 14.9766 17.4613 15.1734 17.4613H17.9779C18.1943 17.4613 18.3715 17.5301 18.5092 17.6679C18.647 17.8057 18.7159 17.9828 18.7159 18.1993V25.3727H23.8819C25.2202 25.3727 26.1943 25.0381 26.8044 24.369C27.4342 23.6802 27.7491 22.7159 27.7491 21.476V12.7085C27.7491 12.5117 27.818 12.3444 27.9557 12.2066C28.0935 12.0689 28.2706 12 28.4871 12H31.2915C31.4883 12 31.6556 12.0689 31.7934 12.2066C31.9311 12.3444 32 12.5215 32 12.738V21.5055C32 23.2374 31.7048 24.6445 31.1144 25.7269C30.524 26.8093 29.6384 27.6064 28.4576 28.1181C27.2768 28.6101 25.8007 28.8561 24.0295 28.8561H15.1734ZM8.70849 28.8561C8.51169 28.8561 8.3444 28.7872 8.20664 28.6494C8.06888 28.5117 8 28.3346 8 28.1181V12.738C8 12.5215 8.06888 12.3444 8.20664 12.2066C8.3444 12.0689 8.51169 12 8.70849 12H17.5646C19.3358 12 20.8118 12.2558 21.9926 12.7675C23.1734 13.2595 24.059 14.0467 24.6494 15.1292C25.2399 16.2116 25.5351 17.6187 25.5351 19.3506V22.6568C25.5351 22.8536 25.4662 23.0209 25.3284 23.1587C25.1907 23.2964 25.0234 23.3653 24.8266 23.3653H22.0221C21.8057 23.3653 21.6285 23.3063 21.4908 23.1882C21.353 23.0504 21.2841 22.8733 21.2841 22.6568V19.3801C21.2841 18.1205 20.9692 17.1562 20.3395 16.4871C19.7294 15.818 18.7552 15.4834 17.417 15.4834H12.2509V28.1181C12.2509 28.3346 12.182 28.5117 12.0443 28.6494C11.9065 28.7872 11.7294 28.8561 11.5129 28.8561H8.70849Z'
      fill='white'
    />
  </svg>
)

export default MoneyShekelIconSvg
