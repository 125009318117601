import classNames from 'classnames'
import './TabsNavbar.scss'

const TabsNavbar = ({ items, currentItem, setCurrentItem, disabled = false }) => {
  return (
    <nav className={classNames('tabs-navbar', { disabled })}>
      <ul>
        {items.map(ni => (
          <li
            key={ni.id}
            className={classNames({ 'tabs-navbar-active-item': currentItem === ni.id })}
            {...(!disabled && { onClick: () => setCurrentItem(ni.id) })}
          >
            {ni.text}
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default TabsNavbar
