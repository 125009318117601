import { useCallback, useEffect, useState } from 'react'
import PolicyTypesList from './PolicyTypesList'
import CustomerTablesNavbar from '../../../customer/components/CustomerTablesNavbar/CustomerTablesNavbar'
import { useTranslation } from 'react-i18next'
import { getPolicyTypesRequest, updateAgencyPolicyTypeRequest } from '../../../../http/requests/policiyMappingRequests'
import PoliciesFilters from '../PoliciesFilters/PoliciesFilters'
import { useSelector } from 'react-redux'
import { getCheckedIds } from '../../../../utils/objectUtils'
import './PolicyTypesListContainer.scss'

const policyTypeListType = {
  all: 'all',
  new: 'new',
}

const PolicyTypesListContainer = () => {
  const { t } = useTranslation('policies')
  const [list, setList] = useState([])
  const [listType, setListType] = useState(policyTypeListType.all)
  const [listLoading, setListLoading] = useState(true)

  const {
    searchFilters: { reportName },
    selectFilters: { companies, categories, policyTypes },
    rangeFilters: { averageCommissionRateRange, expectedCommissionRateRange },
  } = useSelector(({ policies }) => policies)

  const updateListItem = async item => {
    try {
      setList(prev => prev.map(pr => ({ ...pr, loading: pr.id === item.id ? true : pr.loading })))
      const {
        data: { data: updateSucces },
      } = await updateAgencyPolicyTypeRequest({
        id: item.id,
        categoryId: item.categoryId,
        validationCommissionRate: item.expectedCommissionRate,
      })
      if (updateSucces) {
        fetchList({ showLoader: false })
      }
    } catch (error) {
      setList(prev => prev.map(pr => ({ ...pr, loading: pr.id === item.id ? false : pr.loading })))
    }
  }

  const fetchList = useCallback(
    async ({ showLoader = true } = {}) => {
      try {
        setListLoading(showLoader)
        const {
          data: { data },
        } = await getPolicyTypesRequest({
          reportName,
          companies: getCheckedIds(companies, 'selected'),
          categories: getCheckedIds(categories, 'selected'),
          policyTypes: getCheckedIds(policyTypes, 'selected'),
          averageCommissionRateRange,
          expectedCommissionRateRange,
        })
        setList(data)
        setListLoading(false)
      } catch (error) {
        setListLoading(false)
      }
    },
    [reportName, companies, categories, averageCommissionRateRange, expectedCommissionRateRange, policyTypes]
  )

  useEffect(() => {
    fetchList()
  }, [fetchList])

  return (
    <div className='policy-types-list-container'>
      <header>
        <h3>{t(`policyTypesTab.tabName`)}</h3>
      </header>
      <CustomerTablesNavbar
        navbarItems={Object.values(policyTypeListType).map(v => ({ type: v, text: t(`policyTypesTab.${v}Types`) }))}
        currentTableType={listType}
        setCurrentTable={({ type }) => setListType(type)}
      />
      <div className='policies-filters-wrapper'>
        <PoliciesFilters />
      </div>
      <PolicyTypesList
        list={list.filter(pt => (listType === policyTypeListType.new ? pt.newType : true))}
        loading={listLoading}
        updateListItem={updateListItem}
      />
    </div>
  )
}

export default PolicyTypesListContainer
