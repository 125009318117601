import classNames from 'classnames'
import React, { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import AgenciesListFilterDropdownItem from './AgenciesListFilterDropdownItem'
import './AgenciesListFilterDropdown.css'

const AgenciesListFilterDropdown = ({
  title,
  filterItems = [],
  setChecked,
  multiSelect = true,
  clearFilter = () => {},
  disabled = false,
}) => {
  const { t, i18n } = useTranslation('customers')
  const all = t('filters.all')
  const [open, setOpen] = useState(false)
  const refDropdown = useRef(null)
  const hasChecked = filterItems && filterItems.some(item => item.checked)

  const clearFilters = () => {
    const checkedItem = filterItems.find(item => item.checked === true)
    if (checkedItem) {
      setChecked(checkedItem.id, false)
      filterItems.forEach(item => (item.checked = false))
    }

    clearFilter()
  }

  const handleHideDropdown = event => {
    if (event.key === 'Escape' || (refDropdown.current && !refDropdown.current.contains(event.target))) {
      setOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleHideDropdown, true)
    document.addEventListener('click', handleHideDropdown, true)
    return () => {
      document.removeEventListener('keydown', handleHideDropdown, true)
      document.removeEventListener('click', handleHideDropdown, true)
    }
  })
  return (
    <div
      className={classNames('agencies-list-filter-dropdown', {
        open: open && filterItems,
        disabled,
        'has-checked': hasChecked,
      })}
      ref={refDropdown}
    >
      <div className='agencies-list-filter-content' {...(!disabled && { onClick: () => setOpen(!open) })}>
        <div>
          <p>{title}</p>
          {
            <span
              style={{
                visibility: filterItems.filter(itm => itm.checked).length > 0 && !disabled ? 'visible' : 'hidden',
              }}
            >
              !
            </span>
          }
        </div>
        <div className='agencies-list-filter-dropdown-icon'>
          <img src='./assets/arrow-dropdown-down/arrow_drop_down_24px.png' alt='dwn' className='img-icon' />
        </div>
      </div>

      {open && filterItems && (
        <div className='agencies-list-filter-dropdown-items'>
          {filterItems.map((item, i) => (
            <AgenciesListFilterDropdownItem
              value={i18n.language === 'he' ? item.name : item?.nameEn ?? item?.englishName}
              checked={item.checked}
              key={item.id + ' ' + i}
              setChecked={() => setChecked(item.id, !item.checked)}
              hideDropdown={() => {
                if (!multiSelect) setOpen(false)
              }}
              disabled={item.disabled}
              disabledDesc={item.disabledDesc}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default AgenciesListFilterDropdown
