import { useTranslation } from 'react-i18next'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AgentGroupsSidebar from '../AgentGroupsSidebar/AgentGroupsSidebar'
import { getUserNameInitials } from '../../services/getUserNameInitials'
import { avatarStyles } from '../../types/colorsTypes'
import { getRandomInt } from '../../services/getRandom'
import { deselectAgentsGroups } from '../../features/shared/actions/sharedActions'
import './AgentGroupsSelector.scss'
import { useCrossDomainCookies } from '../../environment/urls'

const AgentGroupsSelector = () => {
  const [showSidebar, setShowSidebar] = useState(false)
  const { selectedGroupsIds, agentGroups } = useSelector(({ shared }) => shared)
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const groups = agentGroups.filter(gr => selectedGroupsIds.includes(gr.id))

  const visibleGroups = groups.slice(0, 3)
  const summaryAvatar =
    groups.length > 3 ? (
      <div className='agent-groups-avatar default'>
        <span dir='ltr'>+{groups.length - 1}</span>
      </div>
    ) : null

  const closeSidebarHandler = () => {
    setShowSidebar(false)
  }

  useEffect(() => {
    document.cookie = `agentGroups=${selectedGroupsIds.join('-')};  ${useCrossDomainCookies} ${
      selectedGroupsIds.length === 0 ? 'expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;' : ''
    }`
  }, [selectedGroupsIds])

  return (
    <>
      <div className='agent-groups-selector' onClick={() => setShowSidebar(true)}>
        {selectedGroupsIds.length === 0 ? (
          <div className='agent-groups-empty'>{t('agentGroups.selectGroup')}</div>
        ) : (
          <>
            <div className='agent-groups-avatars'>
              {visibleGroups.map(gr => (
                <div
                  key={gr.id}
                  className='agent-groups-avatar'
                  style={avatarStyles[gr?.colorIndex ?? getRandomInt(1, 3) - 1]}
                >
                  {getUserNameInitials(gr.name)}
                </div>
              ))}
              {summaryAvatar}
            </div>
            <div className='agent-groups-all'>
              {groups.map(gr => (
                <div
                  key={gr.id}
                  className='agent-groups-avatar'
                  style={avatarStyles[gr?.colorIndex ?? getRandomInt(1, 3) - 1]}
                  // onClick={e => {
                  //   e.stopPropagation()
                  // }}
                >
                  <button
                    className='agent-groups-avatar-deselect'
                    onClick={e => {
                      e.stopPropagation()
                      dispatch(deselectAgentsGroups([gr.id]))
                    }}
                  >
                    <img src='./assets/close-icon-alt-sm/Close.png' alt='dslct' />
                  </button>
                  <div>{getUserNameInitials(gr.name)}</div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      <AgentGroupsSidebar showSidebar={showSidebar} closeSidebar={closeSidebarHandler} />
    </>
  )
}

export default AgentGroupsSelector
