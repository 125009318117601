import { useState, useEffect, useRef } from 'react'
import CheckMark from '../../../../components/Common/Marks/CheckMark/CheckMark'
import CrossMark from '../../../../components/Common/Marks/CrossMark/CrossMark'
import './CustomerDetailsInput.scss'

const CustomerDetailsInput = ({ title, applyChanges, closeInput, detailsType, initValue = '' }) => {
  const [value, setValue] = useState(initValue)
  const inputRef = useRef(null)
  const inputRefContainer = useRef(null)

  const applyClickHandler = () => {
    initValue !== value && value.length > 0 && applyChanges(detailsType, value)
    closeInput()
  }
  const closeClickhandler = () => {
    closeInput()
  }

  const handleChange = event => {
    setValue(event.target.value)
  }

  const handleHideInput = event => {
    if (event.key === 'Escape' || (inputRefContainer.current && !inputRefContainer.current.contains(event.target))) {
      closeInput()
    }
  }

  useEffect(() => {
    inputRef.current.focus()
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', handleHideInput, true)
    document.addEventListener('click', handleHideInput, true)
    return () => {
      document.removeEventListener('keydown', handleHideInput, true)
      document.removeEventListener('click', handleHideInput, true)
    }
  })

  return (
    <div className='customer-details-input-container' ref={inputRefContainer}>
      <div className='customer-details-input-title'>{title}</div>
      <input type='text' value={value} onChange={handleChange} ref={inputRef} />
      <div className='customer-details-input-btns-container'>
        <div className='customer-details-input-btn' onClick={() => closeClickhandler()}>
          <CrossMark />
        </div>
        <div className='customer-details-input-btn' onClick={() => applyClickHandler()}>
          <CheckMark />
        </div>
      </div>
    </div>
  )
}

export default CustomerDetailsInput
