import { Switch, Tooltip } from '@mui/material'
import { InfoTwoTone } from '@mui/icons-material'
import './ProfileSettingSwitch.scss'

const ProfileSettingSwitch = ({ title, desc, tooltip, checked, disabled, toggleSwitch }) => {
  return (
    <div className='profile-setting-switch-card'>
      <div>
        <div>
          <p>{title}</p>
          {tooltip && (
            <Tooltip title={tooltip} arrow placement='bottom'>
              <InfoTwoTone />
            </Tooltip>
          )}
        </div>
        {desc && <p>{desc}</p>}
      </div>
      <div>
        <Switch checked={checked} onChange={toggleSwitch} disabled={disabled} />
      </div>
    </div>
  )
}

export default ProfileSettingSwitch
