import {
  WORKSTATION_TASKS_FAIL,
  WORKSTATION_TASKS_REQUEST,
  WORKSTATION_TASKS_SUCCESS,
  WORKSTATION_SET_TASK_STATUS_FAIL,
  WORKSTATION_SET_TASK_STATUS_REQUEST,
  WORKSTATION_SET_TASK_STATUS_SUCCESS,
  WORKSTATION_TASK_AGENT_FAIL,
  WORKSTATION_TASK_AGENT_REQUEST,
  WORKSTATION_TASK_AGENT_SUCCESS,
  WORKSTATION_FILTERS_REQUEST,
  WORKSTATION_FILTERS_SUCCESS,
  WORKSTATION_FILTERS_FAIL,
  WORKSTATION_FILTER_APPLY,
} from '../../../types/actionTypes'
import {
  getTasksFiltersRequest,
  getTasksRequest,
  setTasksAgentRequest,
  setTasksStatusRequest,
} from '../../../http/requests'
import { customerTask, customerTaskStatus, customerTaskStatusType } from '../../../types/customerTaskTypes'

export const getTasks =
  ({ showLoader = true } = {}) =>
  async (dispatch, getState) => {
    const { filters, from, to } = getState().workstation
    const getCheckedValues = ({ values }) => values.filter(v => v.checked).map(v => v.value)
    const users = getCheckedValues(filters.employees)
    const taskTypes = getCheckedValues(filters.taskTypes)
    const statuses = getCheckedValues(filters.taskStatuses)
    const categories = getCheckedValues(filters.categories)
    dispatch({ type: WORKSTATION_TASKS_REQUEST, payload: showLoader })
    try {
      const {
        data: {
          data: { activeTreatments: tasksWaiting, completedTreatments: tasksTreated, monetaryValue },
        },
      } = await getTasksRequest({ users, taskTypes, statuses, categories, from, to })
      dispatch({
        type: WORKSTATION_TASKS_SUCCESS,
        payload: { tasksWaiting, tasksTreated, monetaryValue },
      })
    } catch (error) {
      dispatch({
        type: WORKSTATION_TASKS_FAIL,
        payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      })
    }
  }

export const getTasksFilters = () => async dispatch => {
  dispatch({ type: WORKSTATION_FILTERS_REQUEST })
  try {
    const {
      data: {
        data: { categories, categoriesEn, dates, employees, taskTypes },
      },
    } = await getTasksFiltersRequest()
    const filters = {
      categories: {
        id: 'categories',
        name: 'מוצר',
        nameEn: 'Category',
        values: categories.map((c, i) => ({
          id: i + 1,
          name: c ?? 'לא משויך למוצר',
          nameEn: categoriesEn[i] ?? 'No Category Associated',
          value: c,
          checked: false,
        })),
      },
      taskTypes: {
        id: 'taskTypes',
        name: 'סוג משימה',
        nameEn: 'Task Type',
        values: taskTypes.map((tt, i) => ({
          id: tt,
          name: customerTask[tt].text(),
          nameEn: customerTask[tt].textEn(),
          value: tt,
          checked: false,
        })),
      },
      employees: {
        id: 'employees',
        name: 'עובד',
        nameEn: 'Employee',
        values: employees.map((e, i) => ({
          id: e.key,
          name: e.value,
          nameEn: e.value,
          value: e.key,
          checked: false,
        })),
      },
      taskStatuses: {
        id: 'taskStatuses',
        name: 'סטטוס',
        nameEn: 'Status',
        values: Object.values(customerTaskStatusType).map(st => ({
          id: st,
          name: customerTaskStatus[st].text(),
          nameEn: customerTaskStatus[st].textEn(),
          value: st,
          checked: false,
        })),
      },
    }
    dispatch({
      type: WORKSTATION_FILTERS_SUCCESS,
      payload: { filters, dates },
    })
  } catch (error) {
    dispatch({
      type: WORKSTATION_FILTERS_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const applyTasksFilter = (filterId, valueId) => ({
  type: WORKSTATION_FILTER_APPLY,
  payload: { filterId, valueId },
})

export const setTaskStatus =
  ({ ids, status, note }) =>
  async dispatch => {
    dispatch({ type: WORKSTATION_SET_TASK_STATUS_REQUEST, payload: ids })
    try {
      const success = await setTasksStatusRequest({ ids, status, note })
      dispatch({
        type: WORKSTATION_SET_TASK_STATUS_SUCCESS,
        payload: { success, ids, status, note },
      })
      if (success) dispatch(getTasks({ showLoader: false }))
    } catch (error) {
      dispatch({
        type: WORKSTATION_SET_TASK_STATUS_FAIL,
        payload: {
          error: error.response && error.response.data.message ? error.response.data.message : error.message,
          ids,
        },
      })
    }
  }

export const setTaskAgent =
  ({ ids, agentId, agentName }) =>
  async dispatch => {
    dispatch({ type: WORKSTATION_TASK_AGENT_REQUEST, payload: ids })
    try {
      const success = await setTasksAgentRequest({ ids, agentId })
      dispatch({
        type: WORKSTATION_TASK_AGENT_SUCCESS,
        payload: { success, ids, agentId, agentName },
      })
    } catch (error) {
      dispatch({
        type: WORKSTATION_TASK_AGENT_FAIL,
        payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      })
    }
  }
