import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getSubAgenciesRequest } from '../../../../http/requests/agencyHouseRequests'
import AgenciesListContainer from '../AgenciesListContainer/AgenciesListContainer'
import AgenciesListItem from '../AgenciesListItem/AgenciesListItem'
import './SubAgencies.scss'
import ButtonCheckbox from '../../../../components/Common/ButtonCheckbox/ButtonCheckbox'
import { agencyExistType } from '../../../../types/agencyTypes'
import { hebrew } from '../../../../i18n'
import AgencyCompanyChip from '../AgencyCompanyChip/AgencyCompanyChip'
import ExportButton from '../../../../components/Common/ExportButton/ExportButton'
import { useDispatch } from 'react-redux'
import { enableSubagencyMode } from '../../../login/actions/loginActions'
import EditSubagencyForm from '../EditSubagencyForm/EditSubagencyForm'

const SubAgencies = () => {
  const { t } = useTranslation('agencyHouse')
  const [agencies, setAgencies] = useState([])
  const [agenciesLoading, setAgenciesLoading] = useState(true)
  const [agenciesError, setAgenciesError] = useState(null)
  const dispatch = useDispatch()
  const [selectedAgency, setSelectedAgency] = useState(null)

  const updateAgencySuccess = success => {
    success && fetchAgencies({ silentLoading: true })
  }

  const subAgencyMenu = agency => [
    {
      id: 1,
      text: (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <ExportButton />
          <span>{t('subAgencyMenu.goToLobby')}</span>
        </div>
      ),
      clickHandler: () => {
        dispatch(enableSubagencyMode(agency.AgencyDbName))
      },
    },
    {
      id: 2,
      text: t('subAgencyMenu.edit'),
      clickHandler: () => {
        setSelectedAgency(agency)
      },
    },
  ]

  const fetchAgencies = async ({ isCancelled = false, silentLoading = false } = {}) => {
    try {
      !silentLoading && setAgenciesLoading(true)
      setAgenciesError(null)
      const {
        data: { Data },
      } = await getSubAgenciesRequest()
      if (!isCancelled) {
        setAgenciesLoading(false)
        setAgencies(Data)
      }
    } catch (error) {
      if (!isCancelled) {
        setAgenciesLoading(false)
        setAgenciesError(error)
      }
    }
  }

  useEffect(() => {
    let isCancelled = false
    fetchAgencies({ isCancelled })
    return () => {
      isCancelled = true
    }
  }, [])

  return (
    <>
      <AgenciesListContainer
        listLoading={agenciesLoading}
        noItems={agencies.length === 0}
        addAgencyType={agencyExistType.new}
        onAddSuccess={fetchAgencies}
      >
        <div className='agencies-list-heading'>
          <div>
            <ButtonCheckbox />
          </div>
          <div className='sub-agency-item-grid'>
            <div>{t('agenciesListHeadings.subAgencyName')}</div>
            <div>{t('agenciesListHeadings.agencyIdNumber')}</div>
            <div>{t('agenciesListHeadings.fields')}</div>
            <div>{t('agenciesListHeadings.companies')}</div>
          </div>
          <div></div>
        </div>
        <div className='agencies-list'>
          {agencies.map(a => (
            <AgenciesListItem key={a.AgencyId} menuItems={subAgencyMenu(a)}>
              <div className='sub-agency-item-grid'>
                <div>{a.AgencyName}</div>
                <div>{a.AgencyLicenseNumber}</div>
                <div className='agency-fields'>
                  {a.Fields.map(f => (
                    <p key={f.Id}>{hebrew() ? f.Name : f.EngilshName}</p>
                  ))}
                </div>
                <div className='agency-companies'>
                  <div className='agency-companies-list'>
                    {a.Companies.slice(0, 4).map(c => (
                      <AgencyCompanyChip key={c.Id}>
                        <img src={`./assets/companies-logos-light/${c.Id}.png`} alt={c.Name} />
                        <p>{hebrew() ? c.Name : c.NameEn}</p>
                      </AgencyCompanyChip>
                    ))}
                  </div>
                  {a.Companies.length > 4 && (
                    <div
                      className='agency-companies-hidden-badge'
                      title={`${a.Companies.slice(4, a.Companies.length)
                        .map(c => (hebrew() ? c.Name : c.NameEn))
                        .join(', ')}`}
                    >
                      {a.Companies.length - 4}+
                    </div>
                  )}
                </div>
              </div>
            </AgenciesListItem>
          ))}
        </div>
      </AgenciesListContainer>
      {selectedAgency && (
        <EditSubagencyForm
          agency={selectedAgency}
          closeForm={() => setSelectedAgency(null)}
          setUpdateSuccess={updateAgencySuccess}
        />
      )}
    </>
  )
}

export default SubAgencies
