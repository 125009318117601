import { Trans, useTranslation } from 'react-i18next'
import './MarketingHero.scss'
import { insuranceField } from '../../../../types/insuranceField'
import { hebrew } from '../../../../i18n'
import { insuranceCategory } from '../../../../types/insuranceCategory'
import { isMobile } from 'react-device-detect'

const MarketingHero = () => {
  const { t } = useTranslation('common')
  return (
    <div
      className='marketing-hero-wrapper'
      id='marketing'
      style={{
        backgroundImage: `url('assets/_marketing/_hero/bg${hebrew() && !isMobile ? '' : '_en'}${
          isMobile ? '-mobile' : ''
        }.png')`,
        backgroundPosition: 'center top',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <section className='marketing-hero-container'>
        <div className='marketing-hero-tagline'>
          <h4>{t('marketingPageHero.preTitle')}</h4>
          <h1>
            <Trans
              i18nKey='marketingPageHero.title' // the key in your JSON file
              ns='common'
              components={[<span></span>]}
            />
          </h1>
        </div>
        <div className='marketing-hero-cards-container'>
          <h4>{t('marketingPageHero.cardsTitle')}</h4>
          <div className='marketing-hero-cards'>
            {Object.values(insuranceField).map(field => (
              <div key={field.id} className='marketing-hero-card'>
                <div className='marketing-hero-card-ico'>
                  <img src={field.activeIconPath} alt={field.nameEn} />
                </div>
                <div className='marketing-hero-card-content'>
                  <h5 className='marketing-hero-card-field'>{hebrew() ? field.name : field.nameEn}</h5>
                  <p className='marketing-hero-card-categories'>
                    {field.insuranceCategories
                      .map(c => (hebrew() ? insuranceCategory[c].name : insuranceCategory[c].nameEn))
                      .join(', ')}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* <div className='marketing-hero-remark'>
          <p>
            <Trans
              i18nKey='marketingPageHero.remark' // the key in your JSON file
              ns='common'
              components={[<span></span>]}
            />
          </p>
        </div> */}
      </section>
    </div>
  )
}

export default MarketingHero
