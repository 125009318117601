import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getUserNameInitials } from '../../../../services/getUserNameInitials'
import AgenciesGroupsSidebar from '../AgenciesGroups/AgenciesGroupsSidebar'
import './SidebarAgentHouseAdditional.scss'

const SidebarAgentHouseAdditional = () => {
  const { UserName } = useSelector(({ login }) => login)

  return (
    <div className='sidebar-agent-house-additional'>
      <div>
        <NavLink to={'/profile'}>
          <span>{getUserNameInitials(UserName)}</span>
        </NavLink>
      </div>
      <AgenciesGroupsSidebar />
    </div>
  )
}

export default SidebarAgentHouseAdditional
