import LandingHeader from '../../features/landing/components/LandingHeader/LandingHeader'
import LandingFooter from '../../features/landing/components/LandingFooter/LandingFooter'
import LandingContacts from '../../features/landing/components/LandingContacts/LandingContacts'
import PaymentCalc from '../../features/registration/components/PaymentCalc/PaymentCalc'
import './PricesCalc.scss'

const PricesCalc = () => {
  return (
    <div className='prices-calc-page-container' id='pricesCalc'>
      <LandingHeader />
      <div className='prices-calc-page-container-calc-wrapper'>
        <PaymentCalc />
      </div>
      <LandingContacts />
      <LandingFooter />
    </div>
  )
}

export default PricesCalc
