import { useState } from 'react'
import { formatAsCurrency, formatAsPercent, formatAsShortDate } from '../../../../utils/formatAs'
import { cn } from '../../../../utils/stylesUtils'
import PolicyCard from '../PolicyCard/PolicyCard'
import PolicyListItemCard from '../PolicyListItemCard/PolicyListItemCard'
import PolicyStatusChip from '../../../../components/Common/PolicyStatusChip/PolicyStatusChip'
import { SyncProblemOutlined } from '@mui/icons-material'
import { entityStatusType } from '../../../../types/entityStatusType'
import EntityStatusChip from '../../../../components/Common/EntityStatusChip/EntityStatusChip'
import { getMonthName } from '../../../../services/getMonthName'
import { useTranslation } from 'react-i18next'
import { hebrew } from '../../../../i18n'
import './PoliciesNewListItem.scss'
import { allPoliciesListType } from '../../../../types/tablesTypes'
import { commissionKeySuffix, deltaCommissionSplitView } from '../../../shared/reducers/sharedSelectors'

const PoliciesNewListItem = ({ policy, classes, selectPolicy, selected, listType }) => {
  const {
    i18n: { language },
  } = useTranslation()
  const [showPolicyCard, setShowPolicyCard] = useState(false)
  {
    /* temporary disabled (task: board - 5570988580 pulse - 7596845841) */
  }
  // const [showPayments, setShowPayments] = useState(false)
  const elementaryListType = listType === allPoliciesListType.elementary
  const keySuffix = commissionKeySuffix()

  return (
    <>
      <PolicyListItemCard
        classes={cn(classes, 'policies-new-list-item', {
          outdated: policy.isOutdated,
        })}
        onClick={() => setShowPolicyCard(true)}
        selectable
        onSelect={() => selectPolicy(policy.id)}
        selected={selected}
      >
        <>
          {/* temporary disabled (task: board - 5570988580 pulse - 7596845841) */}
          {/* <div>
            <button
              onClick={e => {
                e.stopPropagation()
                setShowPayments(prev => !prev)
              }}
            >
              <img src={`./assets/${showPayments ? 'minus-icon/Close' : 'plus-icon/Add'}.png`} alt='more' />
            </button>
          </div> */}
          <div>{policy.policyNumber ?? '--'}</div>
          <div>{policy.customerName ?? '--'}</div>
          <div>{policy.customerIdNumber ?? '--'}</div>
          <div>{policy.company ?? '--'}</div>
          <div>{policy.category[`name${hebrew() ? '' : 'En'}`] ?? '--'}</div>
          <div>{formatAsShortDate(policy.startDate) ?? '--'}</div>
          {elementaryListType && <div>{formatAsShortDate(policy.endDate) ?? '--'}</div>}
          <div>{policy.policyAge ?? '--'}</div>
          {deltaCommissionSplitView() && (
            <div className='financial-value-cell'>
              {formatAsCurrency(policy['commissionAgency'], { dashesForZero: false })}
            </div>
          )}
          <div className='financial-value-cell'>
            {formatAsCurrency(policy['commission' + keySuffix], { dashesForZero: false })}
          </div>{' '}
          <div className='financial-value-cell'>{formatAsCurrency(policy.premium, { dashesForZero: false })}</div>
          {!elementaryListType && (
            <>
              <div className='financial-value-cell'>
                {formatAsCurrency(policy.accumulation, { dashesForZero: false })}
              </div>
              {deltaCommissionSplitView() && (
                <div className='commission-rate-cells'>
                  <div className='financial-value-cell'>
                    {formatAsPercent(policy['commissionRatePremiumAgency'], { dashesForZero: false })}
                  </div>
                  <div className='financial-value-cell'>
                    {formatAsPercent(policy['commissionRateAccumulationAgency'], {
                      maximumFractionDigits: 4,
                      dashesForZero: false,
                    })}
                  </div>
                </div>
              )}
              <div className='commission-rate-cells'>
                <div className='financial-value-cell'>
                  {formatAsPercent(policy['commissionRatePremium' + keySuffix], { dashesForZero: false })}
                </div>
                <div className='financial-value-cell'>
                  {formatAsPercent(policy['commissionRateAccumulation' + keySuffix], {
                    maximumFractionDigits: 4,
                    dashesForZero: false,
                  })}
                </div>
              </div>
            </>
          )}
          {elementaryListType && (
            <>
              {deltaCommissionSplitView() && (
                <div className='financial-value-cell'>
                  {formatAsCurrency(policy['commissionMonthlyAgency'], { dashesForZero: false })}
                </div>
              )}
              <div className='financial-value-cell'>
                {formatAsCurrency(policy['commissionMonthly' + keySuffix], { dashesForZero: false })}
              </div>
              <div className='financial-value-cell'>
                {formatAsCurrency(policy.premiumMonthly, { dashesForZero: false })}
              </div>
              {deltaCommissionSplitView() && (
                <div className='financial-value-cell'>
                  {formatAsPercent(policy['commissionRateAgency'], { dashesForZero: false })}
                </div>
              )}
              <div className='financial-value-cell'>
                {formatAsPercent(policy['commissionRate' + keySuffix], { dashesForZero: false })}
              </div>
            </>
          )}
          <div className='policy-badges'>
            <PolicyStatusChip status={policy.status} />
            {!policy.customerIdNumber && <EntityStatusChip status={entityStatusType.lostData} />}
          </div>
          <div className='policies-new-list-item-icons'>
            {policy.isOutdated && <SyncProblemOutlined style={{ color: 'var(--orange-client)' }} />}
          </div>
        </>
        {/* temporary disabled (task: board - 5570988580 pulse - 7596845841) */}
        {/* {showPayments &&
          policy.payments.map((p, i) => (
            <div className={`policies-new-list-grid subgrid ${elementaryListType && 'policies-new-list-grid-elementary'}`} key={p.pullingDate + i}>
              <div></div>
              <div></div>
              <div>
                {getMonthName(p.pullingDate, language)} {new Date(p.pullingDate).getFullYear()}
              </div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              {elementaryListType && <div></div>}
              <div className='financial-value-cell'>{formatAsCurrency(p.commission, { dashesForZero: false })}</div>
              <div className='financial-value-cell'>{formatAsCurrency(p.premium, { dashesForZero: false })}</div>
              {!elementaryListType && (
                <>
                <div className='financial-value-cell'>{formatAsCurrency(p.accumulation, { dashesForZero: false })}</div>
              <div className='financial-value-cell'>
                {formatAsPercent(p.commissionRatePremium, { dashesForZero: false })}
              </div>
              <div className='financial-value-cell'>
                {formatAsPercent(p.commissionRateAccumulation, { maximumFractionDigits: 4, dashesForZero: false })}
              </div>
              </>)}
              {elementaryListType && (<>
                <div className='financial-value-cell'>
                {formatAsCurrency(p.commissionMonthly, { dashesForZero: false })}
              </div>
              <div className='financial-value-cell'>
                {formatAsCurrency(p.premiumMonthly, { dashesForZero: false })}
              </div>
              <div className='financial-value-cell'>
                {formatAsPercent(p.commissionRate, { dashesForZero: false })}
              </div>
              </>)}
              
              <div className='policy-badges'></div>
              <div className='policies-new-list-item-icons'></div>
            </div>
          ))} */}
      </PolicyListItemCard>
      {showPolicyCard && <PolicyCard closeDetails={() => setShowPolicyCard(false)} id={policy.id} />}
    </>
  )
}

export default PoliciesNewListItem
