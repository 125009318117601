import classNames from 'classnames'
import './MeatBalls.scss'

const MeatBalls = ({ showBackground = true, onClick, opened, size = 'md', meatBallsRef }) => {
  const onClickHandler = e => {
    e.stopPropagation()
    e.preventDefault()
    onClick()
  }
  return (
    <div className={classNames('meatballs-container', { [size]: size })} ref={meatBallsRef}>
      <div className={classNames('meatballs', { opened, transparent: !showBackground })} onClick={onClickHandler}>
        <span className='meatball'></span>
        <span className='meatball'></span>
        <span className='meatball'></span>
      </div>
    </div>
  )
}

export default MeatBalls
