const AddGroupIconSvg = ({ fill = 'var(--text-primary-color)' }) => (
  <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g id='group-add' clipPath='url(#clip0_87_36897)'>
      <path
        id='Vector'
        d='M8.33333 25.0001V20.0001H13.3333V16.6667H8.33333V11.6667H5V16.6667H0V20.0001H5V25.0001H8.33333Z'
        fill={fill}
        fillOpacity='0.6'
      />
      <path
        id='Vector_2'
        opacity='0.3'
        d='M20 16.6667C21.3807 16.6667 22.5 15.5475 22.5 14.1667C22.5 12.786 21.3807 11.6667 20 11.6667C18.6193 11.6667 17.5 12.786 17.5 14.1667C17.5 15.5475 18.6193 16.6667 20 16.6667Z'
        fill={fill}
        fillOpacity='0.6'
      />
      <path
        id='Vector_3'
        opacity='0.3'
        d='M12.2334 28.3333H27.7667C26.3667 27.3667 22.9834 26.25 20.0001 26.25C17.0167 26.25 13.6334 27.3667 12.2334 28.3333Z'
        fill={fill}
        fillOpacity='0.6'
      />
      <path
        id='Vector_4'
        d='M20.0002 19.9999C23.2168 19.9999 25.8335 17.3833 25.8335 14.1666C25.8335 10.9499 23.2168 8.33325 20.0002 8.33325C16.7835 8.33325 14.1668 10.9499 14.1668 14.1666C14.1668 17.3833 16.7835 19.9999 20.0002 19.9999ZM20.0002 11.6666C21.3835 11.6666 22.5002 12.7833 22.5002 14.1666C22.5002 15.5499 21.3835 16.6666 20.0002 16.6666C18.6168 16.6666 17.5002 15.5499 17.5002 14.1666C17.5002 12.7833 18.6168 11.6666 20.0002 11.6666ZM20.0002 22.9166C16.1002 22.9166 8.3335 24.8666 8.3335 28.7499V31.6666H31.6668V28.7499C31.6668 24.8666 23.9002 22.9166 20.0002 22.9166ZM12.2335 28.3333C13.6335 27.3666 17.0168 26.2499 20.0002 26.2499C22.9835 26.2499 26.3668 27.3666 27.7668 28.3333H12.2335ZM28.3335 19.9999C31.5502 19.9999 34.1668 17.3833 34.1668 14.1666C34.1668 10.9499 31.5502 8.33325 28.3335 8.33325C27.9335 8.33325 27.5335 8.36659 27.1502 8.44992C28.4168 10.0166 29.1668 11.9999 29.1668 14.1666C29.1668 16.3333 28.3835 18.2999 27.1168 19.8666C27.5168 19.9499 27.9168 19.9999 28.3335 19.9999ZM32.2002 23.3666C33.8668 24.7166 35.0002 26.4833 35.0002 28.7499V31.6666H40.0002V28.7499C40.0002 25.9333 35.9335 24.1499 32.2002 23.3666Z'
        fill={fill}
        fillOpacity='0.6'
      />
    </g>
    <defs>
      <clipPath id='clip0_87_36897'>
        <rect width='40' height='40' fill={fill} />
      </clipPath>
    </defs>
  </svg>
)

export default AddGroupIconSvg
