import $api from '..'
import { urlCompany, urlCompanyUsers, urlMaster } from '../../environment/urls'

export const updateCompanyCredentialsRequest = async companyUpdateRequest =>
  await $api.post(`${urlCompany()}update-credentials`, companyUpdateRequest)

export const getCompanyUsersRequest = async companyId => await $api.get(`${urlCompanyUsers()}by-company/${companyId}`)
export const getCompanyFieldsRequest = async companyId =>
  await $api.get(`${urlMaster()}company/insurance-fields-by-company/${companyId}`)
export const createCompanyUserRequest = async user => await $api.post(`${urlCompanyUsers()}`, user)
export const updateCompanyUserRequest = async user => await $api.put(`${urlCompanyUsers()}`, user)
export const deleteCompanyUserRequest = async id => await $api.delete(`${urlCompanyUsers()}${id}`)
