import { useTranslation } from 'react-i18next'
import Loader from '../../../../components/Common/Loader/Loader'
import NoData from '../../../../components/Common/NoData/NoData'
import LinedTitle from '../../../../components/Common/LinedTitle/LinedTitle'
import ButtonRounded from '../../../../components/Common/ButtonRounded/ButtonRounded'
import PolicyListItemCard from '../PolicyListItemCard/PolicyListItemCard'
import PoliciesSignalsListItem from './PoliciesSignalsListItem'
import { cn } from '../../../../utils/stylesUtils'
import { formatAsCurrency } from '../../../../utils/formatAs'
import './PoliciesSignalsList.scss'
import {
  commissionViewTranslationPrefix as commissionPrefix,
  commissionKeySuffix,
  deltaCommissionSplitView,
} from '../../../shared/reducers/sharedSelectors'
import SortableColumn from '../../../../components/SortableColumn/SortableColumn'
import { sortBy } from '../../../../types/sortByTypes'
import { POLICIES_SET_SORT_BY } from '../../../../types/actionTypesPolicies'
import { useDispatch, useSelector } from 'react-redux'

export const PoliciesSignalsList = ({
  list,
  loading,
  collapsed,
  onCollapse,
  title,
  showDetails,
  selectIrreg,
  selectedIrregs,
}) => {
  const { t } = useTranslation('policies')
  const { sort } = useSelector(({ policies }) => policies)
  const dispatch = useDispatch()
  const setSortByHandler = sortBy => dispatch({ type: POLICIES_SET_SORT_BY, payload: { sortBy, listKey: 'Signals' } })

  return (
    <div className='policies-signals-list-wrapper'>
      {title && (
        <LinedTitle
          title={title}
          collapsible
          onCollapse={onCollapse}
          collapsed={collapsed}
          count={t('placeholders.signalsCount', { count: list?.totalCount ?? '--' })}
        />
      )}
      {(!title || (title && !collapsed)) && (
        <>
          {loading ? <Loader /> : list?.items?.length === 0 && <NoData />}
          {!loading && list?.items?.length > 0 && (
            <>
              <div
                className={cn('policies-signals-list-header policies-signals-list-grid', {
                  extended: deltaCommissionSplitView(),
                })}
              >
                <div></div>
                <SortableColumn
                  title={t('list.heading.irregMonth')}
                  columnSortBy={sortBy.month}
                  columnSortOrder={sort.orderSignals}
                  currentSortBy={sort.bySignals}
                  setSort={setSortByHandler}
                />
                <SortableColumn
                  title={t('list.heading.irregTotalCount')}
                  columnSortBy={sortBy.monthlyIrregularitiesCount}
                  columnSortOrder={sort.orderSignals}
                  currentSortBy={sort.bySignals}
                  setSort={setSortByHandler}
                />
                <SortableColumn
                  title={t('list.heading.policyNumber')}
                  columnSortBy={sortBy.policyNumber}
                  columnSortOrder={sort.orderSignals}
                  currentSortBy={sort.bySignals}
                  setSort={setSortByHandler}
                  textSort
                />
                <SortableColumn
                  title={t('list.heading.customerName')}
                  columnSortBy={sortBy.customerName}
                  columnSortOrder={sort.orderSignals}
                  currentSortBy={sort.bySignals}
                  setSort={setSortByHandler}
                  textSort
                />
                <SortableColumn
                  title={t('list.heading.customerIdNumber')}
                  columnSortBy={sortBy.customerIdNumber}
                  columnSortOrder={sort.orderSignals}
                  currentSortBy={sort.bySignals}
                  setSort={setSortByHandler}
                  textSort
                />
                <SortableColumn
                  title={t('list.heading.company')}
                  columnSortBy={sortBy.company}
                  columnSortOrder={sort.orderSignals}
                  currentSortBy={sort.bySignals}
                  setSort={setSortByHandler}
                  textSort
                />
                <SortableColumn
                  title={t('list.heading.category')}
                  columnSortBy={sortBy.category}
                  columnSortOrder={sort.orderSignals}
                  currentSortBy={sort.bySignals}
                  setSort={setSortByHandler}
                  textSort
                />
                <SortableColumn
                  title={t('list.heading.policyAge')}
                  columnSortBy={sortBy.policyAge}
                  columnSortOrder={sort.orderSignals}
                  currentSortBy={sort.bySignals}
                  setSort={setSortByHandler}
                />
                {deltaCommissionSplitView() && (
                  <SortableColumn
                    title={t(`list.heading.commission`) + ' ' + t('agencyPrefix')}
                    columnSortBy={sortBy.commissionAgency}
                    columnSortOrder={sort.orderSignals}
                    currentSortBy={sort.bySignals}
                    setSort={setSortByHandler}
                  />
                )}
                <SortableColumn
                  title={t(`list.heading.commission`) + commissionPrefix(t)}
                  columnSortBy={sortBy['commission' + commissionKeySuffix()]}
                  columnSortOrder={sort.orderSignals}
                  currentSortBy={sort.bySignals}
                  setSort={setSortByHandler}
                />
                <SortableColumn
                  title={t(`list.heading.premium`)}
                  columnSortBy={sortBy.premium}
                  columnSortOrder={sort.orderSignals}
                  currentSortBy={sort.bySignals}
                  setSort={setSortByHandler}
                />
                <SortableColumn
                  title={t(`list.heading.accumulation`)}
                  columnSortBy={sortBy.accumulation}
                  columnSortOrder={sort.orderSignals}
                  currentSortBy={sort.bySignals}
                  setSort={setSortByHandler}
                />
                {deltaCommissionSplitView() && (
                  <div>
                    <div>
                      {t('list.heading.cRate')}
                      {' ' + t('agencyPrefix')}
                    </div>
                    <div className='commission-rate-cells'>
                      <SortableColumn
                        title={t('list.heading.premium')}
                        columnSortBy={sortBy.commissionRatePremiumAgency}
                        columnSortOrder={sort.orderSignals}
                        currentSortBy={sort.bySignals}
                        setSort={setSortByHandler}
                      />
                      <SortableColumn
                        title={t('list.heading.accumulation')}
                        columnSortBy={sortBy.commissionRateAccumulationAgency}
                        columnSortOrder={sort.orderSignals}
                        currentSortBy={sort.bySignals}
                        setSort={setSortByHandler}
                      />
                    </div>
                  </div>
                )}
                <div>
                  <div>
                    {t('list.heading.cRate')}
                    {commissionPrefix(t)}
                  </div>
                  <div className='commission-rate-cells'>
                    <SortableColumn
                      title={t('list.heading.premium')}
                      columnSortBy={sortBy['commissionRatePremium' + commissionKeySuffix()]}
                      columnSortOrder={sort.orderSignals}
                      currentSortBy={sort.bySignals}
                      setSort={setSortByHandler}
                    />
                    <SortableColumn
                      title={t('list.heading.accumulation')}
                      columnSortBy={sortBy['commissionRateAccumulation' + commissionKeySuffix()]}
                      columnSortOrder={sort.orderSignals}
                      currentSortBy={sort.bySignals}
                      setSort={setSortByHandler}
                    />
                  </div>
                </div>
                <div></div>
              </div>
              <div className='policies-signals-list'>
                {list.items.map(s => (
                  <PoliciesSignalsListItem
                    key={s.irregularityId}
                    signal={s}
                    classes={'policies-signals-list-grid ' + (deltaCommissionSplitView() ? ' extended' : '')}
                    selectIrreg={selectIrreg}
                    selected={selectedIrregs.includes(s.irregularityId)}
                  />
                ))}
              </div>
              <PolicyListItemCard
                classes={
                  'policies-signals-list-grid policies-signals-list-total ' +
                  (deltaCommissionSplitView() ? ' extended' : '')
                }
              >
                <div>{t('list.heading.listTotal')}</div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                {deltaCommissionSplitView() && (
                  <div className='financial-value-cell'>
                    {formatAsCurrency(list['totalCommissionAgency'], { dashesForZero: false })}
                  </div>
                )}
                <div className='financial-value-cell'>
                  {formatAsCurrency(list['totalCommission' + commissionKeySuffix()], { dashesForZero: false })}
                </div>
                <div className='financial-value-cell'>
                  {formatAsCurrency(list.totalPremium, { dashesForZero: false })}
                </div>
                <div className='financial-value-cell'>
                  {formatAsCurrency(list.totalAccumulation, { dashesForZero: false })}
                </div>{' '}
                <div></div>
                <div></div>
                <div></div>
              </PolicyListItemCard>
            </>
          )}
          {title && !loading && (
            <div className='policies-signals-list-footer'>
              <ButtonRounded onClick={showDetails}>{t('placeholders.showMoreBtn')}</ButtonRounded>
            </div>
          )}
        </>
      )}
    </div>
  )
}
