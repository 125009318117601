import React from 'react'
import { useTranslation } from 'react-i18next'
import './LandingBanner.scss'

const LandingBanner = () => {
  const { t } = useTranslation('landing')
  return (
    <section
      className='landing-banner'
      id='banner'
      style={{
        backgroundImage: `url('assets/_landing/_banner/bg.png'), url('assets/_landing/_banner/image.png')`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat, no-repeat',
        backgroundSize: 'cover, auto',
      }}
    >
      <div
        className='landing-section-container'
        style={{
          backgroundImage: `url('assets/_landing/_banner/mobile-landing-banner.png')`,
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat, no-repeat',
        }}
      >
        <h5 className='landing-banner-title'>{t('banner.title')}</h5>
        <p className='landing-banner-subtitle'>{t('banner.subTitle')}</p>
        <p className='landing-banner-remark'>{t('banner.remark')}</p>
        <div className='landing-btns-container'>
          <a href='#/register'>{t('banner.link')}</a>
        </div>
      </div>
    </section>
  )
}

export default LandingBanner
