import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import classNames from 'classnames'
import './InputRadio.css'

const InputRadio = ({ values, selectedValue, handleChange, row = true, disabled = false }) => {
  return (
    <div className={classNames('input-radio-container', { disabled })}>
      <FormControl>
        <RadioGroup
          aria-label='gender'
          name='controlled-radio-buttons-group'
          value={selectedValue}
          onChange={handleChange}
          row={row}
          aria-disabled={disabled}
        >
          {values.map(val => (
            <FormControlLabel
              value={val.value}
              control={<Radio />}
              label={val.text}
              key={val.id ?? val.value}
              disabled={val.disabled}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </div>
  )
}

export default InputRadio
