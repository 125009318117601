import classNames from 'classnames'
import { createRef, useCallback, useEffect, useState } from 'react'
import ModalWindow from '../ModalWindow/ModalWindow'
import Loader from '../Common/Loader/Loader'
import { getPolicyTermsRequest } from '../../http/requests/privacyPolicyRequests'
import LoadingOverlay from '../Common/LoadingOverlay/LoadingOverlay'
import NoData from '../Common/NoData/NoData'
import parse from 'html-react-parser'
import './ModalAgreement.scss'

export const agreement = { terms: 'terms', policy: 'policy' }

const ModalAgreement = ({
  closeModal,
  confirmAgreement = null,
  agreementConfirmed = false,
  currentAgreementType = agreement.terms,
  modalLoading = false,
}) => {
  const [currentAgreement, setCurrentAgreement] = useState(currentAgreementType)
  const [bottom, setBottom] = useState(agreementConfirmed)
  const [privacy, setPrivacy] = useState('')
  const [terms, setTerms] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const termsAnchorRef = createRef(null)
  const privacyAnchorRef = createRef(null)

  const handleScroll = e => {
    const privacyElementPosition = privacyAnchorRef.current.getBoundingClientRect()
    if (privacyElementPosition) {
      setCurrentAgreement(privacyElementPosition.y <= 203 ? agreement.policy : agreement.terms)
    }
    if (!bottom) {
      const scrolledBottom = e.target.scrollHeight - e.target.scrollTop - 10 <= e.target.clientHeight
      setBottom(scrolledBottom)
    }
  }

  const changeCurrentTerms = useCallback(
    element => {
      setCurrentAgreement(element)
      if (element === agreement.policy) {
        privacyAnchorRef.current?.scrollIntoView({ behavior: 'auto' })
      } else if (element === agreement.terms) {
        termsAnchorRef.current?.scrollIntoView({ behavior: 'auto' })
      }
    },
    [privacyAnchorRef, termsAnchorRef]
  )

  useEffect(() => {
    if (privacy && terms && !loading) {
      changeCurrentTerms(currentAgreement)
    }
  }, [privacy, terms, loading, currentAgreement, changeCurrentTerms])

  useEffect(() => {
    const fetchPrivacyTerms = async () => {
      try {
        const {
          data: { Data },
        } = await getPolicyTermsRequest()
        setPrivacy(Data.AgreementPolicy.Content)
        setTerms(Data.TermsAgreement.Content)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        setError('Server error')
      }
    }
    fetchPrivacyTerms()
  }, [])

  return (
    <ModalWindow closeModal={closeModal} showCloseIcon={false} closeOnOutsideClick={false} renderPortal>
      {modalLoading && <LoadingOverlay />}
      <div className='modal-register-agreement'>
        <div className='modal-register-agreement-header'>
          <h4>תקנון</h4>
          <button onClick={closeModal} disabled={loading}>
            <img src='./assets/cross-icon/cross.png' alt='x' />
          </button>
        </div>
        {loading ? (
          <Loader />
        ) : error ? (
          <NoData text={error} />
        ) : (
          <div className='modal-register-agreement-content'>
            <div className='modal-register-agreement-list'>
              <ul>
                <li
                  className={classNames({ 'active-agreement': currentAgreement === agreement.terms })}
                  onClick={() => changeCurrentTerms(agreement.terms)}
                >
                  תנאי שימוש
                </li>
                <li
                  className={classNames({ 'active-agreement': currentAgreement === agreement.policy })}
                  onClick={() => changeCurrentTerms(agreement.policy)}
                >
                  פרטיות
                </li>
              </ul>
            </div>
            <pre className='modal-register-agreement-text' onScroll={handleScroll}>
              <span id={'termsAnchorRef'} ref={termsAnchorRef}>
                {terms && parse(terms)}
              </span>
              <span id={'privacyAnchorRef'} ref={privacyAnchorRef}>
                {privacy && parse(privacy)}
              </span>
            </pre>
          </div>
        )}
        {confirmAgreement && (
          <div className='modal-register-agreement-footer'>
            <button onClick={confirmAgreement} disabled={!bottom || loading}>
              קראתי ואישרתי את תנאי השימוש
            </button>
          </div>
        )}
      </div>
    </ModalWindow>
  )
}

export default ModalAgreement
