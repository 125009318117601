import { policyRating } from '../../../../types/policyRatingTypes'
import Chip from '../../../../components/Common/Chip/Chip'
import classNames from 'classnames'
import { useState } from 'react'
import CustomerDetailsInput from './CustomerDetailsInput'
import { infoDetails } from '../../../../types/infoDetailsTypes'
import { hebrew } from '../../../../i18n'
import CustomerActions from '../CustomerActions/CustomerActions'
import './CustomerDetailsGeneral.scss'
import { ContentCopy } from '@mui/icons-material'

const CustomerDetailsGeneral = ({ name, updateName, detailsType, idNumber, rating, active = true }) => {
  const [showInput, setShowInput] = useState(false)

  const ratingObj = policyRating.find(obj => obj.key === rating)
  return (
    <div className='customer-details-general'>
      <div>
        <div>{ratingObj && <Chip content={ratingObj.value} color={ratingObj.color} />}</div>
        <div>
          <CustomerActions />
        </div>
      </div>
      <div className={classNames('customer-details-personal', { active })}>
        <div className='customer-details-name'>
          {showInput ? (
            <CustomerDetailsInput
              title={hebrew() ? infoDetails[detailsType].value : infoDetails[detailsType].valueEn}
              detailsType={detailsType}
              closeInput={() => setShowInput(false)}
              applyChanges={updateName}
              initValue={name}
            />
          ) : (
            <>
              <p>{name}</p>
              <button onClick={() => setShowInput(true)}>
                <img src='./assets/edit-icon/1.png' alt='' className='img-icon' />
              </button>
            </>
          )}
        </div>
        <div className='customer-details-id-number'>
          <p>{idNumber}</p>
          <button onClick={() => navigator.clipboard.writeText(idNumber)}>
            <ContentCopy />
          </button>
        </div>
      </div>
    </div>
  )
}

export default CustomerDetailsGeneral
