import { useState } from 'react'
import { formatAsCurrency, formatAsPercent } from '../../../../utils/formatAs'
import { cn } from '../../../../utils/stylesUtils'
import PolicyCard from '../PolicyCard/PolicyCard'
import PolicyListItemCard from '../PolicyListItemCard/PolicyListItemCard'
import PolicyStatusChip from '../../../../components/Common/PolicyStatusChip/PolicyStatusChip'
import { policyStatusType } from '../../../../types/policyStatusType'
import EntityStatusChip from '../../../../components/Common/EntityStatusChip/EntityStatusChip'
import { entityStatusType } from '../../../../types/entityStatusType'
import { hebrew } from '../../../../i18n'
import { getYearAndMonth } from '../../../../services/getMonthName'
import { useTranslation } from 'react-i18next'
import './PoliciesSignalsListItem.scss'
import { commissionKeySuffix, deltaCommissionSplitView } from '../../../shared/reducers/sharedSelectors'

const PoliciesSignalsListItem = ({ signal, classes, selectIrreg, selected }) => {
  const {
    i18n: { language },
  } = useTranslation()
  const [showPolicyCard, setShowPolicyCard] = useState(false)
  const keySuffix = commissionKeySuffix()

  return (
    <>
      <PolicyListItemCard
        classes={cn(classes, 'policies-signals-list-item')}
        onClick={() => setShowPolicyCard(true)}
        selectable
        onSelect={() => selectIrreg(signal.irregularityId)}
        selected={selected}
      >
        <div>{getYearAndMonth(signal.month, { language })}</div>
        <div>{signal.monthlyIrregularitiesCount ?? '--'}</div>
        <div>{signal.policyNumber ?? '--'}</div>
        <div>{signal.customerName ?? '--'}</div>
        <div>{signal.customerIdNumber ?? '--'}</div>
        <div>{signal[`companyName${hebrew() ? '' : 'En'}`] ?? '--'}</div>
        <div>{signal[`categoryName${hebrew() ? '' : 'En'}`] ?? '--'}</div>
        <div>{signal.policyAge ?? '--'}</div>
        {deltaCommissionSplitView() && (
          <div className='financial-value-cell'>
            {formatAsCurrency(signal['commissionAgency'], { dashesForZero: false })}
          </div>
        )}
        <div className='financial-value-cell'>
          {formatAsCurrency(signal[['commission' + keySuffix]], { dashesForZero: false })}
        </div>
        <div className='financial-value-cell'>{formatAsCurrency(signal.premium, { dashesForZero: false })}</div>
        <div className='financial-value-cell'>{formatAsCurrency(signal.accumulation, { dashesForZero: false })}</div>
        {deltaCommissionSplitView() && (
          <div className='commission-rate-cells'>
            <div className='financial-value-cell'>
              {formatAsPercent(signal['commissionRatePremiumAgency'], { dashesForZero: false })}
            </div>
            <div className='financial-value-cell'>
              {formatAsPercent(signal['commissionRateAccumulationAgency'], {
                maximumFractionDigits: 4,
                dashesForZero: false,
              })}
            </div>
          </div>
        )}
        <div className='commission-rate-cells'>
          <div className='financial-value-cell'>
            {formatAsPercent(signal['commissionRatePremium' + keySuffix], { dashesForZero: false })}
          </div>
          <div className='financial-value-cell'>
            {formatAsPercent(signal['commissionRateAccumulation' + keySuffix], {
              maximumFractionDigits: 4,
              dashesForZero: false,
            })}
          </div>
        </div>
        <div className='signal-badges'>
          <PolicyStatusChip status={policyStatusType.irregular} />
          {!signal.customerIdNumber && <EntityStatusChip status={entityStatusType.lostData} />}
        </div>
      </PolicyListItemCard>
      {showPolicyCard && <PolicyCard closeDetails={() => setShowPolicyCard(false)} id={signal.policyId} />}
    </>
  )
}

export default PoliciesSignalsListItem
