import { useEffect } from 'react'
import { useSelector } from 'react-redux'

const applyTheme = theme => {
  document.body.setAttribute('data-theme', theme)
}

function ThemeProvider({ children }) {
  const { currentTheme } = useSelector(state => state.theme)

  useEffect(() => {
    applyTheme(currentTheme)
  }, [currentTheme])

  return <>{children}</>
}

export default ThemeProvider
