export const infoDetailsType = {
  customerName: 'customerName',
  address: 'address',
  phone: 'phone',
  email: 'email',
  group: 'group',
  groupName: 'groupName',
  gender: 'gender',
  birthDate: 'birthDate',
}

export const infoDetails = {
  [infoDetailsType.customerName]: {
    value: 'שם לקוח',
    valueEn: 'Customer Name',
  },
  [infoDetailsType.address]: {
    value: 'כתובת',
    valueEn: 'Address',
    placeHolder: 'הוסף כתובת מגורים',
    placeHolderEn: 'Add client address',
    iconPath: './assets/person-location/Location.png',
  },
  [infoDetailsType.phone]: {
    value: 'טלפון',
    valueEn: 'Phone',
    placeHolder: 'הוסף טלפון נייד',
    placeHolderEn: 'Add client phone',
    iconPath: './assets/person-phone/call.png',
  },
  [infoDetailsType.email]: {
    value: 'אי־מייל',
    valueEn: 'Email',
    placeHolder: 'הוסף כתובת אי־מייל',
    placeHolderEn: 'Add client email',
    iconPath: './assets/person-phone/call.png',
  },
  [infoDetailsType.group]: {
    value: 'תא משפחתי',
    valueEn: 'Family',
    placeHolder: 'Add family memeber',
    placeHolderEn: 'Add family memeber',
    iconPath: './assets/person-family/Category.png',
  },
  [infoDetailsType.groupName]: {
    value: 'תא משפחתי',
    valueEn: 'Family',
    placeHolder: 'Add family memeber',
    placeHolderEn: 'Add family memeber',
    iconPath: './assets/person-family/Category.png',
  },
  [infoDetailsType.birthDate]: {
    value: 'תאריך לידה',
    valueEn: 'Date of Birth',
    placeHolder: 'להוסיף תאריך לידה',
    placeHolderEn: 'Add date of birth',
    iconPath: './assets/person-family/Category.png',
  },
  [infoDetailsType.gender]: {
    value: 'מין',
    valueEn: 'Gender',
    placeHolder: 'להוסיף סקס',
    placeHolderEn: 'Add gender',
    iconPath: './assets/person-family/Category.png',
  },
}
