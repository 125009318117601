import classNames from 'classnames'
import './SegmentedPicker.scss'

const SegmentedPicker = ({ items = [], selectItem }) => {
  if (items.length === 0) {
    return null
  }
  return (
    <div className='segmented-picker'>
      {items.map(({ id, value, selected }) => (
        <button key={id} onClick={() => selectItem(id)} className={classNames({ selected: selected })}>
          {value}
        </button>
      ))}
    </div>
  )
}

export default SegmentedPicker
