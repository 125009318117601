// import { useEffect, useLayoutEffect, useRef } from 'react'

// const useFixedPositionRef = ({ top = 20, left = 20 } = {}) => {
//   const parentRef = useRef(null)
//   const childRef = useRef(null)

//   useEffect(
//     () => {
//       const parent = parentRef.current
//       const child = childRef.current
//       debugger
//       if (parent && child) {
//         const parentRect = parent.getBoundingClientRect()
//         console.log(parentRect)

//         child.style.position = 'fixed'
//         child.style.top = `${parentRect.top}px` // Adjust as needed
//         child.style.left = `${parentRect.left}px` // Adjust as needed
//       }
//     }
//     //  , [top, left]
//   )
//   return [parentRef, childRef]
// }

// export default useFixedPositionRef

import { useEffect, useRef } from 'react'

const useFixedPositionRef = ({ initialPosition = { top: 0, left: 0 }, offsetTop = 0 } = {}) => {
  const parentRef = useRef(null)
  const ref = useRef(null)

  useEffect(() => {
    const updatePosition = () => {
      if (ref.current && parentRef.current) {
        const parentRect = parentRef.current.getBoundingClientRect()
        const { top, left } = initialPosition
        const childRect = ref.current.getBoundingClientRect()

        // Set the element's position relative to its parent's top-center
        ref.current.style.position = 'fixed'
        ref.current.style.top = `${parentRect.top}px`
        ref.current.style.left = `${parentRect.left + parentRect.width / 2 - childRect.width / 2}px`
        ref.current.style.transform = `translate(0, calc(-100% - ${offsetTop}px))`
        ref.current.style.pointerEvents = `all`
      }
    }

    // Update the position when component mounts and on scroll/resize
    updatePosition()
    window.addEventListener('scroll', updatePosition)
    window.addEventListener('resize', updatePosition)

    return () => {
      window.removeEventListener('scroll', updatePosition)
      window.removeEventListener('resize', updatePosition)
    }
  }, [initialPosition, offsetTop])

  return [parentRef, ref]
}

export default useFixedPositionRef
