import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { registerAgency } from '../../../../http/requests'
import { numbersOnlyEventHandler } from '../../../../services/inputs'
import { saveLoginInfo } from '../../../../services/saveLoginInfo'
import { login } from '../../../login/actions/loginActions'
import { twoFactorOption } from '../../../../types/twoFactorOptionTypes'
import { buildFullPhoneNumber } from '../../../../utils/buildFullPhoneNumber'
import ArrowRightIconSvg from '../../../../components/Common/SvgIcons/ArrowRightIconSvg/ArrowRightIconSvg'
import ButtonRounded from '../../../../components/Common/ButtonRounded/ButtonRounded'
import './StepDetails.scss'

const StepDetails = ({ nextStep, prevStep, data, setData }) => {
  const { t } = useTranslation('registration')

  const { agencyId, agencyName, agentEmail, customerId, customerName, phoneNumber, option } = data
  const [numbersCount, setNumbersCount] = useState(customerId.length)
  const [serverError, setServerError] = useState(null)
  const [emailError, setEmailError] = useState(null)
  const [loading, setLoading] = useState(false)

  const prevSelectedOptionPhone = option === twoFactorOption.byPhone

  const dispatch = useDispatch()

  const submitHandler = async e => {
    e.preventDefault()
    if (!isValidEmail(agentEmail) && prevSelectedOptionPhone) {
      setEmailError('Incorrect Email Address')
      return
    }
    const agencyDto = {
      ...data,
      phoneNumber: buildFullPhoneNumber(phoneNumber),
      idNumber: customerId,
      customerName,
      agencyName,
      agencyIdNumber: agencyId,
      agentEmail,
    }
    setLoading(true)
    clearServerError()
    try {
      const { data: response } = await registerAgency(agencyDto)
      if (response.HasError) {
        setServerError(response.ErrorMessage)
        response.ErrorMessage.toLowerCase().includes('email') && setEmailError(response.ErrorMessage)
      } else {
        saveLoginInfo({ ...response.Data, AgreementConfirmed: true })
        dispatch(login())
        nextStep()
      }
    } catch (error) {
      setServerError('Server Error')
    }
    setLoading(false)
  }

  const clearServerError = () => {
    if (serverError) setServerError(null)
  }

  const onChangeHandler = e => {
    const { name, value } = e.target
    clearServerError()
    if ((name === 'agentEmail' || name === 'phoneNumber') && emailError) setEmailError(null)
    setData(prev => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleInputNumbersCount = e => {
    if (e.target.value.length !== numbersCount) {
      setNumbersCount(e.target.value.length)
    }
  }

  const stepBackHandler = () => {
    prevStep()
  }

  const formIsValid = () => {
    return (
      customerId?.length > 0 &&
      customerName?.length > 0 &&
      agencyName?.length > 0 &&
      agencyId?.length > 0 &&
      agentEmail?.length > 0 &&
      phoneNumber?.length > 0
    )
  }

  const isValidEmail = email => email.match('[a-z0-9]+@[a-z]+.[a-z]{2,3}')

  const submitBtnDisabled = () => loading || !formIsValid()

  return (
    <div className='register-step step-details'>
      <div className='form'>
        <form action='' id='details-form' className='form-container' autoComplete='off' onSubmit={submitHandler}>
          <h4 className='form-inputs-header'>{t('stepAgentDetails.userDetailsHeading')}</h4>
          <div className='input-container'>
            <input
              autoFocus
              type='text'
              name='customerName'
              id='customerName'
              placeholder=' '
              onChange={onChangeHandler}
              required
              value={customerName ?? ''}
              disabled={loading}
            />
            <label htmlFor='customerName'>{t('stepAgentDetails.fullNamePlacholder')}</label>
            <span className='line'></span>
          </div>
          <div className='input-container'>
            <input
              type='text'
              name='customerId'
              id='customerId'
              placeholder=' '
              onInput={numbersOnlyEventHandler}
              onChangeCapture={handleInputNumbersCount}
              minLength='9'
              maxLength='9'
              onChange={onChangeHandler}
              required
              value={customerId ?? ''}
              pattern='[0-9]{9,9}'
              disabled={loading}
            />
            <label htmlFor='customerId'>{t('stepAgentDetails.idPlacholder')}</label>
            <span className='chars-count'>{numbersCount}/9</span>
            <span className='line'></span>
          </div>

          <h4 className='form-inputs-header'>{t('stepAgentDetails.paymentDetailsHeading')}</h4>
          <div className='input-container'>
            <input
              type='agencyName'
              name='agencyName'
              id='agencyName'
              placeholder=' '
              onChange={onChangeHandler}
              required
              value={agencyName ?? ''}
              disabled={loading}
            />
            <label htmlFor='agencyName'>{t('stepAgentDetails.agencyNamePlaceholder')}</label>
            <span className='line'></span>
          </div>
          <div className='input-container'>
            <input
              type='text'
              name='agencyId'
              id='agencyId'
              onInput={numbersOnlyEventHandler}
              placeholder=' '
              onChange={onChangeHandler}
              required
              value={agencyId ?? ''}
              disabled={loading}
            />
            <label htmlFor='agencyId'>{t('stepAgentDetails.agencyIdPlaceholder')}</label>
            <span className='line'></span>
          </div>
          <div className='input-container'>
            <input
              type={!prevSelectedOptionPhone ? 'tel' : 'email'}
              placeholder=' '
              value={!prevSelectedOptionPhone ? phoneNumber : agentEmail}
              onChange={onChangeHandler}
              name={!prevSelectedOptionPhone ? 'phoneNumber' : 'agentEmail'}
              id={!prevSelectedOptionPhone ? 'phoneNumber' : 'agentEmail'}
              {...(!prevSelectedOptionPhone && { size: 17 })}
              style={{ ...(!prevSelectedOptionPhone && { direction: 'ltr', textAlign: 'end' }) }}
              disabled={loading}
            />
            <label htmlFor={!prevSelectedOptionPhone ? 'phoneNumber' : 'agentEmail'}>
              {t(`stepAgentDetails.agency${prevSelectedOptionPhone ? 'Email' : 'Phone'}Placeholder`)}
            </label>
            <span className='line'></span>
            {emailError && <span className='input-container-error'>{emailError}</span>}
          </div>
          <p>{t('stepAgentHouseDetails.formRemark')} </p>
        </form>
        <div className='form-buttons-container'>
          <button
            className='agent-details-back-btn'
            onClick={e => {
              e.preventDefault()
              stepBackHandler()
            }}
            disabled={loading}
          >
            <div>
              <ArrowRightIconSvg />
            </div>
            <div>{t('stepAgentHouseDetails.backBtn')}</div>
          </button>
          <ButtonRounded typeSubmit disabled={submitBtnDisabled()} onClick={submitHandler}>
            {loading ? t('btnSending') : t('btnNext')}
          </ButtonRounded>
        </div>
      </div>
    </div>
  )
}

export default StepDetails
