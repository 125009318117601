import { useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import { agencyRoutes } from '../../environment/urls'
import ScanSidebar from '../../features/scan/components/ScanSidebar/ScanSidebar'
import ScanTotals from '../../features/scan/components/ScanTotals/ScanTotals'
import ScanCompany from '../../features/scan/components/ScanCompany/ScanCompany'
import './ScanScreen.scss'
import { useDispatch, useSelector } from 'react-redux'
import { getReportRecordsDates, getReportRecordsMonthStatus } from '../../features/scan/actions/scanActions'
import { SCAN_RESET_ALL } from '../../types/actionTypesScan'
import { scanSelectedMonth } from '../../features/scan/reducers/scanSelectors'
import { useLocation } from 'react-router-dom/cjs/react-router-dom'
const ScanScreen = () => {
  const dispatch = useDispatch()
  const { dates } = useSelector(({ scan }) => scan)
  const screenRoute = '/' + agencyRoutes.scan
  const date = useLocation().pathname.split('/')[3];

  useEffect(() => {
    const params = {
      agency: null,
      date: date
    }
    dispatch(getReportRecordsDates(params))
    return () => dispatch({ type: SCAN_RESET_ALL })
  }, [dispatch, date])

  useEffect(() => {
    scanSelectedMonth() && dispatch(getReportRecordsMonthStatus({ month:  scanSelectedMonth() }))
  }, [dates, dispatch])

  return (
    <div className='scan-screen'>
      <ScanSidebar />
      <div className='scan-screen-main'>
        <Switch>
        <Route path={`${screenRoute}/:id/`} component={ScanCompany} />
          <Route path={`${screenRoute}/:id/:date`} component={ScanCompany} />
          <Route path={screenRoute} component={ScanTotals} />
        </Switch>
      </div>
    </div>
  )
}

export default ScanScreen
