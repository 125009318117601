import { createPortal } from 'react-dom'
import './ConfirmDialog.css'

const ConfirmDialog = ({
  confirm,
  confirmText = 'OK',
  decline,
  declineText = 'Cancel',
  title,
  text,
  children,
  renderPortal,
}) => {
  const confirmHandler = () => {
    confirm()
    decline()
  }

  const content = (
    <div className='confirm-dialog-modal-wrapper'>
      <div className='confirm-dialog-modal-container'>
        <div className='confirm-dialog-modal-content'>
          {title && (
            <div className='confirm-dialog-modal-content-title'>
              <div>{title}</div>
            </div>
          )}
          {text && (
            <div className='confirm-dialog-modal-content-text'>
              <div>{text}</div>
            </div>
          )}
          {children}
          <div className='confirm-dialog-modal-content-btns'>
            <button onClick={decline}>{declineText}</button>
            <button onClick={() => confirmHandler()}>{confirmText}</button>
          </div>
        </div>
      </div>
    </div>
  )

  if (renderPortal) {
    return createPortal(content, document.body)
  }

  return content
}

export default ConfirmDialog
