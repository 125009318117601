import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, useHistory } from 'react-router-dom'
import NoAccess from '../components/NoAccess/NoAccess'
import Sidebar from '../components/Sidebar/Sidebar'
import SidebarAgentHouse from '../features/agentHouse/components/SidebarAgentHouse/SidebarAgentHouse'
import classNames from 'classnames'
import HeaderAgentHouse from '../features/agentHouse/components/HeaderAgentHouse/HeaderAgentHouse'
import SidebarAgentHouseAdditional from '../features/agentHouse/components/SidebarAgentHouseAdditional/SidebarAgentHouseAdditional'
import ModalAgreement from '../components/ModalAgreement/ModalAgreement'
import { sharedRoutes } from '../environment/urls'
import UiTransitionWarnModal from '../components/UiTransitionWarnModal/UiTransitionWarnModal'
import { useState } from 'react'
import { agreePolicyTermsRequest } from '../http/requests/privacyPolicyRequests'
import { agreePrivacyTerms } from '../features/login/actions/loginActions'
import { isRegularOrDeltaAgency } from '../features/login/reducers/loginSelectors'

const PrivateRoute = ({
  allowedRoles = [],
  requiredPreferences = [],
  noAccessRedirect,
  component: Component,
  ...rest
}) => {
  const { agencyDnsName, preferences, agreementConfirmed, showUiTransitionWarn } = useSelector(state => state.login)
  const history = useHistory()
  const [agreePrivacyTermsLoading, setAgreePrivacyTermsLoading] = useState(false)
  const dispatch = useDispatch()

  const agreePrivacyTermsHandler = async () => {
    try {
      setAgreePrivacyTermsLoading(true)
      const {
        data: { Data: agree },
      } = await agreePolicyTermsRequest()
      if (agree) {
        dispatch(agreePrivacyTerms())
      }
      setAgreePrivacyTermsLoading(false)
    } catch (error) {
      setAgreePrivacyTermsLoading(false)
    }
  }

  const routeContent = () =>
    requiredPreferences.length === 0 || requiredPreferences.some(p => preferences.includes(p)) ? (
      <>
        {/* {showUiTransitionWarn && <UiTransitionWarnModal />} */}
        {!agreementConfirmed && (
          <ModalAgreement
            closeModal={() => history.push(sharedRoutes.login)}
            confirmAgreement={agreePrivacyTermsHandler}
            modalLoading={agreePrivacyTermsLoading}
          />
        )}
        <Component />
      </>
    ) : noAccessRedirect ? (
      <Redirect to={noAccessRedirect} />
    ) : (
      <NoAccess />
    )

  return (
    <Route {...rest}>
      {localStorage.getItem('AccessToken') !== null &&
      localStorage.getItem('RefreshToken') !== null &&
      agencyDnsName !== '' ? (
        <div className={classNames(`wrapper${!isRegularOrDeltaAgency() ? '-agency-house' : ''}`)}>
          {!isRegularOrDeltaAgency() ? (
            <>
              <SidebarAgentHouse />
              <div className='agency-house-main'>
                <HeaderAgentHouse />
                {routeContent()}
              </div>
              <SidebarAgentHouseAdditional />
            </>
          ) : (
            <>
              <Sidebar />
              {routeContent()}
            </>
          )}
        </div>
      ) : (
        <Redirect to={'/login'} />
      )}
    </Route>
  )
}

export default PrivateRoute
