import {
  AGENT_HOUSE_ANALYTICS_AGENCIES_FILTER_APPLY,
  AGENT_HOUSE_ANALYTICS_CATEGORIES_FILTER_APPLY,
  AGENT_HOUSE_ANALYTICS_COMPANIES_FILTER_APPLY,
  AGENT_HOUSE_ANALYTICS_DATES_FILTER_APPLY,
  AGENT_HOUSE_ANALYTICS_FILTERS_CLEAR,
  AGENT_HOUSE_ANALYTICS_FILTERS_FAIL,
  AGENT_HOUSE_ANALYTICS_FILTERS_REQUEST,
  AGENT_HOUSE_ANALYTICS_FILTERS_SUCCESS,
  AGENT_HOUSE_ANALYTICS_SET_DATATYPE,
  AGENT_HOUSE_ANALYTICS_SET_LISTTYPE,
  AGENT_HOUSE_ANALYTICS_SET_LISTVIEWTYPE,
} from '../../../types/actionTypes'
import { analyticsIncomesListType, analyticsIncomesListViewType } from '../../../types/analyticsIncomesTypes'
import { analyticsPeriodViewType } from '../../../types/analyticsTypes'
import { requestDataTypes } from '../../../types/requestDataTypes'

const initialState = {
  periodViewType: analyticsPeriodViewType.monthly,
  incomesDataType: requestDataTypes.commission,
  incomesListType: analyticsIncomesListType.agents,
  incomesListViewType: analyticsIncomesListViewType.table,
  selectedDates: [],
  selectedAgencies: [],
  companies: [],
  categories: [],
  filtersLoading: true,
  totals: {},
}

export const agencyHouseAnalyticsReducer = (state = initialState, action) => {
  const { payload, type } = action
  switch (type) {
    case AGENT_HOUSE_ANALYTICS_SET_DATATYPE: {
      return {
        ...state,
        incomesDataType: payload,
      }
    }
    case AGENT_HOUSE_ANALYTICS_SET_LISTTYPE: {
      return {
        ...state,
        incomesListType: payload,
      }
    }
    case AGENT_HOUSE_ANALYTICS_SET_LISTVIEWTYPE: {
      return {
        ...state,
        incomesListViewType: payload,
      }
    }
    case AGENT_HOUSE_ANALYTICS_FILTERS_REQUEST: {
      return {
        ...state,
        filtersLoading: true,
      }
    }
    case AGENT_HOUSE_ANALYTICS_FILTERS_SUCCESS: {
      const { dates, agencies, companies, categories } = payload
      const dateToSelect = dates.findLastIndex(d => d.hasValue)
      return {
        ...state,
        filtersLoading: false,
        selectedDates: dates.map((d, i) => ({ ...d, selected: i === dateToSelect })),
        selectedAgencies: agencies.map(a => ({ ...a, selected: false })),
        companies,
        categories,
      }
    }
    case AGENT_HOUSE_ANALYTICS_FILTERS_FAIL: {
      return {
        ...state,
        filtersLoading: false,
        filtersError: payload,
      }
    }

    case AGENT_HOUSE_ANALYTICS_DATES_FILTER_APPLY: {
      const { selectedDates, period } = payload
      return {
        ...state,
        selectedDates: state.selectedDates.map(d => ({ ...d, selected: selectedDates.includes(d.date.toString()) })),
        periodViewType: period,
      }
    }
    case AGENT_HOUSE_ANALYTICS_AGENCIES_FILTER_APPLY: {
      return {
        ...state,
        selectedAgencies: state.selectedAgencies.map(a => ({ ...a, selected: payload.includes(a.id) })),
      }
    }
    case AGENT_HOUSE_ANALYTICS_CATEGORIES_FILTER_APPLY: {
      return {
        ...state,
        categories: state.categories.map(a => ({ ...a, selected: payload.includes(a.id) })),
      }
    }
    case AGENT_HOUSE_ANALYTICS_COMPANIES_FILTER_APPLY: {
      return {
        ...state,
        companies: state.companies.map(a => ({ ...a, selected: payload.includes(a.id) })),
      }
    }
    case AGENT_HOUSE_ANALYTICS_FILTERS_CLEAR: {
      return {
        ...state,
        companies: [],
        categories: [],
        selectedAgencies: [],
        selectedDates: [],
        periodViewType: analyticsPeriodViewType.monthly,
      }
    }
    default:
      return state
  }
}
