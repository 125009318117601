import ReactApexChart from 'react-apexcharts'
import './ScanDownloadedRadialChart.scss'

const ScanDownloadedRadialChart = ({ percents = 0 }) => {
  const options = {
    series: [percents],
    fill: {
      type: 'solid',
      colors: ['var(--trend-up-color)'],
    },
    plotOptions: {
      radialBar: {
        offsetY: -35,
        hollow: {
          margin: 0,
          size: '60%',
        },
        startAngle: -90,
        endAngle: 90,
        track: {
          background: 'var(--background-primary-color)',
          opacity: 1,
          startAngle: -90,
          endAngle: 90,
          width: '40px',
        },
        dataLabels: {
          show: false,
          name: {
            show: true,
            offsetY: -10,
            color: '#C70039',
            fontSize: '40px',
          },
          value: {
            color: '#FFC300',
            fontSize: '30px',
            show: true,
          },
          total: { show: true, offsetY: -10, color: '#C70039', fontSize: '13px' },
        },
      },
    },

    stroke: {
      lineCap: 'round',
    },
    labels: ['Progress'],
  }
  return <ReactApexChart options={options} series={options.series} type='radialBar' height='230%' />
}

export default ScanDownloadedRadialChart
