import { CloseOutlined } from '@mui/icons-material'
import ModalWindow from '../../../../components/ModalWindow/ModalWindow'
import ScanCompanyCredentialsForm from '../ScanCompanyCredentialsForm/ScanCompanyCredentialsForm'
import { useTranslation } from 'react-i18next'
import { scanStatusType } from '../../../../types/scanStatus'
import './ScanCompanyPopupLoginError.scss'

const ScanCompanyPopupLoginError = ({
  userName,
  scanStatus,
  authFields,
  setAuthFields,
  submit,
  submitDisabled,
  closePopup,
}) => {
  const { t } = useTranslation('scan')

  let formHeading = t('credentials.LoginErrFormHeading')
  const popupHeading = {
    [scanStatusType.LoginError]: t('credentials.loginErr'),
    [scanStatusType.ResetPasswordRequierd]: t('credentials.loginExpiredErr'),
    [scanStatusType.InvalidVaultCredentials]: t('credentials.loginVaultErr'),
    [scanStatusType.ResetVaultPasswordRequired]: t('credentials.loginVaultExpiredErr'),
  }

  return (
    <ModalWindow renderPortal showCloseIcon={false}>
      <div className='scan-company-popup-login-error'>
        <header>
          <div>
            <p>{userName}</p>
            <p>{popupHeading[scanStatus]}</p>
          </div>
          <button onClick={closePopup}>
            <CloseOutlined />
          </button>
        </header>
        <p>{formHeading}</p>
        <ScanCompanyCredentialsForm
          authFields={authFields}
          setAuthFields={setAuthFields}
          submit={submit}
          submitDisabled={submitDisabled}
        />
      </div>
    </ModalWindow>
  )
}

export default ScanCompanyPopupLoginError
