import { useTranslation } from 'react-i18next'
import './AgencyHouseLobbySignalCard.scss'
import { irregularSignal } from '../../../../types/irregularSignals'
import AgencyHouseLobbyChanges from '../AgencyHouseLobbyChanges/AgencyHouseLobbyChanges'
import { InfoTwoTone } from '@mui/icons-material'
import { formatAsNumber } from '../../../../utils/formatAs'
import classNames from 'classnames'

const AgencyHouseLobbySignalCard = ({ signalKey, count, value, onCardClick, selected }) => {
  const { t } = useTranslation('common')

  const signal = Object.values(irregularSignal).find(s => s.key === signalKey)

  return (
    <div
      className={classNames('agency-house-lobby-signal-card', { clickable: onCardClick, selected })}
      {...(onCardClick && { onClick: onCardClick })}
    >
      <div className='agency-house-lobby-signal-card-body'>
        <header>{signal.cardIcon}</header>
        <h6>
          {t(`irregularSignals.${signal.key}`)}{' '}
          <span style={{ verticalAlign: 'text-top' }}>
            <InfoTwoTone style={{ fontSize: '20px' }} />
          </span>
        </h6>
        <p>{formatAsNumber(count)}</p>
      </div>
      <div className='agency-house-lobby-signal-card-footer'>
        <AgencyHouseLobbyChanges
          value={value}
          showTrend={false}
          changesText={t(`irregularSignalsDesc.${signal.key}`)}
          colorChanges={signal.colored}
          showSign={signal.signed}
        />
      </div>
    </div>
  )
}

export default AgencyHouseLobbySignalCard
