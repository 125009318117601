import './WorkstationButtonMobile.scss'

const WorkstationButtonMobile = ({ caption, disabled = false, onClick = () => {}, classes = '' }) => {
  return (
    <button className={'workstation-button-mobile ' + classes} disabled={disabled} onClick={onClick}>
      {caption}
    </button>
  )
}

export default WorkstationButtonMobile
