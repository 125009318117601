import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { hebrew } from '../../../../i18n'
import './CustomersAppliedFiltersBanner.scss'
import { CUSTOMERS_FILTER_BY_ADVANCED } from '../../../../types/actionTypes'

const getAppliedFilters = filters =>
  filters.filter(f => f.checked).map(f => ({ ...f, text: hebrew() ? f.name : f.nameEn ?? f.englishName }))

const removeAppliedFilter = (andFilters, noFilters, type, id) => {
  return [
    andFilters.map(c => ({
      ...c,
      checked: c.id === id && type === filterType.and ? false : c.checked,
      disabled: c.id === id && type === filterType.no ? false : c.disabled,
    })),
    noFilters.map(c => ({
      ...c,
      checked: c.id === id && type === filterType.no ? false : c.checked,
      disabled: c.id === id && type === filterType.and ? false : c.disabled,
    })),
  ]
}

const filterDataType = {
  company: 1,
  category: 2,
}

const filterType = {
  no: 1,
  and: 2,
}

const CustomersAppliedFiltersBanner = () => {
  const {
    advancedFiltersApplied,
    companiesFilter,
    categoriesFilter,
    companiesAndFilter,
    categoriesAndFilter,
    companiesNoFilter,
    categoriesNoFilter,
  } = useSelector(({ customers }) => customers)
  const dispatch = useDispatch()
  const { t } = useTranslation('customers')

  const removeFilter = (type, dataType, id) => {
    let companiesAnd, categoriesAnd, companiesNo, categoriesNo
    if (dataType === filterDataType.company) {
      ;[categoriesAnd, categoriesNo] = [categoriesAndFilter, categoriesNoFilter]
      ;[companiesAnd, companiesNo] = removeAppliedFilter(companiesAndFilter, companiesNoFilter, type, id)
    } else {
      ;[categoriesAnd, categoriesNo] = removeAppliedFilter(categoriesAndFilter, categoriesNoFilter, type, id)
      ;[companiesAnd, companiesNo] = [companiesAndFilter, companiesNoFilter]
    }
    dispatch({
      type: CUSTOMERS_FILTER_BY_ADVANCED,
      payload: {
        companiesFilter,
        categoriesFilter,
        companiesAndFilter: companiesAnd,
        categoriesAndFilter: categoriesAnd,
        companiesNoFilter: companiesNo,
        categoriesNoFilter: categoriesNo,
        advancedFiltersApplied:
          getAppliedFilters([...companiesAnd, ...categoriesAnd, ...companiesNo, ...categoriesNo]).length > 0,
      },
    })
  }

  const renderAppliedFilters = (companies, categories, type) => {
    if (companies.length === 0 && categories.length === 0) {
      return null
    }
    return (
      <>
        <p className='customers-applied-filters-type-title'>
          {t(`filters.showingResults${type === filterType.no ? 'NoOne' : 'Every'}`)}
        </p>
        {companies.map(f => (
          <div key={f.id + f.name} className='customers-applied-filter-chip'>
            <p>{f.text}</p>
            <button onClick={() => removeFilter(type, filterDataType.company, f.id)}>
              <img src='./assets/close-icon-alt-sm/Close.png' alt='x' />
            </button>
          </div>
        ))}
        {categories.map(f => (
          <div key={f.id + f.name} className='customers-applied-filter-chip'>
            <p>{f.text}</p>
            <button onClick={() => removeFilter(type, filterDataType.category, f.id)}>
              <img src='./assets/close-icon-alt-sm/Close.png' alt='x' />
            </button>
          </div>
        ))}
      </>
    )
  }

  if (
    getAppliedFilters([...companiesAndFilter, ...categoriesAndFilter, ...companiesNoFilter, ...categoriesNoFilter])
      .length === 0 ||
    !advancedFiltersApplied
  ) {
    return null
  }

  return (
    <div className='customers-applied-filters-banner-container'>
      <p className='customers-applied-filters-title'>{t('filters.showingResults')}</p>
      {renderAppliedFilters(getAppliedFilters(companiesNoFilter), getAppliedFilters(categoriesNoFilter), filterType.no)}
      {renderAppliedFilters(
        getAppliedFilters(companiesAndFilter),
        getAppliedFilters(categoriesAndFilter),
        filterType.and
      )}
    </div>
  )
}

export default CustomersAppliedFiltersBanner
