import { authField, authFieldType } from '../types/authFieldsTypes'
import { companyType } from '../types/companies'

export const getCompanyAuthFields = company => {
  let { authFieldsArray, authFields, id, companyId } = company
  if (authFieldsArray === undefined) {
    authFieldsArray = authFields
  }
  if (id === undefined) {
    id = companyId
  }
  const fields = authFieldsArray
    .filter(f =>
      id === companyType.altshuler
        ? f !== authFieldType.userName && f !== authFieldType.vaultPassword
        : id === companyType.ayalon
        ? f !== authFieldType.vaultUser && f !== authFieldType.vaultPassword
        : true
    )
    .map(field => {
      const fieldNames = getAuthFieldsNames(id, field.trim())
      return {
        fieldType: field,
        ...fieldNames,
      }
    })
  return fields
}

const getAuthFieldsNames = (companyId, fieldType) => {
  //Ayalon
  if (companyId === companyType.ayalon) {
    return {
      name:
        fieldType === authFieldType.vaultPassword
          ? 'סיסמא איילונט'
          : fieldType === authFieldType.vaultUser
          ? 'משתמש איילונט'
          : authField[fieldType].name,
      nameEn:
        fieldType === authFieldType.vaultPassword
          ? 'Ayalon Password'
          : fieldType === authFieldType.vaultUser
          ? 'Ayalon User'
          : authField[fieldType].nameEn,
    }
  }

  // Altshuler
  if (companyId === companyType.altshuler) {
    return {
      name:
        fieldType === authFieldType.vaultPassword
          ? 'סיסמא עמלות'
          : fieldType === authFieldType.password
          ? 'תעודת זהות'
          : fieldType === authFieldType.idNumber
          ? 'מספר חברה / מספר רשיון'
          : authField[fieldType].name,
      nameEn:
        fieldType === authFieldType.vaultPassword
          ? 'Commissions Password'
          : fieldType === authFieldType.password
          ? 'ID Number'
          : fieldType === authFieldType.idNumber
          ? 'Company number/License number'
          : authField[fieldType].nameEn,
    }
  }

  // PassportCard
  if (companyId === companyType.passportCard) {
    return {
      name: fieldType === authFieldType.userName ? 'אימייל' : authField[fieldType].name,
      nameEn: fieldType === authFieldType.userName ? 'Email' : authField[fieldType].nameEn,
    }
  }

  //Shlomo
  if (companyId === companyType.shlomo) {
    return {
      name:
        fieldType === authFieldType.vaultPassword
          ? 'סיסמא כספת'
          : fieldType === authFieldType.vaultUser
          ? 'שם משתמש כספת'
          : authField[fieldType].name,
      nameEn:
        fieldType === authFieldType.vaultPassword
          ? 'Shlomo Password'
          : fieldType === authFieldType.vaultUser
          ? 'Shlomo User'
          : authField[fieldType].nameEn,
    }
  }
  //Mor
  if (companyId === companyType.more) {
    return {
      name:
        fieldType === authFieldType.userName
          ? 'ח"פ/מס\' רישיון'
          : fieldType === authFieldType.password
          ? 'תעודת זהות'
          : fieldType === authFieldType.idNumber
          ? "מס' טלפון"
          : authField[fieldType].name,
      nameEn:
        fieldType === authFieldType.userName
          ? 'License Number'
          : fieldType === authFieldType.password
          ? 'ID Number'
          : fieldType === authFieldType.idNumber
          ? 'Phone Number'
          : authField[fieldType].nameEn,
    }
  }
  return { ...authField[fieldType] }
}
