import { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import StepPhone from '../StepPhone/StepPhone'
import classNames from 'classnames'
import TabsNavbar from '../../../../components/TabsNavbar/TabsNavbar'
import { twoFactorOption, twoFactorOptions } from '../../../../types/twoFactorOptionTypes'
import { hebrew } from '../../../../i18n'
import { getRegisterCodeRequest, sendRegisterCodeRequest } from '../../../../http/requests/agencyLoginRequest'
import { buildFullPhoneNumber } from '../../../../utils/buildFullPhoneNumber'

const StepPhoneSms = ({ nextStep, data, setData }) => {
  const { t } = useTranslation('registration')
  const { t: tCommon } = useTranslation('common')
  const [sms, setSms] = useState([...Array(6)])

  const { option, agentEmail } = data

  const [serverError, setServerError] = useState(null)
  const [loading, setLoading] = useState(false)

  const selectedOptionPhone = option === twoFactorOption.byPhone

  const refs = useRef([])

  const submitHandler = async e => {
    e.preventDefault()
    const smsCode = sms.join('')
    const { phoneNumber } = data
    try {
      setLoading(true)
      const { data } = await sendRegisterCodeRequest({
        phone: phoneNumber,
        code: smsCode,
        isEmail: !selectedOptionPhone,
        value: selectedOptionPhone ? phoneNumber : agentEmail,
      })
      if (data.HasError) {
        setLoading(false)
        setServerError(data.ErrorMessage)
      } else {
        setData(prev => ({
          ...prev,
          phoneNumber,
          isEmail: !selectedOptionPhone,
          value: selectedOptionPhone ? buildFullPhoneNumber(phoneNumber) : agentEmail,
        }))
        nextStep()
      }
    } catch (error) {
      setLoading(false)
      setServerError('Server Error')
    }
  }

  const requestCode = async e => {
    e.preventDefault()
    setSms([...Array(6)])
    const { phoneNumber } = data
    setLoading(true)
    setData(prev => ({
      ...prev,
      smsCode: [...Array(6)],
    }))
    setServerError(null)
    try {
      const { data } = await getRegisterCodeRequest({
        isEmail: !selectedOptionPhone,
        value: selectedOptionPhone ? phoneNumber : agentEmail,
      })
      if (data.HasError) {
        setServerError(data.ErrorMessage)
      }
    } catch (error) {
      setServerError('Server Error')
    }
    setLoading(false)
    refs.current[0].focus()
  }

  const numbersOnlyEventHandler = index => e => {
    e.target.value = e.target.value.replace(/\D/g, '')
    if (e.target.value.length !== 0 && refs.current[index + 1] !== undefined) {
      refs.current[index + 1].focus()
    }
  }

  const keyDownHandler = index => e => {
    const { key } = e
    if (key === 'Backspace') {
      if (e.target.value.length === 0 && refs.current[index - 1] !== undefined) {
        refs.current[index - 1].focus()
      }
      return
    }
    if (e.target.value.length > 0) {
      if (refs.current[index + 1] !== undefined) {
        refs.current[index + 1].focus()
      }
      return
    }
  }

  const hasEmptyFields = () => sms.some(ch => ch === undefined)

  useEffect(() => {
    refs.current[0].focus()
  }, [])

  return (
    <StepPhone serverError={serverError} setServerError={setServerError}>
      <form className='register-step-phone-form' onSubmit={submitHandler}>
        <TabsNavbar
          items={Object.values(twoFactorOptions).map(o => ({ ...o, text: hebrew() ? o.name : o.nameEn }))}
          currentItem={option}
          setCurrentItem={option => setData(prev => ({ ...prev, option }))}
          disabled
        />
        <div className='register-step-phone-form-header'>
          <h5 className='register-step-phone-form-heading'>{t('step2factor.smsFormHeading')}</h5>
          {tCommon(`login.input${selectedOptionPhone ? 'Phone' : 'Email'}SmsTitle`)}
        </div>
        <div className={classNames('register-step-phone-form-inputs-container', { 'has-error': serverError })}>
          <div className='register-step-phone-form-inputs-group'>
            {[...Array(6)].map((_, i) => (
              <input
                ref={e => (refs.current[i] = e)}
                size={1}
                className='input-otp'
                key={`digit${i + 1}`}
                type='text'
                name={`digit${i + 1}`}
                id={`digit${i + 1}`}
                placeholder='*'
                maxLength='1'
                onInput={numbersOnlyEventHandler(i)}
                onKeyDown={keyDownHandler(i)}
                inputMode='numeric'
                value={sms[i] ?? ''}
                onChange={e => setSms(prev => prev.map((ch, idx) => (idx === i ? e.target.value : ch)))}
              />
            ))}
          </div>
          <button disabled={hasEmptyFields() || loading}>{t('step2factor.smsFormBtnRequest')}</button>
        </div>
        <div className='register-step-phone-form-footer'>
          <h4>
            {t('step2factor.smsRemark')} <span onClick={requestCode}>{t('step2factor.smsResend')}</span>
          </h4>
        </div>
      </form>
    </StepPhone>
  )
}

export default StepPhoneSms
