import { useTranslation } from 'react-i18next'
import Tooltip from '../Tooltip/Tooltip'
import './ButtonsChangeStatus.scss'

const ButtonsChangeStatus = ({ treat, ignore, deactivate, markTreated }) => {
  const { t } = useTranslation('customer')
  return (
    <div className='buttons-change-status-container'>
      {treat && (
        <button className='treat' onClick={treat}>
          <Tooltip position={'top'} title={t('actionButtons.treat')} />
          <img className='off-hover' src='./assets/button-icons/send-light/send.png' alt='treat' />
          <img className='on-hover' src='./assets/button-icons/send/Send.png' alt='treat' />
        </button>
      )}
      {ignore && (
        <button className='ignore' onClick={ignore}>
          <Tooltip position={'top'} title={t('actionButtons.ignore')} />
          <img className='off-hover' src='./assets/button-icons/ok-light/ok.png' alt='ignore' />
          <img className='on-hover' src='./assets/button-icons/ok/ok.png' alt='ignore' />
        </button>
      )}
      {markTreated && (
        <button className='treated' onClick={markTreated}>
          <Tooltip position={'top'} title={t('actionButtons.markTreated')} textWidth='130px' />
          <img
            className='off-hover'
            style={{ height: '22px' }}
            src='./assets/button-icons/hourglass-light/hourglass.png'
            alt='treated'
          />
          <img className='on-hover' src='./assets/button-icons/hourglass/hourglass.png' alt='treated' />
        </button>
      )}
      {deactivate && (
        <button className='deactivate' onClick={deactivate}>
          <Tooltip position={'top'} title={t('actionButtons.deactivate')} />
          <img className='off-hover' src='./assets/button-icons/close-light/close.png' alt='inact' />
          <img className='on-hover' src='./assets/button-icons/close-light/close.png' alt='inact' />
        </button>
      )}
    </div>
  )
}

export default ButtonsChangeStatus
