export const companiesObjects = {
  1: { markerColor: '#FFF200' },
  2: { markerColor: '#2D9D3B' },
  3: { markerColor: '#006EB2' },
  4: { markerColor: '#2D296C' },
  5: { markerColor: '#5B0000' },
  6: { markerColor: '#F15B2D' },
  7: { markerColor: '#008053' },
  8: { markerColor: '#EE3439' },
  9: { markerColor: '#00A20D' },
  10: { markerColor: '#2ECC71' },
  11: { markerColor: '#F1C40F' },
  12: { markerColor: '#F5AD0F' },
  13: { markerColor: '#E20414' },
  14: { markerColor: '#0065AC' },
  15: { markerColor: '#4C8AC5' },
  16: { markerColor: '#F15B2D' },
  17: { markerColor: '#2D296C' },
  18: { markerColor: '#006EB2' },
  19: { markerColor: '#2D9D3B' },
  20: { markerColor: '#FFF200' },
  1001: { markerColor: '#00ff00' },
  1002: { markerColor: '#c93' },
  1003: { markerColor: '#66f' },
  default: { markerColor: '#000' },
}
