import { useTranslation } from 'react-i18next'
import Button from '../../../../components/Common/Button/Button'
import InputText from '../../../../components/Common/InputText/InputText'
import InputSwitch from '../../../../components/Common/InputSwitch/InputSwitch'
import ModalWindow from '../../../../components/ModalWindow/ModalWindow'
import Loader from '../../../../components/Common/Loader/Loader'
import InputSelect from '../../../../components/Common/InputSelect/InputSelect'
import {
  agencyUserRole,
  userPreference,
  userPreferenceAccessType,
  userPreferenceType,
} from '../../../../types/agencyUserTypes'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAgentGroups, getAllAgentGroups, getUserRoles } from '../../../shared/actions/sharedActions'
import { addProfileEmployee, editProfileEmployee } from '../../actions/profileActions'
import { PROFILE_EMPLOYEES_ADD_RESET, PROFILE_EMPLOYEES_EDIT_RESET } from '../../../../types/actionTypes'
import { urlMaster } from '../../../../environment/urls'
import InputSelectAdvanced from '../../../../components/Common/InputSelectAdvanced/InputSelectAdvanced'
import { getUserNameInitials } from '../../../../services/getUserNameInitials'
import { avatarStyles } from '../../../../types/colorsTypes'
import AddAgentGroupModal from '../../../../components/AddAgentGroupModal/AddAgentGroupModal'
import './ProfileAddEmployeeModal.scss'

const userPreferences = accessType =>
  Object.entries(userPreference)
    .filter(([k, v]) => k !== userPreferenceType.all && (accessType ? v.accessTypes.includes(accessType) : true))
    .map(([k, v]) => {
      return {
        id: k,
        ...v,
      }
    })

const ProfileAddEmployeeModal = ({ closeModal, employee = null }) => {
  const { t, i18n } = useTranslation('profile')
  const { roles, rolesLoading, agentGroups, allAgentGroups, allAgentGroupsLoading, addGroupSuccess } = useSelector(
    state => state.shared
  )
  const { countryPhoneCode } = useSelector(state => state.login)
  const {
    addEmployeeLoading,
    addEmployeeSuccess,
    addEmployeeError,
    editEmployeeLoading,
    editEmployeeSuccess,
    companies,
  } = useSelector(state => state.profile)
  const dispatch = useDispatch()

  const [fullName, setFullName] = useState('')
  const [selectedRole, setSelectedRole] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [selectedPreferences, setSelectedPreferences] = useState(userPreferences().map(({ id }) => id))
  const [selectedCompanies, setSelectedCompanies] = useState(companies.map(({ id }) => id))
  const [selectedAgentGroups, setSelectedAgentGroups] = useState([])
  const [showAddAgentGroupModal, setShowAddAgentGroupModal] = useState(false)

  const selectPreference = (id, checked, accessType) => {
    if (id === userPreferenceType.all) {
      setSelectedPreferences(checked ? userPreferences(accessType).map(i => i.id) : [])
    } else {
      setSelectedPreferences(checked ? [...selectedPreferences, id] : selectedPreferences.filter(r => r !== id))
    }
  }

  const selectAllPreferences = (checked, accessType) => {
    const preferences = userPreferences(accessType).map(i => i.id)
    if (checked) {
      // && preferences.some(pf => selectedPreferences.includes(pf))) {
      setSelectedPreferences([...selectedPreferences, ...preferences.filter(pr => !selectedPreferences.includes(pr))])
    } else {
      setSelectedPreferences(selectedPreferences.filter(pr => !preferences.includes(pr)))
    }
  }

  const selectItem = (setItems, items, itemId) => {
    setItems(items.includes(itemId) ? items.filter(c => c !== itemId) : [...items, itemId])
  }

  const deselectItem = (setItems, items, itemId) => {
    setItems(items.filter(c => c !== itemId))
  }

  const selectAllItems = (setItems, items, baseItems) => {
    setItems(items.length !== baseItems.length ? baseItems.map(c => c.id) : [])
  }

  const renderUserPreferences = accessType => (
    <div className='profile-add-user-modal-inputs-group-toggles'>
      <div className='profile-add-user-modal-inputs-group-title'>
        {t(`addUserModal.userPreferences${accessType === userPreferenceAccessType.data ? 'Data' : 'Page'}`)}
      </div>
      <div className='profile-add-user-modal-inputs-group-check'>
        <div className='add-user-modal-toggle' key={userPreferenceType.all}>
          <InputSwitch
            id={userPreferenceType.all}
            onChange={({ target: { value, checked } }) => selectAllPreferences(checked, accessType)}
            checked={userPreferences(accessType)
              .map(pr => pr.id)
              .every(pr => selectedPreferences.includes(pr))}
            value={userPreferenceType.all}
          />
          <label htmlFor={userPreferenceType.all}>
            {hebrew ? userPreference[userPreferenceType.all].name : userPreference[userPreferenceType.all].nameEn}
          </label>
        </div>
        {userPreferences(accessType).map(r => (
          <div className='add-user-modal-toggle' key={r.id}>
            <InputSwitch
              id={r.id}
              onChange={({ target: { value, checked } }) => selectPreference(value, checked, accessType)}
              checked={selectedPreferences.includes(r.id)}
              value={r.id}
            />
            <label htmlFor={r.id}>{hebrew ? r.name : r.nameEn}</label>
          </div>
        ))}
      </div>
    </div>
  )

  const agentGroupsDefaultItem = (
    <div className='agent-groups-default-item'>
      <button
        onClick={e => {
          //e.preventDefault()
          e.stopPropagation()
          setShowAddAgentGroupModal(true)
        }}
      >
        <div className='agent-groups-default-item-btn-icon'>
          <img src='./assets/icon-add-green/Add.png' alt='+' />
        </div>
        <div className='agent-groups-default-item-btn-text'>{t('addUserModal.addGroup')}</div>
      </button>
    </div>
  )

  const noagentGroupsPlaceholder = (
    <>
      <div className='noagents-groups-placeholder-container'>
        <p className='noagents-groups-placeholder'>{t('addUserModal.noAgentGroupsPlaceholder')}</p>
        <p className='noagents-groups-remark'>{t('addUserModal.noAgentGroupsRemark')}</p>
      </div>
      {/* {agentGroupsDefaultItem} */}
    </>
  )

  const submitHandler = e => {
    e.preventDefault()
    const employeeDto = {
      fullName: fullName.trim(),
      phoneNumber: countryPhoneCode + (phoneNumber.length === 10 ? phoneNumber.slice(1) : phoneNumber),
      email,
      role: selectedRole,
      preferences: userPreferences().reduce(
        (acc, val) => ({
          ...acc,
          [val.id]: selectedPreferences.includes(val.id),
        }),
        {}
      ),
      companies: selectedCompanies,
      agentGroups: selectedAgentGroups,
      ...(employee && { id: employee.Id }),
    }
    //console.log(employeeDto)
    //console.log(employee ? 'editing' : 'adding')
    dispatch(employee ? editProfileEmployee(employeeDto) : addProfileEmployee(employeeDto))
  }

  const hebrew = i18n.language === 'he'

  const validateEmail = email => {
    var re = /\S+@\S+\.\S+/
    return re.test(email)
  }

  const formIsValid =
    [fullName, selectedRole, phoneNumber, email, selectedPreferences, selectedCompanies].every(i => i.length > 0) &&
    validateEmail(email)

  const closeModalHandler = () => {
    closeModal()
    dispatch({ type: PROFILE_EMPLOYEES_ADD_RESET })
    dispatch({ type: PROFILE_EMPLOYEES_EDIT_RESET })
  }

  useEffect(() => {
    dispatch(getUserRoles())
    dispatch(getAllAgentGroups())
  }, [dispatch])

  useEffect(() => {
    if (addEmployeeSuccess || editEmployeeSuccess) {
      closeModalHandler()
    }
  }, [addEmployeeSuccess, editEmployeeSuccess])

  useEffect(() => {
    if (employee)
      fetch(`${urlMaster()}account/employee/${employee.Id}`)
        .then(res => res.json())
        .then(result => {
          const employee = result.Data
          setFullName(employee.FullName)
          setSelectedRole(employee.Role)
          setEmail(employee.Email)
          if (employee.PhoneNumber) {
            setPhoneNumber(
              '0' + employee.PhoneNumber.substring(employee.PhoneNumber.length - 9, employee.PhoneNumber.length)
            )
          }
          if (employee.Preferences) {
            setSelectedPreferences(
              Object.entries(employee.Preferences)
                .filter(([_, v]) => v)
                .map(([k, _]) => k.charAt(0).toLowerCase() + k.slice(1))
                .filter(p => Object.keys(userPreferenceType).includes(p))
            )
          }
          if (employee.Companies) {
            setSelectedCompanies(employee.Companies)
          }
          if (employee.AgentGroups) {
            setSelectedAgentGroups(employee.AgentGroups)
          }
        })
  }, [employee])

  useEffect(() => {
    addGroupSuccess && dispatch(getAllAgentGroups())
  }, [addGroupSuccess, dispatch])

  useEffect(() => {
    !employee && setSelectedAgentGroups(allAgentGroups.map(({ id }) => id))
  }, [allAgentGroups, employee])

  return (
    <ModalWindow closeOnOutsideClick={false} closeModal={closeModalHandler}>
      {(addEmployeeLoading || editEmployeeLoading) && (
        <div className='profile-add-user-modal-loader-overlay'>
          <Loader />
        </div>
      )}
      <div className='profile-add-user-modal'>
        <form
          action='' //</div>onSubmit={submitHandler}
        >
          <div className='profile-add-user-modal-header'>
            <h3>{t(`addUserModal.modal${employee ? 'Edit' : ''}Title`)}</h3>
            {addEmployeeError && (
              <div className='profile-add-user-modal-error'>{addEmployeeError ?? 'Server Error'}</div>
            )}
          </div>
          <div className='profile-add-user-modal-content'>
            <div className='profile-add-user-modal-inputs-group-container'>
              <div className='profile-add-user-modal-inputs-group-title'>{t('addUserModal.userDetails')}</div>
              <div className='profile-add-user-modal-inputs-group-row'>
                <InputText label={t('addUserModal.fullName')} onChange={setFullName} value={fullName} required />
                <div className='profile-add-user-modal-input-wrapper'>
                  {rolesLoading && (
                    <div className='profile-add-user-modal-loader-overlay'>
                      <Loader />
                    </div>
                  )}
                  <InputSelect
                    label={t('addUserModal.userType')}
                    values={roles.map(r => {
                      return {
                        ...r,
                        id: r.Key,
                        text: hebrew ? agencyUserRole[r.Key].name : agencyUserRole[r.Key].nameEn,
                      }
                    })}
                    selectedValue={selectedRole}
                    onChange={setSelectedRole}
                  />
                </div>
              </div>
              <div className='profile-add-user-modal-inputs-group-row'>
                <div className='input-text-dir-wrapper'>
                  <InputText
                    label={t('addUserModal.email')}
                    onChange={setEmail}
                    value={email}
                    inputType='email'
                    required
                  />
                </div>
                <div className='input-text-dir-wrapper'>
                  <InputText
                    label={t('addUserModal.phone')}
                    inputType='number'
                    onChange={setPhoneNumber}
                    value={phoneNumber}
                    required
                    minLength={7}
                    maxLength={10}
                    disabled={employee}
                  />
                </div>
              </div>
            </div>
            <div className='profile-add-user-modal-inputs-group-container'>
              <div className='profile-add-user-modal-inputs-group-title'>{t('addUserModal.userPreferences')}</div>
              <div className='profile-add-user-modal-inputs-group-row'>
                <InputSelectAdvanced
                  label={t('addUserModal.companies')}
                  items={companies.map(({ id, name, nameEn }) => ({
                    id,
                    value: hebrew ? name : nameEn,
                    icon: (
                      <img
                        src={`./assets/companies-logos-light/${id}.png`}
                        alt={name}
                        style={{ height: '18px', width: '18px' }}
                      />
                    ),
                  }))}
                  selectedItems={selectedCompanies}
                  selectItem={id => selectItem(setSelectedCompanies, selectedCompanies, id)}
                  deselectItem={id => deselectItem(setSelectedCompanies, selectedCompanies, id)}
                  selectAll={() => selectAllItems(setSelectedCompanies, selectedCompanies, companies)}
                  selectAllText={hebrew ? 'כל החברות' : 'All Companies'}
                />
                <InputSelectAdvanced
                  label={t('addUserModal.agentGroups')}
                  items={allAgentGroups.map(({ id, name, colorIndex }) => ({
                    id,
                    value: name,
                    icon: (
                      <div
                        style={{
                          height: '22px',
                          minWidth: '22px',
                          borderRadius: '5px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          ...avatarStyles[colorIndex],
                        }}
                      >
                        {getUserNameInitials(name)}
                      </div>
                    ),
                  }))}
                  selectedItems={selectedAgentGroups}
                  selectItem={id => selectItem(setSelectedAgentGroups, selectedAgentGroups, id)}
                  deselectItem={id => deselectItem(setSelectedAgentGroups, selectedAgentGroups, id)}
                  selectAll={() => selectAllItems(setSelectedAgentGroups, selectedAgentGroups, agentGroups)}
                  selectAllText={hebrew ? 'כל קבוצות הסוכנים' : 'All Agent Groups'}
                  noItemsPlaceholder={noagentGroupsPlaceholder}
                  defaultItem={agentGroupsDefaultItem}
                />
              </div>
              <div className='profile-add-user-modal-inputs-group-toggles-container'>
                {renderUserPreferences(userPreferenceAccessType.data)}
                {renderUserPreferences(userPreferenceAccessType.page)}
              </div>
            </div>
          </div>
          <div className='profile-add-user-modal-footer'>
            <Button
              //typeSubmit={true}
              onClick={submitHandler}
              caption={t(`addUserModal.modalButton.${employee ? 'edit' : 'send'}`)}
              disabled={!formIsValid || addEmployeeLoading || editEmployeeLoading}
            />
          </div>
        </form>
      </div>
      {showAddAgentGroupModal && <AddAgentGroupModal closeModal={() => setShowAddAgentGroupModal(false)} />}
    </ModalWindow>
  )
}

export default ProfileAddEmployeeModal
