import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import ModalAgreement from '../../components/ModalAgreement/ModalAgreement'
import AgencyHouseInviteNotyfier from '../../features/shared/components/AgencyHouseInviteNotyfier/AgencyHouseInviteNotyfier'
import AgenciesNavbar from '../../features/agentHouse/components/AgenciesNavbar/AgenciesNavbar'
import { regularAgencyPrivateRoutes } from '../../routes/routes'
import { agencyRoutes } from '../../environment/urls'
import { Redirect, Route, Switch } from 'react-router-dom'
import { hebrew } from '../../i18n'
import { getProfileCompanies, getProfileFields, getProfileInfo } from '../../features/profile/actions/profileActions'
import './ProfileScreen.scss'

const ProfileScreen = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation('profile')

  const currAgreement = null
  const [showAgreement, setShowAgreement] = useState(false)

  const baseRoute = regularAgencyPrivateRoutes.find(r => r.path === '/' + agencyRoutes.profile)
  const subRoutes = Object.values(baseRoute.subroute)

  useEffect(() => {
    dispatch(getProfileFields())
    dispatch(getProfileCompanies())
    dispatch(getProfileInfo())
  }, [dispatch])

  return (
    <>
      <div className='profile-screen'>
        <header>
          <h5>{t('userProfile')}</h5>
          <AgenciesNavbar
            navbarItems={subRoutes.map(sr => ({
              path: `${baseRoute.path}${sr.path}`,
              text: hebrew() ? sr.name : sr.nameEn,
            }))}
          />
        </header>
        <div className='profile-screen-content'>
          <Switch>
            {subRoutes.map(sr => (
              <Route path={`${baseRoute.path}${sr.path}`} component={sr.component} key={sr.path} exact />
            ))}
            <Route path={`${baseRoute.path}`}>
              <Redirect to={`${baseRoute.path}${subRoutes[0].path}`} />
            </Route>
          </Switch>
        </div>
      </div>
      {showAgreement && (
        <ModalAgreement closeModal={() => setShowAgreement(false)} currentAgreementType={currAgreement} />
      )}
      <AgencyHouseInviteNotyfier />
    </>
  )
}

export default ProfileScreen
