import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import CheckMark from '../Common/Marks/CheckMark/CheckMark'
import Loader from '../Common/Loader/Loader'
import NoData from '../Common/NoData/NoData'
import classNames from 'classnames'
import { paymentPeriod, paymentPlan, paymentPlanType } from '../../types/paymentPlanTypes'
import { getSubscriptions } from '../../http/requests'
import './PaymentPlans.scss'

const PaymentPlans = ({ selectPlan = () => {}, selectedPlan, showSelectedPlan = null }) => {
  const [selectedPeriod, setSelectedPeriod] = useState(paymentPeriod.monthly)
  const [selectedPrice, setSelectedPrice] = useState(null)
  const [subscriptions, setSubscriptions] = useState([])
  const [subscriptionsLoading, setSubscriptionsLoading] = useState(false)

  const {
    t,
    i18n: { language: lang },
  } = useTranslation('paymentPlans')
  const hebrew = lang === 'he'

  const selectPlanHandler = (type, plan) => {
    setSelectedPrice(plan)
    selectPlan({ type: type, period: selectedPeriod })
  }

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        setSubscriptionsLoading(true)
        setSubscriptions([])
        const {
          data: { Data: data },
        } = await getSubscriptions()
        console.log()
        setSubscriptions(
          data.filter(
            sub =>
              sub.Id !== paymentPlanType.trial &&
              sub.Id !== paymentPlanType.bigAgency &&
              sub.Id !== paymentPlanType.regularAgency
          )
        )
      } catch (error) {
        console.log(error)
      } finally {
        setSubscriptionsLoading(false)
      }
    }

    fetchSubscriptions()
  }, [])

  if (subscriptionsLoading || subscriptions.length === 0) {
    return (
      <div className='payment-plans-wrapper'>
        {subscriptionsLoading ? <Loader /> : <NoData text='No Subscriptions Found' />}
      </div>
    )
  }

  return (
    <div className='payment-plans-wrapper'>
      <div className='payment-period-wrapper'>
        {/* <div className='payment-period-container'>
          <div
            className={classNames('payment-period-text', { selected: selectedPeriod === paymentPeriod.monthly })}
            onClick={() => setSelectedPeriod(paymentPeriod.monthly)}
          >
            {t('monthly')}
          </div>
          <div
            className={classNames('payment-period-text', { selected: selectedPeriod === paymentPeriod.yearly })}
            onClick={() => setSelectedPeriod(paymentPeriod.yearly)}
          >
            {t('yearly')}
          </div>
        </div> */}
      </div>
      <div
        className={classNames('payment-plans-container', {
          'payment-plans-container-yearly': selectedPeriod === paymentPeriod.yearly,
        })}
      >
        {subscriptions.map(s => (
          <div
            key={s.Id}
            className={classNames(
              'payment-plan-card',
              {
                'most-popular': s.IsMostPopular,
                // disabled: s.Id === paymentPlanType.pro && selectedPeriod !== paymentPeriod.yearly,
              },
              'desktop-payment-plan-card'
            )}
            // {...(!(s.Id === paymentPlanType.pro && selectedPeriod !== paymentPeriod.yearly) && {
            //   onClick: () => selectPlanHandler(s.Id, s),
            // })}
            onClick={() => selectPlanHandler(s.Id, s)}
          >
            <div className='payment-plan-card-header'>
              <h3>{t(`${s.NameEn.toLowerCase()}.name`)}</h3>
              <h5>
                <Trans
                  i18nKey={`${s.NameEn.toLowerCase()}.recommendation${
                    selectedPeriod === paymentPeriod.yearly ? 'Yearly' : 'Monthly'
                  }`}
                  ns='paymentPlans'
                  components={[<span style={{ fontWeight: 'bold' }}>link1</span>]} // The component at index 0 (<strong />) will be parent of <0> ({{- key}}), and so on...
                />
              </h5>
            </div>
            <div className='payment-plan-card-body'>
              <div className='payment-plan-card-body-header'>
                <div className='payment-plan-card-body-header-title'>
                  <span>
                    ₪
                    {selectedPeriod === paymentPeriod.yearly
                      ? s.YearlyPricePerAdditionalCustomer.toFixed(2)
                      : s.PricePerAdditionalCustomer.toFixed(2)}
                  </span>
                  <h4>{t('customer')}</h4>
                  {selectedPeriod === paymentPeriod.yearly && s.Id !== 1 && s.Id !== 4 && (
                    <div className='payment-plan-discounts'>
                      <span className='payment-plan-discount'>{(s.Id === 2 ? 18 : 20) + '%'}</span>
                    </div>
                  )}
                </div>
                <div className='payment-plan-card-body-header-subtitle'>
                  <h5>
                    {t('minimumMonthCost', {
                      minimumMonthCost: s.Price - (selectedPeriod === paymentPeriod.yearly ? s.YearDiscount : 0),
                    })}
                  </h5>
                </div>
              </div>
              <div className='payment-plan-card-body-content'>
                <ul className='payment-plan-benefits'>
                  {paymentPlan[s.Id].benefits.map(b => (
                    <li key={b.textEn}>
                      <CheckMark green size='sm' />
                      <div>{hebrew ? b.text : b.textEn}</div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className='payment-plan-card-footer'>
              <p>{t('selectPlan')}</p>
            </div>
          </div>
        ))}
      </div>
      {selectedPrice ? (
        <div className='payment-plans-mobile-container'>
          <div className='payment-plans-mobile--header'>
            <div>
              <span>פירוט תוכנית</span>
              <p>{t(`${selectedPrice.NameEn.toLowerCase()}.name`)}</p>
            </div>
            <div>
              {showSelectedPlan === null ? (
                <p>{t('selectPlan')}</p>
              ) : (
                <p onClick={() => showSelectedPlan(true)}>{t('selectPlan')}</p>
              )}
            </div>
          </div>
          <div className='payment-plans-mobile--body'>
            <h3>
              <Trans
                i18nKey={`${selectedPrice.NameEn.toLowerCase()}.recommendation${
                  selectedPeriod === paymentPeriod.yearly ? 'Yearly' : 'Monthly'
                }`}
                ns='paymentPlans'
                components={[<span style={{ fontWeight: 'bold' }}>link1</span>]}
              />
            </h3>
            {paymentPlan[selectedPrice.Id].benefits.map(b => (
              <p key={b.textEn}>
                <CheckMark green size='lg' />
                <span>{hebrew ? b.text : b.textEn}</span>
              </p>
            ))}
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className='payment-plans-remark-container'>
        <p>{t('paymentRemark')}</p>
      </div>
    </div>
  )
}

export default PaymentPlans
