import { cn } from '../../../utils/stylesUtils'
import Loader from '../Loader/Loader'
import './LoadingOverlay.scss'

const LoadingOverlay = ({ styles, fullscreen = false, text }) => {
  return (
    <div className={cn('loading-overlay', { fullscreen })} style={{ ...styles }}>
      <Loader />
      {text && <p>{text}</p>}
    </div>
  )
}

export default LoadingOverlay
