import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ModalWithSelection from '../../../../components/Common/ModalWithSelection/ModalWithSelection'
import CategoriesConfirmModal from '../CategoriesConfirmModal/CategoriesConfirmModal'
import { hebrew } from '../../../../i18n'

const CategoriesSelector = ({
  closeSelector,
  updatePolicyCategory,
  updateAllRelatedPoliciesCategory,
  categoryId,
  categories,
  loading,
}) => {
  const { t } = useTranslation('customer')
  const [showCategoriesConfirmModal, setShowCategoriesConfirmModal] = useState(false)
  const [newCategoryId, setNewCategoryId] = useState(null)

  const setCategoryToUpdate = categoryId => {
    setNewCategoryId(categoryId)
    if (updateAllRelatedPoliciesCategory) {
      setShowCategoriesConfirmModal(true)
    } else {
      updatePolicyCategory(categoryId)
    }
  }

  const closeSelectorHandler = () => {
    setShowCategoriesConfirmModal(false)
    closeSelector()
  }

  return (
    <>
      <div className='modal-selection-wrapper' onClick={e => e.stopPropagation()}>
        <ModalWithSelection
          items={categories.map(c => ({ ...c, value: hebrew() ? c.name : c.englishName }))}
          currentItemId={categoryId}
          closeModal={closeSelectorHandler}
          onClickHandler={setCategoryToUpdate}
          loading={loading}
          showSearch={true}
          searchPlaceholder={t('searchCategory')}
        />
      </div>
      {showCategoriesConfirmModal && (
        <CategoriesConfirmModal
          categories={categories}
          closeModal={closeSelectorHandler}
          newCategoryId={newCategoryId}
          updateAllRelatedPoliciesCategory={() => updateAllRelatedPoliciesCategory(newCategoryId)}
          updatePolicyCategory={() => updatePolicyCategory(newCategoryId)}
          loading={loading}
          closeOnOutsideClick={false}
        />
      )}
    </>
  )
}

export default CategoriesSelector
