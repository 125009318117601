const CrossIconSvg = ({ fill = 'var(--text-primary-color)' }) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' width='24' height='24'>
    <g id='close'>
      <path
        id='Vector'
        fill={fill}
        d='M 18.2997 5.70997 C 17.9097 5.31997 17.2797 5.31997 16.8897 5.70997 L 11.9997 10.59 L 7.10973 5.69997 C 6.71973 5.30997 6.08973 5.30997 5.69973 5.69997 C 5.30973 6.08997 5.30973 6.71997 5.69973 7.10997 L 10.5897 12 L 5.69973 16.89 C 5.30973 17.28 5.30973 17.91 5.69973 18.3 C 6.08973 18.69 6.71973 18.69 7.10973 18.3 L 11.9997 13.41 L 16.8897 18.3 C 17.2797 18.69 17.9097 18.69 18.2997 18.3 C 18.6897 17.91 18.6897 17.28 18.2997 16.89 L 13.4097 12 L 18.2997 7.10997 C 18.6797 6.72997 18.6797 6.08997 18.2997 5.70997 Z'
      />
    </g>
  </svg>
)

export default CrossIconSvg
