import classNames from 'classnames'
import { useState } from 'react'
import './InputSelectAdvanced.scss'

const DEFAULT_ITEMS_TO_SHOW = 2

const InputSelectAdvanced = ({
  label = 'Options',
  items,
  selectedItems,
  selectItem,
  deselectItem,
  tabIndex = 0,
  required = false,
  selectAll,
  selectAllText,
  noItemsPlaceholder = 'Nothing Found',
  defaultItem,
}) => {
  const [showOptions, setShowOtions] = useState(false)

  const selectItemHandler = (id, e) => {
    selectItem(id)
  }

  const deselectItemHandler = (id, e) => {
    e.stopPropagation()
    deselectItem(id)
  }

  const selectAllHandler = e => {
    selectAll()
  }

  const isSelected = id => selectedItems.includes(id)

  return (
    <div className='input-select-advanced-container'>
      <div
        className={classNames('input-select-advanced-value-container', { 'has-values': selectedItems.length > 0 })}
        tabIndex={tabIndex}
        onBlur={e => {
          if (!e.currentTarget.contains(e.relatedTarget)) {
            setShowOtions(false)
          }
        }}
        title={items
          .filter(itm => isSelected(itm.id))
          .map(itm => itm.value)
          .join(', ')}
      >
        <div className={classNames('input-select-advanced-value')} onClick={() => setShowOtions(!showOptions)}>
          {selectedItems.length > 0 && (
            <div className='input-select-advanced-value-wrapper'>
              {items
                .filter(itm => isSelected(itm.id))
                .slice(0, DEFAULT_ITEMS_TO_SHOW)
                .map(itm => (
                  <div className='input-select-advanced-value-content' key={itm.id} title={itm.value}>
                    <div className='input-select-advanced-value-icon'>{itm.icon}</div>
                    <div className='input-select-advanced-value'>{itm.value}</div>
                    <div className='input-select-advanced-value-dlt'>
                      <button onClick={e => deselectItemHandler(itm.id, e)}>
                        <img src='./assets/close-icon-alt-sm/Close.png' alt='dlt' />
                      </button>
                    </div>
                  </div>
                ))}
              {selectedItems.length > DEFAULT_ITEMS_TO_SHOW && (
                <div>+{selectedItems.length - DEFAULT_ITEMS_TO_SHOW}</div>
              )}
            </div>
          )}
          <div className='input-select-advanced-value-arrow-wrapper'>
            <div className={classNames('input-select-advanced-value-arrow', { flipped: showOptions })}></div>
          </div>
          {label && (
            <div className='input-select-advanced-value-label'>
              {label}
              {required && '*'}
            </div>
          )}
          <div className='line'></div>
        </div>
        {showOptions && items.length === 0 && (
          <div className='input-select-advanced-options-wrapper' style={{ height: 'unset' }}>
            {noItemsPlaceholder}
            {defaultItem}
          </div>
        )}
        {showOptions && items.length > 0 && (
          <div className='input-select-advanced-options-wrapper'>
            <ul className='input-select-advanced-options-container'>
              {selectAll && (
                <li key={'all'} className={classNames('input-select-advanced-option')} onClick={selectAllHandler}>
                  <div className='input-select-advanced-option-btn'>
                    <img
                      src={`./assets/${
                        selectedItems.length === items.length
                          ? 'checkbox-sm-checked/Square.png'
                          : 'check-box-blank/Check Box Blank.png'
                      }`}
                      alt={`${selectedItems.length === items.length ? '-' : '+'}`}
                    />
                  </div>
                  <div>{selectAllText}</div>
                </li>
              )}
              {items.map(itm => (
                <li
                  key={itm.id}
                  className={classNames('input-select-advanced-option')}
                  onClick={e => selectItemHandler(itm.id, e)}
                >
                  <div className='input-select-advanced-option-btn'>
                    <img
                      src={`./assets/${
                        isSelected(itm.id) ? 'checkbox-sm-checked/Square.png' : 'check-box-blank/Check Box Blank.png'
                      }`}
                      alt={`${isSelected(itm.id) ? '-' : '+'}`}
                    />
                  </div>
                  <div>{itm.value}</div>
                </li>
              ))}
            </ul>
            {defaultItem}
          </div>
        )}
      </div>
    </div>
  )
}

export default InputSelectAdvanced
