export const customerData = [
    {
        id: "מתחת ל 1000",
        text: "מתחת ל 1000"
    },
    {
        id: "1000 - 2000",
        text: "1000 - 2000"
    },
    {
        id: "2000 - 3000",
        text: "2000 - 3000"
    },
    {
        id: "3000 - 4000",
        text: "3000 - 4000"
    },
    {
        id: "4000 - 5000",
        text: "4000 - 5000"
    },
    {
        id: "5000+",
        text: "5000+"
    },
]