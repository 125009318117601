import { useEffect, useRef, useState } from 'react'
import { hebrew } from '../../../../i18n'
import ReactApexChart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'
import { delay } from '../../../../services/delay'
import './AgencyHouseIncomesSplittedViewChart.scss'

const formatXaxis = chartId => {
  let chart = document.getElementById(`apexcharts${chartId}`)
  if (!chart) return
  let xAxisLabels = chart.getElementsByClassName('agency-house-analytics-incomes-chart-x-labels')
  if (!xAxisLabels) return
  for (let i = 0; i < xAxisLabels.length; i++) {
    let spans = xAxisLabels[i].childNodes
    if (!spans || spans.length < 6) return
    spans[1].style.fill = 'var(--text-primary-color)'
    spans[1].style.fontWeight = 500
    spans[3].style.fill = 'var(--trend-up-color)'
    spans[3].style.fontWeight = 500
    if (spans[4]?.textContent.includes('\u2191')) {
      spans[4].style.fill = 'var(--trend-up-color)'
    } else if (spans[4]?.textContent.includes('\u2193')) {
      spans[4].style.fill = 'var(--trend-down-color)'
    }
    if (spans[6]?.textContent.includes('\u2191')) {
      spans[6].style.fill = 'var(--trend-up-color)'
    } else if (spans[6]?.textContent.includes('\u2193')) {
      spans[6].style.fill = 'var(--trend-down-color)'
    }
    spans[4].style.fontSize = '12px'
    spans[4].setAttribute('fill-opacity', 0.7)
    spans[6].style.fontSize = '12px'
    spans[6].setAttribute('fill-opacity', 0.7)
    spans[2].removeAttribute('dy')
    spans[2].removeAttribute('x')
    spans[3].removeAttribute('dy')
    spans[3].removeAttribute('x')
    spans[5].removeAttribute('dy')
    spans[5].removeAttribute('x')
    spans[6].removeAttribute('dy')
    spans[6].removeAttribute('x')
  }
  return
}

const highLightBar = (chartId, dataPointIndex, highlightBothBars, suffix) => {
  let chart = document.getElementById(`apexcharts${chartId}`)
  if (!(chart && dataPointIndex !== null)) {
    return
  }
  let baseHighlight = chart.querySelector('.apexcharts-xcrosshairs')
  if (!baseHighlight) {
    return
  }
  baseHighlight.setAttribute('style', `opacity: 0`)
  const agentHighlight = baseHighlight.cloneNode(true)
  const agencyHighlight = baseHighlight.cloneNode(true)
  baseHighlight.after(agentHighlight)
  baseHighlight.after(agencyHighlight)

  if (highlightBothBars || suffix === '') {
    agentHighlight.classList.add('apexcharts-active')
  }
  if (highlightBothBars || suffix === 'Agency') {
    agencyHighlight.classList.add('apexcharts-active')
  }
  const basePosition = agentHighlight.getBoundingClientRect()
  let agentBarPosition
  let agencyBarPosition
  let bars = chart.getElementsByClassName('apexcharts-bar-area')
  if (!bars.length) {
    return
  }
  agentBarPosition = bars[dataPointIndex].getBoundingClientRect()
  agencyBarPosition = bars[dataPointIndex + bars.length / 2].getBoundingClientRect()
  agentHighlight.setAttribute('style', `transform: translateX(${agentBarPosition.x - basePosition.x + 1}px)`)
  agencyHighlight.setAttribute('style', `transform: translateX(${agencyBarPosition.x - basePosition.x + 1}px)`)
}

function addBarsMinHeight(minHeight) {
  let cols = document.getElementsByClassName('apexcharts-bar-area')
  for (let i = 0; i < cols.length; i++) {
    const seriesName = cols[i].parentElement.getAttribute('seriesName')
    if (parseFloat(cols[i].getAttribute('barHeight')) <= minHeight) {
      let barPath = cols[i].getAttribute('d')
      //split by commands
      const pathCommands = barPath.split(/(?=[MLCZ])/)

      const borderRadius = 3
      const delta = 4

      const leftPath = pathCommands[1].split(' ')
      const leftCorner = pathCommands[2].split(' ')
      const topPath = pathCommands[3].split(' ')
      const rightCorner = pathCommands[4].split(' ')

      leftPath[1] = +leftPath[1] - delta + borderRadius * 2
      leftCorner[1] = +leftCorner[1] - delta + borderRadius
      leftCorner[3] = +leftCorner[3] - delta
      leftCorner[5] = +leftCorner[5] - delta
      topPath[1] = +topPath[1] - delta
      rightCorner[1] = +rightCorner[1] - delta
      rightCorner[3] = +rightCorner[3] - delta + borderRadius
      rightCorner[5] = +rightCorner[5] - delta + borderRadius * 2

      pathCommands[1] = leftPath.join(' ')
      pathCommands[2] = leftCorner.join(' ')
      pathCommands[3] = topPath.join(' ')
      pathCommands[4] = rightCorner.join(' ')

      const color =
        seriesName === 'Agency'
          ? 'var(--trend-up-color)'
          : seriesName === 'Agents'
          ? 'var(--text-primary-color)'
          : 'red'

      cols[i].setAttribute('d', pathCommands.join(' '))
      cols[i].setAttribute('style', `fill: ${color}`)
    }
  }
}

const AgencyHouseIncomesSplittedViewChart = ({
  selectItem,
  chartId,
  list,
  property,
  totalsName,
  totalsValue,
  valuePrefix = '',
  valueSuffix = '',
  maximumFractionDigits = 2,
  minimumFractionDigits = 2,
  highlightBothBars,
}) => {
  const yAxisWidth = '45px'
  const [chartWidth, setChartWidth] = useState('100%')
  const chartContainerRef = useRef()
  const { t } = useTranslation('agencyHouseLobby')

  const series = [
    {
      name: 'Agents',
      data: list.map(item => item[property]),
    },
    {
      name: 'Agency',
      data: list.map(item => item[`${property}Agency`]),
    },
  ]
  // Chart options and configuration
  const options = {
    chart: {
      type: 'bar',
      id: chartId,
      parentHeightOffset: 0,
      toolbar: { show: false },
      zoom: { enabled: false },
      events: {
        mounted: (chart, options) => {
          //console.log('mounted')
          formatXaxis(chartId)
        },
        updated: async (chart, options) => {
          //console.log('updated')
          formatXaxis(chartId)
          await delay(300)
          addBarsMinHeight(4)
        },
        animationEnd: async function (chartContext, options) {
          //console.log('animationEnd')
          await delay(300)
          addBarsMinHeight(4)
        },
        xAxisLabelClick: function (event, chartContext, config) {
          chartContext.toggleDataPointSelection(0, config.labelIndex)
        },
        dataPointSelection: function (event, chartContext, config) {
          const suffix =
            config.selectedDataPoints[0]?.length > 0 ? '' : config.selectedDataPoints[1]?.length > 0 ? 'Agency' : null
          const itemToSelect =
            config?.selectedDataPoints[0]?.length > 0 || config?.selectedDataPoints[1]?.length > 0
              ? config.dataPointIndex
              : null
          selectItem(itemToSelect, suffix)
          if (itemToSelect !== null) {
            highLightBar(chartId, itemToSelect, highlightBothBars, suffix)
          }
        },
      },
    },
    colors: ['#ffffff', '#00c65e'], // Green for Agency, white for Agents
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '40%', // Controls bar width,
        distributed: false,
        borderRadius: 8,
        borderRadiusApplication: 'end',
        borderRadiusWhenStacked: 'last',
      },
    },
    dataLabels: {
      enabled: false, // Disabling the default data labels
    },
    xaxis: {
      categories: list.map(item => [
        hebrew() ? item.name : item.nameEn,
        item[property]
          ? `${valuePrefix}${Intl.NumberFormat('en', {
              notation: 'compact',
              maximumFractionDigits,
            }).format(item[property])}${valueSuffix}`
          : '--',
        '   ',
        item[`${property}Agency`]
          ? `${valuePrefix}${Intl.NumberFormat('en', {
              notation: 'compact',
              maximumFractionDigits,
            }).format(item[`${property}Agency`])}${valueSuffix}`
          : '--',
        item[`${property}Diff`]
          ? `${item[`${property}Diff`] > 0 ? '+' : '-'}${valuePrefix}${Intl.NumberFormat('en', {
              notation: 'compact',
              maximumFractionDigits,
            }).format(Math.abs(item[`${property}Diff`]))}${valueSuffix}${
              item[`${property}Diff`] > 0 ? '\u2191' : '\u2193'
            }`
          : '--',
        '   ',
        item[`${property}DiffAgency`]
          ? `${item[`${property}DiffAgency`] > 0 ? '+' : '-'}${valuePrefix}${Intl.NumberFormat('en', {
              notation: 'compact',
              maximumFractionDigits,
            }).format(Math.abs(item[`${property}DiffAgency`]))}${valueSuffix}${
              item[`${property}DiffAgency`] > 0 ? '\u2191' : '\u2193'
            }`
          : '--',
      ]),
      labels: {
        style: { cssClass: 'agency-house-analytics-incomes-chart-x-labels' },
        rotate: 0,
        show: true,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: true,
      showAlways: true,
      decimalsInFloat: 0,
      floating: true,
      tickAmount: 3,
      labels: {
        show: true,
        align: 'right',
        minWidth: Number(yAxisWidth.replace('px', '')),
        style: {
          colors: ['red', 'green'],
          fontSize: '12px',
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 400,
          cssClass: 'agency-house-analytics-incomes-chart-y-labels',
        },
        //offsetX: -13,
        offsetY: 0,
        rotate: 0,
        formatter: value => {
          return Intl.NumberFormat('en', { notation: 'compact' }).format(value)
        },
      },
      axisBorder: {
        show: true,
        color: 'var(--text-primary-color)',
        offsetX: 0,
        offsetY: 0,
      },
    },
    legend: {
      show: false,
      position: 'top',
      horizontalAlign: 'center',
      fontSize: '12px',
      fontFamily: 'Rubik',
      fontWeight: 500,
      labels: {
        colors: ['var(--text-primary-color)', 'var(--trend-up-color)'],
        useSeriesColors: false,
      },
      markers: {
        size: 12,
        shape: 'square',
        strokeWidth: 0,
        fillColors: undefined,
        onClick: undefined,
        offsetX: -5,
        offsetY: 0,
      },
      onItemClick: {
        toggleDataSeries: false,
      },
    },
    grid: {
      show: true,
      showAlways: true,
      strokeDashArray: 1,
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 10,
      },
    },
    tooltip: {
      enabled: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return `<div class="tooltop-custom-box"><span> 
        ${valuePrefix}${Intl.NumberFormat('en', {
          maximumFractionDigits,
          minimumFractionDigits,
        }).format(series[seriesIndex][dataPointIndex])}${valueSuffix}
          </span></div>`
      },
    },
  }

  useEffect(() => {
    if (chartContainerRef.current) {
      const barContainerWidth = 115 // px
      const chartEvaluatedWidth = list.length * barContainerWidth
      if (chartEvaluatedWidth > chartContainerRef.current.clientWidth) setChartWidth(chartEvaluatedWidth)
    }
  }, [list])

  return (
    <div className='agency-house-analytics-incomes-splitted-chart-container'>
      <header>
        <p>{totalsName}</p>
        <p>{totalsValue}</p>
      </header>
      <div className='agency-house-analytics-incomes-chart-axis-wrapper'>
        <div className='agency-house-analytics-incomes-chart-legend-wrapper'>
          <div>
            <span></span>
            <p>{t('totals.agentPrefix')}</p>
          </div>
          <div>
            <span></span>
            <p>{t('totals.agencyPrefix')}</p>
          </div>
        </div>
        <div className='agency-house-analytics-incomes-chart-wrapper' ref={chartContainerRef}>
          <div className='agency-house-analytics-incomes-chart'>
            <ReactApexChart
              options={{
                ...options,
              }}
              series={series}
              type='bar'
              height={'400px'}
              width={chartWidth}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AgencyHouseIncomesSplittedViewChart
