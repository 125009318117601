import { useState, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { agencyExistType } from '../../../../types/agencyTypes'
import ConfirmDialog from '../../../../components/ConfirmDialog/ConfirmDialog'
import './AddAgencyTypeSelector.scss'
import classNames from 'classnames'
import CheckCircleIconSvg from '../../../../components/Common/SvgIcons/CheckCircleIconSvg/CheckCircleIconSvg'

const AddAgencyTypeSelector = ({ setAgencyExistType, type }) => {
  const { t } = useTranslation('agencyHouse')
  const [showWarnDialog, setShowWarnDialog] = useState(false)
  const [userInformed, setUserInformed] = useState(false)
  const [selectedType, setSelectedType] = useState(type)

  const cardOptions = [
    {
      type: agencyExistType.new,
      title: t('addAgencyStep.newAgencyOption.title'),
      desc: t('addAgencyStep.newAgencyOption.desc'),
      remark: t('addAgencyStep.newAgencyOption.remark'),
      ico: './assets/_agencies/subagencies-types/new.png',
    },
    {
      type: agencyExistType.existing,
      title: t('addAgencyStep.existingAgencyOption.title'),
      desc: t('addAgencyStep.existingAgencyOption.desc'),
      ico: './assets/_agencies/subagencies-types/existing.png',
    },
  ]

  const selectAgencyExistTypeHandler = () => {
    if (!userInformed && selectedType === agencyExistType.new) {
      setShowWarnDialog(true)
    } else {
      setAgencyExistType(selectedType)
    }
  }

  return (
    <>
      <div className='add-agency-type-selector'>
        <h4>{t('addAgencyStep.agencyType')}</h4>
        <div className='add-agency-type-cards'>
          {cardOptions.map(c => (
            <div
              key={c.type}
              className={classNames('add-agency-type-card', { 'card-selected': selectedType === c.type })}
              onClick={() => {
                setSelectedType(c.type)
              }}
            >
              <div>
                <img src={c.ico} alt={c.type} />
              </div>
              {selectedType === c.type && (
                <div>
                  <CheckCircleIconSvg />
                </div>
              )}
              <div>
                <h5>{c.title}</h5>
                <p>{c.desc}</p>
              </div>
              <p>{c.remark}</p>
            </div>
          ))}
        </div>
        <button onClick={selectAgencyExistTypeHandler} disabled={!selectedType}>
          {t('addAgencyStep.agencyTypeSelectBtn')}
        </button>
      </div>
      {showWarnDialog && (
        <ConfirmDialog
          confirm={() => {
            setUserInformed(true)
            setAgencyExistType(selectedType)
          }}
          decline={() => {
            setShowWarnDialog(false)
          }}
          title={t('addAgencyStep.warnTitle')}
          text={t('addAgencyStep.warnText')}
          confirmText={t('addAgencyStep.warnOkBtn')}
          declineText={t('addAgencyStep.warnCancelBtn')}
        />
      )}
    </>
  )
}

export default AddAgencyTypeSelector
