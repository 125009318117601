import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../../components/Common/Button/Button'
import Loader from '../../../../components/Common/Loader/Loader'
import CheckMark from '../../../../components/Common/Marks/CheckMark/CheckMark'
import ModalWindow from '../../../../components/ModalWindow/ModalWindow'
import classNames from 'classnames'
import { CUSTOMER_ALL_CATEGORIES_RESET } from '../../../../types/actionTypes'
import { getCustomerCategories } from '../../actions/customerActions'
import './CustomerAllCategoriesModal.scss'

const CustomerAllCategoriesModal = ({ closeModal, customerId = null }) => {
  const { t, i18n } = useTranslation('customer')
  const dispatch = useDispatch()
  const { usedCategories, unusedCategories, categoriesLoading } = useSelector(state => state.customer)

  const title = t('allCategoriesModal.title')
  const usedCategoriesTitle = t('allCategoriesModal.usedCategories')
  const unusedCategoriesTitle = t('allCategoriesModal.unusedCategories')
  const btnCaption = t('allCategoriesModal.confirmBtn')

  const buildCategoriesList = (categories, used = true) => (
    <div className='customer-categories-list'>
      {categories.map(c => (
        <div className='customer-categories-list-item' key={c.id}>
          {used && (
            <div className='customer-categories-list-item-check'>
              <CheckMark green />
            </div>
          )}
          <div className={classNames('customer-categories-list-item-text', { used })}>
            {i18n.language === 'he' ? c.name : c.englishName}
          </div>
          <div className='customer-categories-list-item-note'>
            {used ? (
              <div
                className='customer-categories-list-item-members'
                {...(c.groupMembers.length > 3 && {
                  title: c.groupMembers
                    .filter((m, i) => i >= 3)
                    .map(m => m.value)
                    .join(', '),
                })}
              >
                {c.groupMembers
                  .filter((m, i) => i < 3)
                  .map(m => m.value)
                  .join(', ')}{' '}
                {c.groupMembers.length > 3 ? '...' : ''}
              </div>
            ) : (
              <div>
                <i className='fas fa-lock fa-sm'></i>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  )

  useEffect(() => {
    dispatch(getCustomerCategories(customerId))
    return () => dispatch({ type: CUSTOMER_ALL_CATEGORIES_RESET })
  }, [dispatch, customerId])

  return (
    <>
      <ModalWindow closeModal={closeModal} closeOnOutsideClick={false}>
        <div className='customer-categories-modal-container'>
          <div className='customer-categories-modal-content'>
            <div className='customer-categories-modal-title'>{title}</div>
            {!categoriesLoading ? (
              <div className='customer-categories-lists-container'>
                {usedCategories.length > 0 && (
                  <div className='customer-categories-list-container'>
                    <div className='customer-categories-list-title'>{usedCategoriesTitle}</div>
                    {buildCategoriesList(usedCategories, true)}
                  </div>
                )}
                {unusedCategories.length > 0 && (
                  <div className='customer-categories-list-container'>
                    <div className='customer-categories-list-title'>{unusedCategoriesTitle}</div>
                    {buildCategoriesList(unusedCategories, false)}
                  </div>
                )}
              </div>
            ) : (
              <Loader />
            )}
          </div>
          <div className='customer-categories-modal-footer'>
            <Button caption={btnCaption} onClick={closeModal} />
          </div>
        </div>
      </ModalWindow>
    </>
  )
}

export default CustomerAllCategoriesModal
