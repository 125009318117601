import { useState, useEffect } from 'react'
import { DeleteTwoTone } from '@mui/icons-material'
import ButtonRounded from '../../../../components/Common/ButtonRounded/ButtonRounded'
import ButtonText from '../../../../components/Common/ButtonText/ButtonText'
import AgencyHouseDetailsForm from '../AgencyHouseDetailsForm/AgencyHouseDetailsForm'
import { useTranslation } from 'react-i18next'
import { delay } from '../../../../services/delay'
import { getRandomInt } from '../../../../services/getRandom'
import { agencyType } from '../../../../types/agencyTypes'
import Loader from '../../../../components/Common/Loader/Loader'
import ConfirmDeleteDialog from '../../../../components/ConfirmDeleteDialog/ConfirmDeleteDialog'
import { useDispatch } from 'react-redux'
import { logout } from '../../../login/actions/loginActions'
import {
  getAgencyHouseDetailsRequest,
  updateAgencyHouseDetailsRequest,
} from '../../../../http/requests/agencyHouseRequests'
import { LOGIN_SET_INFO } from '../../../../types/actionTypes'
import BigAgencyPaymentFrameModal from '../../../../components/BigAgencyPaymentFrameModal/BigAgencyPaymentFrameModal'
import './AgencyHouseDetails.scss'

const AgencyHouseDetails = () => {
  const [agency, setAgency] = useState({ agencyId: '', agencyName: '', agentEmail: '', type: null })
  const [agencyLoading, setAgencyLoading] = useState(true)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [serverError, setServerError] = useState(null)
  const [formIsDirty, setFormIsDirty] = useState(false)
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [showUpdateCard, setShowUpdateCard] = useState(false)

  const dispatch = useDispatch()
  const { t } = useTranslation('agencyHouse')

  const onChangeHandler = dataType => value => {
    setServerError(null)
    setFormIsDirty(true)
    setAgency(prev => ({
      ...prev,
      [dataType]: value,
    }))
  }

  const setAgencyTypeHandler = ({ target: { value } }) => {
    setFormIsDirty(true)
    setAgency(prev => ({
      ...prev,
      type: +value,
    }))
  }

  const formIsValid = () =>
    agency.agencyId?.length > 0 && agency.agencyName?.length > 0 && agency.agentEmail?.length > 0

  const submitBtnDisabled = () => submitLoading || !formIsValid() || !formIsDirty

  const submitHandler = async e => {
    e.preventDefault()
    const emailRegex = new RegExp(/^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/, 'gm')
    if (!emailRegex.test(agency.agentEmail)) {
      setServerError('Invalid email format')
      return
    }
    try {
      setServerError(null)
      setSubmitLoading(true)
      const { data } = await updateAgencyHouseDetailsRequest({
        AgencyRegistrationNumber: agency.agencyId,
        AgencyName: agency.agencyName,
        AgentEmail: agency.agentEmail,
        //AgencyType: agency.type,
      })
      if (!data.HasError) {
        dispatch({
          type: LOGIN_SET_INFO,
          payload: {
            agencyName: agency.agencyName,
            currentAgency: agency.agencyName,
            AgencyType: agency.type,
            UserName: agency.agencyName,
          },
        })
      } else {
        setServerError(data.ErrorMessage ?? 'Server Error')
      }
      setSubmitLoading(false)
    } catch (error) {
      setSubmitLoading(false)
      setServerError('Server Error')
    }
  }

  const deleteAgencyHandler = async () => {
    try {
      console.log('Deleting agency')
      setShowConfirmDelete(false)
      setAgencyLoading(true)
      await delay(500, 1500)
      console.log('Agency deleted')
      setAgencyLoading(false)
      dispatch(logout())
    } catch (error) {
      setAgencyLoading(false)
      setServerError('Server Error')
    }
  }

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setAgencyLoading(true)
        const {
          data: { Data },
        } = await getAgencyHouseDetailsRequest()
        setAgency({
          agencyId: Data.AgencyRegistrationNumber,
          agencyName: Data.AgencyName,
          agentEmail: Data.AgentEmail,
          type: Data.AgencyType,
        })
        setAgencyLoading(false)
      } catch (error) {
        setAgencyLoading(false)
        console.log(`%c${error}`, 'background: blue; color: red; padding-inline: 6px;')
      }
    }
    fetchDetails()
  }, [])

  if (agencyLoading) {
    return <Loader />
  }

  return (
    <div className='agency-house-details-container'>
      <div>
        <AgencyHouseDetailsForm
          {...agency}
          setAgencyId={onChangeHandler('agencyId')}
          setAgencyName={onChangeHandler('agencyName')}
          setAgentEmail={onChangeHandler('agentEmail')}
          setAgencyType={setAgencyTypeHandler}
          inputsDisabled={submitLoading}
          serverError={serverError}
        />
        {/* <ButtonText styles={'danger'} onClick={() => setShowConfirmDelete(true)}>
          <DeleteTwoTone />
          <span>{t('deleteUserBtn')}</span>
        </ButtonText> */}
      </div>
      <ButtonRounded onClick={() => setShowUpdateCard(true)}>{t('payments.updateCard')}</ButtonRounded>

      <ButtonRounded onClick={submitHandler} disabled={submitBtnDisabled()}>
        {t('saveDetailsBtn')}
      </ButtonRounded>
      {showConfirmDelete && (
        <ConfirmDeleteDialog
          deleteHandler={deleteAgencyHandler}
          cancelHandler={() => setShowConfirmDelete(false)}
          deleteText={t('deleteConfirmModal.yesBtn')}
          cancelText={t('deleteConfirmModal.noBtn')}
          text={t('deleteConfirmModal.text')}
          title={t('deleteConfirmModal.title')}
        />
      )}
      {showUpdateCard && (
        <BigAgencyPaymentFrameModal
          closeModal={() => setShowUpdateCard(false)}
          onPaymentSuccess={() => setShowUpdateCard(false)}
        />
      )}
    </div>
  )
}

export default AgencyHouseDetails
