import PoliciesDatePicker from '../PoliciesDatePicker/PoliciesDatePicker'
import { useTranslation } from 'react-i18next'
import './PoliciesDateRange.scss'

const PoliciesDateRange = ({ view, from, to, setFrom, setTo, initFrom, initTo }) => {
  const { t } = useTranslation('policies')

  const placeholder = t(`placeholders.select${view === 'year' ? 'Month' : 'Date'}`)
  return (
    <div className='policies-date-range'>
      <PoliciesDatePicker date={from} setDate={setFrom} view={view} placeholder={placeholder} initDate={initFrom} />
      <span>-</span>
      <PoliciesDatePicker date={to} setDate={setTo} view={view} placeholder={placeholder} initDate={initTo} />
    </div>
  )
}

export default PoliciesDateRange
