import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import './ProfileCompanyItem.scss'
import { CheckBox, CheckBoxOutlineBlankOutlined, DeleteTwoTone, EditTwoTone } from '@mui/icons-material'
import { hebrew } from '../../../../i18n'
import { companyConnectionType } from '../../../../types/companies'

const ProfileCompanyItem = ({ company, removeCompany, editCompany, selectCompany, selected }) => {
  const { t, i18n } = useTranslation('profile')
  return (
    <>
      <div className={classNames('profile-company-item profile-company-item-grid', { selected })}>
        {/* <button onClick={selectCompany} className={classNames({ selected })}>
          {selected ? <CheckBox /> : <CheckBoxOutlineBlankOutlined />}
        </button> */}
        <div className='profile-company-info'>
          <img src={`./assets/companies-logos-light/${company.id}.png`} alt={company.id} />
          <p>{hebrew() ? company.name : company.nameEn}</p>
        </div>
        <p className='profile-company-fields'>
          {company.fields.map(f => (hebrew() ? f.name : f.engilshName)).join(' • ')}
        </p>
        <div className='profile-company-site' title={company.website}>
          <a href={company.website} target='_blank' rel='noopener noreferrer'>
            {company.website.replace('https://', '').replace('http://', '').replace('www.', '').split('/')[0]}
          </a>
        </div>
        <div className='profile-company-actions'>
          {company.connectionType === companyConnectionType.email && (
            <button onClick={editCompany}>
              <EditTwoTone />
            </button>
          )}
          <button onClick={removeCompany}>
            <DeleteTwoTone />
          </button>
        </div>
      </div>
    </>
  )
}

export default ProfileCompanyItem
