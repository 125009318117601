import { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import BottomSheetBase from '../../../../components/Common/BottomSheetBase/BottomSheetBase'
import Button from '../../../../components/Common/Button/Button'
import InputSearch from '../../../../components/Common/InputSearch/InputSearch'
import InputSelect from '../../../../components/Common/InputSelect/InputSelect'
import InputText from '../../../../components/Common/InputText/InputText'
import InputTextArea from '../../../../components/Common/InputTextArea/InputTextArea'
import Loader from '../../../../components/Common/Loader/Loader'
import NoData from '../../../../components/Common/NoData/NoData'
import ModalWindow from '../../../../components/ModalWindow/ModalWindow'
import useClickOutside from '../../../../hooks/useClickOutside'
import useDebounce from '../../../../hooks/useDebounce'
import { getTasksCustomersRequest, getTasksPoliciesRequest } from '../../../../http/requests'
import { hebrew } from '../../../../i18n'
import { customerTask, customerTaskType } from '../../../../types/customerTaskTypes'
import { getAgents } from '../../../shared/actions/sharedActions'
import { getTasks } from '../../actions/workstationActions'
import MobileModalBaseScreen from '../MobileModalBaseScreen/MobileModalBaseScreen'
import { addTreatmentRequest } from '../../../../http/requests/treatmentRequests'
import './AddTaskModal.scss'

const AddTaskModal = ({ closeModal }) => {
  const { t } = useTranslation('workstation')
  const dispatch = useDispatch()
  const { agents } = useSelector(({ shared }) => shared)
  const types = Object.entries(customerTask)
    .filter(([_, v]) => !v.addDisabled)
    .map(([k, v]) => ({ id: k, value: k, text: hebrew() ? v.text() : v.textEn() }))
  const [task, setTask] = useState({
    agentId: null,
    policyId: null,
    irregularityId: null,
    customerId: null,
    customerName: '',
    policyNumberCustomerName: '',
    type: null,
    note: null,
  })
  const debouncedCustomerName = useDebounce(task.customerName)
  const debouncedpolicyNumberCustomerName = useDebounce(task.policyNumberCustomerName)

  const [customers, setCustomers] = useState(null)
  const [customersLoading, setCustomersLoading] = useState(false)
  const [policies, setPolicies] = useState(null)
  const [policiesLoading, setPoliciesLoading] = useState(false)
  const [error, setError] = useState(null)
  const [addLoading, setAddLoading] = useState(false)
  const [showBottomSearch, setShowBottomSearch] = useState(false)

  const customerRelatedTask = type =>
    +type === customerTaskType.appointment ||
    +type === customerTaskType.examineProducts ||
    +type === customerTaskType.externalCommission ||
    +type === customerTaskType.groupAffiliation ||
    +type === customerTaskType.customer

  const policyRelatedTask = type => +task.type === customerTaskType.policy

  const onChangeHandler = key => val => {
    if (key === 'customerName' || key === 'policyNumberCustomerName') {
      clearSearch()
      setTask(prev => {
        return {
          ...prev,
          [key]: val,
          policyId: null,
          customerId: null,
        }
      })
      return
    }
    if (key === 'type') {
      clearSearch()
      if (
        customerRelatedTask(+task.type) !== customerRelatedTask(+val) ||
        policyRelatedTask(+task.type) !== policyRelatedTask(+val)
      ) {
        setTask(prev => {
          return {
            ...prev,
            [key]: val,
            policyId: null,
            customerId: null,
            customerName: '',
            policyNumberCustomerName: '',
          }
        })
        return
      }
    }
    setTask(prev => {
      return { ...prev, [key]: val }
    })
  }

  const setTaskCustomer = (customerId, customerName) => {
    setTask(prev => ({ ...prev, customerId, customerName }))
    clearSearch()
    if (showBottomSearch) setShowBottomSearch(false)
  }

  const setTaskPolicy = (policyId, policyNumberCustomerName, customerId) => {
    setTask(prev => ({ ...prev, policyId, policyNumberCustomerName, customerId }))
    clearSearch()
    if (showBottomSearch) setShowBottomSearch(false)
  }

  const submitHandler = async e => {
    e.preventDefault()
    try {
      setAddLoading(true)
      const {
        data: {
          data: { success },
        },
      } = await addTreatmentRequest(task)
      if (success) {
        dispatch(getTasks())
        closeModal()
      }
    } catch (error) {
      setError('Got error')
    } finally {
      setAddLoading(false)
    }
  }

  const submitDisabled = () => {
    if (addLoading || !task.agentId || !task.type) return true
    if (customerRelatedTask(task.type) && !task.customerId) return true
    if (policyRelatedTask(task.type) && !task.policyId) return true
    return false
  }

  useEffect(() => {
    dispatch(getAgents())
  }, [dispatch])

  useEffect(() => {
    const fetchCustomers = async () => {
      if (debouncedCustomerName.trim().length > 0 && !task.customerId) {
        try {
          setCustomers(null)
          setPolicies(null)
          setCustomersLoading(true)
          const {
            data: { data },
          } = await getTasksCustomersRequest(debouncedCustomerName.trim())
          setCustomers(data)
        } catch (error) {
          setError(error)
        } finally {
          setCustomersLoading(false)
        }
      }
    }
    fetchCustomers()
  }, [debouncedCustomerName])

  useEffect(() => {
    const fetchPolicies = async () => {
      if (debouncedpolicyNumberCustomerName.trim().length > 0 && !task.policyId) {
        try {
          setCustomers(null)
          setPolicies(null)
          setPoliciesLoading(true)
          const {
            data: { data },
          } = await getTasksPoliciesRequest(debouncedpolicyNumberCustomerName.trim())
          setPolicies(data)
        } catch (error) {
          setError(error)
        } finally {
          setPoliciesLoading(false)
        }
      }
    }
    fetchPolicies()
  }, [debouncedpolicyNumberCustomerName])

  const ref = useClickOutside(() => {
    if ((customerRelatedTask(task.type) && !task.customerId) || (policyRelatedTask(task.type) && !task.policyId)) {
      clearSearch()
      setTask(prev => ({ ...prev, customerName: '', policyNumberCustomerName: '', customerId: null, policyId: null }))
    }
  })

  const clearSearch = () => {
    setCustomers(null)
    setCustomersLoading(false)
    setPolicies(null)
    setPoliciesLoading(false)
  }

  const renderBottomSearch = () => (
    <BottomSheetBase
      open={showBottomSearch}
      closeOnOutsideClick
      closeSheet={() => {
        if ((customerRelatedTask(task.type) && !task.customerId) || (policyRelatedTask(task.type) && !task.policyId)) {
          clearSearch()
          setTask(prev => ({
            ...prev,
            customerName: '',
            policyNumberCustomerName: '',
            customerId: null,
            policyId: null,
          }))
        }
        setShowBottomSearch(false)
      }}
      sheetTitle={t(`addTaskModal.${policyRelatedTask(task.type) ? 'policyNumber' : 'customerName'}Placeholder`)}
    >
      <div className='mobile-bottom-search-container'>
        <div className='mobile-bottom-search-input-container'>
          <InputSearch
            autofocus
            placeholder='חיפוש'
            searchHandler={onChangeHandler(`${policyRelatedTask(task.type) ? 'policyNumberC' : 'c'}ustomerName`)}
          />
        </div>
        <div className='mobile-bottom-search-results-container'>
          {(customersLoading || customers) && (
            <div className='add-tasks-serach-results'>
              {customersLoading ? (
                <Loader />
              ) : customers.length === 0 ? (
                <NoData text='Nothing Found' />
              ) : (
                <ul>
                  {customers.map(c => (
                    <li
                      key={c.id}
                      onClick={() => {
                        setTaskCustomer(c.id, c.name)
                        setShowBottomSearch(false)
                      }}
                    >
                      <div>
                        <div className='add-tasks-serach-results-name'>{c.name.trim()}</div>
                        <div className='add-tasks-serach-results-main'>
                          <div className='add-tasks-serach-results-number'>{c.unHashedIdNumber}</div>
                          <div className='add-tasks-serach-results-category'></div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}
          {(policiesLoading || policies) && (
            <div className='add-tasks-serach-results'>
              {policiesLoading ? (
                <Loader />
              ) : policies.length === 0 ? (
                <NoData text='Nothing Found' />
              ) : (
                <ul>
                  {policies.map(p => (
                    <li
                      key={p.id}
                      onClick={() => {
                        setTaskPolicy(p.id, p.policyNumber, p.clientId)
                        setShowBottomSearch(false)
                      }}
                    >
                      <div>
                        <div className='add-tasks-serach-results-name'>{p.clientName ? p.clientName.trim() : '-'}</div>
                        <div className='add-tasks-serach-results-main'>
                          <div className='add-tasks-serach-results-number'>{p.policyNumber}</div>
                          <div className='add-tasks-serach-results-category'>({p.categoryName})</div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}
        </div>
      </div>
    </BottomSheetBase>
  )

  const renderModalContent = () => (
    <div className='add-task-modal-container'>
      <div className='add-task-modal-header'>
        <div>{t('addTaskModal.title')}</div>
        <div>
          <button onClick={closeModal}>
            <img src='./assets/close-icon-sm/Close.png' alt='close' />
          </button>
        </div>
      </div>
      <div className='add-task-modal-form-container'>
        <form onSubmit={submitHandler}>
          <div className='add-task-modal-form-inputs'>
            <div className='add-task-modal-form-inputs-grid'>
              <InputSelect
                values={types}
                label={t('addTaskModal.typePlaceholder')}
                onChange={onChangeHandler('type')}
                selectedValue={task.type}
                showLabel={true}
              />
              {((isMobile && (policyRelatedTask(task.type) || customerRelatedTask(task.type))) || !isMobile) && (
                <div className='add-tasks-serach-container' ref={ref}>
                  {policyRelatedTask(task.type) || customerRelatedTask(task.type) ? (
                    <InputText
                      label={t(
                        `addTaskModal.${policyRelatedTask(task.type) ? 'policyNumber' : 'customerName'}Placeholder`
                      )}
                      onChange={onChangeHandler(`${policyRelatedTask(task.type) ? 'policyNumberC' : 'c'}ustomerName`)}
                      value={policyRelatedTask(task.type) ? task.policyNumberCustomerName : task.customerName}
                    />
                  ) : (
                    <div></div>
                  )}
                  {(customersLoading || customers) && !isMobile && (
                    <div className='add-tasks-serach-results'>
                      {customersLoading ? (
                        <Loader />
                      ) : customers.length === 0 ? (
                        <NoData text='Nothing Found' />
                      ) : (
                        <ul>
                          {customers.map(c => (
                            <li key={c.id} onClick={() => setTaskCustomer(c.id, c.name)}>
                              <div>
                                <div className='add-tasks-serach-results-name'>{c.name.trim()}</div>
                                <div className='add-tasks-serach-results-main'>
                                  <div className='add-tasks-serach-results-number'>{c.unHashedIdNumber}</div>
                                  <div className='add-tasks-serach-results-category'></div>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  )}
                  {(policiesLoading || policies) && !isMobile && (
                    <div className='add-tasks-serach-results'>
                      {policiesLoading ? (
                        <Loader />
                      ) : policies.length === 0 ? (
                        <NoData text='Nothing Found' />
                      ) : (
                        <ul>
                          {policies.map(p => (
                            <li key={p.id} onClick={() => setTaskPolicy(p.id, p.policyNumber, p.clientId)}>
                              <div>
                                <div className='add-tasks-serach-results-name'>
                                  {p.clientName ? p.clientName.trim() : '-'}
                                </div>
                                <div className='add-tasks-serach-results-main'>
                                  <div className='add-tasks-serach-results-number'>{p.policyNumber}</div>
                                  <div className='add-tasks-serach-results-category'>({p.categoryName})</div>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  )}
                  {isMobile && (
                    <div
                      className='hidden-oopen-search-button'
                      onClick={() => {
                        setShowBottomSearch(true)
                      }}
                    >
                      Show bottom Search
                    </div>
                  )}
                </div>
              )}
              {showBottomSearch && renderBottomSearch()}
              <InputSelect
                values={agents.map(({ name, id }) => ({ id, value: id, text: name }))}
                label={t('addTaskModal.agentPlaceholder')}
                onChange={onChangeHandler('agentId')}
                selectedValue={task.agentId}
                showLabel={true}
              />
            </div>
            <InputTextArea rows={3} label={t('addTaskModal.notePlaceholder')} onChange={onChangeHandler('note')} />
          </div>
          {error && <div style={{ color: 'red' }}>{error}</div>}
          <div className='add-task-modal-form-footer'>
            <Button caption={t('addTaskModal.createTask')} disabled={submitDisabled()} />
          </div>
        </form>
      </div>
    </div>
  )

  return (
    <>
      {!isMobile && (
        <ModalWindow renderPortal closeModal={closeModal} closeOnOutsideClick={false} showCloseIcon={false}>
          {renderModalContent()}
        </ModalWindow>
      )}
      {isMobile && (
        <MobileModalBaseScreen open goBack={closeModal} modalTitle={t('addTaskModal.title')}>
          {renderModalContent()}
        </MobileModalBaseScreen>
      )}
    </>
  )
}

export default AddTaskModal
