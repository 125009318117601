import classNames from 'classnames'
import './InputTextArea.scss'

const InputTextArea = ({
  placeholder = ' ',
  label,
  value,
  onChange = () => {},
  required = false,
  rows = 7,
  id,
  inputName,
  disabled = false,
}) => {
  const changeHanlder = e => {
    onChange(e.target.value)
  }
  if (!id) {
    id = Date.now()
  }
  return (
    <div className='input-text-area-wrapper'>
      <div className={classNames('input-text-area-container', { disabled })}>
        <textarea
          id={id}
          placeholder={placeholder}
          value={value}
          onChange={changeHanlder}
          rows={rows}
          name={inputName}
          disabled={disabled}
        />
        {label && (
          <label htmlFor={id}>
            {label}
            {required && '*'}
          </label>
        )}
        <span className='line'></span>
      </div>
    </div>
  )
}

export default InputTextArea
