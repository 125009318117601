import Tooltip from '../../Common/Tooltip/Tooltip'
import { useTranslation } from 'react-i18next'
import { NavLink, useLocation } from 'react-router-dom'
import './Navbar.scss'
import { useSelector } from 'react-redux'
import { agencyUserRoleType, userPreferenceType } from '../../../types/agencyUserTypes'
import { agencyPoliciesSubroute, agencyRoutes } from '../../../environment/urls'
import { customersListType, policiesListType } from '../../../types/tablesTypes'
import AgencyHouseAnalyticsNavbar from '../../../features/agencyHouseAnalytics/components/AgencyHouseAnalyticsNavbar/AgencyHouseAnalyticsNavbar'

const Navbar = () => {
  const { preferences } = useSelector(({ login }) => login)
  const { t } = useTranslation('common')
  const location = useLocation()

  const navItems = [
    {
      path: '/lobby',
      icon: `/home${location.pathname === '/lobby' ? '-active' : ''}/Home.png`,
      tooltip: t('sidebar.lobby'),
      allowedRoles: [],
      requiredPreferences: [userPreferenceType.lobby],
    },
    {
      path: '/workstation',
      icon: `/workstation${location.pathname === '/workstation' ? '-active/Category.png' : '/Category.svg'}`,
      tooltip: t('sidebar.tasks'),
      allowedRoles: [
        agencyUserRoleType.administrator,
        agencyUserRoleType.fullUserAccess,
        agencyUserRoleType.agent,
        agencyUserRoleType.watchOnly,
      ],
      requiredPreferences: [],
    },
    {
      path: '/analytics',
      icon: `/analytics${location.pathname.includes('/analytics') ? '-active' : ''}/Activity.png`,
      tooltip: t('sidebar.analytics'),
      allowedRoles: [agencyUserRoleType.fullUserAccess, agencyUserRoleType.agent, agencyUserRoleType.watchOnly],
      requiredPreferences: [userPreferenceType.analytics],
      hiddenItems: <AgencyHouseAnalyticsNavbar />,
    },
    {
      path: `/${agencyRoutes.customers}?type=${customersListType.rating}`,
      icon: `/3-users-sm${
        location.pathname.includes('/customers') && !location.pathname.includes('/analytics') ? '-active' : ''
      }/3 User.png`,
      tooltip: t('sidebar.customers'),
      allowedRoles: [agencyUserRoleType.fullUserAccess, agencyUserRoleType.agent, agencyUserRoleType.watchOnly],
      requiredPreferences: [],
      submenu: [
        {
          path: `/${agencyRoutes.customers}?type=${customersListType.rating}`,
          icon: 'customers-total.png',
          text: t('sidebar.customersAll'),
        },
        {
          path: `/${agencyRoutes.customers}?type=${customersListType.distribution}`,
          icon: 'customers-distribution.png',
          text: t('sidebar.customersDistribution'),
        },
        {
          path: `/${agencyRoutes.customers}?type=${customersListType.groups}`,
          icon: 'customers-groups.png',
          text: t('sidebar.customersGroups'),
        },
      ],
    },
    {
      path: `/${agencyRoutes.policies}?type=${policiesListType.all}`,
      icon: `/report${
        location.pathname.includes('/policies') && !location.pathname.includes('/analytics') ? '-active' : ''
      }/Text.png`,
      tooltip: t('sidebar.policies'),
      allowedRoles: [agencyUserRoleType.fullUserAccess, agencyUserRoleType.agent, agencyUserRoleType.watchOnly],
      requiredPreferences: [],
      submenu: [
        {
          path: `/${agencyRoutes.policies}${agencyPoliciesSubroute.all}`,
          icon: 'policies-total.png',
          text: t('sidebar.policiesAll'),
        },
        {
          path: `/${agencyRoutes.policies}${agencyPoliciesSubroute.signals}`,
          icon: 'policies-signals.png',
          text: t('sidebar.policiesSignals'),
        },
        {
          path: `/${agencyRoutes.policies}${agencyPoliciesSubroute.new}`,
          icon: 'policies-new.png',
          text: t('sidebar.policiesNew'),
        },
        {
          path: `/${agencyRoutes.policies}${agencyPoliciesSubroute.lost}`,
          icon: 'policies-lost.png',
          text: t('sidebar.policiesLost'),
        },
        {
          path: `/${agencyRoutes.policies}${agencyPoliciesSubroute.types}`,
          icon: 'policies-types.png',
          text: t('sidebar.policiesTypes'),
        },
        // {
        //   path: `/${agencyRoutes.policies}?type=${policiesListType.inactive}`,
        //   icon: 'policies-inactive.png',
        //   text: t('sidebar.policiesInactive'),
        // },
      ],
    },
  ]

  return (
    <nav className='navigation'>
      <ul>
        {navItems
          .filter(
            itm => itm.requiredPreferences.length === 0 || itm.requiredPreferences?.some(p => preferences.includes(p))
          )
          .map(navItem => {
            return (
              <li key={navItem.path + navItem.icon}>
                <NavLink
                  to={navItem.path}
                  activeClassName='active'
                  isActive={() => navItem.path.split(/[/?]+/)[1].includes(location.pathname.split(/[/?]+/)[1])}
                >
                  <div className='icon-nav'>
                    <img src={`./assets${navItem.icon}`} alt='' className='img-icon' />
                    <div>{navItem.tooltip}</div>
                  </div>
                </NavLink>
                {navItem.submenu && (
                  <ul className='navbar-submenu'>
                    {navItem.submenu.map(si => (
                      <li key={si.path + si.icon}>
                        <NavLink
                          to={si.path}
                          activeClassName='active-subitem'
                          isActive={() => (location.pathname + location.search).includes(si.path)}
                        >
                          <img src={`./assets/navbar-icons/${si.icon}`} alt='' />
                          <span>{si.text}</span>
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                )}
                <div className='navbar-hidden-subitems'>{navItem.hiddenItems}</div>
              </li>
            )
          })}
      </ul>
    </nav>
  )
}

export default Navbar
