import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getMonthName } from '../../../../services/getMonthName'
import './AgencyHouseLobbyHeader.scss'

const AgencyHouseLobbyHeader = ({ date }) => {
  const { agencyName } = useSelector(({ login }) => login)
  const {
    t,
    i18n: { language },
  } = useTranslation('agencyHouseLobby')

  return (
    <header className='agency-house-lobby-header'>
      <div>
        <h5>{t('headerGreetings', { agencyName })}</h5>
        <p>{date && t('headerTitle', { month: getMonthName(date, language) })}</p>
      </div>
      <p>{date && `${getMonthName(date, language)}, ${date.getFullYear()}`}</p>
    </header>
  )
}

export default AgencyHouseLobbyHeader
