import classNames from 'classnames'
import CancelFilledIconSvg from '../../../../components/Common/SvgIcons/CancelFilledIconSvg/CancelFilledIconSvg'
import './AgencyFieldChip.scss'

const AgencyFieldChip = ({ children, checked, onClick, disabled }) => {
  return (
    <button onClick={onClick} className={classNames('agency-field-chip', { checked })} disabled={disabled}>
      <div>{children}</div>
      {checked && <CancelFilledIconSvg />}
    </button>
  )
}

export default AgencyFieldChip
