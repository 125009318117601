import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { NavLink, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import SignoutButton from '../../Common/SignoutButton/SignoutButton'
import Tooltip from '../../Common/Tooltip/Tooltip'
import LogoutConfirm from '../../../features/login/components/LogoutConfirm/LogoutConfirm'
import { disableSubagencyMode, logout } from '../../../features/login/actions/loginActions'
import { agencyUserRoleType, userPreferenceType } from '../../../types/agencyUserTypes'
import { getCrawlerCompanies } from '../../../features/shared/actions/sharedActions'
import './Extras.scss'
import { hebrew } from '../../../i18n'
import { agencyHouseRoutes } from '../../../environment/urls'

const Extras = () => {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  let history = useHistory()
  const { UserName, preferences, role, subagencyMode } = useSelector(state => state.login)
  const { crawlerCompanies: companies } = useSelector(state => state.shared)

  const [showLogoutModal, setShowLogoutModal] = useState(false)
  const signOutClickHandler = () => {
    if (subagencyMode) {
      dispatch(disableSubagencyMode())
      history.push(`${agencyHouseRoutes.agencies}/subagencies`)
      return
    }
    setShowLogoutModal(true)
  }
  const closeModal = () => {
    setShowLogoutModal(false)
  }

  const [initials, setInitials] = useState('X')

  const showDownload = preferences.includes(userPreferenceType.download)
  // || role !== agencyUserRoleType.administrator

  const currLocationDownload = history.location.pathname.includes('/download')
  const haveReports = companies.some(c => c.reportRecords.length > 0)

  const singOut = () => {
    dispatch(logout())
    history.push('/')
  }

  useEffect(() => {
    if (UserName) {
      setInitials(
        UserName.split(' ')
          .filter(x => x !== '')
          .map((n, i) => {
            return n[0].toUpperCase()
          })
          .slice(0, 3)
          .join('')
      )
    }
  }, [UserName])

  useEffect(() => {
    if (!currLocationDownload && showDownload) {
      dispatch(getCrawlerCompanies())
    }
  }, [dispatch])

  return (
    <div className='extras'>
      {showDownload && (
        <div className='download-reports-link'>
          <Tooltip title={t('sidebar.reportDownload')} position={hebrew() ? 'left' : 'right'} size='lg' />
          <NavLink exact to={'/download'} activeClassName='active'>
            <div className='icon-download'>
              <img src='./assets/download-icon/Download.png' alt='download' />
            </div>
          </NavLink>
          {!currLocationDownload && haveReports && (
            <div className='download-reports-link-attention'>
              <span>!</span>
            </div>
          )}
        </div>
      )}
      {role !== agencyUserRoleType.administrator && (
        <div className='user-profile-link'>
          <Tooltip title={t('sidebar.userProfile')} position='left' size='lg' />
          <NavLink exact to={'/profile'} activeClassName='active'>
            <div className='user-avatar'>
              <div className='user-avatar-img'>{initials}</div>
            </div>
          </NavLink>
        </div>
      )}
      <div className='logout-link'>
        <Tooltip title={t('sidebar.logout')} position='left' size='lg' />
        <div className='icon-signout'>
          <SignoutButton onClickHandler={signOutClickHandler} />
        </div>
      </div>
      {showLogoutModal && <LogoutConfirm closeModal={closeModal} signoutHandler={singOut} />}
    </div>
  )
}

export default Extras
