const HeartIconSvg = ({ fill = 'var(--text-primary-color)' }) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' width='24' height='24'>
    <g id='favorite'>
      <path
        id='Vector'
        opacity='0.3'
        fill={fill}
        d='M 16.5 5 C 14.96 5 13.46 5.99 12.94 7.36 H 11.07 C 10.54 5.99 9.04 5 7.5 5 C 5.5 5 4 6.5 4 8.5 C 4 11.39 7.14 14.24 11.9 18.55 L 12 18.65 L 12.1 18.55 C 16.86 14.24 20 11.39 20 8.5 C 20 6.5 18.5 5 16.5 5 Z'
      />
      <path
        id='Vector_2'
        fill={fill}
        d='M 16.5 3 C 14.76 3 13.09 3.81 12 5.09 C 10.91 3.81 9.24 3 7.5 3 C 4.42 3 2 5.42 2 8.5 C 2 12.28 5.4 15.36 10.55 20.04 L 12 21.35 L 13.45 20.03 C 18.6 15.36 22 12.28 22 8.5 C 22 5.42 19.58 3 16.5 3 Z M 12.1 18.55 L 12 18.65 L 11.9 18.55 C 7.14 14.24 4 11.39 4 8.5 C 4 6.5 5.5 5 7.5 5 C 9.04 5 10.54 5.99 11.07 7.36 H 12.94 C 13.46 5.99 14.96 5 16.5 5 C 18.5 5 20 6.5 20 8.5 C 20 11.39 16.86 14.24 12.1 18.55 Z'
      />
    </g>
  </svg>
)

export default HeartIconSvg
