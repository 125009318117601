import { useTranslation } from 'react-i18next'
import { formatAsCurrency } from '../../../../utils/formatAs'
import WorkstationBanner from '../WorkstationBanner/WorkstationBanner'
import './TasksMonetaryValue.scss'

const TasksMonetaryValue = ({ value = 0 }) => {
  const { t } = useTranslation('workstation')
  return (
    <WorkstationBanner>
      <div className='task-monetary-value'>
        {t('potentialValue')}
        <span>{formatAsCurrency(value)}</span>
      </div>
    </WorkstationBanner>
  )
}

export default TasksMonetaryValue
