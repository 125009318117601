import { useState } from 'react'
import { hebrew } from '../../i18n'
import { NavLink, useHistory } from 'react-router-dom'
import ReportsCountBadge from '../CountBadge/CountBadge'
import MeatBallsMenu from '../Common/MeatBallsMenu/MeatBallsMenu'
import { deleteCompany } from '../../features/download/actions/downloadActions'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog'
import ReportsDownloadListModal from '../ReportsDownloadListModal/ReportsDownloadListModal'
import { downloadReportRequest } from '../../http/requests'
import './DownloadSidebarCompanies.scss'

const DownloadSidebarCompanies = ({ companies, baseRoute, agencyDbName }) => {
  const {
    monthStatus: { companies: scanCompanies },
  } = useSelector(({ scan }) => scan)
  const { t } = useTranslation('download')
  const dispatch = useDispatch()
  const history = useHistory()
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [showReportsListModal, setShowReportsListModal] = useState(false)
  const [company, setCompany] = useState(null)

  const modalTitle = companyName => t('companiesSidebar.deleteModal.caption', { companyName })
  const modalText = t('companiesSidebar.deleteModal.text')
  const modalConfirmBtn = t('companiesSidebar.deleteModal.confirmBtn')
  const modalCancelBtn = t('companiesSidebar.deleteModal.declineBtn')

  const downloadFileHandler = async reportRecordId => {
    await downloadReportRequest({ reportRecordId, agency: agencyDbName })
  }

  const companyMenuItems = company => [
    {
      text: t('companiesSidebar.editLogin'),
      clickHandler: () => {
        history.push(`${baseRoute}/${company.Id}?editLogin=true`)
      },
    },
    {
      text: t('companiesSidebar.downloadSupport'),
      clickHandler: () => {
        const link = document.createElement('a')
        link.download = `${company.Id}_support.rar`
        link.href = `./assets/_files/support/${company.Id}.rar`
        link.click()
        link.remove()
      },
    },
    ...(company.Files.length > 0
      ? [
          {
            text: t('downloadFiles'),
            clickHandler: () => {
              setCompany(company)
              setShowReportsListModal(true)
            },
          },
        ]
      : []),
    {
      text: t('companiesSidebar.deleteCompany'),
      clickHandler: () => {
        setCompany({ id: company.Id, name: hebrew() ? company.Name : company.NameEn })
        setShowConfirmModal(true)
      },
      warn: true,
    },
  ]

  const scanCompaniesDic = Object.fromEntries(scanCompanies.map(c => [c.id, c]))

  return (
    <>
      <ul>
        {companies
          .map(c => ({ ...c, availableReportsCount: scanCompaniesDic[c.Id]?.availableReportsCount ?? 0 }))
          .sort((a, b) => b.availableReportsCount - a.availableReportsCount)
          .map(c => (
            <li key={c.Id} className='download-sidebar-company'>
              <NavLink
                to={`${baseRoute}/${c.Id}`}
                activeClassName='current-link'
                isActive={(match, location) => {
                  if (!match) {
                    return false
                  }
                  return true
                }}
              >
                <div>
                  <div>
                    <img src={`./assets/companies-logos-light/${c.Id}.png`} alt={c.Name} />
                  </div>
                  <div>
                    <p>{hebrew() ? c.Name : c.NameEn}</p>
                    <p>{c.Fields.map(f => (hebrew() ? f.Name : f.EngilshName)).join(', ')}</p>
                  </div>
                </div>
                <div>
                  <ReportsCountBadge count={c.availableReportsCount} />
                  <MeatBallsMenu menuItems={companyMenuItems(c)} positionFixed menuSize='xs' />
                </div>
              </NavLink>
            </li>
          ))}
      </ul>
      {showConfirmModal && (
        <ConfirmDialog
          title={modalTitle(company.name)}
          text={modalText}
          confirmText={modalConfirmBtn}
          declineText={modalCancelBtn}
          decline={() => {
            setShowConfirmModal(false)
            setCompany(null)
          }}
          confirm={() => {
            setCompany(null)
            dispatch(deleteCompany(company.id, agencyDbName))
          }}
        />
      )}
      {showReportsListModal && (
        <ReportsDownloadListModal
          closeModal={() => {
            setShowReportsListModal(false)
            setCompany(null)
          }}
          reports={company.Files.map(f => ({ id: f.Key, name: f.Value, link: f.Value }))}
          downloadReport={id => downloadFileHandler(id)}
        />
      )}
    </>
  )
}

export default DownloadSidebarCompanies
