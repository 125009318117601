export const adminMessageType = {
  mainLoby: 0,
  fileAnalytics: 1,
  workstation: 2,
  customers: 3,
  customer: 4,
  policies: 5,
  profile: 6,
  downloads: 7,
  companyDownloads: 8,
}

export const adminMessage = {
  [adminMessageType.mainLoby]: {
    path: '/lobby',
  },
  [adminMessageType.fileAnalytics]: {
    path: '/analytics',
  },
  [adminMessageType.workstation]: {
    path: '/workstation',
  },
  [adminMessageType.customers]: {
    path: '/customers',
  },
  [adminMessageType.customer]: {
    path: '/customers/:id',
  },
  [adminMessageType.policies]: {
    path: '/policies',
  },
  [adminMessageType.profile]: {
    path: '/profile',
  },
  [adminMessageType.downloads]: {
    path: '/download',
  },
  [adminMessageType.companyDownloads]: {
    path: '/download/:id',
  },
}

export const sameRoutePath = (routePath, notification) => {
  const { type: messageType, companies } = notification
  if (messageType === adminMessageType.customer) {
    const adminPathDirs = adminMessage[adminMessageType.customer].path.split('/')
    const pathDirs = routePath.split('/')
    return adminPathDirs[1] === pathDirs[1] && pathDirs.length >= 3 && pathDirs[2]?.length > 0
  }
  if (messageType === adminMessageType.companyDownloads) {
    const adminPathDirs = adminMessage[adminMessageType.companyDownloads].path.split('/')
    const pathDirs = routePath.split('/')
    return adminPathDirs[1] === pathDirs[1] && pathDirs.length >= 3 && pathDirs[2] === companies
  }
  return routePath === adminMessage[messageType].path
}
