import classNames from 'classnames'
import { useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import BottomSheetBase from '../../../../components/Common/BottomSheetBase/BottomSheetBase'
import DateRangePicker from '../../../../components/Common/DateRangePicker/DateRangePicker'
import useOutsideClick from '../../../../hooks/useOutsideClick'
import { WORKSTATION_FILTERS_CLEAR, WORKSTATION_SET_FROM, WORKSTATION_SET_TO } from '../../../../types/actionTypes'
import { formatAsShortDate } from '../../../../utils/formatAs'
import WorkstationButtonMobile from '../WorkstationButtonMobile/WorkstationButtonMobile'
import './WorkstationDateRange.scss'

const WorkstationDateRange = () => {
  const [showPicker, setShowPicker] = useState(false)
  const { dates, from, to } = useSelector(({ workstation }) => workstation)
  const { t } = useTranslation('workstation')
  const startDate = new Date(dates.at(-1))
  const endDate = new Date(dates[0])
  const dispatch = useDispatch()
  const setFrom = date => dispatch({ type: WORKSTATION_SET_FROM, payload: date })
  const setTo = date => dispatch({ type: WORKSTATION_SET_TO, payload: date })
  const dateRangeRef = useRef()
  useOutsideClick(dateRangeRef, () => {
    showPicker && setShowPicker(false)
  })

  return (
    <>
      <div className='workstation-filter-date-container' ref={dateRangeRef}>
        <div className='workstation-filter-date-controls'>
          <button
            className={classNames('workstation-filter-button', { open: showPicker })}
            onClick={() => setShowPicker(!showPicker)}
          >
            <div className='workstation-filter-button-content'>
              <div className='workstation-filter-button-icon'>
                <img src='./assets/date-icon/date.png' alt='period' />
              </div>
              {from && to ? (
                <span className='workstation-filter-button-name'>{`${
                  from.getTime() === to.getTime()
                    ? formatAsShortDate(from)
                    : formatAsShortDate(to) + ' - ' + formatAsShortDate(from)
                }`}</span>
              ) : (
                <span className='workstation-filter-button-name'>{t('period')}</span>
              )}
            </div>
            <div className='workstation-filter-button-icon mobile-hide'>
              <img src='./assets/arrow-dropdown-down/arrow_drop_down_24px.png' alt='open' />
            </div>
          </button>
        </div>
        {showPicker && !isMobile && (
          <div className='workstation-date-range-picker-wrapper' onClick={e => e.stopPropagation()}>
            <DateRangePicker
              from={from}
              to={to}
              startDate={startDate}
              endDate={endDate}
              setFrom={setFrom}
              setTo={setTo}
            />
          </div>
        )}
        {showPicker && isMobile && (
          <BottomSheetBase open={showPicker} sheetTitle={t('date')} closeSheet={() => setShowPicker(false)}>
            <div className='workstation-date-range-picker-wrapper'>
              <DateRangePicker
                from={from}
                to={to}
                startDate={startDate}
                endDate={endDate}
                setFrom={setFrom}
                setTo={setTo}
              />
            </div>
            <div className='workstation-filter-bottomsheet-footer'>
              <WorkstationButtonMobile
                caption={t('clearFilters')}
                classes={'secondary'}
                onClick={() => {
                  dispatch({ type: WORKSTATION_FILTERS_CLEAR })
                  setShowPicker(false)
                }}
              />
              <WorkstationButtonMobile
                caption={t('save')}
                onClick={() => {
                  setShowPicker(false)
                }}
              />
            </div>
          </BottomSheetBase>
        )}
      </div>
    </>
  )
}

export default WorkstationDateRange
