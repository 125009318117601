import { useHistory } from 'react-router'
import AgencyHouseAnalyticsFiltersbar from '../AgencyHouseAnalyticsFiltersbar/AgencyHouseAnalyticsFiltersbar'
import { useEffect, useState } from 'react'
import useQuery from '../../../../hooks/useQuery'
import { useDispatch, useSelector } from 'react-redux'
import {
  AGENT_HOUSE_ANALYTICS_SET_LISTTYPE,
  AGENT_HOUSE_ANALYTICS_SET_LISTVIEWTYPE,
} from '../../../../types/actionTypes'
import {
  analyticsIncomesExtraListType,
  analyticsIncomesListType,
  analyticsIncomesListViewType,
  policiesDataType,
  policiesTotalsType,
} from '../../../../types/analyticsIncomesTypes'
import queryString from 'query-string'
import {
  getAgencyHouseAnalyticsPoliciesListRequest,
  getAgencyHouseAnalyticsPoliciesTotalsRequest,
} from '../../../../http/requests/agencyHouseRequests'
import { getCheckedIds, lowercaseObjectKeys } from '../../../../utils/objectUtils'
import { isRegularOrDeltaAgency } from '../../../login/reducers/loginSelectors'
import {
  getAgencyAnalyticsPoliciesListRequest,
  getAgencyAnalyticsPoliciesTotalsRequest,
} from '../../../../http/requests/agencyAnalyticsRequests'
import { agencyHouseAnalyticsSubroute } from '../../../../environment/urls'
import AgencyHouseAnalyticsPoliciesTotals from '../AgencyHouseAnalyticsPoliciesTotals/AgencyHouseAnalyticsPoliciesTotals'
import { getRandomInt } from '../../../../services/getRandom'
import AgencyHouseAnalyticsPoliciesListContainer from '../AgencyHouseAnalyticsPoliciesListContainer/AgencyHouseAnalyticsPoliciesListContainer'
import AgencyHouseAnalyticsPoliciesDetailsCard from '../AgencyHouseAnalyticsPoliciesDetailsCard/AgencyHouseAnalyticsPoliciesDetailsCard'
import './AgencyHouseAnalyticsPolicies.scss'
import { analyticsPeriodViewType } from '../../../../types/analyticsTypes'
import { getMonthNameByNumber } from '../../../../services/getMonthName'
import AgencyHouseAnalyticsIncomesDetailsCard from '../AgencyHouseAnalyticsIncomesChangesDetailsCard/AgencyHouseAnalyticsIncomesChangesDetailsCard'
import { familyGroupMode } from '../../../shared/reducers/sharedSelectors'

const AgencyHouseAnalyticsPolicies = () => {
  const [totals, setTotals] = useState(
    Object.values(policiesTotalsType).map(type => ({
      type,
      value: null,
      diff: 0,
    }))
  )
  const [totalsLoading, setTotalsloading] = useState(false)
  const [list, setList] = useState([])
  const [listLoading, setListLoading] = useState(false)
  const [detailsItem, setDetailsItem] = useState(null)

  const dispatch = useDispatch()
  const {
    incomesListType,
    incomesListViewType,
    periodViewType,
    selectedDates,
    selectedAgencies,
    categories,
    companies,
    filtersLoading,
  } = useSelector(({ agencyHouseAnalytics }) => agencyHouseAnalytics)
  const { selectedGroupsIds } = useSelector(({ shared }) => shared)
  const calculateByFamilyGroups = familyGroupMode()

  const history = useHistory()
  let query = useQuery()
  const currentListType = query.get('listType')
  const currentListViewType = query.get('listViewType')

  const selectListType = listType => {
    let listTypeToSet = listType
    if (isRegularOrDeltaAgency() && listType === analyticsIncomesListType.agents)
      listTypeToSet = analyticsIncomesListType.companies
    const parsed = queryString.parse(history.location.search)
    history.replace({ search: new URLSearchParams({ ...parsed, listType: listTypeToSet }).toString() })
  }

  if (
    !currentListType ||
    !Object.values(analyticsIncomesListType)
      .filter(lt => (isRegularOrDeltaAgency() ? lt !== analyticsIncomesListType.agents : true))
      .includes(+currentListType)
  ) {
    selectListType(incomesListType)
  }

  if (
    currentListType &&
    Object.values(analyticsIncomesListType)
      .filter(lt => (isRegularOrDeltaAgency() ? lt !== analyticsIncomesListType.agents : true))
      .includes(+currentListType) &&
    incomesListType !== +currentListType
  ) {
    dispatch({ type: AGENT_HOUSE_ANALYTICS_SET_LISTTYPE, payload: +currentListType })
  }

  const selectListViewType = listViewType => {
    const parsed = queryString.parse(history.location.search)
    history.replace({ search: new URLSearchParams({ ...parsed, listViewType }).toString() })
  }

  if (!currentListViewType || !Object.values(analyticsIncomesListViewType).includes(+currentListViewType)) {
    selectListViewType(incomesListViewType)
  }

  if (
    currentListViewType &&
    Object.values(analyticsIncomesListViewType).includes(+currentListViewType) &&
    incomesListViewType !== +currentListViewType
  ) {
    dispatch({ type: AGENT_HOUSE_ANALYTICS_SET_LISTVIEWTYPE, payload: +currentListViewType })
  }

  const dataType = history.location.pathname.includes(agencyHouseAnalyticsSubroute.policiesNew)
    ? policiesDataType.new
    : policiesDataType.all

  useEffect(() => {
    const fetchTotals = async () => {
      try {
        setTotalsloading(true)
        const getTotalsRequest = isRegularOrDeltaAgency()
          ? getAgencyAnalyticsPoliciesTotalsRequest
          : getAgencyHouseAnalyticsPoliciesTotalsRequest
        const { data: resp } = await getTotalsRequest({
          categories: getCheckedIds(categories, 'selected'),
          companies: getCheckedIds(companies, 'selected'),
          agenciesIds: selectedAgencies.filter(a => a.selected).map(a => a.id),
          timePeriods: selectedDates.filter(a => a.selected).map(a => a.date),
          fromNewPolicies: history.location.pathname.includes(agencyHouseAnalyticsSubroute.policiesNew),
          periodType: periodViewType,
          ...(isRegularOrDeltaAgency() && { calculateByFamilyGroups }),
        })
        const data = lowercaseObjectKeys(resp.Data ?? resp.data)

        setTotals(
          Object.entries(policiesTotalsType).map(([typeKey, type]) => ({
            type,
            value: data[typeKey]?.currentValue,
            diff: data[typeKey]?.diff,
            trend: data[typeKey]?.trend,
          }))
        )
        setTotalsloading(false)
      } catch (error) {
        setTotalsloading(false)
      }
    }
    if (
      (!isRegularOrDeltaAgency() &&
        categories.length > 0 &&
        companies.length > 0 &&
        selectedDates.length > 0 &&
        selectedAgencies.length > 0) ||
      (isRegularOrDeltaAgency() && selectedDates.length > 0 && categories.length > 0 && companies.length > 0)
    ) {
      fetchTotals()
    }
  }, [
    calculateByFamilyGroups,
    selectedDates,
    selectedAgencies,
    categories,
    companies,
    history.location,
    selectedGroupsIds,
  ])

  useEffect(() => {
    const fetchTable = async () => {
      try {
        setDetailsItem(null)
        setList([])
        setListLoading(true)
        const notMonthlyChartView =
          +currentListViewType === analyticsIncomesListViewType.chart &&
          periodViewType !== analyticsPeriodViewType.monthly
        const getListRequest = isRegularOrDeltaAgency()
          ? getAgencyAnalyticsPoliciesListRequest
          : getAgencyHouseAnalyticsPoliciesListRequest
        const { data: resp } = await getListRequest({
          categories: getCheckedIds(categories, 'selected'),
          companies: getCheckedIds(companies, 'selected'),
          agenciesIds: selectedAgencies.filter(a => a.selected).map(a => a.id),
          timePeriods: selectedDates.filter(a => a.selected).map(a => a.date),
          entityType: notMonthlyChartView ? analyticsIncomesExtraListType.timePeriods : +currentListType,
          fromNewPolicies: history.location.pathname.includes(agencyHouseAnalyticsSubroute.policiesNew),
          ...(isRegularOrDeltaAgency() && { calculateByFamilyGroups }),
        })
        let listItems = lowercaseObjectKeys(resp.Data ?? resp.data).items
        if (notMonthlyChartView) {
          listItems = listItems.map(li => {
            const [year, month] = li.key.split('-')
            return {
              ...li,
              name: `${getMonthNameByNumber(month, 'he')}, ${year}`,
              nameEn: `${getMonthNameByNumber(month, 'en')}, ${year}`,
            }
          })
        }
        setList(listItems)
        setListLoading(false)
      } catch (error) {
        setListLoading(false)
      }
    }
    if (
      (!isRegularOrDeltaAgency() &&
        categories.length > 0 &&
        companies.length > 0 &&
        selectedDates.length > 0 &&
        selectedAgencies.length > 0) ||
      (isRegularOrDeltaAgency() && selectedDates.length > 0 && categories.length > 0 && companies.length > 0)
    ) {
      if (
        Object.values(analyticsIncomesListType)
          .filter(lt => (isRegularOrDeltaAgency() ? lt !== analyticsIncomesListType.agents : true))
          .includes(+currentListType)
      )
        fetchTable()
    }
  }, [
    calculateByFamilyGroups,
    selectedDates,
    selectedAgencies,
    categories,
    companies,
    currentListType,
    history.location,
    selectedGroupsIds,
  ])

  useEffect(() => {
    if (detailsItem) {
      setDetailsItem(null)
    }
  }, [history.location, categories, companies])

  return (
    <div className='agency-house-analytics-policies'>
      <main>
        <AgencyHouseAnalyticsFiltersbar />
        <div className='agency-house-analytics-policies-content'>
          <AgencyHouseAnalyticsPoliciesTotals
            periodViewType={periodViewType}
            totals={totals}
            totalsLoading={totalsLoading || filtersLoading}
            dataType={dataType}
          />
          <AgencyHouseAnalyticsPoliciesListContainer
            listType={+currentListType}
            setListType={selectListType}
            listViewType={+currentListViewType}
            setListViewType={selectListViewType}
            periodViewType={periodViewType}
            list={list}
            listLoading={listLoading || totalsLoading || filtersLoading}
            dataType={dataType}
            totals={totals}
            selectItem={setDetailsItem}
            selectedItem={detailsItem}
          />
        </div>
      </main>
      <AgencyHouseAnalyticsPoliciesDetailsCard
        item={detailsItem}
        closeCard={() => setDetailsItem(null)}
        dataType={dataType}
        listViewType={+currentListViewType}
      />
    </div>
  )
}

export default AgencyHouseAnalyticsPolicies
