import classNames from 'classnames'
import { useEffect, useRef } from 'react'
import './ContextMenu.scss'

const ContextMenu = ({ menuItems = [], closeMenu = () => {} }) => {
  const refContextMenu = useRef(null)

  const handleCloseMenu = event => {
    if (event.key === 'Escape' || (refContextMenu.current && !refContextMenu.current.contains(event.target))) {
      event.stopPropagation()
      event.preventDefault()
      closeMenu()
    }
  }

  const handleClick = (event, item) => {
    if (item.clickHandler) {
      event.preventDefault()
      event.stopPropagation()
      item.clickHandler()
    }
    if (!item.disableCloseMenuOnClick) closeMenu()
  }

  useEffect(() => {
    document.addEventListener('keydown', handleCloseMenu, true)
    document.addEventListener('click', handleCloseMenu, true)
    return () => {
      document.removeEventListener('keydown', handleCloseMenu, true)
      document.removeEventListener('click', handleCloseMenu, true)
    }
  })

  return (
    <div className='context-menu-container' ref={refContextMenu}>
      <div className='context-menu' onClick={e => e.stopPropagation()}>
        {menuItems.map((item, i) => (
          <div
            key={i + ' ' + item.text}
            className={classNames('context-menu-item', {
              danger: item.warn,
              blured: item.blured,
              disabled: item.disabled,
            })}
            {...(!item.disabled && item.clickHandler && { onClick: e => handleClick(e, item) })}
          >
            {item.text}
          </div>
        ))}
      </div>
    </div>
  )
}

export default ContextMenu
