import { useTranslation } from 'react-i18next'
import { getMonthName } from '../../../../services/getMonthName'
import PolicyListItemCard from '../PolicyListItemCard/PolicyListItemCard'
import { formatAsCurrency, formatAsPercent } from '../../../../utils/formatAs'
import ButtonRounded from '../../../../components/Common/ButtonRounded/ButtonRounded'
import MicrosoftExcelIconSvg from '../../../../components/Common/SvgIcons/MicrosoftExcelIconSvg/MicrosoftExcelIconSvg'
import { downloadReportRequest } from '../../../../http/requests'
import { Fragment, useState } from 'react'
import { ArrowDownward, ArrowUpward, ErrorOutline, SyncProblemOutlined } from '@mui/icons-material'
import { NavLink } from 'react-router-dom'
import { agencyRoutes } from '../../../../environment/urls'
import PolicyUpdateCommissionRateModal from '../PolicyUpdateCommissionRateModal/PolicyUpdateCommissionRateModal'
import PoliciesSyncIcon from '../PoliciesSyncIcon/PoliciesSyncIcon'
import { Tooltip } from '@mui/material'
import { monthlyPolicyStatuses } from '../../../../types/tablesTypes'
import './PolicyPayments.scss'
import {
  commissionViewTranslationPrefix as commissionPrefix,
  commissionKeySuffix,
  deltaCommissionSplitView,
} from '../../../shared/reducers/sharedSelectors'
import { cn } from '../../../../utils/stylesUtils'

const PolicyPayments = ({ payments, policyId }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('policies')
  const [showPayments, setShowPayments] = useState({})
  const [policyTypeToUpdate, setPolicyTypeToUpdate] = useState(null)

  const trend = value =>
    value < 0 ? (
      <ArrowDownward style={{ color: 'var(--trend-down-color)', fontSize: '14px' }} />
    ) : value > 0 ? (
      <ArrowUpward style={{ color: 'var(--trend-up-color)', fontSize: '14px' }} />
    ) : null

  if (payments.length === 0) {
    return null
  }
  const getReport = ({ reportsInfo }, sideReport) => {
    const report = reportsInfo.find(r => r.isSideReport === sideReport)
    if (!report) {
      return '--'
    } else {
      if (report.reportDownloaded) {
        const downloadReport = async reportRecordId => await downloadReportRequest({ reportRecordId })
        return (
          <ButtonRounded onClick={() => downloadReport(report.reportRecordId)}>
            <MicrosoftExcelIconSvg />
            <span>{formatReportName(report.name)}</span>
          </ButtonRounded>
        )
      } else {
        return (
          <NavLink to={'/' + agencyRoutes.scan + '/' + report.companyId}>
            <span>{t('policyCard.notDownloaded')}</span>
            <PoliciesSyncIcon tooltip={t('list.tooltips.outdatedPolicy')} enabled={true} />
          </NavLink>
        )
      }
    }
  }

  const formatReportName = reportName => {
    const nameLength = 10
    if (reportName.length <= nameLength) {
      return <span>{reportName}</span>
    } else {
      const cuttedName = reportName.substring(0, nameLength)
      return (
        <Tooltip title={reportName} classes={{ popper: 'MuiTooltip-tooltip-custom' }} arrow placement='top'>
          {cuttedName}
        </Tooltip>
      )
    }
  }

  const renderMonthlyPaymentsRow = paymentInfo => {
    switch (paymentInfo.monthlyPaymentsStatus) {
      case monthlyPolicyStatuses.missingMonth:
        return (
          <PolicyListItemCard>
            <div className='policy-payments-grid not-downloaded-grid'>
              <div></div>
              <div className='not-dowloaded-block'>
                {getMonthName(paymentInfo.pullingDate, language)} {new Date(paymentInfo.pullingDate).getFullYear()}
              </div>
              <div className='not-downloaded-button-container '>
                <Tooltip
                  title={t('policyCard.notDownloadedTooltip')}
                  classes={{ popper: 'MuiTooltip-tooltip-custom' }}
                  arrow
                  placement='top'
                >
                  <div className='not-downloaded-button report-link-cell'>
                    <NavLink
                      to={`/${agencyRoutes.scan}/${paymentInfo.reportsInfo[0]?.companyId}/${paymentInfo.reportsInfo[0]?.date}`}
                    >
                      <SyncProblemOutlined style={{ color: 'var(--orange-client)' }} />
                      <span style={{ color: 'var(--orange-client)' }}>{t('policyCard.notDownloaded')}</span>
                    </NavLink>
                  </div>
                </Tooltip>
              </div>
            </div>
          </PolicyListItemCard>
        )
      case monthlyPolicyStatuses.missingPayment:
        return (
          <PolicyListItemCard>
            <div className='policy-payments-grid not-downloaded-grid'>
              <div></div>
              <div className='not-dowloaded-block'>
                {getMonthName(paymentInfo.pullingDate, language)} {new Date(paymentInfo.pullingDate).getFullYear()}
              </div>
              <div className='not-found-in-report-container'>
                <div className='not-found-in-the-report-label'>{t('policyCard.notFoundInTheReport')}</div>
                <Tooltip
                  title={t('policyCard.notFoundInTheReportInfo')}
                  classes={{ popper: 'MuiTooltip-tooltip-custom' }}
                  arrow
                  placement='top'
                >
                  <img src='./assets/info-icon/Round-Info.png' alt='' className='img-icon' />
                </Tooltip>
              </div>
            </div>
          </PolicyListItemCard>
        )
      case monthlyPolicyStatuses.normal:
        return (
          <PolicyListItemCard>
            <div className={cn('policy-payments-grid', { extended: deltaCommissionSplitView() })}>
              <div>
                <button
                  onClick={() =>
                    setShowPayments(prev => ({ ...prev, [paymentInfo.pullingDate]: !prev[paymentInfo.pullingDate] }))
                  }
                >
                  <img
                    src={`./assets/${showPayments[paymentInfo.pullingDate] ? 'minus-icon/Close' : 'plus-icon/Add'}.png`}
                    alt='more'
                  />
                </button>
                {showPayments[paymentInfo.pullingDate]}
              </div>
              <div>
                {getMonthName(paymentInfo.pullingDate, language)} {new Date(paymentInfo.pullingDate).getFullYear()}
              </div>
              <div>
                <div className='financial-value-cell'>
                  {trend(paymentInfo.premiumTrend)}
                  {formatAsCurrency(paymentInfo.totalPremium, { dashesForZero: false })}
                </div>
              </div>
              {deltaCommissionSplitView() && (
                <div>
                  <div className='financial-value-cell'>
                    {trend(paymentInfo['commissionTrendAgency'])}
                    {formatAsCurrency(paymentInfo['totalCommissionAgency'], { dashesForZero: false })}
                  </div>
                </div>
              )}
              <div>
                <div className='financial-value-cell'>
                  {trend(paymentInfo['commissionTrend' + commissionKeySuffix()])}
                  {formatAsCurrency(paymentInfo['totalCommission' + commissionKeySuffix()], { dashesForZero: false })}
                </div>
              </div>
              <div>
                <div className='financial-value-cell'>
                  {trend(paymentInfo.accumulationTrend)}
                  {formatAsCurrency(paymentInfo.totalAccumulation, { dashesForZero: false })}
                </div>
              </div>
              {deltaCommissionSplitView() && (
                <div className='commission-rate-cells'>
                  <div>
                    <div className='financial-value-cell'>
                      {trend(paymentInfo['commissionRatePremiumTrendAgency'])}{' '}
                      {formatAsPercent(paymentInfo['totalCommissionRatePremiumAgency'], {
                        dashesForZero: false,
                      })}
                    </div>
                  </div>
                  <div>
                    <div className='financial-value-cell'>
                      {trend(paymentInfo['commissionRateAccumulationTrendAgency'])}
                      {formatAsPercent(paymentInfo['totalCommissionRateAccumulationAgency'], {
                        maximumFractionDigits: 4,
                        dashesForZero: false,
                      })}
                    </div>
                  </div>
                </div>
              )}
              <div className='commission-rate-cells'>
                <div>
                  <div className='financial-value-cell'>
                    {trend(paymentInfo['commissionRatePremiumTrend' + commissionKeySuffix()])}
                    {formatAsPercent(paymentInfo['totalCommissionRatePremium' + commissionKeySuffix()], {
                      dashesForZero: false,
                    })}
                  </div>
                </div>
                <div>
                  <div className='financial-value-cell'>
                    {trend(paymentInfo['commissionRateAccumulationTrend' + commissionKeySuffix()])}
                    {formatAsPercent(paymentInfo['totalCommissionRateAccumulation' + commissionKeySuffix()], {
                      maximumFractionDigits: 4,
                      dashesForZero: false,
                    })}
                  </div>
                </div>
              </div>

              <div className='report-link-cell'>{getReport(paymentInfo, false)}</div>
              <div className='report-link-cell'>{getReport(paymentInfo, true)}</div>
            </div>

            {showPayments[paymentInfo.pullingDate] &&
              paymentInfo.payments.map((p, i) => (
                <div
                  className={cn('policy-payments-grid subgrid', { extended: deltaCommissionSplitView() })}
                  key={i + 1}
                >
                  <div></div>
                  <div>{p.policyTypeName}</div>
                  <div>
                    <div className='financial-value-cell'>{formatAsCurrency(p.premium, { dashesForZero: false })}</div>
                  </div>
                  {deltaCommissionSplitView() && (
                    <div>
                      <div className='financial-value-cell'>
                        {formatAsCurrency(p['commissionAgency'], { dashesForZero: false })}
                      </div>
                    </div>
                  )}
                  <div>
                    <div className='financial-value-cell'>
                      {formatAsCurrency(p['commission' + commissionKeySuffix()], { dashesForZero: false })}
                    </div>
                  </div>
                  <div>
                    <div className='financial-value-cell'>
                      {formatAsCurrency(p.accumulation, { dashesForZero: false })}
                    </div>
                  </div>
                  {deltaCommissionSplitView() && (
                    <div>
                      <div className='payment-commrate-cell'>
                        <div className='financial-value-cell'>
                          {formatAsPercent(p['commissionRatePremiumAgency'] ? p['commissionRatePremiumAgency'] : null, {
                            dashesForZero: false,
                            maximumFractionDigits: 4,
                          })}
                        </div>
                        {p.policyTypeId && p['commissionRatePremiumAgency'] && (
                          <div className='payment-commrate-cell-button-container'>
                            <button onClick={() => setPolicyTypeToUpdate(p.policyTypeId)}>
                              <ErrorOutline />
                            </button>
                          </div>
                        )}
                      </div>
                      <div className='payment-commrate-cell payment-commrate-acc-cell'>
                        <div className='financial-value-cell'>
                          {formatAsPercent(
                            p['commissionRateAccumulationAgency'] ? p['commissionRateAccumulationAgency'] : null,
                            {
                              dashesForZero: false,
                              maximumFractionDigits: 4,
                            }
                          )}
                        </div>
                        {p.policyTypeId && p['commissionRateAccumulationAgency'] && (
                          <div className='payment-commrate-cell-button-container'>
                            <button onClick={() => setPolicyTypeToUpdate(p.policyTypeId)}>
                              <ErrorOutline />
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <div>
                    <div className='payment-commrate-cell'>
                      <div className='financial-value-cell'>
                        {formatAsPercent(
                          p['commissionRatePremium' + commissionKeySuffix()]
                            ? p['commissionRatePremium' + commissionKeySuffix()]
                            : null,
                          {
                            dashesForZero: false,
                            maximumFractionDigits: 4,
                          }
                        )}
                      </div>
                      {p.policyTypeId && p['commissionRatePremium' + commissionKeySuffix()] && (
                        <div className='payment-commrate-cell-button-container'>
                          <button onClick={() => setPolicyTypeToUpdate(p.policyTypeId)}>
                            <ErrorOutline />
                          </button>
                        </div>
                      )}
                    </div>
                    <div className='payment-commrate-cell payment-commrate-acc-cell'>
                      <div className='financial-value-cell'>
                        {formatAsPercent(
                          p['commissionRateAccumulation' + commissionKeySuffix()]
                            ? p['commissionRateAccumulation' + commissionKeySuffix()]
                            : null,
                          {
                            dashesForZero: false,
                            maximumFractionDigits: 4,
                          }
                        )}
                      </div>
                      {p.policyTypeId && p['commissionRateAccumulation' + commissionKeySuffix()] && (
                        <div className='payment-commrate-cell-button-container'>
                          <button onClick={() => setPolicyTypeToUpdate(p.policyTypeId)}>
                            <ErrorOutline />
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div></div>
                  <div></div>
                </div>
              ))}
          </PolicyListItemCard>
        )
      default:
        break
    }
  }

  return (
    <div className='policy-payments-list'>
      <div
        className={cn('policy-payments-grid policy-sidecard-list-heading', { extended: deltaCommissionSplitView() })}
      >
        <div></div>
        <div>{t('list.heading.month')}</div>
        <div>{t('list.heading.premium')}</div>
        {deltaCommissionSplitView() && (
          <div>
            {t('agencyPrefix') + ' '}
            {t(`list.heading.commission`)}
          </div>
        )}
        <div>
          {commissionPrefix(t)}
          {t('list.heading.commission')}
        </div>
        <div>{t('list.heading.accumulation')}</div>
        {deltaCommissionSplitView() && (
          <div>
            <div>
              {t('agencyPrefix') + ' '}
              {t('list.heading.cRate')}
            </div>
            <div className='commission-rate-cells'>
              <div>{t('list.heading.premium')}</div>
              <div>{t('list.heading.accumulation')}</div>
            </div>
          </div>
        )}
        <div>
          <div>
            {commissionPrefix(t)}
            {t('list.heading.cRate')}
          </div>
          <div className='commission-rate-cells'>
            <div>{t('list.heading.premium')}</div>
            <div>{t('list.heading.accumulation')}</div>
          </div>
        </div>
        <div>{t('list.heading.mainReport')}</div>
        <div>{t('list.heading.sideReport')}</div>
      </div>
      {payments.map(p => (
        <Fragment key={p.pullingDate}>{renderMonthlyPaymentsRow(p)}</Fragment>
      ))}
      {policyTypeToUpdate && (
        <PolicyUpdateCommissionRateModal
          closeModal={() => setPolicyTypeToUpdate(null)}
          policyId={policyId}
          policyTypeId={policyTypeToUpdate}
        />
      )}
    </div>
  )
}

export default PolicyPayments
