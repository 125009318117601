import { useDispatch, useSelector } from 'react-redux'
import SegmentedPicker from '../../../../components/Common/SegmentedPicker/SegmentedPicker'
import AgencyHouseAnalyticsDatePicker from '../AgencyHouseAnalyticsDatePicker/AgencyHouseAnalyticsDatePicker'
import { isRegularOrDeltaAgency } from '../../../login/reducers/loginSelectors'
import AgencyHouseAnalyticsFiltersPicker from '../AgencyHouseAnalyticsFiltersPicker/AgencyHouseAnalyticsFiltersPicker'
import {
  AGENT_HOUSE_ANALYTICS_AGENCIES_FILTER_APPLY,
  AGENT_HOUSE_ANALYTICS_CATEGORIES_FILTER_APPLY,
  AGENT_HOUSE_ANALYTICS_COMPANIES_FILTER_APPLY,
} from '../../../../types/actionTypes'
import { useTranslation } from 'react-i18next'
import './AgencyHouseAnalyticsFiltersbar.scss'
import FamilyGroupsToggle from '../../../../components/FamilyGroupsToggle/FamilyGroupsToggle'

const AgencyHouseAnalyticsFiltersbar = ({ dataTypes = [], selectDataType, listTypes = [], selectListType }) => {
  const { selectedAgencies, companies, categories, filtersLoading } = useSelector(
    ({ agencyHouseAnalytics }) => agencyHouseAnalytics
  )
  const { t } = useTranslation('agencyHouseAnalytics')

  const dispatch = useDispatch()
  const applyFiltersHandler = actionType => ids => {
    dispatch({
      type: actionType,
      payload: ids,
    })
  }

  return (
    <div className='agency-house-analytcs-filtersbar'>
      {isRegularOrDeltaAgency() && <FamilyGroupsToggle />}
      {dataTypes.length > 0 && <SegmentedPicker items={dataTypes} selectItem={selectDataType} />}
      {listTypes.length > 0 && <SegmentedPicker items={listTypes} selectItem={selectListType} />}
      <div className='agency-house-analytcs-filtersbar-pickers'>
        {!isRegularOrDeltaAgency() && (
          <AgencyHouseAnalyticsFiltersPicker
            filters={selectedAgencies}
            filtersLoading={filtersLoading}
            applyFilters={applyFiltersHandler(AGENT_HOUSE_ANALYTICS_AGENCIES_FILTER_APPLY)}
            filtersTitle={t('filtersPicker.agencies')}
            noFiltersText={t('filtersPicker.noAgencies')}
            noFoundText={t('filtersPicker.noAgenciesFound')}
            searchPlaceholder={t('filtersPicker.searchPlaceholder')}
          />
        )}
        <AgencyHouseAnalyticsFiltersPicker
          filters={categories}
          filtersLoading={filtersLoading}
          applyFilters={applyFiltersHandler(AGENT_HOUSE_ANALYTICS_CATEGORIES_FILTER_APPLY)}
          filtersTitle={t('filtersPicker.categories')}
          noFiltersText={t('filtersPicker.noCategories')}
          noFoundText={t('filtersPicker.noCategoriesFound')}
          searchPlaceholder={t('filtersPicker.searchPlaceholder')}
        />
        <AgencyHouseAnalyticsFiltersPicker
          filters={companies}
          filtersLoading={filtersLoading}
          applyFilters={applyFiltersHandler(AGENT_HOUSE_ANALYTICS_COMPANIES_FILTER_APPLY)}
          filtersTitle={t('filtersPicker.companies')}
          noFiltersText={t('filtersPicker.noCompanies')}
          noFoundText={t('filtersPicker.noCompaniesFound')}
          searchPlaceholder={t('filtersPicker.searchPlaceholder')}
        />
        <AgencyHouseAnalyticsDatePicker />
      </div>
    </div>
  )
}

export default AgencyHouseAnalyticsFiltersbar
