import { useTranslation } from 'react-i18next'
import {
  analyticsIncomesListType,
  incomesTotalsObject,
  getTotalTypes,
  incomesTotalsType,
} from '../../../../types/analyticsIncomesTypes'
import { commissionKeySuffix, deltaCommissionSplitView, isDeltaAgency } from '../../../shared/reducers/sharedSelectors'
import { analyticsPeriodViewType } from '../../../../types/analyticsTypes'
import { hebrew } from '../../../../i18n'
import { requestDataTypes } from '../../../../types/requestDataTypes'
import Loader from '../../../../components/Common/Loader/Loader'
import { formatAsCurrency, formatAsPercent } from '../../../../utils/formatAs'
import { getUserNameInitials } from '../../../../services/getUserNameInitials'
import { getRandomHexColor } from '../../../../services/getRandom'
import { categoriesObjects } from '../../../../types/categoriesTypes'
import classNames from 'classnames'
import AgencyHouseAnalyticsIncomesNoData from '../AgencyHouseAnalyticsIncomesNoData/AgencyHouseAnalyticsIncomesNoData'
import { getKeyByValue } from '../../../../utils/objectUtils'
import './AgencyHouseAnalyticsIncomesTable.scss'

const AgencyHouseAnalyticsIncomesTable = ({
  dataType,
  listType,
  periodViewType,
  list,
  listLoading,
  selectItem,
  selectedItem,
}) => {
  const { t } = useTranslation('agencyHouseAnalytics')
  const monthlyView = periodViewType === analyticsPeriodViewType.monthly
  const deltaView =
    isDeltaAgency() && (dataType === requestDataTypes.commission || dataType === requestDataTypes.commissionRate)

  const tableHeader = (
    <header className='agency-house-analytics-incomes-table-grid'>
      <div></div>
      <div>
        {t(`tableHeading.${Object.entries(analyticsIncomesListType).find(([key, val]) => val === listType)[0]}`)}
      </div>
      {getTotalTypes(dataType, periodViewType).map(tt => {
        const { name, nameEn, nameMonthlyView, nameEnMonthlyView } = incomesTotalsObject[tt]
        const text = monthlyView ? (hebrew() ? nameMonthlyView : nameEnMonthlyView) : hebrew() ? name : nameEn
        const textPrefix =
          deltaView && !nameEn.includes('Agency') && !nameEn.includes('BothTotal') ? t('agentPrefix') + ' ' : ''
        return <div>{textPrefix + text}</div>
      })}
      {(dataType === requestDataTypes.commission ||
        dataType === requestDataTypes.premium ||
        (dataType === requestDataTypes.accumulation && monthlyView)) && <div>{t(`tableHeading.totalPercentage`)}</div>}
    </header>
  )

  if (listLoading) {
    return <Loader />
  }
  if (list.length === 0) {
    return <AgencyHouseAnalyticsIncomesNoData />
  }
  return (
    <div className='agency-house-analytics-incomes-table-container'>
      {tableHeader}
      <div className='agency-house-analytics-incomes-table'>
        {list.map(itm => (
          <AgencyHouseAnalyticsIncomesTableItem
            key={itm.key}
            item={itm}
            dataType={dataType}
            periodViewType={periodViewType}
            listType={listType}
            selectItem={selectItem}
            selectedItem={selectedItem}
          />
        ))}
      </div>
    </div>
  )
}

export default AgencyHouseAnalyticsIncomesTable

const AgencyHouseAnalyticsIncomesTableItem = ({
  item,
  dataType,
  periodViewType,
  listType,
  selectItem,
  selectedItem,
}) => {
  const { t } = useTranslation('agencyHouseAnalytics')
  const dataKeys = getTotalTypes(dataType, periodViewType).map(
    tt => Object.entries(incomesTotalsType).find(([key, val]) => val === tt)[0]
  )
  const itemName = hebrew() ? item.name : item.nameEn
  let logo
  switch (listType) {
    case analyticsIncomesListType.agents:
      logo = (
        <div className='agency-house-analytics-incomes-table-item-logo' style={{ background: getRandomHexColor() }}>
          {getUserNameInitials(itemName)}
        </div>
      )
      break
    case analyticsIncomesListType.companies:
      logo = (
        <div className='agency-house-analytics-incomes-table-item-logo'>
          <img src={`./assets/companies-logos-light/${item.key}.png`} alt={itemName} />
        </div>
      )
      break
    case analyticsIncomesListType.categories:
      logo = (
        <div
          className='agency-house-analytics-incomes-table-item-logo small'
          style={{ background: categoriesObjects[item.key]?.markerColor ?? categoriesObjects['default'].markerColor }}
        ></div>
      )
      break
    default:
      break
  }

  return (
    <div
      className={classNames('agency-house-analytics-incomes-table-item agency-house-analytics-incomes-table-grid', {
        selected: selectedItem && item.key === selectedItem.key,
      })}
      onClick={() => selectItem({ key: item.key, type: listType })}
    >
      <div>{logo}</div>
      <div>{itemName}</div>
      {dataKeys.map(k => (
        <div key={k}>
          <span>
            {dataType === requestDataTypes.commissionRate
              ? formatAsPercent(item[k], {
                  ...((k === getKeyByValue(incomesTotalsType, incomesTotalsType.commissionRateAccumulation) ||
                    k === getKeyByValue(incomesTotalsType, incomesTotalsType.commissionRateAccumulationAgency) ||
                    k === getKeyByValue(incomesTotalsType, incomesTotalsType.commissionRateAccumulationBothTotal)) && {
                    maximumFractionDigits: 4,
                  }),
                })
              : formatAsCurrency(item[k])}
          </span>
          {dataType === requestDataTypes.commission &&
            k === getKeyByValue(incomesTotalsType, incomesTotalsType[`commissionMonthly${commissionKeySuffix()}`]) && (
              <span className='commission-vat'>
                ({t('tableHeading.inclVat')}:{' '}
                {formatAsCurrency(
                  item[getKeyByValue(incomesTotalsType, incomesTotalsType[`commissionVAT${commissionKeySuffix()}`])]
                )}
                )
              </span>
            )}
          {dataType === requestDataTypes.commission &&
            deltaCommissionSplitView() &&
            k === getKeyByValue(incomesTotalsType, incomesTotalsType[`commissionMonthlyAgency`]) && (
              <span className='commission-vat'>
                ({t('tableHeading.inclVat')}:{' '}
                {formatAsCurrency(item[getKeyByValue(incomesTotalsType, incomesTotalsType.commissionVATAgency)])})
              </span>
            )}
        </div>
      ))}
      {(dataType === requestDataTypes.commission ||
        dataType === requestDataTypes.premium ||
        (dataType === requestDataTypes.accumulation && periodViewType === analyticsPeriodViewType.monthly)) && (
        <div>{formatAsPercent(item.totalPercentage)}</div>
      )}
    </div>
  )
}
