import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { customerFamilyGroup } from '../../reducers/customerSelectors'
import { getCustomerGroups } from '../../actions/customerActions'
import { deleteCustomersGroupCustomerRequest } from '../../../../http/requests'
import CustomerGroupsMemberItem from '../CustomerGroupsMemberItem/CustomerGroupsMemberItem'
import NoData from '../../../../components/Common/NoData/NoData'
import Loader from '../../../../components/Common/Loader/Loader'
import AddCustomerGroupModal from '../../../../components/AddCustomerGroupModal/AddCustomerGroupModal'
import './CustomerFamilyGroupDetails.scss'
import { useTranslation } from 'react-i18next'

const CustomerFamilyGroupDetails = () => {
  const { t } = useTranslation('customer')
  const [showEditModal, setShowEditModal] = useState(false)
  const { customerGroupsLoading } = useSelector(({ customer }) => customer)
  const dispatch = useDispatch()
  const { id: customerId } = useParams()

  const familyGroup = customerFamilyGroup()

  const onEditSuccessHandler = () => {
    dispatch(getCustomerGroups(customerId))
  }

  const removeCustomer = async id => {
    const { success } = await deleteCustomersGroupCustomerRequest(familyGroup.id, id)
    success && onEditSuccessHandler()
  }

  return (
    <div className='customer-details-container-wrapper'>
      <div className='customer-details-container'>
        {customerGroupsLoading ? (
          <Loader />
        ) : !familyGroup ? (
          <NoData text='No Family Group' />
        ) : (
          <>
            <div className='customer-details-header'>
              <div className='customer-details-general'>
                <div className='customer-details-personal active'>
                  <div className='customer-details-name'>
                    <p>{familyGroup.name ?? t('customersGroupNamePlaceholder', { groupId: familyGroup.id })}</p>
                    <button onClick={() => setShowEditModal(true)}>
                      <img src='./assets/edit-icon/1.png' alt='' className='img-icon' />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='customer-details-list-container'>
              {familyGroup.customers.map(c => (
                <CustomerGroupsMemberItem key={c.id} customer={c} removeCustomer={() => removeCustomer(c.id)} />
              ))}
            </div>
          </>
        )}
      </div>
      {showEditModal && (
        <AddCustomerGroupModal
          closeModal={() => setShowEditModal(false)}
          group={familyGroup}
          onEditSuccess={onEditSuccessHandler}
        />
      )}
    </div>
  )
}

export default CustomerFamilyGroupDetails
