import ReactApexChart from 'react-apexcharts'
import { useRef, useEffect, useState, useLayoutEffect } from 'react'
import AgencyHouseAnalyticsIncomesNoData from '../AgencyHouseAnalyticsIncomesNoData/AgencyHouseAnalyticsIncomesNoData'
import classNames from 'classnames'
import './AgencyHouseAnalyticsIncomesChart.scss'

const AgencyHouseAnalyticsIncomesChart = ({
  totalsValue,
  totalsName,
  series = [],
  categories = [],
  selectItem,
  chartId,
  colors,
}) => {
  const [chartWidth, setChartWidth] = useState('100%')
  const yAxisWidth = '45px'
  const chartContainerRef = useRef()
  const options = {
    fill: {
      colors,
    },
    states: {
      normal: {
        filter: {
          type: 'darken',
          value: 0.35,
        },
      },
      hover: {
        filter: {
          type: 'lighten',
          value: 0.25,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'lighten',
          value: 0.15,
        },
      },
    },
    chart: {
      id: chartId,
      type: 'bar',
      toolbar: {
        show: false,
      },
      events: {
        xAxisLabelClick: function (event, chartContext, config) {
          chartContext.toggleDataPointSelection(0, config.labelIndex)
          //selectItem(config.labelIndex)
        },
        dataPointSelection: function (event, chartContext, config) {
          selectItem(config.selectedDataPoints[0].length > 0 ? config.dataPointIndex : null)
        },
      },
    },
    grid: {
      show: true,
      showAlways: true,
      strokeDashArray: 1,
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 2,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: '40',
        borderRadius: 8,
        barHeight: '70%',
        borderRadiusApplication: 'end',
        distributed: true,
        colors: {
          backgroundBarColors: ['var(--text-primary-color'],
          backgroundBarOpacity: 0.12,
          backgroundBarRadius: 0,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    series: [
      {
        name: 'Series 1',
        data: series,
      },
    ],
    xaxis: {
      categories: categories.map(c => [c[0], c[1], c[2]]),
      labels: {
        style: { cssClass: 'agency-house-analytics-incomes-chart-x-labels' },
        rotate: 0,
        offsetY: -4,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: true,
      showAlways: true,
      decimalsInFloat: 0,
      floating: true,
      labels: {
        show: true,
        align: 'right',
        minWidth: Number(yAxisWidth.replace('px', '')),
        style: {
          colors: ['red', 'green'],
          fontSize: '12px',
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 400,
          cssClass: 'agency-house-analytics-incomes-chart-y-labels',
        },
        offsetX: -13,
        offsetY: 0,
        rotate: 0,
        formatter: value => {
          return Intl.NumberFormat('en', { notation: 'compact' }).format(value)
        },
      },
      axisBorder: {
        show: true,
        color: 'var(--text-primary-color)',
        offsetX: 0,
        offsetY: 0,
      },
    },
    tooltip: {
      enabled: false,
    },
  }

  useEffect(() => {
    if (chartContainerRef.current) {
      const barContainerWidth = 115 // px
      const chartEvaluatedWidth = series.length * barContainerWidth
      if (chartEvaluatedWidth > chartContainerRef.current.clientWidth) setChartWidth(chartEvaluatedWidth)
    }
  }, [series])

  useLayoutEffect(() => {
    const categoriesContent = categories.map(c => c[2])
    for (const span of document.getElementsByTagName('tspan')) {
      const index = categoriesContent.findIndex(itm => itm === span.textContent)
      if (index >= 0) {
        span.style.fill = categories[index][3]
      }
    }
  })

  return (
    <div className='agency-house-analytics-incomes-chart-container'>
      <header>
        <p>{totalsName}</p>
        <p>{totalsValue}</p>
      </header>
      <div
        className={classNames('agency-house-analytics-incomes-chart-axis-wrapper', {
          'no-chart-data': series.length === 0,
        })}
      >
        {series.length === 0 ? (
          <AgencyHouseAnalyticsIncomesNoData />
        ) : (
          <>
            <div className='agency-house-analytics-incomes-chart-yaxis-wrapper'>
              <ReactApexChart
                options={{
                  ...options,
                  chart: {
                    id: null,
                    toolbar: {
                      show: false,
                    },
                  },
                  grid: { show: false },
                }}
                series={[{ data: series, name: 'Series 1' }]}
                type='bar'
                height={'260px'}
                width={yAxisWidth}
              />
            </div>
            <div className='agency-house-analytics-incomes-chart-wrapper' ref={chartContainerRef}>
              <div className='agency-house-analytics-incomes-chart'>
                <ReactApexChart
                  options={{
                    ...options,
                    yaxis: { show: false },
                  }}
                  series={[{ data: series, name: 'Series 1' }]}
                  type='bar'
                  height={'260px'}
                  width={chartWidth}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default AgencyHouseAnalyticsIncomesChart
