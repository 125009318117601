export const irregularReason = {
  default: 0,
  missingPayment: 1,
  negativePayment: 2,
  noPremium: 4,
  commissionRate: 8,
  elementaryNegativePayment: 16,
  elementaryPositivePremiumNegativeCommission: 32,
  commissionZero: 64,
  accumulationPremiumZero: 128,
  commissionAmountDowngrade: 256,
  wrongCommissionAmountForNewPolicy: 512,
  wrongCommissionRateForCoverage: 1024,
  elementaryPolicyAboutToEnd: 2048,
  lifePolicyAboutToEnd: 4096,
  noAccumulation: 8192,
}

export const irregularReasonObject = {
  [irregularReason.missingPayment]: {
    name: 'פוליסה נגרעה מדו"ח קודם',
    nameEn: 'Policy was cut from prev report',
    order: 1,
  },
  [irregularReason.negativePayment]: {
    name: 'פרמיה או עמלה שלילית',
    nameEn: 'Negative commission or premium',
    order: 2,
  },
  [irregularReason.elementaryNegativePayment]: {
    name: 'פרמיה או עמלה שלילית באלמנטר',
    nameEn: 'Premium or negative commission in Elementary',
    order: 3,
  },
  [irregularReason.elementaryPositivePremiumNegativeCommission]: {
    name: 'עמלה שלילית עם פרמיה חיובית',
    nameEn: 'Negative premium with positive commission',
    order: 4,
  },
  [irregularReason.noPremium]: {
    name: 'פרמיה 0',
    nameEn: 'Premium is 0',
    order: 5,
  },
  [irregularReason.commissionRate]: {
    name: 'ירידה בשיעור עמלה',
    nameEn: 'Decrease in commission rate',
    order: 6,
  },
  [irregularReason.commissionZero]: {
    name: 'פוליסות עמלה 0',
    nameEn: '0 commission policies',
    order: 7,
  },
  [irregularReason.accumulationPremiumZero]: {
    name: 'צבירה מאופסת',
    nameEn: 'Zero Accumulation',
    order: 8,
  },
  [irregularReason.commissionAmountDowngrade]: {
    name: 'ירידה בסכום העמלה',
    nameEn: 'Commission Amount Downgrade',
    order: 9,
  },
  [irregularReason.wrongCommissionAmountForNewPolicy]: {
    name: 'סכום עמלה לא תקין בפוליסה חדשה',
    nameEn: 'Wrong Commission Amount For New Policy',
    order: 10,
  },
  [irregularReason.wrongCommissionRateForCoverage]: {
    name: 'שיעור עמלה לא תקין לכיסוי',
    nameEn: 'Wrong Commission Rate For Coverage',
    order: 11,
  },
  [irregularReason.elementaryPolicyAboutToEnd]: {
    name: 'פוליסה אלמנטרית עומדת להגמר',
    nameEn: 'Elementary Policy About To End',
    order: 12,
  },
  [irregularReason.lifePolicyAboutToEnd]: {
    name: 'פוליסת חיים/פיננסי עומדת להגמר',
    nameEn: 'Life Policy About To End',
    order: 13,
  },
  [irregularReason.noAccumulation]: {
    name: 'אין צבירה',
    nameEn: 'No Accumulation',
    order: 14,
  },
}
