import { NavLink } from 'react-router-dom'
import './AgencyHouseAnalyticsIncomesNoData.scss'
import { useTranslation } from 'react-i18next'
import { BarChartRounded } from '@mui/icons-material'
import { agencyHouseRoutes } from '../../../../environment/urls'

const AgencyHouseAnalyticsIncomesNoData = () => {
  const { t } = useTranslation('agencyHouseAnalytics')
  return (
    <div className='agency-house-analytics-incomes-no-data-container'>
      <BarChartRounded />
      <p>{t('noDataIncomes')}</p>
      <NavLink to={agencyHouseRoutes.download}>{t('downloadReports')}</NavLink>
    </div>
  )
}

export default AgencyHouseAnalyticsIncomesNoData
