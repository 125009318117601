import $api from '..'
import { urlRegistration } from '../../environment/urls'

export const getRegisterCodeRequest = async ({ isEmail, value }) =>
  await $api.post(`${urlRegistration()}account/requestRegisterCode`, { isEmail, value })

export const sendRegisterCodeRequest = async ({ isEmail, value, phone, code }) =>
  await $api.post(`${urlRegistration()}account/confirmRegisterCode`, { isEmail, value, phone, code })

export const getLoginCodeRequest = async ({ isEmail, value }) =>
  await $api.post(`${urlRegistration()}account/requestLoginCode`, { isEmail, value })

export const sendLoginCodeRequest = async ({ isEmail, value, phone, code }) =>
  await $api.post(`${urlRegistration()}account/confirmLoginCode`, { isEmail, value, phone, code })

  export const sendConfirmLogoutRequest = async ({ appId }) =>
  await $api.delete(`${urlRegistration()}account/confirm-logout/${appId}`)
