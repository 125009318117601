export const bytesPrettyPrint = (bytes, decimals = 2) => {
  let units = ['b', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb']

  let i = 0

  for (i; bytes > 1024; i++) {
    bytes /= 1024
  }

  return `${parseFloat(bytes.toFixed(decimals))}${units[i]}`
}
