import { useTranslation } from 'react-i18next'
import ButtonText from '../../../../components/Common/ButtonText/ButtonText'
import { useEffect, useState } from 'react'
import Loader from '../../../../components/Common/Loader/Loader'
import { getProfileNextPaymentRequest } from '../../../../http/requests/profileRequests'
import NoData from '../../../../components/Common/NoData/NoData'
import { getMonthNameByNumber } from '../../../../services/getMonthName'
import PaymentFrameModal from '../../../../components/PaymentFrameModal/PaymentFrameModal'
import { cancelSubscription, getCurrentSubscription, renewSubscription } from '../../../../http/requests'
import ConfirmDialog from '../../../../components/ConfirmDialog/ConfirmDialog'
import ButtonRounded from '../../../../components/Common/ButtonRounded/ButtonRounded'
import LoadingOverlay from '../../../../components/Common/LoadingOverlay/LoadingOverlay'
import { daysToEndOfMonth, hoursToEndOfDay } from '../../../../utils/date'
import './ProfileNextPayment.scss'

const ProfileNextPayment = () => {
  const { t, i18n } = useTranslation('profile')
  const [payment, setPayment] = useState(null)
  const [paymentLoading, setPaymentLoading] = useState(true)
  const [showUpdateCard, setShowUpdateCard] = useState(false)
  const [subscription, setSubscription] = useState(null)
  const [subscriptionLoading, setSubscriptionLoading] = useState(true)
  const [cancelLoading, setCancelLoading] = useState(false)
  const [showCancelConfirmModal, setShowCancelConfirmModal] = useState(false)

  const subscriptionCancelled = subscription?.isCancelled
  const cancelText = subscriptionCancelled ? 'renew' : 'cancel'

  const cancelButtonClickHander = () => {
    subscriptionCancelled ? toggleSubscriptionHandler() : setShowCancelConfirmModal(true)
  }

  const toggleSubscriptionHandler = async () => {
    const toggleSubscriptionRequest = subscriptionCancelled ? renewSubscription : cancelSubscription
    try {
      setCancelLoading(true)
      const {
        data: { Data: result },
      } = await toggleSubscriptionRequest()
      if (result) {
        setSubscription(prev => ({ ...prev, isCancelled: !subscriptionCancelled }))
      }
      setCancelLoading(false)
    } catch (error) {
      setCancelLoading(false)
    }
  }

  const date = () => {
    const [year, month, day] = payment?.nextPaymentDate?.split('T')[0].split('-')
    return `${+day} ${getMonthNameByNumber(month, i18n.language)}, ${year}`
  }

  useEffect(() => {
    const fetchData = async (setData, setDataLoading, request) => {
      try {
        setDataLoading(true)
        const {
          data: { data },
        } = await request()
        setData(data)
        setDataLoading(false)
      } catch (error) {
        console.log(error)
        setDataLoading(false)
      }
    }

    fetchData(setPayment, setPaymentLoading, getProfileNextPaymentRequest)
    fetchData(setSubscription, setSubscriptionLoading, getCurrentSubscription)
  }, [])

  return (
    <>
      <div className='profile-next-payment'>
        {subscriptionLoading ? (
          <Loader />
        ) : (
          <section className='profile-next-payment-cancel-container'>
            {cancelLoading && <LoadingOverlay />}
            {subscription.isPaidByAssociation ? (
              <>
                <div className='profile-next-payment-cancel-info'>
                  <h5>{t(`payments.cancelSubscription`)}</h5>
                  <p>{t(`payments.paidByAssociation`)}</p>
                </div>
              </>
            ) : (
              <>
                <div className='profile-next-payment-cancel-info'>
                  <h5>{t(`payments.${cancelText}Subscription`)}</h5>
                  <p>{t(`payments.${cancelText}SubscriptionDesc`)}</p>
                  <ButtonRounded
                    accent={subscriptionCancelled ? 'success' : 'danger'}
                    onClick={cancelButtonClickHander}
                  >
                    {t(`payments.${cancelText}Subscription`)}
                  </ButtonRounded>
                </div>
                {subscriptionCancelled && (
                  <div className='profile-next-payment-cancel-in'>
                    <p>{t('payments.cancelIn')}</p>
                    <p>{t('payments.daysHours', { days: daysToEndOfMonth(), hours: hoursToEndOfDay() })}</p>
                  </div>
                )}
              </>
            )}
          </section>
        )}
        <header>
          <p>{t('payments.nextDetails')}</p>
          <div>
            <ButtonText onClick={() => setShowUpdateCard(true)}>{t('payments.updateCard')}</ButtonText>
            {/* <ButtonText>{t('payments.howIsCalc')}</ButtonText>*/}
          </div>
        </header>
        {paymentLoading && <Loader />}
        {!paymentLoading && !payment && <NoData text='Nothing Found' />}
        {!paymentLoading && payment && (
          <section>
            <div className='profile-next-payment-details-card'>
              <p>{t('payments.nextDate')}</p>
              <p>{date()}</p>
            </div>
            <div className='profile-next-payment-details-card'>
              <p>{t('payments.customers')}</p>
              <p>{new Intl.NumberFormat().format(payment.customers)}</p>
            </div>
            <div className='profile-next-payment-details-card'>
              <p>{t('payments.users')}</p>
              <p>{payment.users}</p>
            </div>
            <div className='profile-next-payment-details-card'>
              <p>{t('payments.total')}</p>
              <p>₪{payment.total.toFixed(2)}</p>
            </div>
          </section>
        )}
      </div>
      {showUpdateCard && (
        <PaymentFrameModal
          closeModal={() => setShowUpdateCard(false)}
          onPaymentSuccess={() => setShowUpdateCard(false)}
        />
      )}
      {showCancelConfirmModal && (
        <ConfirmDialog
          confirm={toggleSubscriptionHandler}
          decline={() => {
            setShowCancelConfirmModal(false)
          }}
          title={t(`paymentPlanCard.${cancelText}ModalTitle`)}
          text={t(`paymentPlanCard.${cancelText}ModalText`)}
          declineText={t(`paymentPlanCard.cancelModalBtnNo`)}
          confirmText={t(`paymentPlanCard.cancelModalBtnYes`)}
        />
      )}
    </>
  )
}

export default ProfileNextPayment
