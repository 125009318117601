import { useTranslation } from 'react-i18next'
import './MarketingConnectionBanner.scss'
import { companyConnection } from '../../../../types/companies'
import { hebrew } from '../../../../i18n'

const MarketingConnectionBanner = () => {
  const { t } = useTranslation('common')

  return (
    <div className='marketing-connection-banner-wrapper'>
      <section className='marketing-connection-banner-container'>
        <h4>{t('marketingConnectionType.heading')}</h4>
        <div className='marketing-connection-banner-cards'>
          {Object.values(companyConnection).map(c => (
            <div className='marketing-connection-banner-card' key={c.id}>
              <div className='marketing-connection-banner-card-icon'>
                <img src={c.iconPath} alt={c.name} />
              </div>
              <h4 className='marketing-connection-banner-card-name'>{hebrew() ? c.name : c.nameEn}</h4>
              <p className='marketing-connection-banner-card-desc'>{hebrew() ? c.description : c.descriptionEn}</p>
            </div>
          ))}
        </div>
      </section>
    </div>
  )
}

export default MarketingConnectionBanner
