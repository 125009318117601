import classNames from 'classnames'
import ButtonCheckbox from '../../../../components/Common/ButtonCheckbox/ButtonCheckbox'
import MeatBallsMenu from '../../../../components/Common/MeatBallsMenu/MeatBallsMenu'
import './AgenciesListItem.scss'

const AgenciesListItem = ({ children, onClick, menuItems = [] }) => {
  const onClickHandler = () => {
    onClick()
  }
  return (
    <div
      className={classNames('agencies-list-item agencies-list-item-grid', { clickable: onClick })}
      {...(onClick && { onClick: onClickHandler })}
    >
      <div>
        <ButtonCheckbox
          onClick={e => {
            e.stopPropagation()
            console.log('checkbox clicked')
          }}
        />
      </div>
      {children}
      <div>
        <MeatBallsMenu menuItems={menuItems} />
      </div>
    </div>
  )
}

export default AgenciesListItem
