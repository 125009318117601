import { useTranslation } from 'react-i18next'
import './ProfilePaymentsHistory.scss'
import { useEffect, useState } from 'react'
import { getProfilePaymentsHistoryRequest } from '../../../../http/requests/profileRequests'
import Loader from '../../../../components/Common/Loader/Loader'
import NoData from '../../../../components/Common/NoData/NoData'
import { Download } from '@mui/icons-material'
import { formatAsShortDate } from '../../../../utils/formatAs'
import ButtonText from '../../../../components/Common/ButtonText/ButtonText'

const ProfilePaymentsHistory = () => {
  const { t } = useTranslation('profile')
  const [payments, setPayments] = useState([])
  const [paymentsLoading, setPaymentsLoading] = useState(true)
  const [showAll, setShowAll] = useState(false)

  useEffect(() => {
    const fetchPaymnets = async () => {
      try {
        setPaymentsLoading(true)
        const {
          data: { data },
        } = await getProfilePaymentsHistoryRequest()
        setPayments(data)
        setPaymentsLoading(false)
      } catch (error) {
        console.log(error)
        setPaymentsLoading(false)
      }
    }

    fetchPaymnets()
  }, [])

  return (
    <div className='profile-payments-history-container'>
      <p>{t('payments.historyHeader')}</p>
      <section>
        {paymentsLoading && <Loader />}
        {!paymentsLoading && payments.length === 0 && <NoData text={'No payments'} />}
        {!paymentsLoading && (
          <>
            <div className='profile-payments-history-grid profile-payments-history-item-heading'>
              <p>{t('payments.historyDate')}</p>
              <p>{t('payments.historyCustomers')}</p>
              <p>{t('payments.historyTotal')}</p>
              <div></div>
            </div>
            {payments.slice(0, showAll ? payments.length : 5).map(p => (
              <div key={p.id} className='profile-payments-history-grid profile-payments-history-item'>
                <p>{formatAsShortDate(p.date, '.', { fullYear: true })}</p>
                <p>{new Intl.NumberFormat('en-US').format(p.customers)}</p>
                <p>₪{p.total.toFixed(2)}</p>
                <div>
                  {p.invoiceId && (
                    <button>
                      <Download />
                    </button>
                  )}
                </div>
              </div>
            ))}
            <div>
              <ButtonText onClick={() => setShowAll(true)}>{t('payments.historyShowAll')}</ButtonText>{' '}
            </div>
          </>
        )}
      </section>
    </div>
  )
}

export default ProfilePaymentsHistory
