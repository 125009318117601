import { useState, useEffect } from 'react'
import './NotifyDialog.css'

const NotifyDialog = ({
  confirm = () => {},
  confirmText = 'OK',
  text,
  closeNotify = () => {},
  closeNotifyTimout = 5000,
  timeoutStep = 1000,
  onCloseAction = () => {},
}) => {
  const [counter, setCounter] = useState(closeNotifyTimout / 1000)

  const confirmHandler = () => {
    confirm()
    closeNotify()
  }

  useEffect(() => {
    const timerId = setInterval(() => {
      if (counter > 0 && counter >= timeoutStep / 1000) setCounter(counter - timeoutStep / 1000)
      else {
        onCloseAction()
        closeNotify()
      }
    }, timeoutStep)
    return () => {
      clearInterval(timerId)
    }
  })

  return (
    <div className='notify-dialog-modal-container'>
      <div className='notify-dialog-modal'>
        <div className='notify-dialog-modal-content'>
          <div className='notify-dialog-modal-content-text'>{text}</div>
          <div className='notify-dialog-modal-content-counter'>{counter.toFixed(timeoutStep % 1000 === 0 ? 0 : 1)}</div>
        </div>
        <div className='notify-dialog-modal-btns'>
          <button onClick={() => confirmHandler()}>{confirmText}</button>
        </div>
      </div>
    </div>
  )
}

export default NotifyDialog
