import React from 'react'

const NoChartDataSvgIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='129' height='60' viewBox='0 0 129 60' fill='none'>
      <g opacity='0.4'>
        <path
          d='M33.6968 34.3794H24.5442L9.63184 50.0039H18.7837L33.6968 34.3794Z'
          fill='#02B64A'
          stroke='#020000'
          strokeWidth='0.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M24.5435 34.3794H33.696L48.6084 50.0039H39.4559L24.5435 34.3794Z'
          fill='#02B64A'
          stroke='#020000'
          strokeWidth='0.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M70.7857 25.7402H61.6332L39.4839 50.0041H48.6358L70.7857 25.7402Z'
          fill='#02B64A'
          stroke='#020000'
          strokeWidth='0.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path d='M61.6021 25.7402H70.7546L92.9039 50.0041H83.7513L61.6021 25.7402Z' fill='#02B64A' />
        <mask
          id='mask0_1254_91684'
          style={{ maskType: 'luminance' }}
          maskUnits='userSpaceOnUse'
          x='61'
          y='25'
          width='32'
          height='26'
        >
          <path d='M61.6021 25.7402H70.7546L92.9039 50.0041H83.7513L61.6021 25.7402Z' fill='white' />
        </mask>
        <g mask='url(#mask0_1254_91684)'>
          <path
            d='M66.9326 37.0561C68.718 30.7033 82.7781 50.4676 88.5807 44.35C94.3832 38.2325 94.7611 52.9928 94.7611 52.9928L75.6365 53.3562L69.3876 50.2739'
            fill='#02923B'
          />
        </g>
        <path
          d='M61.6021 25.7402H70.7546L92.9039 50.0041H83.7513L61.6021 25.7402Z'
          stroke='#020000'
          strokeWidth='0.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M113.966 16.043L105.221 15.3882L83.7021 50.0037H92.8547L113.966 16.043Z'
          fill='#02B64A'
          stroke='#020000'
          strokeWidth='0.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path d='M111.546 11.9316L95.8604 17.2503L119.368 20.9936L111.546 11.9316Z' fill='#02B64A' />
        <mask
          id='mask1_1254_91684'
          style={{ maskType: 'luminance' }}
          maskUnits='userSpaceOnUse'
          x='95'
          y='11'
          width='25'
          height='10'
        >
          <path d='M111.546 11.9316L95.8604 17.2503L119.368 20.9936L111.546 11.9316Z' fill='white' />
        </mask>
        <g mask='url(#mask1_1254_91684)'>
          <path
            d='M112.684 10.2329L111.546 11.9312C111.546 11.9312 96.615 18.703 96.3918 19.0563C96.1686 19.4095 89.8081 14.9387 89.8081 14.9387L102.529 11.2329'
            fill='#02923B'
          />
        </g>
        <path
          d='M111.546 11.9316L95.8604 17.2503L119.368 20.9936L111.546 11.9316Z'
          stroke='#020000'
          strokeWidth='0.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M108.649 17.3872L107.197 17.752'
          stroke='#020000'
          strokeWidth='0.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M55.988 38.6582L54.3486 39.3736'
          stroke='#020000'
          strokeWidth='0.3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  )
}

export default NoChartDataSvgIcon
