import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ModalWindow from '../../ModalWindow/ModalWindow'
import Button from '../Button/Button'
import InputTextArea from '../InputTextArea/InputTextArea'
import './ModalWithNote.scss'

const ModalWithNote = ({
  onAddNote = () => {},
  closeModal,
  closeOnOutsideClick,
  title,
  subtitle,
  label,
  btnCaption,
  back,
  defaultNote,
}) => {
  const [note, setNote] = useState(defaultNote)
  const { t } = useTranslation('customer')

  const addNoteHandler = text => {
    setNote(text)
  }

  const backHandler = () => {
    back()
    closeModal()
  }

  const onAddNoteHandler = () => {
    onAddNote(note)
    closeModal()
  }

  const disabled = () => !note?.trim().length > 0

  return (
    <ModalWindow closeModal={closeModal} closeOnOutsideClick={closeOnOutsideClick} renderPortal>
      <div className='modal-note-container'>
        <div className='modal-note-content'>
          {<div className='modal-note-title'>{title ?? t('treatNoteModal.modalTitle')}</div>}
          {<div className='modal-note-subtitle'>{subtitle ?? t('treatNoteModal.modalSubtitle')}</div>}
          <InputTextArea label={label ?? t('alreadyTreatedNoteModal.inputLabel')} onChange={addNoteHandler} />
        </div>
        <div className='modal-note-btns'>
          {back && (
            <button className='back-button' onClick={backHandler}>
              <img src='./assets/arrow-dropdown-right/Right Square.png' alt='bck' />
            </button>
          )}
          <Button
            caption={btnCaption ?? t('alreadyTreatedNoteModal.btnTitle')}
            disabled={disabled()}
            onClick={onAddNoteHandler}
          />
        </div>
      </div>
    </ModalWindow>
  )
}

export default ModalWithNote
