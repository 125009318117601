import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../../../components/Common/Button/Button'
import InputSearch from '../../../../components/Common/InputSearch/InputSearch'
import Loader from '../../../../components/Common/Loader/Loader'
import ConfirmDialog from '../../../../components/ConfirmDialog/ConfirmDialog'
import ModalWindow from '../../../../components/ModalWindow/ModalWindow'
import './AddUserModal.scss'

const AddUserModal = ({
  users,
  loading,
  onAddUser = () => {},
  closeModal,
  closeOnOutsideClick,
  currentUser,
  askConfirm = false,
}) => {
  const { t } = useTranslation('customer')

  const [selectedUser, setSelectedUser] = useState(currentUser)
  const [usersList, setUsersList] = useState([])
  const [searchText, setSearchText] = useState('')
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [prevAgent, setPrevAgent] = useState()
  const [newAgent, setNewAgent] = useState()

  const modalTitle = t('addUserModal.modalTitle')
  const modalSubtitle = t('addUserModal.modalSubtitle')
  const searchPlaceholder = t('addUserModal.searchPlaceholder')
  const btnCaption = t('addUserModal.btnTitle')

  const confirmModalTitle = t('confirmAddUserModal.modalTitle')
  const confirmModalText = t('confirmAddUserModal.modalText', { prevAgent: prevAgent?.name, newAgent: newAgent?.name })
  const confirmModalYesBtn = t('confirmAddUserModal.yesBtn')
  const confirmModalNoBtn = t('confirmAddUserModal.noBtn')

  const selectUserHandler = userId => {
    setSelectedUser(selectedUser === userId ? null : userId)
  }

  const searchUserHandler = text => {
    setSearchText(text)
  }

  const disabled = () => loading || !selectedUser || !users.find(u => u.id === selectedUser)

  const onAddUserHandler = () => {
    onAddUser(users.find(u => u.id === selectedUser))
  }

  useEffect(() => {
    setUsersList(users)
    if (users.length) {
      setPrevAgent(users.find(u => u.id === currentUser))
      setNewAgent(users.find(u => u.id === selectedUser))
    }
  }, [users, selectedUser, currentUser])

  return (
    <>
      <ModalWindow closeModal={closeModal} closeOnOutsideClick={closeOnOutsideClick} renderPortal>
        <div className='add-user-modal-container' onClick={e => e.stopPropagation()}>
          <div className='add-user-modal-content'>
            <div className='add-user-modal-title'>{modalTitle}</div>
            <div className='add-user-modal-subtitle'>{modalSubtitle}</div>
            <InputSearch placeholder={searchPlaceholder} searchHandler={searchUserHandler} />
            {loading ? (
              <Loader />
            ) : (
              <div className='add-user-modal-list'>
                {usersList
                  .filter(u => u.name.toLowerCase().includes(searchText.toLowerCase()))
                  .map(u => (
                    <div key={u.id} onClick={() => selectUserHandler(u.id)} className='add-user-modal-list-item'>
                      <div className='add-user-modal-list-item-ico'>
                        <img
                          src={
                            selectedUser === u.id
                              ? './assets/checkbox-xs-checked/Check Box .png'
                              : './assets/check-box-blank/Check Box Blank.png'
                          }
                          alt={`${selectedUser === u.id ? 'un' : ''}chk`}
                        />
                      </div>
                      <div className='add-user-modal-list-item-text'>{u.name}</div>
                    </div>
                  ))}
              </div>
            )}
          </div>
          <div className='add-user-modal-footer'>
            <Button
              caption={btnCaption}
              disabled={disabled()}
              onClick={askConfirm ? () => setShowConfirmModal(true) : onAddUserHandler}
            />
          </div>
        </div>
      </ModalWindow>
      {currentUser && currentUser !== selectedUser && showConfirmModal && (
        <ConfirmDialog
          title={confirmModalTitle}
          text={confirmModalText}
          confirmText={confirmModalYesBtn}
          declineText={confirmModalNoBtn}
          confirm={onAddUserHandler}
          decline={() => setShowConfirmModal(false)}
        />
      )}
    </>
  )
}

export default AddUserModal
