import { useTranslation } from 'react-i18next'
import {
  AddRounded,
  CancelTwoTone,
  CheckBoxOutlineBlank,
  CheckBoxRounded,
  CloseRounded,
  EditTwoTone,
  RemoveCircleTwoTone,
  SearchTwoTone,
  StarOutlined,
  StarTwoTone,
} from '@mui/icons-material'
import { useState, useEffect } from 'react'
import Loader from '../../../../components/Common/Loader/Loader'
import MeatBallsMenu from '../../../../components/Common/MeatBallsMenu/MeatBallsMenu'
import { getUserNameInitials } from '../../../../services/getUserNameInitials'
import NoData from '../../../../components/Common/NoData/NoData'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { AGENT_HOUSE_GROUPS_SELECT } from '../../../../types/actionTypes'
import { deleteAgencyGroup, getAgentHouseGroups, setAgenciesFavoriteGroup } from '../../actions/agentHouseActions'
import { useCrossDomainCookies } from '../../../../environment/urls'
import AddAgencyGroupModal from '../../../../components/AddAgencyGroupModal/AddAgencyGroupModal'
import './AgenciesGroupsPanel.scss'

const AgenciesGroupsPanel = ({ closePanel }) => {
  const {
    favoriteGroups,
    allGroups,
    selectedGroups: selected,
    groupsLoading,
  } = useSelector(({ agentHouse }) => agentHouse)
  const [selectedGroups, setSelectedGroups] = useState(selected)
  const dispatch = useDispatch()
  const [searchTerm, setSearchTerm] = useState('')
  const [showAddAgencyGroupModal, setShowAddAgencyGroupModal] = useState(false)
  const [updateGroup, setUpdateGroup] = useState(null)
  const [panelDirty, setPanelDirty] = useState(false)

  const { t } = useTranslation('agencyHouse')
  const panelHeader = t('agentGroupsPanel.header')
  const searchPlaceholder = t('agentGroupsPanel.searchPlaceholder')
  const favoritesHeader = t('agentGroupsPanel.favorites')
  const allGroupsHeader = t('agentGroupsPanel.all')
  const noGroupsText = t('agentGroupsPanel.noGroups')
  const nothingFoundText = t('agentGroupsPanel.nothingFound')

  const closePanelHandler = () => {
    if (panelDirty) {
      document.cookie = `agencyGroups=${selectedGroups.join('-')}; ${useCrossDomainCookies} ${
        selectedGroups.length === 0 ? 'expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;' : ''
      }`
      dispatch({ type: AGENT_HOUSE_GROUPS_SELECT, payload: selectedGroups })
    }
    closePanel()
  }

  const searchFilter = gr => gr.name.toLowerCase().includes(searchTerm.toLowerCase())

  const noGroups = favoriteGroups.length === 0 && allGroups.length === 0

  const nothingFound =
    !noGroups &&
    favoriteGroups.filter(searchFilter).length === 0 &&
    allGroups.filter(searchFilter).length === 0 &&
    searchTerm.length > 0

  const menuItems = () => item => {
    const menuItems = [
      {
        text: (
          <div className='agencies-groups-panel-item-menu-item'>
            {item.isFavourite ? <StarOutlined /> : <StarTwoTone />}
            <div>{t(`agentGroupsPanel.${item.isFavourite ? 'removeFrom' : 'addTo'}Favorites`)}</div>
          </div>
        ),
        clickHandler: () => {
          dispatch(setAgenciesFavoriteGroup(item))
        },
      },
    ]
    if (!item.isAgency) {
      menuItems.unshift({
        text: (
          <div className='agencies-groups-panel-item-menu-item'>
            <EditTwoTone />
            <div>{t('agentGroupsPanel.edit')}</div>
          </div>
        ),
        clickHandler: () => {
          setUpdateGroup(item)
          setShowAddAgencyGroupModal(true)
        },
      })
      menuItems.push({
        text: (
          <div className='agencies-groups-panel-item-menu-item' style={{ color: 'var(--trend-down-color)' }}>
            <RemoveCircleTwoTone />
            <div>{t(`agentGroupsPanel.deleteGroup`)}</div>
          </div>
        ),
        clickHandler: () => {
          dispatch(deleteAgencyGroup(item.id))
        },
      })
    }
    return menuItems
  }

  const selectGroupHandler = id => {
    setPanelDirty(true)
    setSelectedGroups(prev => (prev.includes(id) ? prev.filter(pr => pr !== id) : [...prev, id]))
  }

  useEffect(() => {
    dispatch(getAgentHouseGroups())
  }, [dispatch])

  return (
    <>
      <div className='agencies-groups-panel-overlay'>
        <div className='agencies-groups-panel'>
          <AgenciesGroupsPanelHeader
            closePanelHandler={closePanelHandler}
            panelHeader={panelHeader}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            searchPlaceholder={searchPlaceholder}
            showAddGroup={() => setShowAddAgencyGroupModal(true)}
          />
          <main>
            {groupsLoading ? (
              <Loader />
            ) : (
              <>
                {noGroups && <NoData text={noGroupsText} />}
                {nothingFound && <NoData text={nothingFoundText} />}
                <AgenciesGroupsPanelList
                  items={favoriteGroups.filter(searchFilter)}
                  listHeader={favoritesHeader}
                  selectedItems={selectedGroups}
                  selectItem={selectGroupHandler}
                  menuItems={menuItems()}
                />
                <AgenciesGroupsPanelList
                  items={allGroups.filter(searchFilter)}
                  listHeader={allGroupsHeader}
                  selectItem={selectGroupHandler}
                  selectedItems={selectedGroups}
                  menuItems={menuItems()}
                />
              </>
            )}
          </main>
        </div>
      </div>
      {showAddAgencyGroupModal && (
        <AddAgencyGroupModal
          closeModal={() => {
            setUpdateGroup(null)
            setShowAddAgencyGroupModal(false)
          }}
          group={updateGroup}
        />
      )}
    </>
  )
}

export default AgenciesGroupsPanel

const AgenciesGroupsPanelList = ({ items, selectedItems, selectItem, listHeader, menuItems }) =>
  items.length > 0 && (
    <section>
      <h5>{listHeader}</h5>
      <div className='agencies-groups-panel-items-container'>
        {items.map(gr => (
          <AgenciesGroupsPanelItem
            item={gr}
            key={gr.id}
            selected={selectedItems.includes(gr.id)}
            selectItem={selectItem}
            menuItems={menuItems}
          />
        ))}
      </div>
    </section>
  )

const AgenciesGroupsPanelItem = ({ item, selected, selectItem, menuItems }) => (
  <div className={classNames('agencies-groups-panel-item-container', { selected })}>
    <button onClick={() => selectItem(item.id)}>{selected ? <CheckBoxRounded /> : <CheckBoxOutlineBlank />}</button>
    <div className={classNames('agencies-groups-panel-item', { selected })}>
      {item.loading && (
        <div className='agencies-groups-panel-item-loader-overlay'>
          <Loader />
        </div>
      )}
      <AgenciesGroupsPanelItemAvatar names={item.agencies} />
      <p title={item.agencies.map(n => n.name).join(',')}>
        {item.agencies
          .slice(0, 2)
          .map(n => n.name)
          .join(',')}
        {item.agencies.length > 2 && `, +${item.agencies.length - 2}`}
      </p>
      <MeatBallsMenu menuItems={menuItems(item)} menuSize='sm' />
    </div>
  </div>
)

const AgenciesGroupsPanelItemAvatar = ({ names }) => (
  <div className='agencies-groups-panel-avatar-container'>
    {names.length > 1 ? (
      <>
        <div
          className='agencies-groups-panel-avatar-sm'
          style={{
            backgroundColor: names[0].backgroundColor,
          }}
        >
          {getUserNameInitials(names[0]?.name)}
        </div>
        <div className='agencies-groups-panel-avatar-sm' style={{ backgroundColor: names[1]?.backgroundColor }}>
          <span style={{ direction: 'ltr' }}>
            {names.length > 2 ? `+${names.length - 2}` : getUserNameInitials(names[1]?.name)}
          </span>
        </div>
      </>
    ) : (
      <div className='agencies-groups-panel-avatar' style={{ backgroundColor: names[0].backgroundColor }}>
        {getUserNameInitials(names[0].name)}
      </div>
    )}
  </div>
)

const AgenciesGroupsPanelHeader = ({
  closePanelHandler,
  panelHeader,
  searchTerm,
  setSearchTerm,
  searchPlaceholder,
  showAddGroup,
}) => {
  const [showSearch, setShowSearch] = useState(false)
  const closeSearchHandler = () => {
    setShowSearch(false)
    setSearchTerm('')
  }
  return (
    <header>
      {showSearch ? (
        <>
          <div>
            <button>
              <SearchTwoTone />
            </button>
          </div>
          <input
            type='text'
            placeholder={searchPlaceholder}
            onChange={e => setSearchTerm(e.target.value)}
            //value={searchTerm}
          />
          <div>
            <button onClick={closeSearchHandler}>
              <CancelTwoTone />
            </button>
          </div>
        </>
      ) : (
        <>
          <h4>{panelHeader}</h4>
          <div>
            <button onClick={() => setShowSearch(true)}>
              <SearchTwoTone />
            </button>
            <button onClick={showAddGroup}>
              <AddRounded />
            </button>
            <button onClick={closePanelHandler}>
              <CloseRounded />
            </button>
          </div>
        </>
      )}
    </header>
  )
}
