import { useRef } from 'react'
import CloseButton from '../../Common/CloseButton/CloseButton'
import './SearchAgentsInput.scss'

const SearchAgentsInput = ({ searchValue, setSearchValue, placeholder = 'search' }) => {
  const inputRef = useRef(null)
  const changeHandler = e => {
    setSearchValue(e.target.value)
  }

  const clearInput = () => {
    setSearchValue('')
  }

  return (
    <div className='agents-search-input-container'>
      {searchValue !== '' && (
        <div>
          <CloseButton onCloseClick={clearInput} />
        </div>
      )}
      <input type='text' placeholder={placeholder} value={searchValue} onChange={changeHandler} ref={inputRef} />
      <div onClick={() => inputRef.current.focus()}>
        <img src='./assets/search-icon/Search.png' alt='srch' className='img-icon' />
      </div>
    </div>
  )
}

export default SearchAgentsInput
