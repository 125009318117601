const CheckIconSvg = ({ fill = 'var(--text-primary-color)' }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='21' height='20' viewBox='0 0 21 20' fill='none'>
    <g id='CheckFilled'>
      <path
        id='Vector'
        d='M7.82923 13.2291L4.35423 9.75414L3.1709 10.9291L7.82923 15.5875L17.8292 5.58748L16.6542 4.41248L7.82923 13.2291Z'
        fill={fill}
      />
    </g>
  </svg>
)

export default CheckIconSvg
