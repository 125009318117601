import { useSelector } from 'react-redux'
import WorkstationDateRange from '../WorkstationDateRange/WorkstationDateRange'
import WorkstationFilter from '../WorkstationFilter/WorkstationFilter'
import WorkstationFiltersClear from '../WorkstationFiltersClear/WorkstationFiltersClear'
import './WorkstationFiltersContainer.scss'

const WorkstationFiltersContainer = () => {
  const { filters, from, to } = useSelector(({ workstation }) => workstation)
  const filtersApplied =
    Object.values(filters)
      .flatMap(({ values }) => values)
      .filter(v => v.checked).length > 0 ||
    from ||
    to

  return (
    <div className='workstation-filters'>
      <WorkstationFilter />
      <WorkstationDateRange />
      {filtersApplied && <WorkstationFiltersClear />}
    </div>
  )
}

export default WorkstationFiltersContainer
