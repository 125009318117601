import classNames from 'classnames'
import CheckMark from '../../../../components/Common/Marks/CheckMark/CheckMark'
import './CustomersFilterDropdownItem.css'

const CustomersFilterDropdownItem = ({ value, checked, setChecked, hideDropdown, disabled, disabledDesc }) => {
  const clickHandler = () => {
    if (disabled) return
    setChecked()
    hideDropdown()
  }
  return (
    <div className={classNames('customers-filter-dropdown-item', { disabled })} onClick={clickHandler}>
      <div className='customers-filter-dropdown-item-value'>{value}</div>
      {disabled && <div className='customers-filter-dropdown-item-disabled'>{disabledDesc}</div>}
      {checked && !disabled && (
        <div className='customers-filter-dropdown-item-checked-icon'>
          <CheckMark green={true} />
        </div>
      )}
    </div>
  )
}

export default CustomersFilterDropdownItem
