import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ModalWindow from '../ModalWindow/ModalWindow'
import Button from '../Common/Button/Button'
import Loader from '../Common/Loader/Loader'
import { hebrew } from '../../i18n'
import { getUserNameInitials } from '../../services/getUserNameInitials'
import { getRandomInt } from '../../services/getRandom'
import CrossMark from '../Common/Marks/CrossMark/CrossMark'
import InputText from '../Common/InputText/InputText'
import {
  AGENT_HOUSE_ADD_GROUP_RESET,
  AGENT_HOUSE_AGENCIES_RESET,
  AGENT_HOUSE_UPDATE_GROUP_RESET,
} from '../../types/actionTypes'
import NoData from '../Common/NoData/NoData'
import { avatarStyles } from '../../types/colorsTypes'
import { sortBy, sortOrder } from '../../types/sortByTypes'
import SearchAgencyInput from './SearchAgencyInput/SearchAgencyInput'
import {
  addAgenciesGroup,
  getAgentHouseAllAgencies,
  updateAgenciesGroup,
} from '../../features/agentHouse/actions/agentHouseActions'
import './AddAgencyGroupModal.scss'

const AddAgencyGroupModal = ({ closeModal, group = null }) => {
  const [searchAgency, setSearchAgency] = useState('')
  const [groupName, setGroupName] = useState(group ? group.name : '')
  const [selectedAgencies, setSelectedAgencies] = useState(group ? group.agencies : [])
  const [sort, setSort] = useState({
    by: sortBy.name,
    order: sortOrder.asc,
  })
  const { t } = useTranslation('agencyHouse')
  const {
    allAgencies,
    allAgenciesLoading,
    addGroupLoading,
    addGroupSuccess,
    updateGroupSuccess,
    favoriteGroups,
    allGroups,
  } = useSelector(({ agentHouse }) => agentHouse)
  const groupToUpdate = [...favoriteGroups, ...allGroups].find(gr => gr.id === group?.id)
  const updateGroupLoading = groupToUpdate && groupToUpdate.loading
  const filteredAgencies = allAgencies.filter(an => an.name.toLowerCase().includes(searchAgency.toLowerCase()))

  const setSortHandler = sortBy => {
    if (sort.by === sortBy) {
      setSort(prev => {
        return {
          ...prev,
          order: prev.order !== sortOrder.asc ? sortOrder.asc : sortOrder.desc,
        }
      })
    } else {
      setSort({ by: sortBy, order: sortOrder.asc })
    }
  }

  const comparator = (a, b) => {
    const key = hebrew() ? 'name' : 'name'
    const x = a[key]
    const y = b[key]
    if (x === y) {
      if (a.name && b.name) {
        return a.name.normalize().localeCompare(b.name.normalize())
      } else if (!a.name) {
        return 1
      } else if (!b.name) {
        return -1
      }
    } else if (x === null) {
      return 1
    } else if (y === null) {
      return -1
    }
    return sort.order === sortOrder.asc
      ? x.normalize().localeCompare(y.normalize())
      : y.normalize().localeCompare(x.normalize())
  }

  const dispatch = useDispatch()

  const submitBtnDisabled =
    updateGroupLoading ||
    allAgenciesLoading ||
    addGroupLoading ||
    allAgencies.length === 0 ||
    (selectedAgencies.length === 0 && !group) ||
    groupName.length === 0

  const selectAgencyHandler = agency => {
    setSelectedAgencies(prev =>
      prev.map(a => a.id).includes(agency.id) ? prev.filter(a => a.id !== agency.id) : [...prev, agency]
    )
  }

  const selectAllAgenciesHandler = () => {
    // deselect if all are selected
    if (filteredAgencies.every(({ id }) => selectedAgencies.map(fa => fa.id).includes(id))) {
      setSelectedAgencies(prev => prev.filter(an => !filteredAgencies.map(fa => fa.id).includes(an.id)))
    } else {
      //select all if some are not
      const notIncluded = filteredAgencies.filter(({ id }) => !selectedAgencies.map(fa => fa.id).includes(id))
      setSelectedAgencies(prev => [...prev, ...notIncluded])
    }
  }

  const addGroupHandler = () => {
    dispatch(
      group
        ? updateAgenciesGroup({ ...group, name: groupName, agencies: selectedAgencies })
        : addAgenciesGroup({
            name: groupName,
            agencies: selectedAgencies,
          })
    )
  }

  useEffect(() => {
    dispatch(getAgentHouseAllAgencies())
    return () => dispatch({ type: AGENT_HOUSE_AGENCIES_RESET })
  }, [dispatch])

  useEffect(() => {
    if ((addGroupSuccess && !addGroupLoading) || (group && updateGroupSuccess && !updateGroupLoading)) closeModal()
    return () => {
      addGroupSuccess && dispatch({ type: AGENT_HOUSE_ADD_GROUP_RESET })
      group && updateGroupSuccess && dispatch({ type: AGENT_HOUSE_UPDATE_GROUP_RESET })
    }
  }, [addGroupSuccess, addGroupLoading, updateGroupSuccess, group, updateGroupLoading, closeModal, dispatch])

  return (
    <ModalWindow
      closeOnOutsideClick={false}
      closeModal={closeModal}
      renderPortal
      showCloseIcon={false}
      loadingOverlay={addGroupLoading || updateGroupLoading}
    >
      <div className='add-agency-group-modal'>
        <header className='add-agency-group-modal-header'>
          <div>{t('agencyGroupsModal.title')}</div>
          <button onClick={closeModal}>
            <img src='./assets/close-icon-square/close-square.png' alt='cls' />
          </button>
        </header>
        <div className='add-agency-group-modal-content'>
          <div className='add-agency-group-modal-sidebar'>
            <InputText
              value={groupName}
              onChange={setGroupName}
              required={true}
              label={t('agencyGroupsModal.namePlaceholder')}
            />
            <div className='add-agency-group-modal-selected-heading'>{t('agencyGroupsModal.selectedAgencies')}</div>
            <div className='add-agency-group-modal-selected-items'>
              {selectedAgencies.map(an => (
                <div className='add-agency-group-modal-selected-item' key={an.id}>
                  <div
                    className='add-agency-group-modal-list-selected-item-avatar'
                    style={avatarStyles[an?.colorIndex ?? getRandomInt(1, 3) - 1]}
                  >
                    {getUserNameInitials(an.name)}
                  </div>
                  <div>{an.name}</div>
                  <div onClick={() => selectAgencyHandler(an)}>
                    <CrossMark />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className='add-agency-group-modal-main'>
            <div className='add-agency-group-modal-main-content'>
              {allAgenciesLoading ? (
                <Loader />
              ) : allAgencies.length === 0 ? (
                <NoData text={t('agencyGroupsModal.noAgencies')} />
              ) : (
                <>
                  <div className='add-agency-group-search-container'>
                    <SearchAgencyInput
                      searchValue={searchAgency}
                      setSearchValue={setSearchAgency}
                      placeholder={t('agencyGroupsModal.searchAgencyPlaceholder')}
                    />
                  </div>
                  <div className='add-agency-group-modal-list-container'>
                    {filteredAgencies.length === 0 ? (
                      <div className='add-agency-group-modal-no-data-container'>
                        <NoData text={t('agencyGroupsModal.nothingFound')} />
                      </div>
                    ) : (
                      <>
                        <div className='add-agency-group-modal-list-heading add-agency-group-modal-list-grid'>
                          <div>
                            {filteredAgencies.length > 0 && (
                              <img
                                src={`./assets/${
                                  filteredAgencies.every(({ id }) => selectedAgencies.map(fa => fa.id).includes(id))
                                    ? 'checkbox-sm-checked/Square.png'
                                    : 'check-box-blank/Check Box Blank.png'
                                }`}
                                alt='slct'
                                onClick={selectAllAgenciesHandler}
                              />
                            )}
                          </div>
                          <div onClick={() => setSortHandler(sortBy.name)} className='sortable-column'>
                            {t('agencyGroupsModal.agencyName')}
                            {sort.by === sortBy.name && (
                              <i
                                className={`fas fa-sort-alpha-down${sort.order === sortOrder.asc ? '' : '-alt'} fa-lg`}
                              ></i>
                            )}
                          </div>
                        </div>
                        <div className='add-agency-group-modal-list'>
                          {filteredAgencies.sort(comparator).map(an => (
                            <div
                              key={an.id}
                              className='add-agency-group-modal-list-item add-agency-group-modal-list-grid'
                            >
                              <div>
                                <img
                                  src={`./assets/${
                                    selectedAgencies.map(a => a.id).includes(an.id)
                                      ? 'checkbox-sm-checked/Square.png'
                                      : 'check-box-blank/Check Box Blank.png'
                                  }`}
                                  alt='slct'
                                  onClick={() => selectAgencyHandler(an)}
                                />
                              </div>
                              <div className='add-agency-group-modal-list-item-name'>
                                <div
                                  className='add-agency-group-modal-list-item-avatar'
                                  style={avatarStyles[an?.colorIndex ?? getRandomInt(1, 3) - 1]}
                                >
                                  {getUserNameInitials(an.name)}
                                </div>
                                <div>{an.name}</div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
            <footer className='add-agency-group-modal-footer'>
              <Button caption={t('agencyGroupsModal.addBtn')} disabled={submitBtnDisabled} onClick={addGroupHandler} />
            </footer>
          </div>
        </div>
      </div>
    </ModalWindow>
  )
}

export default AddAgencyGroupModal
