import LandingHeader from '../../features/landing/components/LandingHeader/LandingHeader'
import LandingFooter from '../../features/landing/components/LandingFooter/LandingFooter'
import LoginStep from '../../features/login/components/LoginStep/LoginStep'
import './LoginScreen.scss'

const LoginScreen = () => {
  return (
    <div className='login-wrapper'>
      <LandingHeader />
      <LoginStep />
      <LandingFooter />
    </div>
  )
}

export default LoginScreen
