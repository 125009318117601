import { cssColor } from './cssColors'

export const colorTypes = {
  red: 'red',
  redFaded: 'red-faded',
  green: 'green',
  orange: 'orange',
  grey: 'grey',
  white: 'white',
}

export const avatarStyles = [
  {
    background: cssColor.greenClient,
  },
  { background: cssColor.orangeClient, color: 'black' },
  { background: cssColor.redClient },
]
