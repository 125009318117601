import React, { useEffect, useState } from 'react'
import CustomersFieldsPolicies from '../CustomersFieldsPolicies/CustomersFieldsPolicies'
import { useParams } from 'react-router-dom'
import { getCustomerSalesPotentialRequest } from '../../../../http/requests/customerRequests'
import { familyGroupMode } from '../../../shared/reducers/sharedSelectors'
import './CustomerSalesPotentialContainer.scss'

const CustomerSalesPotentialContainer = () => {
  const { id } = useParams()
  const calculateByGroup = familyGroupMode()
  const [fields, setFields] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchPotentialFields = async () => {
      try {
        setLoading(true)
        setFields([])
        const {
          data: { data },
        } = await getCustomerSalesPotentialRequest(id, calculateByGroup)
        setFields(data)
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }
    fetchPotentialFields()
  }, [id, calculateByGroup])

  return (
    <div className='customer-potential-policies-container'>
      <CustomersFieldsPolicies fields={fields} loading={loading} potentialFields />
    </div>
  )
}

export default CustomerSalesPotentialContainer
