import {
  WORKSTATION_TASKS_FAIL,
  WORKSTATION_TASKS_REQUEST,
  WORKSTATION_TASKS_SUCCESS,
  WORKSTATION_RESET,
  WORKSTATION_SET_TASK_STATUS_REQUEST,
  WORKSTATION_SET_TASK_STATUS_SUCCESS,
  WORKSTATION_SET_TASK_STATUS_FAIL,
  WORKSTATION_TASK_AGENT_REQUEST,
  WORKSTATION_TASK_AGENT_SUCCESS,
  WORKSTATION_TASK_AGENT_FAIL,
  WORKSTATION_TASK_AGENT_RESET,
  WORKSTATION_FILTERS_REQUEST,
  WORKSTATION_FILTERS_SUCCESS,
  WORKSTATION_FILTERS_FAIL,
  WORKSTATION_FILTER_APPLY,
  WORKSTATION_FILTERS_CLEAR,
  WORKSTATION_TASKS_SEARCH,
  WORKSTATION_SET_SORT,
  WORKSTATION_SET_FROM,
  WORKSTATION_SET_TO,
  WORKSTATION_RESET_FROMTO,
} from '../../../types/actionTypes'
import { customerTaskStatusType } from '../../../types/customerTaskTypes'
import { sortBy, sortOrder } from '../../../types/sortByTypes'

const initialState = {
  tasks: [],
  monetaryValue: 0,
  tasksLoading: true,
  tasksError: null,
  filters: {},
  filtersLoading: true,
  filtersError: null,
  dates: [],
  taskStatusLoading: {},
  taskAgentLoading: false,
  taskAgentSuccess: false,
  selectedTasks: [],
  search: '',
  sort: {
    by: sortBy.customerName,
    order: sortOrder.asc,
  },
  from: null,
  to: null,
}

export const workstationReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case WORKSTATION_TASKS_REQUEST:
      const showLoader = payload
      return {
        ...state,
        tasks: showLoader ? [] : state.tasks,
        monetaryValue: 0,
        tasksLoading: showLoader,
        tasksError: null,
      }
    case WORKSTATION_TASKS_SUCCESS:
      const { tasksTreated, tasksWaiting, monetaryValue } = payload
      return {
        ...state,
        tasks: [...tasksWaiting, ...tasksTreated],
        monetaryValue,
        tasksLoading: false,
        tasksError: null,
      }
    case WORKSTATION_TASKS_FAIL:
      return {
        ...state,
        tasks: [],
        monetaryValue: 0,
        tasksLoading: false,
        tasksError: payload,
      }
    case WORKSTATION_FILTERS_REQUEST:
      return {
        ...state,
        filters: {},
        dates: [],
        filtersLoading: false,
        filtersError: null,
      }
    case WORKSTATION_FILTERS_SUCCESS:
      const { filters, dates } = payload
      return {
        ...state,
        filters,
        dates,
        filtersLoading: false,
        filtersError: null,
      }
    case WORKSTATION_FILTERS_FAIL:
      return {
        ...state,
        filters: {},
        dates: [],
        filtersLoading: false,
        filtersError: payload,
      }
    case WORKSTATION_FILTER_APPLY:
      const { filterId, valueId } = payload
      return {
        ...state,
        filters: {
          ...state.filters,
          [filterId]: {
            ...state.filters[filterId],
            values: state.filters[filterId].values.map(v => (v.id === valueId ? { ...v, checked: !v.checked } : v)),
          },
        },
      }
    case WORKSTATION_FILTERS_CLEAR:
      const prevFilters = { ...state.filters }
      for (const filter in prevFilters) {
        if (Object.hasOwnProperty.call(prevFilters, filter)) {
          prevFilters[filter].values = prevFilters[filter].values.map(v => ({ ...v, checked: false }))
        }
      }
      return {
        ...state,
        filters: prevFilters,
        from: null,
        to: null,
      }
    case WORKSTATION_SET_TASK_STATUS_REQUEST:
      return {
        ...state,
        taskStatusLoading: { ...state.taskStatusLoading, ...Object.fromEntries(payload.map(tId => [tId, true])) },
      }
    case WORKSTATION_SET_TASK_STATUS_SUCCESS:
      const { success, status, ids, note } = payload
      return {
        ...state,
        tasks: state.tasks.map(t => ({
          ...t,
          status: ids.includes(t.id) && success ? status : t.status,
          description: ids.includes(t.id) && success && note ? t.description + ' - ' + note : t.description,
        })),
        taskStatusLoading: { ...state.taskStatusLoading, ...Object.fromEntries(ids.map(tId => [tId, false])) },
        ...(success &&
          status === customerTaskStatusType.treated && {
            selectedTasks: state.selectedTasks.filter(t => ids.includes(t)),
          }),
      }
    case WORKSTATION_SET_TASK_STATUS_FAIL:
      return {
        ...state,
        taskStatusLoading: { ...state.taskStatusLoading, ...Object.fromEntries(payload.ids.map(tId => [tId, false])) },
        taskStatusError: payload,
      }
    case WORKSTATION_TASK_AGENT_REQUEST:
      return {
        ...state,
        taskAgentLoading: true,
        taskAgentSuccess: false,
      }
    case WORKSTATION_TASK_AGENT_SUCCESS: {
      const { success, agentId, agentName, ids } = payload
      return {
        ...state,
        tasks: state.tasks.map(t => ({
          ...t,
          responsibleEmployeeId: ids.includes(t.id) && success ? agentId : t.responsibleEmployeeId,
          responsibleEmployeeName: ids.includes(t.id) && success ? agentName : t.responsibleEmployeeName,
        })),
        taskAgentLoading: false,
        taskAgentSuccess: success,
      }
    }
    case WORKSTATION_TASK_AGENT_FAIL:
      return {
        ...state,
        taskAgentLoading: false,
        taskAgentSuccess: false,
        taskAgentError: action.payload,
      }
    case WORKSTATION_TASK_AGENT_RESET:
      return {
        ...state,
        taskAgentLoading: false,
        taskAgentSuccess: false,
        taskAgentError: null,
      }
    case WORKSTATION_TASKS_SEARCH:
      return {
        ...state,
        search: payload,
      }
    case WORKSTATION_SET_SORT:
      return {
        ...state,
        sort: {
          by: payload,
          order:
            payload !== state.sort.by
              ? sortOrder.asc
              : state.sort.order === sortOrder.asc
              ? sortOrder.desc
              : sortOrder.asc,
        },
      }
    case WORKSTATION_SET_FROM:
      return {
        ...state,
        from: payload,
      }
    case WORKSTATION_SET_TO:
      return {
        ...state,
        to: payload,
      }
    case WORKSTATION_RESET_FROMTO:
      return {
        ...state,
        from: null,
        to: null,
      }
    case WORKSTATION_RESET:
      return initialState
    default:
      return state
  }
}
