import {
  AccessibleRounded,
  AirplanemodeActive,
  BusinessCenterRounded,
  DirectionsCarRounded,
  EmojiFoodBeverageRounded,
  EmojiPeopleRounded,
  EventSeatRounded,
  HomeWorkRounded,
  LocalHospitalRounded,
  SearchRounded,
  TrendingUpRounded,
  VpnKeyRounded,
  WarningRounded,
} from '@mui/icons-material'

export const insuranceCategoryType = {
  risks: 1,
  pension: 2,
  financial: 3,
  car: 4,
  appartment: 5,
  travel: 6,
  bussiness: 7,
  otherE: 8,
  otherL: 9,
  health: 10,
  mortgage: 11,
  workIncapacity: 12,
  nursing: 13,
  liability: 14,
  benefitFund: 15,
}

export const insuranceCategory = {
  [insuranceCategoryType.risks]: {
    id: insuranceCategoryType.risks,
    name: 'סיכונים',
    nameEn: 'Risks',
    sortOrder: 1,
    cardIcon: <WarningRounded />,
  },
  [insuranceCategoryType.pension]: {
    id: insuranceCategoryType.pension,
    name: 'פנסיוני',
    nameEn: 'Pension',
    sortOrder: 6,
    cardIcon: <EmojiFoodBeverageRounded />,
  },
  [insuranceCategoryType.financial]: {
    id: insuranceCategoryType.financial,
    name: 'פיננסים',
    nameEn: 'Financial',
    sortOrder: 8,
    cardIcon: <TrendingUpRounded />,
  },
  [insuranceCategoryType.car]: {
    id: insuranceCategoryType.car,
    name: 'רכב',
    nameEn: 'Car',
    sortOrder: 10,
    cardIcon: <DirectionsCarRounded />,
  },
  [insuranceCategoryType.appartment]: {
    id: insuranceCategoryType.appartment,
    name: 'דירה',
    nameEn: 'Appartment',
    sortOrder: 11,
    cardIcon: <HomeWorkRounded />,
  },
  [insuranceCategoryType.travel]: {
    id: insuranceCategoryType.travel,
    name: 'חו"ל',
    nameEn: 'Travel',
    sortOrder: 15,
    cardIcon: <AirplanemodeActive />,
  },
  [insuranceCategoryType.bussiness]: {
    id: insuranceCategoryType.bussiness,
    name: 'עסק',
    nameEn: 'Bussiness',
    sortOrder: 12,
    cardIcon: <BusinessCenterRounded />,
  },
  [insuranceCategoryType.otherE]: {
    id: insuranceCategoryType.otherE,
    name: 'אלמנטרי כללי',
    nameEn: 'Other.E',
    sortOrder: 14,
  },
  [insuranceCategoryType.otherL]: {
    id: insuranceCategoryType.otherL,
    name: 'חיים כללי',
    nameEn: 'Other.L',
    sortOrder: 9,
  },
  [insuranceCategoryType.health]: {
    id: insuranceCategoryType.health,
    name: 'בריאות',
    nameEn: 'Health',
    sortOrder: 2,
    cardIcon: <LocalHospitalRounded />,
  },
  [insuranceCategoryType.mortgage]: {
    id: insuranceCategoryType.mortgage,
    name: 'משכנתא',
    nameEn: 'Mortgage',
    sortOrder: 4,
    cardIcon: <VpnKeyRounded />,
  },
  [insuranceCategoryType.workIncapacity]: {
    id: insuranceCategoryType.workIncapacity,
    name: 'אובדן כושר',
    nameEn: 'Work Incapacity',
    sortOrder: 5,
    cardIcon: <EmojiPeopleRounded />,
  },
  [insuranceCategoryType.nursing]: {
    id: insuranceCategoryType.nursing,
    name: 'סיעודי',
    nameEn: 'Nursing',
    sortOrder: 3,
    cardIcon: <AccessibleRounded />,
  },
  [insuranceCategoryType.liability]: {
    id: insuranceCategoryType.liability,
    name: 'חבויות',
    nameEn: 'Liability',
    sortOrder: 13,
    cardIcon: <SearchRounded />,
  },
  [insuranceCategoryType.benefitFund]: {
    id: insuranceCategoryType.benefitFund,
    name: 'גמל',
    nameEn: 'Benefit Fund',
    sortOrder: 7,
    cardIcon: <EventSeatRounded />,
  },
}

export const getInsuranceCategories = () => Object.values(insuranceCategory).sort((a, b) => a.order - b.order)
