import { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import './AcceptCookiesBanner.scss'
import { useState } from 'react'
import classNames from 'classnames'

const AcceptCookiesBanner = ({ closeBanner = () => {}, acceptCookies = () => {} }) => {
  const [isFadingOut, setIsFadingOut] = useState(false)
  const bodyOverflowRef = useRef()

  const { t } = useTranslation('common')
  const closeWithFading = closeHandler => {
    setIsFadingOut(true)
    setTimeout(() => {
      closeHandler()
    }, 450)
  }

  const acceptCookiesHanlder = () => {
    acceptCookies()
    closeBanner()
  }
  useEffect(() => {
    bodyOverflowRef.current = document.body.style.overflow
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = bodyOverflowRef.current
    }
  }, [])

  return createPortal(
    <div className='cookies-banner-wrapper'>
      <div className={classNames('cookies-banner-container', { 'fade-out': isFadingOut })}>
        <h4>{t('cookiesBanner.caption')}</h4>
        <p>{t('cookiesBanner.text')}</p>
        <div className='cookies-banner-accept-btn-container'>
          <button onClick={() => closeWithFading(acceptCookiesHanlder)}>{t('cookiesBanner.acceptBtn')}</button>
        </div>
        <div className='cookies-banner-close-btn-container'>
          <button onClick={() => closeWithFading(closeBanner)}>
            <img src='./assets/close-icon-sm/Close.png' alt='cls' />
          </button>
        </div>
      </div>
    </div>,
    document.body
  )
}

export default AcceptCookiesBanner
