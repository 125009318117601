import { companiesObjects } from './companiesTypes'
import { insuranceFieldType } from './insuranceField'

export const companyType = {
  ayalon: 1,
  harel: 2,
  clal: 3,
  migdal: 4,
  menora: 5,
  phoenix: 6,
  altshuler: 7,
  hacshara: 8,
  shlomo: 9,
  chacklai: 10,
  shirbit: 11,
  shomera: 12,
  passportCard: 13,
  more: 14,
  analyst: 15,
  yelinLapidot: 16,
  excellence: 17,
  meitav: 18,
  bestInvest: 19,
  //bestTrade: 20,
  srulik: 1001,
  lukhanin: 1002,
  ledok: 1003,
}

export const bussinessType = {
  insurance: 1,
  investment: 2,
}

export const companyConnectionType = {
  direct: 1,
  email: 2,
}

export const companyConnection = {
  [companyConnectionType.direct]: {
    id: companyConnectionType.direct,
    iconPath: './assets/_marketing/_connection-banner/direct-connection.png',
    name: 'חיבור ישיר',
    nameEn: 'Direct Connection',
    description:
      'חיבור מאובטח לחילוץ נתוני העמלות באמצעות מתן שם משתמש וסיסמא של אתר היצרן ובית ההשקעות, שנשמרים אצלנו בסטנדרט האבטחה הגבוה ביותר.',
    descriptionEn:
      "A secure connection to extract the commission data by providing a username and password of the manufacturer's website and the investment house, which are kept with us at the highest security standard.",
  },
  [companyConnectionType.email]: {
    id: companyConnectionType.email,
    iconPath: './assets/_marketing/_connection-banner/email-connection.png',
    name: 'חיבור באי־מייל',
    nameEn: 'Email Connection',
    description:
      'חיבור דרך תיבת מייל באמצעות יצירות כתובת אימייל ייעודית עבורך אלייה ייועברו דוחות העמלות לאחר שיישלחו אלייך למייל מבית ההשקעות בכל חודש.',
    descriptionEn:
      'Connection through an email box by creating a dedicated email address for you to which the commission reports will be sent after they are sent to your email from the investment house every month.',
  },
}

const getLogoPath = companyId => `./assets/companies-logos-light/${companyId}.png`
const getLogoIconPath = companyId => `./assets/companies-logos-icons-light/${companyId}.png`

const getCompanyProps = companyId => ({
  id: companyId,
  iconPath: getLogoPath(companyId),
  iconLogoPath: getLogoIconPath(companyId),
  markerColor: companiesObjects[companyId]?.markerColor ?? companiesObjects['default'].markerColor,
})

export const companies = [
  {
    ...getCompanyProps(companyType.ayalon),
    name: 'איילון',
    nameEn: 'Ayalon',
    bussinessType: bussinessType.insurance,
    fields: [
      {
        type: insuranceFieldType.elementary,
        //expected: new Date(2023, 5, 1)
      },
      { type: insuranceFieldType.lifeFinance },
    ],
    connectionTypes: [companyConnectionType.direct],
    emailInstructionsTitle: 'על מנת לחבר את בית ההשקעות לחשבונך עקוב אחר ההוראות או צפה בסרטון',
    emailInstructions: [
      'הכנס לאתר בית ההשקעות בכתובת: lps.meitav.co.il',
      'עבור לפרופיל האישי שלך באתר',
      'העתק את כתובת המייל שהופקה עבורך',
    ],
    emailInstructionsAdditional: ['הזן את הכתובת ככתובת שניה למשתמש', 'שמור את ההגדרות'],
  },
  {
    ...getCompanyProps(companyType.harel),
    name: 'הראל',
    nameEn: 'Harel',
    bussinessType: bussinessType.insurance,
    fields: [
      { type: insuranceFieldType.elementary },
      { type: insuranceFieldType.lifeFinance },
      { type: insuranceFieldType.abroad },
    ],
    connectionTypes: [companyConnectionType.direct],
  },
  {
    ...getCompanyProps(companyType.clal),
    name: 'כלל',
    nameEn: 'Clal',
    bussinessType: bussinessType.insurance,
    fields: [{ type: insuranceFieldType.elementary }, { type: insuranceFieldType.lifeFinance }],
    connectionTypes: [companyConnectionType.direct],
  },
  {
    ...getCompanyProps(companyType.migdal),
    name: 'מגדל',
    nameEn: 'Migdal',
    bussinessType: bussinessType.insurance,
    fields: [{ type: insuranceFieldType.elementary }, { type: insuranceFieldType.lifeFinance }],
    connectionTypes: [companyConnectionType.direct],
  },
  {
    ...getCompanyProps(companyType.menora),
    name: 'מנורה',
    nameEn: 'Menora',
    bussinessType: bussinessType.insurance,
    fields: [{ type: insuranceFieldType.elementary }, { type: insuranceFieldType.lifeFinance }],
    connectionTypes: [companyConnectionType.direct],
  },
  {
    ...getCompanyProps(companyType.phoenix),
    name: 'הפניקס',
    nameEn: 'Phoenix',
    bussinessType: bussinessType.insurance,
    fields: [
      { type: insuranceFieldType.elementary },
      { type: insuranceFieldType.lifeFinance },
      { type: insuranceFieldType.abroad },
    ],
    connectionTypes: [companyConnectionType.direct],
  },
  {
    ...getCompanyProps(companyType.altshuler),
    name: 'אלטשולר',
    nameEn: 'Altshuler',
    bussinessType: bussinessType.investment,
    fields: [{ type: insuranceFieldType.lifeFinance }],
    connectionTypes: [companyConnectionType.direct],
  },
  {
    ...getCompanyProps(companyType.bestInvest),
    name: 'בסט אינווסט',
    nameEn: 'Best Invest',
    bussinessType: bussinessType.investment,
    fields: [{ type: insuranceFieldType.lifeFinance }],
    connectionTypes: [companyConnectionType.direct],
  },
  {
    ...getCompanyProps(companyType.hacshara),
    name: 'הכשרה',
    nameEn: 'Hacshara',
    bussinessType: bussinessType.insurance,
    fields: [
      {
        type: insuranceFieldType.elementary,
      },
      {
        type: insuranceFieldType.lifeFinance,
      },
    ],
    connectionTypes: [companyConnectionType.direct],
  },
  {
    ...getCompanyProps(companyType.shlomo),
    name: 'שלמה',
    nameEn: 'Shlomo',
    bussinessType: bussinessType.insurance,
    fields: [{ type: insuranceFieldType.elementary }],
    connectionTypes: [companyConnectionType.direct],
  },
  { ...getCompanyProps(companyType.chacklai), name: 'חקלאי', nameEn: 'Chacklai' },
  { ...getCompanyProps(companyType.shirbit), name: 'שירביט', nameEn: 'Shirbit' },
  {
    ...getCompanyProps(companyType.shomera),
    name: 'שומרה',
    nameEn: 'Shomera',
    bussinessType: bussinessType.insurance,
    fields: [{ type: insuranceFieldType.elementary }],
    connectionTypes: [companyConnectionType.direct],
  },
  {
    ...getCompanyProps(companyType.passportCard),
    name: 'פספורטכארד',
    nameEn: 'PassportCard',
    bussinessType: bussinessType.insurance,
    fields: [{ type: insuranceFieldType.abroad }],
    connectionTypes: [companyConnectionType.direct],
  },
  {
    ...getCompanyProps(companyType.more),
    name: 'מור',
    nameEn: 'More',
    bussinessType: bussinessType.investment,
    fields: [{ type: insuranceFieldType.lifeFinance }],
    connectionTypes: [companyConnectionType.direct],
  },
  {
    ...getCompanyProps(companyType.analyst),
    name: 'אנליסט',
    nameEn: 'Analyst',
    bussinessType: bussinessType.investment,
    fields: [{ type: insuranceFieldType.lifeFinance }],
    connectionTypes: [companyConnectionType.direct],
  },
  {
    ...getCompanyProps(companyType.yelinLapidot),
    name: 'ילין לפידות',
    nameEn: 'Yelin Lapidot',
    bussinessType: bussinessType.investment,
    fields: [{ type: insuranceFieldType.lifeFinance }],
    connectionTypes: [companyConnectionType.email],
  },
  {
    ...getCompanyProps(companyType.excellence),
    name: 'אקסלנס',
    nameEn: 'Excellence',
    bussinessType: bussinessType.investment,
    fields: [{ type: insuranceFieldType.lifeFinance }],
    connectionTypes: [companyConnectionType.email],
  },
  {
    ...getCompanyProps(companyType.meitav),
    name: 'מיטב טרייד',
    nameEn: 'Best Trade',
    bussinessType: bussinessType.investment,
    fields: [{ type: insuranceFieldType.lifeFinance }],
    connectionTypes: [companyConnectionType.direct],
  },
]

const companyByNames = {}
export const companiesByNames = companies.map(company => (companyByNames[company.nameEn] = company))
const companyByIds = {}
export const companiesByIds = companies.map(company => (companyByIds[company.id] = company))
