export const policyHistoryType = {
  policyCreatedInSystem: 0,
  irregularityFixed: 1,
  irregularityIgnored: 2,
  treatmentCreated: 3,
  treated: 4,
  paymentUpdated: 5,
  canceled: 6,
  removed: 7,
  categoryChanged: 8,
}

export const policyHistory = {
  [policyHistoryType.policyCreatedInSystem]: {
    text: () => 'Policy was created',
    textEn: () => 'Policy was created',
  },
  [policyHistoryType.irregularityFixed]: {
    text: () => 'סימון חריגה כ-טופלה',
    textEn: () => 'Irregularity was marked as treated',
  },
  [policyHistoryType.irregularityIgnored]: {
    text: () => 'חריגה סומנה כתקינה',
    textEn: () => 'Irregularity was ignored',
  },
  [policyHistoryType.treatmentCreated]: {
    text: treatAgent => `הועבר לטיפול ${treatAgent ? `${treatAgent}` : ''}`,
    textEn: treatAgent => `Transferred for treatment ${treatAgent ? `to ${treatAgent}` : ''}`,
  },
  [policyHistoryType.treated]: {
    text: () => 'Was marked as treated',
    textEn: () => `Was marked as treated`,
  },
  [policyHistoryType.paymentUpdated]: {
    text: () => 'Payment was updated',
    textEn: () => 'Payment was updated',
  },
  [policyHistoryType.canceled]: {
    text: () => 'בוטל סימון החריגה כתקינה',
    textEn: () => 'Action was cancelled',
  },
  [policyHistoryType.removed]: {
    text: () => 'Policy was removed',
    textEn: () => 'Policy was removed',
  },
  [policyHistoryType.categoryChanged]: {
    text: () => 'הקטגוריה השתנתה',
    textEn: () => 'Category Changed',
  },
}
