import ButtonRounded from '../Common/ButtonRounded/ButtonRounded'
import './ConfirmWarnDialog.scss'

const ConfirmWarnDialog = ({
  confirmHandler,
  confirmText = 'OK',
  cancelHandler,
  cancelText = 'Cancel',
  title,
  text,
  dangerWarn,
}) => {
  return (
    <div className='confirm-warn-dialog-modal-wrapper'>
      <div className='confirm-warn-dialog-modal-container'>
        {title && (
          <header>
            <h5>{title}</h5>
          </header>
        )}
        {text && (
          <main>
            <p>{text}</p>
          </main>
        )}
        <footer className='confirm-dialog-modal-content-btns'>
          <ButtonRounded onClick={cancelHandler} variant='outlined'>
            {cancelText}
          </ButtonRounded>
          <ButtonRounded onClick={confirmHandler} styles={dangerWarn ? 'danger' : ''}>
            {confirmText}
          </ButtonRounded>
        </footer>
      </div>
    </div>
  )
}

export default ConfirmWarnDialog
