import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { irregularStatus } from '../../../types/irregularStatusTypes'
import { entityStatus, entityStatusType } from '../../../types/entityStatusType'
import './PolicyStatusMarker.scss'

const PolicyStatusMarker = ({ active, irregular, lostData, size = 'sm', showActiveStatus = true }) => {
  const { i18n } = useTranslation()
  const status = !active
    ? entityStatusType.inactive
    : lostData
    ? entityStatusType.lostData
    : irregular === irregularStatus.irregular
    ? entityStatusType.irregular
    : showActiveStatus
    ? entityStatusType.active
    : null
  const { backgroundColor, color } = entityStatus[status ?? entityStatusType.normal]
  return (
    <>
      {status && (
        <div className='policy-status-marker-container'>
          <div className={classNames('policy-status-marker', { [size]: size })} style={{ backgroundColor, color }}>
            {i18n.language === 'he' ? entityStatus[status].text : entityStatus[status].textEn}
          </div>
        </div>
      )}
    </>
  )
}

export default PolicyStatusMarker
