export const cssColor = {
  greenText: 'var(--trend-up-color)',
  greenBackgroundTransparent: 'rgba(var(--trend-up-color-rgb), 0.17)',
  redText: 'var(--trend-down-color)',
  redBackgroundTransparent: 'rgba(var(--trend-down-color-rgb), 0.17)',
  primaryText: 'var(--text-primary-color)',
  whiteBackgroundTransparent: 'var(--text-third-color)',
  yellowText: 'rgb(255,191,0)',
  yellowBackgroundTransparent: 'rgba(255,191,0,0.17)',
  greenClient: 'var(--green-client)',
  orangeClient: 'var(--orange-client)',
  redClient: 'var(--red-client)',
}
