import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import { sharedReducer } from '../features/shared/reducers/sharedReducer'
import { themeReducer } from '../features/themes/reducers/themeReducer'
import { loginReducer } from '../features/login/reducers/loginReducer'
import { customersReducer } from '../features/customers/reducers/customersReducer'
import { customerReducer } from '../features/customer/reducers/customerReducer'
import { downloadReducer } from '../features/download/reducers/downloadReducer'
import { policiesReducer } from '../features/policies/reducers/policiesReducer'
import { policyReducer } from '../features/policy/reducers/policyReducer'
import { profileReducer } from '../features/profile/reducers/profileReducer'
import { workstationReducer } from '../features/workstation/reducers/workstationReducer'
import { landingReducer } from '../features/landing/reducers/landingReducer'
import { agentHouseReducer } from '../features/agentHouse/reducers/agentHouseReducer'
import { agencyHouseDownloadReducer } from '../features/agencyHouseDownload/reducers/agencyHouseDownloadReducer'
import { agencyHouseAnalyticsReducer } from '../features/agencyHouseAnalytics/reducers/agencyHouseAnalitycsReducer'
import { scanReducer } from '../features/scan/reducers/scanReducer'
import { phoneCodes } from '../environment/phoneCodes'
import {
  getItemFromLocalStorage,
  getObjectFromLocalStorage,
  getPreferencesFromLocalStorage,
} from '../services/localStorageService'
import { paymentPlanType } from '../types/paymentPlanTypes'
import { cookieGetItemValue } from '../services/cookieStorgeService'
import { agencyType } from '../types/agencyTypes'
import { deltaCommissionViewType } from '../types/deltaCommissionViewTypes'
import { familyGroupType } from '../types/familyGroupTypes'

const reducer = combineReducers({
  theme: themeReducer,
  login: loginReducer,
  customers: customersReducer,
  customer: customerReducer,
  download: downloadReducer,
  profile: profileReducer,
  policies: policiesReducer,
  policy: policyReducer,
  workstation: workstationReducer,
  landing: landingReducer,
  agentHouse: agentHouseReducer,
  agencyHouseDownload: agencyHouseDownloadReducer,
  agencyHouseAnalytics: agencyHouseAnalyticsReducer,
  shared: sharedReducer,
  scan: scanReducer,
})

const subagencyMode = cookieGetItemValue('subagencyMode')
const UserId = cookieGetItemValue('UserId')
const UserName = getItemFromLocalStorage('UserName')
const AgencyType = subagencyMode ? agencyType.regular : +getItemFromLocalStorage('AgencyType')
const agencyId = cookieGetItemValue('agencyId')
const agencyName = getItemFromLocalStorage('agencyName')
const agencyDnsName = cookieGetItemValue('agencyDnsName')
const role = cookieGetItemValue('role')
const preferences = getPreferencesFromLocalStorage()
const agreementConfirmed = getItemFromLocalStorage('AgreementConfirmed') === 'true'
let commissionView = deltaCommissionViewType.agent

if (UserId) {
  const localStorageUser = getObjectFromLocalStorage(UserId)
  if (localStorageUser && localStorageUser.commissionViewType) {
    commissionView = localStorageUser.commissionViewType
  }
}

const theme = cookieGetItemValue('theme')
const themeFromCookie = theme && theme !== '' ? theme : 'dark'
const {
  il: { areaCodes, countryCode },
} = phoneCodes

const initialState = {
  theme: {
    currentTheme: themeFromCookie,
  },
  login: {
    subagencyMode,
    countryPhoneCode: countryCode,
    areaPhoneCodes: areaCodes,
    areaPhoneCode: '',
    phoneNumber: '',
    agencies: [],
    currentAgency: '',
    UserId,
    AgencyType,
    UserName,
    agencyId,
    agencyName,
    agencyDnsName,
    role,
    preferences,
    loading: false,
    success: false,
    totalSuccess: false,
    serverError: {},
    subscription: paymentPlanType.trial,
    agreementConfirmed,
  },
  shared: {
    countryPhoneCode: countryCode,
    areaPhoneCodes: areaCodes,
    categories: [],
    categoriesLoading: true,
    agentsLoading: true,
    agents: [],
    agentNumbersLoading: true,
    activeAgents: [],
    deletedAgents: [],
    allAgentGroupsLoading: true,
    allAgentGroups: [],
    agentGroupsLoading: true,
    agentGroups: [],
    addGroupSuccess: false,
    addGroupLoading: false,
    removeGroupSuccess: false,
    updateGroupSuccess: false,
    selectedGroupsIds: [],
    rolesLoading: true,
    roles: [],
    crawlerCompanies: [],
    crawlerCompaniesLoading: true,
    noSubscriptionFound: false,
    noPayment: false,
    agencyInvitesLoading: true,
    agencyInvites: [],
    agencyInvitesError: null,
    familyGroupsType: familyGroupType.standalone,
    commissionView,
  },
}

const middleware = [thunk]
const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)))

export default store
