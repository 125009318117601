import { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useDispatch, useSelector } from 'react-redux'
import AgentGroupsBanner from '../../components/AgentGroupsBanner/AgentGroupsBanner'
import Loader from '../../components/Common/Loader/Loader'
import NoData from '../../components/Common/NoData/NoData'
import { getTasks, getTasksFilters } from '../../features/workstation/actions/workstationActions'
import TasksList from '../../features/workstation/components/TasksList/TasksList'
import TasksMonetaryValue from '../../features/workstation/components/TasksMonetaryValue/TasksMonetaryValue'
import WorkstationHeader from '../../features/workstation/components/WorkstationHeader/WorkstationHeader'
import { hebrew } from '../../i18n'
import { WORKSTATION_RESET } from '../../types/actionTypes'
import { customerTask, customerTaskStatusType } from '../../types/customerTaskTypes'
import { sortBy, sortOrder } from '../../types/sortByTypes'

import './WorkstationScreen.scss'
import BackToBigAgency from '../../features/shared/components/BackToBigAgency/BackToBigAgency'

const WorkstationScreen = () => {
  const { tasks, tasksLoading, monetaryValue, filters, search, sort, from, to } = useSelector(
    state => state.workstation
  )
  const { selectedGroupsIds } = useSelector(({ shared }) => shared)

  const [showWaitingTasks, setShowWaitingTasks] = useState(true)
  const [showTreatedTasks, setShowTreatedTasks] = useState(false)
  const dispatch = useDispatch()

  const collapseTasksHandler = (value, setValue) => {
    setValue(!value)
  }

  const comparator = (
    { customerName: nameA, policyIdNumber: pNumA, type: typeA },
    { customerName: nameB, policyIdNumber: pNumB, type: typeB }
  ) => {
    switch (sort.by) {
      default:
      case sortBy.customerName: {
        if (nameA === null) return 1
        if (nameB === null) return -1
        return sort.order === sortOrder.asc
          ? nameA?.normalize().localeCompare(nameB?.normalize())
          : nameB?.normalize().localeCompare(nameA?.normalize())
      }
      case sortBy.policyNumber: {
        if (pNumA === null) return 1
        if (pNumB === null) return -1
        if (+pNumA < +pNumB) {
          return sort.order === sortOrder.asc ? -1 : 1
        }
        if (+pNumA > +pNumB) {
          return sort.order === sortOrder.asc ? 1 : -1
        }
        return 0
      }
      case sortBy.taskType: {
        const getTaskName = taskType => (hebrew() ? customerTask[taskType].text() : customerTask[taskType].textEn())
        if (getTaskName(typeA) === null) return 1
        if (getTaskName(typeB) === null) return -1
        return sort.order === sortOrder.asc
          ? getTaskName(typeA).normalize().localeCompare(getTaskName(typeB).normalize()) ||
              nameA?.normalize().localeCompare(nameB?.normalize())
          : getTaskName(typeB).normalize().localeCompare(getTaskName(typeA).normalize()) ||
              nameB?.normalize().localeCompare(nameA?.normalize())
      }
    }
  }

  const filterTasks = taskStatus =>
    tasks
      .filter(
        ({ status, customerName, policyIdNumber }) =>
          status === taskStatus &&
          (search !== ''
            ? customerName?.toUpperCase().includes(search.trim().toUpperCase()) ||
              policyIdNumber?.toUpperCase().includes(search.trim().toUpperCase())
            : true)
      )
      .sort(comparator)

  const tasksWaiting = filterTasks(customerTaskStatusType.treatWaiting)
  const tasksTreated = filterTasks(customerTaskStatusType.treated)

  useEffect(() => {
    dispatch(getTasksFilters())
    return () => {
      dispatch({ type: WORKSTATION_RESET })
    }
  }, [dispatch, selectedGroupsIds])

  useEffect(() => {
    if (Object.keys(filters).length !== 0) dispatch(getTasks())
  }, [dispatch, filters, from, to])

  if (tasksLoading) {
    return (
      <div className='workstation-screen'>
        {!isMobile && (
          <div className='workstation-screen-banners'>
            <BackToBigAgency />
            <AgentGroupsBanner />
          </div>
        )}
        <WorkstationHeader />
        <div className='workstation-content'>
          <Loader />
        </div>
      </div>
    )
  }

  return (
    <div className='workstation-screen'>
      {!isMobile && (
        <div className='workstation-screen-banners'>
          <BackToBigAgency />
          <AgentGroupsBanner />
        </div>
      )}
      <WorkstationHeader />
      <div className='workstation-content'>
        {tasks.filter(({ status }) => status === customerTaskStatusType.treatWaiting).length > 0 && (
          <TasksMonetaryValue value={monetaryValue} />
        )}
        {tasks.length === 0 ? (
          <NoData text='No tasks found' />
        ) : (
          <TasksList
            tasksWaiting={tasksWaiting}
            tasksTreated={tasksTreated}
            showWaitingTasks={showWaitingTasks}
            setShowWaitingTasks={setShowWaitingTasks}
            showTreatedTasks={showTreatedTasks}
            setShowTreatedTasks={setShowTreatedTasks}
            collapseTasksHandler={collapseTasksHandler}
          />
        )}
      </div>
    </div>
  )
}

export default WorkstationScreen
