import { InfoTwoTone } from '@mui/icons-material'
import Loader from '../../../../components/Common/Loader/Loader'
import './AgencyHouseAnalyticsPoliciesTotalsCard.scss'

const AgencyHouseAnalyticsPoliciesTotalsCard = ({ name, icon, value, difference, loading }) => {
  return (
    <div className='agency-house-analytics-policies-totals-card'>
      <header>
        <div className='agency-house-analytics-policies-totals-card-icon'>{icon}</div>
        <p>
          {name} <InfoTwoTone />
        </p>
      </header>
      {loading ? (
        <Loader />
      ) : (
        <div className='agency-house-analytics-policies-totals-card-content'>
          <div>{value}</div>
          <div>{difference}</div>
        </div>
      )}
    </div>
  )
}

export default AgencyHouseAnalyticsPoliciesTotalsCard
