import { userPreferenceType } from './agencyUserTypes'

export const requestDataTypes = {
  premium: 0,
  commission: 1,
  accumulation: 2,
  policies: 3,
  commissionRate: 4,
  commissionRatePremium: 5,
  commissionRateAccumulation: 6,
  avarageComission: 7,
  customers: 8,
}

export let requestDataTypeObject = {
  [requestDataTypes.commission]: {
    id: requestDataTypes.commission,
    name: 'עמלה',
    nameEn: 'Commission',
    checked: false,
    markerColor: 'var(--orange-client)',
    order: 1,
    requiredPreference: userPreferenceType.comission,
  },
  [requestDataTypes.premium]: {
    id: requestDataTypes.premium,
    name: 'פרמיה',
    nameEn: 'Premium',
    checked: false,
    markerColor: 'var(--green-client)',
    order: 2,
    requiredPreference: userPreferenceType.premium,
  },
  [requestDataTypes.commissionRatePremium]: {
    id: requestDataTypes.commissionRatePremium,
    name: 'שיעור עמלה פרמיה',
    nameEn: 'Commission Rate Premium',
    checked: false,
    markerColor: 'pink',
    order: 3,
    requiredPreference: userPreferenceType.comissionRate,
  },
  [requestDataTypes.commissionRateAccumulation]: {
    id: requestDataTypes.commissionRateAccumulation,
    name: 'שיעור עמלה צבירה',
    nameEn: 'Commission Rate Accumulation',
    checked: false,
    markerColor: 'pink',
    order: 3,
    requiredPreference: userPreferenceType.comissionRate,
  },
  [requestDataTypes.commissionRate]: {
    id: requestDataTypes.commissionRate,
    name: 'שיעור עמלה',
    nameEn: 'Commission Rate',
    checked: false,
    markerColor: 'pink',
    order: 3,
    requiredPreference: userPreferenceType.comissionRate,
  },
  [requestDataTypes.accumulation]: {
    id: requestDataTypes.accumulation,
    name: 'צבירה',
    nameEn: 'Accumulation',
    checked: false,
    markerColor: 'var(--red-client)',
    order: 4,
    requiredPreference: userPreferenceType.accumulation,
  },
  [requestDataTypes.policies]: {
    id: requestDataTypes.policies,
    name: 'פוליסות',
    nameEn: 'Policies',
    checked: false,
    markerColor: 'var(--text-primary-color)',
    order: 5,
  },
  [requestDataTypes.avarageComission]: {
    id: requestDataTypes.avarageComission,
    name: 'ממוצע עמלה ללקוח',
    nameEn: 'Average commission per customer',
    checked: false,
    markerColor: 'var(--text-primary-color)',
    order: 6,
    requiredPreference: userPreferenceType.comission,
  },
}
