import classNames from 'classnames'
import './LinedTitle.scss'
import { cn } from '../../../utils/stylesUtils'

const LinedTitle = ({
  title,
  count,
  remark,
  shareAction,
  externalLink,
  collapsible = false,
  onCollapse = () => {},
  collapsed = false,
  collapsedIconPath = './assets/plus-icon/Add.png',
  uncollapsedIconPath = './assets/minus-icon/Close.png',
  selectAll,
  allSelected,
  size,
  badge,
  children,
  hideLine = false,
  classes = '',
}) => {
  return (
    <>
      <div className={cn('lined-title', { [size]: size }, classes)}>
        <div className='lined-title-content'>
          {selectAll && (
            <div className='lined-title-selectall-container'>
              <button onClick={selectAll}>
                <img
                  src={`./assets/${
                    allSelected ? 'checkbox-lg-checked/Square.png' : 'checkbox-md-blank/checkbox-blank.png'
                  }`}
                  alt='allslct'
                />
              </button>
            </div>
          )}
          {collapsible && (
            <div className={classNames('lined-title-content-icon', { collapsed })} onClick={onCollapse}>
              {collapsed ? <img src={collapsedIconPath} alt='show' /> : <img src={uncollapsedIconPath} alt='hide' />}
            </div>
          )}
          {title && title.length > 0 && (
            <span
              className={classNames('lined-title-text', { collapsed })}
              {...(collapsible && { onClick: onCollapse })}
            >
              {title}
            </span>
          )}
          {(count || count === 0) && <span className='lined-title-count'>({count})</span>}
          {badge}
          {remark && remark.length > 0 && <span className='lined-title-remark'>{remark}</span>}
          {(externalLink || shareAction) && (
            <span className='lined-title-actions'>
              {externalLink && (
                <span className='lined-title-action-icon'>
                  <a href={externalLink}>
                    <img src='./assets/export/Export@1.1x.png' alt='exprt' />
                  </a>
                </span>
              )}
              {shareAction && (
                <span className='lined-title-action-icon'>
                  <img src='./assets/share/share.png' alt='shr' onClick={shareAction} />
                </span>
              )}
            </span>
          )}
        </div>
        {!hideLine && <hr className='lined-title-line' />}
      </div>
      {!collapsed && children}
    </>
  )
}

export default LinedTitle
