import {
  PROFILE_COMPANIES_REQUEST,
  PROFILE_COMPANIES_SUCCESS,
  PROFILE_COMPANIES_FAIL,
  PROFILE_GET_COMPANIES_REQUEST,
  PROFILE_GET_COMPANIES_SUCCESS,
  PROFILE_GET_COMPANIES_FAIL,
  PROFILE_ADD_COMPANIES_REQUEST,
  PROFILE_ADD_COMPANIES_SUCCESS,
  PROFILE_ADD_COMPANIES_FAIL,
  PROFILE_DELETE_COMPANY_REQUEST,
  PROFILE_DELETE_COMPANY_SUCCESS,
  PROFILE_DELETE_COMPANY_FAIL,
  PROFILE_FIELDS_REQUEST,
  PROFILE_FIELDS_SUCCESS,
  PROFILE_FIELDS_FAIL,
  PROFILE_DETAILS_REQUEST,
  PROFILE_DETAILS_SUCCESS,
  PROFILE_SET_FIELDS_FAIL,
  PROFILE_SET_FIELDS_SUCCESS,
  PROFILE_SET_FIELDS_REQUEST,
  PROFILE_EDIT_COMPANY_REQUEST,
  PROFILE_EDIT_COMPANY_SUCCESS,
  PROFILE_EDIT_COMPANY_FAIL,
  PROFILE_EMPLOYEES_REQUEST,
  PROFILE_EMPLOYEES_SUCCESS,
  PROFILE_EMPLOYEES_FAIL,
  PROFILE_EMPLOYEES_ADD_REQUEST,
  PROFILE_EMPLOYEES_ADD_SUCCESS,
  PROFILE_EMPLOYEES_ADD_FAIL,
  PROFILE_EMPLOYEES_DELETE_REQUEST,
  PROFILE_EMPLOYEES_DELETE_SUCCESS,
  PROFILE_EMPLOYEES_DELETE_FAIL,
  PROFILE_EMPLOYEES_EDIT_REQUEST,
  PROFILE_EMPLOYEES_EDIT_SUCCESS,
  PROFILE_EMPLOYEES_EDIT_FAIL,
  PROFILE_EMPLOYEES_SEND_REQUEST,
  PROFILE_EMPLOYEES_SEND_SUCCESS,
  PROFILE_EMPLOYEES_SEND_FAIL,
} from '../../../types/actionTypes'
import { urlProfile, urlAgency, urlMaster } from '../../../environment/urls'
import $api from '../../../http'

const agencyUrl = urlAgency()
const profileUrl = urlProfile()
const masterUrl = urlMaster()

export const getProfileFields = () => async dispatch => {
  try {
    dispatch({ type: PROFILE_FIELDS_REQUEST })
    const {
      data: { data },
    } = await $api.get(`${profileUrl}available-fields`)
    dispatch({
      type: PROFILE_FIELDS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PROFILE_FIELDS_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const setProfileFields = companies => async dispatch => {
  try {
    dispatch({ type: PROFILE_SET_FIELDS_REQUEST })
    await $api.post(`${agencyUrl}add-companies`, companies)
    dispatch({
      type: PROFILE_SET_FIELDS_SUCCESS,
    })
    dispatch(getProfileCompanies())
    dispatch(getProfileFields())
  } catch (error) {
    dispatch({
      type: PROFILE_SET_FIELDS_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const getProfileCompanies = () => async dispatch => {
  try {
    dispatch({ type: PROFILE_COMPANIES_REQUEST })
    const {
      data: { data },
    } = await $api.get(`${profileUrl}companies`)
    dispatch({
      type: PROFILE_COMPANIES_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PROFILE_COMPANIES_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const getAvailableCompanies = () => async dispatch => {
  try {
    dispatch({ type: PROFILE_GET_COMPANIES_REQUEST })
    const {
      data: { data },
    } = await $api.get(`${profileUrl}available-companies`)
    dispatch({
      type: PROFILE_GET_COMPANIES_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PROFILE_GET_COMPANIES_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const addCompanies = companiesIds => async dispatch => {
  dispatch({
    type: PROFILE_ADD_COMPANIES_REQUEST,
  })
  try {
    await $api.post(`${agencyUrl}add-companies`, companiesIds)
    dispatch({
      type: PROFILE_ADD_COMPANIES_SUCCESS,
    })
    dispatch(getProfileCompanies())
    dispatch(getProfileFields())
    dispatch(getProfileInfo())
  } catch (error) {
    dispatch({
      type: PROFILE_ADD_COMPANIES_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const profileEditCompany = company => async dispatch => {
  await dispatch({
    type: PROFILE_EDIT_COMPANY_REQUEST,
    payload: company.id,
  })
  try {
    const { data: updateCredentialsResponse } = await $api.post(`${agencyUrl}add-companies-with-credentials`, [company])
    if (!updateCredentialsResponse.hasError) {
      dispatch({
        type: PROFILE_EDIT_COMPANY_SUCCESS,
      })
    } else {
      dispatch({
        type: PROFILE_EDIT_COMPANY_FAIL,
        payload: updateCredentialsResponse.errorMessage,
      })
    }
  } catch (error) {
    dispatch({
      type: PROFILE_EDIT_COMPANY_FAIL,
      payload: error.message,
    })
  }
}

export const deleteCompany = companyId => async dispatch => {
  dispatch({
    type: PROFILE_DELETE_COMPANY_REQUEST,
  })
  try {
    await $api.delete(`${agencyUrl}remove-company/${companyId}`)
    dispatch({
      type: PROFILE_DELETE_COMPANY_SUCCESS,
    })
    dispatch(getProfileCompanies())
    dispatch(getProfileFields())
  } catch (error) {
    dispatch({
      type: PROFILE_DELETE_COMPANY_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const getProfileInfo = () => async (dispatch, getState) => {
  const userId = getState().login.UserId
  dispatch({
    type: PROFILE_DETAILS_REQUEST,
  })
  try {
    const {
      data: { data },
    } = await $api.get(`${profileUrl}user-info/${userId}`)
    dispatch({
      type: PROFILE_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PROFILE_DELETE_COMPANY_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const getProfileEmployees = () => async (dispatch, getState) => {
  const { agencyDnsName: agency } = getState().login
  dispatch({
    type: PROFILE_EMPLOYEES_REQUEST,
  })
  try {
    const {
      data: {
        Data: { employees },
      },
    } = await $api.get(`${masterUrl}account/employees/${agency}`)
    dispatch({
      type: PROFILE_EMPLOYEES_SUCCESS,
      payload: employees,
    })
  } catch (error) {
    dispatch({
      type: PROFILE_EMPLOYEES_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const addProfileEmployee = employee => async (dispatch, getState) => {
  const { agencyDnsName: agency } = getState().login
  employee = { ...employee, agency }
  dispatch({
    type: PROFILE_EMPLOYEES_ADD_REQUEST,
  })
  try {
    const {
      data: { Data, HasError, ErrorMessage },
    } = await $api.post(`${masterUrl}agency/create-employee`, { ...employee })
    if (Data?.success) {
      Data?.success && dispatch(getProfileEmployees())
      dispatch({
        type: PROFILE_EMPLOYEES_ADD_SUCCESS,
        payload: Data?.success,
      })
    } else if (HasError) {
      dispatch({
        type: PROFILE_EMPLOYEES_ADD_FAIL,
        payload: ErrorMessage ?? 'Server Error',
      })
    }
  } catch (error) {
    console.log('Err catched', error)
    dispatch({
      type: PROFILE_EMPLOYEES_ADD_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const editProfileEmployee = employee => async (dispatch, getState) => {
  const { agencyDnsName: agency } = getState().login
  employee = { ...employee, agency }
  dispatch({
    type: PROFILE_EMPLOYEES_EDIT_REQUEST,
  })
  try {
    const {
      data: {
        Data: { success },
      },
    } = await $api.post(`${masterUrl}account/update-employee`, { ...employee })
    dispatch({
      type: PROFILE_EMPLOYEES_EDIT_SUCCESS,
      payload: success,
    })
    if (success) {
      dispatch(getProfileEmployees())
    }
  } catch (error) {
    console.log('Err catched', error)
    dispatch({
      type: PROFILE_EMPLOYEES_EDIT_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const deleteProfileEmployee = employeeId => async dispatch => {
  dispatch({
    type: PROFILE_EMPLOYEES_DELETE_REQUEST,
    payload: employeeId,
  })
  try {
    const {
      data: {
        Data: { succeed: success },
      },
    } = await $api.get(`${masterUrl}account/delete/${employeeId}`)
    dispatch({
      type: PROFILE_EMPLOYEES_DELETE_SUCCESS,
      payload: { success, employeeId },
    })
  } catch (error) {
    console.log('Err catched', error)
    dispatch({
      type: PROFILE_EMPLOYEES_DELETE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const sendProfileReminder = employeeId => async dispatch => {
  dispatch({
    type: PROFILE_EMPLOYEES_SEND_REQUEST,
    payload: employeeId,
  })
  try {
    const {
      data: {
        Data: { succeed: success },
      },
    } = await $api.get(`${masterUrl}account/resend-invitation/${employeeId}`)
    dispatch({
      type: PROFILE_EMPLOYEES_SEND_SUCCESS,
      payload: { success, employeeId },
    })
  } catch (error) {
    console.log('Err catched', error)
    dispatch({
      type: PROFILE_EMPLOYEES_SEND_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}
