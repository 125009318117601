import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../../components/Common/Loader/Loader'
import NoData from '../../../../components/Common/NoData/NoData'
import { policyHistory } from '../../../../types/policyHistoryTypes'
import { formatAsShortDate } from '../../../../utils/formatAs'
import { cancelPolicyLog } from '../../../policy/actions/policyActions'
import './CustomerPolicyHistory.scss'

const CustomerPolicyHistory = () => {
  const { t, i18n } = useTranslation('customer')

  const history = useSelector(state => state.policy).history
  const historyLoading = useSelector(state => state.policy).historyLoading
  const cancelLogId = useSelector(state => state.policy).cancelId
  const selectedPolicyId = useSelector(state => state.policy).selectedPolicy

  const dispatch = useDispatch()

  if (historyLoading) return <Loader />

  if (history.length === 0) return <NoData text={t('policies.noHistory')} />

  return (
    <>
      <div className='customer-policy-history-grid heading'>
        <div>{t('tableHeadings.action')}</div>
        <div>{t('tableHeadings.byWho')}</div>
        <div>{t('tableHeadings.date')}</div>
        <div></div>
      </div>
      {history.map(itm => (
        <div key={itm.createDate.toString() + itm.id} className='customer-policy-history-grid history-item'>
          {cancelLogId === itm.id && (
            <div className='policy-history-item-loader-overlay'>
              <Loader />
            </div>
          )}
          <div>
            {i18n.language === 'he'
              ? policyHistory[itm.type].text(itm.responsibleName)
              : policyHistory[itm.type].textEn(itm.responsibleName)}
          </div>
          <div>{itm.userName}</div>
          <div>{formatAsShortDate(itm.createDate, '/')}</div>
          <div className='history-cancel-btn-container'>
            {itm.cancelAllowed && (
              <button onClick={() => dispatch(cancelPolicyLog(itm.id, selectedPolicyId, itm.type))}>
                {t('cancelBtn')}
              </button>
            )}
          </div>
        </div>
      ))}
    </>
  )
}

export default CustomerPolicyHistory
