const CheckboxOutlineBlankSvg = ({ fill = 'var(--text-primary-color)' }) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' width='24' height='24'>
    <g id='check-box-outline-blank'>
      <path
        id='Vector'
        fill={fill}
        fillOpacity='0.6'
        d='M 18 19 H 6 C 5.45 19 5 18.55 5 18 V 6 C 5 5.45 5.45 5 6 5 H 18 C 18.55 5 19 5.45 19 6 V 18 C 19 18.55 18.55 19 18 19 Z M 19 3 H 5 C 3.9 3 3 3.9 3 5 V 19 C 3 20.1 3.9 21 5 21 H 19 C 20.1 21 21 20.1 21 19 V 5 C 21 3.9 20.1 3 19 3 Z'
      />
    </g>
  </svg>
)

export default CheckboxOutlineBlankSvg
