import classNames from 'classnames'
import './InputSwitch.scss'

const InputSwitch = ({ id, checked, onChange, value, disabled = false }) => {
  return (
    <label className={classNames('switch', { disabled })} dir='rtl'>
      <input type='checkbox' id={id} checked={checked} onChange={onChange} value={value} disabled={disabled} />
      <span className='slider round'></span>
    </label>
  )
}

export default InputSwitch
