import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import './RegisterTermsFooter.scss'
import ModalAgreement, { agreement } from '../../../../components/ModalAgreement/ModalAgreement'

const RegisterTermsFooter = () => {
  const { t } = useTranslation('registration')
  const [currAgreement, setCurrAgreement] = useState(null)
  const [showAgreement, setShowAgreement] = useState(false)
  const setCurrAgreementHandler = (e, agreementType) => {
    e.preventDefault()
    setCurrAgreement(agreementType)
    setShowAgreement(true)
  }
  return (
    <div className='register-terms-footer'>
      <p onClick={e => setCurrAgreementHandler(e, agreement.policy)}>{t('links.privacy')}</p>
      <p onClick={e => setCurrAgreementHandler(e, agreement.terms)}>{t('links.terms')}</p>
      <p>{t('links.support')}</p>
      {showAgreement && (
        <ModalAgreement closeModal={() => setShowAgreement(false)} currentAgreementType={currAgreement} />
      )}
    </div>
  )
}

export default RegisterTermsFooter
