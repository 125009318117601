import { InfoTwoTone } from '@mui/icons-material'
import './AgencyHouseAnalyticsIncomesTotalsCard.scss'
import Loader from '../../../../components/Common/Loader/Loader'

const AgencyHouseAnalyticsIncomesTotalsCard = ({
  name,
  icon,
  value,
  difference,
  loading,
  additionalName,
  additionalValue,
  additionalDifference,
}) => {
  return (
    <div className='agency-house-analytics-incomes-totals-card-wrapper'>
      <div className='agency-house-analytics-incomes-totals-card'>
        <header>
          <div className='agency-house-analytics-incomes-totals-card-icon'>{icon}</div>
          <p>
            {name} <InfoTwoTone />
          </p>
        </header>
        {loading ? (
          <Loader />
        ) : (
          <div className='agency-house-analytics-incomes-totals-card-content'>
            <div>{value}</div>
            <div>{difference}</div>
          </div>
        )}
      </div>
      {(additionalName || additionalValue || additionalDifference) && !loading && (
        <div className='agency-house-analytics-incomes-totals-card'>
          <header>
            <p>{additionalName}</p>
          </header>
          <div className='agency-house-analytics-incomes-totals-card-content'>
            <div>{additionalValue}</div>
            <div>{additionalDifference}</div>
          </div>
        </div>
      )}
    </div>
  )
}

export default AgencyHouseAnalyticsIncomesTotalsCard
