import { hebrew } from '../../../../i18n'
import { policiesDataTypes, policiesTotalsObject } from '../../../../types/analyticsIncomesTypes'
import { analyticsPeriodViewType } from '../../../../types/analyticsTypes'
import AgencyHouseLobbyChanges from '../../../agencyHouseLobby/components/AgencyHouseLobbyChanges/AgencyHouseLobbyChanges'
import AgencyHouseAnalyticsPoliciesTotalsCard from './AgencyHouseAnalyticsPoliciesTotalsCard'
import { useTranslation } from 'react-i18next'
import './AgencyHouseAnalyticsPoliciesTotals.scss'

const AgencyHouseAnalyticsPoliciesTotals = ({ dataType, periodViewType, totals, totalsLoading }) => {
  const { t } = useTranslation('agencyHouseLobby')
  const policyDataType = policiesDataTypes.find(dt => dt.id === dataType)
  const totalsValues = totals
    .filter(
      t => policyDataType.totals.includes(t.type) && policiesTotalsObject[t.type].periodViews.includes(periodViewType)
    )
    .map(t => ({
      ...policiesTotalsObject[t.type],
      value: totals.find(tv => tv.type === t.type).value,
      diff: totals.find(tv => tv.type === t.type).diff,
    }))
  const monthlyView = periodViewType === analyticsPeriodViewType.monthly

  const totalName = ({ name, nameEn, nameMonthlyView, nameEnMonthlyView }) => {
    if (monthlyView) {
      return hebrew() ? nameMonthlyView : nameEnMonthlyView
    }
    return hebrew() ? name : nameEn
  }

  const totalValue = c => <h5>{c.value ?? '--'}</h5>

  const totalDiff = c => (
    <AgencyHouseLobbyChanges
      value={c.diff}
      showValue={true}
      showPercentSymbol={false}
      showCurrencySymbol={false}
      {...(!monthlyView && { changesText: t('totals.prevPeriodChanges') })}
    />
  )

  return (
    <div className='agency-house-analytics-policies-totals'>
      {totalsValues.map(t => (
        <AgencyHouseAnalyticsPoliciesTotalsCard
          key={t.name}
          name={totalName(t)}
          icon={t.icon}
          value={totalValue(t)}
          difference={totalDiff(t)}
          loading={totalsLoading}
        />
      ))}
    </div>
  )
}

export default AgencyHouseAnalyticsPoliciesTotals
