export const policyTableType = {
  payments: 'payments',
  history: 'history',
  policyTypes: 'policyTypes',
}

export const customerTableType = {
  policies: 'policies',
  externalPayments: 'externalPayments',
  irregular: 'irregular',
  inactive: 'inactive',
  portfolioHistory: 'portfolioHistory',
  salesPotential: 'salesPotential',
}

export const policiesListType = {
  all: 'all',
  new: 'new',
  tracking: 'tracking',
  lostData: 'lostData',
  irregular: 'irregular',
  inactive: 'inactive',
  signals: 'signals',
  active: 'active',
}

export const policiesListTypeMapToApi = {
  [policiesListType.all]: 1,
  [policiesListType.active]: 2,
  [policiesListType.new]: 3,
  [policiesListType.lostData]: 4,
  [policiesListType.inactive]: 5,
  [policiesListType.irregular]: 6,
}

export const customersListType = {
  total: 'total',
  rating: 'rating',
  ratingRed: 'rating-red',
  ratingOrange: 'rating-orange',
  ratingGreen: 'rating-green',
  distribution: 'distribution',
  irregular: 'irregular',
  inactive: 'inactive',
  groups: 'groups',
}

export const workstationTableType = {
  tasksWaiting: 'tasksWaiting',
  tasksHandled: 'tasksHandled',
}

export const allPoliciesListType = {
  all: '1',
  lifeFinance: '2',
  elementary: '3',
  specialPayments: '4',
}

export const allPoliciesList = {
  [allPoliciesListType.all]: { id: allPoliciesListType.all, type: allPoliciesListType.all, key: 'all' },
  [allPoliciesListType.lifeFinance]: {
    id: allPoliciesListType.lifeFinance,
    type: allPoliciesListType.lifeFinance,
    key: 'lifeFinance',
  },
  [allPoliciesListType.elementary]: {
    id: allPoliciesListType.elementary,
    type: allPoliciesListType.elementary,
    key: 'elementary',
  },
  [allPoliciesListType.specialPayments]: {
    id: allPoliciesListType.specialPayments,
    type: allPoliciesListType.specialPayments,
    key: 'specialPayments',
  },
}

export const tabPaymentLabelsNames = {
  [allPoliciesListType.all]: 'allPayments',
  [allPoliciesListType.elementary]: 'elementaryPayments',
  [allPoliciesListType.lifeFinance]: 'lifeFinance',
  [allPoliciesListType.specialPayments]: 'specialPayments',
}

export const monthlyPolicyStatuses = {
  normal: 1,
  missingMonth: 2,
  missingPayment: 3
}
