import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import NoMobileLayout from '../../components/NoMobileLayout/NoMobileLayout'
import AgencyHouseDownloadSidebar from '../../features/agencyHouseDownload/components/AgencyHouseDownloadSidebar/AgencyHouseDownloadSidebar'
import AgencyHouseDownloadSummaryContainer from '../../features/agencyHouseDownload/components/AgencyHouseDownloadSummaryContainer/AgencyHouseDownloadSummaryContainer'
import { Route, Switch, useLocation } from 'react-router-dom'
import { agencyHouseRoutes } from '../../environment/urls'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAgencyHouseDownloadAgencies,
  getAgencyHouseDownloadAgenciesHistory,
} from '../../features/agencyHouseDownload/actions/agencyHouseDownloadActions'
import { AGENT_HOUSE_DOWNLOAD_RESET } from '../../types/actionTypes'
import ScanTotals from '../../features/scan/components/ScanTotals/ScanTotals'
import ScanCompany from '../../features/scan/components/ScanCompany/ScanCompany'
import { getReportRecordsDates } from '../../features/scan/actions/scanActions'
import { scanSelectedMonth } from '../../features/scan/reducers/scanSelectors'
import './AgencyHouseDownloadScreen.scss'

const AgencyHouseDownloadScreen = () => {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const { Agencies, agenciesLoading, AvailableReports, FailedReports, history, checkDownloadResults } = useSelector(
    ({ agencyHouseDownload }) => agencyHouseDownload
  )
  const scanStarted = useSelector(state => state.download.scanStarted)
  const { pathname } = useLocation()

  useEffect(() => {
    dispatch(getAgencyHouseDownloadAgencies())
    dispatch(getAgencyHouseDownloadAgenciesHistory())
    return () => dispatch({ type: AGENT_HOUSE_DOWNLOAD_RESET })
  }, [dispatch])

  useEffect(() => {
    if (checkDownloadResults && !scanStarted) {
      const withLoader = false
      dispatch(getAgencyHouseDownloadAgencies({ withLoader }))
      dispatch(getAgencyHouseDownloadAgenciesHistory({ withLoader }))
    }
  }, [dispatch, checkDownloadResults, scanStarted])

  useEffect(() => {
    const [agencyDbName] = pathname
      .replace(agencyHouseRoutes.download, '')
      .split('/')
      .filter(p => p.length > 0)
    if (agencyDbName && !scanSelectedMonth()) {
      dispatch(getReportRecordsDates({ agency: agencyDbName }))
    }
  }, [pathname, dispatch])

  if (isMobile) {
    return <NoMobileLayout pageName={t('sidebar.reportDownload')} />
  }

  return (
    <div className='agency-house-download-screen'>
      <AgencyHouseDownloadSidebar />
      <Switch>
        <Route
          path={`${agencyHouseRoutes.download}/:agencyDbName/:id`}
          component={() => (
            <div className='agency-house-download-screen-totals-wrapper'>
              <ScanCompany />
            </div>
          )}
        />
        <Route path={`${agencyHouseRoutes.download}/:agencyDbName`}>
          <div className='agency-house-download-screen-totals-wrapper'>
            <ScanTotals />
          </div>
        </Route>
        <Route path={`${agencyHouseRoutes.download}`}>
          <AgencyHouseDownloadSummaryContainer
            Agencies={Agencies}
            agenciesLoading={agenciesLoading}
            AvailableReports={AvailableReports}
            FailedReports={FailedReports}
            history={history}
          />
        </Route>
      </Switch>
    </div>
  )
}

export default AgencyHouseDownloadScreen
