import { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import './AssociatedAgencyDetailsSidebar.scss'
import { CloseRounded } from '@mui/icons-material'
import {
  editAgencyInviteRequest,
  getAssociatedAgencyDetailsRequest,
} from '../../../../http/requests/agencyHouseRequests'
import Loader from '../../../../components/Common/Loader/Loader'
import { hebrew } from '../../../../i18n'
import { agencyApprovalStatus, agencyPayment, agencyPaymentType } from '../../../../types/agencyTypes'
import { formatAsPhoneNumber } from '../../../../utils/formatAs'
import ButtonText from '../../../../components/Common/ButtonText/ButtonText'
import classNames from 'classnames'
import { userPreference } from '../../../../types/agencyUserTypes'
import ButtonCheckbox from '../../../../components/Common/ButtonCheckbox/ButtonCheckbox'
import LoadingOverlay from '../../../../components/Common/LoadingOverlay/LoadingOverlay'
import { echo } from '../../../../utils/consoleUtils'

const AssociatedAgencyDetailsSidebar = ({ agencyId, closeSidebar }) => {
  const { t } = useTranslation('agencyHouse')
  const [agency, setAgency] = useState(null)
  const [agencyLoading, setAgencyLoading] = useState(true)
  const [formIsDirty, setFormIsDirty] = useState(false)
  const [editCompaniesEnabled, setEditCompaniesEnabled] = useState(false)
  const [editPrefsEnabled, setEditPrefsEnabled] = useState(false)
  const [mirrorRequestLoading, setMirrorRequestLoading] = useState(false)

  const submitBtnDisabled = !formIsDirty || mirrorRequestLoading

  const selectCompany = id => {
    setFormIsDirty(true)
    setAgency(prev => ({
      ...prev,
      Companies: prev.Companies.map(c => ({ ...c, NoAccess: c.Id === id ? !c.NoAccess : c.NoAccess })),
    }))
  }

  const selectPref = pref => {
    setFormIsDirty(true)
    setAgency(prev => ({
      ...prev,
      Preferences: { ...prev.Preferences, [pref]: !prev.Preferences[pref] },
    }))
  }

  const submitMirrorRequest = async () => {
    try {
      setMirrorRequestLoading(true)
      const {
        data: { Data },
      } = await editAgencyInviteRequest({
        agencyId: agency.AgencyId,
        preferences: agency.Preferences,
        companyIds: agency.Companies.filter(c => !c.NoAccess).map(c => c.Id),
      })
      if (!Data) fetchAgency()
      setMirrorRequestLoading(false)
    } catch (error) {
      setMirrorRequestLoading(false)
      echo.log(echo.asWarning(`Send mirror request error`))
      echo.log(echo.asAlert(error))
      fetchAgency()
    }
  }

  const fetchAgency = useCallback(
    async (ignore = false) => {
      try {
        const {
          data: { Data: agency },
        } = await getAssociatedAgencyDetailsRequest(agencyId)
        if (!ignore) {
          setAgency(agency)
          setAgencyLoading(false)
        }
      } catch (error) {
        !ignore && setAgencyLoading(false)
      }
    },
    [agencyId]
  )

  useEffect(() => {
    let ignore = false
    fetchAgency(ignore)
    return () => {
      ignore = true
    }
  }, [agencyId, fetchAgency])

  return (
    <div className='associated-agency-details-sidebar-overlay'>
      <aside className='associated-agency-details-sidebar'>
        {mirrorRequestLoading && <LoadingOverlay />}
        <header>
          <p>{agency?.UserName}</p>
          <button onClick={closeSidebar}>
            <CloseRounded />
          </button>
        </header>
        <div>
          {agencyLoading ? (
            <Loader />
          ) : (
            <>
              <section>
                <div>
                  <p>{t('associatedAgencyDetails.details')}</p>
                </div>
                <div className='associated-agency-details-sidebar-main-details'>
                  <div>
                    <span>{t('agenciesListHeadings.agentIdNumber')}</span>
                    <span>{agency.UserIdNumber ?? '--'}</span>
                  </div>
                  <div>
                    <span>{t('agenciesListHeadings.agentPhone')}</span>
                    <span className='agent-phone-number'>
                      {agency.UserPhone ? formatAsPhoneNumber(agency.UserPhone) : '--'}
                    </span>
                  </div>
                  <div>
                    <span>{t('agenciesListHeadings.email')}</span>
                    <span>{agency.UserEmail ?? '--'}</span>
                  </div>

                  <div>
                    <span>{t('agenciesListHeadings.associatedAgencyName')}</span>
                    <span>{agency.AgencyName ?? '--'}</span>
                  </div>
                  <div>
                    <span>{t('agenciesListHeadings.licenseAgentNumber')}</span>
                    <span>{agency.AgencyLicenseNumber ?? '--'}</span>
                  </div>
                  <div>
                    <span>{t('agenciesListHeadings.requestStatus')}</span>
                    <span>
                      {hebrew()
                        ? agencyApprovalStatus[agency.ConnectionStatus].name
                        : agencyApprovalStatus[agency.ConnectionStatus].nameEn}
                    </span>
                  </div>
                  <hr />

                  <div>
                    <span>{t('agenciesListHeadings.permissions')}</span>
                    <span>{agency.IsFullPermissionAccess ? t('fullMirroring') : t('partialMirroring')}</span>
                  </div>
                  <div>
                    <span>{t('agenciesListHeadings.payments')}</span>
                    <span>
                      {agency.IsPaidByAssociation
                        ? hebrew()
                          ? agencyPayment[agencyPaymentType.byAgency].name
                          : agencyPayment[agencyPaymentType.byAgency].nameEn
                        : hebrew()
                        ? agencyPayment[agencyPaymentType.byAgent].name
                        : agencyPayment[agencyPaymentType.byAgent].nameEn}
                    </span>
                  </div>
                </div>
              </section>
              <section>
                <div>
                  <p>{t('associatedAgencyDetails.companies')}</p>
                  {editCompaniesEnabled ? (
                    <ButtonText disabled={submitBtnDisabled} onClick={submitMirrorRequest}>
                      {t('associatedAgencyDetails.confirmUpdateRequest')}
                    </ButtonText>
                  ) : (
                    <ButtonText onClick={() => setEditCompaniesEnabled(true)}>
                      {t('associatedAgencyDetails.updateRequest')}
                    </ButtonText>
                  )}
                </div>
                <div className='associated-agency-details-sidebar-list'>
                  {agency.Companies.map(c => (
                    <div
                      className={classNames('associated-agency-details-sidebar-list-item', {
                        'no-access': c.NoAccess,
                      })}
                      key={c.Id}
                    >
                      <img src={`./assets/companies-logos-light/${c.Id}.png`} alt={c.Name} />
                      <p>{hebrew() ? c.Name : c.NameEn}</p>
                      {editCompaniesEnabled ? (
                        <ButtonCheckbox checked={!c.NoAccess} onClick={() => selectCompany(c.Id)} />
                      ) : (
                        c.NoAccess && <p>{t('noAccess')}</p>
                      )}
                    </div>
                  ))}
                </div>
              </section>
              <section>
                <div>
                  <p>{t('associatedAgencyDetails.dataAccess')}</p>
                  {editPrefsEnabled ? (
                    <ButtonText disabled={submitBtnDisabled} onClick={submitMirrorRequest}>
                      {t('associatedAgencyDetails.confirmUpdateRequest')}
                    </ButtonText>
                  ) : (
                    <ButtonText onClick={() => setEditPrefsEnabled(true)}>
                      {t('associatedAgencyDetails.updateRequest')}
                    </ButtonText>
                  )}
                </div>
                <div className='associated-agency-details-sidebar-list'>
                  {Object.entries(agency.Preferences)
                    .filter(([pKey, _]) => userPreference[pKey.charAt(0).toLowerCase() + pKey.slice(1)])
                    .map(([pKey, pVal]) => {
                      const pref = pKey.charAt(0).toLowerCase() + pKey.slice(1)
                      return (
                        <div
                          className={classNames('associated-agency-details-sidebar-list-item', {
                            'no-access': !pVal,
                          })}
                          key={userPreference[pref].id}
                        >
                          <p>{hebrew() ? userPreference[pref].name : userPreference[pref].nameEn}</p>
                          {editPrefsEnabled ? (
                            <ButtonCheckbox checked={pVal} onClick={() => selectPref(pKey)} />
                          ) : (
                            !pVal && <p>{t('noAccess')}</p>
                          )}
                        </div>
                      )
                    })}
                </div>
              </section>
            </>
          )}
        </div>
      </aside>
    </div>
  )
}

export default AssociatedAgencyDetailsSidebar
