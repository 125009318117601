import LandingHeader from '../../features/landing/components/LandingHeader/LandingHeader'
import LandingHero from '../../features/landing/components/LandingHero/LandingHero'
import LandingDownloads from '../../features/landing/components/LandingDownloads/LandingDownloads'
import LandingPortfolio from '../../features/landing/components/LandingPortfolio/LandingPortfolio'
import LandingPreferences from '../../features/landing/components/LandingPreferences/LandingPreferences'
import LandingIrregs from '../../features/landing/components/LandingIrregs/LandingIrregs'
import LandingBanner from '../../features/landing/components/LandingBanner/LandingBanner'
import LandingContacts from '../../features/landing/components/LandingContacts/LandingContacts'
import LandingFooter from '../../features/landing/components/LandingFooter/LandingFooter'
import './LandingPage.scss'

const LandingPage = () => {
  return (
    <div className='landing-container' id='home'>
      <LandingHeader />
      <LandingHero />
      <LandingPreferences />
      <LandingDownloads />
      <LandingPortfolio />
      <LandingIrregs />
      <LandingBanner />
      <LandingContacts />
      <LandingFooter />
    </div>
  )
}

export default LandingPage
