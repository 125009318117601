import { createPortal } from 'react-dom'
import { cn } from '../../utils/stylesUtils'
import './ModalContainer.scss'

const ModalContainer = ({ children, usePortal = true, onOverlayClick, bluredBackground = true }) => {
  const content = (
    <div
      className={cn('modal-container-overlay', { blured: bluredBackground })}
      onClick={e => {
        e.stopPropagation()
        onOverlayClick && onOverlayClick()
      }}
    >
      <div
        className='modal-container'
        {...(onOverlayClick && {
          onClick: e => {
            e.stopPropagation()
          },
        })}
      >
        {children}
      </div>
    </div>
  )

  return usePortal ? createPortal(content, document.body) : content
}

export default ModalContainer
