export const authFieldType = {
  userName: 'UserName',
  password: 'Password',
  vaultUser: 'VaultUser',
  vaultPassword: 'VaultPassword',
  idNumber: 'IdNumber',
  answer: 'Answer',
  phoneNumber: 'PhoneNumber'
  // UserName = שם משתמש
  // Password = סיסמא
  // VaultUser = משתמש כספת
  // VaultPassword = סיסמא כספת
  // IdNumber = תעודת זהות
  // Answer = תשובת זיהוי
  // PhoneNumber = מספר טלפון
}

export const authField = {
  [authFieldType.userName]: {
    nameEn: 'UserName',
    name: 'שם משתמש',
  },
  [authFieldType.password]: {
    nameEn: 'Password',
    name: 'סיסמא',
  },
  [authFieldType.vaultUser]: {
    nameEn: 'Vault User',
    name: 'משתמש כספת',
  },
  [authFieldType.vaultPassword]: {
    nameEn: 'Vault Password',
    name: 'סיסמא כספת',
  },
  [authFieldType.idNumber]: {
    nameEn: 'ID Number',
    name: 'תעודת זהות',
  },
  [authFieldType.answer]: {
    nameEn: 'Answer',
    name: 'תשובת זיהוי',
  },
  [authFieldType.phoneNumber]: {
    nameEn: 'Phone Number',
    name: 'מספר טלפון',
  },
}