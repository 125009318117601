import { sortOrder } from '../../types/sortByTypes'
import './SortableColumn.scss'

const SortableColumn = ({ title, columnSortBy, currentSortBy, columnSortOrder, setSort, numericSort, textSort }) => {
  return (
    <div className='sortable-column' onClick={() => setSort(columnSortBy)}>
      <span>{title}</span>
      {currentSortBy === columnSortBy && (
        <i
          className={`fas fa-sort-${numericSort ? 'numeric' : textSort ? 'alpha' : 'amount'}-down${
            columnSortOrder === sortOrder.desc ? '' : '-alt'
          } fa-lg`}
        ></i>
      )}
    </div>
  )
}

export default SortableColumn
