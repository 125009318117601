import {
  getDownloadAgenciesHistoryRequest,
  getDownloadAgenciesRequest,
} from '../../../http/requests/agencyHouseRequests'
import { getRandomHexColor } from '../../../services/getRandom'
import {
  AGENT_HOUSE_DOWNLOAD_AGENCIES_FAIL,
  AGENT_HOUSE_DOWNLOAD_AGENCIES_REQUEST,
  AGENT_HOUSE_DOWNLOAD_AGENCIES_SUCCESS,
  AGENT_HOUSE_DOWNLOAD_HISTORY_FAIL,
  AGENT_HOUSE_DOWNLOAD_HISTORY_REQUEST,
  AGENT_HOUSE_DOWNLOAD_HISTORY_SUCCESS,
} from '../../../types/actionTypes'
import { capitalizeObjectKeys } from '../../../utils/objectUtils'

export const getAgencyHouseDownloadAgencies =
  ({ singleAgency = false, withLoader = true } = {}) =>
  async dispatch => {
    dispatch({ type: AGENT_HOUSE_DOWNLOAD_AGENCIES_REQUEST, payload: withLoader })
    try {
      const res = await getDownloadAgenciesRequest(singleAgency)
      const payload = singleAgency
        ? {
            Agencies: [capitalizeObjectKeys(res.data.data)],
            AvailableReports: res.data.data.availableReports,
            FailedReports: res.data.data.failedReports,
          }
        : res.data.Data
      dispatch({
        type: AGENT_HOUSE_DOWNLOAD_AGENCIES_SUCCESS,
        payload: {
          ...payload,
          Agencies: payload.Agencies.map(a => ({ ...a, AvatarBackgroundColor: getRandomHexColor() })),
        },
      })
    } catch (error) {
      dispatch({
        type: AGENT_HOUSE_DOWNLOAD_AGENCIES_FAIL,
        payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      })
    }
  }

export const getAgencyHouseDownloadAgenciesHistory =
  ({ singleAgency = false, withLoader = true } = {}) =>
  async dispatch => {
    dispatch({ type: AGENT_HOUSE_DOWNLOAD_HISTORY_REQUEST, payload: withLoader })
    try {
      const res = await getDownloadAgenciesHistoryRequest(singleAgency)
      const payload = singleAgency ? capitalizeObjectKeys(res.data.data) : res.data.Data
      dispatch({
        type: AGENT_HOUSE_DOWNLOAD_HISTORY_SUCCESS,
        payload: payload.map(d => ({
          ...d,
          Agencies: d.Agencies.map(a => ({ ...a, AvatarBackgroundColor: getRandomHexColor() })),
        })),
      })
    } catch (error) {
      dispatch({
        type: AGENT_HOUSE_DOWNLOAD_HISTORY_FAIL,
        payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      })
    }
  }
