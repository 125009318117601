import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { customersListType } from '../../../../types/tablesTypes'
import CustomersFilterDropdown from '../CustomersFilter/CustomersFilterDropdown'
import {
  customersFilterByCategories,
  customersFilterByCompanies,
  customersFilterByGroup,
  customersFilterByGroupType,
  getCustomersGroups,
  getCustomersTotalDashboard,
} from '../../actions/customersActions'
import { useTranslation } from 'react-i18next'
import ModalWindowImport from '../../../../components/ModalWindowImport/ModalWindowImport'
import { CUSTOMERS_FILTERS_CLEAR, CUSTOMER_ADD_MEMBER_RESET } from '../../../../types/actionTypes'
import MeatBallsMenu from '../../../../components/Common/MeatBallsMenu/MeatBallsMenu'
import CustomersFilterAdvanced from '../CustomersFilterAdvanced/CustomersFilterAdvanced'
import CustomersPoliciesSearchInput from '../../../../components/CustomersPoliciesSearchInput/CustomersPoliciesSearchInput'
import {
  downloadCustomersGroupsTemplateRequest,
  exportCustomersInfoRequest,
  uploadCustomersGroupsTemplateRequest,
} from '../../../../http/requests/customersRequests'
import ButtonRounded from '../../../../components/Common/ButtonRounded/ButtonRounded'
import AddCustomerGroupModal from '../../../../components/AddCustomerGroupModal/AddCustomerGroupModal'
import { Add } from '@mui/icons-material'
import FamilyGroupsToggle from '../../../../components/FamilyGroupsToggle/FamilyGroupsToggle'
import { familyGroupMode } from '../../../shared/reducers/sharedSelectors'
import './CustomersFilterBar.css'
import { exportPoliciesByRankByGroupRequest } from '../../../../http/requests/policiesRequests'
import { getCheckedIds } from '../../../../utils/objectUtils'
import LoadingOverlay from '../../../../components/Common/LoadingOverlay/LoadingOverlay'
import { sortOrder } from '../../../../types/sortByTypes'

const CustomersFilterBar = ({ currentListType, searchTerm, setSearchTerm }) => {
  const {
    companiesFilter,
    categoriesFilter,
    companiesAndFilter,
    categoriesAndFilter,
    companiesNoFilter,
    categoriesNoFilter,
    groupFilter,
    groupTypesFilter,
    searchTerm: searchText,
    page,
    pageSize,
    collapsedGroups,
    sort,
  } = useSelector(state => state.customers)

  const { addMemberLoading, addMemberSuccess } = useSelector(state => state.customer)
  const [showImportModal, setShowImportModal] = useState(false)
  const [showAddGroupModal, setShowAddGroupModal] = useState(false)
  const [importModalError, setImportModalError] = useState(null)
  const [importFiles, setImportFiles] = useState([])
  const [fileReadSuccess, setFileReadSuccess] = useState(false)
  const [uploadFileLoading, setUploadFileLoading] = useState(false)
  const [uploadFileSuccess, setUploadFileSuccess] = useState(false)
  const [loadingOverlay, setLoadingOverlay] = useState(false)

  const dispatch = useDispatch()
  const { t } = useTranslation('customers')

  const clearFilters = () => {
    dispatch({ type: CUSTOMERS_FILTERS_CLEAR })
    setSearchTerm('')
  }

  const advancedFiltersApplied =
    companiesAndFilter.some(c => c.checked) ||
    categoriesAndFilter.some(c => c.checked) ||
    companiesNoFilter.some(c => c.checked) ||
    categoriesNoFilter.some(c => c.checked)

  const filtersApplied =
    companiesFilter.some(c => c.checked) ||
    categoriesFilter.some(c => c.checked) ||
    advancedFiltersApplied ||
    groupFilter.some(i => i.checked) ||
    groupTypesFilter.some(gt => gt.checked) ||
    searchTerm.length > 0

  const downloadTemplate = async () => {
    await downloadCustomersGroupsTemplateRequest({
      advancedFiltersApplied,
      sort,
      categoriesFilter,
      companiesFilter,
      categoriesAndFilter,
      companiesAndFilter,
      categoriesNoFilter,
      companiesNoFilter,
      groupFilter,
      searchText,
      page,
      pageSize,
      collapsedGroups,
      calculateByGroup: familyGroupMode(),
    })
  }

  const readFile = async files => {
    setFileReadSuccess(false)
    const file = files[0]
    try {
      if (!file) {
        setImportModalError('File can not be read. Check contents')
      } else {
        setFileReadSuccess(true)
        setImportFiles([file])
      }
    } catch (error) {
      setImportModalError('File can not be read. Check contents')
    }
  }

  const deleteFile = file => {
    setImportFiles(importFiles.filter(itm => itm.path !== file.path))
  }

  const submitImportedData = async () => {
    try {
      setUploadFileLoading(true)
      const {
        data: { data: result, errorMessage = 'Error' },
      } = await uploadCustomersGroupsTemplateRequest(importFiles[0])
      if (!result) {
        setImportModalError(errorMessage)
        setImportFiles([])
      } else {
        setUploadFileSuccess(true)
        dispatch(getCustomersGroups())
      }
      setUploadFileLoading(false)
    } catch (error) {
      setUploadFileLoading(false)
    }
  }

  const exportCustomersRankGroups = async () => {
    try {
      setLoadingOverlay(true)
      const requestFilters = {
        categories: getCheckedIds(advancedFiltersApplied ? categoriesAndFilter : categoriesFilter),
        companies: getCheckedIds(advancedFiltersApplied ? companiesAndFilter : companiesFilter),
        excludeCategories: getCheckedIds(advancedFiltersApplied ? categoriesNoFilter : []),
        excludeCompanies: getCheckedIds(advancedFiltersApplied ? companiesNoFilter : []),
        shouldHaveBoth: advancedFiltersApplied,
        percentageSplitGroups: groupFilter.filter(c => c.checked).map(c => c.id + 1),
        searchText,
        customersListType: currentListType,
        page,
        pageSize,
        sortBy: sort.by,
        descending: sort.order === sortOrder.desc,
        collapsedGroups: Object.entries(collapsedGroups)
          .filter(([key, v]) => !v.show)
          .map(([key]) => key),
        calculateByGroup: familyGroupMode(),
      }
      await exportPoliciesByRankByGroupRequest({
        rankFilter: groupFilter.filter(f => f.checked).map(f => f.id + 1),
        ...requestFilters,
      })
      setLoadingOverlay(false)
    } catch (error) {
      setLoadingOverlay(false)
    }
  }

  const closeModalHandler = () => {
    setShowImportModal(false)
    setUploadFileSuccess(false)
    dispatch({ type: CUSTOMER_ADD_MEMBER_RESET })
  }

  const setCompaniesFilter = (id, checked) => {
    dispatch(customersFilterByCompanies(id, checked))
  }

  const setCategoriesFilter = (id, checked) => {
    dispatch(customersFilterByCategories(id, checked))
  }

  const setGroupTypesFilter = (id, checked) => {
    dispatch(customersFilterByGroupType(id, checked))
  }

  const setGroupFilter = (id, checked) => {
    dispatch(customersFilterByGroup(id, checked))
  }

  const importContextMenuItems = [
    {
      text: t('importMenu.importGroups'),
      clickHandler: () => setShowImportModal(true),
    },
    ...(currentListType === customersListType.distribution
      ? [
          {
            text: t('importMenu.exportCustomersRankGroups'),
            clickHandler: exportCustomersRankGroups,
          },
        ]
      : []),
    ...(currentListType !== customersListType.groups
      ? [
          {
            text: t('importMenu.exportCustomers'),
            clickHandler: async () =>
              exportCustomersInfoRequest({
                listType: currentListType,
                advancedFiltersApplied,
                sort,
                categoriesFilter,
                companiesFilter,
                categoriesAndFilter,
                companiesAndFilter,
                categoriesNoFilter,
                companiesNoFilter,
                groupFilter,
                searchTerm: searchText,
                page,
                pageSize,
                collapsedGroups,
              }),
          },
        ]
      : []),
  ]

  return (
    <div className='customers-filterbar'>
      {loadingOverlay && <LoadingOverlay fullscreen />}
      <FamilyGroupsToggle />
      <div className='customers-filterbar-search'>
        <CustomersPoliciesSearchInput
          searchValue={searchTerm}
          setSearchValue={setSearchTerm}
          placeholder={t(`filters.search${currentListType === customersListType.groups ? 'Groups' : ''}Placeholder`)}
        />
      </div>
      <div className='customers-filterbar-filters'>
        {currentListType === customersListType.distribution && (
          <CustomersFilterDropdown
            title={t('filters.groups')}
            filterItems={groupFilter}
            setChecked={setGroupFilter}
            multiSelect={false}
          />
        )}
        {currentListType === customersListType.groups && (
          <CustomersFilterDropdown
            title={t('filters.groupTypes')}
            filterItems={groupTypesFilter ?? []}
            setChecked={setGroupTypesFilter}
          />
        )}
        {currentListType !== customersListType.groups && <CustomersFilterAdvanced />}
        <CustomersFilterDropdown
          title={t('filters.categories')}
          filterItems={categoriesFilter ?? []}
          setChecked={setCategoriesFilter}
          disabled={advancedFiltersApplied}
        />
        <CustomersFilterDropdown
          title={t('filters.companies')}
          filterItems={companiesFilter ?? []}
          setChecked={setCompaniesFilter}
          disabled={advancedFiltersApplied}
        />
        <div>
          <button onClick={clearFilters} className='customers-clear-filters' disabled={!filtersApplied}>
            {t('filters.clear')}
          </button>
        </div>
        <div className='customers-filterbar-import'>
          <MeatBallsMenu menuItems={importContextMenuItems} />
        </div>
        {currentListType === customersListType.groups && (
          <div>
            <ButtonRounded onClick={() => setShowAddGroupModal(true)}>
              <Add />
              <span>{t('filters.addGroup')}</span>
            </ButtonRounded>
          </div>
        )}
      </div>

      {showImportModal && (
        <ModalWindowImport
          closeModal={closeModalHandler}
          title={t('importModal.title')}
          heading={t('importModal.heading')}
          templateDownloadTitle={t('importModal.templateDownloadTitle')}
          downloadTemplate={downloadTemplate}
          dndPlaceholder={t('importModal.dragNDropPlaceholder')}
          uploadBtnCaption={t('importModal.uploadBtn')}
          importBtnCaption={t('importModal.importBtn')}
          fileTypes={'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
          errors={importModalError}
          setErrors={setImportModalError}
          readFiles={readFile}
          readSuccess={fileReadSuccess}
          setReadSucces={setFileReadSuccess}
          files={importFiles}
          deleteFiles={deleteFile}
          submit={submitImportedData}
          submitSuccess={addMemberSuccess || uploadFileSuccess}
          submitLoading={addMemberLoading || uploadFileLoading}
        />
      )}
      {showAddGroupModal && (
        <AddCustomerGroupModal
          closeModal={() => setShowAddGroupModal(false)}
          onAddSuccess={() => {
            dispatch(getCustomersGroups())
            dispatch(getCustomersTotalDashboard())
          }}
        />
      )}
    </div>
  )
}

export default CustomersFilterBar
