import { useState } from 'react'
import { isMobile } from 'react-device-detect'
import BottomSheetBase from '../BottomSheetBase/BottomSheetBase'
import './InputSelect.css'

const InputSelect = ({
  name,
  id,
  values = [],
  label,
  selectedValue,
  showLabel = false,
  onChange = () => {},
  disabled = false,
}) => {
  const [showValuseSheet, setShowValuseSheet] = useState(false)

  const onChangeHandler = e => {
    onChange(e.target.value)
  }

  return (
    <>
      <div className='input-select-container'>
        <div className='select'>
          <select
            name={name}
            id={id}
            required
            value={selectedValue ?? ''}
            disabled={disabled}
            {...(!isMobile
              ? { onChange: onChangeHandler }
              : {
                  onChange: e => {
                    e.preventDefault()
                  },
                })}
          >
            {label && (
              <option value='' disabled>
                {label}
              </option>
            )}
            {values.map(value => (
              <option value={value.id} key={value.id}>
                {value.text}
              </option>
            ))}
          </select>
          {showLabel && label && selectedValue && <label>{label}</label>}
          <span className='line'></span>
        </div>
        {isMobile && (
          <div className='hidden-select-button' {...(isMobile && { onClick: () => setShowValuseSheet(true) })}>
            Show options
          </div>
        )}
      </div>
      <BottomSheetBase
        open={showValuseSheet}
        closeOnOutsideClick
        closeSheet={() => setShowValuseSheet(false)}
        sheetTitle={label}
      >
        <div className='mobile-select-container'>
          <ul className='mobile-select-options-container'>
            {values.map(value => (
              <li
                key={value.id}
                onClick={() => {
                  onChange(value.id)
                  setShowValuseSheet(false)
                }}
              >
                {value.text}
              </li>
            ))}
          </ul>
        </div>
      </BottomSheetBase>
    </>
  )
}

export default InputSelect
