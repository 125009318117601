import { useTranslation } from 'react-i18next'
import './CustomerTotalsDetailsList.scss'
import NoData from '../../../../components/Common/NoData/NoData'
import Loader from '../../../../components/Common/Loader/Loader'
import { getMonthName } from '../../../../services/getMonthName'
import { Fragment, useState } from 'react'
import { ArrowDownward, ArrowUpward, ExpandLess, ExpandMore } from '@mui/icons-material'
import AnalyticsChangesValue from '../../../agencyHouseAnalytics/components/AnalyticsChangesValue/AnalyticsChangesValue'
import { formatAsCurrency } from '../../../../utils/formatAs'
import { hebrew } from '../../../../i18n'
import { cn } from '../../../../utils/stylesUtils'
import { analyticsIncomesListType } from '../../../../types/analyticsIncomesTypes'
import { categoriesObjects } from '../../../../types/categoriesTypes'

const getlogo = item => {
  switch (item.entityType) {
    case analyticsIncomesListType.companies:
      return (
        <div className=''>
          <img src={`./assets/companies-logos-light/${item.key}.png`} alt={item.name} />
        </div>
      )
    case analyticsIncomesListType.categories:
      return (
        <div
          className=''
          style={{
            background: categoriesObjects[item.key]?.markerColor ?? categoriesObjects['default'].markerColor,
            width: '10px',
            height: '10px',
            borderRadius: '2px',
          }}
        ></div>
      )
    default:
      break
  }
}

const CustomerTotalsDetailsList = ({ list, loading, error }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('customer')
  const [openItems, setOpenItems] = useState({})

  const showSubItem = key => setOpenItems(prev => ({ ...prev, [key]: !prev[key] }))

  if (loading) return <Loader />
  if (!loading && error) return <NoData text='Server Error' />
  if (!loading && !error && list.length === 0) return <NoData text='No Data' />

  return (
    <>
      <div className='customer-totals-details-list-header customer-totals-details-list-grid'>
        <div></div>
        <p>{t('totalsCard.total')}</p>
        <p>{t('totalsCard.changeFromPrevMonth')}</p>
        <div></div>
      </div>
      <div className='customer-totals-details-list'>
        {list.map(({ value, key, subItems }) => (
          <Fragment key={key}>
            <div className='customer-totals-details-list-item customer-totals-details-list-grid'>
              <p>
                {getMonthName(new Date(key), language)} {new Date(key).getFullYear()}
              </p>
              <div className='customer-totals-details-list-item-value'>{formatAsCurrency(value.currentValue)}</div>
              <AnalyticsChangesValue currency={true} value={value.diff} />
              <div>
                {subItems.length > 0 && <ShowSubItemsButton isOpen={openItems[key]} onClick={() => showSubItem(key)} />}
              </div>
            </div>
            {openItems[key] &&
              subItems.map(si => (
                <Fragment key={key + si.key}>
                  <div
                    className={cn('customer-totals-details-list-item subitem customer-totals-details-list-grid', {
                      open: openItems[key + si.key],
                    })}
                  >
                    <div>
                      <div>{getlogo(si)}</div>
                      <p>{hebrew() ? si.name : si.nameEn}</p>
                    </div>
                    <div className='customer-totals-details-list-item-value'>
                      {formatAsCurrency(si.value.currentValue)}
                    </div>
                    <AnalyticsChangesValue currency={true} value={si.value.diff} />
                    <div>
                      {si.subItems.length > 0 && (
                        <ShowSubItemsButton
                          isOpen={openItems[key + si.key]}
                          onClick={() => showSubItem(key + si.key)}
                        />
                      )}
                    </div>
                  </div>
                  {openItems[key + si.key] &&
                    si.subItems.map(ssi => (
                      <Fragment key={key + si.key + ssi.key}>
                        <div
                          className={cn(
                            'customer-totals-details-list-item subsubitem customer-totals-details-list-grid'
                          )}
                        >
                          <div>
                            <div>{getlogo(ssi)}</div>
                            <p>{hebrew() ? ssi.name : ssi.nameEn}</p>
                          </div>
                          <div className='customer-totals-details-list-item-value'>
                            {formatAsCurrency(ssi.value.currentValue)}
                          </div>
                          <AnalyticsChangesValue currency={true} value={ssi.value.diff} />
                          <div></div>
                        </div>
                      </Fragment>
                    ))}
                </Fragment>
              ))}
          </Fragment>
        ))}
      </div>
    </>
  )
}

export default CustomerTotalsDetailsList

const ShowSubItemsButton = ({ isOpen, onClick }) => {
  return <button onClick={onClick}>{isOpen ? <ExpandLess /> : <ExpandMore />}</button>
}
