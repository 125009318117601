export const SHARED_CATEGORIES_REQUEST = 'SHARED_CATEGORIES_REQUEST'
export const SHARED_CATEGORIES_SUCCESS = 'SHARED_CATEGORIES_SUCCESS'
export const SHARED_CATEGORIES_FAIL = 'SHARED_CATEGORIES_FAIL'

export const SHARED_AGENTS_REQUEST = 'SHARED_AGENTS_REQUEST'
export const SHARED_AGENTS_SUCCESS = 'SHARED_AGENTS_SUCCESS'
export const SHARED_AGENTS_FAIL = 'SHARED_AGENTS_FAIL'
export const SHARED_AGENTS_RESET = 'SHARED_AGENTS_RESET'

export const SHARED_ROLES_REQUEST = 'SHARED_ROLES_REQUEST'
export const SHARED_ROLES_SUCCESS = 'SHARED_ROLES_SUCCESS'
export const SHARED_ROLES_FAIL = 'SHARED_ROLES_FAIL'

export const SHARED_CRAWLER_REQUEST = 'SHARED_CRAWLER_REQUEST'
export const SHARED_CRAWLER_SUCCESS = 'SHARED_CRAWLER_SUCCESS'
export const SHARED_CRAWLER_FAIL = 'SHARED_CRAWLER_FAIL'

export const SHARED_AGENT_NUMBERS_REQUEST = 'SHARED_AGENT_NUMBERS_REQUEST'
export const SHARED_AGENT_NUMBERS_SUCCESS = 'SHARED_AGENT_NUMBERS_SUCCESS'
export const SHARED_AGENT_NUMBERS_FAIL = 'SHARED_AGENT_NUMBERS_FAIL'
export const SHARED_AGENT_NUMBERS_RESET = 'SHARED_AGENT_NUMBERS_RESET'

export const SHARED_AGENT_REMOVE_REQUEST = 'SHARED_AGENT_REMOVE_REQUEST'
export const SHARED_AGENT_REMOVE_SUCCESS = 'SHARED_AGENT_REMOVE_SUCCESS'
export const SHARED_AGENT_REMOVE_FAIL = 'SHARED_AGENT_REMOVE_FAIL'

export const SHARED_AGENT_RESTORE_REQUEST = 'SHARED_AGENT_RESTORE_REQUEST'
export const SHARED_AGENT_RESTORE_SUCCESS = 'SHARED_AGENT_RESTORE_SUCCESS'
export const SHARED_AGENT_RESTORE_FAIL = 'SHARED_AGENT_RESTORE_FAIL'

export const SHARED_ALL_AGENT_GROUPS_REQUEST = 'SHARED_ALL_AGENT_GROUPS_REQUEST'
export const SHARED_ALL_AGENT_GROUPS_SUCCESS = 'SHARED_ALL_AGENT_GROUPS_SUCCESS'
export const SHARED_ALL_AGENT_GROUPS_FAIL = 'SHARED_ALL_AGENT_GROUPS_FAIL'

export const SHARED_AGENT_GROUPS_REQUEST = 'SHARED_AGENT_GROUPS_REQUEST'
export const SHARED_AGENT_GROUPS_SUCCESS = 'SHARED_AGENT_GROUPS_SUCCESS'
export const SHARED_AGENT_GROUPS_FAIL = 'SHARED_AGENT_GROUPS_FAIL'

export const SHARED_ADD_GROUP_REQUEST = 'SHARED_ADD_GROUP_REQUEST'
export const SHARED_ADD_GROUP_SUCCESS = 'SHARED_ADD_GROUP_SUCCESS'
export const SHARED_ADD_GROUP_FAIL = 'SHARED_ADD_GROUP_FAIL'
export const SHARED_ADD_GROUP_RESET = 'SHARED_ADD_GROUP_RESET'

export const SHARED_REMOVE_GROUP_REQUEST = 'SHARED_REMOVE_GROUP_REQUEST'
export const SHARED_REMOVE_GROUP_SUCCESS = 'SHARED_REMOVE_GROUP_SUCCESS'
export const SHARED_REMOVE_GROUP_FAIL = 'SHARED_REMOVE_GROUP_FAIL'

export const SHARED_UPDATE_GROUP_REQUEST = 'SHARED_UPDATE_GROUP_REQUEST'
export const SHARED_UPDATE_GROUP_SUCCESS = 'SHARED_UPDATE_GROUP_SUCCESS'
export const SHARED_UPDATE_GROUP_FAIL = 'SHARED_UPDATE_GROUP_FAIL'
export const SHARED_UPDATE_GROUP_RESET = 'SHARED_UPDATE_GROUP_RESET'

export const SHARED_SELECT_GROUPS = 'SHARED_SELECT_GROUPS'
export const SHARED_DESELECT_GROUPS = 'SHARED_DESELECT_GROUPS'

export const SHARED_SET_NO_SUBSCRIPTION = 'SHARED_SET_NO_SUBSCRIPTION'
export const SHARED_SET_NO_PAYMENT = 'SHARED_SET_NO_PAYMENT'

export const SHARED_AGENCY_INVITES_REQUEST = 'SHARED_AGENCY_INVITES_REQUEST'
export const SHARED_AGENCY_INVITES_SUCCESS = 'SHARED_AGENCY_INVITES_SUCCESS'
export const SHARED_AGENCY_INVITES_FAIL = 'SHARED_AGENCY_INVITES_FAIL'
export const SHARED_AGENCY_INVITE_UPDATE = 'SHARED_AGENCY_INVITE_UPDATE'

export const SHARED_SET_FAMILY_GROUPS_TYPE = 'SHARED_SET_FAMILY_GROUPS_TYPE'
export const SHARED_SET_COMMISSION_VIEW = 'SHARED_SET_COMMISSION_VIEW'
