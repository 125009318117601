import { getYearAndMonth } from '../../../services/getMonthName'
import {
  POLICIES_FILTERS_REQUEST,
  POLICIES_FILTERS_SUCCESS,
  POLICIES_FILTERS_FAIL,
  POLICIES_SIGNALS_FILTER_APPLY,
} from '../../../types/actionTypes'
import {
  APPLY_POLICIES_FILTERS_DATE,
  APPLY_POLICIES_FILTERS_RANGE,
  APPLY_POLICIES_FILTERS_SEARCH,
  APPLY_POLICIES_FILTERS_SELECT,
  GET_POLICIES_FILTERS_FAIL,
  GET_POLICIES_FILTERS_REQUEST,
  GET_POLICIES_FILTERS_SUCCESS,
  POLICIES_SET_SORT_BY,
  POLICIES_TOGGLE_RANGE_FILTERS_VISIBILITY,
} from '../../../types/actionTypesPolicies'
import { irregularSignal } from '../../../types/irregularSignals'
import { policyStatus } from '../../../types/policyStatusType'
import { sortBy, sortOrder } from '../../../types/sortByTypes'
import { generateMonthDatesForPreviousYears, getLastMonth } from '../../../utils/date'

const signalsFilterInit = Object.values(irregularSignal)
  .filter(s => s?.irregularReasons?.length > 0)
  .map(({ order, id, name, nameEn }) => {
    return {
      order,
      id,
      name,
      nameEn,
      checked: false,
    }
  })
  .sort((a, b) => a.order - b.order)

const irregMonthsInit = generateMonthDatesForPreviousYears({ date: getLastMonth().date, yearsCount: 2 }).map(d => ({
  id: d,
  name: getYearAndMonth(d, { language: 'he' }),
  nameEn: getYearAndMonth(d, { language: 'en' }),
}))

const rangeInit = initVal => ({
  from: initVal,
  to: initVal,
})

const searchFiltersInit = {
  customerName: '',
  customerIdNumber: '',
  policyNumber: '',
  reportName: '',
}

const datesFiltersInit = {
  anyMonthInReportsDatesInit: rangeInit(null),
  firstTimeInReportDatesInit: rangeInit(null),
  irregularityStartDateInit: rangeInit(null),
  policyStartDatesInit: rangeInit(null),
  policyEndDatesInit: rangeInit(null),
  anyMonthInReportsDates: rangeInit(null),
  firstTimeInReportDates: rangeInit(null),
  irregularityStartDate: rangeInit(null),
  policyStartDates: rangeInit(null),
  policyEndDates: rangeInit(null),
}

const selectFiltersInit = {
  companies: [],
  categories: [],
  policyTypes: [],
  agents: [],
  statuses: Object.values(policyStatus),
  irregMonths: irregMonthsInit,
  irregsSort: [
    {
      id: 'month',
      name: 'חודש החריגה',
      nameEn: 'month of irregularity',
      selected: true,
    },
    {
      id: 'total',
      name: "מס' חריגות",
      nameEn: 'total irregs',
      selected: false,
    },
  ],
}

const rangeFiltersInit = {
  premiumRange: rangeInit(null),
  commissionRange: rangeInit(null),
  accumulationRange: rangeInit(null),
  commissionRateByPremiumRange: rangeInit(null),
  commissionRateByAccumulationRange: rangeInit(null),
  averageCommissionRateRange: rangeInit(null),
  expectedCommissionRateRange: rangeInit(null),
}

const initialState = {
  searchFilters: searchFiltersInit,
  datesFilters: datesFiltersInit,
  selectFilters: selectFiltersInit,
  rangeFilters: rangeFiltersInit,
  showRangeFilters: false,
  filtersLoading: true,
  filtersError: null,
  sort: {
    byElementary: sortBy.policyNumber,
    orderElementary: sortOrder.desc,
    byLife: sortBy.policyNumber,
    orderLife: sortOrder.desc,
    bySpecial: sortBy.commission,
    orderSpecial: sortOrder.desc,
    bySignals: sortBy.policyNumber,
    orderSignals: sortOrder.desc,
  },
  companiesFilter: [],
  categoriesFilter: [],
  signalsFilter: signalsFilterInit,
}

export const policiesReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case GET_POLICIES_FILTERS_REQUEST: {
      return {
        ...state,
        filtersLoading: true,
        filtersError: null,
        searchFilters: searchFiltersInit,
        datesFilters: datesFiltersInit,
        selectFilters: selectFiltersInit,
      }
    }
    case GET_POLICIES_FILTERS_SUCCESS: {
      const { dates, select } = payload
      return {
        ...state,
        filtersLoading: false,
        filtersError: null,
        datesFilters: { ...state.datesFilters, ...dates },
        selectFilters: { ...state.selectFilters, ...select },
      }
    }
    case GET_POLICIES_FILTERS_FAIL: {
      return {
        ...state,
        filtersLoading: false,
        filtersError: payload,
        searchFilters: searchFiltersInit,
        datesFilters: datesFiltersInit,
        selectFilters: selectFiltersInit,
      }
    }
    case APPLY_POLICIES_FILTERS_SEARCH: {
      const { key, value } = payload
      return { ...state, searchFilters: { ...state.searchFilters, [key]: value } }
    }
    case APPLY_POLICIES_FILTERS_SELECT: {
      const { key, value } = payload
      return {
        ...state,
        selectFilters: {
          ...state.selectFilters,
          [key]: state.selectFilters[key].map(itm => ({ ...itm, selected: value.includes(itm.id) })),
        },
      }
    }
    case APPLY_POLICIES_FILTERS_RANGE: {
      const { key, value } = payload
      return {
        ...state,
        rangeFilters: {
          ...state.rangeFilters,
          [key]: value,
        },
      }
    }
    case APPLY_POLICIES_FILTERS_DATE: {
      return {
        ...state,
        datesFilters: {
          ...state.datesFilters,
          ...payload,
        },
      }
    }
    case POLICIES_FILTERS_REQUEST:
      return {
        ...state,
        companiesFilter: [],
        categoriesFilter: [],
      }
    case POLICIES_FILTERS_SUCCESS:
      return {
        ...state,
        companiesFilter: action.payload.companiesFilter,
        categoriesFilter: action.payload.categoriesFilter,
      }
    case POLICIES_FILTERS_FAIL:
      return {
        ...state,
        companiesFilter: [],
        categoriesFilter: [],
      }
    case POLICIES_SIGNALS_FILTER_APPLY: {
      const { signalId, checked } = action.payload
      return {
        ...state,
        signalsFilter: state.signalsFilter
          .map(s => (s.id === signalId ? { ...s, checked } : s))
          .map((item, _, arr) =>
            arr.every(itm => itm.checked) || arr.every(itm => !itm.checked) ? { ...item, checked: false } : item
          ),
      }
    }
    case POLICIES_TOGGLE_RANGE_FILTERS_VISIBILITY:
      return {
        ...state,
        showRangeFilters: !state.showRangeFilters,
      }
    case POLICIES_SET_SORT_BY:
      const { sortBy: sortByToSet, listKey } = action.payload
      const {
        sort: { [`by${listKey}`]: currentSortBy, [`order${listKey}`]: currentSortOrder },
      } = state
      return {
        ...state,
        sort: {
          ...state.sort,
          [`by${listKey}`]: sortByToSet,
          [`order${listKey}`]:
            sortByToSet === currentSortBy
              ? currentSortOrder !== sortOrder.asc
                ? sortOrder.asc
                : sortOrder.desc
              : currentSortOrder,
        },
      }
    default:
      return state
  }
}
