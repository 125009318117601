import Loader from '../../../../components/Common/Loader/Loader'
import NoData from '../../../../components/Common/NoData/NoData'
import { useTranslation } from 'react-i18next'
import './PolicyTypesList.scss'
import PolicyTypeListItem from './PolicyTypeListItem'

const PolicyTypesList = ({ list, loading, updateListItem }) => {
  const { t } = useTranslation('policies')
  if (loading || list.length === 0) {
    return <div className='policy-types-list-wrapper'>{loading ? <Loader /> : <NoData />}</div>
  }

  return (
    <div className='policy-types-list-wrapper'>
      <div className='policy-types-list-header policy-types-list-grid'>
        {/* <div>btn</div> */}
        <div>{t('list.heading.policyTypeName')}</div>
        <div>{t('list.heading.company')}</div>
        <div>{t('list.heading.category')}</div>
        <div>{t('list.heading.reportName')}</div>
        <div>{t('list.heading.avrgCommission')}</div>
        <div>{t('list.heading.expectedCommission')}</div>
      </div>
      <div className='policy-types-list'>
        {list.map(pt => (
          <PolicyTypeListItem policyType={pt} key={pt.id} updatePolicyType={updateListItem} />
        ))}
      </div>
    </div>
  )
}

export default PolicyTypesList
