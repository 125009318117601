import { authCookie, COOKIES_MAX_AGE } from '../constants/cookies'
import { useCrossDomainCookies } from '../environment/urls'

export const cookieGetItemValue = item => {
  let name = item + '='
  let ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

export const cookieGetItem = itemKey => {
  const pairs = document.cookie.split('; ')
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=')
    if (pair[0] === itemKey) {
      return unescape(pair.slice(1).join('='))
    }
  }
  return null
}

export const cookieGetItems = items => {
  const pairs = document.cookie.split(';')
  const cookies = {}
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=')
    if (items.some(itm => pair[0].trim().includes(itm)))
      cookies[(pair[0] + '').trim()] = unescape(pair.slice(1).join('='))
  }
  return cookies
}

export const getAuthCookies = () => cookieGetItems(Object.values(authCookie))

export const updateAuthCookies = () =>
  Object.entries(getAuthCookies()).forEach(
    c => (document.cookie = `${c[0]}=${c[1]}; Max-Age=${COOKIES_MAX_AGE}${useCrossDomainCookies}`)
  )
