import { createPortal } from 'react-dom'
import ButtonRounded from '../Common/ButtonRounded/ButtonRounded'
import './ConfirmDeleteDialog.scss'

const ConfirmDeleteDialog = ({
  deleteHandler,
  deleteText = 'OK',
  cancelHandler,
  cancelText = 'Cancel',
  title,
  text,
  disabled,
}) => {
  return createPortal(
    <div className='confirm-delete-dialog-modal-wrapper'>
      <div className='confirm-delete-dialog-modal-container'>
        {title && (
          <header>
            <h5>{title}</h5>
          </header>
        )}
        {text && (
          <section>
            <p>{text}</p>
          </section>
        )}
        <footer className='confirm-dialog-modal-content-btns'>
          <ButtonRounded onClick={cancelHandler} variant='outlined' disabled={disabled}>
            {cancelText}
          </ButtonRounded>
          <ButtonRounded styles='danger' onClick={deleteHandler} disabled={disabled}>
            {deleteText}
          </ButtonRounded>
        </footer>
      </div>
    </div>,
    document.body
  )
}

export default ConfirmDeleteDialog
