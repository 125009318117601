export const getRandomInt = (min, max) => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export const getRandomEmptyArray = (min = 1, max = 5) => [...Array(getRandomInt(min, max))]

export const getRandomIntArray = (min, max, count) => {
  let result = []
  min = Math.ceil(min)
  max = Math.floor(max)
  while (result.length < count) {
    const randomInt = Math.floor(Math.random() * (max - min + 1)) + min
    if (!result.includes(randomInt)) result.push(randomInt)
  }
  return result
}

export const getRandomArrayItems = (arr, count) => {
  let result = []
  const min = 0
  const max = arr.length - 1
  while (result.length < (count || getRandomInt(0, arr.length))) {
    const randomInt = Math.floor(Math.random() * (max - min + 1)) + min
    const arrItem = arr[randomInt]
    if (!result.includes(arrItem)) result.push(arrItem)
  }
  return result
}

export const getRandomArrayItem = arr => arr[getRandomInt(0, arr.length - 1)]

export const getRandomFloat = (min = 0.01, max = 100000) => {
  return Math.random() * (max - min) + min
}

export const getRandomFloatWithPrecision = (min = 0.01, max = 100000) => {
  return Math.round((Math.random() * (max - min) + min + Number.EPSILON) * 100) / 100
}

export const getRandomDate = (start = new Date(2021, 0, 1), end = new Date()) => {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()))
}

export const getRandomLetter = () => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
  const charactersLength = characters.length
  result = characters.charAt(Math.floor(Math.random() * charactersLength))
  return result
}

export const getRandomBoolean = () => Math.random() < 0.5

export const getRandomHexColor = () => {
  var letters = '0123456789ABCDEF'
  var color = '#'
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}
