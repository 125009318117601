import { CheckBoxOutlineBlank, CheckBoxRounded, Close } from '@mui/icons-material'
import ModalWindow from '../../../../components/ModalWindow/ModalWindow'
import { useTranslation } from 'react-i18next'
import { useEffect, useRef, useState } from 'react'
import { cn } from '../../../../utils/stylesUtils'
import { hebrew } from '../../../../i18n'
import { userPreference, userPreferenceType } from '../../../../types/agencyUserTypes'
import ButtonRounded from '../../../../components/Common/ButtonRounded/ButtonRounded'
import './ProfileConnectedAgencySettingsModal.scss'

const ProfileConnectedAgencySettingsModal = ({ settings, cancelConnection, updateConnection, closeModal, loading }) => {
  const [companies, setCompanies] = useState(settings.companies)
  const [preferences, setPreferences] = useState(
    Object.fromEntries(
      Object.entries(settings.preferences).filter(([key, _]) => Object.keys(userPreferenceType).includes(key))
    )
  )

  const [formIsDirty, setFormIsDirty] = useState(false)

  const { t } = useTranslation('profile')

  const selectedCompaniesCount = companies.filter(c => c.hasAccess).length
  const selectedPreferencesCount = Object.values(preferences).filter(v => v).length
  const allCompaniesSelected = !companies.some(c => !c.hasAccess)
  const allPreferencesSelected = !Object.values(preferences).some(pr => !pr)

  const saveBtnDisabled = true || selectedCompaniesCount === 0 || selectedPreferencesCount === 0 || !formIsDirty

  const selectCompany = id => {
    setCompanies(companies.map(c => ({ ...c, hasAccess: c.id === id ? !c.hasAccess : c.hasAccess })))
  }

  const selectPreference = pr => {
    setPreferences({ ...preferences, [pr]: !preferences[pr] })
  }

  const selectAllCompanies = () => {
    setCompanies(companies.map(c => ({ ...c, hasAccess: !allCompaniesSelected })))
  }

  const selectAllPreferences = () => {
    setPreferences(prev => {
      for (const key in prev) {
        prev[key] = !allPreferencesSelected
      }
      return { ...prev }
    })
  }

  useEffect(() => {
    !isFirstRun.current && setFormIsDirty(true)
  }, [companies, preferences])

  const isFirstRun = useRef(true)
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false
      return
    }
  })

  return (
    <ModalWindow renderPortal showCloseIcon={false} loadingOverlay={loading}>
      <div className='profile-connected-agency-settings-modal'>
        <header>
          <p>{t('agencies.settingsModal.heading')}</p>
          <button onClick={closeModal}>
            <Close />
          </button>
        </header>
        <div>
          <section>
            <div className='modal-section-heading'>
              <div>
                <p>{t('agencies.settingsModal.companies', { count: selectedCompaniesCount })}</p>
              </div>
              <div>
                <SelectButton
                  text={t('agencies.settingsModal.selectAll')}
                  selected={allCompaniesSelected}
                  onClick={selectAllCompanies}
                />
              </div>
            </div>
            {companies.map(c => (
              <div key={c.id} className={cn({ selected: c.hasAccess })}>
                <div>
                  <img src={`./assets/companies-logos-light/${c.id}.png`} alt='' />
                  <p>{hebrew() ? c.name : c.nameEn}</p>
                </div>
                <div>
                  <SelectButton selected={c.hasAccess} onClick={() => selectCompany(c.id)} />
                </div>
              </div>
            ))}
          </section>
          <section>
            <div className='modal-section-heading'>
              <p>{t('agencies.settingsModal.data', { count: selectedPreferencesCount })}</p>
              <div>
                <SelectButton
                  text={t('agencies.settingsModal.selectAll')}
                  selected={allPreferencesSelected}
                  onClick={selectAllPreferences}
                />
              </div>
            </div>
            {Object.entries(preferences).map(([k, selected]) => (
              <div key={k} className={cn({ selected })}>
                <div>
                  <p>{hebrew() ? userPreference[k].name : userPreference[k].nameEn}</p>
                </div>
                <div>
                  <SelectButton selected={selected} onClick={() => selectPreference(k)} />
                </div>
              </div>
            ))}
          </section>
        </div>
        <footer>
          <ButtonRounded
            variant='outlined'
            accent='danger'
            onClick={() => cancelConnection({ companies, preferences })}
          >
            {t('agencies.cancelBtn')}
          </ButtonRounded>
          <ButtonRounded
            disabled={saveBtnDisabled}
            onClick={() => {
              updateConnection({ companies, preferences })
            }}
          >
            {t('agencies.settingsModal.saveBtn')}
          </ButtonRounded>
        </footer>
      </div>
    </ModalWindow>
  )
}

export default ProfileConnectedAgencySettingsModal

const SelectButton = ({ selected, onClick, text }) => (
  <button onClick={onClick}>
    {text && <span>{text}</span>}
    {selected ? (
      <CheckBoxRounded style={{ color: 'var(--trend-up-color)' }} />
    ) : (
      <CheckBoxOutlineBlank style={{ color: 'var(--text-secondary-color)' }} />
    )}
  </button>
)
