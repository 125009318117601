import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { registerStep } from '../../../../types/registerStep'
import './RegisterSidebar.scss'

const RegisterSidebar = ({ steps, currStep, setStep }) => {
  const { t } = useTranslation('registration')
  const allowedBack = (fromStep, toStep, allowedFromSteps) => fromStep > toStep && allowedFromSteps.includes(fromStep)

  return (
    <div className='register-sidebar'>
      <div className='register-sidebar--desktop'>
        <ul>
          {steps.map(({ number: step, allowedFromSteps, icon, iconInactive, name }) => {
            step = +step
            if (step === registerStep.twoFactorSms) return null
            return (
              <li
                className={classNames({
                  active: currStep === step,
                  checked: currStep > step,
                  'allowed-back': allowedBack(currStep, step, allowedFromSteps),
                })}
                key={step}
                {...(allowedBack(currStep, step, allowedFromSteps) && { onClick: () => setStep(step) })}
              >
                <div>
                  <div>
                    <h3>{t(`step${name}.name`)}</h3>
                    <h4>{t('step', { stepNumber: step > 3 ? step - 2 : step })}</h4>
                  </div>
                  <img
                    src={currStep > step ? './assets/reg-icons/done/Done.png' : currStep === step ? icon : iconInactive}
                    className={currStep === step ? '' : 'reg-step-img-enlarged'}
                    alt={step}
                  />
                </div>
              </li>
            )
          })}
        </ul>
      </div>
      {steps[currStep - 1] ? (
        <div className='register-sidebar--mobile'>
          <div className='register-sidebar-info'>
            <div className='register-sidebar-title-block'>
              <img
                src={
                  currStep > steps[currStep - 1]
                    ? './assets/reg-icons/done/mobile-active.svg'
                    : steps[currStep - 1].iconMobile
                }
                alt={steps[currStep - 1]}
              />
              <h3>{t(`step${steps[currStep - 1].name}.name`)}</h3>
            </div>
            <div className='register-sidebar-steps-block'>
              <p> שלב</p>
              {steps.length - 1} / <span>{currStep - 1}</span>
            </div>
          </div>
          <div className='status-step-line'>
            <div style={{ width: `${(currStep - 1) * 2 * 10}%` }}></div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

export default RegisterSidebar
