import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import NoMobileLayout from '../../components/NoMobileLayout/NoMobileLayout'
import AgencyHouseLobbyTotals from '../../features/agencyHouseLobby/components/AgencyHouseLobbyTotals/AgencyHouseLobbyTotals'
import ButtonRounded from '../../components/Common/ButtonRounded/ButtonRounded'
import AgencyHouseLobbyHeader from '../../features/agencyHouseLobby/components/AgencyHouseLobbyHeader/AgencyHouseLobbyHeader'
import AgencyHouseLobbySignals from '../../features/agencyHouseLobby/components/AgencyHouseLobbySignals/AgencyHouseLobbySignals'
import { getAgencyHouseSales, getLobbyTotalsRequest } from '../../http/requests/agencyHouseRequests'
import AgencyHouseLobbySales from '../../features/agencyHouseLobby/components/AgencyHouseLobbySales/AgencyHouseLobbySales'
import { lowercaseObjectKeys } from '../../utils/objectUtils'
import { useSelector } from 'react-redux'
import AgenciesGroupsBanner from '../../features/agentHouse/components/AgenciesGroupsBanner/AgenciesGroupsBanner'
import { lobbyTotalsInitial } from '../../types/lobbyTotalsInitial'
import './AgencyHouseLobbyScreen.scss'

const AgencyHouseLobbyScreen = () => {
  const [totals, setTotals] = useState(lobbyTotalsInitial)
  const [totalsLoading, setTotalsLoading] = useState(true)
  const [signals, setSignals] = useState({})
  const [sales, setSales] = useState({ existingCategories: [], nonExistingCategories: [] })
  const [salesLoading, setSalesLoading] = useState(true)
  const [showMore, setShowMore] = useState(false)

  const { selectedGroups } = useSelector(({ agentHouse }) => agentHouse)

  const { t } = useTranslation('agencyHouseLobby')
  const { t: tCommon } = useTranslation('common')

  useEffect(() => {
    const fetchTotals = async () => {
      try {
        setTotalsLoading(true)
        const {
          data: { Data },
        } = await getLobbyTotalsRequest()
        setTotals(Data)
        setSignals({
          ...(Data.MissingOrNoPremiumIrregularitiesCount !== 0 && {
            cancellation: {
              count: Data.MissingOrNoPremiumIrregularitiesCount,
              value: Data.MissingOrNoPremiumIrregularitiesValue,
            },
          }),
          ...(Data.NegativePaymentIrregularitiesCount !== 0 && {
            negativePayment: {
              count: Data.NegativePaymentIrregularitiesCount,
              value: Data.NegativePaymentIrregularitiesValue,
            },
          }),
          ...(Data.CommissionRateIrregularitiesCount !== 0 && {
            commissionDowngrade: {
              count: Data.CommissionRateIrregularitiesCount,
              value: Data.CommissionRateIrregularitiesValue,
            },
          }),
          ...(Data.CommissionZeroIrregularitiesCount !== 0 && {
            commissionZero: {
              count: Data.CommissionZeroIrregularitiesCount,
              value: Data.CommissionZeroIrregularitiesValue,
            },
          }),
          ...(Data.AccumulationPremiumZeroIrregularitiesCount !== 0 && {
            accumulationPremiumZero: {
              count: Data.AccumulationPremiumZeroIrregularitiesCount,
              value: Data.AccumulationPremiumZeroIrregularitiesValue,
            },
          }),
          // ...(Data.PoliciesThatEndsNextMonthCount !== 0 && {
          //   renewal: { count: Data.PoliciesThatEndsNextMonthCount, value: Data.PoliciesThatEndsNextMonthValue },
          // }),
        })
        setTotalsLoading(false)
      } catch (error) {
        setTotalsLoading(false)
        console.log(error)
      }
    }
    fetchTotals()
  }, [selectedGroups])

  useEffect(() => {
    const fetchSales = async () => {
      try {
        setSalesLoading(true)
        const {
          data: { Data },
        } = await getAgencyHouseSales()
        setSales(lowercaseObjectKeys(Data))
        setSalesLoading(false)
      } catch (error) {
        setSalesLoading(false)
        console.log(error)
      }
    }
    //showMore && fetchSales()
  }, [showMore, selectedGroups])

  if (isMobile) {
    return <NoMobileLayout pageName={tCommon('sidebar.lobby')} />
  }

  return (
    <div className='agency-house-lobby-screen-wrapper'>
      <AgenciesGroupsBanner />
      <div className='agency-house-lobby-screen'>
        <AgencyHouseLobbyHeader
          date={totals.Month && new Date(+totals.Month?.split('-')[0], +totals.Month?.split('-')[1] - 1, 1)}
        />
        <AgencyHouseLobbyTotals totals={totals} totalsLoading={totalsLoading} />
        <ButtonRounded variant='outlined' onClick={() => setShowMore(!showMore)}>
          {t('seeMoreBtn')}
        </ButtonRounded>
        {showMore && (
          <>
            <AgencyHouseLobbySignals signals={signals} signalsLoading={totalsLoading} />
            {/* <AgencyHouseLobbySales sales={sales} loading={salesLoading} /> */}
          </>
        )}
      </div>
    </div>
  )
}

export default AgencyHouseLobbyScreen
