import { useState, useEffect } from 'react'
import CrossIconSvg from '../../../../components/Common/SvgIcons/CrossIconSvg/CrossIconSvg'
import SidebarAgentHouse from '../SidebarAgentHouse/SidebarAgentHouse'
import './AddAgencyStepper.scss'
import classNames from 'classnames'
import CheckIconSvg from '../../../../components/Common/SvgIcons/CheckIconSvg/CheckIconSvg'
import { useTranslation } from 'react-i18next'
import AddAgencyTypeSelector from '../AddAgencyTypeSelector/AddAgencyTypeSelector'
import ConfirmDialog from '../../../../components/ConfirmDialog/ConfirmDialog'
import { agencyExistType, agencyType } from '../../../../types/agencyTypes'
import AddNewAgencyForm from '../AddNewAgencyForm/AddNewAgencyForm'
import AssociateAgencyForm from '../AssociateAgencyForm/AssociateAgencyForm'
import AssociateAgencySummary from '../AssociateAgencySummary/AssociateAgencySummary'
import AddNewAgencySummary from '../AddNewAgencySummary/AddNewAgencySummary'
import { useSelector } from 'react-redux'

const addStep = {
  agencyType: 1,
  details: 2,
  result: 3,
}

const AddAgencyStepper = ({ addAgencyType, closeStepper, onAddSuccess, onAssociateSuccess }) => {
  const loggedAsAssociation = useSelector(({ login: { AgencyType } }) => AgencyType === agencyType.association)

  const { t } = useTranslation('agencyHouse')
  const [currStep, setCurrStep] = useState(loggedAsAssociation ? addStep.agencyType : addStep.details)
  const [selectedAgencyType, setSelectedAgencyType] = useState(addAgencyType)
  const [showCancelDialog, setShowCancelDialog] = useState(false)
  const [addSuccess, setAddSuccess] = useState(false)
  const [associateSuccess, setAssociateSuccess] = useState(false)
  // Run callback if at least one adding was successfull
  const [runOnAddSuccess, setRunOnAddSuccess] = useState(false)
  const [runOnAssociateSuccess, setRunOnAssociateSuccess] = useState(false)
  const [newAgency, setNewAgency] = useState({
    agencyName: '',
    agencyNumber: '',
    fields: [],
    companies: [],
  })
  const [associatedAgency, setAssociatedAgency] = useState({
    email: '',
    phone: '',
    companies: [],
    prefs: [],
    paymentType: null,
  })
  const addExistingAgency = selectedAgencyType === agencyExistType.existing

  const closeStepperHandler = () => {
    currStep === addStep.details ? setShowCancelDialog(true) : closeStepper()
  }

  const setSelectedAgencyTypeHanlder = type => {
    setSelectedAgencyType(type)
    setCurrStep(prev => prev + 1)
  }

  const stepBackHandler = () => {
    setCurrStep(prev => prev - 1)
  }

  const currentStepScreen = () => {
    switch (currStep) {
      case addStep.agencyType:
        return <AddAgencyTypeSelector setAgencyExistType={setSelectedAgencyTypeHanlder} type={selectedAgencyType} />
      case addStep.details:
        return addExistingAgency ? (
          <AssociateAgencyForm
            stepBack={stepBackHandler}
            nextStep={() => setCurrStep(addStep.result)}
            setAssociatedAgency={setAssociatedAgency}
            setAssociateSuccess={setAssociateSuccess}
          />
        ) : (
          <AddNewAgencyForm
            {...(loggedAsAssociation && { stepBack: stepBackHandler })}
            nextStep={() => setCurrStep(addStep.result)}
            setNewAgency={setNewAgency}
            setAddSuccess={setAddSuccess}
          />
        )
      case addStep.result:
        return addExistingAgency ? (
          <AssociateAgencySummary
            addOneMore={() => setCurrStep(addStep.details)}
            finish={closeStepper}
            associatedAgency={associatedAgency}
          />
        ) : (
          <AddNewAgencySummary
            newAgency={newAgency}
            addOneMore={() => setCurrStep(addStep.details)}
            finish={closeStepper}
          />
        )
      default:
        break
    }
  }

  useEffect(() => {
    addSuccess && !runOnAddSuccess && setRunOnAddSuccess(true)
    associateSuccess && !runOnAssociateSuccess && setRunOnAssociateSuccess(true)
  }, [addSuccess, runOnAddSuccess, associateSuccess, runOnAssociateSuccess])

  useEffect(() => {
    return () => {
      runOnAddSuccess && onAddSuccess && onAddSuccess()
      runOnAssociateSuccess && onAssociateSuccess && onAssociateSuccess()
    }
  }, [runOnAddSuccess, onAddSuccess, onAssociateSuccess, runOnAssociateSuccess])

  return (
    <div className='add-agency-stepper-wrapper'>
      <div className='add-agency-stepper'>
        <header className='add-agency-stepper-header'>
          <div className='add-agency-stepper-steps'>
            {Object.entries(addStep)
              .filter(([k, v]) => (loggedAsAssociation ? true : v !== addStep.agencyType))
              .map(([k, v], i) => (
                <div
                  key={k}
                  className={classNames('add-agency-stepper-step', { upcoming: currStep < v, current: currStep == v })}
                >
                  <div className='add-agency-stepper-step-badge'>{currStep > v ? <CheckIconSvg /> : i + 1}</div>
                  <div className='add-agency-stepper-step-text'>{t(`addAgencyStep.${k}`)}</div>
                </div>
              ))}
          </div>
          <button onClick={closeStepperHandler}>
            <CrossIconSvg />
          </button>
        </header>
        <main className='add-agency-stepper-content'>{currentStepScreen()}</main>
        {showCancelDialog && (
          <ConfirmDialog
            confirm={() => closeStepper()}
            decline={() => {
              setShowCancelDialog(false)
            }}
            title={t('addAgencyStep.cancelDialogTitle')}
            text={t('addAgencyStep.cancelDialogText')}
            confirmText={t('addAgencyStep.cancelDialogOkBtn')}
            declineText={t('addAgencyStep.cancelDialogCancelBtn')}
          />
        )}
      </div>
    </div>
  )
}

export default AddAgencyStepper
