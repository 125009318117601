export const agencyUserRoleType = {
  agent: 'agent',
  administrator: 'administrator',
  watchOnly: 'watch-only',
  fullUserAccess: 'full-user-access',
  secretaryUserAccess: 'secretary-user-access',
}

export const agencyUserRole = {
  [agencyUserRoleType.agent]: {
    name: 'סוכן',
    nameEn: 'Agent',
  },
  [agencyUserRoleType.administrator]: {
    name: 'אדמינסטרציה',
    nameEn: 'Administration',
  },
  [agencyUserRoleType.watchOnly]: {
    name: 'צפייה בלבד',
    nameEn: 'Watch Only',
  },
  [agencyUserRoleType.fullUserAccess]: {
    name: 'משתמש עם גישה מלאה',
    nameEn: 'Full Access User',
  },
  [agencyUserRoleType.secretaryUserAccess]: {
    name: 'secretaryUserAccess',
    nameEn: 'secretaryUserAccess',
  },
}

export const userPreferenceAccessType = {
  data: 1,
  page: 2,
}

export const userPreferenceType = {
  all: 'all',
  elementary: 'elementary',
  lifeAndFinance: 'lifeAndFinance',
  download: 'download',
  lobby: 'lobby',
  analytics: 'analytics',
  premium: 'premium',
  accumulation: 'accumulation',
  comission: 'comission',
  comissionRate: 'comissionRate',
}

export const userPreference = {
  [userPreferenceType.all]: {
    id: userPreferenceType.all,
    name: 'הכל',
    nameEn: 'All',
    accessTypes: [userPreferenceAccessType.data, userPreferenceAccessType.page],
  },
  [userPreferenceType.elementary]: {
    id: userPreferenceType.elementary,
    name: 'נתוני אלמנטרי',
    nameEn: 'Elementary',
    accessTypes: [userPreferenceAccessType.data],
  },
  [userPreferenceType.lifeAndFinance]: {
    id: userPreferenceType.lifeAndFinance,
    name: 'נתוני חיים',
    nameEn: 'Life & Finance',
    accessTypes: [userPreferenceAccessType.data],
  },
  [userPreferenceType.download]: {
    id: userPreferenceType.download,
    name: 'הורדת דוחות',
    nameEn: 'Download Reports',
    accessTypes: [userPreferenceAccessType.page],
  },
  [userPreferenceType.lobby]: {
    id: userPreferenceType.lobby,
    name: 'לובי',
    nameEn: 'Lobby',
    accessTypes: [userPreferenceAccessType.page],
  },
  [userPreferenceType.analytics]: {
    id: userPreferenceType.analytics,
    name: 'ניתוח תיק',
    nameEn: 'Analytics',
    accessTypes: [userPreferenceAccessType.page],
  },
  [userPreferenceType.premium]: {
    id: userPreferenceType.premium,
    name: 'פרמיה',
    nameEn: 'Premium',
    accessTypes: [userPreferenceAccessType.data],
  },
  [userPreferenceType.accumulation]: {
    id: userPreferenceType.accumulation,
    name: 'צבירה',
    nameEn: 'Accumulation',
    accessTypes: [userPreferenceAccessType.data],
  },
  [userPreferenceType.comission]: {
    id: userPreferenceType.comission,
    name: 'עמלות',
    nameEn: 'Commission',
    accessTypes: [userPreferenceAccessType.data],
  },
  [userPreferenceType.comissionRate]: {
    id: userPreferenceType.comissionRate,
    name: 'שיעור עמלה',
    nameEn: 'Commission Rate',
    accessTypes: [userPreferenceAccessType.data],
  },
}

export const userStatusType = {
  inviteWaiting: 'inviteWaiting',
}

export const userStatus = {
  [userStatusType.inviteWaiting]: {
    name: 'הזמנה טרם אושרה',
    nameEn: 'Invite not yet confirmed',
  },
}

export const buildUserPreferences = preferences => {
  return Object.entries(preferences)
    .filter(([k, v]) => userPreferenceType.hasOwnProperty(k.charAt(0).toLowerCase() + k.slice(1)) && v === true)
    .map(([k, _]) => k.charAt(0).toLowerCase() + k.slice(1))
}

export const getUserPreferencesList = () => {
  return Object.values(userPreference).filter(pr => pr.id !== userPreferenceType.all)
}
