import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Redirect, Switch } from 'react-router-dom'
import { useIdleTimer } from 'react-idle-timer'
import { useTranslation } from 'react-i18next'
import PrivateRoute from './HOCs/PrivateRoute'
import LogoutConfirm from './features/login/components/LogoutConfirm/LogoutConfirm'
import LandingPage from './views/LandingPage/LandingPage'
import SignalRNotifications from './components/SignalRNotifications/SignalRNotifications'
import PaymentPlansModal from './components/PaymentPlansModal/PaymentPlansModal'
import { SHARED_SET_NO_SUBSCRIPTION } from './types/actionTypesShared'
import { createHashHistory } from 'history'
import { ASK_LOGOUT_TIMEOUT, AUTO_LOGOUT_TIMEOUT } from './constants/login'
import { cookieGetItem, getAuthCookies, updateAuthCookies } from './services/cookieStorgeService'
import { authCookie } from './constants/cookies'
import AcceptCookiesBanner from './components/AcceptCookiesBanner/AcceptCookiesBanner'
import { agencyHouseRoutes } from './environment/urls'
import {
  bigAgencyPrivateRoutes,
  sharedPrivateRoutes,
  sharedPublicRoutes,
  regularAgencyPrivateRoutes,
} from './routes/routes'
import './App.css'
import AgencyHouseInviteNotyfier from './features/shared/components/AgencyHouseInviteNotyfier/AgencyHouseInviteNotyfier'
import SignalRCrawlerNotifications from './components/SignalRCrawlerNotifications/SignalRCrawlerNotifications'
import SignalRLogout from './components/SignalRLogout/SignalRLogout'
import useQuery from './hooks/useQuery'
import ModalAgreement from './components/ModalAgreement/ModalAgreement'
import { hebrew } from './i18n'
import { isRegularOrDeltaAgency } from './features/login/reducers/loginSelectors'
import useApiRequest from './hooks/useApiRequest'
import { getPolicyTypesChangesRequest } from './http/requests/policiyMappingRequests'
import PolicyTypesChangesModal from './features/shared/components/PolicyTypesChangesModal/PolicyTypesChangesModal'

const { APP_CONFIG } = window

const askLogoutTime = ASK_LOGOUT_TIMEOUT
const autoLogoutTime = AUTO_LOGOUT_TIMEOUT

function App() {
  const history = createHashHistory()
  const { i18n } = useTranslation()
  const { UserId } = useSelector(({ login }) => login)
  const { noSubscriptionFound } = useSelector(({ shared }) => shared)
  const dispatch = useDispatch()
  const [showLogout, setShowLogout] = useState(false)
  const [showCookiesBanner, setShowCookiesBanner] = useState(false)
  const [showAgencyHouseNotyfier, setShowAgencyHouseNotyfier] = useState(false)
  const [showPolicyTypesChangesModal, setShowPolicyTypesChangesModal] = useState(false)

  const { data: policyTypesChanges, fetch: getPolicyTypesChanges } = useApiRequest(getPolicyTypesChangesRequest, {
    dataInit: [],
  })

  const query = useQuery()

  const acceptCookies = () => (document.cookie = `acceptedCookies=true; Max-Age=${31536000000};`)

  const handleOnIdle = event => {
    if (UserId) {
      setShowLogout(true)
    }
  }

  const handleOnAction = event => {
    if (UserId !== '' && Object.keys(getAuthCookies()).length === Object.keys(authCookie).length) {
      updateAuthCookies()
    }
  }

  useIdleTimer({
    //timeout: 1000 * 10,// 10 secs
    timeout: askLogoutTime,
    onIdle: handleOnIdle,
    onAction: handleOnAction,
    //throttle: 1000, // run action no more then 1 secs
    throttle: 10000, // run action no more then 10 secs
  })

  const closePaymentPlansModal = () => dispatch({ type: SHARED_SET_NO_SUBSCRIPTION, payload: false })

  const onPaymentSuccessHandler = () => {
    closePaymentPlansModal()
    history.push('/download')
  }

  useEffect(() => {
    document.cookie = 'agentGroups=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
    document.cookie = 'agencyGroups=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
  }, [])

  useEffect(() => {
    if (!cookieGetItem(`acceptedCookies`)) {
      setShowCookiesBanner(true)
    }
  }, [])

  useEffect(() => {
    setShowAgencyHouseNotyfier(
      isRegularOrDeltaAgency() && Boolean(UserId) && !history.location.pathname.includes('profile')
    )
  }, [UserId, history])

  useEffect(() => {
    if (UserId && isRegularOrDeltaAgency()) {
      getPolicyTypesChanges()
    }
  }, [UserId])

  useEffect(() => {
    policyTypesChanges.length > 0 && setShowPolicyTypesChangesModal(true)
  }, [policyTypesChanges])

  useEffect(() => {
    const root = document.getElementById('body-root')
    root.dir = hebrew() ? 'rtl' : 'ltr'
  }, [])

  return (
    <>
      {(process.env.REACT_APP_API_ENV === 'development' ||
        process.env.REACT_APP_API_ENV === 'development-stage' ||
        APP_CONFIG.SHOW_LANG_SWITCH) && (
        <div className='change-lng-wrapper'>
          <button
            className='change-lng-btn'
            onClick={() => {
              i18n.changeLanguage('he')
              const root = document.getElementById('body-root')
              root.dir = 'rtl'
            }}
          >
            he
          </button>
          <button
            className='change-lng-btn'
            onClick={() => {
              i18n.changeLanguage('en')
              const root = document.getElementById('body-root')
              root.dir = 'ltr'
            }}
          >
            en
          </button>
        </div>
      )}
      <Switch>
        {sharedPublicRoutes.map(r => (
          <Route {...r} key={r.path + 'commonPublic'} />
        ))}
        {isRegularOrDeltaAgency()
          ? regularAgencyPrivateRoutes.map(r => <PrivateRoute {...r} key={r.path + 'regular'} />)
          : bigAgencyPrivateRoutes.map(r => <PrivateRoute {...r} key={r.path + 'big'} />)}
        {sharedPrivateRoutes.map(r => (
          <PrivateRoute {...r} key={r.path + 'commonPrivate'} />
        ))}
        <Route path='/'>
          {UserId ? (
            <Redirect to={isRegularOrDeltaAgency() ? '/lobby' : `${agencyHouseRoutes.agencies}`} />
          ) : (
            <LandingPage />
          )}
        </Route>
      </Switch>
      <SignalRNotifications />
      <SignalRCrawlerNotifications />
      <SignalRLogout />
      {showAgencyHouseNotyfier && <AgencyHouseInviteNotyfier />}
      {showLogout && UserId && (
        <LogoutConfirm closeModal={() => setShowLogout(false)} autoLogout={true} autoLogoutTime={autoLogoutTime} />
      )}
      {noSubscriptionFound &&
        UserId &&
        !history.location.pathname.includes('register') &&
        !history.location.pathname.includes('login') && (
          <PaymentPlansModal closeModal={closePaymentPlansModal} onPaymentSuccess={onPaymentSuccessHandler} />
        )}
      {showCookiesBanner && (
        <AcceptCookiesBanner closeBanner={() => setShowCookiesBanner(false)} acceptCookies={acceptCookies} />
      )}
      {query.get('agreement') && (
        <ModalAgreement
          closeModal={() => {
            query.delete('agreement')
            history.replace({ search: new URLSearchParams(query).toString() })
          }}
          currentAgreementType={query.get('agreement')}
        />
      )}
      {showPolicyTypesChangesModal && (
        <PolicyTypesChangesModal
          changes={policyTypesChanges}
          closeModal={() => setShowPolicyTypesChangesModal(false)}
        />
      )}
    </>
  )
}

export default App
