import {
  DOWNLOAD_REMOVE_COMPANY_REQUEST,
  DOWNLOAD_REMOVE_COMPANY_SUCCESS,
  DOWNLOAD_REMOVE_COMPANY_FAIL,
  DOWNLOAD_SET_AGENCY_DBNAME,
  AGENT_HOUSE_DOWNLOAD_CHECK_RESULTS,
} from '../../../types/actionTypes'
import { urlAgency } from '../../../environment/urls'
import $api from '../../../http'

export const deleteCompany = (companyId, agency) => async dispatch => {
  dispatch({
    type: DOWNLOAD_REMOVE_COMPANY_REQUEST,
  })
  try {
    const {
      data: { hasError },
    } = await $api.delete(`${urlAgency(agency)}remove-company/${companyId}`)
    console.log(hasError)
    dispatch({
      type: DOWNLOAD_REMOVE_COMPANY_SUCCESS,
      payload: { companyId, hasError, agency },
    })
    if (!hasError && agency) {
      dispatch({ type: AGENT_HOUSE_DOWNLOAD_CHECK_RESULTS })
    }
  } catch (error) {
    dispatch({
      type: DOWNLOAD_REMOVE_COMPANY_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const setAgencyDbName = agencyDbName => (dispatch, getState) => {
  const { Agencies } = getState().agencyHouseDownload
  const { agencyDnsName } = getState().login
  const dbName = Agencies.find(a => a.DbName === agencyDbName)?.DbName ?? agencyDnsName
  dbName && dispatch({ type: DOWNLOAD_SET_AGENCY_DBNAME, payload: dbName })
}
