import { useTranslation } from 'react-i18next'
import AgenciesNavbar from '../../features/agentHouse/components/AgenciesNavbar/AgenciesNavbar'
import { Redirect, Route, useHistory, useParams, useRouteMatch } from 'react-router-dom'
import AssociatedAgencies from '../../features/agentHouse/components/AssociatedAgencies/AssociatedAgencies'
import SubAgencies from '../../features/agentHouse/components/SubAgencies/SubAgencies'
import { agencyType } from '../../types/agencyTypes'
import { useSelector } from 'react-redux'
import AgencyHouseDetails from '../../features/agentHouse/components/AgencyHouseDetails/AgencyHouseDetails'
import './AgenciesScreen.scss'

const agencySubroute = {
  details: {
    path: 'details',
    component: <AgencyHouseDetails />,
    agencyTypes: [agencyType.association, agencyType.big],
  },
  subagencies: {
    path: 'subagencies',
    component: <SubAgencies />,
    agencyTypes: [agencyType.association, agencyType.big],
  },
  associated: {
    path: 'associated',
    component: <AssociatedAgencies />,
    agencyTypes: [agencyType.association],
  },
}

function AgenciesSubrouteWrapper() {
  const { AgencyType } = useSelector(({ login }) => login)
  const { subpath } = useParams()
  const history = useHistory()
  let pathToRoute = agencySubroute[subpath]?.agencyTypes.includes(AgencyType) && agencySubroute[subpath]?.path
  if (!pathToRoute) {
    pathToRoute = agencySubroute.associated.path
    history.replace({ pathname: pathToRoute })
  }
  return agencySubroute[pathToRoute].component ?? <div>Under development</div>
}

const AgenciesScreen = () => {
  const { AgencyType } = useSelector(({ login }) => login)
  const { t } = useTranslation('agencyHouse')
  const { path } = useRouteMatch()

  return (
    <main className='agencies-screen'>
      <h5>{t('agencies')}</h5>
      <AgenciesNavbar
        navbarItems={Object.values(agencySubroute)
          .filter(r => r.agencyTypes.includes(AgencyType))
          .map(({ path: subpath }) => ({
            path: `${path}/${subpath}`,
            text: t(`agenciesNavbar.${subpath}`),
          }))}
      />
      <Route path={`${path}`} exact>
        <Redirect to={`${path}/${agencySubroute.subagencies.path}`} />
      </Route>
      <Route path={`${path}/:subpath`} component={AgenciesSubrouteWrapper} />
    </main>
  )
}

export default AgenciesScreen
