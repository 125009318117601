import { useState } from 'react'
import CustomerPoliciesList from '../CustomerPoliciesList/CustomerPoliciesList'
import './CustomerPoliciesGroup.scss'

const CustomerPoliciesGroup = ({ groupName, policies, policiesTotals }) => {
  const [groupHidden, setGroupHidden] = useState(false)
  return (
    <div className='customer-policies-group'>
      <header>
        <button onClick={() => setGroupHidden(!groupHidden)}>
          {groupHidden ? (
            <img src={`./assets/icon-add/Add.png`} alt='more' />
          ) : (
            <img src={`./assets/minus-icon/Close.png`} alt='less' />
          )}
        </button>
        <p>
          {groupName} <span>({policies.length})</span>
        </p>
      </header>
      {!groupHidden && <CustomerPoliciesList policies={policies} policiesTotals={policiesTotals} />}
    </div>
  )
}

export default CustomerPoliciesGroup
