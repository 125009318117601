import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SearchGroupCustomersInput from './SearchGroupCustomersInput/SearchGroupCustomersInput'
import './AddCustomerGroupModal.scss'
import ModalWindow from '../ModalWindow/ModalWindow'
import InputText from '../Common/InputText/InputText'
import Loader from '../Common/Loader/Loader'
import NoData from '../Common/NoData/NoData'
import { hebrew } from '../../i18n'
import Button from '../Common/Button/Button'
import { addCustomersGroupRequest, filterCustomersRequest } from '../../http/requests'
import { useBottomScrollListener } from 'react-bottom-scroll-listener'
import InputSelect from '../Common/InputSelect/InputSelect'
import { getCustomerGroupTypes } from '../../features/customer/actions/customerActions'
import { editCustomersGroup } from '../../features/customers/actions/customersActions'
import { CUSTOMERS_GROUP_EDIT_RESET } from '../../types/actionTypes'
import { customerGroupType } from '../../types/customerGroupTypes'
import { DeleteOutline, ErrorOutlineOutlined, GppGoodOutlined, Groups, HighlightOff } from '@mui/icons-material'
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog'

const customerHasExistingFamilyGroup = (customer, group) => {
  if ((customer.familyGroupId && !group) || (customer.familyGroupId && group.id !== customer.familyGroupId)) {
    return customer
  }
}

const AddCustomerGroupModal = ({
  closeModal,
  group = null,
  groupCustomers = null,
  onEditSuccess = () => {},
  onAddSuccess = () => {},
}) => {
  const { groupTypes } = useSelector(({ customer }) => customer)
  const { customersGroups, editCustomersGroupSuccess } = useSelector(({ customers }) => customers)

  const { t } = useTranslation('customers')
  const [searchCustomers, setSearchCustomers] = useState('')
  const [groupName, setGroupName] = useState(
    group ? group.name ?? t('customersGroupNamePlaceholder', { groupId: group.id }) : ''
  )
  const [selectedCustomers, setSelectedCustomers] = useState(
    group ? group.customers : groupCustomers ? groupCustomers : []
  )
  const [groupType, setGroupType] = useState(group ? group.type : null)
  const [customers, setCustomers] = useState([])
  const [customersCount, setCustomersCount] = useState(0)
  const [customersLoading, setCustomersLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [addGroupSuccess, setAddGroupSuccess] = useState(false)
  const [addGroupLoading, setAddGroupLoading] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [showRemoveAllConfirm, setShowRemoveAllConfirm] = useState(false)
  const [customerToCheck, setCustomerToCheck] = useState({ name: '', familyGroupName: '' })

  const pageSize = 25
  const pagesCount = Math.ceil(customersCount === 0 ? 1 : customersCount / pageSize)
  const groupToEdit = customersGroups.flatMap(cg => cg.groupItems).find(gr => gr.id === group?.id)
  const editGroupLoading = groupToEdit && groupToEdit.loading
  const dispatch = useDispatch()
  const listRef = useBottomScrollListener(() => {
    if (currentPage < pagesCount && !customersLoading) {
      setCurrentPage(prev => prev + 1)
    }
  })

  const familyGroupType = groupType === customerGroupType.family

  const isMainFamilyGroupMember = () => selectedCustomers.find(c => c.isMainFamilyGroupMember)

  const submitBtnDisabled =
    selectedCustomers.length === 0 ||
    groupName.length === 0 ||
    !groupType ||
    (familyGroupType && !isMainFamilyGroupMember())

  const selectCustomerHandler = customer => {
    if (selectedCustomers.find(c => c.id === customer.id)) {
      setSelectedCustomers(selectedCustomers.filter(c => c.id !== customer.id))
    } else {
      const customerWithGroup = customerHasExistingFamilyGroup(customer, group)
      if (customerWithGroup && familyGroupType) {
        setCustomerToCheck(customerWithGroup)
        setShowConfirm(true)
      } else {
        setSelectedCustomers([...selectedCustomers, customer])
      }
    }
  }

  const setGroupTypeHandler = type => {
    if (
      type === customerGroupType.family &&
      selectedCustomers.filter(c => customerHasExistingFamilyGroup(c, group)).length > 0
    ) {
      setShowRemoveAllConfirm(true)
    } else {
      setGroupType(type)
    }
  }

  const setMainMember = ({ id }) =>
    setSelectedCustomers(prev => prev.map(c => ({ ...c, isMainFamilyGroupMember: c.id === id })))
  const removeMainMember = () => setSelectedCustomers(prev => prev.map(c => ({ ...c, isMainFamilyGroupMember: false })))

  const searchCustomersHandler = value => {
    setCurrentPage(1)
    setCustomers([])
    setCustomersCount(0)
    setSearchCustomers(value)
  }

  const addGroupHandler = async () => {
    try {
      setAddGroupLoading(true)
      const result = await addCustomersGroupRequest({
        groupName,
        groupType,
        selectedCustomers: selectedCustomers.map(c => c.id),
        ...(familyGroupType && { ownerCustomerId: isMainFamilyGroupMember().id }),
      })
      setAddGroupSuccess(!result.hasError)
    } catch (error) {
      console.log('Error adding group')
    } finally {
      setAddGroupLoading(false)
    }
  }

  const editGroupHandler = async () => {
    if (
      (group.name === groupName || !group.name) &&
      group.type === groupType &&
      group.customers.length === selectedCustomers.length &&
      group.customers.every((c, i) => c.id === selectedCustomers[i].id) &&
      isMainFamilyGroupMember().id ===
        (group ? group.customers : groupCustomers).find(c => c.isMainFamilyGroupMember)?.id
    ) {
      closeModal()
      return
    }
    dispatch(
      editCustomersGroup({
        ...group,
        groupName,
        groupType,
        selectedCustomers: selectedCustomers.map(c => c.id),
        ...(familyGroupType && { ownerCustomerId: isMainFamilyGroupMember().id }),
      })
    )
  }

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        setCustomersLoading(true)
        const response = await filterCustomersRequest(searchCustomers.toUpperCase(), currentPage - 1, pageSize)
        const { items, totalCount } = response
        setCustomers(prev => [...prev, ...[...(items ?? [])]])
        setCustomersCount(totalCount)
      } catch (error) {
        console.log('Error getting customers')
      } finally {
        setCustomersLoading(false)
      }
    }
    fetchCustomers()
  }, [currentPage, searchCustomers])

  useEffect(() => {
    dispatch(getCustomerGroupTypes())
  }, [dispatch])

  useEffect(() => {
    if ((addGroupSuccess && !addGroupLoading) || (group && editCustomersGroupSuccess && !editGroupLoading)) closeModal()
    if (group && editCustomersGroupSuccess && !editGroupLoading) onEditSuccess()
    if (!addGroupLoading && addGroupSuccess) onAddSuccess()
    return () => {
      editCustomersGroupSuccess && dispatch({ type: CUSTOMERS_GROUP_EDIT_RESET })
    }
  }, [
    addGroupSuccess,
    addGroupLoading,
    group,
    editCustomersGroupSuccess,
    editGroupLoading,
    closeModal,
    onEditSuccess,
    onAddSuccess,
    dispatch,
  ])

  return (
    <ModalWindow
      closeOnOutsideClick={false}
      closeModal={closeModal}
      renderPortal
      showCloseIcon={false}
      loadingOverlay={addGroupLoading || (group && editGroupLoading)}
    >
      <div className='add-customer-group-modal'>
        <header className='add-customer-group-modal-header'>
          <div>{t('customersGroupModal.modalTitle')}</div>
          <button onClick={closeModal}>
            <img src='./assets/close-icon-square/close-square.png' alt='cls' />
          </button>
        </header>
        <div className='add-customer-group-modal-content'>
          <div className='add-customer-group-modal-sidebar'>
            <div className='add-customer-group-modal-sidebar-inputs'>
              <InputText
                value={groupName}
                onChange={setGroupName}
                required={true}
                label={t('customersGroupModal.groupNamePlaceholder')}
              />
              <InputSelect
                disabled={group && group.type === customerGroupType.family}
                label={t('customersGroupModal.groupTypePlaceholder')}
                values={groupTypes
                  .filter(gr => {
                    if (group && !familyGroupType) {
                      return gr.id !== customerGroupType.family
                    } else {
                      return true
                    }
                  })
                  .map(gr => {
                    return {
                      ...gr,
                      id: gr.id,
                      text: hebrew() ? gr.name : gr.englishName,
                    }
                  })}
                selectedValue={groupType}
                onChange={type => setGroupTypeHandler(+type)}
                showLabel
              />
            </div>
            <div className='add-customer-group-modal-sidebar-main-member'>
              {familyGroupType && (
                <>
                  <div className='add-customer-group-modal-selected-heading'>
                    {t('customersGroupModal.mainFamilyMember')}
                  </div>
                  <div className='add-customer-group-modal-selected-items'>
                    <SelectedCustomer
                      customer={isMainFamilyGroupMember()}
                      removeMain={removeMainMember}
                      groupType={groupType}
                      removeCustomer={() => selectCustomerHandler(isMainFamilyGroupMember())}
                    />
                  </div>
                </>
              )}
            </div>
            <div className='add-customer-group-modal-sidebar-selected'>
              <div className='add-customer-group-modal-selected-heading'>
                {selectedCustomers.length > 0 && t('customersGroupModal.selectedListHeader')}
              </div>
              <div className='add-customer-group-modal-selected-items'>
                {selectedCustomers.map(c => (
                  <SelectedCustomer
                    key={c.id}
                    customer={c}
                    removeCustomer={() => selectCustomerHandler(c)}
                    groupType={groupType}
                    setMain={() => setMainMember(c)}
                    removeMain={removeMainMember}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className='add-customer-group-modal-main'>
            <div className='add-customer-group-modal-main-content'>
              <div className='add-customer-group-search-container'>
                <SearchGroupCustomersInput
                  searchValue={searchCustomers}
                  setSearchValue={searchCustomersHandler}
                  placeholder={t('customersGroupModal.searchPlaceholder')}
                />
              </div>
              <div className='add-customer-group-modal-list-container' ref={listRef}>
                {customers.length === 0 && !customersLoading ? (
                  <div className='add-customer-group-modal-no-data-container'>
                    <NoData text='Nothing Found' />
                  </div>
                ) : (
                  <div className='add-customer-group-modal-list'>
                    {customers.map(c => (
                      <div key={c.id} className='add-customer-group-modal-list-item'>
                        <div className='add-customer-group-modal-list-item-checkbox'>
                          <img
                            src={`./assets/${
                              selectedCustomers.map(({ id }) => id).includes(c.id)
                                ? 'checkbox-sm-checked/Square.png'
                                : 'check-box-blank/Check Box Blank.png'
                            }`}
                            alt='slct'
                            onClick={() => selectCustomerHandler(c)}
                          />
                        </div>
                        <div className='add-customer-group-modal-list-item-info'>
                          <div className='add-customer-group-modal-list-item-name'>{c.name}</div>
                          <div className='add-customer-group-modal-list-item-number'>{c.idNumber}</div>
                        </div>
                        <div className='add-customer-group-modal-list-item-extras'>
                          {c.familyGroupId && (
                            <>
                              <span>({c.familyGroupName})</span>
                              <Groups />
                            </>
                          )}
                        </div>
                      </div>
                    ))}
                    {customersLoading && <Loader />}
                  </div>
                )}
              </div>
            </div>
            <footer className='add-customer-group-modal-footer'>
              <Button
                caption={t('customersGroupModal.saveBtn')}
                disabled={submitBtnDisabled}
                onClick={group ? editGroupHandler : addGroupHandler}
              />
            </footer>
          </div>
        </div>
      </div>
      {showConfirm && (
        <ConfirmDialog
          confirm={() => {
            setSelectedCustomers([...selectedCustomers, { ...customerToCheck, isMainFamilyGroupMember: false }])
          }}
          decline={() => {
            setShowConfirm(false)
            setCustomerToCheck({ name: '', familyGroupName: '' })
          }}
          title={t('customersGroupModal.warnTitle')}
          text={t('customersGroupModal.warnText', {
            customerName: customerToCheck.name,
            prevGroupName: customerToCheck.familyGroupName,
            newGroupName: groupName,
          })}
          confirmText={t('customersGroupModal.confirmAdd')}
          declineText={t('customersGroupModal.declineAdd')}
        />
      )}
      {showRemoveAllConfirm && (
        <ConfirmDialog
          confirm={() => {
            setGroupType(customerGroupType.family)
            setSelectedCustomers(selectedCustomers.filter(c => !customerHasExistingFamilyGroup(c, group)))
          }}
          decline={() => {
            setShowRemoveAllConfirm(false)
          }}
          title={t('customersGroupModal.warnTitle')}
          text={t('customersGroupModal.removeAllWarnText', {
            customerNames: [
              selectedCustomers
                .filter(c => customerHasExistingFamilyGroup(c, group))
                .slice(0, -1)
                .map(c => c.name)
                .join(', '),
              selectedCustomers.filter(c => customerHasExistingFamilyGroup(c, group)).at(-1).name,
            ].join(`${hebrew() ? ' ו' : ' and '}`),
          })}
          confirmText={t('customersGroupModal.confirmAdd')}
          declineText={t('customersGroupModal.declineAdd')}
        />
      )}
    </ModalWindow>
  )
}

export default AddCustomerGroupModal

const SelectedCustomer = ({ groupType, customer, removeCustomer, setMain, removeMain }) => {
  const { t } = useTranslation('customers')
  const familyGroupType = groupType === customerGroupType.family
  if (!customer && familyGroupType) {
    return (
      <div className='add-customer-group-modal-list-item no-main'>
        <div>
          <ErrorOutlineOutlined style={{ color: 'var(--trend-down-color)' }} />
        </div>
        <div>
          <p>{t('customersGroupModal.notSelected')}</p>
          <p>{t('customersGroupModal.specifyMain')}</p>
        </div>
      </div>
    )
  }

  return (
    <div className='add-customer-group-modal-list-item'>
      {familyGroupType && customer.isMainFamilyGroupMember && (
        <div className='add-customer-group-modal-list-item-icon'>
          <GppGoodOutlined style={{ color: 'var(--orange-client)' }} />
        </div>
      )}
      <div className='add-customer-group-modal-list-item-info'>
        <div className='add-customer-group-modal-list-item-name'>{customer.name ?? customer.customerName}</div>
        <div className='add-customer-group-modal-list-item-number'>
          {customer.idNumber ?? customer.customerIdNumber ?? customer.unHashedIdNumber ?? '*********'}
        </div>
      </div>
      <div className='add-customer-group-modal-list-item-actions'>
        {familyGroupType && !customer.isMainFamilyGroupMember && (
          <button onClick={setMain}>
            <GppGoodOutlined />
          </button>
        )}
        {familyGroupType && customer.isMainFamilyGroupMember && (
          <button onClick={removeMain}>
            <HighlightOff />
          </button>
        )}
        <button onClick={removeCustomer}>
          <DeleteOutline />
        </button>
      </div>
    </div>
  )
}
