import { showSidebar } from '../../utils/sidebar'
import MobileHeaderBase from '../Common/MobileHeaderBase/MobileHeaderBase'
import './NoMobileLayout.scss'

const NoMobileLayout = ({ pageName }) => {
  return (
    <div className='no-layout-container'>
      <MobileHeaderBase goBack={showSidebar} text={pageName} />
      <div className='no-layout-content'>
        <div>
          <img src='./assets/info-icon-md/Info.png' alt='' className='img-icon' />
        </div>
        Mobile version is not available at the moment
        <br />
        Use desktop version instead
      </div>
    </div>
  )
}

export default NoMobileLayout
