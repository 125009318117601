export const analyticsType = {
  revenues: 'revenues',
  products: 'products',
  policies: 'policies',
  companiesCategories: 'companiesCategories',
  customers: 'customers',
  portfolioDevelopment: 'portfolioDevelopment',
}

export const analyticsObject = {
  [analyticsType.revenues]: {
    nameEn: 'Revenues',
    name: 'הכנסות',
  },
  [analyticsType.policies]: {
    nameEn: 'Policies',
    name: 'פוליסות',
  },
  [analyticsType.companiesCategories]: {
    nameEn: 'companiesCategories',
    name: 'מוצרים וחברות',
  },
  [analyticsType.customers]: {
    nameEn: 'Customers',
    name: 'לקוחות',
  },
  [analyticsType.portfolioDevelopment]: {
    nameEn: 'portfolioDevelopment',
    name: 'התפתחות תיק',
  },
}

export const analyticsPeriodViewType = {
  monthly: 1,
  quarterly: 2,
  semiAnnual: 3,
  annual: 4,
  other: 5,
}

export const analyticsPeriodView = {
  [analyticsPeriodViewType.monthly]: {
    type: analyticsPeriodViewType.monthly,
    name: 'חודשי',
    nameEn: 'monthly',
  },
  [analyticsPeriodViewType.quarterly]: {
    type: analyticsPeriodViewType.quarterly,
    name: 'רבעוני',
    nameEn: 'quarterly',
  },
  [analyticsPeriodViewType.semiAnnual]: {
    type: analyticsPeriodViewType.semiAnnual,
    name: 'חצי שנתי',
    nameEn: 'semi-annual',
  },
  [analyticsPeriodViewType.annual]: {
    type: analyticsPeriodViewType.annual,
    name: 'שנתי',
    nameEn: 'annual',
  },
  [analyticsPeriodViewType.other]: {
    type: analyticsPeriodViewType.other,
    name: 'אחר',
    nameEn: 'other',
  },
}

export const getAnalyticsPeriodViewsFiltered = (viewsToFilter = []) =>
  Object.values(analyticsPeriodViewType).filter(v => !viewsToFilter.includes(v))
