import { AccountCircle, EditOutlined } from '@mui/icons-material'
import './ProfileAvatar.scss'

const ProfileAvatar = () => {
  return (
    <div className='profile-avatar'>
      <AccountCircle style={{ fontSize: 60, opacity: 0.17 }} />
      <button>
        <EditOutlined fontSize='small' />
      </button>
    </div>
  )
}

export default ProfileAvatar
