import store from '../../../app/store'
import { customerGroupType } from '../../../types/customerGroupTypes'

export const customerFamilyGroup = () => {
  const familyGroups = store.getState().customer.customerGroups.find(gr => +gr.groupName === customerGroupType.family)
  if (!familyGroups) {
    return null
  }
  return familyGroups.groupItems[0]
}
