import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { themeToggle } from '../../../themes/actions/themeActions'
import { InvertColors, Language } from '@mui/icons-material'
import { useDispatch } from 'react-redux'
import './LandingHeader.scss'
import i18n, { hebrew } from '../../../../i18n'
import { setItemToLocalStorage } from '../../../../services/localStorageService'

const { APP_CONFIG } = window

const language = {
  he: 'עִברִית',
  en: 'EN',
}

const LandingHeader = () => {
  const registerRoute = window.location.href.includes('register')
  const loginRoute = window.location.href.includes('login')

  const { t } = useTranslation('landing')
  const [checked, setChecked] = useState(false)
  const dispatch = useDispatch()
  const links = [
    {
      text: t('navbar.links.privacyPolicy'),
      href: '#',
    },
    {
      text: t('navbar.links.termsOfUse'),
      href: '#',
    },
  ]

  const switchTheme = () => dispatch(themeToggle())
  const switchLang = () => {
    const langToSwitch = hebrew() ? 'en' : 'he'
    i18n.changeLanguage(langToSwitch)
    setItemToLocalStorage('lang', langToSwitch)
    const root = document.getElementById('body-root')
    root.dir = hebrew() ? 'rtl' : 'ltr'
  }

  const scrollToContactsHandler = e => {
    e.preventDefault()
    const contacts = document.getElementById('contacts')
    contacts && contacts.scrollIntoView()
  }

  const closeMenu = () => {
    setChecked(!checked)
  }

  if (loginRoute) {
    return (
      <header className='landing-header'>
        <div className='landing-header-wrapper'>
          <div></div>
          <div className='landing-header-logo'>
            <NavLink to='/' className='landing-header-logo-link'>
              <img src='./assets/logo/Logo_full.svg' alt='AGent_logo' />
            </NavLink>
          </div>
          <div className='landing-header-buttons-container'>
            {(process.env.REACT_APP_API_ENV === 'development' || APP_CONFIG?.STAGE_ENV) && (
              <>
                {/* <button onClick={switchTheme} className='btn-theme'>
                <InvertColors />
              </button> */}
              </>
            )}
            <button onClick={switchLang} className='btn-link '>
              <Language />
              <span>{language[`${hebrew() ? 'en' : 'he'}`]}</span>
            </button>
          </div>
        </div>
        <div className='landing-header-mobile-wrapper' style={{ justifyContent: 'center' }}>
          <div></div>
          <div className='mobile-logo' style={{ margin: '0' }}>
            <NavLink to='/' className='landing-header-logo-link'>
              <img src='/assets/Logo/Logo_full.svg' alt='logo' />
            </NavLink>
          </div>
          <div></div>
        </div>
      </header>
    )
  }

  return (
    <header className='landing-header'>
      <div className='landing-header-wrapper'>
        <div className='landing-header-logo'>
          <img src='./assets/logo/Logo_full.svg' alt='AGent_logo' />
        </div>
        <nav className='landing-navbar'>
          <ul>
            <li>
              <a href='#'>{t('navbar.home')}</a>
            </li>
            {/* <li>
              <a href='#downloads'>{t('navbar.howItWorks')}</a>
            </li>
            <li>
              <a href='#portfolio'>{t('navbar.products')}</a>
            </li> */}
            {/* <li>
              <a href='#/prices'>{t('navbar.prices')}</a>
            </li> */}
            <li>
              <a href='#/marketing'>{t('navbar.marketing')}</a>
            </li>
            <li>
              <a href='' onClick={scrollToContactsHandler}>
                {t('navbar.contactUs')}
              </a>
            </li>
            <li>
              <a href='#/pricescalc'>{t('navbar.pricesCalc')}</a>
            </li>
          </ul>
        </nav>
        <div className='landing-header-buttons-container'>
          <NavLink className='btn-link disabled' to='login'>
            {t('navbar.login')}
          </NavLink>
          {!registerRoute && (
            <NavLink className='btn-link ' to='register'>
              {t('navbar.join')}
            </NavLink>
          )}
          {(process.env.REACT_APP_API_ENV === 'development' || APP_CONFIG?.STAGE_ENV) && (
            <>
              {/* <button onClick={switchTheme} className='btn-theme'>
                <InvertColors />
              </button> */}
            </>
          )}
          <button onClick={switchLang} className='btn-link '>
            <Language />
            <span>{language[`${hebrew() ? 'en' : 'he'}`]}</span>
          </button>
        </div>
      </div>
      <div className='landing-header-mobile-wrapper'>
        <div id='mobileMenuToggle'>
          <input type='checkbox' checked={checked} onClick={() => closeMenu()} onChange={() => {}} />
          <span className='mobile-icon-menu'>
            <img src='/assets/_landing/dashboard/mobile-icon-m.svg' alt='mobile-icon-m' />
          </span>
          <div id='menu'>
            <div
              className='mobile-menu-container'
              style={{
                backgroundImage: `url('assets/_landing/dashboard/mobile-menu-background.png')`,
                backgroundRepeat: 'no-repeat',
              }}
            >
              <div className='landing-header-mobile-title'>
                <p>ברוכים הבאים</p>
                <p> ל־Ai.Gent</p>
              </div>
              <ul>
                <li onClick={() => closeMenu()}>
                  <a href='#'>{t('navbar.home')}</a>
                  <img src='/assets/_landing/dashboard/mobile-house-icon.svg' alt='q' />
                </li>
                {/* <li onClick={() => closeMenu()}>
                  <a href='#/prices'>{t('navbar.prices')}</a>
                  <img src='/assets/_landing/dashboard/mobile-card-icon.svg' alt='q' />
                </li> */}
                <li onClick={() => closeMenu()}>
                  <a href='#/marketing'>{t('navbar.marketing')}</a>
                  <img src='/assets/_landing/dashboard/mobile-card-icon.svg' alt='q' />
                </li>
                <li onClick={() => closeMenu()}>
                  <a href='' onClick={scrollToContactsHandler}>
                    {t('navbar.contactUs')}
                  </a>
                  <img src='/assets/_landing/dashboard/mobile-question-icon.svg' alt='q' />
                </li>
              </ul>
              <div className='mobile-menu-buttons'>
                <NavLink className='btn-link ' to='login'>
                  {t('navbar.login')}
                </NavLink>
                {!registerRoute && (
                  <NavLink className='btn-link disabled' to='register'>
                    {t('navbar.join')}
                  </NavLink>
                )}
              </div>
              <div className='mobile-menu-text'>
                {links.map(l => (
                  <div key={l.text + l.href}>
                    <a href={l.href}>{l.text}</a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className='mobile-logo'>
          <img src='/assets/Logo/Logo_full.svg' alt='logo' />
        </div>
        {!registerRoute && (
          <NavLink className='btn-link-mobile' to='register'>
            {t('navbar.join')}
          </NavLink>
        )}
        {registerRoute && (
          <NavLink className='btn-link-mobile' to='login'>
            {t('navbar.login')}
          </NavLink>
        )}
      </div>
    </header>
  )
}

export default LandingHeader
