import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import Loader from '../../../../components/Common/Loader/Loader'
import CheckMark from '../../../../components/Common/Marks/CheckMark/CheckMark'
import NoData from '../../../../components/Common/NoData/NoData'
import { WORKSTATION_TASK_AGENT_RESET } from '../../../../types/actionTypes'
import { SHARED_AGENTS_RESET } from '../../../../types/actionTypesShared'
import { getAgents } from '../../../shared/actions/sharedActions'
import { setTaskAgent } from '../../actions/workstationActions'
import './WorkstationTaskAgentMenu.scss'

const WorkstationTaskAgentMenu = ({ tasks, closeMenu = () => {}, onCloseMenu = () => {} }) => {
  const { agents, agentsLoading } = useSelector(state => state.shared)
  const { taskAgentLoading, taskAgentSuccess } = useSelector(state => state.workstation)
  const [currAgent, setCurrAgent] = useState(tasks[0]?.responsibleEmployeeId)
  const dispatch = useDispatch()

  const refContextMenu = useRef(null)

  const handleCloseMenu = event => {
    if (event.key === 'Escape' || (refContextMenu.current && !refContextMenu.current.contains(event.target))) {
      event.stopPropagation()
      closeMenu()
    }
  }

  const clickHandler = (event, agentId) => {
    event.stopPropagation()
    setCurrAgent(agentId)
    const agentName = agents.find(a => a.id === agentId).name
    dispatch(setTaskAgent({ ids: tasks.map(t => t.id), agentId, agentName }))
  }

  useEffect(() => {
    dispatch(getAgents())
    return () => {
      dispatch({ type: SHARED_AGENTS_RESET })
      dispatch({ type: WORKSTATION_TASK_AGENT_RESET })
    }
  }, [dispatch])

  useEffect(() => {
    if (taskAgentSuccess) {
      closeMenu()
      onCloseMenu()
    }
  }, [taskAgentSuccess, closeMenu])

  useEffect(() => {
    document.addEventListener('keydown', handleCloseMenu, true)
    document.addEventListener('click', handleCloseMenu, true)
    return () => {
      document.removeEventListener('keydown', handleCloseMenu, true)
      document.removeEventListener('click', handleCloseMenu, true)
    }
  })

  if (agentsLoading) {
    return (
      <div className='workstation-task-agent-menu-container' ref={refContextMenu}>
        <div className='workstation-task-agent-menu' onClick={e => e.stopPropagation()}>
          <Loader />
        </div>
      </div>
    )
  }

  if (agents.length === 0) {
    return (
      <div className='workstation-task-agent-menu-container' ref={refContextMenu}>
        <div className='workstation-task-agent-menu' onClick={e => e.stopPropagation()}>
          <NoData text='No agents found' />
        </div>
      </div>
    )
  }

  return (
    <div className='workstation-task-agent-menu-container' ref={refContextMenu}>
      <div className='workstation-task-agent-menu' onClick={e => e.stopPropagation()}>
        {taskAgentLoading && (
          <div className='workstation-task-agent-menu-loader-overlay'>
            <Loader />
          </div>
        )}
        {agents.map((a, i) => (
          <div
            key={a.id + i}
            className={classNames('workstation-task-agent-item-container', { current: a.id === currAgent })}
            {...(a.id !== currAgent && { onClick: e => clickHandler(e, a.id) })}
          >
            <div className='workstation-task-agent-item-text'>{a.name}</div>
            {a.id === currAgent && <CheckMark green />}
          </div>
        ))}
      </div>
    </div>
  )
}

export default WorkstationTaskAgentMenu
