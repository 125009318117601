import ModalWindow from '../../../../components/ModalWindow/ModalWindow'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import './StopScanWarn.scss'

const StopScanWarn = ({ stopScan, closeWarn }) => {
  const { t } = useTranslation('scan')
  return (
    <ModalWindow renderPortal showCloseIcon={false}>
      <div className='stop-scan-warn-modal'>
        <main>
          <h5>{t('stopScanWarn.heading')}</h5>
          <p>{t('stopScanWarn.remark1')}</p>
          <p>{t('stopScanWarn.remark2')}</p>
        </main>
        <footer>
          <Button onClick={closeWarn} variant='text' style={{ color: 'var(--text-primary-color)' }}>
            {t('stopScanWarn.cancelBtn')}
          </Button>
          <Button onClick={stopScan} variant='text' style={{ color: 'var(--trend-down-color)' }}>
            {t('stopScanWarn.stopBtn')}
          </Button>
        </footer>
      </div>
    </ModalWindow>
  )
}

export default StopScanWarn
