import { useTranslation } from 'react-i18next'
import PoliciesFilters from '../PoliciesFilters/PoliciesFilters'
import ButtonTab from '../../../../components/Common/ButtonTab/ButtonTab'
import { formatAsNumber } from '../../../../utils/formatAs'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getCheckedIds } from '../../../../utils/objectUtils'
import { getPoliciesSignalsRequest } from '../../../../http/requests/policiesRequests'
import PoliciesListPageSize from '../PoliciesListPageSize/PoliciesListPageSize'
import { PoliciesSignalsList } from './PoliciesSignalsList'
import { irregularSignal } from '../../../../types/irregularSignals'
import { UTCDateToShortLocale } from '../../../../utils/date'
import PoliciesSignalsAppBar from '../PoliciesSignalsAppBar/PoliciesSignalsAppBar'
import './PoliciesSignalsListContainer.scss'
import { sortOrder } from '../../../../types/sortByTypes'

const signals = Object.values(irregularSignal)

const PoliciesSignalsListContainer = () => {
  const { t } = useTranslation('policies')
  const [signalsLists, setSignalsLists] = useState(
    Object.fromEntries(signals.map(s => [s.id, { items: [], totalCount: 0, ...s }]))
  )
  const [listLoading, setListLoading] = useState(true)
  const [collapsedLists, setCollapsedLists] = useState({})
  const [pageSize, setPageSize] = useState(50)
  const [selectedSignal, setSelectedSignal] = useState(null)
  const [selectedIrregs, setSelectedIrregs] = useState([])
  const [page, setPage] = useState(1)

  const {
    searchFilters: { customerName, customerIdNumber, policyNumber },
    selectFilters: { companies, categories, irregMonths, policyTypes },
    rangeFilters,
    sort,
  } = useSelector(({ policies }) => policies)
  const { selectedGroupsIds } = useSelector(({ shared }) => shared)

  const handleCollpaseList = signalType => setCollapsedLists(prev => ({ ...prev, [signalType]: !prev[signalType] }))

  const selectIrregHanlder = id => {
    setSelectedIrregs(prev => (prev.includes(id) ? prev.filter(i => i !== id) : [...prev, id]))
  }

  const setPageSizeHandler = pageSize => {
    setPage(1)
    setPageSize(pageSize)
  }

  const fetchList = useCallback(
    async ({ silentLoading = false } = {}) => {
      try {
        setListLoading(!silentLoading)
        const signalRequestParams = {
          customerName,
          customerIdNumber,
          policyNumber,
          policyTypes: getCheckedIds(policyTypes, 'selected'),
          companies: getCheckedIds(companies, 'selected'),
          categories: getCheckedIds(categories, 'selected'),
          months: getCheckedIds(irregMonths, 'selected').map(d => UTCDateToShortLocale(d)),
          pageSize: !selectedSignal ? 10 : pageSize,
          ...rangeFilters,
          pageNumber: page,
          sortBy: sort.bySignals,
          descending: sort.orderSignals === sortOrder.desc,
        }
        const requests = []
        requests.push(
          ...signals
            .filter(sl => (!selectedSignal ? true : selectedSignal === sl.id))
            .map(sl => getPoliciesSignalsRequest({ ...signalRequestParams, signalType: sl.id }))
        )
        const results = await Promise.all(requests)
        signals
          .filter(sl => (!selectedSignal ? true : selectedSignal === sl.id))
          .forEach((sl, idx) => {
            setSignalsLists(prev => ({ ...prev, [sl.id]: { ...prev[sl.id], ...results[idx].data.data } }))
            setCollapsedLists(prev => ({ ...prev, [sl.id]: results[idx].data.data.items.length === 0 || prev[sl.id] }))
          })
        setListLoading(false)
      } catch (error) {
        console.log('Error catch')
        console.log(error)
        setListLoading(false)
      }
    },
    [
      customerName,
      customerIdNumber,
      policyNumber,
      policyTypes,
      companies,
      categories,
      irregMonths,
      rangeFilters,
      selectedSignal,
      pageSize,
      page,
      sort,
    ]
  )

  useEffect(() => {
    fetchList()
  }, [fetchList, selectedGroupsIds])

  useEffect(() => {
    setPage(1)
  }, [
    customerName,
    customerIdNumber,
    policyNumber,
    companies,
    categories,
    irregMonths,
    rangeFilters,
    selectedSignal,
    selectedGroupsIds,
    sort,
  ])

  useEffect(() => {
    setSelectedIrregs([])
  }, [selectedSignal])

  return (
    <div className='policies-signals-list-container'>
      <header className='policies-list-header'>
        <section className='policies-list-tabs'>
          <ButtonTab
            tabName={t(`screenTabs.policiesSignals.all`)}
            badge={t('placeholders.signalsCount', {
              count: formatAsNumber(Object.values(signalsLists).reduce((acc, curr) => acc + curr.totalCount, 0)),
            })}
            selected={!selectedSignal}
            onClick={() => setSelectedSignal(null)}
          />
          {Object.values(signalsLists).map(sl => (
            <ButtonTab
              key={sl.key}
              tabName={t(`screenTabs.policiesSignals.${sl.key}`)}
              badge={t('placeholders.signalsCount', { count: formatAsNumber(signalsLists[sl.id].totalCount) })}
              selected={selectedSignal === sl.id}
              onClick={() => setSelectedSignal(sl.id)}
            />
          ))}
        </section>
      </header>
      <div className='policies-filters-wrapper'>
        <PoliciesFilters
          filterHeader={t(
            `screenTabs.policiesSignals.${!selectedSignal ? 'allSignals' : signalsLists[selectedSignal].key}`
          )}
          filterHeaderTooltip={t('screenTabs.policiesSignals.tooltip')}
          signalType={selectedSignal}
        />
      </div>
      <div className='policies-signals-lists-wrapper'>
        {Object.values(signalsLists)
          .filter(sl => (!selectedSignal ? true : selectedSignal === sl.id))
          .map(sl => (
            <PoliciesSignalsList
              {...(!selectedSignal && {
                title: t(`screenTabs.policiesSignals.${sl.key}`),
              })}
              onCollapse={() => handleCollpaseList(sl.id)}
              collapsed={collapsedLists[sl.id]}
              showDetails={() => setSelectedSignal(sl.id)}
              list={signalsLists[sl.id]}
              loading={listLoading}
              key={sl.id}
              selectIrreg={selectIrregHanlder}
              selectedIrregs={selectedIrregs}
            />
          ))}
        {selectedIrregs.length > 0 && (
          <div className='policies-lists-appbar-wrapper'>
            <PoliciesSignalsAppBar
              closeBar={() => setSelectedIrregs([])}
              signals={Object.values(signalsLists)
                .filter(sl => (!selectedSignal ? true : selectedSignal === sl.id))
                .flatMap(s => s.items)
                .filter(p => selectedIrregs.includes(p.irregularityId))}
              onSignalAction={() => fetchList({ silentLoading: true })}
            />
          </div>
        )}
      </div>
      {selectedSignal && (
        <PoliciesListPageSize
          pageSize={pageSize}
          setPageSize={setPageSizeHandler}
          itemsCount={signalsLists[selectedSignal].totalCount}
          page={page}
          setPage={setPage}
        />
      )}
    </div>
  )
}

export default PoliciesSignalsListContainer
