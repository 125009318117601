import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import Loader from '../../../../components/Common/Loader/Loader'
import { getProfileNextPaymentDetailsRequest } from '../../../../http/requests/profileRequests'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import './ProfileNextPaymentDetails.scss'

const detailsType = {
  basePrice: 'basePrice',
  changings: 'changings',
  oneTimePayments: 'oneTimePayments',
  discounts: 'discounts',
  total: 'total',
}

const ProfileNextPaymentDetails = () => {
  const { t } = useTranslation('profile')
  const [details, setDetails] = useState(null)
  const [detailsLoading, setDetailsLoading] = useState(true)
  const [showDetails, setShowDetails] = useState([])

  const showDetailsHandler = type =>
    setShowDetails(prev => (prev.includes(type) ? prev.filter(p => p !== type) : [...prev, type]))

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setDetailsLoading(true)
        const {
          data: { data },
        } = await getProfileNextPaymentDetailsRequest()
        setDetails(data)
        setDetailsLoading(false)
      } catch (error) {
        console.log(error)
        setDetailsLoading(false)
      }
    }

    fetchDetails()
  }, [])

  return (
    <div className='profile-next-payment-details-container'>
      <p>{t('payments.detailsHeading')}</p>
      {detailsLoading && <Loader />}
      {!detailsLoading &&
        details &&
        Object.values(detailsType).map(dt => (
          <DetailsItem
            typeName={t(`payments.details${dt}`)}
            key={dt}
            details={details}
            showDetails={showDetails}
            type={dt}
            showDetailsHandler={() => showDetailsHandler(dt)}
          />
        ))}
    </div>
  )
}

export default ProfileNextPaymentDetails

const DetailsItem = ({ details, type, typeName, showDetails, showDetailsHandler }) => {
  return (
    <div className='profile-next-payment-details-item'>
      <div>
        <p>{typeName}</p>
        <p>₪{details[type].value.toFixed(2)}</p>
        {type !== detailsType.total && (
          <div>
            {details[type].items.length > 0 && (
              <button onClick={showDetailsHandler}>
                {showDetails.includes(type) ? <ArrowDropUp /> : <ArrowDropDown />}
              </button>
            )}
          </div>
        )}
      </div>
      {showDetails.includes(type) &&
        details[type].items.map(itm => (
          <div key={itm.id}>
            <p>{itm.name}</p>
            <p>₪{itm.value.toFixed(2)}</p>
          </div>
        ))}
    </div>
  )
}
