import { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams, useLocation } from 'react-router'
import Loader from '../../../../components/Common/Loader/Loader'
import {
  getCompanyReportRecordsMonthStatusRequest,
  getScanStatusRequest,
  sendScanAnswerRequest,
  sendScanSmsRequest,
  startScanRequest,
  stopScanRequest,
  toggleReportDisableRequest,
} from '../../../../http/requests/scanRequests'
import { latestMonthSelected, scanSelectedMonth } from '../../reducers/scanSelectors'
import { useDispatch, useSelector } from 'react-redux'
import { groupBy, lowercaseObjectKeys } from '../../../../utils/objectUtils'
import { hebrew } from '../../../../i18n'
import { useTranslation } from 'react-i18next'
import ButtonRounded from '../../../../components/Common/ButtonRounded/ButtonRounded'
import { COMPANY_MONTH_REPORTS_COUNT_UPDATE, REPORTS_DATE_SELECT_LATEST } from '../../../../types/actionTypesScan'
import LinedTitle from '../../../../components/Common/LinedTitle/LinedTitle'
import NoData from '../../../../components/Common/NoData/NoData'
import { reportRecordStatusType } from '../../../../types/reportRecordStatus'
import { insuranceField } from '../../../../types/insuranceField'
import ScanCompanyReport from '../ScanCompanyReport/ScanCompanyReport'
import ScanCompanyNoLoginInfo from '../ScanCompanyNoLoginInfo/ScanCompanyNoLoginInfo'
import { companyConnectionType } from '../../../../types/companies'
import { scanStatusType } from '../../../../types/scanStatus'
import { clog, echo } from '../../../../utils/consoleUtils'
import ScanCompanyPopupLoginError from '../ScanCompanyPopupLoginError/ScanCompanyPopupLoginError'
import { getCompanyAuthFields } from '../../../../utils/getAuthFields'
import { authFieldType } from '../../../../types/authFieldsTypes'
import { validationError } from '../../../../types/validationErrors'
import { companyAuthType, getAuthType } from '../../../../types/companyAuthType'
import { updateCompanyCredentialsRequest } from '../../../../http/requests/agencyCompanyRequests'
import ScanCompanyPopupSms from '../ScanCompanyPopupSms/ScanCompanyPopupSms'
import RadialChartScanProgress from '../../../../components/Charts/RadialChartScanProgress'
import { Warning } from '@mui/icons-material'
import ScanUploadReportModal from '../ScanUploadReportModal/ScanUploadReportModal'
import queryString from 'query-string'
import StopScanWarn from '../StopScanWarn/StopScanWarn'
import ScanCompanyHeader from '../ScanCompanyHeader/ScanCompanyHeader'
import './ScanCompany.scss'
import { cn } from '../../../../utils/stylesUtils'

const ScanCompany = () => {
  const { t } = useTranslation('scan')
  const { agencyDbName } = useParams()
  const {
    monthStatus: { companies },
  } = useSelector(({ scan }) => scan)
  const dispatch = useDispatch()
  const location = useLocation()
  const { editLogin = false } = queryString.parse(location.search)

  const [checkScanStatus, setCheckScanStatus] = useState(false)
  const [scanInProgress, setScanInProgress] = useState(false)
  const [scanStatus, setScanStatus] = useState(scanStatusType.None)
  const [scanProgress, setScanProgress] = useState(0)
  const [scanUsers, setScanUsers] = useState([])
  const [singleUserScan, setSingleUserScan] = useState(false)
  const [currentUser, setCurrentUser] = useState(null)
  const [awsInstanceStarted, setAwsInstanceStarted] = useState(false)

  const [company, setCompany] = useState(null)
  const [companyLoading, setCompanyLoading] = useState(true)
  const [reports, setReports] = useState([])
  const [toggleReportLoading, setToggleReportLoading] = useState(false)
  const [hiddenUsers, setHiddenUsers] = useState({})

  const [showErrorReport, setShowErrorReport] = useState([])
  const [showUploadModal, setShowUploadModal] = useState(false)
  const [showStopScanWarn, setShowStopScanWarn] = useState(false)
  const [showAddUser, setShowAddUser] = useState(false)

  const directCompany = company?.connectionType === companyConnectionType.direct && company?.companyId !== 19

  const reportNew = status => status === reportRecordStatusType.none
  const reportSaved = status => status === reportRecordStatusType.saved
  const reportError = status => status !== reportRecordStatusType.saved && status !== reportRecordStatusType.none

  const companyReportRecords = reports?.filter(rr => !rr.isDisabled)

  const userDownloadStatus = userId => {
    const userAllReportsSaved = company?.users
      ?.find(u => u.id === userId)
      ?.reportRecords?.every(rr => reportSaved(rr.status) || rr.isDisabled)
    if (userAllReportsSaved) {
      return { text: t('userDownloadFinished'), style: 'finished' }
    }
    if (userId === userScanStarted) {
      return { text: t('userDownloading'), style: 'downloading' }
    }
    if (singleUserScan) {
      return { text: 'Skipped', style: 'skipped' }
    }
    const scanUserIndex = company.users.findIndex(u => u.id === userScanStarted)
    const userIndex = company.users.findIndex(u => u.id === userId)
    if (scanUserIndex > userIndex) {
      return { text: t('userDownloadFinished'), style: 'finished' }
    }
    if (scanUserIndex < userIndex) {
      return { text: t('userDownloadWating'), style: 'waiting' }
    }
  }

  const fieldsReportsRecords = reports => {
    const groupedReports = groupBy(
      reports.sort(({ fieldId: aId }, { fieldId: bId }) => insuranceField[bId].order - insuranceField[aId].order),
      'fieldId'
    )
    return Object.entries(groupedReports)
      .map(([k, v]) => ({
        id: k,
        name: v[0].fieldName,
        nameEn: v[0].fieldNameEn,
        //sort by report status, none - first, saved - last
        reports: v.sort(({ status: a }, { status: b }) => {
          const getStatusPriority = s => (reportNew(s) ? -1 : reportSaved(s) ? 1 : 0)
          return getStatusPriority(a) - getStatusPriority(b)
        }),
      }))
      .sort(({ id: aId }, { id: bId }) => insuranceField[aId].order - insuranceField[bId].order)
  }

  const { id } = useParams()
  const month = scanSelectedMonth()

  const updateCompanyMonthRecords = useCallback(
    reportRecords => {
      const companyId = reportRecords[0].insuranceCompanyId
      const availableReportsCount = reportRecords.filter(
        rr => !rr.isDisabled && rr.status !== reportRecordStatusType.saved
      ).length
      dispatch({ type: COMPANY_MONTH_REPORTS_COUNT_UPDATE, payload: { id: companyId, availableReportsCount } })
    },
    [dispatch]
  )

  const userScanStarted = scanUsers.findLast(u => u.downloaded)?.id
  const userToScan = scanUsers.find(u => !u.downloaded)?.id
  const nextUserToScan = () => {
    const index = scanUsers.findIndex(u => !u.downloaded)
    if (index > -1) {
      return scanUsers[index]?.id
    }
    return null
  }

  const fetchCompany = useCallback(
    async ({ checkStatusFlag = false, showLoading = true } = {}) => {
      try {
        setScanInProgress(false)
        setScanStatus(scanStatusType.None)
        setScanProgress(0)
        setScanUsers([])
        setCompanyLoading(showLoading)
        setSingleUserScan(false)
        setCurrentUser(null)
        showLoading && setCompany(null)
        let { data: resp } = await getCompanyReportRecordsMonthStatusRequest({ id, month, agency: agencyDbName })
        resp = lowercaseObjectKeys(resp.Data ?? resp.data)
        const reportRecords = resp.users.flatMap(u => u.reportRecords)
        const usersToHide = {}
        resp.users.forEach(u => {
          if (u.reportRecords.length === 0) {
            usersToHide[u.id] = true
          }
        })
        setScanUsers(
          resp.users
            .filter(u => u.reportRecords.some(rr => rr.status !== reportRecordStatusType.saved && !rr.isDisabled))
            .map((u, i) => ({ ...u, downloaded: false }))
        )
        setHiddenUsers(usersToHide)
        setCompany(resp)
        setReports(reportRecords)
        reportRecords.length > 0 && updateCompanyMonthRecords(reportRecords)
        setShowErrorReport([])
        setCheckScanStatus(checkStatusFlag)
        setCompanyLoading(false)
      } catch (error) {
        echo.log(echo.asAlert('--- FETCH COMPANY ERROR ---'))
        echo.log(echo.asAlert(error))
        setCompanyLoading(false)
      }
    },
    [id, month]
  )

  const startScan = useCallback(
    async (userId, specificRecordIds) => {
      const { companyId } = company
      setAwsInstanceStarted(false)
      const scanUser = userId ?? userToScan
      const reportsToScan =
        specificRecordIds ??
        company.users
          .flatMap(u => u.reportRecords)
          .filter(rr => rr.companyUserId === scanUser && !rr.isDisabled && !rr.isExpired)
          .map(({ id }) => id)
      try {
        setReports(prev => prev.map(rr => ({ ...rr, current: false })))
        setShowErrorReport([])
        setScanInProgress(true)
        setScanUsers(prev => prev.map(pu => ({ ...pu, downloaded: pu.id === scanUser ? true : pu.downloaded })))
        setCurrentUser(scanUser)
        const {
          data: {
            data: { status: scanStatusInfo },
          },
        } = await startScanRequest({
          companyId,
          companyUsers: [
            {
              id: scanUser,
              recordIds: reportsToScan,
            },
          ],
        })
        setCheckScanStatus(true)
        setAwsInstanceStarted(true)
        const { status, statusText } = scanStatusInfo
        echo.log(echo.asInfo('start status'), echo.asWarning(status), echo.asWarning(statusText))
        setScanStatus(status)
      } catch (error) {
        echo.log(echo.asAlert('--- START SCAN ERROR ---'))
        echo.log(echo.asAlert(error))
        setScanInProgress(false)
      }
    },
    [company, userToScan]
  )

  const checkStatus = useCallback(
    async userId => {
      try {
        const {
          data: {
            data: { status: scanStatusInfo },
          },
        } = await getScanStatusRequest(company.companyId, userId)
        const { status, statusText, percent, records } = scanStatusInfo
        if (
          // status === scanStatusType.LoginError ||
          // status === scanStatusType.ResetPasswordRequierd ||
          // status === scanStatusType.InvalidVaultCredentials ||
          // status === scanStatusType.ResetVaultPasswordRequired ||
          // status === scanStatusType.WaitingSmsTimeOut ||
          // status === scanStatusType.IncorrectSMSCode ||
          // status === scanStatusType.Canceled ||
          status === scanStatusType.Closed ||
          status === scanStatusType.None
        ) {
          setCheckScanStatus(false)
          let nextToScan = null
          const index = scanUsers.findIndex(u => !u.downloaded)
          if (index > -1) {
            nextToScan = scanUsers[index]?.id
          }
          if (!singleUserScan && nextToScan) {
            startScan()
          } else {
            setScanInProgress(false)
            records &&
              updateCompanyMonthRecords(
                reports.map(rr => ({
                  ...rr,
                  status: records?.find(r => r.id === rr.id)?.status ?? rr.status,
                }))
              )
            fetchCompany({ checkStatusFlag: false, showLoading: false })
          }
        } else {
          if (status === scanStatusType.WaitingSms || status === scanStatusType.WaitingAnswer) {
            setCheckScanStatus(false)
          }
          setScanInProgress(true)
          if (percent !== scanProgress) {
            echo.log(
              echo.asInfo('progress changed'),
              echo.asWarning(`prev - ${scanProgress}`),
              echo.asWarning(`curr - ${percent}`)
            )
            setScanProgress(percent)
          }
        }
        if (status !== scanStatus) {
          echo.log(
            echo.asInfo('status changed'),
            echo.asWarning(`prev - ${scanStatus}`),
            echo.asWarning(`curr - ${status} - ${statusText}`)
          )
          setScanStatus(status)
        }
        if (records) {
          setReports(prev =>
            prev.map(rr => ({
              ...rr,
              status: records?.find(r => r.id === rr.id)?.status ?? rr.status,
              current: records?.find(r => r.id === rr.id)?.current,
            }))
          )
        }
      } catch (error) {
        echo.log(echo.asAlert('--- CHECK SCAN STATUS ERROR ---'))
        echo.log(echo.asAlert(error))
        echo.log(echo.asInfo('SCAN STOPPED'))
        setCheckScanStatus(false)
      }
    },
    [
      company,
      singleUserScan,
      fetchCompany,
      reports,
      scanProgress,
      scanStatus,
      updateCompanyMonthRecords,
      scanUsers,
      startScan,
    ]
  )

  const stopScan = async () => {
    const { companyId } = company
    try {
      setShowStopScanWarn(false)
      setScanInProgress(false)
      setCheckScanStatus(false)
      await stopScanRequest({
        companyId,
        userId: currentUser,
      })
    } catch (error) {
      echo.log(echo.asAlert('--- STOP SCAN ERROR ---'))
      echo.log(echo.asAlert(error))
    } finally {
      if (singleUserScan || !nextUserToScan()) {
        setSingleUserScan(false)
        fetchCompany({ checkStatusFlag: false, showLoading: false })
        updateCompanyMonthRecords(reports)
      } else if (!singleUserScan) {
        startScan()
      }
    }
  }

  const toggleReportDisable = async ids => {
    try {
      setToggleReportLoading(true)
      const {
        data: {
          data: { disabled, reportRecordIds },
        },
      } = await toggleReportDisableRequest(ids)
      fetchCompany({ showLoading: false })
      setToggleReportLoading(false)
    } catch (error) {
      echo.log(echo.asAlert('--- TOGGLE REPORT ERROR ---'))
      echo.log(echo.asAlert(error))
      setToggleReportLoading(false)
    }
  }

  useEffect(() => {
    id && month && fetchCompany()
  }, [id, month, fetchCompany])

  useEffect(() => {
    const check = () => company && checkScanStatus && currentUser && checkStatus(currentUser)
    const interval = setInterval(() => {
      check()
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [company, checkScanStatus, checkStatus, currentUser])

  if (companyLoading) {
    return (
      <div className='scan-company-container'>
        <ScanCompanyHeader />
        <Loader />
      </div>
    )
  }

  if (!company) {
    return (
      <div className='scan-company-container'>
        <NoData text='Nothing Found' />
      </div>
    )
  }

  if ((directCompany && (company.users.length === 0 || !company.hasLoginInfo)) || editLogin || showAddUser) {
    return (
      <div className='scan-company-container'>
        <ScanCompanyHeader showAddUser={() => setShowAddUser(true)} />
        <ScanCompanyNoLoginInfo
          company={company}
          addUser={showAddUser}
          onAddUser={() => setShowAddUser(false)}
          onUsersUpdated={fetchCompany}
        />
      </div>
    )
  }

  if (reports.length === 0) {
    return (
      <div className='scan-company-container'>
        <ScanCompanyHeader />
        <NoData text='No Reports Found' />
      </div>
    )
  }

  const nextCompanyIdToScan = () => {
    const currCompanyIndex = companies.findIndex(c => c.id === company?.companyId)
    const nextCompany =
      companies
        .filter((_, i) => i > currCompanyIndex)
        .find(c => c.id !== company?.companyId && c.availableReportsCount > 0)?.id ??
      companies.find(c => c.id !== company?.companyId && c.availableReportsCount > 0)?.id
    return nextCompany
  }

  const adminBanner = (company?.body || company?.subject) && (
    <div className='scan-company-banner admin-msg'>
      <Warning />
      <div>
        <p>{company?.subject}</p>
        <p>{company?.body}</p>
      </div>
    </div>
  )

  let banner = null
  if (scanInProgress && !awsInstanceStarted) {
    banner = <ScanCompanyCreateInstanceBanner />
  } else if (latestMonthSelected()) {
    if (scanInProgress) {
      banner = <ScanCompanyInProgressBanner progress={scanProgress} stopScan={() => setShowStopScanWarn(true)} />
    } else {
      if (directCompany && companyReportRecords.every(r => reportNew(r.status))) {
        banner = <ScanCompanyAllNewReportsBanner startScan={startScan} startScanDisabled={scanInProgress} />
      }
      if (companyReportRecords.every(r => reportSaved(r.status))) {
        banner = <ScanCompanyAllDownloadedBanner nextCompanyIdToScan={nextCompanyIdToScan()} />
      }
      if (companyReportRecords.some(r => reportError(r.status))) {
        banner = (
          <ScanCompanySomeErrorsBanner
            scanInProgress={scanInProgress}
            nextCompanyIdToScan={nextCompanyIdToScan()}
            directCompany={directCompany}
            startScan={startScan}
          />
        )
      }
      if (
        companyReportRecords.some(r => reportSaved(r.status)) &&
        companyReportRecords.some(r => reportNew(r.status))
      ) {
        banner = (
          <ScanCompanyScanStoppedBanner
            startScan={startScan}
            scanInProgress={scanInProgress}
            directCompany={directCompany}
          />
        )
      }
    }
  } else {
    banner = (
      <ScanCompanyPrevMonthBanner
        startScan={startScan}
        scanInProgress={scanInProgress}
        scanAvailable={directCompany && companyReportRecords.some(rr => !reportSaved(rr.status))}
        setShowStopScanWarn={setShowStopScanWarn}
      />
    )
  }

  return (
    <>
      <div className='scan-company-container'>
        <ScanCompanyHeader
          scanInProgress={scanInProgress}
          openUploadModal={() => setShowUploadModal(true)}
          uploadEnabled={
            !scanInProgress && reports.filter(r => r.status !== reportRecordStatusType.saved && !r.isExpired).length > 0
          }
        />
        {adminBanner}
        {banner}
        <ul className='scan-company-users'>
          {company.users.map(u => (
            <li key={u.id}>
              <LinedTitle
                collapsible
                title={u.name}
                count={u.reportRecords.length}
                collapsed={hiddenUsers[u.id]}
                onCollapse={() => {
                  setHiddenUsers(prev => ({ ...prev, [u.id]: !prev[u.id] }))
                }}
                {...(scanInProgress && {
                  badge: (
                    <div className={cn('user-download-badge', userDownloadStatus(u.id).style)}>
                      {userDownloadStatus(u.id).text}
                    </div>
                  ),
                })}
              />
              {!hiddenUsers[u.id] && (
                <ul className='scan-company-fields'>
                  {u.reportRecords.length === 0 && <NoData text={'No Reports Found'} />}
                  {fieldsReportsRecords(reports.filter(r => r.companyUserId === u.id)).map(f => (
                    <li key={f.id} className='scan-company-field'>
                      <LinedTitle size='small' count={f.reports.length} title={hebrew() ? f.name : f.nameEn} />
                      <ul className='scan-company-reports'>
                        {f.reports.map(r => (
                          <ScanCompanyReport
                            report={r}
                            key={r.id}
                            company={company}
                            showErrorReport={showErrorReport}
                            setShowErrorReport={setShowErrorReport}
                            scanReport={() => {
                              setSingleUserScan(true)
                              startScan(u.id, [r.id])
                            }}
                            scanInProgress={scanInProgress}
                            toggleDisable={() => toggleReportDisable([r.id])}
                            reportLoading={toggleReportLoading}
                          />
                        ))}
                      </ul>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
      <ScanCompanyErrorPopup
        scanStatus={scanStatus}
        company={company}
        startScan={startScan}
        stopScan={() => setShowStopScanWarn(true)}
        continueScan={() => {
          checkStatus(currentUser)
          setCheckScanStatus(true)
        }}
        user={company.users.find(u => u.id === currentUser)}
      />
      {showUploadModal && (
        <ScanUploadReportModal
          users={company.users}
          closeModal={() => setShowUploadModal(false)}
          reports={reports.filter(r => r.status !== reportRecordStatusType.saved && !r.isExpired)}
          onUploadSuccess={ids => {
            setShowUploadModal(false)
            const reportRecords = reports.map(r => ({
              ...r,
              status: ids.includes(r.id) ? reportRecordStatusType.saved : r.status,
            }))
            setReports(reportRecords)
            updateCompanyMonthRecords(reportRecords)
          }}
        />
      )}
      {showStopScanWarn && <StopScanWarn closeWarn={() => setShowStopScanWarn(false)} stopScan={stopScan} />}
    </>
  )
}

export default ScanCompany

const ScanCompanyAllNewReportsBanner = ({ startScanDisabled, startScan }) => {
  const { t } = useTranslation('scan')
  return (
    <div className='scan-company-banner'>
      <div className='scan-company-banner-content'>
        <h5>{t('allNewReportsBanner.heading')}</h5>
        <p>{t('allNewReportsBanner.text')}</p>
      </div>
      <div className='scan-company-banner-actions'>
        {
          <ButtonRounded disabled={startScanDisabled} onClick={() => startScan()}>
            {t('dwnldAllBtn')}
          </ButtonRounded>
        }
      </div>
    </div>
  )
}

const ScanCompanyInProgressBanner = ({ stopScan, progress }) => {
  const { t } = useTranslation('scan')
  return (
    <div className='scan-company-banner'>
      <RadialChartScanProgress progress={progress} width={'100px'} height={'70px'} />
      <div className='scan-company-banner-content'>
        <h5>{t('scanInProgressBanner.heading')}</h5>
        <p>{t('scanInProgressBanner.text')}</p>
      </div>
      <div className='scan-company-banner-actions'>
        <ButtonRounded onClick={stopScan}>{t('stopScanBtn')}</ButtonRounded>
      </div>
    </div>
  )
}

const ScanCompanyCreateInstanceBanner = () => {
  const { t } = useTranslation('scan')
  return (
    <div className='scan-company-banner'>
      <div className='scan-company-banner-content'>
        <h5>{t('scanCompanyCreateInstanceBanner.heading')}</h5>
      </div>
      <div className='scan-company-banner-actions'>
        <Loader />
      </div>
    </div>
  )
}

const ScanCompanyScanStoppedBanner = ({ directCompany, scanInProgress, startScan }) => {
  const { t } = useTranslation('scan')
  return (
    <div className='scan-company-banner'>
      <div className='scan-company-banner-content'>
        <h5>{t('scanStoppedBanner.heading')}</h5>
        <p>{t('scanStoppedBanner.text')}</p>
      </div>
      <div className='scan-company-banner-actions'>
        {directCompany && (
          <ButtonRounded disabled={scanInProgress} onClick={() => startScan()}>
            {t('continueScanBtn')}
          </ButtonRounded>
        )}
      </div>
    </div>
  )
}

const ScanCompanySomeErrorsBanner = ({ nextCompanyIdToScan, directCompany, scanInProgress, startScan }) => {
  const { t } = useTranslation('scan')
  return (
    <div className='scan-company-banner'>
      <div className='scan-company-banner-content'>
        <h5>{t('someErrorsBanner.heading')}</h5>
        <p>{t('someErrorsBanner.text')}</p>
      </div>
      <div className='scan-company-banner-actions'>
        {directCompany && (
          <ButtonRounded disabled={scanInProgress} onClick={() => startScan()}>
            {t('tryAgainBtn')}
          </ButtonRounded>
        )}
        <GoNextCompanyButton nextCompanyIdToScan={nextCompanyIdToScan} />
      </div>
    </div>
  )
}

const ScanCompanyAllDownloadedBanner = ({ nextCompanyIdToScan }) => {
  const { t } = useTranslation('scan')
  return (
    <div className='scan-company-banner'>
      <div className='scan-company-banner-content'>
        <h5>{t('allDownloadedBanner.heading')}</h5>
        <p>{t('allDownloadedBanner.text')}</p>
      </div>
      <div className='scan-company-banner-actions'>
        <GoNextCompanyButton nextCompanyIdToScan={nextCompanyIdToScan} />
      </div>
    </div>
  )
}

const ScanCompanyPrevMonthBanner = ({ scanAvailable, startScan, scanInProgress, setShowStopScanWarn }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('scan')
  return scanInProgress ? (
    <ScanCompanyInProgressBanner progress={scanInProgress} stopScan={() => setShowStopScanWarn(true)} />
  ) : (
    <div className='scan-company-banner prev-month'>
      <div className='scan-company-banner-content'>
        <h5>{t('prevMonthBanner.heading')}</h5>
      </div>
      <div className='scan-company-banner-actions'>
        {scanAvailable && (
          <ButtonRounded disabled={scanInProgress} onClick={() => startScan()}>
            {t('dwnldAvailableBtn')}
          </ButtonRounded>
        )}{' '}
        <ButtonRounded
          variant='outlined'
          onClick={() => {
            dispatch({ type: REPORTS_DATE_SELECT_LATEST })
          }}
        >
          {t('backToCurrMonthBtn')}
        </ButtonRounded>
      </div>
    </div>
  )
}

const GoNextCompanyButton = ({ nextCompanyIdToScan }) => {
  const { t } = useTranslation('scan')
  const history = useHistory()
  if (!nextCompanyIdToScan) return null
  return (
    <ButtonRounded
      variant='outlined'
      onClick={() => history.push(history.location.pathname.replace(new RegExp('[^/]+$'), nextCompanyIdToScan))}
    >
      {t('goNextCompanyBtn')}
    </ButtonRounded>
  )
}

const ScanCompanyErrorPopup = ({ scanStatus, company, startScan, stopScan, continueScan, user }) => {
  const [submitLoading, setSubmitLoading] = useState(false)
  const [fields, setFields] = useState(
    getCompanyAuthFields(company).map(field => ({ ...field, value: '', name: hebrew() ? field.name : field.nameEn }))
  )
  const [errors, setErrors] = useState(
    getCompanyAuthFields(company).map(field => ({ fieldType: field.fieldType, error: validationError.zeroLength }))
  )

  const filterByStatus = scanStatus => f => {
    switch (scanStatus) {
      // case scanStatusType.LoginError:
      // case scanStatusType.ResetPasswordRequierd: {
      //   return f.fieldType === authFieldType.userName || f.fieldType === authFieldType.password
      // }
      // case scanStatusType.InvalidVaultCredentials:
      // case scanStatusType.ResetVaultPasswordRequired: {
      //   return f.fieldType.toLowerCase().includes('vault')
      // }
      case scanStatusType.WaitingAnswer: {
        return f.fieldType === authFieldType.answer
      }
      default:
        return true
    }
  }

  const hasErrors = scanStatus =>
    errors
      .filter(filterByStatus(scanStatus))
      .map(field => field.error)
      .some(err => err !== validationError.noError)

  const submitHandler = async () => {
    try {
      setSubmitLoading(true)
      let submitParams
      if (scanStatus === scanStatusType.WaitingAnswer) {
        submitParams = {
          companyId: company.companyId,
          answer: fields.find(f => f.fieldType === authFieldType.answer).value,
          userId: user.id,
        }
      } else {
        submitParams = {
          hasTwoUsers: company.hasElementaryUser,
          companyId: company.companyId,
          insuranceFields: company.fields.map(f => f.id),
          authType: company.hasElementaryUser
            ? getAuthType(company.reportRecords.filter(rr => !rr.isDisabled)[0].authType) ?? companyAuthType.all
            : companyAuthType.all,
          authFields: fields.filter(filterByStatus(scanStatus)).map(f => {
            return { name: f.fieldType, value: f.value }
          }),
        }
      }

      const submitRequest =
        scanStatus === scanStatusType.WaitingAnswer ? sendScanAnswerRequest : updateCompanyCredentialsRequest
      let {
        data: { data },
      } = await submitRequest(submitParams)
      if (scanStatus === scanStatusType.WaitingAnswer) {
        data.status === 'None' ? stopScan() : continueScan()
      } else if (data) {
        startScan()
      }
      setSubmitLoading(false)
    } catch (error) {
      clog(`Error submitting for scan status ${scanStatus}`)
      clog(error)
      setSubmitLoading(false)
    }
  }

  const submitSmsHandler = async (userId, sms) => {
    try {
      setSubmitLoading(true)
      let {
        data: { data },
      } = await sendScanSmsRequest({ companyId: company.companyId, userId, sms })
      continueScan()
      setSubmitLoading(false)
    } catch (error) {
      clog(`Error submitting for scan status ${scanStatus}`)
      setSubmitLoading(false)
    }
  }

  useEffect(() => {
    setErrors(
      fields.map(field => ({
        fieldType: field.fieldType,
        error: field.value === '' ? validationError.zeroLength : validationError.noError,
      }))
    )
  }, [fields])

  switch (scanStatus) {
    // case scanStatusType.LoginError:
    // case scanStatusType.ResetPasswordRequierd:
    // case scanStatusType.InvalidVaultCredentials:
    // case scanStatusType.ResetVaultPasswordRequired:
    case scanStatusType.WaitingAnswer: {
      return (
        <ScanCompanyPopupLoginError
          userName={user?.name}
          authFields={fields.filter(filterByStatus(scanStatus))}
          setAuthFields={setFields}
          submit={submitHandler}
          submitDisabled={hasErrors(scanStatus) || submitLoading}
          scanStatus={scanStatus}
          closePopup={stopScan}
        />
      )
    }
    case scanStatusType.WaitingSms:
      // case scanStatusType.IncorrectSMSCode:
      return (
        <ScanCompanyPopupSms
          user={user}
          company={company}
          sendSmsHandler={submitSmsHandler}
          scanStatus={scanStatus}
          sendLoadingStatus={submitLoading}
          closePopup={stopScan}
        />
      )
    default:
      return null
  }
}
