import AppBar from '../AppBar/AppBar'
import './ItemsAppBar.scss'

const ItemsAppBar = ({ closeBar = () => {}, children, itemsCount }) => {
  return (
    <AppBar closeBar={closeBar}>
      <div className='items-appbar-container'>
        <div className='appbar-counter-container'>{itemsCount}</div>
        <div className='appbar-content-container'>{children}</div>
      </div>
    </AppBar>
  )
}

export default ItemsAppBar
