import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import BottomSheetBase from '../../../../components/Common/BottomSheetBase/BottomSheetBase'
import ModalWithNote from '../../../../components/Common/ModalWithNote/ModalWithNote'
import { customerTaskStatus, customerTaskStatusType } from '../../../../types/customerTaskTypes'
import { setTaskStatus } from '../../actions/workstationActions'
import './SetTaskStatusMenu.scss'

const SetTaskStatusMenu = ({ tasks, closeMenu = () => {}, onCloseMenu = () => {} }) => {
  const { t, i18n } = useTranslation('workstation')
  const [showNoteModal, setShowNoteModal] = useState(false)
  const [newStatus, setNewStatus] = useState(null)
  const dispatch = useDispatch()

  const hebrew = i18n.language === 'he'

  const refContextMenu = useRef(null)
  const menuItems = Object.values(customerTaskStatusType).map(statusValue => {
    return {
      status: statusValue,
      text: customerTaskStatus[statusValue].text(),
      textEn: customerTaskStatus[statusValue].textEn(),
      clickHandler: tasks.some(t => t.status !== statusValue)
        ? () => {
            if (tasks.some(t => t.status === customerTaskStatusType.treatWaiting)) {
              setNewStatus(statusValue)
              setShowNoteModal(true)
            } else {
              dispatch(setTaskStatus({ ids: tasks.map(t => t.id), status: statusValue }))
              onCloseMenu()
            }
          }
        : () => {
            closeMenu()
            onCloseMenu()
          },
    }
  })

  const handleCloseMenu = event => {
    if (
      !isMobile &&
      (event.key === 'Escape' || (refContextMenu.current && !refContextMenu.current.contains(event.target))) &&
      !showNoteModal
    ) {
      event.stopPropagation()
      closeMenu()
    }
  }

  const handleCloseModal = event => {
    setShowNoteModal(false)
    closeMenu()
  }

  const addTreatNote = note => {
    dispatch(setTaskStatus({ ids: tasks.map(t => t.id), status: newStatus, note }))
    onCloseMenu()
  }

  const handleClick = (event, item) => {
    event.stopPropagation()
    item.clickHandler()
  }

  const renderMenu = () => (
    <>
      <div className='set-task-status-menu-container'>
        <div className='set-task-status-menu' onClick={e => e.stopPropagation()} ref={refContextMenu}>
          {menuItems.map((item, i) => (
            <button
              key={i + item.text}
              className={classNames('set-task-status-menu-item', {
                treated: item.status === customerTaskStatusType.treated,
              })}
              onClick={e => handleClick(e, item)}
            >
              {hebrew ? item.text : item.textEn}
            </button>
          ))}
        </div>
      </div>
      {showNoteModal && (
        <ModalWithNote closeOnOutsideClick={true} closeModal={handleCloseModal} onAddNote={addTreatNote} />
      )}
    </>
  )

  useEffect(() => {
    document.addEventListener('keydown', handleCloseMenu, true)
    document.addEventListener('click', handleCloseMenu, true)
    return () => {
      document.removeEventListener('keydown', handleCloseMenu, true)
      document.removeEventListener('click', handleCloseMenu, true)
    }
  })

  return (
    <>
      {isMobile ? (
        <BottomSheetBase open sheetTitle={t('listHeader.taskStatus')} closeSheet={closeMenu} closeOnOutsideClick={true}>
          {renderMenu()}
        </BottomSheetBase>
      ) : (
        renderMenu()
      )}
    </>
  )
}

export default SetTaskStatusMenu
